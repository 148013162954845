import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  // TableFooter,
  // TablePagination,
  MenuItem,
  Select,
  Checkbox,
  TextField,
  Input,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './sendNotification.style';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';

const SendNotification = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // const [level, setLevel] = useState('');
  // const [checkLoader] = useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(null);
  // const [page, setPage] = React.useState(0);
  const [branchId, setBranchId] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedBranchNames, setSelectedBranchNames] = useState([]);
  const [selectedRoleNames, setSelectedRoleNames] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [roleId, setRoleId] = useState([]);
  const [allClicked, setAllClicked] = useState(false);
  const [userList, setUserList] = useState(null);
  const alert = useAlert();

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);
  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);

  useEffect(() => {
    // module=localStorage.getItem('Notification')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Notification')
      },
    });
  },[])

  const searchUsers = () => {
    // setBranchId(branchIdchosen);
    if (roleId.length === 0) {
      alert.warning('Role is mandatory');
    } else if (branchId.length === 0) {
      alert.warning('Branch is mandatory');
    } else if (!message) {
      alert.warning('Message is mandatory');
    } else {
      setLoading(true);
      fetch(`${urls.getRoleBranchUserDetailsAPI}?role=${roleId}&branch=${branchId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Notification')
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setLoading(false);
            return res.json();
          }
          // if (res.status !== 200) {
          //   setLoading(false);
          //   alert.warning('Something went wrong please try again ');
          // }
          return 0;
        })
        .then((response) => {
          setLoading(false);
          // console.log(response);
          setUserList(response);
          // setUserList(data);
        });
      // fetchUsers({
      //   url: `${urls.getRoleBranchUserDetailsAPI}
      // ${objectToQueryString({ role: roleId, branch: branchId })}`,
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${auth.personal_info.token}`,
      //   },
      // });
    }
  };
  useEffect(() => {
    fetchRoles({
      url: `${urls.getRoleRegistration}?user_type=USER`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Notification')
      },
    });
    fetchBranches({
      url: urls.branchApi,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Notification')
      },
    });
  }, [fetchRoles, fetchBranches, auth.personal_info.token]);

  let loader = null;
  if (branchListLoading || roleListLoading || loading || modulePermissionLoading) {
    loader = <Loader open />;
  }

  const sendTheNotification = () => {
    if (roleId.length === 0) {
      alert.warning('Role is mandatory');
    } else if (branchId.length === 0) {
      alert.warning('Branch is mandatory');
    } else if (!message) {
      alert.warning('Message is mandatory');
    } else {
      const data = {
        text: message,
        role: roleId,
        branch: branchId,
        // user: selected,
      };
      // below is the format of payload.
      // only 'text: message' is mandatory in payload.
      // if all rolenames/branchnames get selected, exclude role/branch from the payload.
      // JSON.stringify({
      //   text: message,
      //   role: roleId,
      //   branch: branchId,
      //   user: selected,
      // }),
      // if (!(roleList && roleList.length === selectedRoleNames.length)) {
      //   data.role = roleId;
      // }
      // if (!(branchList && branchList.length === selectedBranchNames.length)) {
      //   data.branch = branchId;
      // }
      if (!allClicked && selected.length !== 0) {
        data.user = selected;
      }
      // sendNotificationAPICall({
      //   url: urls.sendNotificationAPI,
      //   method: 'POST',
      //   body: data,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${auth.personal_info.token}`,
      //   },
      // });
      setLoading(true);
      fetch(`${urls.sendNotificationAPI}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Notification')
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 201) {
            alert.success('Your notification has been Sent');
            setSelectedRoleNames([]);
            setSelectedBranchNames([]);
            setRoleId([]);
            setBranchId([]);
            setSelected([]);
            setUserList(null);
          }
          if (res.status !== 201) {
            setLoading(false);
            alert.warning('Something went wrong please try again ');
          }
          return 0;
        })
        .then((response) => {
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log(response);
          // setUserList(data);
        });
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAll = () => {
    if (userList.length === selected.length) {
      setSelected([]);
      setAllClicked(false);
    } else {
      setAllClicked(true);
      const userIdArray = [];
      userList.map((oneUser) => userIdArray.push(oneUser.user.id));
      setSelected(userIdArray);
    }
  };

  const branchChosen = (branchNames) => {
    if (branchNames.includes('Select All')) {
      if (branchList.length === selectedBranchNames.length) {
        setSelectedBranchNames([]);
      } else {
        setBranchId(branchList.map((br) => br.id));
        setSelectedBranchNames(branchList.map((br) => br.branch_name));
      }
    } else {
      setSelectedBranchNames(branchNames);
      // eslint-disable-next-line max-len
      setBranchId(branchNames.map((br) => branchList.filter((item) => item.branch_name === br)[0].id));
    }
  };
  const roleChosen = (roleNames) => {
    if (roleNames.includes('Select All')) {
      if (roleList.response.length === selectedRoleNames.length) {
        setSelectedRoleNames([]);
      } else {
        const roleIdArray = [];
        const rolenameArray = [];
        roleList.response.map((onerole) => {
          roleIdArray.push(onerole.role_id);
          rolenameArray.push(onerole.role_name);
          return null;
        });
        setRoleId(roleIdArray);
        setSelectedRoleNames(rolenameArray);
      }
    } else {
      setSelectedRoleNames(roleNames);
      const roleIdarray = [];
      for (let k = 0; k < roleNames.length; k += 1) {
        roleIdarray.push(
          roleList
            && roleList.response.filter((item) => item.role_name === roleNames[k])[0]
              .role_id,
        );
      }
      setRoleId(roleIdarray);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Send Notification</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography>
                  Enter Message
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <TextField
                  className={classes.textField}
                  label="Type your Message"
                  required
                  multiline
                  rows={14}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  variant="outlined"
                />
                <Typography>
                  Select Role
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  fullWidth
                  multiple
                  value={selectedRoleNames}
                  onChange={(e) => { roleChosen(e.target.value); }}
                  variant="outlined"
                  renderValue={(chosen) => chosen.join(', ')}
                  input={<Input />}
                  className={classes.textField}
                  style={{ color: 'black', maxWidth: 675 }}
                >
                  (
                  <MenuItem
                    value="Select All"
                    key={0}
                    name="Select All"
                  >
                    <Checkbox
                      checked={roleList && roleList.length === selectedRoleNames.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  )
                  {roleList
                  && roleList.length !== 0
                  && roleList.response.map((data) => (
                    <MenuItem
                      value={data.role_name}
                      key={data.role_id}
                      name={data.role_name}
                    >
                      {/* {data.id ? data.name : ''} */}
                      <Checkbox
                        checked={
                          selectedRoleNames.indexOf(data.role_name) > -1
                        }
                      />
                      <ListItemText primary={data.role_name} />
                    </MenuItem>
                  ))}
                </Select>
                <Typography>
                  Select Branch
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  fullWidth
                  multiple
                  value={selectedBranchNames}
                  onChange={(e) => { branchChosen(e.target.value); }}
                  variant="outlined"
                  input={<Input />}
                  renderValue={(chosen) => chosen.join(', ')}
                  className={classes.textField}
                  style={{ color: 'black', maxWidth: 675 }}
                >
                  (
                  <MenuItem
                    value="Select All"
                    key={0}
                    name="Select All"
                  >
                    <Checkbox
                      checked={branchList && branchList.length === selectedBranchNames.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  )
                  {branchList
                  && branchList.length !== 0
                  && branchList.map((data) => (
                    <MenuItem
                      value={data.branch_name}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {/* {data.id ? data.branch_name : ''} */}
                      <Checkbox
                        checked={
                          selectedBranchNames.indexOf(data.branch_name) > -1
                        }
                      />
                      <ListItemText primary={data.branch_name} />
                    </MenuItem>
                  ))}

                </Select>
                <Button
                  style={{ marginTop: '30px' }}
                  className={classes.updateButton}
                  color="primary"
                  variant="contained"
                  onClick={() => searchUsers()}
                  size="large"
                  fullWidth
                >
                  search
                </Button>
              </Grid>
              {userList && userList.length !== 0 ? (
                <Grid item md={6} xs={12}>
                  <div className={classes.tablePart}>
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            Select User
                          </TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">ERP No</TableCell>
                          <TableCell align="center">Branch</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          hover
                          onClick={() => handleSelectAll()}
                        >
                          <TableCell align="center">
                            <Checkbox
                              checked={userList.length === selected.length}
                              onClick={() => handleSelectAll()}
                            />
                          </TableCell>
                          <TableCell align="center">Select All</TableCell>
                          <TableCell align="center" />
                        </TableRow>
                        {userList
                          && userList.length !== 0
                          && userList.map((item) => {
                            const isItemSelectedId = isSelected(item.user.id);
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, item.user.id)}
                                role="checkbox"
                                tabIndex={-1}
                                key={item.user.first_name}
                                selected={isItemSelectedId}
                              >
                                <TableCell align="center">
                                  {' '}
                                  <Checkbox
                                    checked={isItemSelectedId}
                                    id={item.user.first_name + item.id}
                                    key={item.user.first_name + item.id}
                                    // inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {item.user.first_name}
                                </TableCell>
                                <TableCell align="center">
                                  {item.user.username || null}
                                </TableCell>
                                <TableCell align="center">
                                  {item.branch[0].branch_name}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                  {modulePermission && modulePermission.response.can_send ?
                  <Button
                    style={{ marginTop: '30px' }}
                    className={classes.updateButton}
                    color="primary"
                    variant="contained"
                    onClick={() => sendTheNotification()}
                    size="large"
                    fullWidth
                  >
                    Send
                  </Button>:null}
                </Grid>
              ) : userList && userList.length === 0 && (
                <Grid item md={6} xs={12}>
                  <Typography>No users Found</Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </div>
      </div>
      {/* <>
      </> */}
      <div />
      {loader}
    </>
  );
};

SendNotification.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SendNotification);
