import React from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Divider,
  IconButton,
  Button,
  Paper,
  Box,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
// import urls from '../../../url';
import styles from './allNotes.style';

function IndividualNotesModel({
  classes, handelClose, open, information, fullInformaion,
}) {
  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function downloadFunction() {
    // eslint-disable-next-line no-alert
    window.alert('Download Feature will be enabled at next release');
  }

  return (
    <Dialog
      maxWidth="xl"
      className={classes.modal}
      open={open}
      disableEnforceFocus
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
    >
      <DialogTitle id="alert-dialog-title" onClose={handelClose}>
        Full Notes on&nbsp;&nbsp;
        <b style={{ color: 'blue' }}>{(fullInformaion.learning_module && fullInformaion.learning_module.title) || ''}</b>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={10} xs={6}>
            <Typography variant="h5">Nots :</Typography>
          </Grid>
          <Grid item md={2} xs={6}>
            {/* href={`${urls.downloadNotesApi}?notes_id=${information && information.id}`} */}
            <Button variant="contained" color="primary" onClick={() => downloadFunction()}>
              Download&nbsp;
              <CloudDownloadIcon />
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box border={1} style={{ borderColor: 'lightgray', padding: '10px', margin: '10px' }} className={classes.notesPaper}>
              {ReactHtmlParser((information && information.notes) || '')}
            </Box>
          </Grid>
          <Grid item md={7} sm={7} xs={12} style={{ border: '1px solid lightgray' }}>
            <video src={fullInformaion.learning_module && fullInformaion.learning_module.video} width="100%" height="400" controls controlsList="nodownload">
              <source src={fullInformaion.learning_module && fullInformaion.learning_module.video} type="video/mp4" height="300" width="100%" />
              <track src={fullInformaion.learning_module && fullInformaion.learning_module.video} kind="captions" srcLang="en" label="english_captions" />
            </video>
          </Grid>
          <Grid item md={5} sm={5} xs={12} style={{ border: '1px solid lightgray' }}>
            <Paper className={classes.textBox} style={{ width: '100%', height: '100%' }}>
              <Typography variant="h5">About Video :</Typography>
              <Divider className={classes.divider} />
              {ReactHtmlParser(fullInformaion
                && fullInformaion.learning_module
                 && fullInformaion.learning_module.text)}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

IndividualNotesModel.defaultProps = {
  information: '',
  fullInformaion: '',

};

IndividualNotesModel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  information: PropTypes.instanceOf(Object),
  fullInformaion: PropTypes.instanceOf(Object),
  handelClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(IndividualNotesModel);
