/* eslint-disable react/no-array-index-key */
// import React, { Fragment, useState, useEffect } from "react";
// import styles from "./TrainingUnit.style";
// import { withStyles } from "@material-ui/core";
// import urls from "../../../../../url";
// import { Link } from "react-router-dom";

// function TrainingChapters({ classes }) {
//   const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
//   const [trainingUnits, setTrainingUnits] = useState();
//   let unitId = localStorage.getItem("unitId");

//   async function getUnits(id) {
//     const URL = urls.inHouseModules;
//     const fetchResult = fetch(URL + `?chapter_id=${id}`, {
//       method: "GET",
//       cache: "reload",
//       headers: {
//         "Content-Type": "Application/json",
//         Authorization: `Bearer ${auth.personal_info.token}`
//       }
//     });
//     const response = await fetchResult;
//     const jsonData = await response.json();
//     return jsonData;
//   }

//   useEffect(() => {
//     getUnits(unitId).then(data => setTrainingUnits(data));
//   }, []);

//   return (
//     <Fragment>
//       <div className={classes.chapterBackground}>
//         <div className={classes.moduleCover}>
//           {trainingUnits && console.log(trainingUnits)}
//         </div>
//       </div>
//     </Fragment>
//   );
// }

// export default withStyles(styles)(TrainingChapters);

// import React, { Fragment, useState, useEffect } from "react";
// import styles from "./TrainingUnit.style";
// import urls from "../../../../../url";
// import { Link } from "react-router-dom";
// import { Grid, Typography, withStyles, Divider, Box } from "@material-ui/core";
// const TrainingUnits = ({ classes }) => {
//   const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
//   const [trainingUnits, setTrainingUnits] = useState();
//   let unitId = localStorage.getItem("unitId");

//   async function getUnits(id) {
//     const URL = urls.inHouseModules;
//     const fetchResult = fetch(URL + `?chapter_id=${id}`, {
//       method: "GET",
//       cache: "reload",
//       headers: {
//         "Content-Type": "Application/json",
//         Authorization: `Bearer ${auth.personal_info.token}`
//       }
//     });
//     const response = await fetchResult;
//     const jsonData = await response.json();
//     return jsonData;
//   }

//   useEffect(() => {
//     getUnits(unitId).then(data => setTrainingUnits(data));
//   }, []);
//   const videoPlay = (file, title) => {
//     let play = null;
//     play = (
//       <React.Fragment>
//         <Grid container spacing={2}>
//           <Grid item md={10} xs={12}>
//             <Typography variant='h5'>{title}</Typography>
//             <Divider className={classes.divider} />
//           </Grid>
//           <Grid item md={12} xs={12}>
//             <Grid item md={10} xs={12} style={{ margin: "12px 0px" }}>
//               <video
//                 id='background-video'
//                 controls
//                 alt='video file is crashed'
//                 height='100%'
//                 width='100%'
//               >
//                 <source src={file} type='video/mp4' />
//               </video>
//             </Grid>
//           </Grid>
//         </Grid>
//       </React.Fragment>
//     );
//     return play;
//   };

//   const showImage = (imageFile, title) => {
//     let text = null;
//     text = (
//       <React.Fragment>
//         <Grid container spacing={2}>
//           <Grid item md={10} xs={12}>
//             <Typography variant='h5'>{title}</Typography>
//             <Divider className={classes.divider} />
//           </Grid>
//           <Grid item md={1} />
//           <Grid item md={10} xs={12}>
//             <Grid item md={10} xs={12} style={{ margin: "12px 0px" }}>
//               <iframe
//                 src={`http://docs.google.com/gview?url=${imageFile}&embedded=true`}
//                 style={{ width: "100%", height: "700px", frameborder: "0" }}
//                 alt='PDF file is crashed'
//               ></iframe>
//             </Grid>
//           </Grid>
//           <Divider className={classes.divider} />
//         </Grid>
//       </React.Fragment>
//     );
//     return text;
//   };
//   const textDisplay = (document, title) => {
//     let text = null;
//     text = (
//       <React.Fragment>
//         <Grid container spacing={2}>
//           <Grid item md={10} xs={12}>
//             <Typography variant='h5'>{title}</Typography>
//             <Divider className={classes.divider} />
//           </Grid>
//           <Grid item md={12} xs={12}>
//             <Grid
//               item
//               md={10}
//               xs={12}
//               style={{ margin: "12px 0px", textAlign: "justify" }}
//             >
//               <Typography alt='text file null'>{document}</Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//       </React.Fragment>
//     );
//     return text;
//   };
//   const renderData = () => {
//     let showChapters = null;
//     showChapters = (
//       <React.Fragment>
//         {trainingUnits &&
//           trainingUnits.map((index, id) => {
//             return (
//               <React.Fragment key={id}>
//                 <Grid container>
//                   <Grid item md={2} />
//                   <Grid item md={10} xs={12}>
//                     {console.log(">>>>>>>", index && index.content_type)}
//                     {index && index.content_type === "Video"
//                       ? videoPlay(index.file, index.title)
//                       : ""}
//                     {index && index.content_type === "Text"
//                       ? textDisplay(index.document, index.title)
//                       : ""}
//                     {index && index.content_type === "File"
//                       ? showImage(index.file, index.title)
//                       : ""}
//                   </Grid>
//                 </Grid>
//               </React.Fragment>
//             );
//           })}
//       </React.Fragment>
//     );
//     return showChapters;
//   };

//   return (
//     <React.Fragment>
//       <Grid container style={{ paddingTop: "12vh" }}>
//         <Grid item md={1} />
//         <Grid item md={10} xs={12}>
//           <Box border={2} className={classes.paper}>
//             {trainingUnits && trainingUnits.length != 0 ? (
//               renderData()
//             ) : (
//               <Typography variant='h4'>
//                 Content of this chapter is empty..!
//               </Typography>
//             )}
//           </Box>
//         </Grid>
//       </Grid>
//     </React.Fragment>
//   );
// };
// // MoreInfo.defaultProps = {};
// export default withStyles(styles)(TrainingUnits);
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  withStyles,
  Button,
  Checkbox,
  Radio,
  Divider,
  StepButton,
  Card,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
  useHistory,
} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../../../../../hoc/loader';
import urls from '../../../../../url';
import useFetch from '../../../../../hoc/useFetch';
import { useAlert } from '../../../../../hoc/alert/alert';
import styles from './CoursesUnit.style';

const CoursesUnit = ({ classes }) => {
  // const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [double, setDouble] = useState(false);
  const [next] = useState(false);
  const [session, setSession] = useState(false);
  const [mcqTestOpen, setMcqTestOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [mcqQueList, setMcqQuesList] = useState('');
  const [initialValue, SetinitialValue] = useState(null);
  const [questionPaperId, setQuestionPaperId] = useState('');
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [trainingUnits] = useState();
  // const unitId = localStorage.getItem('unitId');
  // eslint-disable-next-line no-console
  console.log(double, session, 'dddddd');
  // const nextPage = localStorage.getItem('nextItem');

  // async function getUnits(id) {
  //   const URL = urls.inHouseModules;
  //   const fetchResult = fetch(`${URL}?chapter_id=${id}`, {
  //     method: 'GET',
  //     cache: 'reload',
  //     headers: {
  //       'Content-Type': 'Application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   });
  //   const response = await fetchResult;
  //   const jsonData = await response.json();
  //   return jsonData;
  // }

  const {
    data: finishMcqTest,
    isLoading: finishingMcqTest,
    doFetch: fetchToMcqTest,
  } = useFetch(null);

  // const {
  //   data: fileData,
  //   isLoading: fileLoading,
  //   doFetch: fetchFile,
  // } = useFetch(null);

  // useEffect(() => {
  //   getUnits(unitId).then((data) => setTrainingUnits(data));
  //   fetchFile({
  //     url:
  //       'https://udanta.s3.amazonaws.com/media/1Growth%20Mindset%20-%20Copy.pptx',
  //     method: 'get',
  //     type: 'blob',
  //     headers: {
  //       // 'Content-Type': 'application/json',
  //       // Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   });
  // }, []);

  // function getFileUrl() {
  //   let url;
  //   if (fileData && window && URL) {
  //     url = window.URL.createObjectURL(
  //       new Blob([fileData], {
  //         type:
  //           'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  //       }),
  //     );
  //   }
  //   return url;
  // }

  // console.log(getFileUrl());

  useEffect(() => {
    if (finishMcqTest) {
      setMcqTestOpen(false);
      setActiveStep(0);
      setQuestionPaperId('');
      setSelectedValue([]);
      setSelectedOption([]);
      SetinitialValue(null);
    }
  }, [finishMcqTest]);

  const history = useHistory();

  const DialogTitle = ((props) => {
    const {
      children, onClose, ...other
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function handleChange(e, ql, index) {
    if (
      ql
      && ql.type_question
      && ql.type_question.question_type_name === 'Single Choice'
    ) {
      const awV = [...selectedValue];
      awV[index] = true;
      setSelectedValue(awV);
      const awa = [...selectedOption];
      const ans = {
        id: ql.id,
        answer: e.target.value,
        question_type: ql.type_question.id,
      };
      awa[index] = ans;
      setSelectedOption(awa);
      SetinitialValue(e.target.value);
    }

    if (
      ql
      && ql.type_question
      && ql.type_question.question_type_name === 'Multiple Choice'
    ) {
      const currentIndex = initialValue.indexOf(e.target.value);
      const newChecked = [...initialValue];
      if (currentIndex === -1) {
        newChecked.push(e.target.value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      SetinitialValue(newChecked);

      const awV = [...selectedValue];
      awV[index] = !(newChecked && newChecked.length === 0);
      setSelectedValue(awV);
      const awa = [...selectedOption];
      const ans = {
        id: ql.id,
        answer: newChecked,
        question_type: ql.type_question.id,
      };
      awa[index] = ans;
      setSelectedOption(awa);
    }
  }

  function handleClick(e, ql, index) {
    if (
      ql
      && ql.type_question
      && ql.type_question.question_type_name === 'Single Choice'
    ) {
      const awV = [...selectedValue];
      awV[index] = !awV[index];
      setSelectedValue(awV);
      const awa = [...selectedOption];
      const ans = {
        id: ql.id,
        answer: e.target.value,
        question_type: ql.type_question.id,
      };
      awa[index] = awa[index] ? null : ans;
      setSelectedOption(awa);
      SetinitialValue(e.target.value);
    }

    if (
      ql
      && ql.type_question
      && ql.type_question.question_type_name === 'Multiple Choice'
    ) {
      const currentIndex = initialValue.indexOf(e.target.value);
      const newChecked = [...initialValue];
      if (currentIndex === -1) {
        newChecked.push(e.target.value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      SetinitialValue(newChecked);
      const awV = [...selectedValue];
      awV[index] = !(newChecked && newChecked.length === 0);
      setSelectedValue(awV);
      const awa = [...selectedOption];
      const ans = {
        id: ql.id,
        answer: newChecked,
        question_type: ql.type_question.id,
      };
      awa[index] = awa[index] ? null : ans;
      setSelectedOption(awa);
    }

    // if (
    //   ql &&
    //   ql.type_question &&
    //   ql.type_question.question_type_name === "Match The Following"
    // ) {

    //   alert
    // }
  }

  const handleCloseMcqTest = () => {
    setMcqTestOpen(false);
    // setActiveStep(0);
    setQuestionPaperId('');
    // setSelectedValue([]);
    // setSelectedOption([]);
    // SetinitialValue(null);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    if (selectedOption && selectedOption[step] && selectedOption[step].answer) {
      SetinitialValue(
        selectedOption && selectedOption[step] && selectedOption[step].answer,
      );
    } else {
      SetinitialValue([]);
    }
  };

  function handleBack() {
    if (
      selectedOption
      && selectedOption[activeStep - 1]
      && selectedOption[activeStep - 1].answer.length !== 0
    ) {
      SetinitialValue(
        selectedOption
          && selectedOption[activeStep - 1]
          && selectedOption[activeStep - 1].answer,
      );
    } else {
      SetinitialValue([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleNext(quesLen, stepLen) {
    if (
      selectedOption
      && selectedOption[activeStep + 1]
      && selectedOption[activeStep + 1].answer.length !== 0
    ) {
      SetinitialValue(
        selectedOption
          && selectedOption[activeStep + 1]
          && selectedOption[activeStep + 1].answer,
      );
    } else {
      SetinitialValue([]);
    }
    if (stepLen < quesLen.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === quesLen.length - 1) {
      const data = {
        question_paper: questionPaperId,
        answer: selectedOption,
      };
      fetchToMcqTest({
        url: urls.apiToFinishMcqTest,
        body: data,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case stepIndex:
        return (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography variant="h6">
                {stepIndex + 1}
                {mcqQueList
                  && mcqQueList[stepIndex]
                  && mcqQueList[stepIndex].question[0].mcqQuestion}
              </Typography>
            </Grid>
            {mcqQueList
              && mcqQueList[stepIndex].type_question
              && mcqQueList[stepIndex].type_question.question_type_name
                === 'Single Choice' && (
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Choose Correct Answer :
                      </FormLabel>
                      <RadioGroup
                        aria-label="Answer"
                        name="Answer"
                        value={
                          initialValue && typeof initialValue === 'string'
                            ? initialValue
                            : false
                        }
                        onChange={(e) => handleChange(e, mcqQueList[stepIndex], stepIndex)}
                        onClick={(e) => handleClick(e, mcqQueList[stepIndex], stepIndex)}
                      >
                        <FormControlLabel
                          value="option1"
                          control={<Radio color="primary" />}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option1
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="option2"
                          control={<Radio color="primary" />}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option2
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="option3"
                          control={<Radio color="primary" />}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option3
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="option4"
                          control={<Radio color="primary" />}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option4
                          }
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
            )}
            {mcqQueList
              && mcqQueList[stepIndex].type_question
              && mcqQueList[stepIndex].type_question.question_type_name
                === 'Multiple Choice' && (
                <Grid item md={12} xs={12}>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Choose Correct Answer :
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              checked={
                                initialValue
                                && initialValue.some(
                                  (element) => element === 'option1',
                                )
                              }
                              onChange={(e) => handleChange(
                                e,
                                mcqQueList[stepIndex],
                                stepIndex,
                              )}
                              onClick={(e) => handleClick(e, mcqQueList[stepIndex], stepIndex)}
                              value="option1"
                            />
                          )}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option1
                          }
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              checked={
                                initialValue
                                && initialValue.some(
                                  (element) => element === 'option2',
                                )
                              }
                              onChange={(e) => handleChange(
                                e,
                                mcqQueList[stepIndex],
                                stepIndex,
                              )}
                              onClick={(e) => handleClick(e, mcqQueList[stepIndex], stepIndex)}
                              value="option2"
                            />
                          )}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option2
                          }
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              checked={
                                initialValue
                                && initialValue.some(
                                  (element) => element === 'option3',
                                )
                              }
                              onChange={(e) => handleChange(
                                e,
                                mcqQueList[stepIndex],
                                stepIndex,
                              )}
                              onClick={(e) => handleClick(e, mcqQueList[stepIndex], stepIndex)}
                              value="option3"
                            />
                          )}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option3
                          }
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              checked={
                                initialValue
                                && initialValue.some(
                                  (element) => element === 'option4',
                                )
                              }
                              onChange={(e) => handleChange(
                                e,
                                mcqQueList[stepIndex],
                                stepIndex,
                              )}
                              onClick={(e) => handleClick(e, mcqQueList[stepIndex], stepIndex)}
                              value="option4"
                            />
                          )}
                          label={
                            mcqQueList
                            && mcqQueList[stepIndex]
                            && mcqQueList[stepIndex].question[0].option4
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
            )}
          </Grid>
        );
      default:
        return 'Uknown stepIndex';
    }
  }

  const FunctionToTakeMcqTest = () => {
    let modal = null;
    modal = (
      <>
        <Dialog
          fullWidth
          maxWidth="xl"
          open={mcqTestOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.mcqmodal}
        >
          <DialogTitle id="alert-dialog-title" onClose={handleCloseMcqTest}>
            Mcq Test
          </DialogTitle>
          <Divider />
          {mcqQueList && mcqQueList.length === 0 && (
            <DialogContent>
              <Typography
                variant="h4"
                style={{ color: 'blue', textAlign: 'center' }}
              >
                {' '}
                No Questions Found
              </Typography>
            </DialogContent>
          )}
          {mcqQueList && mcqQueList.length !== 0 && (
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12} className={classes.paperMain}>
                  <Card className={classes.card}>
                    <Stepper activeStep={activeStep} nonLinear>
                      {mcqQueList
                        && mcqQueList.length
                        && mcqQueList.map((label, i) => (
                          <Step key={label.id}>
                            <StepButton
                              onClick={handleStep(i)}
                              className={
                                activeStep === i ? classes.stepBtn : ''
                              }
                              completed={!!selectedValue[i]}
                            />
                          </Step>
                        ))}
                    </Stepper>
                  </Card>
                </Grid>
                <Grid item md={12} xs={12}>
                  {getStepContent(activeStep)}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ margin: '10px' }}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  </Grid>

                  <Grid item md={11}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        marginTop: '10px',
                        float:
                          activeStep === mcqQueList.length - 1 ? 'right' : '',
                      }}
                      onClick={() => handleNext(mcqQueList, activeStep, mcqQueList.id)}
                    >
                      {activeStep === mcqQueList.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          )}
        </Dialog>
      </>
    );
    return modal;
  };

  // useEffect(() => {
  //   if (completed) {
  //     history.push("/lessons");
  //   }
  // }, [completed]);
  // const location = useLocation();

  // console.log('Location State ++++', location.state);

  // useEffect(() => {
  //   if (location.state.next == undefined) {
  //     setnext(true);
  //     setSession(true);
  //   } else if (location.state.next.index == location.state.next.data.length) {
  //     setnext(true);
  //     setSession(true);
  //   } else {
  //     setnext(false);
  //     setSession(false);
  //   }
  // });

  // const {
  //   chapterData: chapter,
  //   chapterId: chapterID,
  //   isCompleted
  // } = location.state.current;

  const courseId = localStorage.getItem('courseID');
  const courseContentId = localStorage.getItem('courseContentId');

  const alert = useAlert();

  // const {
  //   data: sendChapterId,
  //   isLoading: ChapterIdLoading,
  //   doFetch: fetchChapterId
  // } = useFetch();

  const videoPlay = (file, title) => {
    let play = null;
    play = (
      <>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item md={10} xs={12}>
            <Typography variant="h5">{title}</Typography>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item md={2} />
          <Grid item md={10} xs={12}>
            <Grid item md={10} xs={12} style={{ margin: '12px 0px' }}>
              <video
                id="background-video"
                controls
                controlsList="nodownload"
                alt="video file is crashed"
                height="100%"
                width="100%"
              >
                <source src={file} type="video/mp4" />
                <track
                  src={file}
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                />
              </video>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
    return play;
  };
  function functionToOpenToTakeMcqTest(mcqFile, title, Id) {
    setQuestionPaperId(Id);
    setMcqQuesList(mcqFile);
    setMcqTestOpen(true);
  }

  const showMcqButton = (mcqFile, title, questionPaperid) => {
    let MCQTESTBUTTON = null;
    MCQTESTBUTTON = (
      <>
        <Grid container spacing={2} className={classes.Mcqgrid}>
          <Grid item md={2} />
          <Grid item md={10} xs={12}>
            <Typography variant="h5">
              Press the Below Button to Take Test on
              {' '}
              <b style={{ color: 'blue' }}>{title}</b>
            </Typography>
          </Grid>
          <Grid item md={2} />
          <Grid item md={10} xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => functionToOpenToTakeMcqTest(mcqFile, title, questionPaperid)}
            >
              Take Mcq Test on
              {' '}
              {title}
            </Button>
          </Grid>
        </Grid>
      </>
    );
    return MCQTESTBUTTON;
  };

  const showImage = (imageFile, title) => {
    let text = null;
    text = (
      <>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item md={10} xs={12}>
            <Typography variant="h5">{title}</Typography>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item md={2} />
          <Grid item md={10} xs={12}>
            <Grid item md={10} xs={12} style={{ margin: '12px 0px' }}>
              <iframe
                title="ifrme"
                src={`https://docs.google.com/gview?url=${imageFile}&embedded=true`}
                style={{ width: '100%', height: '700px', frameborder: '0' }}
                alt="PDF file is crashed"
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
      </>
    );
    return text;
  };

  const assiessmentDisplay = (FileAssessment, title) => {
    let assiessmentFile = null;
    assiessmentFile = (
      <>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item md={10} xs={12}>
            <Typography variant="h5">{title}</Typography>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item md={2} />
          <Grid item md={10} xs={12}>
            <Grid item md={10} xs={12} style={{ margin: '12px 0px' }}>
              <iframe
                title="myFrame"
                src={`https://docs.google.com/gview?url=${FileAssessment}&embedded=true`}
                style={{ width: '100%', height: '700px', frameborder: '0' }}
                alt="PDF file is crashed"
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
      </>
    );
    return assiessmentFile;
  };

  const showPPT = (document123) => ({ __html: document123 });

  const textDisplay = (document, title) => {
    let text = null;
    text = (
      <>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item md={10} xs={12}>
            <Typography variant="h5">{title}</Typography>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item md={2} />
          <Grid item md={10} xs={12}>
            <Grid
              item
              md={10}
              xs={12}
              style={{ margin: '12px 0px', textAlign: 'justify' }}
            >
              <Typography alt="text file null">{document}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
    return text;
  };

  const renderData = () => {
    let showChapters = null;
    showChapters = (
      <>
        {trainingUnits
          && trainingUnits.map((index, Id) => (
            <React.Fragment key={Id}>
              <Grid container>
                <Grid item md={1} />
                <Grid item md={10} xs={12}>
                  {index && index.content_type === 'Video'
                    ? videoPlay(index.file, index.title)
                    : ''}
                  {index && index.content_type === 'Text'
                    ? textDisplay(index.document, index.title)
                    : ''}
                  {index && index.content_type === 'File'
                    ? showImage(index.file, index.title)
                    : ''}
                  {index && index.content_type === 'Assignment'
                    ? assiessmentDisplay(index.file, index.title)
                    : ''}
                  {index && index.content_type === 'McqTest'
                    ? showMcqButton(
                      index.chapter_wise_mcq
                            && index.chapter_wise_mcq.mcq_questions,
                      index.title,
                      index.question_paper,
                    )
                    : ''}
                  {index && index.content_type === 'ppt' && (
                  <Grid container spacing={2}>
                    <Grid item md={10} xs={12}>
                      <Typography variant="h5">{index.title}</Typography>
                      <Divider className={classes.divider} />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{ margin: '12px 0px', textAlign: 'justify' }}
                      dangerouslySetInnerHTML={showPPT(
                        index.ppt_iframe,
                        index.title,
                      )}
                    />
                  </Grid>
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
      </>
    );
    return showChapters;
  };

  const onNextHandler = () => {
    // useEffect(() => {
    history.push('/lessons');
    // getUnits(nextPage).then(data => setTrainingUnits(data));

    // const { data, index, itemId } = location.state.next;

    // history.push({
    //   pathname: "/modelbody",
    //   state: {
    //     current: {
    //       chapterData: data[index].chapter_wise_videos,
    //       chapterId: data[index].id,
    //       itemID: itemId,
    //       isCompleted: data[index].is_chapter_completed
    //     },
    //     next: {
    //       data,
    //       index: index + 1,
    //       itemId
    //     }
    //   }
    // });
    setDouble(false);
    setSession(false);
  };
  // const onBackHandler = () => {
  //   history.push('/lessons');
  // };
  const backToCourseEnrol = () => {
    history.push('/lessons');
  };
  const completedID = (id) => {
    if (id) {
      const obj = {
        content_related_chapter: id,
        course: courseId,
        course_content: courseContentId,
      };

      fetch(`${urls.base}/courses/is_chapter_completed/`, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 201) {
            alert.success('Successfully Created');
            return res.json();
          }
          return 0;
        })
        .then((data) => {
          setDouble(data);
        });
    } else {
      alert.warning('Chapter ID empty..!');
    }

    setSession(false);
  };
  let loader = null;
  if (finishingMcqTest) {
    loader = <Loader open />;
  }
  return (
    <>
      <Grid container>
        <Grid item md={1} xs={1} />
        <Grid item md={10} xs={10}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={11} xs={11}>
                <Typography
                  variant="h4"
                  styles={{ color: 'white' }}
                  className={classes.typographyPadding}
                >
                  Chapter Content
                </Typography>
              </Grid>
              <Grid item md={1} xs={1}>
                <IconButton
                  style={{ float: 'right' }}
                  onClick={backToCourseEnrol}
                >
                  {' '}
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
          {/* {chapter && chapter.length != 0 && chapterID && chapter != null ? (
            renderData()
          ) : (
              <Typography variant='h4'>
                Content of this chapter is empty..!
            </Typography>
            )} */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={1} />
        <Grid item md={10} xs={12}>
          {/* <Box border={2} className={classes.paperr}> */}
          {trainingUnits
          && trainingUnits.length !== 0
          && trainingUnits != null ? (
              renderData()
            ) : (
              <Typography variant="h4">
                Content of this chapter is empty..!
              </Typography>
            )}
          {/* </Box> */}
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        {trainingUnits
        && trainingUnits.length !== 0
        // chapterID &&
        // isCompleted == false &&
        && trainingUnits != null ? (
          <Button
            variant="contained"
            disabled={double}
            color="primary"
            style={{ marginBottom: '10px', marginLeft: '40%' }}
            onClick={() => completedID()}
          >
            complete
          </Button>
          ) : (
            ''
          )}
        {/* {trainingUnits &&
        trainingUnits.length != 0 &&
        // chapterID &&
        trainingUnits != null ? (
          <Button
            variant='contained'
            disabled={next}
            color='primary'
            style={{ marginBottom: "10px", marginLeft: "2%" }}
            onClick={onNextHandler}
          >
            Next1
          </Button>
        ) : (
          ""
        )}
        {session ? (
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: "10px", marginLeft: "2%" }}
            onClick={onBackHandler}
          >
            Finish
          </Button>
        ) : (
          ""
        )} */}
        <Button
          variant="contained"
          disabled={next}
          color="primary"
          style={{ marginBottom: '10px', marginLeft: '2%' }}
          onClick={onNextHandler}
        >
          Back
        </Button>
      </Grid>

      {loader}

      {FunctionToTakeMcqTest()}
    </>
  );
};
CoursesUnit.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CoursesUnit);
