/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Checkbox,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './role.style';
import Select from '@material-ui/core/Select';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
// import modal from '../../../hoc/modal';
import urls from '../../../url';

const Role = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [addRole, setAddRole] = useState('');
  const [loading, setloading] = useState(false);
  const [roleType, setRoleType] = useState(false);
  const [user, setUser] = useState('');
  const [role, setRole] = useState('');
  const [mappingID, setMappingId] = useState('');
  const [updateRoleType, setUpdateRoleType] = useState(false);

  const {
    data: roleList,                 //old
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch([]);

  
  const {
    data: DeleteRoleResponse,           //old
    isLoading: deleteRoleLoading,
    doFetch: DeleteRole,
  } = useFetch();
  const {
    data: userList,
    isLoading: userListLoading,
    doFetch: fetchuserList,
  } = useFetch([]);

const {
    data: role_List,
    isLoading: role_ListLoading,
    doFetch: fetchRole_List,
  } = useFetch([]);

  function fetchRolesListFunc() {           
    fetchRoles({
      url: urls.getAllMappedUser,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School')
      },
    });
  }
  function fetchUserListFunc() {
    fetchuserList({
      url: urls.getAllUser,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School')
        // module: localStorage.getItem('Position Mapping')
      },
    });
  }

  function fetchRole_ListFunc() {
    fetchRole_List({
      url: urls.getRole,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School')
        // module: localStorage.getItem('Position Mapping')
      },
    });
  }
  useEffect(() => {
    fetchRolesListFunc();       //old
    fetchUserListFunc();
    fetchRole_ListFunc();

  }, [fetchRoles, DeleteRoleResponse, auth.personal_info.token]);
  // console.log('>>>CheckPoint>>:', JSON.parse(localStorage.getItem('user')));
//   if(roleList.response)
//     console.log("hi ",roleList.response[0][0].id)

  const handleClose = () => {
    setOpen(false);
  };
  const alert = useAlert();
  const renameRole = (i) => {
    setOpen(true);
    setUserId(roleList && roleList.response[i][i].user_id);
    setMappingId(roleList && roleList.response[i][i].id);
    setRoleName((roleList && roleList.response.length && roleList.response[i][i].user_name) || '');
    setUpdateRoleType((roleList && roleList.response.length && roleList.response[i][i].orchids_user) || '');
  };
  const updateRoleFunction = () => {
    if (!roleName) {
      alert.warning('Empty Filled , please Filled All Field');
      return;
    }
    if (open) {
      const data = {
        role: role,
        user: userId,
        // is_academic: updateRoleType,
      };

      setloading(true);
      fetch(`${urls.getAllMappedUser}${mappingID}/details/`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
        module: localStorage.getItem('School')
          // module: localStorage.getItem('Position Mapping')
        },
      })
        .then((res) => {
          if (res.status === 302) {
            setloading(false);
            alert.warning('Role name already exist');
          }
          if (res.status === 200) {
            setloading(false);
            setOpen(false);
            setRole('');
            setUser('');
            setUpdateRoleType(false);
            fetchRolesListFunc();
            alert.success('Successfully Created');
            return res.json();
          }
          if (res.status !== 200 && res.status !== 302) {
            setloading(false);
            alert.warning('somthing went wrong please try again ');
          }
          return 0;
        });
    }
  };
  const deleteRoleFunction = (id) => {
    // console.log('delete data', id);
    DeleteRole({
      url: `${urls.getAllMappedUser}${id}/details/`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School')
        // module: localStorage.getItem('Position Mapping')
      },
    });
  };
  const addRoleFunction = () => {
    // if (!addRole) {
    //   alert.warning('Empty Filled , please Filled All Field');
    //   return;
    // }
    const data = {
      user: user,
    //   is_academic: roleType,
      role:role
    };

    setloading(true);
    fetch(urls.getAllMappedUser, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('School')
        // module: localStorage.getItem('Position Mapping')
      },
    })
      .then((res) => {
        if (res.status === 400) {
          setloading(false);
          alert.warning('Role name already exist');
        }
        if (res.status === 201) {
          setloading(false);
          setUser('');
          setRoleType(false);
          fetchRolesListFunc();
          alert.success('Successfully Created');
          return res.json();
        }
        if (res.status !== 201 && res.status !== 302) {
          setloading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      });
  };

  let loader = null;
  if (roleListLoading || loading || deleteRoleLoading || userListLoading) {
    loader = <Loader open />;
  }
  const roleTableData = () => {
    let td = null;
    if (roleList && roleList.response && roleList.response.length) {
      // console.log('<h3>Role Api hits</h3>');
      td = (
        <>
          <TableBody>
            {roleList.response.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="left">{i + 1}</TableCell>
                <TableCell align="left">{data[i].user_name}</TableCell>
                <TableCell align="left">{data[i].role_name}</TableCell>
                <TableCell align="left">{data[i].orchids_user ? 'Orchids' : 'Non-Orchids'}</TableCell>
                <TableCell align="left">
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => renameRole(i)}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    className={classes.addButton}
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={() => deleteRoleFunction(data[i].id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {loader}
        </>
      );
    }
    return td;
  };

  const handleRoleChange = (data) => {
    //   console.log('data',data.user_id)
    //   console.log('data',data.user_name)
    if(data!==null && data.id!==null)
      setUser(data.user_id)
    // if (data !== null && data.id !== null)
    //   fetchSubjectList({
    //     url: `${urls.getSubjectByGrade}?grade_id=${data.id}`,
    //     method: `GET`,
    //     headers: {
    //       Authorization: `Bearer ${auth.personal_info.token}`,
    //       module: localStorage.getItem('Chapter_Sequence')
    //     },
    //   })

  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Assign Role</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select Name
                    <b style={{ color: 'red' }}>*</b>
                    </Typography>
                <Autocomplete
                    id="Role"
                    options={userList.response}
                    getOptionLabel={(option) => option.user_name}
                    // style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params}  variant="outlined" />}
                    onChange={(e, data) => handleRoleChange(data)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                  <Typography>
                    Select Role
                    <b style={{ color: 'red' }}>*</b>
                    </Typography>
                    <Select
                    id="demo-simple-select-required"
                    labelId="demo-simple-select-required-label"
                    margin="dense"
                    fullWidth
                    defaultValue="User Name"
                    value={role || ''}
                    onChange={(e) => setRole(e.target.value)}
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: 'black',height:'55px' }}
                >
                    {role_List && role_List.length !== 0 && role_List.response.map((data) => (
                    <MenuItem
                        value={data.role_id}
                        key={data.role_id}
                        name={data.role_name}
                    >
                        {data.role_name ? data.role_name : ''}
                    </MenuItem>
                    ))}

                </Select>
                {/* <TextField
                  label="Role Name"
                  margin="dense"
                  fullWidth
                  required
                  value={addRole}
                  onChange={(e) => setAddRole(e.target.value)}
                  variant="outlined"
                /> */}
              </Grid>
              {/* <Grid item md={2} xs={12} style={{marginTop:'12px'}}>
                <span>Is Orchids :</span>
                <Checkbox
                  color="primary"
                  checked={roleType}
                  value={roleType}
                  onChange={(e) => setRoleType(e.target.checked)}
                />
              </Grid> */}
              <Grid item md={4} xs={12} style={{marginTop:'12px'}}>
                <Button
                  className={classes.updateButton}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => addRoleFunction()}
                >
                  Assign Role
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
        <>
          <Paper className={classes.paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Role Name </TableCell>
                  <TableCell align="left">Division</TableCell>
                  <TableCell align="left">Update</TableCell>
                  <TableCell align="left">Delete</TableCell>
                </TableRow>
              </TableHead>
              {roleTableData()}
            </Table>
          </Paper>
        </>
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <>
                <Typography variant="h5">Update Role</Typography>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="Role Name"
                      margin="dense"
                      fullWidth
                      disabled={true}
                      required
                      value={roleName}
                      onChange={(e) => setRoleName(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                  <Typography>
                    Select Role
                    <b style={{ color: 'red' }}>*</b>
                    </Typography>
                    <Select
                    id="demo-simple-select-required"
                    labelId="demo-simple-select-required-label"
                    margin="dense"
                    fullWidth
                    defaultValue="User Name"
                    value={role || ''}
                    onChange={(e) => setRole(e.target.value)}
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: 'black' }}
                >
                    {role_List && role_List.length !== 0 && role_List.response.map((data) => (
                    <MenuItem
                        value={data.role_id}
                        key={data.role_id}
                        name={data.role_name}
                    >
                        {data.role_name ? data.role_name : ''}
                    </MenuItem>
                    ))}

                </Select>
                {/* <TextField
                  label="Role Name"
                  margin="dense"
                  fullWidth
                  required
                  value={addRole}
                  onChange={(e) => setAddRole(e.target.value)}
                  variant="outlined"
                /> */}
                </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      className={classes.updateButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateRoleFunction()}
                    >
                      Update Role
                    </Button>
                  </Grid>
                </Grid>
              </>
            </div>
          </Fade>
        </Modal>
      </div>
      {loader}
    </>
  );
};
Role.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(Role);
