export default (theme) => ({
  inductionForm: {},
  formColor: {
    color: '#b75c4a',
  },
  selectBranch: {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: "center",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    flexDirection: 'column',
  },
  selectInput: {
    padding: '0.7rem 2rem',
    backgroundColor: '#4054b5',
    border: '1px solid #4054b5',
    borderRadius: '0.4rem',
    cursor: 'pointer',
    color: 'white',
    width: '50%',
  },
  inductionFormSubmit: {
    padding: '0.5rem 1rem',
    backgroundColor: '#4054b5',
    color: 'white',
    border: '1px solid #4054b5',
    fontWeight: 'bold',
    fontSize: '1rem',
    borderRadius: '0.2rem',
    marginTop: '1rem',
    cursor: 'pointer',
  },
  root: {
    '& > *': {
      //   margin: theme.spacing(1)
    },
  },
  input: {
    display: 'none',
  },
  allVideos: {
    display: 'grid',
    marginTop: '1rem',
    gridTemplateColumns: 'repeat(4 , 1fr)',
    gridGap: '1rem',
    width: '100%',
    height: 'auto',
  },
  eachCard: {
    border: '1px solid black',
    padding: '1rem',
  },
  btn: {
    padding: '0.5rem 1rem',
    borderRadius: '2px',
    border: '1px solid transparent',
    color: 'white',
    backgroundColor: '#4054b5',
    cursor: 'pointer',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});
