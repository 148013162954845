/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './inHouseLearning.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import axios from 'axios';


const InHouseLearningVideos = ({ classes, history }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [learningList, setLearningList] = useState();
  const [Loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const alert = useAlert();
  const [permission,setPermission]=useState([]);


  function getData() {
    setLoading(true);
    fetch(`${urls.learningVideosApi}?page_size=${rowsPerPage || 10}&page=${page + 1}&type=1`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Learnig_Videos')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Data Received');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      }).then((data) => {
        setLearningList(data);
      });
  }

  let loader = null;
  if (Loading) {
    loader = <Loader open />;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth) {
      getData();
    }
  }, [auth.personal_info.token, page, rowsPerPage]);

     // For Permissions
     function getPermissonData(id) {
      axios.get(urls.getPermissons,{
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: id
        },
      }).then(response => {
        setPermission(response.data.response)
      }).catch(error => {
        console.log(error);
      })
    }
  
    useEffect(() => {
      getPermissonData(localStorage.getItem('Learnig_Videos'))
    }, [])
  

  function handelOpenFullView(data) {
    sessionStorage.setItem('learningVideoFullData', JSON.stringify(data));
    history.push('/learning_full_view');
  }

  return (
    <>
      <div className={classes.LearningModule} style={{ height: learningList && learningList.results && learningList.results.length < 10 ? '100vh' : '100%' }}>
        <Typography variant="h4" className={classes.typographys}>LEARNING VIDEOS</Typography>
        <Divider className={classes.divider} />
        <Grid container className={classes.paper2}>
          <Grid item md={12} sm={12} xs={12}>
            {learningList && learningList.results && learningList.results.length === 0
        && (
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}> No learning videos are added</Typography>
        )}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {learningList && learningList.results && learningList.results.length !== 0
        && (
        <>
          <Grid container spacing={2}>
            {learningList && learningList.results && learningList.results.length > 0
            && learningList.results.map((item, index) => {
              const colors = [
                '#54688c',
                '#f47a62',
                '#4a66da',
                '#75cba8',
                '#f2bf5e',
              ];
              const diffColors = index % 4;
              return (
                <Grid item md={3} sm={3} xs={12} key={item.id}>
                  <Grid container>
                    <Paper className={classes.paper} style={{ backgroundColor: colors[diffColors] }}>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                          <Typography variant="h6" style={{ color: 'white' }}>
                            {item.title || ''}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Button style={{ color: 'white', borderColor: 'white' }} variant="outlined" onClick={() => handelOpenFullView(item)}>Start</Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </>
        )}
          </Grid>
          <Grid item md={12} xs={12}>
            {learningList && learningList.results && learningList.results.length > 0
            && (
            <Table className={classes.paper1}>
              <TableBody>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() => `Page ${page + 1} of ${+learningList.total_pages}`}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    count={+learningList.count}
                    rowsPerPage={rowsPerPage || 10}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: '13px' }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(learningList.total_pages - 1)}
                      disabled={page === +learningList.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            )}
          </Grid>
        </Grid>
        {loader}
      </div>
    </>
  );
};
InHouseLearningVideos.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InHouseLearningVideos);
