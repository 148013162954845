/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  IconButton,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useAlert } from '../../../hoc/alert/alert';
import styles from './BranchwiseVideos.style';
import urls from '../../../url';
import axios from 'axios';


function BranchwiseVideos({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [branchSubmit, setBranchSubmit] = useState('');
  const [branch, setBranch] = useState([]);
  const [video, setVideo] = useState('');
  const [newBranchVideo, setNewBranchVideo] = useState('');
  const [allBranchVideos, setAllBranchVideo] = useState();
  const [open, setOpen] = useState(false);
  const [updateVideo, setUpdateVideo] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [deleted, setDeleted] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [updatedRes, setUpdatedRes] = useState('');
  const [permission, setPermission] = useState([]);


  const handleClickOpen = (videoName, branchName, totalId, branchId) => {
    setOpen(true);
    localStorage.setItem('videoFileName', videoName);
    localStorage.setItem('branchName', branchName);
    localStorage.setItem('totalId', totalId);
    localStorage.setItem('branchId', branchId);
  };

  const alert = useAlert();
  const handleClose = () => {
    setOpen(false);
    setUpdateVideo('');
  };

  const handleDeleteClick = async (id) => {
    setLoader(true);

    const response = await fetch(
      `${urls.branchVideosApi}?id=${id}`,

      // &page_size=${rowsPerPage ||
      //   5}&page=${page + 1}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Branch_Wise_Videos')
        },
      },
    );

    
    const getDataInfo = await response.json();
    // console.log(getDataInfo);
    // setTotalPages(getDataInfo.total_pages);

    setDeleted(getDataInfo);
    setLoader(false);

    // return getDataInfo;
  };

  function handleChangePage(event, newPage) {
    setRefresh(true);
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(5);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRefresh(true);
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setRefresh(true);
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setRefresh(true);
    setPage(lastPage);
  }

  async function getData(url) {
    setLoader(true);
    const dataResult = fetch(url, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Branch_Wise_Videos')
      },
    });
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    return finalData;
  }

  useEffect(() => {
    getData(urls.getBranchNames).then((data) => {
      setBranch(data);
    });
  }, []);

   // For Permissions
   function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Branch_Wise_Videos'))
  }, [])

  const getBranchWiseVideos = async (id) => {
    setLoader(true);

    const response = await fetch(
      `${urls.branchWiseVideosApi}?branch_id=${id}&page_size=${rowsPerPage
        || 5}&page=${page + 1}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Branch_Wise_Videos')
        },
      },
    );
    const getDataInfo = await response.json();
    // allBranchVideos;
    // console.log(getData);
    // setTotalPages(getDataInfo.total_pages);

    setLoader(false);

    return getDataInfo;
  };

  const newBranchVideoSubmit = async () => {
    if (!branchSubmit) {
      alert.warning('Select Branch Name');
      return 0;
    }
    setLoader(true);
    const formData = new FormData();
    formData.append('file', video);
    formData.append('branch', branchSubmit);
    formData.append('topic', Math.floor(Math.random() * 10000));
    const response = await fetch(urls.branchVideosApi, {
      method: 'POST', // or 'PUT'
      body: formData, // data can be `string` or {object}!
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Branch_Wise_Videos')
      },
    });
    const getDataiNFO = await response.json();
    // setBranchSubmit("");
    setLoader(false);
    getBranchWiseVideos(branchSubmit).then((data) => {
      setAllBranchVideo(data);
    });
    setVideo('');
    return getDataiNFO;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    newBranchVideoSubmit().then((data) => setNewBranchVideo(data.results));
  };

  const handleModalForm = async (e) => {
    if (!branchSubmit) {
      alert.warning('Select Branch Name');
      return 0;
    }
    e.preventDefault();
    setLoader(true);

    const totalId = localStorage.getItem('totalId');
    const formData = new FormData();
    if (typeof (updateVideo) === 'object') {
      formData.append('file', updateVideo);
    }
    formData.append('id', totalId);
    formData.append('branch', localStorage.getItem('branchId'));
    formData.append('topic', Math.floor(Math.random() * 10000));
    const response = await fetch(`${urls.branchVideosApi}`, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Branch_Wise_Videos')
      },
    });
    const getDataInfo = await response.json();
    setLoader(false);
    setOpen(false);
    getBranchWiseVideos(branchSubmit).then((data) => {
      setAllBranchVideo(data);
    });
    setUpdatedRes(getDataInfo);
    return getDataInfo;
  };

  // const getBranchVideos = async () => {
  //   const formData = new FormData();
  //   formData.append('file', video);
  //   formData.append('branch', branchSubmit);
  //   const response = await fetch(urls.branchVideosApi, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   });
  //   const getData = await response.json();
  //   setBranchSubmit('');
  //   setVideo('');
  //   // console.log(getData.total_pages);
  //   return getData;
  // };

  function videoUpload(data) {
    setVideo(data);
  }

  function videoUpdateUpload(data) {
    setUpdateVideo(data);
  }

  useEffect(() => {
    if (refresh === true || newBranchVideo || deleted || updatedRes) {
      getBranchWiseVideos(branchSubmit).then((data) => {
        setAllBranchVideo(data);
      });
    }
  }, [newBranchVideo, page, rowsPerPage, deleted, updatedRes]);

  const handleBranchChange = (event) => {
    setBranchSubmit(event.target.value);
    getBranchWiseVideos(event.target.value).then((data) => {
      setAllBranchVideo(data);
    });
  };

  return (
    <>
      {loader === true && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress />
        </Backdrop>
      )}
      {permission.can_view ? 
      <form className={classes.inductionForm} onSubmit={handleSubmit}>
        <div className={classes.selectBranch}>
          <Typography variant="h5" style={{ width: '40%' }}>
            Branch
          </Typography>
          <select
            id="contained-button-fileData"
            className={classes.selectInput}
            onChange={handleBranchChange}
            defaultValue="DEFAULT"
          >
            <option disabled value="DEFAULT">
              Select Branch
            </option>
            {branch
              && branch.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.branch_name}
                </option>
              ))}
          </select>
        </div>
        {permission.can_import ? 
        <>
        <h2 style={{ width: '40%', margin: '0px' }}>Upload Video</h2>
        <div className={classes.root} id="uploadVideo">
          <input
            accept="video/mp4"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(e) => videoUpload(e.target.files[0])}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload Video
            </Button>
            <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
              {video && video.name}
            </span>
          </label>
        </div>
        <button type="submit" className={classes.inductionFormSubmit}>
          Submit
        </button>
        </>
        :null}
      </form>
      :null}
      <div className={classes.allVideos}>
        {allBranchVideos
          && allBranchVideos.results.map((item) => (
            <div key={item.id} className={classes.eachCard}>
              <video width="100%" controls controlsList="nodownload">
                <source src={item.file} type="video/mp4" />
                <track
                  src={item.file}
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                />
                Your browser does not support the video tag.
              </video>
              <p>
                <span style={{ fontWeight: 'bold' }}>Branch Name : </span>
                {item.branch.branch_name}
              </p>
              {permission.can_import ? 
              <>
              <button
                type="submit"
                className={classes.btn}
                onClick={() => handleClickOpen(
                  item.file,
                  item.branch.branch_name,
                  item.id,
                  item.branch.id,
                )}
              >
                Update
              </button>
              &nbsp;
              <button
                type="submit"
                className={classes.btn}
                onClick={() => handleDeleteClick(item.id)}
              >
                Delete
              </button>
              </>
              :null}

            </div>
          ))}

        <div>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Update The Video
            </DialogTitle>
            <DialogContent>
              <form
                className={classes.inductionForm}
                id="modalForm"
                onSubmit={(e) => handleModalForm(e, localStorage.getItem('branchId'))}
              >
                <div className={classes.root} id="updateVideo">
                  <input
                    accept="video/mp4"
                    className={classes.input}
                    id="contained-button-file1"
                    type="file"
                    onChange={(e) => videoUpdateUpload(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file1">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      component="span"
                      startIcon={<CloudUploadIcon />}
                    >
                      Update Video
                    </Button>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginTop: '15px',
                        display: 'block',
                      }}
                    >
                      <span style={{ display: 'block' }}>
                        Updating Video :
                        {' '}
                        {updateVideo ? updateVideo.name : 'No Video Selected'}
                      </span>
                      <span>
                        Present Video &nbsp;:
                        {' '}
                        {localStorage.getItem('videoFileName')}
                      </span>
                    </span>
                  </label>
                </div>
                <button type="submit" className={classes.inductionFormSubmit}>
                  Submit
                </button>
              </form>
            </DialogContent>
            <DialogActions />
          </Dialog>
        </div>
      </div>
      {allBranchVideos && allBranchVideos.results.length !== 0 && (
        <Table>
          <TableBody>
            <TableRow>
              <TablePagination
                colSpan={6}
                labelDisplayedRows={() => `Page ${page
                  + 1} of ${+allBranchVideos.total_pages}`}
                rowsPerPageOptions={[5, 8, 20, 30]}
                count={+allBranchVideos.count}
                rowsPerPage={rowsPerPage || 5}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <TableCell style={{ marginTop: '13px' }}>
                <IconButton
                  onClick={firstPageChange}
                  disabled={page === 0 || page === 1}
                >
                  <FirstPageIcon />
                </IconButton>
                <IconButton
                  onClick={() => lastPageChange(
                    allBranchVideos.total_pages - 1,
                  )}
                  disabled={
                  page === +allBranchVideos.total_pages - 1
                }
                >
                  <LastPageIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
}
BranchwiseVideos.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(BranchwiseVideos);
