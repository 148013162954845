/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Fade,
  Backdrop,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './categorySubType.Style';
import urls from '../../../url';
import axios from 'axios'
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';

const CategorySubType = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [categorySubType, setCategorySubType] = useState('');
  const [open, setOpen] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [loading, setloading] = useState(false);
  const [subjectUpdate, setSubjectUpdate] = useState('');

  const {
    data: getCourseCategoryType,
    isLoading: gettingCategoryType,
    doFetch: fetchToCreateCategoryType,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);


  useEffect(() => {
    // module=localStorage.getItem('Subject')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Subject')	
      },
    });
  }, [])

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function functionToGetSubCategories() {
    fetchToCreateCategoryType({
      url: `${urls.addCourseCategorySubTypeApi}?page_size=${rowsPerPage
        || 10}&page=${page + 1}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Subject')
      },
    });
  }

  useEffect(() => {
    if (auth) {
      functionToGetSubCategories();
      setCategorySubType('');
    }
  }, [auth, rowsPerPage, page]);

  const alert = useAlert();

  let loader = null;
  if (loading || gettingCategoryType || modulePermissionLoading) {
    loader = <Loader open />;
  }

  function addCategotySubType() {
    if (!categorySubType) {
      alert.warning('Fill the Fields');
      return;
    }
    const data = {
      sub_type_name: categorySubType,
    };
    setloading(true);
    fetch(`${urls.addCourseCategorySubTypeApi}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Subject')
      },
    }).then((res) => {
      if (res.status === 400) {
        setloading(false);
        setCategorySubType('');
        alert.warning(' Subject already exist');
      }
      if (res.status === 201) {
        setloading(false);
        setOpen(false);
        setCategorySubType('');
        functionToGetSubCategories();
        alert.success('Successfully Created');
        return res.json();
      }
      if (res.status !== 201 && res.status !== 400) {
        setloading(false);
        setCategorySubType('');
        alert.warning('somthing went wrong please try again ');
      }
      return 0;
    });
  }

  function functionToEditCategorySubType(id) {
    setOpen(true);
    setTypeId(
      getCourseCategoryType
        && getCourseCategoryType.results
        && getCourseCategoryType.results[id].id,
    );
    setSubjectUpdate(
      getCourseCategoryType
        && getCourseCategoryType.results
        && getCourseCategoryType.results[id].sub_type_name,
    );
  }

  const handleDeleteSubject = (id) =>{
    setloading(true)
    axios.delete(`${urls.deleteSubject}${id}`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Subject')
      },
    })
    .then(response =>{
      functionToGetSubCategories();
      setloading(false)
      alert.success('Course deleted successfully ')
    }).then(error =>{
      setloading(false)
      alert.error('Something went wrong please try again !!!')
    })
  }

  const handleClose = () => {
    setOpen(false);
    setSubjectUpdate('');
  };

  function functionToUpdateCategorySubType() {
    if (!subjectUpdate) {
      alert.warning('Fill the Fields');
      return;
    }
    const data = {
      sub_type_name: subjectUpdate,
    };
    setloading(true);
    fetch(`${urls.apiForUpdateCourseSubType}${typeId}/course_sub_type/`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Subject')
      },
    }).then((res) => {
      if (res.status === 400) {
        setloading(false);
        setCategorySubType('');
        alert.warning('Subject already exist');
      }
      if (res.status === 200) {
        setloading(false);
        setOpen(false);
        setCategorySubType('');
        functionToGetSubCategories();
        alert.success('Successfully Created');
        return res.json();
      }
      if (res.status !== 200 && res.status !== 400) {
        setloading(false);
        setCategorySubType('');
        alert.warning('somthing went wrong please try again ');
      }
      return 0;
    });
  }

  const categoryTableData = () => {
    let td = null;
    if (getCourseCategoryType && getCourseCategoryType.results.length) {
      td = (
        <>
          <TableBody>
            {getCourseCategoryType.results.map((data, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={i}>
                <TableCell align="left">{i + 1}</TableCell>
                <TableCell align="left">{data.sub_type_name}</TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ? 
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => functionToEditCategorySubType(i)}
                  >
                    Update
                  </Button>: null}
                </TableCell>

                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ? 
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => handleDeleteSubject(data.id)}
                  >
                    Delete
                  </Button>: null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {loader}
        </>
      );
    }
    return td;
  };

  return (
    <>
      <Typography variant="h5">Add Subject</Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              className={classes.textField}
              label="Enter Subject"
              margin="dense"
              required
              value={categorySubType}
              onChange={(e) => setCategorySubType(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            {modulePermission && modulePermission.response.can_add ?
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => addCategotySubType()}
            >
              Add Subject
            </Button>: null}
          </Grid>
        </Grid>
      </Paper>
      {getCourseCategoryType && getCourseCategoryType.results.length && (
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h6">S.No</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6">Subject</Typography>
                </TableCell>
                <TableCell align="left">
                {modulePermission && modulePermission.response.can_update ?
                  <Typography variant="h6">Edit</Typography>:null}
                </TableCell>
                <TableCell align="left">
                {modulePermission && modulePermission.response.can_update ?
                  <Typography variant="h6">Delete</Typography>:null}
                </TableCell>
              </TableRow>
            </TableHead>
            {categoryTableData()}
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+getCourseCategoryType.total_pages}`}
                  rowsPerPageOptions={[10, 20, 30]}
                  count={+getCourseCategoryType.count}
                  rowsPerPage={rowsPerPage || 10}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(getCourseCategoryType.total_pages - 1)}
                    disabled={page === +getCourseCategoryType.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      )}

      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.updaatepaper}>
              <>
                <Typography variant="h5">Update Subject</Typography>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      label="Update subject"
                      margin="dense"
                      required
                      value={subjectUpdate || ''}
                      onChange={(e) => setSubjectUpdate(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} />
                  <Grid item md={4} xs={12}>
                    <Button
                      className={classes.updateButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => functionToUpdateCategorySubType()}
                    >
                      Update subject
                    </Button>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </>
            </div>
          </Fade>
        </Modal>
      </>
      {loader}
    </>
  );
};
CategorySubType.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CategorySubType);
