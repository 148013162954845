export default () => ({
  chapterBackground: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#055184',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    position: 'relative',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
  },
  moduleCover: {
    width: '70%',
    height: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  moduleCards: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0.5rem 0.5rem 0',
    padding: ' 2rem',
  },
  firstHead: {
    fontSize: '1.6rem',
    margin: '0.5rem',
    textAlign: 'center',
  },
  secondHead: {
    fontSize: '0.9rem',
    margin: '0.5rem 0.5rem 1rem 0.5rem',
  },
  linkDecor: {
    textDecoration: 'none',
    color: 'white',
  },
});
