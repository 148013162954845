/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  //   Table,
  //   TableBody,
  //   TableCell,
  //   TableHead,
  //   TableRow,
  Paper,
  Box,
  //   Modal,
  //   Fade,
  //   Backdrop,
  //   IconButton,
  TextField,
  //   TableFooter,
  //   TablePagination,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  InputLabel,
  ListItemIcon,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import styles from "./consolidatedReport.style";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let status;
let link1;
let link2;
let link3;
let link4;
let finalUrl;
let schoolArray = [];
const ConsolidatedReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [checkLoader, setCheckLoader] = useState(false);
  //   const [open, setOpen] = React.useState(false);
  //   const [rowsPerPage, setRowsPerPage] = React.useState(null);
  //   const [page, setPage] = React.useState(0);

  const [role, setRole] = useState([]);
  const [branch, setBranch] = useState([]);
  const [school, setSchool] = useState([]);

  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const alert = useAlert();
  const [permission, setPermission] = useState([]);
  const [SelectAll, setSelectedAll] = useState("");
  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [trainingType, setTrainingType] = useState("");
  const [bottomHRef, setBottomHRef] = useState("");
  const [isSingleAttempted, setIsSingleAttempted] = useState(false);


  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const [trainingTypeList] = useState([
    {
      id: "1",
      name: "Induction Training",
    },
    {
      id: "4",
      name: "Subject Training",
    },
    {
      id: "3",
      name: "Trainer Driven",
    },
    {
      id: "2",
      name: "Self Driven",
    },
  ]);
  // const verifyEmail = auth
  // && auth.academic_profile
  // && auth.academic_profile.user.email;

  // const verify=verifyEmail.includes("@orchids.edu.in")
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  var verify = loginData.role_permission.is_orchids;

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);

  const {
    data: schoolList,
    isLoading: schoolListLoading,
    doFetch: fetchSchool,
  } = useFetch(null);

  const {
    data: GradeList,
    isLoading: gradeListLoading,
    doFetch: fetchGradeList
  } = useFetch([]);

  const {
    data: subjectList,
    isLoading: subjectLoading,
    doFetch: fetchSubjectList
  } = useFetch([])


  useEffect(() => {
    fetchSchool({
      url: urls.editSchoolApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("School_Wise_Report"),
      },
    });
    //kept for future condition
    // link1=`${urls.getRoleRegistration}?search=orchids&user_type=USER`
    // link2=`${urls.getRoleRegistration}?search=other&user_type=USER`
    // link3=`${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`
    // link4=`${urls.getRoleRegistration}?search=other&user_type=ADMIN`

    // finalUrl = localStorage.getItem("userType")==="USER" ? verify ? link1 : link2 :
    // localStorage.getItem("userType")==="ADMIN" ? verify ? link3 :link4 :null

    fetchRoles({
      url: `${urls.getRoleRegistration}?user_type=USER`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Consolidated_Report"),
      },
    });

    fetchGradeList({
      url: `${urls.grades}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Consolidated_Report')
      },
    });

    const schoolArr = [];
    for (let i = 0; i < school.length; i += 1) {
      schoolArr.push(
        schoolList && schoolList.filter((item) => item.name === school[i])[0].id
      );
    }

    fetchBranches({
      url: `${urls.branchApi}?school_id=${schoolArr}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Consolidated_Report"),
      },
    });
  }, [
    fetchRoles,
    fetchBranches,
    auth.personal_info.token,
    fetchSchool,
    school,
  ]);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // console.log(schoolArr, "schoolList");

  const sendEmail = () => {
    if (!branch || branch.length === 0) {
      alert.warning("Select Branch");
      return;
    }
    if (!role || role.length === 0) {
      alert.warning("Select Role");
      return;
    }
    // if (!trainingType) {
    //   alert.warning('Select Training Type'); return;
    // }
    if (
      !email ||
      !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
        email
      )
    ) {
      alert.warning("Email Can't be empty");
      return;
    }
    const roleArr = [];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList &&
        roleList.response.filter((item) => item.role_name === role[i])[0]
          .role_id
      );
    }
    const gradeArr = [];
    for (let i = 0; i < grade.length; i += 1) {
      gradeArr.push(
        GradeList &&
        GradeList.filter((item) => item.type_name === grade[i])[0].id
      );
    }

    const subjectArr = [];
    for (let i = 0; i < subject.length; i += 1) {
      subjectArr.push(
        subjectList &&
        subjectList.data.filter((item) => item.subject_name === subject[i])[0].id
      );
    }
    const schoolArr = [];
    for (let i = 0; i < school.length; i += 1) {
      schoolArr.push(
        schoolList && schoolList.filter((item) => item.name === school[i])[0].id
      );
    }
    async function loading() {
      setCheckLoader(true);

      const response = await fetch(
        `${urls.consolidatedReportSendMailApi}?branch=${[branch]}&role=${[
          roleArr,
        ]}&school=${[
          schoolArr,
        ]}&grade=${[gradeArr]}&subject=${[subjectArr]}&training_type=${trainingType}&email=${email}&start_date=${startDate}&end_date=${endDate}&download=False&first_attempt=${isSingleAttempted}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Consolidated_Report"),
          },
        }
      );
      const res = await response.json();
      status = response.status;
      return res;
    }
    loading()
      .then(() => {
        // console.log('harsha',res)
        if (status === 200) {
          setCheckLoader(false);
          alert.success(`Your consolidated report has been sent to ${email}.`);
          // eslint-disable-next-line no-console
        }
        if (status === 404) {
          setCheckLoader(false);
          alert.warning(`Data not found on ${email}.`);
        }
        if (status === 400) {
          setCheckLoader(false);
          alert.warning(`Bad Request`);
        }
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.warning(`Error:${error}`);
      });

    setBranch([]);
    setGrade([]);
    setSubject([]);
    setEmail("");
    setRole([]);
    setSchool([]);
    setTrainingType("");
    setStartDate(null);
    setEndDate(null);
  };

  let loader = null;
  if (roleListLoading || branchListLoading || checkLoader) {
    loader = <Loader open />;
  }

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem("Consolidated_Report"));
  }, []);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };

  function handleSelectALl(e) {
    if (SelectAll === true && e.target.value.length - 1 === branchList.length) {
      setSelectedAll(false);
      setBranch([]);
      return;
    }
    if (e.target.value.length !== 0) {
      if (
        e.target.value.filter((data) => data === "0").length === 1 &&
        SelectAll === false
      ) {
        const setarray = [];
        for (let i = 0; i < branchList.length; i += 1) {
          setarray.push(branchList[i].id);
        }
        setSelectedAll(true);
        setBranch(setarray);
      } else {
        setBranch(e.target.value);
        setSelectedAll(false);
      }
    } else {
      setBranch(e.target.value);
      setSelectedAll(false);
    }
  }

  const isAllSelected =
    schoolList?.length > 0 && school?.length === schoolList?.length;

  const isAllRoleSelected = roleList?.response?.length > 0 && role?.length === roleList?.response?.length;

  console.log(
    isAllSelected,
    school?.length,
    schoolList?.length,
    "isAllSelected"
  );

  const handleChange = (event) => {
    const value = event.target.value;
    // console.log(value[value.length - 1], "handleChange");
    const sourseList = schoolList.map((item) => item?.name);
    if (value[value.length - 1] === "all") {
      setSchool(school?.length === schoolList?.length ? [] : sourseList);
      return;
    }

    setSchool(value);
  };

  const handleSelectAllRole = (event) => {
    const value = event.target.value;
    // console.log(value[value.length - 1], "handleChange");
    const sourseList = roleList.response.map((item) => item?.role_name);
    if (value[value.length - 1] === "all") {
      setRole(role?.length === roleList.response?.length ? [] : sourseList);
      return;
    }
    setRole(value);
  };

  const isAllGradeSelected = GradeList?.length > 0 && grade?.length === GradeList?.length

  const handleGradeChange = (event) => {
    const value = event.target.value;
    const gradeLists = GradeList.map((item) => item?.type_name);
    if (value[value.length - 1] === "all") {
      setGrade(grade?.length === GradeList?.length ? [] : gradeLists);
      return;
    }
    setGrade(value);
    setSubject([]);
  }

  useEffect(() => {
    if (grade.length > 0) {
      const gradeArr = [];
      for (let i = 0; i < grade.length; i += 1) {
        gradeArr.push(
          GradeList &&
          GradeList.filter((item) => item.type_name === grade[i])[0].id
        );
      }
      fetchSubjectList({
        url: `${urls.getMultipleSubjectsByGrade}?grade_id=${gradeArr}`,
        method: `GET`,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Consolidated_Report')
        },
      })
    }
  }, [grade])
  const isAllSubjectsSelected = subjectList.data?.length > 0 && subject?.length === subjectList.data?.length

  const handleSubjectChange = (event) => {
    const value = event.target.value;
    const subjectLists = subjectList.data.map((item) => item?.subject_name);
    if (value[value.length - 1] === "all") {
      setSubject(grade?.length === subjectList?.length ? [] : subjectLists);
      return;
    }
    setSubject(value);
  }

  useEffect(() => {
    if (!branch || branch.length === 0) {
      // alert.warning("Select Branch");
      return;
    }
    if (!role || role.length === 0) {
      // alert.warning("Select Role");
      return;
    }
    if (!trainingType) {
      // alert.warning("Select Training Type");
      return;
    }
    const roleArr = [];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList &&
        roleList.response.filter((item) => item.role_name === role[i])[0]
          .role_id
      );
    }

    const gradeArr = [];
    for (let i = 0; i < grade.length; i += 1) {
      gradeArr.push(
        GradeList &&
        GradeList.filter((item) => item.type_name === grade[i])[0].id
      );
    }

    const subjectArr = [];
    for (let i = 0; i < subject.length; i += 1) {
      subjectArr.push(
        subjectList &&
        subjectList.data.filter((item) => item.subject_name === subject[i])[0].id
      );
    }
    setBottomHRef([
      {
        csv: `${urls.consolidatedReportSendMailApi}?branch=${[branch]}&role=${[
          roleArr,
        ]}&training_type=${trainingType}&email=${email}&grade=${gradeArr}&subject=${subjectArr}&start_date=${startDate}&end_date=${endDate}&download=True&first_attempt=${isSingleAttempted}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Consolidated_Report')}&export_type=csv`
      },
    ]);
  }, [branch, role, trainingType, grade, subject, isSingleAttempted])

  function handleClearAll() {
    setBranch([]);
    setEmail("");
    setRole([]);
    setTrainingType("");
    setGrade([]);
    setSubject([]);
    setSchool([]);
    setIsSingleAttempted(false);
  }

  function handleAttemptsCheck(){
    setIsSingleAttempted(!isSingleAttempted);
  }



  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Consolidated Report </Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container item spacing={1}>
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select School
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="mutiple-select-label">
                        Select School
                      </InputLabel> */}

                    <Select
                      margin="dense"
                      fullWidth
                      value={school || []}
                      // onChange={(e) => setSchool(e.target.value)}
                      onChange={handleChange}
                      // onChange={(e) => handleSelectALlSchool(e)}
                      multiple
                      className={classes.textField}
                      variant="outlined"
                      style={{ color: "black", width: "400px" }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key="all" value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllSelected}
                            indeterminate={
                              school.length > 0 &&
                              school.length < schoolList.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {schoolList &&
                        schoolList.length !== 0 &&
                        schoolList.map((data) => (
                          <MenuItem
                            value={data.name}
                            key={data.id}
                            name={data.name}
                          >
                            <Checkbox
                              checked={school.indexOf(data.name) > -1}
                            />
                            <ListItemText primary={data.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4}>
                    <Button>2</Button>
                  </Grid> */}
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select Branch
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <Select
                      margin="dense"
                      fullWidth
                      multiple
                      value={branch || []}
                      // onChange={(e) => setBranch(e.target.value)}
                      onChange={(e) => handleSelectALl(e)}
                      className={classes.textField}
                      variant="outlined"
                      style={{ color: "black", width: "400px" }}
                    >
                      <MenuItem key="0" value="0">
                        Select All
                      </MenuItem>
                      {branchList &&
                        branchList.length !== 0 &&
                        branchList.map((data) => (
                          <MenuItem
                            value={data.id}
                            key={data.id}
                            name={data.branch_name}
                          >
                            {data.branch_name ? data.branch_name : ""}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select Role
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <Select
                      margin="dense"
                      fullWidth
                      multiple
                      value={role || []}
                      isRequired
                      onChange={handleSelectAllRole}
                      className={classes.textField}
                      variant="outlined"
                      style={{ color: "black", width: "400px" }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key="all" value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllRoleSelected}
                            indeterminate={
                              role.length > 0 &&
                              role.length < roleList.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {roleList &&
                        roleList.length !== 0 &&
                        roleList.response.map((data) => (
                          <MenuItem
                            value={data.role_name}
                            key={data.role_id}
                            name={data.role_name}
                          >
                            <Checkbox
                              checked={role.indexOf(data.role_name) > -1}
                            />
                            <ListItemText primary={data.role_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4}>
                    <Button>5</Button>
                  </Grid> */}
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select Grade
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <Select
                      margin="dense"
                      fullWidth
                      value={grade || []}
                      onChange={handleGradeChange}
                      multiple
                      className={classes.textField}
                      variant="outlined"
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{ color: "black", width: "400px" }}
                    >
                      <MenuItem key="all" value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllGradeSelected}
                            indeterminate={
                              role.length > 0 && role.length < GradeList.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {GradeList &&
                        GradeList.length !== 0 &&
                        GradeList.map((data) => (
                          <MenuItem
                            value={data.type_name}
                            key={data.id}
                            name={data.type_name}
                          >
                            <Checkbox
                              checked={grade.indexOf(data.type_name) > -1}
                            />
                            <ListItemText primary={data.type_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select Subject
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <Select
                      margin="dense"
                      fullWidth
                      value={subject || []}
                      onChange={handleSubjectChange}
                      multiple
                      className={classes.textField}
                      variant="outlined"
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{ color: "black", width: "400px" }}
                    >
                      <MenuItem key="all" value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllSubjectsSelected}
                            indeterminate={
                              subject.length > 0 && subject.length < subjectList.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {subjectList &&
                        subjectList.length !== 0 &&
                        subjectList.data.map((data) => (
                          <MenuItem
                            value={data.subject_name}
                            key={data.id}
                            name={data.subject_name}
                          >
                            <Checkbox
                              checked={subject.indexOf(data.subject_name) > -1}
                            />
                            <ListItemText primary={data.subject_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>
                    Select Training
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <Select
                      margin="dense"
                      fullWidth
                      value={trainingType || ""}
                      onChange={(e) => setTrainingType(e.target.value)}
                      className={classes.textField}
                      variant="outlined"
                      style={{ color: "black", width: "400px" }}
                    >
                      {trainingTypeList &&
                        trainingTypeList.length !== 0 &&
                        trainingTypeList.map((data) => (
                          <MenuItem
                            value={data.id}
                            key={data.id}
                            name={data.name}
                          >
                            {data.id ? data.name : ""}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>
                    Enter Email Address
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    label=""
                    margin="dense"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    style={{ color: "black", width: "400px" }}
                  />
                  {email &&
                    !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                      email
                    ) ? (
                    <FormHelperText
                      id="component-error-text"
                      style={{ color: "red" }}
                    >
                      Invalid Email Address
                    </FormHelperText>
                  ) : null}
                  {email &&
                    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                      email
                    ) ? (
                    <FormHelperText
                      id="component-error-text"
                      style={{ color: "green" }}
                    >
                      Valid Email Address
                    </FormHelperText>
                  ) : null}
                </Grid>
                {/* <Grid item xs={4}>
                    <Button>8</Button>
                  </Grid> */}
                <Grid item md={4} xs={12}>
                  <Typography>Start Date</Typography>
                  <TextField
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => handleStartDateChange(e.target.value)}
                    type="date"
                    value={startDate || ""}
                    variant="outlined"
                    style={{ color: "black", width: "400px" }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>End Date</Typography>
                  <TextField
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => handleEndDateChange(e.target.value)}
                    type="date"
                    value={endDate || ""}
                    variant="outlined"
                    style={{ color: "black", width: "400px" }}
                  />
                </Grid>
                <Grid md={2} xs={12}>
                  <FormControlLabel
                  className={classes.textField}
                  style={{ marginTop: "33px" }}
                   control={
                   <Checkbox
                    checked={isSingleAttempted}
                    onChange={handleAttemptsCheck}
                  />}
                    label="First Attempt Report"
                     />
                </Grid>
                <Grid item md={1} xs={12}>
                  <Button
                    style={{ marginTop: "33px" }}
                    className={classes.updateButton}
                    variant="contained"
                    color="primary"
                    fullWidth
                    href={bottomHRef && bottomHRef[0].csv}
                    disabled={(branch && role && trainingType && grade && subject) ? false : true}
                  >
                    Download
                  </Button>
                </Grid>
                <Grid item md={1} xs={12}>
                  {permission.can_send ? (
                    <Button
                      style={{ marginTop: "33px" }}
                      className={classes.updateButton}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => sendEmail()}
                      disabled={(email && trainingType && grade && subject && branch && role) ? false : true}
                    >
                      Send
                    </Button>
                  ) : null}
                </Grid>
                <Grid item md={1} xs={12}>
                  <Button
                    style={{ marginTop: "33px" }}
                    className={classes.updateButton}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={(school.length > 0 || branch.length > 0 || role.length > 0 || trainingType || grade.length > 0 || subject.length > 0 || startDate || endDate) ? false : true}
                    onClick={handleClearAll}
                  >
                    Clear All
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography>Select School</Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={school || []}
                  // onChange={(e) => setSchool(e.target.value)}
                  onChange={handleChange}
                  // onChange={(e) => handleSelectALlSchool(e)}
                  multiple
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black", width: "400px" }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem key="all" value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          school.length > 0 && school.length < schoolList.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {schoolList &&
                    schoolList.length !== 0 &&
                    schoolList.map((data) => (
                      <MenuItem
                        value={data.name}
                        key={data.id}
                        name={data.name}
                      >
                        <Checkbox checked={school.indexOf(data.name) > -1} />
                        <ListItemText primary={data.name} />
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  Select Branch
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  multiple
                  value={branch || []}
                  // onChange={(e) => setBranch(e.target.value)}
                  onChange={(e) => handleSelectALl(e)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black", width: "400px" }}
                >
                  <MenuItem key="0" value="0">
                    Select All
                  </MenuItem>
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((data) => (
                      <MenuItem
                        value={data.id}
                        key={data.id}
                        name={data.branch_name}
                      >
                        {data.branch_name ? data.branch_name : ""}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography>
                  Select Role
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  multiple
                  value={role || []}
                  isRequired
                  onChange={(e) => setRole(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black", width: "400px" }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {roleList &&
                    roleList.length !== 0 &&
                    roleList.response.map((data) => (
                      <MenuItem
                        value={data.role_name}
                        key={data.role_id}
                        name={data.role_name}
                      >
                        <Checkbox checked={role.indexOf(data.role_name) > -1} />
                        <ListItemText primary={data.role_name} />
                        
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  Select Training
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={trainingType || ""}
                  onChange={(e) => setTrainingType(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black", width: "400px" }}
                >
                  {trainingTypeList &&
                    trainingTypeList.length !== 0 &&
                    trainingTypeList.map((data) => (
                      <MenuItem value={data.id} key={data.id} name={data.name}>
                        {data.id ? data.name : ""}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography>
                  Enter Email Address
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <TextField
                  label=""
                  margin="dense"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                />
                {email &&
                !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "red" }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
                {email &&
                /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "green" }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>Start Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleStartDateChange(e.target.value)}
                  type="date"
                  value={startDate || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>End Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleEndDateChange(e.target.value)}
                  type="date"
                  value={endDate || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={1} xs={12}>
                {permission.can_send ? (
                  <Button
                    style={{ marginTop: "33px" }}
                    className={classes.updateButton}
                    color="primary"
                    variant="contained"
                    onClick={() => sendEmail()}
                  >
                    Send
                  </Button>
                ) : null}
              </Grid>
            </Grid> */}
          </Paper>
        </div>
      </div>
      {loader}
    </>
  );
};

ConsolidatedReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ConsolidatedReport);
