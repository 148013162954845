import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./addSchool.style";
import { useAlert } from "../../../hoc/alert/alert";
import urls from "../../../url";
import Loader from "../../../hoc/loader";
import useFetch from "../../../hoc/useFetch";
import axios from "axios";
import ListSchool from "./listSchool";
// import useFetch from '../../../hoc/useFetch';

const AddSchool = ({ classes }) => {
  const alert = useAlert();
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [school, setSchool] = useState("");
  const [checkLoader, setCheckLoader] = useState(false);
  const [permission, setPermission] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState(null);
  const [school1, setSchool1] = useState(null);

  // const {
  //   data: schoolList,
  //   isLoading: schoolListLoading,
  //   doFetch: schoollevel
  // } = useFetch([])
  const [schoolList, setSchoolList] = useState([]);

  useEffect(() => {
    getSchoolList();
  }, []);

  const getSchoolList = () => {
    axios
      .get(`${urls.editSchoolApi}`, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("School"),
        },
      })
      .then((result) => {
        // if (result.data.status_code === 200) {
        console.log(result.data, "pagination");
        setSchoolList(result.data);
        //   setData({
        //     ...data,
        //     totalData: result.data.count,
        //     totalPages: result.data.total_pages,
        //     currentPage: result.data.current_page,
        //   });
        // }
      })
      .catch((error) => {
        //   setLoading(false);
        //   setAlert('error', error.message);
        console.log("err", error);
      });
  };

  function getPermissonSchool() {
    setLoader(true);
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("School"),
        },
      })
      .then((response) => {
        setPermission(response.data.response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error hi:", error);
        localStorage.setItem("school_can_add", false);
      });
  }

  useEffect(() => {
    getPermissonSchool();
  }, []);

  let pageLoading = null;

  if (loader) {
    pageLoading = <Loader open />;
  } else {
    pageLoading = null;
  }

  const addSchoolLevel = () => {
    if (!school) {
      alert.warning("Enter School First");
      return;
    }
    async function getSchoolAsync() {
      setCheckLoader(true);
      const response = await fetch(`${urls.editSchoolApi}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("School"),
        },
        body: JSON.stringify({
          name: school,
        }),
      });
      const data = await response.json();
      setCheckLoader(false);
      return data;
    }
    getSchoolAsync()
      .then((res) => {
        alert.success(res);
        setSchool("");
        getSchoolList();
      })
      .catch((err) => {
        alert.warning(err);
      });
  };
  return (
    <>
      <Typography variant="h4">Add School</Typography>
      <Divider className={classes.divider} />
      <Paper>
        {permission.can_add ? (
          <>
            <Grid container spacing={3} className={classes.root}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Add School"
                  margin="dense"
                  fullWidth
                  required
                  variant="outlined"
                  multiline
                  rows={1}
                  rowsMax={4}
                  value={school}
                  onChange={(e) => setSchool(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addSchoolLevel()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <h2>Not Authorize</h2>
        )}
      </Paper>
      <br />
      <br />
      <Grid xs={12}>
        <ListSchool
          schoolList={schoolList}
          setEditData={setEditData}
          school={school1}
          setSchool={setSchool1}
          editData={editData}
          addSchoolLevel={addSchoolLevel}
          setSchoolList={setSchoolList}
          getSchoolList={getSchoolList}
        />
      </Grid>
      {pageLoading}
    </>
  );
};
AddSchool.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(AddSchool);
