/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Fade,
  Backdrop,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './courseType.Style';
import urls from '../../../url';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';

const CourseType = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [categoryType, setCategoryType] = useState('');
  const [open, setOpen] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [loading, setloading] = useState(false);
  const [updateGrade, setUpdateGrade] = useState('');

  const {
    data: getCourseCategoryType,
    isLoading: gettingCategoryType,
    doFetch: fetchToCreateCategoryType,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);

  useEffect(() => {
    // module=localStorage.getItem('Grade')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Grade')	
      },
    });
  }, [])

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function functionToGetCreatedCategory() {
    fetchToCreateCategoryType({
      url: `${urls.addCourseCategoryTypeApi}?page_size=${rowsPerPage || 10}&page=${page + 1}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Grade')
      },
    });
  }

  useEffect(() => {
    if (auth) {
      functionToGetCreatedCategory();
    }
    setCategoryType('');
  }, [auth, rowsPerPage, page]);

  const alert = useAlert();

  let loader = null;
  if (gettingCategoryType || loading || modulePermissionLoading) {
    loader = <Loader open />;
  }

  function addCategotyType() {
    if (!categoryType) {
      alert.warning('Fill the Fields');
      return;
    }
    const data = {
      type_name: categoryType,
    };

    setloading(true);
    fetch(urls.addCourseCategoryTypeApi, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Grade')
      },
    }).then((res) => {
      if (res.status === 400) {
        setloading(false);
        setCategoryType('');
        alert.warning(' Grade already exist');
      }
      if (res.status === 201) {
        setloading(false);
        setOpen(false);
        setCategoryType('');
        functionToGetCreatedCategory();
        alert.success('Grade Successfully Created');
        return res.json();
      }
      if (res.status !== 201 && res.status !== 400) {
        setloading(false);
        alert.warning('somthing went wrong please try again ');
      }
      return 0;
    });
  }

  function functionToEditCategoryType(id) {
    setOpen(true);
    setTypeId(
      getCourseCategoryType
        && getCourseCategoryType.results
        && getCourseCategoryType.results[id].id,
    );
    setUpdateGrade(
      getCourseCategoryType
        && getCourseCategoryType.results
        && getCourseCategoryType.results[id].type_name,
    );
  }
  const handleClose = () => {
    setOpen(false);
    setUpdateGrade('');
  };

  const handleDeleteGrade = (id) =>{
    setloading(true)
    axios.delete(`${urls.deleteCourse}${id}/course_type/`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Subject')
      },
    })
    .then(response =>{
      functionToGetCreatedCategory();
      setloading(false)
      alert.success('Course deleted successfully ')
    }).then(error =>{
      setloading(false)
      alert.error('Something went wrong please try again !!!')
    })
  }

  function functionToUpdateCategoryType() {
    if (!updateGrade) {
      alert.warning('Enter Grade');
      return;
    }
    const data = {
      type_name: updateGrade,
    };
    setloading(true);
    fetch(`${urls.apiForUpdateCourseType}${typeId}/course_type/`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Grade')
      },
    }).then((res) => {
      if (res.status === 400) {
        setloading(false);
        setUpdateGrade('');
        alert.warning(' Grade already exist');
      }
      if (res.status === 200) {
        setloading(false);
        setOpen(false);
        setUpdateGrade('');
        functionToGetCreatedCategory();
        alert.success('Grade Successfully Created');
        return res.json();
      }
      if (res.status !== 200 && res.status !== 400) {
        setloading(false);
        alert.warning('somthing went wrong please try again ');
      }
      return 0;
    });
  }

  const categoryTableData = () => {
    let td = null;
    if (getCourseCategoryType && getCourseCategoryType.results.length) {
      td = (
        <>
          <TableBody>
            {getCourseCategoryType.results.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="left">{i + 1}</TableCell>
                <TableCell align="left">{data.type_name}</TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ?

                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => functionToEditCategoryType(i)}
                  >
                    Update
                  </Button>:null}
                </TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ?

                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => handleDeleteGrade(data.id)}
                  >
                    Delete
                  </Button>:null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {loader}
        </>
      );
    }
    return td;
  };

  function openModelUpdate() {
    let modal = null;
    modal = (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.updaatepaper}>
              <Typography variant="h5">Update Grade</Typography>
              <Divider className={classes.divider} />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TextField
                    className={classes.textField}
                    label="Update Grade"
                    margin="dense"
                    required
                    value={updateGrade || ''}
                    onChange={(e) => setUpdateGrade(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} />
                <Grid item md={4} xs={12}>
                  {modulePermission && modulePermission.response.can_update ?  
                  <Button
                    className={classes.updateButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => functionToUpdateCategoryType()}
                  >
                    Update Grade
                  </Button>
                :null}
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </div>
          </Fade>
        </Modal>
      </>
    );
    return modal;
  }

  return (
    <>
      <Typography variant="h5">Add Grade</Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item md={4} xs={12}>
            <TextField
              className={classes.textField}
              label="Enter Grade"
              margin="dense"
              required
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            {modulePermission && modulePermission.response.can_add ?
            
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => addCategotyType()}
            >
              Create Grade
            </Button>
          :null}
          </Grid>
        </Grid>
      </Paper>
      {getCourseCategoryType && getCourseCategoryType.results.length && (
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h6">S.No</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6">Grade </Typography>
                </TableCell>
                <TableCell align="left">
                {modulePermission && modulePermission.response.can_update ?
                  <Typography variant="h6">Edit</Typography> :null}
                </TableCell> 
                <TableCell align="left">
                {modulePermission && modulePermission.response.can_update ?
                  <Typography variant="h6">Delete</Typography> :null}
                </TableCell> 
              </TableRow>
            </TableHead>
            {categoryTableData()}
          </Table>
        </Paper>
      )}
      {getCourseCategoryType && getCourseCategoryType.results.length !== 0 && (
      <Paper>
        <Table>
          <TableBody>
            <TableRow>
              <TablePagination
                colSpan={6}
                labelDisplayedRows={() => `Page ${page
                        + 1} of ${+getCourseCategoryType.total_pages}`}
                rowsPerPageOptions={[10, 20, 30]}
                count={+getCourseCategoryType.count}
                rowsPerPage={rowsPerPage || 10}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <TableCell style={{ marginTop: '13px' }}>
                <IconButton
                  onClick={firstPageChange}
                  disabled={page === 0 || page === 1}
                >
                  <FirstPageIcon />
                </IconButton>
                <IconButton
                  onClick={() => lastPageChange(getCourseCategoryType.total_pages - 1)}
                  disabled={page === +getCourseCategoryType.total_pages - 1}
                >
                  <LastPageIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      )}
      {openModelUpdate()}
      {loader}
    </>
  );
};

CourseType.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CourseType);
