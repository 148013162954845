/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import './InductionForm.css';
import {
  withStyles, Typography, Grid, Button,
} from '@material-ui/core';
import styles from './InductionForm.style';
import urls from '../../../url';
import FeedBackForm from '../feedBack/feedBackForm';
import { useAlert } from '../../../hoc/alert/alert';
import useFetch from '../../../hoc/useFetch';
import axios from 'axios';


const initialSubmit = {
  branchName: [],
  subjectName: [],
  // volumeName: [],
};

const initialValues = {
  inductionGrade: [],
  inductionSubject: [],
  inductionVolume: [],
};
function InductionForm({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // const [inductionBranch, setInductionBranch] = useState();
  // const [inductionCategory, setInductionCategory] = useState();
  // const [inductionGrade, setInductionGrade] = useState(null);
  // const [inductionSubject, setInductionSubject] = useState(null); 

  const [values, setAllValues] = useState(initialValues);
  const [submitData, setSubmitData] = useState(initialSubmit);
  const [submit, setSubmit] = useState(false);
  const [inductionData, setInductionData] = useState('');
  const [grade, setGrade] = useState();
  const [subject, setSubject] = useState();
  const [volumeType, setVolumeType] = useState([]);
  const [initialState, setInitialState] = useState([]);
  const [permission, setPermission] = useState([]);

  const alert = useAlert();
 

  const handleSubmit = (e) => {
    // console.log("induc+subject",values.inductionGrade.length,submitData.subjectName.length)
    if (submitData.subjectName.length !== 0 && grade) {
      e.preventDefault();
      setSubmit(true);
    }
    else {
      e.preventDefault()
      alert.warning("Select grade or subject");
    }
  };


  async function getData(url) {
    const dataResult = fetch(url, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Subject_Training')
      },
    });

    const response = await dataResult;
    const finalData = await response.json();
    return finalData;
  }
   // For Permissions
   function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Subject_Training'))
  }, [])


  async function getSubject(gradeId) {
    // console.log("grade 111 "+gradeId);
    const subjectResult = fetch(
      `${urls.getGradeNames}?grade_id=${gradeId}`,
      {
        method: 'GET',
        cache: 'reload',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Subject_Training')
        },
      },
    )
    const response = await subjectResult;
    const subjectData = await response.json();
    // console.log('subjectData',subjectData)
    return subjectData;
  }

  async function getVolume(subjectId, gradeId) {
    // localStorage.setItem('GradeId', subjectId);
    // localStorage.setItem('GradeId', subjectId);
    // console.log('FF',subjectId)
    const volumeResult = fetch(
      `${urls.getVolumeBySubject}?grade_id=${subjectId}&subject_id=${gradeId}`,
      {
        method: 'GET',
        cache: 'reload',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: ` Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Subject_Training')
        },
      },
    );
    const response = await volumeResult;
    const volumeData = await response.json();
    setVolumeType(volumeData)
    return volumeData;
  }
  const {
      data: modulePermission,
      isLoading: modulePermissionLoading,
      doFetch: fetchModulePermission,
    } = useFetch(null)



    useEffect(() => {
      fetchModulePermission({
        url: `${urls.getPermissons}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Subject_Training')
        },
      });
    },[])
  useEffect(() => {
    let inductionFullData = null;

    getData(urls.fetchInductionFormData).then((data) => {
      inductionFullData = data;
      setInductionData(inductionFullData);
    });
  }, []);

  const handleGrade = (gradeId) => {
     window.localStorage.removeItem('VolumeId') 
    const subjectDataID = localStorage.getItem('subjectDataID');
    console.log('CC', subjectDataID)

    setGrade(gradeId)
    setAllValues({ ...values, inductionGrade: gradeId })
    localStorage.setItem('gradeDataID', gradeId);
    getSubject(gradeId).then(
      (data) => setAllValues({ ...values, inductionSubject: data })
    );
  
  };

  // useEffect(() => {
  //   if(grade && subject)
  //   {
  //     getVolume(grade, subject)
  //   }
  // }, [grade, subject])

  const handleSubject = (volume) => {
    localStorage.setItem('subjectDataID', volume);
    
    const categoryId = localStorage.getItem('subjectDataID');

    setSubject(volume)
    setAllValues({ ...values, inductionGrade: volume })
    // localStorage.setItem('subjectDataID', volume);

    getVolume(grade, volume).then(
      (data) => setAllValues({ ...values, inductionVolume: data })
    );
  };

  const handleVolume = (volume) => {
    setInitialState(volume)
    localStorage.setItem('VolumeId', volume);
    // const categoryId = localStorage.getItem('subjectDataID');

    setSubject(volume)
    setAllValues({ ...values, inductionGrade: volume })
    // localStorage.setItem('subjectDataID', volume);

    // getVolume(grade, volume).then(
    //   (data) => setAllValues({ ...values, inductionVolume: data })
    // );
    // handleVolume(volume.target.value)
    // if(volume.target.value !== 'DEFAULT'){
    //   setSubmitData({ ...submitData, volumeName: volume.target.value});
    // }
  };

  const handleBranchChange = (event) => {
    setSubmitData({ ...submitData, branchName: event.target.value });
  };

  const handleSubjectChange = (event) => {
    handleSubject(event.target.value)
    if (event.target.value !== 'DEFAULT') {
      setSubmitData({ ...submitData, subjectName: event.target.value });
    }
  };

  //  const handleSubjectChange = (event) => {
  //   handleSubject()
  //   if (event.target.value !== 'DEFAULT') {
  //     setSubmitData({ ...submitData, subjectName: event.target.value });
  //   }
  // };

  // const handleVolume = (e) =>{
  //   console.log(e.target.value);
  //   if (e.target.value !== 'DEFAULT') {
  //     setVolume(e.target.value);
  //   }
  // }

  if (submit) {
    localStorage.setItem('subjectDataID', submitData.subjectName);
    // localStorage.setItem('gradeDataID', values.inductionGrade)  ;

    return <Redirect to="/volumes" />;
  }

  return (
    <>
      {/* sub_type_name  --- > subject key */}
      {/* branch_name --- > branch key */}
      <Grid container className={classes.backGroundColor}>
        <Grid item md={12} xs={12}>
          <div className={classes.formDiv}>
            <form className={classes.inductionForm} onSubmit={handleSubmit}>
              {/* {(auth.personal_info.role === 'Principal' || auth.personal_info.role === 'Teacher' || 
              auth.personal_info.role === 'LeadTeacher' ||
                auth.personal_info.role === 'Coordinator' ) && ( */}
              <>
                <div className="selectBranch">
                  <Typography style={{
                    width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                  }}
                  >
                    Grade
                    </Typography>
                  <select
                    defaultValue="DEFAULT"
                    className="selectInput"
                    onChange={(e) => handleGrade(e.target.value)}
                    required
                  >
                    <option disabled value="DEFAULT">
                      Select Grade
                      </option>
                    {inductionData
                      && inductionData.course_type
                      && inductionData.course_type.length !== 0
                      && inductionData.course_type.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.type_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="selectBranch">
                  <Typography style={{
                    width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                  }}
                  >
                    Subject
                    </Typography>
                  <select
                    defaultValue="DEFAULT"
                    className="selectInput"
                    onChange={handleSubjectChange}
                    required
                  >
                    <option value="DEFAULT">
                      Select Subject
                      </option>
                    {inductionData
                      && inductionData.course_sub_type
                      && inductionData.course_sub_type.length !== 0
                      && inductionData.course_sub_type.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.sub_type_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="selectBranch">
                  <Typography style={{
                    width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                  }}
                  >
                    Volume
                    </Typography>
                  <select
                    defaultValue="DEFAULT"
                    className="selectInput"
                    onChange={(e) => handleVolume(e.target.value)}
                    required
                  >
                    <option value="DEFAULT">
                      Select Volume
                      </option>
                    {volumeType
                      && volumeType.data
                      && volumeType.data.length !== 0
                      && volumeType.data.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.volume_name}
                        </option>
                      ))}
                  </select>
                </div>
              </>
              {modulePermission && modulePermission.response.can_view ?
              <Button style={{ lineHeight: '0', fontSize: '25px', color: 'white' }} type="submit" className={classes.inductionFormSubmit}>
                <div className="arcButtonback">
                  Submit
                </div>
              </Button>:null}
            </form>
          </div>
        </Grid>
        <Grid item md={12} xs={12} className={classes.feedBackDiv}>
          <FeedBackForm />
        </Grid>
      </Grid>
    </>
  );
}
InductionForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(InductionForm);
