export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1, 2, 1),
    //   boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  applyButton: {
    marginTop: '5px',
  },
  textField: {
    width: '100%',
  },
});
