/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  withStyles,
  Divider,
  Grid,
} from '@material-ui/core';
import styles from './wibenarSchedule.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import TinyTextEditor from '../../../hoc/tinyMce/tinyTextEditor';
import Calendar from '../calender/calender';
import CreateUpdateWibenar from './createUpdateWibenar';
import ViewAllWibenars from './viewMoreWibenars';

const ScheduleWebinar = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [Loading, setLoading] = useState(false);
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [creteteUpdateWibenarFullData, setCretateUpdateWibenarFullData] = useState({});
  const [openAllWibenars, setViewAllWibenars] = useState(false);
  const [allWibenarsData, setAllWibenarsData] = useState({});
  const [type, setType] = useState('');
  // const [allMeetingData, setAllMeetingData] = useState({});
  const [webinaarInformation, setWebinaarInformation] = useState([]);

  let loader = null;
  if (Loading) {
    loader = <Loader open />;
  }

  function getData() {
    setLoading(true);
    fetch(`${urls.getAllWibenarScheduledContentWritterApi}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Webinar')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Data Received');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
        }
        return 0;
      }).then((data) => {
        // console.log(data, 'll');
        setWebinaarInformation(data);
      });
  }

  useEffect(() => {
    if (auth) {
      getData();
    }
  }, [auth.personal_info.token]);

  const closeViewAllWibenars = (data) => {
    setViewAllWibenars(false);
    if (data === 'success') {
      getData();
    }
  };

  const closeCreateUpdateModel = (data) => {
    setOpen(false);
    if (data === 'success') {
      closeViewAllWibenars();
      getData();
    }
    setCretateUpdateWibenarFullData({
      date: '', speakerErp: '', topicName: '', duration: '', schedule: '', aboutSpeaker: '', aboutSession: '', profileImage: '', user_id: '', createdBy: '', roles: '', branches: '', onlineClassType: '', joinLimit: '', grade: '', subject: '', zoom_email : ''
    });
  };

  function openMeetings(data, userId, day) {
    const branchArray = [];
    const rolseArray = [];
    for (let i = 0; i < data.role.length; i += 1) {
      rolseArray.push(data.role[i].id);
    }
    for (let i = 0; i < data.branch.length; i += 1) {
      branchArray.push(data.branch[i].id);
    }
    const scheduleTime = (data.zoom_details.start_time && data.zoom_details.start_time.split('T')[1].slice(0, 5)) || '';
    setCretateUpdateWibenarFullData({
      date: day,
      speakerErp: data.user.username,
      topicName: data.zoom_details.topic,
      duration: data.zoom_details.duration,
      schedule: scheduleTime || '',
      aboutSpeaker: data.about_speaker,
      aboutSession: data.about_session,
      profileImage: data.speaker_photo,
      grade: data.grade,
      subject: data.subject,
      user_id: userId,
      createdBy: data.created_by,
      roles: rolseArray,
      branches: branchArray,
      idNo: data.id,
      joinLink: (data.zoom_details && data.zoom_details.join_url) || '',
      startLink: (data.zoom_details && data.zoom_details.start_url) || '',
      zoom_email:(data.zoom_details && data.zoom_details.zoom_email) || "",
      onlineClassType: '0',
      joinLimit: data.join_limit,
    });
    setEdit(true);
    setOpen(true);
  }
  function viewAllMeetingsFunc(data, typedata) {
    setViewAllWibenars(true);
    setAllWibenarsData(data);
    setType(typedata);
  }

  function openWibenar(data, userId, day) {
    const branchArray = [];
    const rolseArray = [];
    for (let i = 0; i < data.role.length; i += 1) {
      rolseArray.push(data.role[i].id);
    }
    for (let i = 0; i < data.branch.length; i += 1) {
      branchArray.push(data.branch[i].id);
    }
    const scheduleTime = (data.zoom_details.start_time && data.zoom_details.start_time.split('T')[1].slice(0, 5)) || '';
    setCretateUpdateWibenarFullData({
      date: day,
      speakerErp: data.user.username,
      topicName: data.zoom_details.topic,
      duration: data.zoom_details.duration,
      schedule: scheduleTime || '',
      aboutSpeaker: data.about_speaker,
      aboutSession: data.about_session,
      profileImage: data.speaker_photo,
      user_id: userId,
      grade: data.grade,
      subject: data.subject,
      createdBy: data.created_by,
      roles: rolseArray,
      branches: branchArray,
      idNo: data.id,
      joinLink: (data.zoom_details && data.zoom_details.join_url) || '',
      startLink: (data.zoom_details && data.zoom_details.start_url) || '',
      zoom_email:(data.zoom_details && data.zoom_details.zoom_email) || "",
      onlineClassType: '1',
      joinLimit: data.join_limit,
    });
    setEdit(true);
    setOpen(true);
  }
  function viewAllWibenarsFunc(data, typeData) {
    setViewAllWibenars(true);
    setAllWibenarsData(data);
    setType(typeData);
  }

  function addMeetingandWibenar(day) {
    setCretateUpdateWibenarFullData({
      date: day, speakerErp: '', topicName: '', duration: '', schedule: '', aboutSpeaker: '', aboutSession: '', profileImage: '', user_id: '', createdBy: '', roles: '', branches: '', onlineClassType: '', joinLimit: '',
    });
    setOpen(true);
    setEdit(false);
  }

  function openOnlineClass() {

  }
  function viewAllOnlineMeetings() {

  }

  return (
    <>
      <Typography variant="h4" className={classes.typographys}>Schedule Webinar</Typography>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Calendar
            meetingData={webinaarInformation}
            wibenarData={webinaarInformation}
            onlineClassData={webinaarInformation}
            allMeetingsFunction={openMeetings}
            allwibenarsFunction={openWibenar}
            viewOnlineClass={openOnlineClass}
            AddMeetingAndWibenar={addMeetingandWibenar}
            viewMultipleWibenars={viewAllWibenarsFunc}
            viewMultipleMeetings={viewAllMeetingsFunc}
            viewAllOnlineClass={viewAllOnlineMeetings}
          />
        </Grid>
      </Grid>
      <CreateUpdateWibenar
        open={open}
        fullData={creteteUpdateWibenarFullData}
        handleClose={closeCreateUpdateModel}
        edit={edit}
      />
      <ViewAllWibenars
        open={openAllWibenars}
        handleClose={closeViewAllWibenars}
        fullData={allWibenarsData}
        setEditfromModel={openWibenar}
        setEditModelForMeeting={openMeetings}
        type={type}
      />
      <div style={{ display: 'none' }}>
        <TinyTextEditor
          id="aboutSpeakerDescId"
        />
      </div>
      {loader}
    </>
  );
};
ScheduleWebinar.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ScheduleWebinar);
