import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
// import GlobalSearch from '../../../components/GlobalSearch/GlobalSearch.jsx';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomizedDialogs from '../dialog_users/dialog_users.jsx';
// import DeleteDialog from '../../delete_dialog/delete_dialog.jsx';
import PersonIcon from '@material-ui/icons/Person';
import './users_table.scss';
// import services from '../../../services/organization.js';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField,Grid } from '@material-ui/core';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
// import axios from "axios";
// import DeleteAlertDialog from '../../../pages/delete_dialog/delete_dialog.jsx'
import Urls from "../../../../url";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'erp', numeric: false, disablePadding: false, label: 'ERP Number' },
  { id: 'userName', numeric: false, disablePadding: false, label: 'Username' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'role_v1', numeric: false, disablePadding: false, label: 'Role V1' },
  // { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
  { id: 'role_v2', numeric: false, disablePadding: false, label: 'Role V2' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Update' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Active/Inactive' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <PersonIcon />
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Users
        </Typography>
        </>
      )}

      {numSelected > 0 ? (<>
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleClickOpen} >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Uncheck">
          <IconButton aria-label="delete">
            <CloseIcon />
          </IconButton>
        </Tooltip>
        {/* <DeleteDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} /> */}
      </>
      ) : (
        <>
          {/* <GlobalSearch setSearchData={props.setSearchData}/> */}
          <TextField
              label="Search User ..."
              value = {props.searchData}
              onChange = {(e) => props.setSearchData(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          <Tooltip title="Refresh list">
            <IconButton aria-label="filter list" onClick={ props.getUserRefreshList} >
              <AutorenewIcon/>
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([])
  const [userData,setUserData]=useState(null)
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [active, setActive] = useState(false);
  const [Alldata, setAllData] = useState('');
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));

  const handleSwitch = (status, id) => {
    console.log(status, "status, comming");
    let userStatus = status? 1 : 2;
    props.handleDelete(id, userStatus)
  }
  

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setIdToDelete(null);
  }

  const handleOpenDeleteDialog = (e, item) => {
    setOpenDeleteDialog(true)
    setIdToDelete(item.id);
  }

  useEffect(()=>{
    // axios.get(`${urls.getUserList}?page=${page}`, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //   },
    // })
    // .then(response => {
    //   // alert.success('user status changed');
    //   setData(response.data.results)
    // }).catch(error => {
    //   // alert.warning("Status not updated !!!");
    // })
    console.log(props.data, "search1 from table");
    setData(props.data)
    setAllData(props.Alldata)
  },[props.data,auth, page, rowsPerPage])

  
  if(data)
    console.log("data check",data)
  const getUserList = () => {
    // services.getUserDetail()
    // .then((res) => {
    //   console.log(res.data, "Check user")
    //   setData(res.data.result);
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  }
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUser = (id) => {
    // services.deleteUserDetail(id)
    //   .then((res) => {
    //     props.getUserList();
    //     setOpenDeleteDialog(false);
    //     setIdToDelete(null);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    //console.log(name,"requireditem")
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleNextPage = (url) => {
    axios.get(url , {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    })
    .then(response => {
      // alert.success('user status changed');
      setData(response.data.results)
    }).catch(error => {
      // alert.warning("Status not updated !!!");
    })
  };
  if(data)
    console.log("next",data)

  const handleChangePage = (event, newPage) => {
    console.log("event ",newPage)
    // handleNextPage(Alldata.next)
    if(page<newPage){
      props.getUserList(false)
    }else{
      props.getUserList(true)
    }
    console.log('page',newPage)
    setPage(props.page);
    if (!rowsPerPage) {
      setRowsPerPage(100);
    }
  };

  
  useEffect(()=>{
    setPage(props.page)
  },[])


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  //console.log(rowsPerPage,"rowsPerPage")
  return (
    <div className={classes.root} id="table-container" >
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} open={open} handleClickOpen={() => handleClickOpen()} getUserList={props.getUserList} setSearchData={props.setSearchData} searchData = {props.searchData} 
        page={props.page} setPage={props.setPage} getUserRefreshList={props.getUserRefreshList}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    //console.log(item.gst_treatment.name, "hey")
                    const isItemSelected = isSelected(item.name);
                    console.log(isItemSelected, "selected item")
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const name = item.name;
                    return (
                      /* 
                       // <TableRow
                      // /* //   hover */
                      // /* //   onClick={(event) => { }
                      //     handleClick(event, props.data.name)
                      //     console.log(item, "itemed")
                      //   }}
                      //   role="checkbox"
                      //   aria-checked={isItemSelected}
                      //   tabIndex={-1}
                      //   key={data.name}
                      //   selected={isItemSelected}
                      // >*/
                      

                      <TableRow>
                     
                        <TableCell padding="checkbox">
                          
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" >
                          {item.erp}
                        </TableCell>
                        <TableCell>{item.username}</TableCell>
                        <TableCell>{item.first_name}</TableCell>
                        <TableCell >{item.email}</TableCell>
                        <TableCell  >{item.role}</TableCell>
                        <TableCell  >{item.roles}</TableCell>
                        <TableCell>
                        {props.accessRule.can_update ? 
                        <IconButton size="small" onClick={() => props.editUser(item)} >
                            <EditIcon />
                        </IconButton>
                        :null}
                        </TableCell>
                        <TableCell>
                        {props.accessRule.can_activate_inactivate ? 
                        <Switch
                            checked={item.is_active}
                            onChange={(e) => handleSwitch(item.is_active, item.id)}
                            name="UserStatus"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                          :null}
                        </TableCell>
                                               
                       </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[100, 500, 1000]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
        {Alldata && Alldata.results.length !== 0 && (
                <Grid item md={12} xs={12}>
                  <Paper
                    // style={{ backgroundColor: 'lightgray', marginTop: '10px' }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TablePagination
                          style={{display:'flex',paddingLeft:'32%'}}
                            colSpan={6}
                            labelDisplayedRows={() => `Page ${props.page
                                + 1} of ${+Alldata.total_pages}`}
                            rowsPerPageOptions={[100]}
                            count={+Alldata.count}
                            rowsPerPage={rowsPerPage || 100}
                            page={props.page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'Rows per page' },
                            }}
                            onChangePage={handleChangePage}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                          <TableCell style={{border:'solid red' }}>
                            {/* <IconButton
                              // onClick={firstPageChange}
                              disabled={page === 0 || page === 1}
                            >
                              <FirstPageIcon />
                            </IconButton>
                            <IconButton
                              // onClick={() => lastPageChange(Alldata.total_pages - 1,)}
                              disabled={
                                page === +Alldata.total_pages - 1
                              }
                            >
                              <LastPageIcon />
                            </IconButton> */}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              )}
      </Paper>


      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      <CustomizedDialogs open={openUpdate} 
      handleClose={handleClickCloseUpdate}
      handleClickOpen={() => handleClickOpen()} 
      handleClose={() => handleClose()} />

      {/* <DeleteAlertDialog handleClose = {handleCloseDeleteDialog} open = {openDeleteDialog} idToDelete = {idToDelete} deleteData = {deleteUser} type = "User"/> */}
    </div>
  );
}
