/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import MovieIcon from '@material-ui/icons/Movie';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
// import bookImage from '../../../Media/bookImage.webp';
import PropTypes from 'prop-types';
import urls from '../../../../../url';
import styles from './CourseLesson.style';
import icon1 from '../../../Media/ICONS-06.svg';

function CoursesLessons({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [trainingLessons, setTrainingLessons] = useState();
  const [loader, setLoader] = useState(false);

  const contentId = localStorage.getItem('contentId');

  const getUnitId = (id) => {
    localStorage.setItem('unitId', id);
    localStorage.setItem('nextPage', id);
  };

  async function getLessons(id) {
    setLoader(true);
    const URL = urls.inHouseModules;
    const fetchResult = fetch(`${URL}?content_id=${id}`, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
    const response = await fetchResult;
    const jsonData = await response.json();
    setLoader(false);

    return jsonData;
  }

  useEffect(() => {
    getLessons(contentId).then((data) => setTrainingLessons(data));
  }, []);

  const showLessons = () => {
    if (trainingLessons) {
      if (trainingLessons.length === 1) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0 auto',
              marginTop: '5rem',
            }}
          >
            <div>
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[0].id)}
              >
                {/* <MovieIcon style={{ fontSize: "4rem" }} /> */}
                <img alt="crashed" src={icon1} width="80%" height="80%" />
              </Link>
              {/* <p style={{ marginTop: "0" }}>{trainingLessons[0].title}</p> */}
            </div>
          </div>
        );
      }
      if (trainingLessons.length === 2) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '80%',
              margin: '0 auto',
              marginTop: '30vh',
            }}
          >
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(trainingLessons[0].id)}
            >
              <MovieIcon style={{ fontSize: '4rem' }} />
            </Link>

            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(trainingLessons[1].id)}
            >
              <MovieIcon style={{ fontSize: '4rem' }} />
            </Link>
          </div>
        );
      }
      if (trainingLessons.length === 3) {
        return (
          <>
            <div
              style={{
                top: '20vh',
                display: 'flex',
                justifyContent: 'center',
                width: '80%',
                margin: '0 auto',
                marginTop: '4rem',
              }}
            >
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[0].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%',
                margin: '0 auto',
                marginTop: '10rem',
              }}
            >
              <div
                style={{
                  width: '10px',
                }}
              >
                <Link
                  to="/CoursesUnit"
                  onClick={() => getUnitId(trainingLessons[1].id)}
                >
                  <MovieIcon style={{ fontSize: '4rem' }} />
                </Link>
                {' '}
              </div>
              <div
                style={
                  {
                    //   margin: "0 auto",
                    //   float: "right"
                  }
                }
              >
                <Link
                  to="/CoursesUnit"
                  onClick={() => getUnitId(trainingLessons[2].id)}
                >
                  <MovieIcon style={{ fontSize: '4rem' }} />
                </Link>
                {' '}
              </div>
            </div>
          </>
        );
      }
      if (trainingLessons.length === 4) {
        return (
          <>
            <div
              style={{
                // top: "20vh",
                display: 'flex',
                justifyContent: 'space-between',
                width: '80%',
                margin: '0 auto',
                marginTop: '20vh',
              }}
            >
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[0].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[1].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              {' '}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '80%',
                margin: '0 auto',
                marginTop: '8rem',
              }}
            >
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[2].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[3].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              {' '}
            </div>
          </>
        );
      }
      if (trainingLessons.length === 5) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '6rem auto 1rem auto',
              }}
            >
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[0].id)}
              >
                {/* <MovieIcon style={{ fontSize: "4rem" }} /> */}
              </Link>
            </div>
            <div
              style={{
                // top: "20vh",
                display: 'flex',
                justifyContent: 'space-between',
                width: '65%',
                margin: '0 auto',
                marginBottom: '4rem',
              }}
            >
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[1].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[2].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              {' '}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%',
                margin: '0 auto',
              }}
            >
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[3].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              <Link
                to="/CoursesUnit"
                onClick={() => getUnitId(trainingLessons[4].id)}
              >
                <MovieIcon style={{ fontSize: '4rem' }} />
              </Link>
              {' '}
            </div>
          </>
        );
      }
      return (
        <>
          {loader ? (
            <>
              <CircularProgress
                variant="determinate"
                value={100}
                className={classes.top}
                size={60}
                thickness={8}
              />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.bottom}
                size={60}
                thickness={8}
              />
            </>
          ) : (
            ''
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '30%',
              margin: '6rem auto 1rem auto',
            }}
          >
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(
                trainingLessons[2].id,
                localStorage.setItem('nextItem', trainingLessons[3].id),
              )}
            >
              <MovieIcon style={{ fontSize: '4rem', color: 'black' }} />
            </Link>
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(
                trainingLessons[3].id,
                localStorage.setItem('nextItem', trainingLessons[4].id),
              )}
            >
              <MovieIcon style={{ fontSize: '4rem', color: 'black' }} />
            </Link>
          </div>
          <div
            style={{
              // top: "20vh",
              display: 'flex',
              justifyContent: 'space-between',
              width: '65%',
              margin: '0 auto',
              marginBottom: '4rem',
            }}
          >
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(
                trainingLessons[1].id,
                localStorage.setItem('nextItem', trainingLessons[2].id),
              )}
            >
              <MovieIcon style={{ fontSize: '4rem', color: 'black' }} />
            </Link>
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(
                trainingLessons[4].id,
                localStorage.setItem('nextItem', trainingLessons[4].id),
              )}
            >
              <MovieIcon style={{ fontSize: '4rem', color: 'black' }} />
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '90%',
              margin: '0 auto',
              // marginTop: "6rem"
            }}
          >
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(
                trainingLessons[0].id,
                localStorage.setItem('nextItem', trainingLessons[1].id),
              )}
            >
              <MovieIcon style={{ fontSize: '4rem', color: 'black' }} />
            </Link>
            <Link
              to="/CoursesUnit"
              onClick={() => getUnitId(trainingLessons[5].id)}
            >
              <MovieIcon style={{ fontSize: '4rem', color: 'black' }} />
            </Link>
          </div>
        </>
      );
    }
    return 0;
  };

  return (
    <>
      <div className={classes.lessonBackground}>
        {/* <div className={classes.mainDiv}> */}
        {loader ? (
          <>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50% , -50%)',
              }}
            >
              <CircularProgress
                variant="determinate"
                value={100}
                className={classes.top}
                size={60}
                thickness={8}
              />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.bottom}
                size={60}
                thickness={8}
              />
            </div>
          </>
        ) : (
          <div className={classes.bookImgDiv}>{showLessons()}</div>
        )}
      </div>
      {/* </div> */}
    </>
  );
}
CoursesLessons.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CoursesLessons);
