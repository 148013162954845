/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Hidden from '@material-ui/core/Hidden';
// import { Button } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;
  const readFullArticle = () => {
    localStorage.setItem('post', JSON.stringify(post));
  };

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea href="/full-article" onClick={readFullArticle}>
        <CardMedia
          className={classes.media}
          image={post.blog_header_image}
          title={post.blog_title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {ReactHtmlParser(post.blog_title)}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {ReactHtmlParser(post.blog_content.length < 200
              ? post.blog_content
              : post.blog_content.substring(0, 200))}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
