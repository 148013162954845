import React, { useState, useEffect} from 'react';
import {
  withStyles,
  Radio,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ClassInitiate from './ClassInitiate';
import OnlinForm from './onlineform';
import styles from './ClassInitiate.style';
import urls from '../../../url';
import axios from 'axios';

const InitiateMainPage = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [status, setStatus] = useState('');
  const [permission, setPermission] = useState([]);

 // For Permissions
 function getPermissonData(id) {
  axios.get(urls.getPermissons,{
    headers: {
      'Content-Type': 'Application/json',
      Authorization: `Bearer ${auth.personal_info.token}`,
      module:id
    },
  }).then(response => {
    setPermission(response.data.response)
  }).catch(error => {
    console.log(error);
  })
}

useEffect(() => {
  // console.log("hi")
  getPermissonData(localStorage.getItem('Initiate_Class'))
}, [])

  return (
    <div className={classes.tdMainDiv}>
      {/* {permission.can_add ? */}
      <>
      <h2> Initiate</h2>
      <Box border={1} classes={classes.classBox} style={{ margin: '20px', borderColor: 'lightgray' }}>
        <Grid container spacing={2} style={{ textAlign: 'center', padding: '10px' }}>
          <Grid item md={12} xs={12}>
            <Typography>Select Class Type :</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Radio
              checked={status === 'offline'}
              onChange={(e) => setStatus(e.target.value)}
              value="offline"
              color="primary"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
            />
            Offline
          </Grid>
          <Grid item md={6} xs={6}>
            <Radio
              checked={status === 'online'}
              onChange={(e) => setStatus(e.target.value)}
              value="online"
              color="primary"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
            />
            Online
          </Grid>
          <Grid item md={12} xs={12}>
            {status && status === 'offline' && <ClassInitiate />}
            {status && status === 'online' && <OnlinForm />}
          </Grid>
        </Grid>
      </Box>
      </>
       {/* :null} */}
    </div>
  );
};

InitiateMainPage.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InitiateMainPage);
