export default () => ({
  footerDiv: {
    width: '100%',
    height: '15vh',
    backgroundColor: 'orange',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
});
