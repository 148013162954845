import React from 'react';
import { withStyles } from '@material-ui/core';
import '../MainPage/CSS/InHouseMainPage.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import internetIcon from '../Media/UdaanHome/internet.svg';
import orchidsLogo from '../Media/UdaanHome/orchids-logo.png';
import justLogo from '../Media/UdaanHome/Just Logo-01.png';
import styles from './FooterDiv.style';

function FooterDiv() {
  return (
    <>
      <footer className="mainPageFooterDiv">
        <div className="contactDiv">
          <h2>Address</h2>
          <p>MUMBAI OFFICE :</p>
          <p>601-602, Acropolis Bldg, Marol</p>
          <p>Andheri (East), Landmark : Above Tanvi</p>
          <p>Supermarket, Mumbai - 400059</p>
          <iframe
            title="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.0219205481667!2d77.56597641436392!3d13.034276017027649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d608814ca8d%3A0xd0d514a6aa5bd05b!2sK12%20Techno%20Services%20Pvt.%20Ltd.%20~%20Bangalore!5e0!3m2!1sen!2sin!4v1580299542683!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder="0"
            className="mapFrame"
            style={{ border: '0' }}
            allowFullScreen=""
          />
          <div className="emailLink">
            <a href="www.orchids.edu.in">
              <img
                alt="crash"
                src={internetIcon}
                className="internetIcon"
                width="25px"
                height="25px"
              />
              www.orchids.edu.in
              {/* <span> </span> */}
            </a>
            <div className="socialMediaDiv">
              <span className="socialMediaLink">
                <FacebookIcon fontSize="large" />
              </span>
              <span className="socialMediaLink">
                {' '}
                <InstagramIcon fontSize="large" />
              </span>
              <span className="socialMediaLink">
                <YouTubeIcon fontSize="large" />
              </span>
            </div>
          </div>
        </div>
        <div>
          <img alt="crash" src={justLogo} width="100%" height="100%" />
          <h1>Sure Learning</h1>
          <p>Empowered 2000+ teachers for</p>
          <img alt="crash" src={orchidsLogo} width="60%" height="60%" />
        </div>
      </footer>
    </>
  );
}

export default withStyles(styles)(FooterDiv);
