/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  //   Table,
  //   TableBody,
  //   TableCell,
  //   TableHead,
  //   TableRow,
  Paper,
  Box,
  //   Modal,
  //   Fade,
  //   Backdrop,
  //   IconButton,
  TextField,
  //   TableFooter,
  //   TablePagination,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  InputLabel,
  ListItemIcon,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import styles from "./BranchRoleWiseReport.style";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let status;
let link1;
let link2;
let link3;
let link4;
let finalUrl;
let schoolArray = [];
const BranchRoleWiseReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [checkLoader, setCheckLoader] = useState(false);
  //   const [open, setOpen] = React.useState(false);
  //   const [rowsPerPage, setRowsPerPage] = React.useState(null);
  //   const [page, setPage] = React.useState(0);

  const [role, setRole] = useState([]);
  const [branch, setBranch] = useState([]);
  const [school, setSchool] = useState([]);

  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const alert = useAlert();
  const [permission, setPermission] = useState([]);
  const [SelectAll, setSelectedAll] = useState("");

  const [trainingType, setTrainingType] = useState("");

  // const verifyEmail = auth
  // && auth.academic_profile
  // && auth.academic_profile.user.email;

  // const verify=verifyEmail.includes("@orchids.edu.in")
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  var verify = loginData.role_permission.is_orchids;

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);

  useEffect(() => {
    //kept for future condition
    // link1=`${urls.getRoleRegistration}?search=orchids&user_type=USER`
    // link2=`${urls.getRoleRegistration}?search=other&user_type=USER`
    // link3=`${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`
    // link4=`${urls.getRoleRegistration}?search=other&user_type=ADMIN`

    // finalUrl = localStorage.getItem("userType")==="USER" ? verify ? link1 : link2 :
    // localStorage.getItem("userType")==="ADMIN" ? verify ? link3 :link4 :null

    fetchRoles({
      url: `${urls.getRoleRegistration}?user_type=USER`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("User_List_Report"),
      },
    });

    fetchBranches({
      url: `${urls.branchApi}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("User_List_Report"),
      },
    });
  }, [fetchRoles, fetchBranches, auth.personal_info.token]);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // console.log(schoolArr, "schoolList");

  // const sendEmail = () => {
  //   if (!branch || branch.length === 0) {
  //     alert.warning("Select Branch");
  //     return;
  //   }
  //   if (!role || role.length === 0) {
  //     alert.warning("Select Role");
  //     return;
  //   }
  //   // if (!trainingType) {
  //   //   alert.warning('Select Training Type'); return;
  //   // }

  //   async function loading() {
  //     setCheckLoader(true);

  //     const response = await fetch(
  //       `${urls.downloadBranchAndRoleWiseReport}?branch=${[branchArr]}&role=${[
  //         roleArr,
  //       ]}`,
  //       {
  //         method: "GET",

  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${auth.personal_info.token}`,
  //           module: localStorage.getItem("User_List_Report"),
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     status = response.status;
  //     return res;
  //   }
  //   loading()
  //     .then(() => {
  //       // console.log('harsha',res)
  //       if (status === 200) {
  //         setCheckLoader(false);
  //         alert.success(`Your consolidated report has been sent .`);
  //         // eslint-disable-next-line no-console
  //       }
  //       if (status === 404) {
  //         setCheckLoader(false);
  //         alert.warning(`Data not found.`);
  //       }
  //       if (status === 400) {
  //         setCheckLoader(false);
  //         alert.warning(`Bad Request`);
  //       }
  //     })
  //     .catch((error) => {
  //       setCheckLoader(false);
  //       alert.warning(`Error:${error}`);
  //     });

  //   setBranch([]);
  //   setRole([]);
  // };

  const downloadBranchAndRoleWiseReport = () => {
    const roleArr = [];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList &&
          roleList.response.filter((item) => item.role_name === role[i])[0]
            .role_id
      );
    }

    const branchArr = [];
    for (let i = 0; i < branch.length; i += 1) {
      branchArr.push(
        branchList &&
          branchList.filter((item) => item.branch_name === branch[i])[0].id
      );
    }

    const fetchReport = async () => {
      const response = await fetch(
        `${urls.downloadBranchAndRoleWiseReport}?branch=${[branchArr]}&role=${[
          roleArr,
        ]}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            "Content-Type": "text/ms-excel",
            module: localStorage.getItem("User_List_Report"),
          },
        }
      );
      const res = await response.blob();
      return res;
    };
    fetchReport()
      .then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.setAttribute("download", "Branch_wise_and_role_wise_UserList.xlsx");
        a.click();
      })
      .catch(console.error);
    setBranch([]);
    setRole([]);
  };

  let loader = null;
  if (roleListLoading || branchListLoading || checkLoader) {
    loader = <Loader open />;
  }

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem("User_List_Report"));
  }, []);

  const isAllSelected =
    roleList?.length > 0 && role?.length === roleList?.length;
  const handleChange = (event) => {
    const value = event.target.value;
    // console.log(value[value.length - 1], "handleChange");
    const roleeList = roleList.response.map((item) => item?.role_name);
    if (value[value.length - 1] === "all") {
      setRole(role?.length === roleList?.length ? [] : roleeList);
      return;
    }
    setRole(value);
  };

  const isAllBranchSelected =
    branchList?.length > 0 && branch?.length === branchList?.length;

  const handleBranchChange = (event) => {
    const value = event.target.value;
    // console.log(value[value.length - 1], "handleChange");
    const branchesList = branchList.map((item) => item?.branch_name);
    if (value[value.length - 1] === "all") {
      setBranch(branch?.length === branchList?.length ? [] : branchesList);
      return;
    }
    setBranch(value);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Branch And Role Wise Report </Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid container item spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      Select Branch
                      <b style={{ color: "red" }}>*</b>
                    </Typography>

                    <FormControl className={classes.formControl}>
                      <Select
                        margin="dense"
                        fullWidth
                        value={branch || []}
                        // onChange={(e) => setSchool(e.target.value)}
                        onChange={handleBranchChange}
                        // onChange={(e) => handleSelectALlSchool(e)}
                        multiple
                        className={classes.textField}
                        variant="outlined"
                        style={{ color: "black" }}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key="all" value="all">
                          <ListItemIcon>
                            <Checkbox
                              checked={isAllBranchSelected}
                              indeterminate={
                                branch.length > 0 &&
                                branch.length < branchList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {branchList &&
                          branchList.length !== 0 &&
                          branchList.map((data) => (
                            <MenuItem
                              value={data.branch_name}
                              key={data.id}
                              name={data.branch_name}
                            >
                              <Checkbox
                                checked={branch.indexOf(data.branch_name) > -1}
                              />
                              <ListItemText primary={data.branch_name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>
                      Select Role
                      <b style={{ color: "red" }}>*</b>
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <Select
                        margin="dense"
                        fullWidth
                        value={role || []}
                        // onChange={(e) => setSchool(e.target.value)}
                        onChange={handleChange}
                        // onChange={(e) => handleSelectALlSchool(e)}
                        multiple
                        className={classes.textField}
                        variant="outlined"
                        style={{ color: "black" }}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key="all" value="all">
                          <ListItemIcon>
                            <Checkbox
                              checked={isAllSelected}
                              indeterminate={
                                role.length > 0 && role.length < roleList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {roleList &&
                          roleList.length !== 0 &&
                          roleList.response.map((data) => (
                            <MenuItem
                              value={data.role_name}
                              key={data.role_id}
                              name={data.role_name}
                            >
                              <Checkbox
                                checked={role.indexOf(data.role_name) > -1}
                              />
                              <ListItemText primary={data.role_name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs={6}>
                    {permission.can_export ? (
                      <Button
                        style={{ marginTop: "33px" }}
                        className={classes.updateButton}
                        color="primary"
                        variant="contained"
                        onClick={() => downloadBranchAndRoleWiseReport()}
                        // href={`${urls.getExcelDownLoad}?webinar_id=${selectedUsers}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Meeting_Webinar_Report')}`}
                      >
                        Download
                      </Button>
                    ) : null}
                  </Grid>

                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
      </div>
      {loader}
    </>
  );
};

BranchRoleWiseReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(BranchRoleWiseReport);
