import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  BrowserRouter,
  //  Switch, Route,
  // Link
  // Redirect,
} from 'react-router-dom';
import UserRegister from '../userRegister/UserRegister';
import useStyles from './Navbar.style';
import UserLogin from '../userLogin/UserLogin';
import PerformanceCard from '../studentCourse/performanceCard/performanceCard';
// import Grid from "@material-ui/core/Grid";

const Navbar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [profileanchorEl, setProfileAnchorEl] = useState(null);

  if (localStorage.getItem('UserLogin') === null){
    window.localStorage.clear();
  }

  useEffect(() => {
    if (localStorage.getItem('UserLogin') !== null) {
      setSignIn(true);
    } else {
      setSignIn(false);
    }
  }, [signIn]);

  const handleProfileClick = (event) => {
    // console.log(event.currentTarget , 'sdsdsdsds')
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogoutClose = () => {
    setProfileAnchorEl(null);
    localStorage.removeItem('UserLogin');
    localStorage.setItem('isLoggedIn', false);
    setSignIn(false);
    window.location.href = '/';
  };

  const handleClickOpen = () => <UserRegister />;

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoginOpen = () => {
    setOpen(true);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleProfileMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const handleProfileOpen = (e) => {
  //   console.log('Hiiii');
  // };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My Account</MenuItem>
      <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ color: 'white' }}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <Button
          color="secondary"
          variant="contained"
          className={classes.navBtns}
          onClick={handleLoginOpen}
          style={{ backgroundColor: '#f99595' }}
        >
          Sign In
        </Button>
      </MenuItem>
      <BrowserRouter>
        {/* <Link to='/profile' style={{ textDecoration: "none", color: "black" }}> */}
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge color="secondary">
              <AccountCircle />
            </Badge>
          </IconButton>
          <a href="/register" className={classes.removeLinkLine}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
            >
              SIGN UP
            </Button>
          </a>
        </MenuItem>
        {/* </Link> */}
      </BrowserRouter>
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  // function functionToOpenPerformanceCard(){
  //   history.push('/performanceCard')
  //   // return (

  //   // <BrowserRouter>
  //   // <Redirect to="/performanceCard"/>
  //   // </BrowserRouter>
  //   // )

  // }

  return (
    // <div className={classes.grow}>
    <>
      <>
        <Toolbar>
          <a href="/" style={{ color: 'white', textDecoration: 'none' }}>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              style={{ fontWeight: 'bold' }}
            >
              {/* SURE LEARNING */}
            </Typography>
          </a>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder='Search…'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div> */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {signIn ? (
              <div>
                <Button
                  // color='secondary'
                  style={{ backgroundColor: '#f99595' }}
                  variant="contained"
                  className={classes.navBtns}
                >
                  Sign In
                </Button>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="secondary"
                  variant="contained"
                  className={classes.navBtns}
                  onClick={handleProfileClick}
                >
                  My Account
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={profileanchorEl}
                  keepMounted
                  open={Boolean(profileanchorEl)}
                  onClose={handleProfileClose}
                >
                  <a
                    href="/profile"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <MenuItem onClick={handleProfileClose}>Profile</MenuItem>
                  </a>
                  <MenuItem onClick={handleLogoutClose}>Logout</MenuItem>
                </Menu>
                <PerformanceCard />
              </div>
            ) : (
              <Button
                // color='secondary'
                variant="contained"
                style={{
                  backgroundColor: '#f99595',
                  color: 'white',
                  marginRight: '0px',
                  borderRight: '1px solid white',
                  borderRadius: '0px',
                }}
                className={classes.navBtns}
                onClick={handleLoginOpen}
              >
                Sign In
              </Button>
            )}
            {localStorage.getItem('UserLogin') == null && (
              <a href="/register" className={classes.removeLinkLine}>
                <Button
                  variant="contained"
                  // color='secondary'
                  style={{
                    backgroundColor: '#f99595',
                    color: 'white',
                    borderRadius: '0px',
                  }}
                  onClick={handleClickOpen}
                >
                  SIGN UP
                </Button>
              </a>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </>
      {renderMobileMenu}
      {renderMenu}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        className={classes.modalBgc}
        PaperProps={{
          style: {
            background: 'rgba(135,187,190 , 0.6)',
          },
        }}
      >
        <DialogContent>
          <UserLogin modalClose={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default withStyles(useStyles)(Navbar);
