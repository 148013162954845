/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  IconButton,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  TableCell,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import styles from './inHouseNotification.style';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import useFetch from '../../../hoc/useFetch';

const InHouseNotification = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [Loading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const {
    data: notifications,
    isLoading: notificationsLoading,
    doFetch: getNotifications,
  } = useFetch();
  const scrollHere = useRef();

  // const DialogTitle = (props) => {
  //   const { children, onClose, ...other } = props;
  //   return (
  //     <MuiDialogTitle disableTypography className={classes.root} {...other}>
  //       <Typography variant="h6">{children}</Typography>
  //       {onClose ? (
  //         <IconButton
  //           aria-label="close"
  //           className={classes.closeButton}
  //           onClick={onClose}
  //         >
  //           <CloseIcon />
  //         </IconButton>
  //       ) : null}
  //     </MuiDialogTitle>
  //   );
  // };
  // DialogTitle.propTypes = {
  //   children: PropTypes.string.isRequired,
  //   onClose: PropTypes.func.isRequired,
  // };

  let loader = null;
  if (Loading || notificationsLoading) {
    loader = <Loader open />;
    // scrollHere.current.scrollIntoView({ block: 'end', inline: 'nearest' });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const firstPageChange = () => {
    setPage(0);
  };

  const lastPageChange = (lastPage) => {
    setPage(lastPage);
  };
  // useEffect(() => {
  //   scrollHere.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  // }, [notifications]);

  useEffect(() => {
    if (auth) {
      getNotifications({
        url: urls.sendNotificationAPI,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Notification')
        },
      });
    }
  }, [auth, auth.personal_info.token, getNotifications]);

  useEffect(() => {
    getNotifications({
      url: `${urls.sendNotificationAPI}?page_size=${rowsPerPage || 10}&page=${page + 1}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Notification')
      },
    });
  }, [page, rowsPerPage]);

  return (
    <>
      <div className={classes.LeanrningFullModule}>
        <Typography variant="h4" className={classes.typographys}>My Notifications</Typography>
        <Divider className={classes.divider} />
        {/* <Paper className={classes.paper}> */}
        {(notifications && notifications.results.length === 0)
          ? <Typography variant="h5" style={{ textAlign: 'center', color: 'blue' }}>No notifications to show.</Typography>
          : notifications && notifications.results && notifications.results.map((data) => (
            <Grid container>
              <Grid item md={3} xs={1} />
              <Grid item md={6} xs={10}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="span" gutterBottom>
                      {data.send_by.username}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant="span" style={{ fontSize: 12 }}>
                      {data.send_date}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {data.text}
                    </Typography>
                    <Typography variant="body2" component="p" />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={3} xs={1} />
            </Grid>
          ))}
        {notifications && notifications.results.length !== 0 && (
        <Grid container>
          <Grid item md={3} xs={1} />
          <Grid item md={6} xs={10}>
            <Paper
              style={{ backgroundColor: 'lightgray', marginTop: '10px' }}
            >
              <TablePagination
                colSpan={6}
                labelDisplayedRows={() => `Page ${page + 1} of ${+notifications.total_pages}`}
                rowsPerPageOptions={[10, 20, 30]}
                count={+notifications.count}
                rowsPerPage={rowsPerPage || 10}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              {/* <IconButton
                onClick={firstPageChange}
                disabled={page === 0 || page === 1}
              >
                <FirstPageIcon />
              </IconButton>
              <IconButton
                onClick={() => lastPageChange(
                  notifications.total_pages - 1,
                )}
                disabled={page === +notifications.total_pages - 1}
              >
                <LastPageIcon />
              </IconButton> */}
            </Paper>
          </Grid>
          <Grid item md={3} xs={1} />
        </Grid>
        )}

        {loader}
      </div>
      <div ref={scrollHere} />
    </>
  );
};
InHouseNotification.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InHouseNotification);
