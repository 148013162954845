import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Dialog,
  Box,
  Grid,
} from "@material-ui/core";
import { Page, Text, View, Document, StyleSheet } from "react-pdf";
import FileViewer from "react-file-viewer";
import ResourceDetailViewer from "../../admin/AddResourse/Resource_Deails/resources_details";
import urls from "../../../url";
import axios from "axios";
import "./resourses.css";

// import { AttachmentPreviewerContext } from "../../attachment-previewer/attachment-previewer-contexts";
//   import ViewPdf from '../../../../../studentCourse/courses/viewPdf';
function Resourses() {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [resourceList, setResourceList] = useState([]);
  const column = ["Sl No.", "Resource Title", "Resource File"];
  const [isOpenResouurceDialog, setIsOpenResouurceDialog] = useState(false);
  const [selectedResourcePath, setSelectedResourcePath] = useState(null);
  const folderDetails = JSON.parse(sessionStorage.getItem("folderDetails"));
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  const adminRole = loginData?.personal_info?.role;

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  const handleOpenResourceDetail = (path) => {
    setSelectedResourcePath(path);
    setIsOpenResouurceDialog(true);
  };

  const handleCloseResourceDetail = (path) => {
    setSelectedResourcePath(null);
    setIsOpenResouurceDialog(false);
  };

  const getParticularResourseData = (folderDetails) => {
    axios
      .get(`${urls.GetAllResources}?folder_id=${folderDetails}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        // let temp = [];
        // // console.log(folderDetails);
        // let filterResource = response.data.filter(
        //   (eachFilter) => eachFilter.folders === folderDetails.id
        // );
        // console.log(filterResource, "filterResource");
        // filterResource.map((eachRound) => {
        //   temp.push({
        //     round_name: eachRound.round_name,
        //     id: eachRound.id,
        //   });
        // });
        console.log(response.data, "hell");
        setResourceList(response.data);
        // handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAdminResourseData = () => {
    let temp = {
      folder_id: folderDetails.id,
    };
    console.log(temp);
    axios
      .post(`${urls.folderWiseDataRetrieve}`, temp, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        setResourceList(response.data);
        console.log(response.data, "hell");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (adminRole?.toString().toLowerCase() === "admin") {
      getAdminResourseData();
    } else {
      getParticularResourseData(folderDetails?.id);
    }
  }, []);
  return (
    <>
      <Typography className="folder-heading" variant="h4">
        Resources
      </Typography>
      <Divider />
      {/* <ViewPdf
          pdfFileLink={data}
          pdfLinks={pdfLinkdInfo || []}
          isDownloadaded={download || false}
          currentIndux={currentIndux}
        /> */}

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {/* <Typography
            className="folder-heading"
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              margin: "auto",
              fontWeight: "bolder",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          >
            Resources
          </Typography> */}
          <Grid
            className="folder-list"
            style={{ margin: "2% 0 0 0" }}
            container
            item
            spacing={3}
          >
            {resourceList &&
              resourceList.map((eachData, index) => {
                return (
                  <>
                    <Grid item xs={3}>
                      <div
                        className="folders"
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleResourceList(eachData)}

                        // onClick={handleResourceList}
                      >
                        <Grid
                          onClick={(e) => {
                            // window.open(eachData?.documents, "_blank");
                            handleOpenResourceDetail(eachData?.documents);
                          }}
                        >
                          {eachData?.documents?.endsWith(".mp4") ||
                          eachData?.documents?.endsWith(".mp3") ||
                          eachData?.documents?.includes(".mp4") ||
                          eachData?.documents?.includes(".mp3") ? (
                            <video
                              id="documents-iframe"
                              style={{
                                width: "150px",
                                // objectFit: 'contain',
                                height: "150px",
                                // height: fullscreen ? '92vh' : '69vh',
                              }}
                              controls
                              // autoPlay
                              controlsList="nodownload"
                            >
                              {eachData?.documents?.endsWith(".mp4") ||
                              eachData?.documents?.includes(".mp4") ? (
                                <source
                                  src={eachData?.documents}
                                  type="video/mp4"
                                />
                              ) : (
                                <source
                                  src={eachData?.documents}
                                  type="audio/mp3"
                                />
                              )}
                              Your browser does not support HTML5 video.
                            </video>
                          ) : eachData?.documents?.endsWith(".docx") ||
                            eachData?.documents?.endsWith(".doc") ||
                            eachData?.documents?.endsWith(".xlsx") ||
                            eachData?.documents?.endsWith(".pdf") ||
                            eachData?.documents?.endsWith(".csv") ? (
                            <div style={{ height: "100px", width: "250px" }}>
                              <FileViewer
                                fileType={
                                  eachData?.documents?.endsWith(".docx")
                                    ? "docx"
                                    : eachData?.documents?.endsWith(".doc")
                                    ? "doc"
                                    : eachData?.documents?.endsWith(".xlsx")
                                    ? "xlsx"
                                    : eachData?.documents?.endsWith(".pdf")
                                    ? "pdf"
                                    : "csv"
                                }
                                filePath={eachData?.documents}
                              />
                            </div>
                          ) : eachData?.documents?.endsWith(".pptx") ||
                            eachData?.documents?.endsWith(".ppt") ? (
                            <iframe
                              id="documents-iframe"
                              title="documents-iframe"
                              src={
                                eachData?.documents?.endsWith(".pptx")
                                  ? `https://view.officeapps.live.com/op/embed.aspx?src=${eachData?.documents}`
                                  : `https://view.officeapps.live.com/op/embed.aspx?src=${eachData?.documents}`
                              }
                              // src={isPPt ? pptFileSrc : `${src}#toolbar=0&navpanes=0&scrollbar=0`}
                              // src={isPPt ? pptFileSrc : `http://docs.google.com/gview?url=${src}&embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                              className="documents-viewer-frame-preview-iframe"
                            />
                          ) : (
                            <img
                              style={{ height: "100px", width: "200px" }}
                              src={eachData?.documents}
                            />
                          )}
                        </Grid>
                        {/* <img
                        style={{ height: "50px", width: "100px" }}
                        src={folder}
                      /> */}
                        <Grid item>
                          <Typography
                            // align="center"
                            // display="block"
                            // variant="caption"
                            style={{
                              margin:
                                eachData?.documents?.endsWith(".mp4") ||
                                eachData?.documents?.endsWith(".mp3") ||
                                eachData?.documents?.includes(".mp4") ||
                                eachData?.documents?.includes(".mp3") ||
                                eachData?.documents?.endsWith(".docx") ||
                                eachData?.documents?.endsWith(".doc") ||
                                eachData?.documents?.endsWith(".xlsx") ||
                                eachData?.documents?.endsWith(".pdf") ||
                                eachData?.documents?.endsWith(".csv") ||
                                eachData?.documents?.endsWith(".pptx") ||
                                eachData?.documents?.endsWith(".ppt")
                                  ? "0px 0px 0px 35%"
                                  : "0px 0px 0px 15%",
                            }}
                            key={eachData.id}
                          >
                            {eachData.resources_title}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </Box>

      {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {column &&
                column.map((eachColumn, index) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "20px",
                        justifyContent: "space-between",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      key={index}
                    >
                      {eachColumn}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceList &&
              resourceList.map((eachData, index) => {
                return (
                  <TableRow key={eachData.id}>
                    <TableCell
                      style={{
                        justifyContent: "space-between",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>

                    <TableCell
                      style={{
                        justifyContent: "space-between",
                        textAlign: "center",
                      }}
                    >
                      {eachData.resources_title}
                    </TableCell>

                    <TableCell
                      style={{
                        justifyContent: "space-between",
                        textAlign: "center",
                        width: "25%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        window.open(eachData?.documents, "_blank");
                      }}
                      onClick={(e) => {
                        // window.open(eachData?.documents, "_blank");
                        handleOpenResourceDetail(eachData?.documents);
                      }}
                    >
                      {eachData?.documents?.endsWith(".mp4") ||
                      eachData?.documents?.endsWith(".mp3") ||
                      eachData?.documents?.includes(".mp4") ||
                      eachData?.documents?.includes(".mp3") ? (
                        <video
                          id="documents-iframe"
                          style={{
                            width: "150px",
                            // objectFit: 'contain',
                            height: "150px",
                            // height: fullscreen ? '92vh' : '69vh',
                          }}
                          controls
                          // autoPlay
                          controlsList="nodownload"
                        >
                          {eachData?.documents?.endsWith(".mp4") ||
                          eachData?.documents?.includes(".mp4") ? (
                            <source
                              src={eachData?.documents}
                              type="video/mp4"
                            />
                          ) : (
                            <source
                              src={eachData?.documents}
                              type="audio/mp3"
                            />
                          )}
                          Your browser does not support HTML5 video.
                        </video>
                      ) : eachData?.documents?.endsWith(".docx") ||
                        eachData?.documents?.endsWith(".doc") ||
                        eachData?.documents?.endsWith(".xlsx") ||
                        eachData?.documents?.endsWith(".pdf") ||
                        eachData?.documents?.endsWith(".csv") ? (
                        <div style={{ height: "100px", width: "250px" }}>
                          <FileViewer
                            fileType={
                              eachData?.documents?.endsWith(".docx")
                                ? "docx"
                                : eachData?.documents?.endsWith(".doc")
                                ? "doc"
                                : eachData?.documents?.endsWith(".xlsx")
                                ? "xlsx"
                                : eachData?.documents?.endsWith(".pdf")
                                ? "pdf"
                                : "csv"
                            }
                            filePath={eachData?.documents}
                          />
                        </div>
                      ) : eachData?.documents?.endsWith(".pptx") ||
                        eachData?.documents?.endsWith(".ppt") ? (
                        <iframe
                          id="documents-iframe"
                          title="documents-iframe"
                          src={
                            eachData?.documents?.endsWith(".pptx")
                              ? `https://view.officeapps.live.com/op/embed.aspx?src=${eachData?.documents}`
                              : `https://view.officeapps.live.com/op/embed.aspx?src=${eachData?.documents}`
                          }
                          // src={isPPt ? pptFileSrc : `${src}#toolbar=0&navpanes=0&scrollbar=0`}
                          // src={isPPt ? pptFileSrc : `http://docs.google.com/gview?url=${src}&embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                          className="documents-viewer-frame-preview-iframe"
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "200px" }}
                          src={eachData?.documents}
                        />
                      )}
                    </TableCell>

                   
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer> */}
      <Dialog
        maxWidth="xl"
        open={isOpenResouurceDialog}
        onClose={handleCloseResourceDetail}
      >
        <ResourceDetailViewer
          close={handleCloseResourceDetail}
          resource={selectedResourcePath}
          eachData={resourceList}
        />
      </Dialog>
    </>
  );
}

export default Resourses;
