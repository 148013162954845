import React, { useState } from 'react';
import { withStyles, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import './modulOnlineClass.css';
import styles from './moduleorOnlineClass.style';

function ModuleOrOnline({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  localStorage.removeItem('principalCourseType');
  const handelOnlineClass = () => {
    if (auth && auth.personal_info && (auth.personal_info.role === 'Teacher' || auth.personal_info.role === 'Deputy Zonal Head' || auth.personal_info.role === 'Business Development Manager' || auth.personal_info.role === 'Assistant Business Development Manager' || auth.personal_info.role === 'Zonal Head- Inbound Marketing' || auth.personal_info.role === 'Cluster Counselor' || auth.personal_info.role === 'Counselor' || auth.personal_info.role === 'Digital marketing head' || auth.personal_info.role === 'MarketingHead' || auth.personal_info.role === 'SEO head' || auth.personal_info.role === 'Digital marketing specialist' || auth.personal_info.role === 'Digital Marketing Executive' || auth.personal_info.role === 'Associate Content and Management Lead' || auth.personal_info.role === 'EA' || auth.personal_info.role === 'FOE' || (auth.personal_info.role === 'Deputy Zonal Head') || auth.personal_info.role === 'LeadTeacher')) {
      window.location = '/online_Class_Start';
    }
  };

  const handleModules = () => {
    if (auth && auth.personal_info && (auth.personal_info.role === 'Teacher' || auth.personal_info.role === 'Deputy Zonal Head' || auth.personal_info.role === 'Business Development Manager' || auth.personal_info.role === 'Assistant Business Development Manager' || auth.personal_info.role === 'Zonal Head- Inbound Marketing' || auth.personal_info.role === 'Cluster Counselor' || auth.personal_info.role === 'Counselor' || auth.personal_info.role === 'Digital marketing head' || auth.personal_info.role === 'MarketingHead' || auth.personal_info.role === 'SEO head' || auth.personal_info.role === 'Digital marketing specialist' || auth.personal_info.role === 'Digital Marketing Executive' || auth.personal_info.role === 'Associate Content and Management Lead' || auth.personal_info.role === 'EA' || auth.personal_info.role === 'FOE' || (auth.personal_info.role === 'Deputy Zonal Head') || auth.personal_info.role === 'LeadTeacher')) {
      localStorage.setItem('principalCourseType', 'trainer_driven');
      localStorage.setItem('coursesType', 'trainer_driven');
      window.location = '/modules';
    }
  };
  return (
    <Grid container className={`${classes.formDiv} ${classes.formColor}`}>
      <Grid item md={6} xs={12} className="responsive" style={{ textAlign: 'center', backgroundColor: 'lightsteelblue' }}>
        <Button
          className="arcButton"
          style={{
            padding: '1rem 1.2rem',
            marginRight: '1rem',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            fontFamily: 'caviarDreams',
            fontWeight: '800',
            backgroundColor: 'transparent',
          }}
          onClick={handleModules}
        >
          <div className="arcBtn">
            Modules
          </div>
        </Button>
      </Grid>
      <Grid item md={6} xs={12} className="responsive" style={{ textAlign: 'center', backgroundColor: 'white' }}>
        <Button
          className="arcButton"
          style={{
            padding: '1rem 1.2rem',
            marginRight: '1rem',
            color: 'black ',
            cursor: 'pointer',
            fontSize: '30px',
            fontWeight: '800',
            fontFamily: 'caviarDreams',
            backgroundColor: 'transparent',
          }}
          onClick={handelOnlineClass}
        >
          <div className="arcBtn">
            Online Training
          </div>
        </Button>
      </Grid>
    </Grid>
  );
}

ModuleOrOnline.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ModuleOrOnline);
