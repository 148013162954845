import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Dialog,
  FormControl,
  InputLabel,
  Box,
  Paper,
  DialogTitle,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import useFetch from "../../../hoc/useFetch";
import urls from "../../../url";
import { useAlert } from "../../../hoc/alert/alert";
import "./createResourse.css";
import Loader from "../../../hoc/loader";
import axios from "axios";
import FolderTopic from "./Add_Folder_Topic/folder_topic";
// import uuidv4 from "uuid/v4";
// import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  rootSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  dialogPaper: {
    height: "250px",
    overflowX: "hidden",
  },
}));

function CreateResources({}) {
  // console.log(edit, editData, "dd");
  const [fileDataInfo, setFileDataInfo] = useState([
    {
      fileData: [],
    },
  ]);

  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const classes = useStyles();
  const alert = useAlert();
  const [schoolId, setSchoolId] = React.useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [permission, setPermission] = useState([]);
  const [checkLoader, setCheckLoader] = useState(false);
  const [roles, setRoles] = useState();
  const [resourseTitle, setResourseTitle] = useState("");
  const [resourseList, setResourseList] = useState("");
  const [folderList, setFolderList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState([]);
  const [isOpenFolderDialog, setIsOpenFolderDialog] = useState(false);

  const {
    data: showSchoolList,
    isLoading: showSchoolListLoading,
    doFetch: fetchSchool,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);

  function getPermissonBranch(id) {
    const auth = JSON.parse(localStorage.getItem("UserLogin"));
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        localStorage.setItem("branch_can_add", false);
      });
  }

  const getParticularRoleData = (id) => {
    axios
      .get(urls.getRole, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("User_Roles"),
        },
      })
      .then((response) => {
        setRoles(response.data.response);

        // handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllFolders = () => {
    axios
      .get(urls.getResourceFolder, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        setFolderList(response.data);

        // handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(schoolId.role_id, "search for role");
  useEffect(() => {
    getPermissonBranch(localStorage.getItem("Branch"));
    getParticularRoleData();
    getAllFolders();
    // if (edit === true) {
    //   roleEditor = roles.filter(
    //     (item) => editData?.role_ids?.indexOf(item.branch.id) !== -1
    //   );
    //   console.log(edit, "hgc");
    //   setSchoolId(editData.roles_ids);
    //   setResourseTitle(editData.resources_title);
    //   setFileDataInfo(editData.documents);
    // }
  }, [checkLoader]);

  let loader = null;
  if (showSchoolListLoading || checkLoader || modulePermissionLoading) {
    loader = <Loader open />;
  }
  useEffect(() => {
    // console.log('TEST 2', fetchSchool)
    fetchSchool({
      url: urls.editSchoolApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Branch"),
      },
    });
  }, [fetchSchool, auth.personal_info.token]);
  const newItem = () => {
    setActionItems([...actionItems, ""]);
  };
  const handleErrors = (response) => {
    if (response.status !== 201) {
      // if (response.status === 409) {
      //   alert.error(`A user already exists with ERP ${newErp}`);
      //   return response;
      // }
      // if (response.status === 404) {
      //   alert.error(`No user found for ERP ${erp}. Kindly register.`);
      //   return response;
      // }
      // if (response.status === 400) {
      //   alert.error(`Error: ${response.message}`);
      //   return response;
      // }
      alert.error(`Error : ${response.message}`);
    }
    return response;
  };

  const updateResourse = (data) => {
    let formData = new FormData();
    // formData.append("id", editData.id);
    formData.append("resources_title", resourseTitle);
    formData.append("roles_id", schoolId?.role_id);

    formData.append("documents", fileDataInfo);
    axios
      .put(urls.UpdateResource, formData, {
        // method: 'POST',
        // body: JSON.stringify(data),
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          //   module : props.module
        },
      })
      .then((response) => {
        alert.success("user updated successfully");
        // props.close();
        window.location.reload();
      })
      .catch((error) => {
        alert.warning("Something went wrong try again");
      });
  };
  const deleteItem = (index) => {
    setActionItems(
      actionItems.filter((item, currentIndex) => currentIndex !== index)
    );
  };

  const getResourceList = () => {
    axios
      .get(`${urls.GetResource}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        alert.success("Resources retrieved successfully");
        // alert.success(response.data.message);
        setResourseList(response.data);
      })
      .catch((error) => {
        // alert.warning("Status not updated !!!");
      });
  };

  const handleSubmit = () => {
    console.log(
      "handleSubmit",
      schoolId.role_id,
      resourseTitle,
      fileDataInfo,
      selectedFolder
    );

    const temp2 = {
      resources_title: resourseTitle,
      roles_id: schoolId.role_id,
      documents: fileDataInfo,
    };
    let tempRolesIds = schoolId.map((each) => each.role_id);
    // let tempFolderIds = selectedFolder.map((each) => each.id);
    console.log(tempRolesIds, "rolesIdsUTPAL");
    // console.log("TEMP", temp)

    async function postResourse() {
      // console.log("documents", fileDataInfo);

      let formData = new FormData();
      formData.append("resources_title", resourseTitle);
      formData.append("roles_id", tempRolesIds);
      formData.append("folders", selectedFolder.id);
      formData.append("documents", fileDataInfo);
      formData.append("is_approved", false);
      setCheckLoader(true);
      const response = await axios.post(urls.AddResource, formData, {
        // method: 'POST', // or 'PUT'
        // body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      });
      //   const fetchData = await response.json();
      return response;
    }

    //   axios.post(`${urls.AddResource}`, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${auth.personal_info.token}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: formData,
    //   });
    //   const data = await response.json();
    //   setCheckLoader(false);
    //   return data;
    // }
    postResourse()
      .then(handleErrors)
      .then((response) => {
        if (response.status === 201)
          alert.success("Successfully Added Resourse");
        // console.log(response);
        getResourceList();
        setCheckLoader(false);
        // alert.success(res)
        setActionItems([]);
        setFileDataInfo([]);
        setResourseTitle("");
        setSchoolId([]);
        setSelectedFolder([]);
        // window.location.reload();
      })
      .catch((err) => {
        setCheckLoader(false);
        alert.warning(`Error:${err}`);
      });
  };

  function functionToValidateFile(data) {
    console.log(data, "ddddddd1");
    setFileDataInfo(data);
  }
  const handleRole = (event, value) => {
    setSchoolId(value);
    console.log(value);
  };
  const handleResourceTitle = (e) => {
    setResourseTitle(e.target.value);
    // console.log(e.target.value, "ggg1");
  };

  const handleCloseFolderDialog = () => {
    setIsOpenFolderDialog(false);
  };

  const handleFolderOpenDialog = () => {
    setIsOpenFolderDialog(true);
  };

  // console.log(folderList, "folderList");

  return (
    <>
      <Box style={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                fullWidth
                size="small"
                className="filter-student meeting-form-input"
                options={roles || []}
                getOptionLabel={(option) => option?.role_name || ""}
                filterSelectedOptions
                value={schoolId || []}
                onChange={(event, value) => {
                  handleRole(event, value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    variant="outlined"
                    label="Select Role"
                  />
                )}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    {option?.role_name}
                    {option.is_orchids ? "  - Orchids" : ""}
                  </React.Fragment>
                )}
              />
              {/* <FormControl
                margin="dense"
                variant="outlined"
                className="select-role"
                // error={roundFlag}
                style={{ width: "60%" }}
              >
                <InputLabel id="category">Select Role</InputLabel>
                <Select
                  id="demo-simple-select-required"
                  labelId="demo-simple-select-required-label"
                  margin="dense"
                  fullWidth
                  value={schoolId || ""}
                  onChange={(e) => handleRole(e)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black" }}
                >
                  <MenuItem value="">
                    <em>__Select__</em>
                  </MenuItem>

                  {roles &&
                    roles.length !== 0 &&
                    roles.map((data) => (
                      <MenuItem value={data}>
                        {data.role_name ? data.role_name : ""}{" "}
                        {data.is_orchids ? "  - Orchids" : ""}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Grid>
            {/* <Grid item xs={6}></Grid> */}
            <Grid item xs={6}>
              <Grid style={{ marginLeft: "30%" }}>
                {/* <Typography>Add Folder</Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={resourseTitle || ""}
                  onChange={(e) => handleResourceTitle(e)}
                  style={{ width: "50%" }}
                  label="Folder name"
                  variant="outlined"
                /> */}
                <Button
                  variant="contained"
                  onClick={(e) => handleFolderOpenDialog(e)}
                  color="primary"
                  //   disabled={actionItems.length === 0}
                  style={{ margin: "0 0 0 5%", width: "50%" }}
                >
                  Add Folder
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              {/* <Autocomplete
                multiple
                fullWidth
                size="small"
                className="filter-student meeting-form-input"
                options={folderList || []}
                getOptionLabel={(option) => option?.folder_name || ""}
                filterSelectedOptions
                value={selectedFolder || []}
                onChange={(event, value) => {
                  setSelectedFolder(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    variant="outlined"
                    label="Select Folder"
                  />
                )}
                renderOption={(option, { selected }) => (
                  <React.Fragment>{option?.folder_name}</React.Fragment>
                )}
              /> */}
              <FormControl
                margin="dense"
                variant="outlined"
                className="select-folder"
                // error={roundFlag}
                style={{ width: "60%" }}
              >
                <InputLabel id="category">Select Folder</InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  value={selectedFolder || ""}
                  onChange={(e) => setSelectedFolder(e.target.value)}
                  label="Select Folder"
                >
                  <MenuItem value="">
                    <em>__Select__</em>
                  </MenuItem>

                  {folderList &&
                    folderList.map((eachFolder) => {
                      return (
                        <MenuItem id={eachFolder.id} value={eachFolder}>
                          {eachFolder.folder_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={4}>
              <Typography>Item5</Typography>
                  </Grid> */}
            {/* <Grid item style={{ marginLeft: "50%" }} xs={6}>
              
            </Grid> */}
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                value={resourseTitle || ""}
                onChange={(e) => handleResourceTitle(e)}
                label="resourse title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                style={{ display: "none" }}
                className={classes.fileUpload}
                id="outlined-button-file"
                type="file"
                onChange={(e) => functionToValidateFile(e.target.files[0])}
              />
              <label htmlFor="outlined-button-file">
                <b style={{ fontFamily: "" }}>Upload File</b>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.fileUpload}
                  startIcon={<CloudUploadIcon />}
                >
                  Browse
                </Button>{" "}
                &nbsp;&nbsp;
                {/* {(typeof fileDataInfo[0] === "string" &&
              getFileUrl() && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getFileUrl()}
                >
                  click to View
                </a>
              )) ||
              Object.values(fileDataInfo).map((item, index) => (
               
              ))} */}
                <b>
                  {fileDataInfo.name}
                  &nbsp;&nbsp;
                </b>
              </label>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={(e) => handleSubmit(e)}
                color="primary"
                //   disabled={actionItems.length === 0}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container item spacing={3}>
            <Grid item xs={4}>
              <Typography>Item10</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Item11</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Item12</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <Typography>Item13</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Item14</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Item15</Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
      {loader}
      <Dialog
        maxWidth="xs"
        style={{ margin: "35px 0 0 0" }}
        classes={{ paper: classes.dialogPaper }}
        open={isOpenFolderDialog}
        onClose={handleCloseFolderDialog}
      >
        <DialogTitle className="dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bolder" }} variant="h6">
              Add Folder
            </Typography>
            <Button
              className="weekly-report-actions-butons"
              onClick={() => handleCloseFolderDialog()}
            >
              <CloseIcon />
            </Button>
          </div>
        </DialogTitle>
        <FolderTopic
          close={handleCloseFolderDialog}
          // submit={handleFolderSubmit}
          setIsOpenFolderDialog={setIsOpenFolderDialog}
        />
      </Dialog>
    </>
  );
}

export default CreateResources;
