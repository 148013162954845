import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from './registration.style';
import useFetch from '../../../../hoc/useFetch';
import { useAlert } from '../../../../hoc/alert/alert';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loader from '../../../../hoc/loader';
import urls from '../../../../url';
import axios from 'axios';


const Registration = (props ) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [erp, setErp] = useState('');
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [branch, setBranch] = useState([]);
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState("");
  // const PORTNum = 'http://localhost:8000';
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [specificRoleData, setSpecificRoleData] = useState([]);


  const filterEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const alert = useAlert();

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch();

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranch,
  } = useFetch();

  useEffect(() => {
    let url=props.formType==='Update'?`${urls.getRoleRegistration}`:`${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`;
    fetchRoles({
      url: (url),  
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : props.module
      },
    });
    fetchBranch({
      url: urls.branchApi,
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : props.module
      },
    });
  }, [fetchRoles, fetchBranch, auth.personal_info.token]);


  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : props.module

      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  const getParticularUser = (id) => {
    axios.get(urls.getUserById+id+'/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : props.module

      },
    }).then(response => {
      setErp(response.data.data.erp);
      setName(response.data.data.name);
      setEmail(response.data.data.email);
      setPhoneNo(response.data.data.phone_number);
      setRole(response.data.data.roles);
      let tempBranch = [];
      response.data.data && response.data && response.data.data.branch && response.data.data.branch.map(eachBranch => {
        tempBranch.push(eachBranch.id)
      })
      setBranch(tempBranch)
    }).catch(error => {
      console.log(error);
    })
  }

  const getParticularRoleData = (id) => {
    axios.get(urls.getRole+`${id}/details/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Users')
      }
    })
      .then((response) => {
        setSpecificRoleData(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getParticularRoleData(role)

  }, [role])

  useEffect(() => {
    console.log(props, "result1 props data");
    if(props.formType === "Update")
    {
      getParticularUser(props.dataToEdit.id);
    }
  }, [props.formType])

  useEffect(() => {
    getPermissonData(localStorage.getItem('Registration'))
  }, [])

  const registerFunction = () => {
    if (!erp || !phoneNo || !name || !email || !role || !branch) {
      alert.warning('Select All Fields');
      return;
    }
    if (email.match(/[a-z@]/i)) {
      // eslint-disable-next-line no-useless-escape
      // const filter = /^([a-zA-Z0-9_\.\-])+\@gmail\.com$/;
      // const filterEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filterEmail.test(email)) {
        alert.warning('Please enter valid Email...');
        return;
      }
    }
    const data = {
      erp : erp,
      name : name,
      phone_number: phoneNo,
      email : email,
      roles: role,
      branch : branch,
      password :  password,
    };
    if(props.formType === "Update")
    {
      axios.put(urls.updateUser+props.dataToEdit.id+'/', data,  {
        // method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module : props.module
        },
      })
      .then(response => {
        alert.success('user updated successfully');
        props.close();
        window.location.reload();
      }).catch(error => {
        alert.warning('Something went wrong try again');
      })
    }
    else {
        console.log(data, 'result1 created object');
        async function loading() {
          setLoad(true);
          const response = await fetch(urls.formRegister, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.personal_info.token}`,
              module : props.module
            },
          });
          const res = await response.json();
          return res;
        }
        loading().then((res) => {
          setLoad(false);
          if (res === 'Success') {
            alert.success('user registration success');
            props.close();
            window.location.reload();
          } else {
            alert.warning('user already exists');
          }
       
    });
  }
  };

  let loader = null;
  if (roleListLoading || branchListLoading) {
    loader = <Loader open />;
  }
  if (load) {
    loader = <Loader open />;
  }
  // eslint-disable-next-line no-useless-escape
  // const filterEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return (
    <>
    {/* {permission.can_view ?  */}
    <>
      <Typography variant="h4" style = {{padding : '10px'}}>{props.formType} User</Typography>
      <Divider />
      <Paper style = {{padding : '10px'}}>
        <Grid container spacing={2} >
          <Grid item md={6} xs={12}>
            <TextField
              label="ERP No"
              margin="dense"
              fullWidth
              required
              value={erp}
              onChange={(e) => e.target.value.length < 12 && setErp(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Name"
              margin="dense"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
          </Grid>


          <Grid item md={6} xs={12}>
          <FormControl
              variant="outlined"
              margin="dense"
              className="user_password"
              style = {{width: '100%'}}
            >
            <InputLabel>Select Branch</InputLabel>
            <Select
              label="Branch"
              fullWidth
              required
              multiple
              value={branch || []}
              onChange={(e) => setBranch(e.target.value)}
            >
              {branchList
                && branchList.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.branch_name}
                  >
                    {data.id ? data.branch_name : ''}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          </Grid>
          {props.formType === "Update"?
          <Grid item md={6} xs={12}>
          <TextField
              fullWidth
              name="Password"
              required={true}
              label="Password"
              margin="dense"
              value={password}
              variant="outlined"
              // error={passwordFlag}
              // helperText={passwordHelper}
              // onBlur={validatePassword}
              onChange={(e) => setPassword(e.target.value)}
              className="user_password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                // <-- toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="toggle-password-eye"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </Grid>
          
          :<Grid item md={6} xs={12}>
          <TextField
              fullWidth
              disabled
              name="Password"
              required={true}
              label="Password"
              margin="dense"
              // value={password}
              variant="outlined"
              helperText="By Default Password is same as ERP..!"
            />

          </Grid>
          
          }

          
          <Grid item md={6} xs={12}>
            <TextField
              label="Email"
              margin="dense"
              type="email"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
            {email && !filterEmail.test(email) ? (
              <FormHelperText
                id="component-error-text"
                style={{ color: 'red' }}
              >
                In Valid Email Address
              </FormHelperText>
            ) : null}
            {email && filterEmail.test(email) ? (
              <FormHelperText
                id="component-error-text"
                style={{ color: 'green' }}
              >
                Valid Email Address
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Phone No"
              type="number"
              margin="dense"
              fullWidth
              required
              value={phoneNo}
              // eslint-disable-next-line max-len
              onChange={(e) => setPhoneNo(
                Math.max(0, parseInt(e.target.value, 10))
                  .toString()
                  .slice(0, 10),
              )}
              variant="outlined"
            />
          </Grid>


          <Grid item md={6} xs={12}>
          <FormControl
              variant="outlined"
              margin="dense"
              className="user_password"
              style = {{width: '100%'}}
            >
            <InputLabel>Select Role</InputLabel>
              <Select
                label="Role"
                margin="dense"
                fullWidth
                className='user_password'
                required
                value={role || ''}
                onChange={(e) => setRole(e.target.value)}
                variant="outlined"
              >
                {roleList
                  && roleList.response.map((data) => (
                    <MenuItem
                      value={data.role_id}
                      key={data.role_id}
                      name={data.role__name}
                    >
                      {data.role_id ? data.role_name : ''}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
              <Typography style = {{fontSize: '14px', padding : '5px', color : '#4051B5', fontWeight : 'bold'}}>{specificRoleData.user_type} | {specificRoleData && specificRoleData.length !== 0 ? specificRoleData.is_orchids ?'ORCHIDS' : 'NON ORCHIDS' : null}</Typography>

            </Grid>
          {/* <Grid item md={12} xs={12}>
                        <TextField
                            label="Address"
                            margin="dense"
                            fullWidth
                            required
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                            variant="outlined"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                        />
                    </Grid> */}
          
          <Grid item md={8} xs={9}/>
          <Grid item md={2} xs={2}>
            <Button onClick = {(e) => props.close()}>
              Close
            </Button>
          </Grid>

          
          <Grid item md={2} xs={2}>
          {permission.can_add ? 
            <Button
              variant="contained"
              color="primary"
              onClick={() => registerFunction()}
            >
              {props.formType}
            </Button>
            :null}
          </Grid>
        </Grid>
      </Paper>
      </>
       {/* :null} */}
      {loader}
    </>
  );
};
// Registration.propTypes = {
//   classes: PropTypes.instanceOf(Object).isRequired,
  
// };
export default Registration;