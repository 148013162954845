/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { useAlert } from '../../../hoc/alert/alert';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
// import Main from './Main';
// import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';
import Article from './article';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';
import Loader from '../../../hoc/loader';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    // fit: scale,
    // flex: 1,
    // width: 300,
    // height: '100%',
    // resizeMode: 'cover',
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
}));

const mainFeaturedPost = {
  title: 'Sure Learning',
  description: 'The Blog',
  image: 'https://source.unsplash.com/random',
  imgText: 'main image description',
  linkText: '',
};

const featuredPosts = [
  {
    id: '1',
    title: 'Featured post',
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id: '2',
    title: 'Post title',
    date: 'Nov 11',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id: '3',
    title: 'Featured post',
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id: '4',
    title: 'Featured post',
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id: '5',
    title: 'Featured post',
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
];

const posts = [post1, post2, post3];

const sidebar = {
  title: 'About',
  description:
    'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'GitHub', icon: GitHubIcon },
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Facebook', icon: FacebookIcon },
  ],
};

export default function Blog() {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const classes = useStyles();
  // const [] = useState('');
  const alert = useAlert();
  let loader = null;
  // const { sections, title } = props;
  const [value, setValue] = useState(null);
  const [loadMask, setLoadMask] = useState(false);

  const {
    data: blogList,
    isLoading: blogListLoading,
    doFetch: fetchBlogs,
  } = useFetch(null);

  const {
    data: categoriesList,
    // isLoading: categoriesListLoading,
    doFetch: fetchCategories,
  } = useFetch(null);

  // const [filteredBlogListResults, setFilteredBlogListResults] = useState(blogList.results);
  const [displayBlog, setDisplayBlog] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    async function loading() {
      setLoadMask(true);

      const response = await fetch(`${urls.getCategoryFilteredBlogsApi}?category_id=${newValue}`, {
        method: 'GET',

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Blogs')
        },
      });
      const res = await response.json();
      return res;
    }
    loading()
      .then((res) => {
        setLoadMask(false);
        // eslint-disable-next-line no-console
        console.log(res);
        setDisplayBlog(res);
      })
      .catch(
        (error) => {
          setLoadMask(false);
          alert.warning(`Error:${error}`);
        },
      );
  };
  const readFullArticle = (post) => {
    localStorage.setItem('post', JSON.stringify(post));
  };
  useEffect(() => {
    async function loading() {
      setLoadMask(true);

      const response = await fetch(`${urls.getBlogAPI}`, {
        method: 'GET',

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Blogs')
        },
      });
      const res = await response.json();
      return res;
    }
    loading()
      .then((res) => {
        setLoadMask(false);
        // eslint-disable-next-line no-console
        console.log(res);
        setDisplayBlog(res.results);
      })
      .catch(
        (error) => {
          setLoadMask(false);
          alert.warning(`Error:${error}`);
        },
      );
    fetchCategories({
      url: `${urls.getBlogCategoriesList}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Blogs')
      },
    });
  }, [fetchCategories, auth.personal_info.token]);

  if (blogListLoading || loadMask) {
    loader = <Loader open />;
  }
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          {/* <img src="https://source.unsplash.com/random" alt="" style={{ height: 390, width: 1300, paddingTop: 22 }} /> */}
          {/* <Header title="" sections={sections} /> */}
          <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
            {categoriesList && categoriesList.map((category, i) => (
              <Tabs
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                value={value || i + 1}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  key={category.id}
                  label={category.category_title}
                  value={category.id}
                />
              </Tabs>
            ))}
          </Toolbar>
          <Grid container spacing={4} style={{ marginTop: '20px' }}>
            {displayBlog && displayBlog.map((post) => (
              // <FeaturedPost key={post.id} post={post} />
              <Grid key={post.id} item xs={12} md={6}>
                <CardActionArea href="/full-article" onClick={() => readFullArticle(post)}>
                  {/* <CardMedia
                    className={classes.media}
                    image={post.blog_header_image}
                    title={post.blog_title}
                  /> */}
                  <img src={`${urls.s3}${post.blog_header_image.substring(32)}`} alt="" fullWidth style={{ height: 400, width: 600 }} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {ReactHtmlParser(post.blog_title)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="span">
                      {ReactHtmlParser && post.blog_content!==null && (post.blog_content.length<200
                        ? post.blog_content
                        : post.blog_content.substring(0, 200))}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </main>
      </Container>
      <Footer title="" description="" />
      {loader}
    </>
  );
}
