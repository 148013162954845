export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    overflow: true,
    backgroundColor: 'snow',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  answerPaper: {
    color: 'lightgray',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
    width: '100%',
  },
  button: {
    marginTop: '10px',
    marginLeft: '2px',
  },
  typography: {
    backgroundColor: 'snow',
    marginTop: '20px',
  },
  typographyDeesc: {
    padding: theme.spacing(1, 2, 1),
  },
  TypographyDate: {
    padding: theme.spacing(1, 2, 0),
  },
  Griddata: {
    padding: theme.spacing(1, 4, 1),
  },
  textField: {
    width: '100%',
  },
});
