import Background from '../../../Media/bookImage.webp';

export default () => ({
  lessonBackground: {
    backgroundColor: '#85c5fb',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    position: 'relative',
  },
  top: {
    color: '#eef3fd',
    position: 'relative',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bookImgDiv: {
    width: '65%',
    height: '88vh',
    margin: '0 auto',
    paddingTop: '12vh',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  firstbox: {
    width: 'auto',
    height: 'auto',
    margin: '0 auto',
  },
});
