export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  box: {
    padding: theme.spacing(1, 2, 1),
    color: 'lightgray',
  },
  typography: {
    fontFamily: 'Charter Bd BT',
    color: 'darkslategray',
    marginRight: '20px',
  },
  appBar: {
    padding: theme.spacing(4, 3, 3),
    backgroundColor: 'lightgray',
  },
  Message: {
    color: 'blue',
    textAlign: 'center',
    marginTop: '50px',
  },
  textMessage: {
    color: 'dimgray',
    textAlign: 'center',
  },
  Avatar: {
    marginLeft: '46%',
    marginTop: '10px',
  },
});
