import React from 'react';
import './BackDrop.css';
import PropTypes from 'prop-types';

function BackDrop({ click }) {
  return (<button type="submit" aria-label="Mute volume" tabIndex={0} className="backdrop" onClick={click} />);
}

BackDrop.defaultProps = {
  click: () => {},
};

BackDrop.propTypes = {
  click: PropTypes.func,
};
export default BackDrop;
