import React from 'react';
import { Button, IconButton } from '@material-ui/core';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import internetIcon from '../Media/UdaanHome/internet.svg';
import orchidsLogo from '../Media/UdaanHome/orchids-logo.png';
import justLogo from '../Media/UdaanHome/Just Logo-01.png';
import './contactUs.css';
// import arc from './images/arc.svg';

function ContactUs() {
  return (
    <>
      <footer className="mainPageFooterDiv">
        <div className="contactDiv" style={{ marginTop: '15%' }}>
          <h2>Address</h2>
          <p>MUMBAI OFFICE :</p>
          <p>Prince House 51/3,</p>
          <p>Marol Co-op. Industrial Estate,</p>
          <p>M.V. Road, Mumbai - 400059</p>
          <iframe
            title="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.925112339994!2d72.88256064285281!3d19.110940961803664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c81569afae91:0x16a1a5b60f33144f!2sPrince House!5e0!3m2!1sen!2sin!4v1613454046662!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder="0"
            className="mapFrame"
            style={{ border: '0' }}
            allowFullScreen=""
          />
          <div style={{ fontSize: '22px', padding: '20px' }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.orchids.edu.in"
              style={{ textDecoration: 'none' }}
            >
              <Button>
                <img
                  alt="crash"
                  src={internetIcon}
                  className="internetIcon"
                  width="25px"
                  height="25px"
                />
                                &nbsp; www.orchids.edu.in
              </Button>
            </a>
            <div className="socialMediaDiv">
              <span className="socialMediaLink">
                <IconButton>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/Sure-Learning-108204044308528/"
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src="https://img.icons8.com/fluent/50/000000/facebook-new.png"
                      alt="crash"
                    />
                  </a>
                </IconButton>
              </span>
              <span className="socialMediaLink">
                <IconButton>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/surelearning?igshid=1i8rs81ym0hag"
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src="https://img.icons8.com/fluent/48/000000/instagram-new.png"
                      alt="crash"
                    />
                  </a>
                </IconButton>
              </span>
              <span className="socialMediaLink">
                <IconButton>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCcwkcto7bF2zeP74Spy9cvg"
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src="https://img.icons8.com/fluent/48/000000/youtube-play.png"
                      alt="crash"
                    />
                  </a>
                </IconButton>
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            alt="crash"
            src={justLogo}
            width="100%"
            height="100%"
            className="responsive"
          />
          <h1>Sure Learning</h1>
          <p>Empowered 2000+ teachers for</p>
          <img
            alt="crash"
            src={orchidsLogo}
            width="60%"
            height="60%"
          />
        </div>
      </footer>
    </>
  );
}

export default ContactUs;
