import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './SideDrawer.css';

function SideDrawer({ show }) {


  const [isOrchidsEmail,setisOrchidsEmail] = useState(true);

  const handleLogoutClose = () => {
    localStorage.removeItem('UserLogin');
    localStorage.setItem('isLoggedIn', false);
    window.location.href = '/';
  };
  let drawerClasses = 'sideDrawer';
  if (show) {
    drawerClasses = 'sideDrawer open';
  }

  const loginData = JSON.parse(localStorage.getItem('UserLogin'))
  const role = loginData && loginData.personal_info && loginData.personal_info.role;
  // var orchidsMail = loginDetail.academic_profile.user.email.includes("@orchids.edu.in");

  // var n = loginData.academic_profile.user.email.includes("@orchids.edu.in");
  var n = loginData.role_permission.is_orchids;
  useEffect(() => {
        // console.log("n "+n);
    if(!n){
        setisOrchidsEmail(false);
    }else{
      setisOrchidsEmail(true);
    }
  })
  const handleSelfDriven = () => {
    localStorage.setItem('principalCourseType', 'self_driven');
  };
  const urlFun = ()=>{
    if(role==='Principal'){
      return  <a href="/principalDashboard/" onClick={handleSelfDriven}>Reports</a>
    }
    if(role==='Coordinator'){
      return  <a href="/principalDashboard/visualDashboard" onClick={handleSelfDriven}>Reports</a>
    }
    if (role==='Teacher'){
      return  <a href="/teacherReport" onClick={handleSelfDriven}>Reports</a>
    }
    return null
  }
  return (
    <nav className={drawerClasses}>
      <ul>
        <li>
          <a href="https://www.loom.com/share/9ac3aac3622240f19f07cd4b6e27e875">
            Get Started
          </a>
        </li>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        {localStorage.getItem('Subject_Training')!=="null" ?
        <li>
            <a href="/SubjectTraining">Subject Training</a>
        </li>:null}
        {isOrchidsEmail?(
          <>
            {localStorage.getItem('Induction_Training')!=="null" ?
            <li>
              <a href="/inductionTraining">Induction Training</a>
            </li>:null}
            { localStorage.getItem('Self_Driven_Training')!=="null" || localStorage.getItem('Trainer_Driven')!=="null" ?
            <li>
              <a href="/inHouseCourses">Courses</a>
            </li>:null}
          </>
        ):(<li>
            {urlFun()}
          </li>)
        }
        {localStorage.getItem('Treasure_Box')!=="null" ?
        <li>
          <a href="/inHouseTreasureBox">Treasure Box</a>
        </li>:null}
        {localStorage.getItem('Blogs')!=="null" ?
        <li>
          <Link to="/activity">Blog</Link>
        </li>:null}
        {localStorage.getItem('Learnig_Videos')!=="null" ?
        <li>
          <Link to="/inhouse_Learning_videos">Learning</Link>
        </li>:null}
        {localStorage.getItem('Learning_Notes')!=="null" ?
        <li>
          <Link to="/learning_note_view"> My Notes</Link>
        </li>:null}
        {localStorage.getItem('Notification')!=="null" ?
        <li>
          <Link to="/view_notification">Notifications</Link>
        </li>:null}
        {localStorage.getItem('isLoggedIn')!=="null" ?
        <li>
          <Link to="/inhouse_calendar">Calendar</Link>
        </li>:null}
        {/* <li style={{ borderRight: '1px solid transparent' }}>
          <a href="/contactUs">Contact</a>
        </li> */}
        {/* {localStorage.getItem('Profile')!=="null" ? */}
        <li style={{ borderRight: '1px solid transparent' }}>
          <a href="/user_profile">View Profile</a>
        </li>
        {localStorage.getItem('Change_Password')!=="null" ?
        <li style={{ borderRight: '1px solid transparent' }}>
          <a href="/change_password">Change Password</a>
        </li>:null}
        <li>
          <button
            style={{ borderRight: '1px solid transparent' }}
            type="submit"
            tabIndex={0}
            onClick={handleLogoutClose}
            className="logoutLink"
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
}
// SideDrawer.defaultProps = {
//   show: () => {},
// };

SideDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default SideDrawer;

