export default () => ({
  formDiv: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f8c853',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '5vh',
  },
  formColor: {
    color: '#b75c4a',
  },
});
