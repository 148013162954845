/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles, Button, Grid, Typography, Box,
} from '@material-ui/core';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import PropTypes from 'prop-types';
// import ideabulb from '../../Media/idea.png';
import styles from './TrainingModules.style';
import urls from '../../../../url';
import useFetch from '../../../../hoc/useFetch';
import image from '../../../../components/reusableComponents/courseInfoModal/course.jpg';
// import Loader from "../../../../hoc/loder.gif";
import '../../InhouseCourses/CoursesTraining.css';
import axios from 'axios';


function TrainingModule({ classes, history }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [permission, setPermission] = useState([]);


  let [courseType] = useState(localStorage.getItem('coursesType'));
  // const [PrincipalcourseType] = useState(localStorage.getItem('principalCourseType'));
  if (courseType !== 'induction_training') {
    if (history.location.pathname === '/teacherDashboard/modules') {
      // if (PrincipalcourseType === 'self_driven') {
      //   if (courseType === 'self_driven') {
      localStorage.setItem('coursesType', 'self_driven');
      // }
    }
  }

  [courseType] = useState(localStorage.getItem('coursesType'));
  const [subjectDataID] = useState(localStorage.getItem('subjectDataID'));
  // const [modules, setModulesData] = useState();
  // const [loader, setLoader] = useState(false);
  const classIdNo = sessionStorage.getItem('classId');

  // For Permissions
  function getPermissonData() {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Self_Courses')

      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData()
  }, [])  

  const {
    data: modules,
    isLoading: gettingModules,
    doFetch: fetchModules,
  } = useFetch(null);

  // async function getModules() {
  //   setLoader(true);
  //   let URL;
  //   if (courseType === 'induction_training' && auth.personal_info &&
  // auth.personal_info.role === 'Teacher') {
  //     if (subjectDataID) {
  //       URL = `${urls.inHouseModules}?subject=${subjectDataID}&${courseType}=true`;
  //     } else {
  //       URL = `${urls.inHouseModules}?${courseType}=true`;
  //     }
  //   } else if (
  //     ((auth.personal_info && auth.personal_info.role === 'AcademicHeads')
  //       || (auth.personal_info && auth.personal_info.role === 'Planner')
  //       || (auth.personal_info && auth.personal_info.role === 'Coordinator')
  //       || (auth.personal_info && auth.personal_info.role === 'AcademicManager')
  //       || (auth.personal_info && auth.personal_info.role === 'Principal'))
  //     && courseType === 'trainer'
  //   ) {
  //     URL = `${urls.inHouseModules}?${courseType}=true&class_id=${classIdNo}`;
  //   } else {
  //     URL = `${urls.inHouseModules}?${courseType}=true`;
  //   }
  //   const fetchResult = fetch(URL, {
  //     method: 'GET',
  //     cache: 'reload',
  //     headers: {
  //       'Content-Type': 'Application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   });
  //   const response = await fetchResult;
  //   const jsonData = await response.json();
  //   setLoader(false);
  //   return jsonData;
  // }

  useEffect(() => {
    // getModules().then((data) => setModulesData(data));
    let URL;
    if (
      courseType === 'induction_training'
      // && auth.personal_info
      // && (auth.personal_info.role === 'Teacher' || auth.personal_info.role === 'Deputy Zonal Head' || auth.personal_info.role === 'Business Development Manager' || auth.personal_info.role === 'Assistant Business Development Manager' || auth.personal_info.role === 'Zonal Head- Inbound Marketing' || auth.personal_info.role === 'Cluster Counselor' || auth.personal_info.role === 'Counselor' || auth.personal_info.role === 'Digital marketing head' || auth.personal_info.role === 'MarketingHead' || auth.personal_info.role === 'SEO head' || auth.personal_info.role === 'Digital marketing specialist' || auth.personal_info.role === 'Digital Marketing Executive' || auth.personal_info.role === 'Associate Content and Management Lead' || auth.personal_info.role === 'EA' || auth.personal_info.role === 'FOE')
    ) {
      if (subjectDataID) {
        URL = `${urls.inHouseModules}?subject=${subjectDataID}&${courseType}=true`;
      } else {
        URL = `${urls.inHouseModules}?${courseType}=true`;
      }
    } else if (
      // ((auth.personal_info && auth.personal_info.role === 'AcademicHeads')
      //   || (auth.personal_info && auth.personal_info.role === 'Planner')
      //   || (auth.personal_info && auth.personal_info.role === 'Coordinator')
      //   || (auth.personal_info && auth.personal_info.role === 'AcademicManager')
      //   || (auth.personal_info && auth.personal_info.role === 'Principal')
      //   || (auth.personal_info && auth.personal_info.role === 'LeadTeacher'))
       courseType === 'trainer'
    ) {
      URL = `${urls.inHouseModules}?${courseType}=true&class_id=${classIdNo}`;
    } else {
      URL = `${urls.inHouseModules}?${courseType}=true`;
    }
    fetchModules({
      url: URL,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Self_Courses')
      },
    });
  }, [auth]);

  const moduleClick = (ratingStatus, id, name) => {
    if (auth.personal_info.role === 'Teacher' && (courseType === 'is_training_course' || courseType === 'self_driven')) {
      history.push('/teacherDashboard/chapters');
    } else {
      history.push('/chapters');
    }
    sessionStorage.setItem('moduleId', id);
    sessionStorage.setItem('moduleName', name);
    sessionStorage.setItem('ratingStatus', ratingStatus);
  };

  // useEffect(() =>{
  //   console.log("Modules", modules)

  // },[modules])

  return (
    <div>
      {permission.can_view ? 
      <div
        className={classes.trainingModule}
        style={{
          height: modules && modules.length >= 4 ? '100%' : '100vh',
          paddingTop: modules && modules.length >= 8 ? '15vh' : '0',
        }}
      >
        {gettingModules ? (
          <>
            <img
              // open={open}
              className={classes.progress}
              alt="crash"
              src={require('../../../../hoc/loder.gif')}
              width="100%"
              height="100%"
            />
          </>
        ) : (
          <>
            {/* <div style={{ color: "white", marginLeft: "15%" }}>
              <span>
                Training Total Hours:
                <b>{modules && modules.training_duration}</b>
              </span>
              ,
              <span>
                Training Pending Duration :
                <b>{modules && modules.pending_duration}</b>
              </span>
            </div>
            <br /> */}
            {modules && modules.length === 0 && (
              <div className={classes.moduleBox}>
                <Typography
                  variant="h5"
                  style={{
                    marginTop: '50px',
                    textAlign: 'center',
                    color: 'blue',
                  }}
                >
                  No Courses Are Published
                </Typography>
              </div>
            )}
            <div className={classes.moduleBox}>
              <Grid container spacing={1}>
                {modules
                  && modules.length !== 0
                  && modules.map((module, index) => {
                    const colors = [
                      '#54688c',
                      '#f47a62',
                      '#4a66da',
                      '#75cba8',
                      // "#f2bf5e"
                    ];
                    const diffColors = index % 4;
                    return (
                      <Grid item xs={12} md={3} style={{ marginTop: '10px' }} key={module.id}>
                        <Box
                          border={3}
                          className={classes.boxEffect}
                          style={{ borderColor: 'lightgray', borderRadius: '9px', backgroundColor: colors[diffColors] }}
                        >
                          <Grid container spacing={1} style={{ textAlign: 'center' }}>
                            <Grid item md={12} xs={12}>
                              <img
                                src={module.course_image || image}
                                alt="course"
                                height="180px"
                                width="100%"
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography
                                className={classes.firstHead}
                                title={module.course_name}
                                style={{
                                  display: 'contents', color: 'white', textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', fontWeight: 'bold',
                                }}
                              >
                                {module.course_name}
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography style={{ color: 'white', fontSize: '17px' }}>
                                {(courseType === 'trainer_driven' && 'Trainer Driven') || ''}
                                {(courseType === 'induction_training' && 'Induction') || ''}
                                {(courseType === 'self_driven' && 'Self Driven') || ''}
                                {(courseType === 'is_training_course' && 'Training Course') || ''}
                                {(courseType === 'trainer' && 'Trainer') || ''}
                              </Typography>
                            </Grid>
                            {module.start_date && module.end_date
                                && (
                                <Grid item md={12} xs={12} style={{ padding: '6px' }}>
                                  <table className={classes.table} style={{ color: 'white', textAlign: 'center' }}>
                                    <thead>
                                      <tr>
                                        <th>
                                          <b style={{ display: 'inline-flex' }}>
                                            Start Date&nbsp;
                                            <InsertInvitationIcon style={{ fontSize: '17px' }} />
                                          </b>
                                        </th>
                                        <th>
                                          <b style={{ display: 'inline-flex' }}>
                                            End Date&nbsp;
                                            <InsertInvitationIcon style={{ fontSize: '17px' }} />
                                          </b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{(module.start_date && new Date(module.start_date).toString().split('G')[0].substring(0, 16))}</td>
                                        <td>{(module.end_date && new Date(module.end_date).toString().split('G')[0].substring(0, 16))}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <Typography style={{ color: 'white', fontSize: '15px' }}>
                                    Start Date :&nbsp;
                                    {(module.start_date && new Date(module.start_date).toString().split('G')[0].substring(0, 16))}
                                  </Typography>
                                  <Typography style={{ color: 'white', fontSize: '15px' }}>
                                    End Date :&nbsp;
                                    {(module.end_date && new Date(module.end_date).toString().split('G')[0].substring(0, 16))}
                                  </Typography> */}
                                </Grid>
                                )}
                            <Grid item md={12} xs={12} style={{ padding: '6px' }}>
                              <table className={classes.table} style={{ color: 'white', textAlign: 'center' }}>
                                <thead>
                                  <tr>
                                    <th>
                                      <b style={{ display: 'inline-flex' }}>
                                        Total Hours&nbsp;
                                        <AccessAlarmIcon style={{ fontSize: '17px' }} />
                                      </b>
                                    </th>
                                    <th>
                                      <b style={{ display: 'inline-flex' }}>
                                        Pending Hours&nbsp;
                                        <AccessAlarmIcon style={{ fontSize: '17px' }} />
                                      </b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{module.total_duration}</td>
                                    <td>{module.pending_hours}</td>
                                  </tr>
                                </tbody>
                              </table>
                              {/* <Typography style={{ color: 'white', fontSize: '15px' }}>
                                Total Hours :&nbsp;
                                {module.total_duration}
                              </Typography>
                              <Typography style={{ color: 'white', fontSize: '15px' }}>
                                Pending Hours :&nbsp;
                                {module.pending_hours}
                              </Typography> */}
                            </Grid>
                            <Grid className={classes.responsive} item md={12} xs={12} title={module.to_show === false && 'please Complete Before Modules'} style={{ marginBottom: '10px', textAlign: 'center' }}>
                              <Button
                                style={{ lineHeight: '0', fontSize: '20px' }}
                                disabled={module.to_show === false}
                                variant="container"
                                onClick={() => moduleClick(module.rating_status, module.id, module.course_name)}
                              >
                                <div className="arcButtonback">
                                  Start
                                </div>
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </>
        )}
      </div>
      :null}
    </div>
  );
}
TrainingModule.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(TrainingModule);
