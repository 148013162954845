import Background from '../../../Media/bookImage.webp';

export default () => ({
  lessonBackground: {
    backgroundColor: '#85c5fb',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  head: {
    display: '-webkit-box',
    textAlign: 'center',
    maxWidth: '200px',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  imageSettings: {
    // height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
    // maxHeight: '80%',
    // maxWidth: '80%',
  },
  textSettings: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  progress: {
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    height: 'auto',
  },
  root: {
    position: 'absolute',
    marginTop: '40vh',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
  },
  bookImgDiv: {
    // backgroundImage: `url(${Background})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // width: '66%',
    // // height: '100vh',
    // // width: '210%',
    // // height: '100vh',
    // // margin: '0 auto',
    // height: 'auto',
    paddingTop: '15vh',
    width: '90%',
    height: '100vh',
    background: `url(${Background}) no-repeat center center`,
    // '-webkit-background-size': 'cover',
    // '-moz-background-size': 'cover',
    // '-o-background-size': 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  firstbox: {
    width: 'auto',
    height: 'auto',
    margin: '0 auto',
  },
});
