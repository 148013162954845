export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    overflow: true,
    backgroundColor: 'lightgray',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
  },
  button: {
    marginTop: '10px',
    marginLeft: '2px',
  },
  Typography: {
    padding: theme.spacing(2, 2, 2),
    backgroundColor: 'snow',
  },
  TypographyDate: {
    padding: theme.spacing(1, 2, 0),
  },
  textField: {
    padding: theme.spacing(1, 2, 1),
  },
});
