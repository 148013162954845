/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import { useAlert } from '../../../hoc/alert/alert';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    padding: theme.spacing(8.5, 0, 6),
    // marginBottom: theme.spacing(4),
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 460,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    // padding: theme.spacing(3),
    padding: theme.spacing(8, 0, 6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  image: {
    position: 'absolute',
    bottom: '10px',
    left: '16px',
    fontSize: '40px',
    // color: 'black',
  },
}
));
export default function Article() {
  const [displayBlog, setDisplayBlog] = useState();
  const [loadMask, setLoadMask] = useState(false);
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [post] = useState(JSON.parse(localStorage.getItem('post')));
  const classes = useStyles();
  const alert = useAlert();
  // const { post } = props;
  const readFullArticle = (replacePost) => {
    localStorage.setItem('post', JSON.stringify(replacePost));
  };

  let loader = null;
  if (loadMask) {
    loader = <Loader open />;
  }
  useEffect(() => {
    async function loading() {
      setLoadMask(true);

      const response = await fetch(`${urls.getBlogAPI}`, {
        method: 'GET',

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Blogs')
          
        },
      });
      const res = await response.json();
      return res;
    }
    loading()
      .then((res) => {
        setLoadMask(false);
        // eslint-disable-next-line no-console
        console.log(res);
        const filteredBlog = res.results.filter((eachBlog) => post.id !== eachBlog.id);
        setDisplayBlog(filteredBlog);
      })
      .catch(
        (error) => {
          setLoadMask(false);
          alert.warning(`Error:${error}`);
        },
      );
  }, [auth.personal_info.token, post.id]);
  return (
    <>
      <Paper className={classes.mainFeaturedPost} style={{ marginBottom: '10px' }}>
        {/* Increase the priority of the hero background image */}
        <img
          style={{ height: 390, width: 1300, paddingTop: 10 }}
          src={post.blog_header_image}
          alt=""
        />
        {/* <div className={classes.image}>{ReactHtmlParser(post.blog_title)}</div> */}
        <div className={classes.overlay} />
        {/* <Grid container>
          <Grid item md={6} xs={12}> */}
        <div className={classes.image}>
          <Typography component="h1" variant="h3" color="inherit" gutterBottom>
            {ReactHtmlParser(post.blog_title)}
          </Typography>
          {}
        </div>
        {/* </Grid>
      </Grid> */}
      </Paper>
      <div />
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <div style={{ marginBottom: '10px' }}>
              <small>
                {post.added_by ? post.added_by : 'Sure Learning Writer'}
                {' '}
                |
                {' '}
                {post.added_date}
                {' '}
                |
                {' '}
                {post.read_time.split(':')[1] !== '00' ? post.read_time.split(':')[1] : 'Few'}
                {' '}
                min read
              </small>
            </div>
            <Typography color="inherit" span>
              {ReactHtmlParser(post.blog_content)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {/* <Header title="Suggested" /> */}
      <Container maxWidth="lg">
        <div style={{ marginTop: 40 }}>
          <Typography type="h4" align="center">Suggested Articles</Typography>
        </div>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          {displayBlog && displayBlog.map((blog) => (
            // <FeaturedPost key={post.id} post={post} />
            <Grid key={blog.id} item xs={12} md={6}>
              <CardActionArea href="/full-article" onClick={() => readFullArticle(blog)}>
                {/* <CardMedia
                  className={classes.media}
                  image={blog.blog_header_image}
                  title={blog.blog_title}
                /> */}
                <img src={`${urls.s3}${post.blog_header_image.substring(32)}`} alt="" style={{ height: 400, width: 600 }} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {ReactHtmlParser(blog.blog_title)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="span">
                    {ReactHtmlParser && blog.blog_content!== null &&(blog.blog_content.length <200
                      ? blog.blog_content
                      : blog.blog_content.substring(0, 200))}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </Container>
      {loader}
    </>
  );
}

Article.propTypes = {
  post: PropTypes.object,
};
