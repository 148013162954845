import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Dialog,
  FormControl,
  InputLabel,
  Box,
  Paper,
  TextField,
  Divider,
} from "@material-ui/core";
import "./folder_topic.scss";
import axios from "axios";
import urls from "../../../../url";
import { useAlert } from "../../../../hoc/alert";

const useStyles = makeStyles((theme) => ({
  //   formControl: {
  //     margin: theme.spacing(1),
  //     minWidth: "100%",
  //   },
  //   selectEmpty: {
  //     marginTop: theme.spacing(2),
  //   },
  //   root: {
  //     "& > *": {
  //       margin: theme.spacing(1),
  //       width: "50ch",
  //     },
  //   },
  //   rootSpacing: {
  //     "& > *": {
  //       margin: theme.spacing(1),
  //     },
  //   },
}));

const FolderTopic = (props) => {
  const classes = useStyles();
  const alert = useAlert();
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));

  const [folder, setFolder] = useState("");

  const handleAddFolder = (event) => {
    setFolder(event.target.value);
  };

  const handleFolderSubmit = () => {
    let data = {
      folder_name: folder,
    };

    axios
      .post(`${urls.addResourceFolder}`, data, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // let res = result.data.message;
        // let capitalizedResponse = res[0].toUpperCase() + res.slice(1);
        // alert.success(capitalizedResponse);
        if (result.status === 201) {
          alert.success("Folder created successfully");
          props.setIsOpenFolderDialog(false);
          window.location.reload();
          setFolder("");
          // console.log(result);
        }
        if (result.status === 200) {
          alert.warning(result.data.message);
        }
        // console.log(result, "loooooooo");
      })
      .catch((error) => {
        console.log(error);
        // alert.warning(error.response.data.message);
      });

    // setIsOpenFolderDialog(false);
  };

  return (
    <div>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <TextField
            style={{ width: "80%", marginLeft: "5%" }}
            label="Enter folder name"
            variant="outlined"
            id="outlined-basic"
            size="small"
            multiline
            rows={1}
            rowsMax={3}
            value={folder}
            onChange={handleAddFolder}
            // disabled={school !== null}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            // onClick={(id) => updateResources(editData)}
          >
            Submit
          </Button>
        </Grid> */}
        <Divider className="divider-2" />
        <div className="action-button">
          <div className="action-all-buttons">
            <Button
              variant="outlined"
              onClick={props.close}
              className="action-button-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="action-button-next"
              onClick={handleFolderSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default FolderTopic;
