// export const keyFrameExampleOne = keyframes`
// 0% {
//   height: 200px;
// }
// 100% {
//   height: 600px;
//   background: orange;
// }
// `;

export default (theme) => ({
  trainingModule: {
    width: '100%',
    height: '100vh',
    // backgroundColor: '#bada55',
    backgroundColor: 'lightsteelblue',
  },
  dummyBox: {
    width: '15%',
    height: '80vh',
  },
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
    // left: 0
  },
  moduleCover: {
    width: '70%',
    height: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  modelhead: {
    // padding: theme.spacing(1, 2, 1),
    width: '100%',
    height: 'auto',
    display: 'grid',
    // marginTop: '10%',
    textAlign: 'center',
  },

  moduleBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    paddingTop: '12vh',
    margin: '0 auto',
  },
  moduleCards: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0.5rem 1rem 0',
    padding: ' 1rem',
  },
  introBox: {
    width: '10rem',
    textAlign: 'center',
  },
  firstHead: {
    display: '-webkit-box',
    textAlign: 'center',
    maxWidth: '250px',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondHead: {
    fontSize: '0.9rem',
    margin: '0.5rem 0.5rem 1rem 0.5rem',
    textAlign: 'center',
    display: 'block',
  },
  bulbImg: {
    paddingBottom: '1rem',
    transition: 'transform 500ms ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'rotate(360deg)',
    },
  },
  tooltiptext: {
    visibility: 'hidden',
    width: '120px',
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    '&:hover': {
      visibility: 'visible',
    },
  },
  topCards: {
    display: 'flex',
    width: '100%',
    height: 'auto',
  },
  bottomCards: {
    display: 'flex',
    marginTop: '1rem',
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mcqmodal: {
    maxHeight: 700,
    overflow: 'auto',
    paddingTop: '50px',
  },
  table: {
    fontFamily: 'arial, sans-serif',
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: '13px',
    textAlign: 'center',
  },
  boxEffect: {
    '&:hover': {
      borderRadius: '5px',
      boxShadow: '2px 2px 5px 5px lightgray',
    },
  },
});
