import React, { useEffect, useState } from "react";
import {
  Dashboard as DashboardIcon,
  Add as AddIcon,
  // Edit as EditIcon,
  // NoteAdd as NoteAddIcon,
  // FileCopy as FileCopyIcon,
  // CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import DescriptionIcon from "@material-ui/icons/Description";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SchoolIcon from "@material-ui/icons/School";
import Users from "../components/admin/pages/users/users.jsx";
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

// import AdminDashboard from '../components/admin/dashboard/dashboard';
import PostAddIcon from "@material-ui/icons/PostAdd";
import LocalLibraryRoundedIcon from "@material-ui/icons/LocalLibraryRounded";
import Report from "../components/admin/report/report";
// import ContentDashboard from '../components/contentWriter/dashboard/dashboard';
import SuperAdminDashboard from "../components/superAdmin/dashboard/dashboard";
// import Role from '../components/superAdmin/role/role';
import PositionMapping from "../components/contentWriter/positionMapping/positionMapping";
// import RoleMapping from '../components/superAdmin/roleMapping/roleMapping';
// import AddCategory from '../components/contentWriter/addCategory/AddCategory';
import CourseLevel from "../components/contentWriter/addCourseLevel/addCourseLevel";
// import Courses from '../components/admin/courses/courses';
// import Approve from '../components/admin/approve/approve';
import Registration from "../components/superAdmin/registration/registration";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CourseLanguage from "../components/contentWriter/dashboard/courseLanguage/courseLanguage";
// import CourseSubTitle from '../components/contentWriter/courseSubTitle/courseSubTitle';
// import StudentCourse from '../components/contentWriter/studentCourse/studentCourse';
import CreateCourse from "../components/contentWriter/createCourse/createCourse";
import CreateCourseType from "../components/contentWriter/courseType/courseType";
import CategorySubType from "../components/contentWriter/categorySubType/categorySubType";
// import CategoryMapping from '../components/contentWriter/categoryMapping/categoryMapping';
import Authorize from "../components/admin/authorize/Authorize";
import BranchwiseVideos from "../components/contentWriter/BranchwiseVideos/BranchwiseVideos";
import StartCourseCreation from "../components/contentWriter/createCourse/startCourseCreation/startCourseCreation";
import MoreInfo from "../components/reusableComponents/courseEnroleModle/MoreInfo";
// import TeacherToolBox from '../components/contentWriter/teacherToolBox/TeacherToolBox';
import TreasureBox from "../components/contentWriter/treasureBox/treasureBox";
import PptVideo from "../components/contentWriter/pptVideo/pptVideo";
// import AssessmentReview from '../components/contentWriter/assessmentReviews/assessmentReviews';

import CourseEnroleModle from "../components/reusableComponents/courseEnroleModle/courseEnroleModle";
import ModelBody from "../studentCourse/courses/modelBody";
import consolidatedReport from "../components/admin/consolidatedReport/consolidatedReport";
import issues from "../components/admin/Issues/issues";
import changePassword from "../components/admin/changePassword/changePassword";
// import TreasureBoxCategory from '../co
// mponents/contentWriter/treasureBoxCategory/treasureBoxCategory';
import AddBlogs from "../components/contentWriter/addBlogs/AddBlogs";
import AdminDashboard from "../components/admin/dashboard/dashboard";
import ScheduleWebinar from "../components/contentWriter/WebinarSchedule/wibenarSchedule";
import updateUserStatus from "../components/admin/updateUserStatus/updateUserStatus";
import dashboard from "../components/admin/visual-dashboard/dashboard";
import LearningVideos from "../components/contentWriter/learningVideos/learningVideos";
import sendNotification from "../components/admin/sendNotification/sendNotification";
import AllNotesView from "../components/admin/AllNotes/allNotes";
import CourseSequence from "../components/contentWriter/courseSequence/courseSequence";
import ChapterSequence from "../components/contentWriter/ChapterSequence/ChapterSequence";
import MeetingWebinarReport from "../components/admin/meetingWebinarReport/meetingWebinarReport";
import userListDownload from "../components/contentWriter/userListDownload/userListDownload";
import GradeMapping from "../components/contentWriter/GradeMapping/GradeMapping";
import WeeklyReport from "../components/common/weekly-report/weeklyReport";
import AddSchool from "../components/admin/AddSchool/addSchool";
import addBranch from "../components/admin/AddBranch/addBranch";
import Resourses from "../components/admin/AddResourse/addresourse";
import SchoolWiseReport from "../components/admin/SchoolWiseReport/SchoolWiseReport";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
// import GroupAddIcon from '@material-ui/icons/GroupAddIcon';
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// import useFetch from '../hoc/useFetch';
import useFetch from "../hoc/useFetch";
import urls from "../url";

import SchoolMapping from "../components/contentWriter/School_Course_Mapping/CourseMapping";
// import RoleAuthorization from '../components/admin/RoleAuthorization/AddRoleFrom';
import Role from "../components/admin/role/role.jsx";
// import AddRoleAuthorization from '../components/admin/AddRoleAuthorization/AddRoleAuthorization';
// import Role_management from '../components/admin/Role Mangement/role_table/role_table';
import AssignRole from "../components/admin/Assign_Role/RoleAssign";
import TrainerFolderTopic from "../components/TrainerDriven/trainthetrainer/trainer_folder_topic/trainer_folder_topic.js";
import viewResources from "../components/TrainerDriven/trainthetrainer/resourses";
import TeacherAttendance from "../components/admin/UpdateAttendance/TeacherAttendance.js";
import BranchRoleWiseReport from "../components/admin/BranchAndRoleWiseReport/BranchRoleWiseReport.js";
import mcqReport from "../components/admin/McqReport/mcqReport.js";
import randomMcqReport from "../components/admin/RandomMcqReport/randomMcqReport.js";

const loginData = JSON.parse(localStorage.getItem("UserLogin"));
const role =
  loginData && loginData.personal_info && loginData.personal_info.is_superuser;
// const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')))
const adminRole = loginData?.personal_info?.role;
// const ComponentList = {
// const {
//   data: schoolPermission,
//   isLoading: schoolPermissionLoading,
//   doFetch: fetchSchoolPermission,
// } = useFetch(null)

// useEffect(() => {
//   fetchSchoolPermission({
//     url: `${urls.getPermissons}`,
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${auth.personal_info.token}`,
//       module: localStorage.getItem('School')
//     },
//   });
// },[])

const ComponentList = {
  admin: [
    // console.log("hi",localStorage.getItem('Dashboard(Admin)')),
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: AdminDashboard,
    },
    localStorage.getItem("Users") !== "null" || role
      ? {
          name: "Users",
          icon: <AccountBoxIcon />,
          link: "/registration",
          component: Users,
        }
      : null,
    // role===true?
    localStorage.getItem("User_Roles") !== "null" || role
      ? {
          name: "User Roles",
          icon: <EqualizerIcon />,
          link: "/role_authorization",
          component: Role,
        }
      : null,
    // role===true?
    // {
    //   name: 'Assign Role',
    //   icon: <AddIcon />,
    //   link: '/Assign_role',
    //   component: AssignRole,
    // }
    // :null,
    localStorage.getItem("School") !== "null" || role
      ? {
          name: "School",
          icon: <SchoolIcon />,
          link: "/add_school",
          component: AddSchool,
        }
      : null,
    localStorage.getItem("Branch") !== "null" || role
      ? {
          name: "Branch",
          icon: <PostAddIcon />,
          link: "/add_branch",
          component: addBranch,
        }
      : null,
    localStorage.getItem("Courses") !== "null" || role
      ? {
          name: "Courses",
          icon: <AddIcon />,
          link: "/create_Course",
          component: CreateCourse,
        }
      : null,
    localStorage.getItem("Course_Authorization") !== "null" || role
      ? {
          name: "Course Authorization",
          icon: <AddIcon />,
          link: "/authorize",
          component: Authorize,
        }
      : null,
    localStorage.getItem("Course_Wise_Report") !== "null" || role
      ? {
          name: "Report",
          icon: <AddIcon />,
          link: "/report",
          component: Report,
        }
      : null,
    // localStorage.getItem('Consolidated_Report')!=="null" || role?
    localStorage.getItem("Consolidated_Report") !== "null" || role
      ? {
          name: "Consolidated Report",
          icon: <AddIcon />,
          link: "/consolidatedReport",
          component: consolidatedReport,
        }
      : null,
      localStorage.getItem("Mcq_Report") !== "null" || role
      ? {
          name: "Mcq Report",
          icon: <AddIcon />,
          link: "/mcqReport",
          component: mcqReport,
        }
      : null,
    // role===true?
    localStorage.getItem("Mcq_Report") !== "null" || role
    ? {
        name: "Mcq Report",
        icon: <AddIcon />,
        link: "/randomMcqReport",
        component: randomMcqReport,
      }
    : null,
    localStorage.getItem("Issues") !== "null" || role
      ? {
          name: "Issues",
          icon: <AddIcon />,
          link: "/issues",
          component: issues,
        }
      : null,
    // localStorage.getItem('Change_Password')!=="null"?
    // {
    //   name: 'Change password',
    //   icon: <AddIcon />,
    //   link: '/changeAdminPassword',
    //   component: changePassword,
    // }:null,
    // localStorage.getItem('Change_User_Status')!=="null"?
    // {
    //   name: 'Change User Status',
    //   icon: <AddIcon />,
    //   link: '/updateUserStatus',
    //   component: updateUserStatus,
    // }:null,
    localStorage.getItem("Learning_Notes") !== "null" || role
      ? {
          name: "View all Notes",
          icon: <AddIcon />,
          link: "/view_all_notes",
          component: AllNotesView,
        }
      : null,
    localStorage.getItem("Grade_Subject_Mapping") !== "null" || role
      ? {
          name: "Grade Subject Mapping",
          icon: <AddIcon />,
          link: "/GradeMapping",
          component: GradeMapping,
        }
      : null,
    localStorage.getItem("School_Course_Mapping") !== "null" || role
      ? {
          name: "School Course Mapping",
          icon: <AddIcon />,
          link: "/SchoolCourseMapping",
          component: SchoolMapping,
        }
      : null,
    localStorage.getItem("Meeting_Webinar_Report") !== "null" || role
      ? {
          name: "Meeting & Webinar Report",
          icon: <DescriptionIcon />,
          link: "/view_meeting_webiar_reports",
          component: MeetingWebinarReport,
        }
      : null,
    // localStorage.getItem('Weekly_Report')!=="null" || role?
    localStorage.getItem("Weekly_Report") !== "null" || role
      ? {
          name: "Weekly Report",
          icon: <DescriptionIcon />,
          link: "/weekly_report",
          component: WeeklyReport,
        }
      : null,
    // localStorage.getItem('School_Wise_Report')!=="null" || role?
    localStorage.getItem("School_Wise_Report") !== "null" || role
      ? {
          name: "School Wise Report",
          icon: <LocalLibraryRoundedIcon />,
          link: "/school_wise_report",
          component: SchoolWiseReport,
        }
      : null,
    // localStorage.getItem("Assign_Trainee") !== "null" &&
    adminRole?.toString().toLowerCase() === "admin"
      ? {
          name: "Update Pending Hours",
          icon: <AssignmentIndIcon />,
          link: "/admin/update_pending_hours",
          component: TeacherAttendance,
        }
      : null,
    localStorage.getItem("User_List_Report") !== "null" &&
    adminRole?.toString().toLowerCase() === "admin"
      ? {
          name: "BranchAndRoleWise Report",
          icon: <AssignmentIndIcon />,
          link: "/admin/branch_and_role_wise_report",
          component: BranchRoleWiseReport,
        }
      : null,
    localStorage.getItem("Resources_data") !== "null" &&
    adminRole?.toString().toLowerCase() !== "admin"
      ? {
          name: "Resourses",
          icon: <ArtTrackIcon />,
          link: "/Resourses",
          component: Resourses,
        }
      : null,
    localStorage.getItem("Resources_data") !== "null" &&
    adminRole?.toString().toLowerCase() === "admin"
      ? {
          name: "Resourses",
          icon: <ArtTrackIcon />,
          link: "/trainerDriven/Resourses-Folder",
          component: TrainerFolderTopic,
        }
      : null,

    localStorage.getItem("Resources_data") !== "null" &&
    adminRole?.toString().toLowerCase() === "admin"
      ? {
          name: "",
          icon: "",
          link: "/trainerDriven/Resourses",
          component: viewResources,
        }
      : null,
    // {
    //   name: 'Role',
    //   icon:<EqualizerIcon/>,
    //   link:'/role_authorization',
    //   component: RoleAuthorization,
    // },
    // {
    //   name: 'Grade Subject Mapping',
    //   icon: <AddIcon />,
    //   link: '/GradeMapping',
    //   component: GradeMapping,
    // },
    // console.log(role),

    // {
    //   name: 'Dashboard',
    //   icon: <DashboardIcon />,
    //   link: '/',
    //   component: dashboard,
    // },
    // localStorage.getItem('School')==="null"?
    // {
    //   name: 'Add School',
    //   icon: <SchoolIcon />,
    //   link: '/add_school',
    //   component: AddSchool,
    // }:null,
    localStorage.getItem("Webinar") !== "null" || role
      ? {
          name: "Schedule Webinar",
          icon: <DateRangeIcon />,
          link: "/Schedule_Webinar",
          component: ScheduleWebinar,
        }
      : null,
    localStorage.getItem("Course_Language") !== "null" || role
      ? {
          name: "Course Language ",
          icon: <AddIcon />,
          link: "/Course_Language",
          component: CourseLanguage,
        }
      : null,
    // localStorage.getItem("User_List_Report") !== "null" || role
    //   ? {
    //       name: "User List Report",
    //       icon: <AddIcon />,
    //       link: "/userListDownload",
    //       component: userListDownload,
    //     }
    //   : null,
    localStorage.getItem("Notification") !== "null" || role
      ? {
          name: "Notification ",
          icon: <AddIcon />,
          link: "/send_notification",
          component: sendNotification,
        }
      : null,
    localStorage.getItem("Course_Level") !== "null" || role
      ? {
          name: "Course Level",
          icon: <AddIcon />,
          link: "/addCourseLevel",
          component: CourseLevel,
        }
      : null,
    localStorage.getItem("Grade") !== "null" || role
      ? {
          name: "Grade",
          icon: <AddIcon />,
          link: "/addCategoryType",
          component: CreateCourseType,
        }
      : null,
    localStorage.getItem("Subject") !== "null" || role
      ? {
          name: "Subject",
          icon: <AddIcon />,
          link: "/addCategorySubType",
          component: CategorySubType,
        }
      : null,
    // {
    //   name: 'Category Mapping',
    //   icon: <AddIcon />,
    //   link: '/CategoryMapping',
    //   component: CategoryMapping,
    // },
    localStorage.getItem("Branch_Wise_Videos") !== "null" || role
      ? {
          name: "Branch Wise Videos",
          icon: <AddIcon />,
          link: "/Branchwisevideos",
          component: BranchwiseVideos,
        }
      : null,
    localStorage.getItem("Treasure_Box") !== "null" || role
      ? {
          name: "Treasure Box",
          icon: <AddIcon />,
          link: "/treasure_box",
          component: TreasureBox,
        }
      : null,
    // {
    //   name: 'Add Role',
    //   icon: <AddIcon />,
    //   link: '/role',
    //   component: Role,
    // },
    localStorage.getItem("PPT_Video") !== "null" || role
      ? {
          name: "PPT Video",
          icon: <AddIcon />,
          link: "/PPT_Video",
          component: PptVideo,
        }
      : null,
    localStorage.getItem("PPT_Video") !== "null"
      ? {
          name: "Position Mapping",
          icon: <AddIcon />,
          link: "/position_mapping",
          component: PositionMapping,
        }
      : null,
    // localStorage.getItem('Change_Password')!=="null"?
    // {
    //   name: 'Change password',
    //   icon: <AddIcon />,
    //   link: '/changeContentWriterPassword',
    //   component: changePassword,
    // }:null,

    localStorage.getItem("Blogs") !== "null" || role
      ? {
          name: "Add Blogs",
          icon: <AddIcon />,
          link: "/add_blogs",
          component: AddBlogs,
        }
      : null,
    localStorage.getItem("Learnig_Videos") !== "null" || role
      ? {
          name: "Add Learnign Videos",
          icon: <AddIcon />,
          link: "/add_learning_videos",
          component: LearningVideos,
        }
      : null,
    localStorage.getItem("Course_Sequence") !== "null" || role
      ? {
          name: "Course Sequencing",
          icon: <AddIcon />,
          link: "/course_sequencing",
          component: CourseSequence,
        }
      : null,
    localStorage.getItem("Chapter_Sequence") !== "null" || role
      ? {
          name: "Chapter Sequencing",
          icon: <ImportContactsIcon />,
          link: "/chapter_sequencing",
          component: ChapterSequence,
        }
      : null,
    // {
    //   name: 'Grade Subject Mapping',
    //   icon: <AddIcon />,
    //   link: '/GradeMapping',
    //   component: GradeMapping,
    // },
    {
      link: "/editBody",
      component: StartCourseCreation,
    },
    {
      link: "/MoreInfo",
      component: MoreInfo,
    },
    {
      link: "/modelBody",
      component: ModelBody,
    },
    {
      link: "/enroleModel",
      component: CourseEnroleModle,
    },
  ],

  contentwriter: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: dashboard,
    },
    {
      name: "Consolidated Report",
      icon: <AddIcon />,
      link: "/consolidatedReport",
      component: consolidatedReport,
    },
    localStorage.getItem("School") === "null"
      ? {
          name: "Add School",
          icon: <SchoolIcon />,
          link: "/add_school",
          component: AddSchool,
        }
      : null,
    localStorage.getItem("Webinar") !== "null"
      ? {
          name: "Schedule Webinar",
          icon: <DateRangeIcon />,
          link: "/Schedule_Webinar",
          component: ScheduleWebinar,
        }
      : null,
    localStorage.getItem("Course_Language") !== "null"
      ? {
          name: "Course Language ",
          icon: <AddIcon />,
          link: "/Course_Language",
          component: CourseLanguage,
        }
      : null,
    localStorage.getItem("User_List_Report") !== "null"
      ? {
          name: "User List Report",
          icon: <AddIcon />,
          link: "/userListDownload",
          component: userListDownload,
        }
      : null,
    localStorage.getItem("Notification") !== "null"
      ? {
          name: "Notification ",
          icon: <AddIcon />,
          link: "/send_notification",
          component: sendNotification,
        }
      : null,
    {
      name: "Add Role",
      icon: <AddIcon />,
      link: "/role",
      component: Role,
    },
    {
      name: "Grade Subject Mapping",
      icon: <AddIcon />,
      link: "/GradeMapping",
      component: GradeMapping,
    },
    // {
    //   name: 'Course Subtitle',
    //   icon: <AddIcon />,
    //   link: '/courseSubTitle',
    //   component: CourseSubTitle,
    // },
    // {
    //   name : 'Student Courses',
    //   icon: <AddIcon />,
    //   link: '/studentCourses',
    //   component: StudentCourse
    // },
    {
      name: "Courses",
      icon: <AddIcon />,
      link: "/create_Course",
      component: CreateCourse,
    },
    // {
    //   name: 'Add Category',
    //   icon: <AddIcon />,
    //   link: '/addCategory',
    //   component: AddCategory,
    // },
    localStorage.getItem("Course_Level") !== "null"
      ? {
          name: "Course Level",
          icon: <AddIcon />,
          link: "/addCourseLevel",
          component: CourseLevel,
        }
      : null,
    localStorage.getItem("Grade") !== "null"
      ? {
          name: "Grade",
          icon: <AddIcon />,
          link: "/addCategoryType",
          component: CreateCourseType,
        }
      : null,
    localStorage.getItem("Subject") !== "null"
      ? {
          name: "Subject",
          icon: <AddIcon />,
          link: "/addCategorySubType",
          component: CategorySubType,
        }
      : null,
    // {
    //   name: 'Category Mapping',
    //   icon: <AddIcon />,
    //   link: '/CategoryMapping',
    //   component: CategoryMapping,
    // },
    localStorage.getItem("Branch_Wise_Videos") !== "null"
      ? {
          name: "Branch Wise Videos",
          icon: <AddIcon />,
          link: "/Branchwisevideos",
          component: BranchwiseVideos,
        }
      : null,
    localStorage.getItem("Treasure_Box") !== "null"
      ? {
          name: "Treasure Box",
          icon: <AddIcon />,
          link: "/treasure_box",
          component: TreasureBox,
        }
      : null,
    // {
    //   name: 'Teacher Tool Box',
    //   icon: <AddIcon />,
    //   link: '/teacherToolBox',
    //   component: TeacherToolBox,
    // },
    // localStorage.getItem('Role_Position_Mapping')!=="null"?

    // :null,
    // {
    //   name: 'Treasure Box Category',
    //   icon: <AddIcon />,
    //   link: '/treasure_box_category',
    //   component: TreasureBoxCategory,
    // },
    localStorage.getItem("PPT_Video") !== "null"
      ? {
          name: "PPT Video",
          icon: <AddIcon />,
          link: "/PPT_Video",
          component: PptVideo,
        }
      : null,
    localStorage.getItem("PPT_Video") !== "null"
      ? {
          name: "Position Mapping",
          icon: <AddIcon />,
          link: "/position_mapping",
          component: PositionMapping,
        }
      : null,
    // localStorage.getItem('Change_Password')!=="null"?
    // {
    //   name: 'Change password',
    //   icon: <AddIcon />,
    //   link: '/changeContentWriterPassword',
    //   component: changePassword,
    // }:null,

    localStorage.getItem("Blogs") !== "null"
      ? {
          name: "Add Blogs",
          icon: <AddIcon />,
          link: "/add_blogs",
          component: AddBlogs,
        }
      : null,
    localStorage.getItem("Learnig_Videos") !== "null"
      ? {
          name: "Add Learnign Videos",
          icon: <AddIcon />,
          link: "/add_learning_videos",
          component: LearningVideos,
        }
      : null,
    localStorage.getItem("Course_Sequence") !== "null"
      ? {
          name: "Course Sequencing",
          icon: <AddIcon />,
          link: "/course_sequencing",
          component: CourseSequence,
        }
      : null,
    localStorage.getItem("Chapter_Sequence") !== "null"
      ? {
          name: "Chapter Sequencing",
          icon: <ImportContactsIcon />,
          link: "/chapter_sequencing",
          component: ChapterSequence,
        }
      : null,
    // {
    //   link: '/editBody',
    //   component: StartCourseCreation,
    // },
    // {
    //   link: '/MoreInfo',
    //   component: MoreInfo,
    // },
    // {
    //   link: '/modelBody',
    //   component: ModelBody,
    // },
    {
      link: "/enroleModel",
      component: CourseEnroleModle,
    },
  ],
  superadmin: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: SuperAdminDashboard,
    },
    // {
    //  name: "Add Role",
    //  icon: <AddIcon />,
    //  link: "/role",
    //  component: Role
    //  },
    //  {
    //    name: "Role Mapping",
    //    icon: <LinkIcon />,
    //    link: "/roleMapping",
    //    component: RoleMapping
    //  },
    {
      name: "Registration",
      icon: <PersonAddIcon />,
      link: "/registration",
      component: Registration,
    },
  ],
};

export default ComponentList;
