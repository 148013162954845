/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "../inHouseTreasureBox.css";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  Divider,
  Backdrop,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./inHouseTreasureBoxVideos.style";
import { useAlert } from "../../../../hoc/alert/alert";
import urls from "../../../../url";

const InHouseTreasureBoxVideos = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [videoData, setVideoData] = useState([]);
  const playerRef = useRef();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [openFullView, setFullView] = useState(false);
  const [FullViewData, seFullViewData] = useState({
    title: null,
    video: null,
    text: null,
  });

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function functionToGetVideos() {
    setLoading(true);
    fetch(
      `${urls.creatingVideoForEachModuleApi}?module=${localStorage.getItem(
        "module"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("Treasure_Box"),
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success("Videos Successfully Recieved");
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning("somthing went wrong please try again ");
        }
        return 0;
      })
      .then((data) => {
        setVideoData(data);
      });
  }

  useEffect(() => {
    if (auth) {
      functionToGetVideos();
    }
  }, [auth]);

  const playHandle = (videoId) => {
    if (playerRef && playerRef.current && playerRef.current.currentTime) {
      console.log(playerRef.current.currentTime);
    }
    async function videoViewed() {
      const response = await fetch(
        `${urls.branchUpdateVideosApi}/${videoId}/video_viewed/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            "Content-Type": "application/json",
            module: localStorage.getItem("Treasure_Box"),
          },
        }
      );
      const fetchData = await response.json();
      return fetchData;
    }
    setTimeout(() => {
      videoViewed()
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error, "dddddddd");
          setLoading(false);
        });
    }, 30000);
  };

  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   // if (playerRef) {
  //   //   console.log('video is playing......................');
  //   //   // const media = document.querySelector('video');
  //   //   playerRef.current.onplay = () => {
  //   //     console.log('Triggering play+++');
  //   //     // socket.trigger('play');
  //   //   };
  //   // }
  //   console.log('video is playing......................');
  //   if (playerRef) {
  //     console.log(playerRef);
  //   }
  //   console.log(playerRef);
  // }, [playerRef]);
  const handleCloseFullView = () => {
    setFullView(false);
    seFullViewData({ title: null, video: null, text: null });
  };

  function OpenFullModel(titleData, videoInfo, textData) {
    setFullView(true);
    seFullViewData({ title: titleData, video: videoInfo, text: textData });
  }
  function functionForFullViewVodeoModule() {
    let FullView = null;
    FullView = (
      <Grid container>
        <Grid item md={12} xs={12}>
          <Dialog
            maxWidth="xl"
            fullWidth
            className={classes.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={openFullView}
          >
            <DialogTitle id="alert-dialog-title" onClose={handleCloseFullView}>
              {FullViewData.title || ""}
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <Grid container>
                <Grid item md={2} />
                <Grid item md={8} xs={12}>
                  <Box border={1} className={classes.contentBox}>
                    <Grid item md={12} xs={12}>
                      <video
                        ref={playerRef}
                        controlsList="nodownload"
                        controls
                        src={FullViewData.video ? `${FullViewData.video}` : ""}
                        height="450"
                        width="100%"
                      >
                        <track
                          src={
                            FullViewData.video ? `${FullViewData.video}` : ""
                          }
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                      </video>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography varinat="h4" className={classes.TextTitle}>
                        About Video
                      </Typography>
                    </Grid>
                    <Divider className={classes.dividerD} />
                    <Grid item md={12} xs={12}>
                      <Typography varinat="h6" className={classes.TextDesc}>
                        {ReactHtmlParser(FullViewData.text)}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    );
    return FullView;
  }

  return (
    <>
      <div className={classes.activityBox1}>
        {loading === true && (
          <Backdrop className={classes.backdrop} style={{ zIndex: 500 }} open>
            <CircularProgress />
          </Backdrop>
        )}
        {videoData && videoData.length === 0 && (
          <Typography className={classes.typText}>
            {" "}
            No Videos are added
          </Typography>
        )}
        <div
          className="moduleCoverTreasureVideo"
          style={{
            height: videoData && videoData.length < 4 ? "100vh" : "auto",
          }}
        >
          {videoData &&
            videoData.length !== 0 &&
            videoData.map((data) => (
              <div
                className={
                  videoData && videoData.length < 3
                    ? classes.modelcard1
                    : classes.moduleCards
                }
                key={data.id}
              >
                <div className={classes.introBox}>
                  {console.log(data.file, "videofile")}
                  <video
                    onPlay={() => playHandle(data && data.id)}
                    ref={playerRef}
                    controlsList="nodownload"
                    controls
                    muted={openFullView}
                    src={`${data.file}`}
                    height="200"
                    width="300"
                  >
                    <track
                      src={`${data.file}`}
                      kind="captions"
                      srcLang="en"
                      label="english_captions"
                    />
                  </video>
                </div>
                <div className={classes.introBox}>
                  <Typography variant="h6" className={classes.typographyy}>
                    {data.title}
                  </Typography>
                </div>
                <div className={classes.introBox}>
                  <Button
                    className={classes.buttonview}
                    variant="outlined"
                    onClick={() =>
                      OpenFullModel(data.title, data.file, data.text)
                    }
                  >
                    Full View
                  </Button>
                </div>
              </div>
            ))}
        </div>
        {functionForFullViewVodeoModule()}
      </div>
    </>
  );
};
InHouseTreasureBoxVideos.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(InHouseTreasureBoxVideos);
