// export default theme => ({
//     divider: {
//       marginTop: theme.spacing(2),
//       marginBottom: theme.spacing(2),
//     },
//     root: {
//       flexGrow: 1,
//       width: '100%'
//     },
//     textField: {
//       width: '100%',
//     },
//     table: {
//       minWidth: 650,
//     },
//     modal: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//     },
//     paper: {
//       width: '100%',
//       overflowX: 'auto',
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//     },
//     updateButton :{
//       marginTop: '12px',
//     },
//     tableMargin : {
//       marginBottom : '10px',
//     }
//     });

export default (theme) => ({
  root: {
    margin: 'auto',
    width: '90%',
    flexGrow: 1,
  },
  gridTwo: {
    marginTop: '15px',
    display: 'flex',
  },
  gridOne: {
    marginTop: theme.spacing(2),
  },
  qulification: {
    marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
    display: 'flex',
  },
  paperContentLarge: {
    padding: '15px',
    width: '50%',
    margin: 'auto',
    overflowY: 'hidden',
  },
});
