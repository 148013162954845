import React from "react";
import { Dashboard as DashboardIcon } from "@material-ui/icons";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ClassIcon from "@material-ui/icons/Class";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import MoreInfo from "../reusableComponents/courseEnroleModle/MoreInfo";
import CoursesView from "../PrincipalDashboard/PrincipalComponents/CoursesView";
import TrainerDrivenForm from "./ClassInitiateForm/TrainerDrivenForm";
// import ClassInitiate from './ClassInitiate/ClassInitiate';
import InitiateMainPage from "./ClassInitiate/mainPage";
import ModelBody from "../../studentCourse/courses/modelBody";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
// import TrainerDrivenChapters from "./TrainerDrivenChapters/TrainerDrivenChapters";
// import TrainerDrivenLessons from "./TrainerDrivenLessons/TrainerDrivenLessons";
import TrainingChapters from "../../InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingChapters/TrainingChapters";
import TrainingLesson from "../../InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingLessons/TrainingLesson";
import TrainingUnit from "../../InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingUnit/TrainingUnit";
import CourseEnroleModle from "../reusableComponents/courseEnroleModle/courseEnroleModle";
import StartOnlineClass from "./startOnlineClass/startonlineClass";
import TraintheTrainer from "./trainthetrainer/resourses";
import AssementScores from "./trainthetrainer/assementScores";
import RedoIcon from "@material-ui/icons/Redo";
import AssessmentReview from "../PrincipalDashboard/assessmentReviews/assessmentReviews";
import TrainerFolderTopic from "./trainthetrainer/trainer_folder_topic/trainer_folder_topic";
import Resourses from "./trainthetrainer/resourses";
const loginData = JSON.parse(localStorage.getItem("UserLogin"));
const role =
  loginData && loginData.personal_info && loginData.personal_info.is_superuser;

// console.log(loginData?.personal_info?.role, "rrrrroooolllleeeeessss");

const ComponentList = {
  user: [
    localStorage.getItem("All_Completed_Courses") !== "null"
      ? {
          name: "All/Completed Courses",
          icon: <DashboardIcon />,
          link: "/trainerDriven/testDrivenCoursesView",
          component: CoursesView,
        }
      : null,
    localStorage.getItem("Class_Initiation_Form") !== "null"
      ? {
          name: "Class Initiation Form",
          icon: <ListAltIcon />,
          link: "/trainerDriven/classInitiationForm",
          component: TrainerDrivenForm,
        }
      : null,

    localStorage.getItem("Initiate_Class") !== "null"
      ? {
          name: "Initiate Class",
          icon: <SupervisorAccountIcon />,
          link: "/trainerDriven/initiateForm",
          component: InitiateMainPage,
        }
      : null,
    localStorage.getItem("Resources_data") !== "null"
      ? {
          name: "Train the Trainer",
          icon: <ArtTrackIcon />,
          link: "/trainerDriven/TraintheTrainer",
          component: TraintheTrainer,
        }
      : null,

    localStorage.getItem("Resources_data") !== "null"
      ? {
          name: "Resources",
          icon: "",
          link: "/trainerDriven/Resourses-Folder",
          component: TrainerFolderTopic,
        }
      : null,

    localStorage.getItem("Resources_data") !== "null"
      ? {
          name: "",
          icon: "",
          link: "/trainerDriven/Resourses",
          component: Resourses,
        }
      : null,

    // localStorage.getItem("Resources_data") !== "null"
    //   ? {
    //       name: "Train the Trainer",
    //       icon: <ArtTrackIcon />,
    //       link: "/trainerDriven/TraintheTrainer",
    //       component: TraintheTrainer,
    //     }
    //   : null,

    // localStorage.getItem("Resources_data") !== "null"
    //   ? {
    //       name: "Resources",
    //       icon: "",
    //       link: "/trainerDriven/Resourses",
    //       component: TraintheTrainer,
    //       // onclick: alert("clicked"),
    //     }
    //   : null,
    localStorage.getItem("Assessment_Review") !== null
      ? {
          name: "Assessment Scores",
          icon: "",
          link: "/principalDashboard/assessmentReviews",
          component: AssessmentReview,
        }
      : null,
    {
      link: "/chapters",
      component: TrainingChapters,
    },
    {
      link: "/lessons",
      component: TrainingLesson,
    },
    {
      link: "/unit",
      component: TrainingUnit,
    },

    {
      link: "/MoreInfo",
      component: MoreInfo,
    },
    {
      link: "/enroleModel",
      component: CourseEnroleModle,
    },
    {
      link: "/modelBody",
      component: ModelBody,
    },

    localStorage.getItem("Start_Class") !== "null"
      ? {
          name: "Start Class",
          icon: <ClassIcon />,
          link: "/trainerDriven/start_online_class",
          component: StartOnlineClass,
        }
      : null,
  ],
  // leadteacher: [
  //   {
  //     name: 'All/Completed Courses',
  //     icon: <DashboardIcon />,
  //     link: '/trainerDriven/testDrivenCoursesView',
  //     component: CoursesView,
  //   },
  //   {
  //   name: 'Class Initiation Form',
  //   icon: <ListAltIcon />,
  //   link: '/trainerDriven/classInitiationForm',
  //   component: TrainerDrivenForm,
  // },
  //   {
  //     name: 'Initiate Class',
  //     icon: <SupervisorAccountIcon />,
  //     link: '/trainerDriven/initiateForm',
  //     component: InitiateMainPage,
  //   },
  //   {
  //     name: 'Start Class',
  //     icon: <ClassIcon />,
  //     link: '/trainerDriven/start_online_class',
  //     component: StartOnlineClass,
  //   },
  //   {
  //     link: '/chapters',
  //     component: TrainingChapters,
  //   },
  //   {
  //     link: '/lessons',
  //     component: TrainingLesson,
  //   },
  //   {
  //     link: '/unit',
  //     component: TrainingUnit,
  //   },

  //   {
  //     link: '/MoreInfo',
  //     component: MoreInfo,
  //   },
  //   {
  //     link: '/enroleModel',
  //     component: CourseEnroleModle,
  //   },
  //   {
  //     link: '/modelBody',
  //     component: ModelBody,
  //   },
  // ],
  // principal: [
  //   {
  //     name: 'All/Completed Courses',
  //     icon: <DashboardIcon />,
  //     link: '/trainerDriven/testDrivenCoursesView',
  //     component: CoursesView,
  //   },
  //   {
  //     name: 'Class Initiation Form',
  //     icon: <ListAltIcon />,
  //     link: '/trainerDriven/classInitiationForm',
  //     component: TrainerDrivenForm,
  //   },
  //   {
  //     name: 'Initiate Class',
  //     icon: <SupervisorAccountIcon />,
  //     link: '/trainerDriven/initiateForm',
  //     component: InitiateMainPage,
  //   },
  //   {
  //     name: 'Start Class',
  //     icon: <ClassIcon />,
  //     link: '/trainerDriven/start_online_class',
  //     component: StartOnlineClass,
  //   },
  //   {
  //     link: '/chapters',
  //     component: TrainingChapters,
  //   },
  //   {
  //     link: '/lessons',
  //     component: TrainingLesson,
  //   },
  //   {
  //     link: '/unit',
  //     component: TrainingUnit,
  //   },

  //   {
  //     link: '/MoreInfo',
  //     component: MoreInfo,
  //   },
  //   {
  //     link: '/enroleModel',
  //     component: CourseEnroleModle,
  //   },
  //   {
  //     link: '/modelBody',
  //     component: ModelBody,
  //   },
  // ],
  // academicheads: [
  //   {
  //     name: 'All/Completed Courses',
  //     icon: <DashboardIcon />,
  //     link: '/trainerDriven/testDrivenCoursesView',
  //     component: CoursesView,
  //   },
  //   {
  //     name: 'Class Initiation Form',
  //     icon: <ListAltIcon />,
  //     link: '/trainerDriven/classInitiationForm',
  //     component: TrainerDrivenForm,
  //   },
  //   {
  //     name: 'Initiate Form',
  //     icon: <SupervisorAccountIcon />,
  //     link: '/trainerDriven/initiateForm',
  //     component: InitiateMainPage,
  //   },
  //   {
  //     link: '/chapters',
  //     component: TrainingChapters,
  //   },
  //   {
  //     link: '/lessons',
  //     component: TrainingLesson,
  //   },
  //   {
  //     link: '/unit',
  //     component: TrainingUnit,
  //   },

  //   {
  //     link: '/MoreInfo',
  //     component: MoreInfo,
  //   },
  //   {
  //     link: '/enroleModel',
  //     component: CourseEnroleModle,
  //   },
  //   {
  //     link: '/modelBody',
  //     component: ModelBody,
  //   },
  // ],

  // planner: [
  //   {
  //     name: 'All/Completed Courses',
  //     icon: <DashboardIcon />,
  //     link: '/trainerDriven/testDrivenCoursesView',
  //     component: CoursesView,
  //   },
  //   {
  //     name: 'Class Initiation Form',
  //     icon: <ListAltIcon />,
  //     link: '/trainerDriven/classInitiationForm',
  //     component: TrainerDrivenForm,
  //   },
  //   {
  //     name: 'Initiate Form',
  //     icon: <SupervisorAccountIcon />,
  //     link: '/trainerDriven/initiateForm',
  //     component: InitiateMainPage,
  //   },
  //   {
  //     link: '/chapters',
  //     component: TrainingChapters,
  //   },
  //   {
  //     link: '/lessons',
  //     component: TrainingLesson,
  //   },
  //   {
  //     link: '/unit',
  //     component: TrainingUnit,
  //   },

  //   {
  //     link: '/MoreInfo',
  //     component: MoreInfo,
  //   },
  //   {
  //     link: '/enroleModel',
  //     component: CourseEnroleModle,
  //   },
  //   {
  //     link: '/modelBody',
  //     component: ModelBody,
  //   },
  // ],
  // coordinator: [
  //   {
  //     name: 'All/Completed Courses',
  //     icon: <DashboardIcon />,
  //     link: '/trainerDriven/testDrivenCoursesView',
  //     component: CoursesView,
  //   },
  //   {
  //     name: 'Class Initiation Form',
  //     icon: <ListAltIcon />,
  //     link: '/trainerDriven/classInitiationForm',
  //     component: TrainerDrivenForm,
  //   },
  //   {
  //     name: 'Initiate Form',
  //     icon: <SupervisorAccountIcon />,
  //     link: '/trainerDriven/initiateForm',
  //     component: InitiateMainPage,
  //   },
  //   {
  //     link: '/chapters',
  //     component: TrainingChapters,
  //   },
  //   {
  //     link: '/lessons',
  //     component: TrainingLesson,
  //   },
  //   {
  //     link: '/unit',
  //     component: TrainingUnit,
  //   },

  //   {
  //     link: '/MoreInfo',
  //     component: MoreInfo,
  //   },
  //   {
  //     link: '/enroleModel',
  //     component: CourseEnroleModle,
  //   },
  //   {
  //     link: '/modelBody',
  //     component: ModelBody,
  //   },
  // ],
  // academicmanagers: [
  //   {
  //     name: 'All/Completed Courses',
  //     icon: <DashboardIcon />,
  //     link: '/trainerDriven/testDrivenCoursesView',
  //     component: CoursesView,
  //   },
  //   {
  //     name: 'Class Initiation Form',
  //     icon: <ListAltIcon />,
  //     link: '/trainerDriven/classInitiationForm',
  //     component: TrainerDrivenForm,
  //   },
  //   {
  //     name: 'Initiate Form',
  //     icon: <SupervisorAccountIcon />,
  //     link: '/trainerDriven/initiateForm',
  //     component: InitiateMainPage,
  //   },
  //   {
  //     link: '/chapters',
  //     component: TrainingChapters,
  //   },
  //   {
  //     link: '/lessons',
  //     component: TrainingLesson,
  //   },
  //   {
  //     link: '/unit',
  //     component: TrainingUnit,
  //   },

  //   {
  //     link: '/MoreInfo',
  //     component: MoreInfo,
  //   },
  //   {
  //     link: '/enroleModel',
  //     component: CourseEnroleModle,
  //   },
  //   {
  //     link: '/modelBody',
  //     component: ModelBody,
  //   },
  // ],
};

export default ComponentList;
