/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Divider,
  withStyles,
  Tab,
  Tabs,
  AppBar,
  Avatar,
  TablePagination,
  IconButton,
  Paper,
  TableRow,
  TableCell,
  Table,
  Box,
  TableBody,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';
import styles from './createCourse.Style';
import Loader from '../../../hoc/loader';
import CourseInfoModalView from '../../reusableComponents/courseInfoModal/courseInfoModal';
import CourseEnroleModle from '../../reusableComponents/courseEnroleModle/courseEnroleModle';

const CreateCourse = ({ classes, history }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [fillForm, setFillForm] = useState(false);
  const [Status, setStatus] = useState('active');
  const [value, setValue] = React.useState(0);
  const [viewData, setViewData] = useState(false);
  const [viewidno, setViewId] = useState('');
  const [courseIDNo, setCourseIDNo] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [StatusDetails, setStatusDetails] = useState('');
  const [activeInactive, setActiveInactive] = useState('');
  const [loaading, setLoading] = useState(false);

  const [avalue, setAValue] = useState();
  const [showSearch, setShowSearch]= useState(false);
  useEffect(() => {
    if (auth && auth.personal_info && auth.personal_info.role === 'ContentWriter') {
      const addedd = [
        { statusId: 1, statusName: 'Active' },
        { statusId: 2, statusName: 'In Active' },
        { statusId: 3, statusName: 'Draft' },
      ];
      setStatusDetails(addedd);
    } 
    else {
      const addedd = [
        { statusId: 1, statusName: 'Active' },
        { statusId: 2, statusName: 'In Active' },
      ];
      setStatusDetails(addedd);
    }
  }, [auth]);

  function handleChange(event, newValue) {
    setPage(0);
    setRowsPerPage(12);
    setValue(newValue);
    if (StatusDetails[newValue].statusId === 1) {
      setStatus('active');
      setShowSearch(false)
      getStatusInformation()
    } else if (StatusDetails[newValue].statusId === 2) {
      setStatus('in_active');
      setShowSearch(false)
      getStatusInformation()
    } else if (StatusDetails[newValue].statusId === 3) {
      setStatus('draft');
      setShowSearch(false)
      getStatusInformation()
    }
  }

  const {
    data: courseDetailsData,
    isLoading: courseDetailsDataLoading,
    doFetch: FetchCourseDetails,
  } = useFetch(null);

  const {
    data: getSearchCourseData,
    isLoading: getSearchCourseLoding,
    doFetch: FetchSearchCourse,
  } = useFetch(null);

  const {
    data: StatusInformation,
    isLoading: StatusInformationLoading,
    doFetch: FetchStatusInformation,
  } = useFetch('');

  const {
    data: EditCourseInfo,
    isLoading: EditCourseInfoLoading,
    doFetch: fetchEditCourseInfo,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)



  useEffect(() => {
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
  },[])

  let loader = null;
  if (
    courseDetailsDataLoading
    || StatusInformationLoading
    || EditCourseInfoLoading
    || getSearchCourseLoding 
    || loaading
    || modulePermissionLoading
  ) {
    loader = <Loader open />;
  }

  function getStatusInformation() {
    FetchStatusInformation({
      url: `${
        urls.getStatusInformationApi
      }?status=${Status}&page_size=${rowsPerPage || 12}&page=${page + 1}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
      },
    });
  }
  async function toggleActive(courseId, activeStat) {
    setLoading(true);
    const response = await fetch(`${urls.toggleActive}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
      },
      body: JSON.stringify({
        course_id: courseId,
        status: activeStat,
      }),
    });
    const data = await response.json();
    // console.log(data[0].course_name);
    setLoading(false);
    setActiveInactive(data[0].course_name);
    return data;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(12);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth || Status) {
      getStatusInformation();
    }
  }, [auth, Status, page, rowsPerPage, activeInactive]);

  function FetchCourseDetailsFunction() {
    FetchCourseDetails({
      url: urls.getCourseDetailsApi,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
      },
    });
  }
  function getSearchCourse() {
    setLoading(true);
    FetchSearchCourse({
      url: `${
        urls.getStatusInformationApi
      }?status=${Status}&page_size=${rowsPerPage || 12}&page=${page + 1}&search=${avalue}`,

      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
      },
    });
    setLoading(false);
  }

  useEffect(() => {
    
    if (courseDetailsData) {
      setFillForm(true);
      setCourseIDNo(courseDetailsData.id);
      localStorage.setItem('userId', courseDetailsData.id);
      history.push('/editBody');
    }
  }, [courseDetailsData]);

  useEffect(() => {
    if (EditCourseInfo) {
      setFillForm(true);
      setViewData(false);
    }
  }, [EditCourseInfo]);

  function courseFullForm() {
    
    localStorage.setItem('userId', courseIDNo);
  }

  function refreshPageFunction() {
    window.location.reload();
  }

  function setViewDataFunction(IndNo) {
    setViewData(true);
    setFillForm(false);
    setViewId(IndNo);
    localStorage.setItem('viewID', IndNo);
  }

  function EditCourseInformation(info) {
    console.log(StatusInformation.results, 'startus Information');
    localStorage.setItem('EditID', info);
    setCourseIDNo(
      (StatusInformation && StatusInformation.results[info].id) || '',
    );
    let courseID1 ;
    if(showSearch)
    {
       courseID1 = getSearchCourseData && getSearchCourseData.results[info].id;
    }
    else{
      courseID1 = StatusInformation && StatusInformation.results[info].id;
    }
    localStorage.setItem('userId', courseID1);
    fetchEditCourseInfo({
      url: `${urls.editCourseInfoApi}?course_id=${StatusInformation
        && StatusInformation.results[info].id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
      },
    });
  }

  function setEnrollFunction() {
    setViewData(false);
  }

  function functionHandleBackinViewModal() {
    setViewData(false);
    setFillForm(false);
    getStatusInformation();
  }
  function searchCourse(){
    // console.log("aval"+avalue);
    if(avalue===undefined){
      setShowSearch(false)
    }else{
      // StatusInformation=avalue
      getSearchCourse();
      setShowSearch(true);
      // console.log(getSearchCourseData)
    }
    setAValue('')
  }
  return (
    <>
      <Grid container >
      <Grid item xs={6} style={{display:'flex'}}>
        <Typography variant="h4">
          {viewData
            ? 'View Course'
            : (EditCourseInfo && 'Edit Course') || 'Create Course'}
        </Typography>
        </Grid>

        <Grid xs={6} style={{display:'flex',flexDirection:'row-reverse',justify:'flex-start'}}>
        <Button variant="contained"  color="primary" onClick={searchCourse} style={{marginTop:'auto'}} >
          Search
        </Button>
        <TextField 
        style={{flexDirection:'row-reverse',marginRight:'1%',marginTop:'auto'}}
          id="standard-basic" 
          label="Course name"
          value={avalue}
          onChange={(e)=>{setAValue(e.target.value)}}
        />
        
      </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {!fillForm && !viewData && (
        <>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  color="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {StatusDetails
                    && StatusDetails.map((statusData) => (
                      <Tab
                        key={statusData.statusId}
                        style={{ marginRight: '4%', marginLeft: '4%' }}
                        label={statusData.statusName}
                      />
                    ))}
                </Tabs>
              </AppBar>
            </Grid>
            {StatusInformation && StatusInformation.results.length === 0 && (
              <Grid item md={12} xs={12}>
                <Grid container>
                  <Grid item md={5} xs={4} />
                  <Grid item md={1} xs={1} style={{ marginLeft: '5%' }}>
                    <Typography className={classes.Message}>
                      <Avatar
                        style={{
                          fontSize: '50px',
                          width: '90px',
                          height: '90px',
                        }}
                        src={require('./books.png')}
                        alt="Remy Sharp"
                        className={classes.bigAvatar}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                {value === 0 ? (
                  <Grid>
                    <Typography variant="h5" className={classes.Message}>
                      No Courses to Display
                      {' '}
                    </Typography>
                    <Typography variant="h6" className={classes.textMessage}>
                      Click on Start Button to Create New Course
                      <br />
                      <Button
                        onClick={() => refreshPageFunction()}
                        className={classes.textMessage}
                      >
                        <RefreshIcon />
                        {' '}
                        Refresh Page
                      </Button>
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {value === 1 ? (
                  <Grid>
                    <Typography variant="h5" className={classes.Message}>
                      No In_Active Courses to Display
                      {' '}
                    </Typography>
                    <Typography variant="h6" className={classes.textMessage}>
                      Click on Start Button to Create New Course
                      <br />
                      <Button
                        onClick={() => refreshPageFunction()}
                        className={classes.textMessage}
                      >
                        <RefreshIcon />
                        {' '}
                        Refresh Page
                      </Button>
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {value === 2 ? (
                  <Grid>
                    <Typography variant="h5" className={classes.Message}>
                      No Drafted Courses to Display
                      {' '}
                    </Typography>
                    <Typography variant="h6" className={classes.textMessage}>
                      Click on Start Button to Create New Course
                      <br />
                      <Button
                        onClick={() => refreshPageFunction()}
                        className={classes.textMessage}
                      >
                        <RefreshIcon />
                        {' '}
                        Refresh Page
                      </Button>
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            )}
            { showSearch
              && getSearchCourseData!==undefined
              && getSearchCourseData!==null
              && getSearchCourseData.results.map((item, index) => {
                const {
                  course_image: courseImage,
                  course_name: courseName,
                  course_duration: courseDuration,
                  is_free: courseType,
                } = item;
                return (
                  <CourseInfoModalView
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    courseImg={courseImage}
                    ratingdata={0}
                    courseName={courseName}
                    courseDuration={courseDuration}
                    userCount=""

                    courseType={courseType}
                    button={3}
                    viewData={setViewDataFunction}
                    viewid={index}
                    editInfo={EditCourseInformation}
                    activeStatus={Status}
                    courseId={item.id}
                    activeFunc={toggleActive}
                    courseCategory={(item.course_category && item.course_category.category_name) || ''}
                  />
                );
              })}
            {StatusInformation
              && !showSearch
              && StatusInformation.length !== 0
              && StatusInformation.results.map((item, index) => {
                const {
                  course_image: courseImage,
                  course_name: courseName,
                  course_duration: courseDuration,
                  is_free: courseType,
                } = item;
                return (
                  <CourseInfoModalView
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    courseImg={courseImage}
                    ratingdata={0}
                    courseName={courseName}
                    courseDuration={courseDuration}
                    userCount=""
                    courseType={courseType}
                    button={3}
                    viewData={setViewDataFunction}
                    viewid={index}
                    editInfo={EditCourseInformation}
                    activeStatus={Status}
                    courseId={item.id}
                    activeFunc={toggleActive}
                    courseCategory={(item.course_category && item.course_category.category_name) || ''}
                  />
                );
              })}
            <Grid container spacing={2}>
              {/* <Grid item md={6}/> */}
              {StatusInformation && StatusInformation.results.length !== 0 && (
                <Grid item md={12} xs={12}>
                  <Paper
                    style={{ backgroundColor: 'lightgray', marginTop: '10px' }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TablePagination
                            colSpan={6}
                            labelDisplayedRows={() => `Page ${page
                                + 1} of ${+StatusInformation.total_pages}`}
                            rowsPerPageOptions={[8, 20, 30]}
                            count={+StatusInformation.count}
                            rowsPerPage={rowsPerPage || 8}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'Rows per page' },
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                          <TableCell style={{ marginTop: '13px' }}>
                            <IconButton
                              onClick={firstPageChange}
                              disabled={page === 0 || page === 1}
                            >
                              <FirstPageIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => lastPageChange(
                                StatusInformation.total_pages - 1,
                              )}
                              disabled={
                                page === +StatusInformation.total_pages - 1
                              }
                            >
                              <LastPageIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              )}
              <Grid item md={4} />
              <Grid item md={4} xs={12}>
                <Box className={classes.appBar}>
                  <Grid container spacing={2}>
                    <Grid item md={8} xs={8}>
                      <Typography variant="h5" className={classes.typography}>
                        Start Course Creation
                      </Typography>
                    </Grid>
                    {modulePermission && modulePermission.response.can_add ?
                    <Grid item md={4} xs={4}>
                      <Button
                        id="start"
                        variant="contained"
                        color="primary"
                        onClick={() => FetchCourseDetailsFunction()}
                      >
                        Start
                      </Button>
                    </Grid>:null}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} />
          </Grid>
        </>
      )}
      {fillForm && !viewData && (
        <>
          {courseFullForm()}
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => functionHandleBackinViewModal()}
          >
            Back to View All Courses
          </Button>
        </>
      )}
      {loader}
      {viewData && !fillForm && (
        <>
          <CourseEnroleModle
            resEnrolId={setEnrollFunction}
            showViewData={viewidno}
          />
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '10px', marginLeft: '8%' }}
            onClick={() => functionHandleBackinViewModal()}
          >
            Back
          </Button>
        </>
      )}
    </>
  );
};
CreateCourse.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CreateCourse);
