/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchBar from "material-ui-search-bar";
import {
  Grid,
  TablePagination,
  IconButton,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { useAlert } from "../../../hoc/alert";
import useFetch from "../../../hoc/useFetch";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import urls from "../../../url";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
    // marginTop: "2rem",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TeacherAttendance() {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [searched, setSearched] = useState("");

  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState([]);

  const classes = useStyles();
  // const theme = useTheme();
  // const [personName, setPersonName] = useState([]);
  const [branchWiseTeachers, setBranchWiseTeachers] = useState([]);
  const [allUserDurationList, setAllUserDurationList] = useState([]);
  const [allUserDurationPageCount, setAllUserDurationPageCount] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [permission, setPermission] = useState([]);
  const [dopen, setDopen] = useState(false);
  const [erp, setErp] = useState(null);
  const [reducePendingtime, setReducePendingtime] = useState("");
  const alert = useAlert();
  console.log(reducePendingtime);

  const columns = [
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    { id: "email", label: "Email", minWidth: 100, align: "center" },
    {
      id: "designation",
      label: "Designation",
      minWidth: 100,
      align: "center",
    },
    {
      id: "mobileNo",
      label: "Mobile No",
      minWidth: 100,
      align: "center",
    },
    { id: "erpNo", label: "ERP No", minWidth: 100, align: "center" },
    { id: "branch", label: "Branch", minWidth: 100, align: "center" },

    {
      id: "allottedHours",
      label: "Allotted Hours",
      minWidth: 100,
      align: "center",
    },
    {
      id: "pendingHours",
      label: "Pending Hours",
      minWidth: 100,
      align: "center",
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 100,
      align: "center",
    },
  ];

  const {
    data: categoryList,
    isLoading: categoryListLoading,
    doFetch: fetchCategoryList,
  } = useFetch(null);
  const {
    data: subCategoryList,
    isLoading: subCategoryListLoading,
    doFetch: fetchSubCategoryList,
  } = useFetch(null);
  const {
    data: courses,
    isLoading: coursesListLoading,
    doFetch: fetchCoursesList,
  } = useFetch(null);
  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  // const handleDateChangeFunc = (date) => {
  //   setSelectedDate(date);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleEdit = (e, user) => {
    console.log("handle", user);
    setErp(user);
    setDopen(!dopen);
  };
  const handleReducePendingHours = () => {
    const data = {
      username: erp,
      // auth.academic_profile.user.username,
      duration: reducePendingtime,
    };

    axios
      .put(`${urls.ReducePendingHours}`, data, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        let res = result.data.message;
        let capitalizedResponse = res[0].toUpperCase() + res.slice(1);
        alert.success(capitalizedResponse);
        setDopen(!dopen);
        setReducePendingtime("");
        getAllUserDurations();
      })
      .catch((error) => {
        console.log(error);
        // alert.warning(error.response.data.message);
      });
  };
  const handleClose = () => {
    setDopen(false);
    setReducePendingtime("");
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  function handelSelectAll() {
    if (
      branchWiseTeachers &&
      branchWiseTeachers.results &&
      branchWiseTeachers.results.length < 1
    ) {
      alert.warning("No Data Found");
      return 0;
    }
    if (
      selected.length ===
      (branchWiseTeachers &&
        branchWiseTeachers.results &&
        branchWiseTeachers.results.length)
    ) {
      setSelected([]);
    } else {
      const array = [];
      const n = branchWiseTeachers && branchWiseTeachers.results.length;
      for (let i = 0; i < n; i += 1) {
        array.push(branchWiseTeachers.results[i].user.id);
      }
      setSelected(array);
    }
  }

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Assign_Trainee"),
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData();
  }, []);

  useEffect(() => {
    getAllUserDurations();
  }, [page, rowsPerPage]);

  function getAllUserDurations() {
    let tempArray = [];
    axios
      .get(
        `${urls.getAllUserDurations}?page_size=${rowsPerPage ||
          10}&page=${page + 1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        }
      )
      .then((response) => {
        // response.data.results.map((eachDetails, index) => {
        //           tempArray.push({
        //             id: eachDetails?.id,
        //             erpNo: eachDetails?.erp,
        //             mobileNo: eachDetails?.phone_number,
        //             branch: eachDetails?.branch[0].branch_name,
        // duration: eachDetails?.duration,
        // erp: eachDetails?.erp,
        // lead_teacher: eachDetails?.lead_teacher,
        // roles_category: eachDetails?.roles_category
        // user: {id: 668, username: '20190424953', first_name: 'Revati', email: '20190424953@orchids.edu.in'}
        //           });
        // });
        console.log(response.data.results);
        setAllUserDurationList(response.data.results);
        setAllUserDurationPageCount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const requestSearch = (searchValue) => {
    // console.log(searchValue?.length, searchValue, "searchValue");
    let temp = [];
    if (searchValue !== "") {
      axios
        .get(`${urls.getAllUserDurations}?search=${searchValue}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        })
        .then((response) => {
          // console.log(response);
          setAllUserDurationList(response.data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getAllUserDurations();
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    getAllUserDurations();
  };
  return (
    <>
      {(loader ||
        categoryListLoading ||
        subCategoryListLoading ||
        coursesListLoading) === true && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress />
        </Backdrop>
      )}
      <div>
        <Typography variant="h4">Update Pending Hours</Typography>
        <br />
      </div>

      {/* <TableContainer component={Paper}> */}
      {allUserDurationList && allUserDurationList && (
        <>
          <div style={{ margin: "0 0 70px" }}>
            <Paper elevation={24}>
              <SearchBar
                style={{
                  width: "30%",
                  float: "right",
                  // margin: "0 0 15px 0",
                }}
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </Paper>
          </div>
          <Paper elevation={3}>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={classes.table}
              >
                <TableHead style={{ backgroundColor: "#5C78E5" }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow
                hover
                onClick={() => handelSelectAll()}
                role="checkbox"
                tabIndex={-1}
                key="100000"
                selected={
                  selected.length ===
                  (allUserDurationList && allUserDurationList.length)
                }
              >
                <TableCell align="center">
                  <Checkbox
                    checked={
                      selected.length !== 0 &&
                      selected.length ===
                        (allUserDurationList && allUserDurationList.length)
                    }
                    onClick={() => handelSelectAll()}
                  />
                </TableCell>
                <TableCell align="center">Select All</TableCell>
              </TableRow> */}
                  {allUserDurationList &&
                    allUserDurationList.map((item) => {
                      const isItemSelectedId = isSelected(item?.id);
                      return (
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={item?.id}
                          selected={isItemSelectedId}
                        >
                          {/* <TableCell
                        align="center"
                        onClick={(event) => handleClick(event, item.id)}
                      >
                        {" "}
                        <Checkbox
                          checked={isItemSelectedId}
                          id={item.user.first_name + item.user.id}
                          key={item.user.first_name + item.user.id}
                          // inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                          <TableCell align="center">
                            {item && item?.user && item?.user?.first_name}
                          </TableCell>
                          <TableCell align="center">
                            {item?.user?.email}
                          </TableCell>
                          <TableCell align="center">
                            {item?.roles_category?.name}
                          </TableCell>
                          <TableCell align="center">
                            {item?.phone_number}
                          </TableCell>
                          <TableCell align="center">
                            {item?.erp || null}
                          </TableCell>
                          <TableCell align="center">
                            {item?.branch[0]?.branch_name}
                          </TableCell>
                          <TableCell align="center">
                            {item?.duration?.total_duration}
                          </TableCell>
                          <TableCell align="center">
                            {item?.duration?.pending_duration}
                          </TableCell>
                          <TableCell align="center">
                            <EditIcon
                              style={{ cursor: "pointer" }}
                              onClick={(event) => handleEdit(event, item?.erp)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <Dialog
                    open={dopen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Reduce time in minutes
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        id="outlined-basic"
                        onChange={(e) => setReducePendingtime(e.target.value)}
                        label="Enter time in minutes"
                        variant="outlined"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        variant="default"
                        color="primary"
                      >
                        cancel
                      </Button>
                      <Button
                        onClick={handleReducePendingHours}
                        variant="contained"
                        color="primary"
                        autoFocus
                        disabled={reducePendingtime === ""}
                      >
                        save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
      {allUserDurationList && allUserDurationList.length !== 0 && (
        <Grid item md={12} xs={12}>
          <Paper style={{ backgroundColor: "lightgray", marginTop: "10px" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() =>
                      `Page ${page +
                        1} of ${+allUserDurationPageCount.total_pages}`
                    }
                    rowsPerPageOptions={[5, 20, 30]}
                    count={+allUserDurationPageCount.count}
                    rowsPerPage={rowsPerPage || 10}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: "13px" }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        lastPageChange(allUserDurationPageCount.total_pages - 1)
                      }
                      disabled={
                        page === +allUserDurationPageCount.total_pages - 1
                      }
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
      {/* {permission.can_add ? (
        <>
          {branchWiseTeachers && branchWiseTeachers.results && (
            <button
              type="submit"
              style={{
                backgroundColor: "royalblue",
                padding: "0.5rem 1rem",
                color: "white",
                cursor: "pointer",
                border: "1px solid transparent",
                borderRadius: "4px",
                marginTop: "1rem",
              }}
              onClick={assignTeachersSubmit}
            >
              Assign The Teachers
            </button>
          )}
        </>
      ) : null} */}
      {/* </TableContainer> */}
    </>
  );
}

export default TeacherAttendance;
