export default (theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  floatingLabelFocusStyle: {
    color: 'red',
  },
  addUserbutton: {
    marginTop: '26px',
  },
  addUserbtn: {
    display: 'grid',
  },
  creatorProSection: {
    marginTop: '30px',
  },
  proImg: {
    display: 'Block',
    margin: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  cPro: {
    display: 'block',
    margin: 'auto',
    borderRadius: '50%',
  },
  upload: {
    background: '#555',
    color: '#fff',
    padding: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  videoLoad: {
    display: 'block',
    margin: 'auto',
    width: 'auto',
  },
  uploadModal: {
    color: '#fff',
    padding: '12px',
    fontSize: '14px',
    marginBottom: '13px',
    background: '#424242',
    fontWeight: 'bold',
  },
  backgroundHead: {
    // background: '#ababab',
    margin: '3px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveCreatorButton: {
    marginTop: '9px',
  },
  spanUpdateBtn: {
    background: '#555',
    color: '#fff',
    padding: '5px',
    margin: '5px',
  },
  timer: {
    fontSize: '15px',
  },
});
