/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid, TablePagination, IconButton, Typography, Divider, TextField, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './ChapterSequence.Style';
import { useAlert } from '../../../hoc/alert';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';
import axios from 'axios';

function ChapterSequence({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [indexId, setIndexId] = useState('');
  const [subjectId, setSubjectId] = useState(0);
  const [volumeId, setVolumeId] = useState(0);
  const [chapterList, setChapterList] = useState([]);
  const alert = useAlert();
  const {
    data: sequencingCoursesList,
    isLoading: gettingSequencingCourseList,
    doFetch: fetchAllSeequencingCourseList,
  } = useFetch([]);

  const {
    data: sequencingUpdateRes,
    isLoading: updatingSequencing,
    doFetch: fetchSubmitting,
  } = useFetch([]);

  const {
    data: GradeList,
    isLoading: gradeListLoading,
    doFetch: fetchGradeList
  } = useFetch([]);

  const {
    data: subjectList,
    isLoading: subjectLoading,
    doFetch: fetchSubjectList
  } = useFetch([])

  const {
    data: volumeList,
    isLoading: volumeListLoading,
    doFetch: fetchVolumeList
  } = useFetch([])
  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);

  useEffect(() => {
    //  module=localStorage.getItem('Chapter_Sequence')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Chapter_Sequence')
      },
    });
  }, [])

  async function handleChapter() {
    axios.get(`${urls.getChaptersByVolume}?volume_id=${volumeId}&page_size=${rowsPerPage || 12}&page=${page + 1}`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Chapter_Sequence')
      },

    }).then((res) => {
      setChapterList(res.data)
      //  setGradeList(res.data)
    }).catch((err) => {
      alert.warning(err)
    });
  }


  useEffect(() => {
    fetchGradeList({
      url: `${urls.grades}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Chapter_Sequence')
      },
    });
    handleChapter();
  }, [auth, page, rowsPerPage, sequencingUpdateRes, indexId]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(12);
    }
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  const handleGradeChange = (data) => {
    if (data !== null && data.id !== null)
      fetchSubjectList({
        url: `${urls.getSubjectByGrade}?grade_id=${data.id}`,
        method: `GET`,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Chapter_Sequence')
        },
      })

  }
  const handleSubjectChange = (data) => {
    if (data !== null && data.id !== null)
      fetchVolumeList({
        url: `${urls.getVolumeBySubject}?subject_id=${data.id}`,
        method: `GET`,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Chapter_Sequence')
        },
      })
  }

  const handleVolumeChange = (data) => {
    if (data !== null) {
      setVolumeId(data.id)
    }
  }
  function handleGetChapters() {
    axios.get(`${urls.getChaptersByVolume}?volume_id=${volumeId}&page_size=${rowsPerPage || 12}&page=${page + 1}`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Chapter_Sequence')
      },

    }).then((res) => {
      setChapterList(res.data)
    }).catch((err) => {
      alert.warning(err)
    });
    if (indexId) {
      document.getElementById(`sequencing${parseInt(indexId, 10)}`).value = '';
    }
  }

  let loader = null;
  if (gettingSequencingCourseList || updatingSequencing || gradeListLoading || subjectLoading || volumeListLoading || modulePermissionLoading) {
    loader = <Loader open />;
  }

  function functionToUpdate(id, e) {
    setIndexId(id);
    if (!document.getElementById(`sequencing${id}`).value) {
      alert.warning('enter sequencing number');
      return;
    }
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('are you confirm to change the sequencing number');
    if (confirm) {
      const data = {
        sequencing: document.getElementById(`sequencing${id}`).value,
      };
      fetchSubmitting({
        url: `${urls.setChapterSequencing}${id}/chapter_sequencing/?new_sequencing=${data.sequencing}`,
        body: data,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Chapter_Sequence')
        },
      });
    }
    setTimeout(function () {
      handleChapter()
        ;
    }, 1000);

  }

  return (
    <>
      <Typography variant="h4">Chapter Sequencing</Typography>
      <Divider className={classes.divider} />
      <Grid md={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} sm={3}>
            <Autocomplete
              id="Grade"
              options={GradeList}
              getOptionLabel={(option) => option.type_name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Select Grade"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />}
              onChange={(e, data) => handleGradeChange(data)}

            />
          </Grid>
          <Grid item md={3} xs={12} sm={3}>
            <Autocomplete
              id="Subject"
              options={subjectList.data}
              getOptionLabel={(option) => option.subject_name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Select Subject"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />}
              onChange={(e, data) => handleSubjectChange(data)}

            />
          </Grid>
          <Grid item md={3} xs={12} sm={3}>
            <Autocomplete
              id="Subject"
              options={volumeList.data}
              getOptionLabel={(option) => option.volume_name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Select Volume"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
              }
              onChange={(e, data) => handleVolumeChange(data)}

            />
          </Grid>
          <Grid item md={3} xs={12} sm={3}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '10px' }}
              onClick={handleGetChapters}
            >
              submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {chapterList && chapterList.results
            && chapterList.results.length === 0
            && <Typography variant="h6" style={{ color: 'blue', marginTop: '20px', textAlign: 'center' }}>Chapters Not Found</Typography>}
          {chapterList && chapterList.results
            && chapterList.results.length !== 0
            && (
              <Paper className={classes.paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">S.No</TableCell>
                      <TableCell align="left">Chapter Name</TableCell>
                      <TableCell align="left">Old Sequencing Number</TableCell>
                      <TableCell align="left">Enter New Sequencing Number</TableCell>
                      {modulePermissionLoading && modulePermission.response.can_update ?
                        <TableCell align="left">Change</TableCell> : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chapterList
                      && chapterList.results
                      && chapterList.results.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{item.title}</TableCell>
                          <TableCell align="left">{item.sequencing}</TableCell>
                          <TableCell align="left">
                            <TextField
                              helperText="Enter New Sequencing Number"
                              margin="dense"
                              required
                              id={`sequencing${item.id}`}
                              onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value, 10))
                                  .toString().slice(0, 10);
                              }}
                              type="number"
                              inputProps={{ style: { fontFamily: 'Times', color: 'black' } }}
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell align="left">
                            {modulePermission && modulePermission.response.can_update ?
                              <Button variant="contained" color="primary" onClick={(e) => functionToUpdate(item.id, e)}>Change</Button>
                              : null}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
        </Grid>
        <Grid item md={12} xs={12}>
          {chapterList
            && chapterList.results
            && chapterList.results.length !== 0 && (
              <Paper style={{ backgroundColor: 'lightgray', marginTop: '10px' }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TablePagination
                        colSpan={6}
                        labelDisplayedRows={() => `Page ${page + 1} of ${+chapterList.total_pages}`}
                        rowsPerPageOptions={[12, 20, 30]}
                        count={+chapterList.count}
                        rowsPerPage={rowsPerPage || 12}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'Rows per page' },
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      <TableCell style={{ marginTop: '13px' }}>
                        <IconButton
                          onClick={firstPageChange}
                          disabled={page === 0 || page === 1}
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => lastPageChange(chapterList.total_pages - 1)}
                          disabled={page === +chapterList.total_pages - 1}
                        >
                          <LastPageIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            )}
        </Grid>
      </Grid>
      {loader}
    </>
  );
}

ChapterSequence.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ChapterSequence);
