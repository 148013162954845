/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  withStyles,
  Divider,
  Button,
  Modal,
  Fade,
  IconButton,
  Backdrop,
  Box,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import styles from './addLinkModule.style';

const AddLinksModel = ({
  classes, open, handleClose, saveDataFunc, linkList, updataList,
}) => {
  // const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [linksArray, setLinksArray] = useState(linkList || []);

  const linkTypesList = [
    { id: 1, activityName: 'PDF' },
    { id: 1, activityName: 'Video' },
    { id: 1, activityName: 'WebSite' },
  ];

  function addLinkFunction() {
    setLinksArray((oldData) => [
      ...oldData,
      { page_no: '', link: [] },
    ]);
  }

  function addExtraLable(index, key) {
    setLinksArray((previousData) => {
      previousData[index][key].push({ linkInfo: '', linkType: '', name: '' });
      return [...previousData];
    });
  }

  const handleArray = (i, e, key, id, key2) => {
    if (!linksArray.length) {
      alert.warning('No data available');
      return;
    }

    setLinksArray((data) => {
      const newData = [...data];
      switch (key) {
        case 'page_no':
          newData[i][key] = e;
          return newData;
        case 'link':
          newData[i][key][id][key2] = e;
          return newData;
        default:
          return null;
      }
    });
  };

  function removeFunction(index) {
    setLinksArray((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function DeleteLableFunction(index, labelIndex) {
    setLinksArray((previousData) => {
      previousData[index].link.splice(labelIndex, 1);
      return [...previousData];
    });
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
      // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={11} xs={11}>
                <Typography variant="h4">{updataList ? 'Update List' : 'Add Links'}</Typography>
              </Grid>
              <Grid item md={1} xs={1}>
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Button variant="outlined" color="primary" onClick={() => addLinkFunction()}>Add more + </Button>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            {linksArray && linksArray.length === 0
          && <Typography variant="h5" style={{ color: 'blue', marginTop: '20px', textAlign: 'center' }}>Press Add Button to add Links</Typography>}
            {linksArray && linksArray.length !== 0 && linksArray.map((data, index) => (
              <Box border={1} className={classes.box} key={index}>
                <Grid container spacing={2} key={index}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label="Page No"
                      margin="dense"
                      type="number"
                      required
                      value={data.page_no || ''}
                      onChange={(e) => handleArray(index, e.target.value, 'page_no')}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12} style={{ marginTop: '10px' }}>
                    <Button variant="outlined" color="primary" onClick={() => addExtraLable(index, 'link')}>Add More Links + </Button>
                  </Grid>
                  <Grid item md={2} xs={12} style={{ marginTop: '10px' }}>
                    <Button variant="contained" color="primary" onClick={() => removeFunction(index)}>
                      Delete
                    </Button>
                  </Grid>
                  {data && data.link.map((infoLink, indexNo) => (
                    <>
                      <Grid item md={3} xs={12} key={indexNo}>
                        <Typography>
                          Selct Link Type
                          <b style={{ color: 'red' }}>*</b>
                        </Typography>
                        <Select
                          margin="dense"
                          fullWidth
                          placeholder="select Link Type *"
                          value={infoLink.linkType}
                          variant="outlined"
                          onChange={(e) => handleArray(index, e.target.value, 'link', indexNo, 'linkType')}
                        >
                          {linkTypesList.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.activityName}
                            >
                              {option.activityName}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12} style={{ marginTop: '16px' }}>
                        <TextField
                          className={classes.textField}
                          label="Name"
                          margin="dense"
                          required
                          value={infoLink.name || ''}
                          onChange={(e) => handleArray(index, e.target.value, 'link', indexNo, 'name')}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12} style={{ marginTop: '16px' }}>
                        <TextField
                          className={classes.textField}
                          label="Link"
                          margin="dense"
                          required
                          value={infoLink.linkInfo || ''}
                          onChange={(e) => handleArray(index, e.target.value, 'link', indexNo, 'linkInfo')}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={2} xs={12} style={{ marginTop: '16px' }}>
                        <IconButton onClick={() => DeleteLableFunction(index, indexNo)}>
                          <CloseIcon variant="contained" color="primary" />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            ))}
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button variant="contained" color="primary" onClick={() => saveDataFunc(linksArray)}>Save</Button>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

AddLinksModel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveDataFunc: PropTypes.func.isRequired,
  updataList: PropTypes.bool.isRequired,
  linkList: PropTypes.instanceOf(Array).isRequired,
};

export default withStyles(styles)(AddLinksModel);
