/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  withWidth,
  withStyles,
  Typography,
  // Divider,
  Button,
  Radio,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import urls from '../../url';
import styles from './forgotPassword.style';
import Loader from '../../hoc/loader';
import { useAlert } from '../../hoc/alert';

const ForgotPassword = ({ classes }) => {
  const [erp, setErp] = useState('');
  const [handleEmailorNumber, setHandleEmailorNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState('');
  const [confirmPassword, setConfrmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [LoaderOnn, setLoaderOnn] = useState(false);
  const [uniquekey, setUniquekey] = useState('');
  const [message, setMessage] = useState('');

  const alert = useAlert();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  let loader = null;
  if (LoaderOnn) {
    loader = <Loader open />;
  }

  // function to send erp , phone or email id option
  function SendOtpFunctions() {
    if (!handleEmailorNumber) {
      alert.warning('Enter ERP and select Email or Mobile Number');
      return;
    }
    if (!erp || erp.length < 11) {
      alert.warning('Enter Your Valid 11 Digit Erp No');
      return;
    }
    setLoaderOnn(true);
    fetch(
      `${urls.forgetPasswordOtpGeneratedApi}${(handleEmailorNumber
        === 'MobileNo'
        && `?mobile_no=${true}`)
        || `?email=${true}`}&erp=${erp}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        // module: localStorage.getItem('Change_Password')
        },
      },
    )
      .then((res) => {
        if (res.status === 404) {
          setLoaderOnn(false);
          alert.error('Account does not exists with this erp.');
        }
        if (res.status === 200) {
          setLoaderOnn(false);
          setActiveStep(2);
          alert.success(
            'Otp is send to your registerd EmailId or Mobile Number',
          );
          return res.json();
        }
        if (res.status !== 200 && res.status !== 404) {
          setLoaderOnn(false);
          alert.warning('somthing went wrong please try again ');
        }
        return null;
      })
      .then((data) => {
        if (data && data.unique_key && data.unique_key) {
          setUniquekey(data.unique_key);
        }
        if (data && data.response && data.response) {
          setMessage(data.response);
        }
      });
  }

  function OtpVerifyFunction() {
    if (!otp) {
      alert.warning('Enter Otp');
      return;
    }
    setLoaderOnn(true);
    fetch(`${urls.otpVerifyurl}?otp=${otp}&verification_id=${erp}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        module: localStorage.getItem('Change_Password')
      },
    })
      .then((res) => {
        if (res.status === 404) {
          setLoaderOnn(false);
          alert.error('Invalid OTP');
        }
        if (res.status === 200) {
          setLoaderOnn(false);
          return res.json();
        }
        if (res.status !== 200 && res.status !== 404) {
          setLoaderOnn(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => {
        if (data.response === 'success') {
          setLoaderOnn(false);
          setActiveStep(3);
          alert.success('Otp Verified Successfully');
        } else {
          setLoaderOnn(false);
          alert.warning('Incorrect OTP');
        }
      });
  }

  function SubmitChangePassword() {
    if (!confirmPassword || !password) {
      alert.warning('Fill the Fields');
      return;
    }
    if (confirmPassword !== password) {
      alert.warning('Confirm Password Is Not Matching');
      return;
    }
    setLoaderOnn(true);

    fetch(
      `${urls.forgetPasswordApi}?password=${confirmPassword}&verification_id=${erp}&unique_key=${uniquekey}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        module: localStorage.getItem('Change_Password')
        },
      },
    ).then((res) => {
      if (res.status === 200) {
        setLoaderOnn(false);
        alert.success('Password reset is Successfully Done');
        window.location.href = '/';
        return res.json();
      }
      if (res.status !== 200) {
        setLoaderOnn(false);
        alert.warning('somthing went wrong please try again ');
      }
      return null;
    });
  }

  return (
    <div>
      <Grid
        container
        justify="center"
        style={{
          paddingTop: '80px',
          // paddingLeft: "20px",
          // paddingRight: "20px",
        }}
      >
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            color="primary"
            style={{ textAlign: 'center' }}
          >
            Forgot Password
          </Typography>
        </Grid>
        {activeStep === 1 && (
          <Grid container justify="center" className={classes.grid}>
            <Grid item md={4} xs={12} style={{ alignContent: 'center' }}>
              <TextField
                label="Enter ERP No"
                variant="outlined"
                type="number"
                margin="dense"
                fullWidth
                required
                value={erp || ''}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value, 10))
                    .toString()
                    .slice(0, 11);
                }}
                inputProps={{ maxLength: 11 }}
                onChange={(e) => (e.target.value.length < 12 && setErp(e.target.value)) || ''}
              />
              {erp && erp.length < 11 ? (
                <FormHelperText
                  id="component-error-text"
                  style={{ color: 'red' }}
                >
                  Enter Your 11 Digit Valid ERP no
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                Select to Send OTP to
              </Typography>
            </Grid>
            <Grid item md={4} />
            <Grid item md={8} xs={12}>
              <Radio
                checked={handleEmailorNumber === 'MobileNo'}
                onChange={(e) => setHandleEmailorNumber(e.target.value)}
                value="MobileNo"
                hideRadio
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
              />
              {' '}
              Mobile Number
              <Radio
                checked={handleEmailorNumber === 'EmailId'}
                onChange={(e) => setHandleEmailorNumber(e.target.value)}
                value="EmailId"
                hideRadio
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'B' }}
              />
              Email Id
            </Grid>
            <Grid item md={4} />
            <Grid container spacing={1}>
              <Grid item md={4} />
              <Grid item md={2} xs={12}>
                <a href="/">
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="medium"
                  >
                    Back
                  </Button>
                </a>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    SendOtpFunctions();
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {activeStep === 2 && (
          <Grid container className={classes.grid}>
            <Grid md={12} xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h6">{message}</Typography>
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <TextField
                label="Enter OTP"
                margin="normal"
                variant="outlined"
                fullWidth
                value={otp || ''}
                onChange={(e) => (e.target.value.length < 7 ? setOtp(e.target.value) : '')}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={4} />
              <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="medium"
                  onClick={() => setActiveStep(1)}
                >
                  Back
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    OtpVerifyFunction();
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {activeStep === 3 && (
          <>
            <Grid container className={classes.grid}>
              <Grid item md={4} />
              <Grid item md={4} xs={12}>
                <TextField
                  label="New Password"
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} />
              <Grid item md={4} />
              <Grid item md={4} xs={12}>
                <TextField
                  label="Confirm Password"
                  margin="normal"
                  type={showPassword1 ? 'text' : 'password'}
                  required
                  fullWidth
                  value={confirmPassword || ''}
                  onChange={(e) => setConfrmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCPassword}
                          onMouseDown={handleMouseDownCPassword}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                {password && confirmPassword && confirmPassword !== password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Confirm Password should match password
                  </FormHelperText>
                ) : null}
                {password && confirmPassword && confirmPassword === password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Confirm Password matched
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4} />
                <Grid item md={2} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="medium"
                    onClick={() => setActiveStep(2)}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="medium"
                    onClick={() => {
                      SubmitChangePassword();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {loader}
    </div>
  );
};

ForgotPassword.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(withWidth()(ForgotPassword));
