/* eslint-disable max-len */
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';

const Responsive = () => {
  // render() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div style={{ width: '95%', margin: '15px auto' }}>
      <h2 style={{ textAlign: 'center' }}>Testimonials</h2>
      <div style={{ padding: 14 }}>
        <Slider {...settings}>
          <div>
            <img
              alt="crash"
              src={require('./SliderMedia/Aparna.jpeg')}
              width="50%"
              height="200px"
              style={{ margin: '0 auto', paddingBottom: '5px' }}
            />
            <h4 style={{ textAlign: 'center', fontFamily: 'times' }}>Aparna Khairnar</h4>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Principal </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> ORCHIDS THE INTERNATIONAL SCHOOL. </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Ambegaon. </p>
            <p className="testimonialPara">
              Technology and educational specialists need to be mindful of investing on their teaching staff as well as digital teaching tools such as Sure Learning. Yes, it’s Sure Learning that is training our teachers to become more efficient. It is anytime, anywhere!
              {/* This module has a right blend of practical as well as theoretical
              knowledge of the concepts covered. This is tailored according to
              the ORCHIDS organizational needs. It is fun, accessible,
              interactive and exciting for the employees to attend. */}
              {' '}
            </p>
          </div>
          <div>
            <img
              alt="crash"
              src={require('./SliderMedia/KavitaMukherjee.JPG')}
              width="50%"
              height="200px"
              style={{ margin: '0 auto', paddingBottom: '5px' }}
            />
            <h4 style={{ textAlign: 'center', fontFamily: 'times' }}>Kavita Mukherjee</h4>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Principal</p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> ORCHIDS THE INTERNATIONAL SCHOOL. </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Borivali.</p>
            <p className="testimonialPara">
              First of all, I would like to congratulate the learning and development team for preparing the training modules and launching this website ‘Sure Learning’. This training module acts as an induction manual for all. A great initiative altogether!
              {/* Technology and educational specialists need to be mindful of
              investing on their teaching staff as well as digital teaching
              tools such as Sure Learning. Yes, it’s Sure Learning that is
              training our teachers to become more efficient. It is anytime,
              anywhere! */}
            </p>
          </div>
          <div>
            <img
              alt="crash"
              src={require('./SliderMedia/Rekha.jpeg')}
              width="50%"
              height="200px"
              style={{ margin: '0 auto', paddingBottom: '5px' }}
            />
            <h4 style={{ textAlign: 'center', fontFamily: 'times' }}>Rekha Jadhav</h4>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Principal</p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> ORCHIDS THE INTERNATIONAL SCHOOL. </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Mahalakshmi Layout. </p>
            <p className="testimonialPara">
              Sure Learning takes the learning to whole new world of exploration and experiences of being independent and confident through self-driven online learning.I found it is well-structured and designed as a single-stop solution for all needs of a learner.
              {/* First of all, I would like to congratulate the learning and
              development team for preparing the training modules and launching
              this website ‘Sure Learning’. This training module acts as an
              induction manual for all. A great initiative altogether! */}
              {' '}
            </p>
          </div>
          <div>
            <img
              alt="crash"
              src={require('./SliderMedia/Sakina.jpeg')}
              width="50%"
              height="200px"
              style={{ margin: '0 auto', paddingBottom: '5px' }}
            />
            <h4 style={{ textAlign: 'center', fontFamily: 'times' }}>Sakina Qasim Zaidi</h4>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Principal </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> ORCHIDS THE INTERNATIONAL SCHOOL. </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Mysore Road branch. </p>
            <p className="testimonialPara">
              This module has a right blend of practical as well as theoretical knowledge of the concepts covered. This is tailored according to the ORCHIDS organizational needs. It is fun, accessible, interactive and exciting for the employees to attend.
              {/* Sure Learning permits the learner with the opportunity to balance
              his /her priorities. A learner can adjust her educational duties
              to fit amidst other life priorities, while still advancing in the
              direction of a brighter future. Happy learning…. */}
            </p>
          </div>
          <div>
            <img
              alt="crash"
              src={require('./SliderMedia/vikrolibranch.jpeg')}
              width="50%"
              height="200px"
              style={{ margin: '0 auto', paddingBottom: '5px' }}
            />
            <h4 style={{ textAlign: 'center', fontFamily: 'times' }}>Kavita Nagpal</h4>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Principal </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> ORCHIDS THE INTERNATIONAL SCHOOL. </p>
            <p className="testimonialPara" style={{ fontFamily: 'times' }}> Vikhroli. </p>
            <p className="testimonialPara">
              Sure Learning permits the learner with the opportunity to balance his /her priorities. A learner can adjust her educational duties to fit amidst other life priorities, while still advancing in the direction of a brighter future. Happy learning….
              {/* Sure Learning takes the learning to whole new world of exploration
              and experiences of being independent and confident through
              self-driven online learning. I found it is well-structured and
              designed as a single-stop solution for all needs of a learner. */}
              {' '}
            </p>
          </div>
        </Slider>
      </div>
    </div>
  );
  // }
};
export default Responsive;
