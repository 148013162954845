/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Divider,
  withStyles,
  TablePagination,
  IconButton,
  Paper,
  // Avatar,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';
import styles from './Authorize.style';
import Loader from '../../../hoc/loader';
import CourseInfoModalView from '../../reusableComponents/courseInfoModal/courseInfoModal';
import CourseEnroleModle from '../../reusableComponents/courseEnroleModle/courseEnroleModle';

const CreateCourse = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [fillForm, setFillForm] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [viewidno, setViewId] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);

  const {
    data: StatusInformation,
    isLoading: StatusInformationLoading,
    doFetch: FetchStatusInformation,
  } = useFetch('');

  const {
    data: publishCourse,
    isLoading: publishingCourse,
    doFetch: fetchToPublishCourse,
  } = useFetch(null);

  let loader = null;
  if (StatusInformationLoading || publishingCourse) {
    loader = <Loader open />;
  }

  function getStatusInformation() {
    FetchStatusInformation({
      url: `${urls.getAuthorizeCourses}?page_size=${rowsPerPage || 8}&page=${page + 1}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(8);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth) {
      getStatusInformation();
    }
  }, [auth, page, rowsPerPage, publishCourse]);

  // useEffect(() => {
  //   if (courseDetailsData) {
  //     setFillForm(true);
  //   }
  // }, [courseDetailsData]);

  function setViewDataFunction(Indno) {
    setViewData(true);
    setFillForm(false);
    setViewId(StatusInformation && StatusInformation[Indno]);
    localStorage.setItem('viewID', Indno);
  }

  function functionToUnpublishCourse(info) {
    const data = {
      dis_approved: true,
    };
    fetchToPublishCourse({
      url: `${urls.publishCourse}/${info}/update_is_course_to_publish/`,
      method: 'PUT',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
  }
  function functionToPublishCourse(info) {
    const data = {
      is_publish: true,
    };
    fetchToPublishCourse({
      url: `${urls.publishCourse}/${info}/update_is_course_to_publish/`,
      method: 'PUT',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')

      },
    });
  }

  function setEnrollFunction() {
    setViewData(false);
  }

  function functionHandleBackinViewModal() {
    setViewData(false);
    setFillForm(false);
    getStatusInformation();
  }

  return (
    <>
      <Typography variant="h4">Authorize Course</Typography>
      <Divider className={classes.divider} />
      {!fillForm && !viewData && (
        <>
          <Grid container spacing={2}>
            <Grid item md={4} />
            {StatusInformation && StatusInformation.results.length === 0 && (
              <Grid item md={4} xs={12}>
                <Typography variant="h5" className={classes.Message}>
                  No Courses are Ready to Publish
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {StatusInformation
              && StatusInformation.results.length !== 0
              && StatusInformation.results.map((item, index) => {
                const {
                  course_image: courseImage,
                  course_name: courseName,
                  course_duration: courseDuration,
                  is_free: courseType,
                  // course_type: courseTypeObj,
                } = item;
                // let { type_name: courseTypeName } = courseTypeObj || {}
                return (
                  <CourseInfoModalView
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    courseImg={courseImage}
                    ratingdata=""
                    courseName={courseName}
                    courseDuration={courseDuration}
                    userCount=""
                    courseType={courseType}
                    button={4}
                    viewData={setViewDataFunction}
                    viewid={item.id}
                    publishCourse={functionToPublishCourse}
                    unpublishCourse={functionToUnpublishCourse}
                    courseCategory={(item.course_category && item.course_category.category_name) || ''}
                  />
                );
              })}

            <Grid container spacing={2}>
              {StatusInformation && StatusInformation.results.length !== 0 && (
                <Grid item md={12} xs={12}>
                  <Paper
                    style={{ backgroundColor: 'lightgray', marginTop: '10px' }}
                  >
                    <TableRow>
                      <TablePagination
                        colSpan={6}
                        labelDisplayedRows={() => `Page ${page
                            + 1} of ${+StatusInformation.total_pages}`}
                        rowsPerPageOptions={[8, 20, 30]}
                        count={+StatusInformation.count}
                        rowsPerPage={rowsPerPage || 8}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'Rows per page' },
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      <TableCell style={{ marginTop: '13px' }}>
                        <IconButton
                          onClick={firstPageChange}
                          disabled={page === 0 || page === 1}
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => lastPageChange(StatusInformation.total_pages - 1)}
                          disabled={page === +StatusInformation.total_pages - 1}
                        >
                          <LastPageIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {loader}
      {viewData && !fillForm && (
        <>
          <CourseEnroleModle
            resEnrolId={setEnrollFunction}
            showViewData={viewidno}
          />
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '10px', marginLeft: '8%' }}
            onClick={() => functionHandleBackinViewModal()}
          >
            Back
          </Button>
        </>
      )}
    </>
  );
};
CreateCourse.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CreateCourse);
