/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Grid, Typography, Button, Box, Divider, TextField, TablePagination, IconButton, Paper, TableRow, TableCell, Table, TableBody, AppBar, Tabs, Tab,
} from '@material-ui/core';
// import mqtt from 'mqtt';
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@material-ui/icons/Close';
import { Add as AddIcon } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import Loader from '../../../hoc/loader';
import styles from './treasureBox.style';
import { useAlert } from '../../../hoc/alert/alert';
import TinyTextEditor from '../../../hoc/tinyMce/tinyTextEditor';
import urls from '../../../url';
import useFetch from '../../../hoc/useFetch';
import axios from 'axios';


const TreasureBox = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [allModules, setAllModules] = useState('');
  const [EditModule, setEditModule] = useState(false);
  const [modelCretatingResponse, setmodelCretatingResponse] = useState('');
  const [moduleIdno, setModuleIdNo] = useState('');
  const [openView, setOpenView] = useState(false);
  const [openFullView, setFullView] = useState(false);
  const [FullViewData, seFullViewData] = useState({ title: null, video: null, text: null });
  const [statusInfo, setStatus] = useState('');
  const [value, setValue] = React.useState(0);
  const [allVidoesCount, setAllVidoesCount] = useState();
  const [permission,setPermission]=useState([]);

  // const [uploadProgress, setUploadProgress] = useState(0);
  // const [topic, setTopic] = useState(0);
  // const [categoryId, setCategoryId] = useState('');

  const StatusArray = [
    { statusId: 1, statusName: 'Active' },
    { statusId: 2, statusName: 'In Active' },
  ];

  const {
    // data: categoryList,
    isLoading: gettingCategoryList,
    doFetch: FetchtreasureBoxCatogary,
  } = useFetch(null);

  function handleChange(event, newValue) {
    setPage(0);
    setRowsPerPage(12);
    setValue(newValue);
    if (StatusArray[newValue].statusId === 1) {
      setPage(0);
      setRowsPerPage(8);
      setStatus('active');
    } else if (StatusArray[newValue].statusId === 2) {
      setPage(0);
      setRowsPerPage(8);
      setStatus('in_active');
    }
  }

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(8);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (modelCretatingResponse) {
      setModuleIdNo(modelCretatingResponse && modelCretatingResponse.id);
    }
  }, [modelCretatingResponse]);

  const functionToGetVideoCount = () => {
    setLoading(true);
    fetch(`${urls.treasureBoxVideoCount}?is_active=${statusInfo || 'active'}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Treasure_Box')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Successfully Fetched');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => {
        setAllVidoesCount(data);
      });
  };

  function functionToGetModules() {
    setLoading(true);
    fetch(`${urls.creatingModuleApi}?is_active=${statusInfo}&page_size=${rowsPerPage || 8}&page=${page + 1}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Treasure_Box')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Successfully Fetched');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => {
        setAllModules(data);
      });
  }

  function functionToActiveInactive(moduleId, status) {
    setPage(0);
    setRowsPerPage(8);
    const data = {
      id: moduleId,
      is_active: !status,
    };
    setLoading(true);
    fetch(urls.creatingModuleApi, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Treasure_Box')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          functionToGetModules();
          functionToGetVideoCount();
          alert.success('Successfully');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      });
  }
  function functionToHandleActiveStatus(id, status) {
    functionToActiveInactive(id, status);
  }

  useEffect(() => {
    if (auth) {
      functionToGetModules();
      functionToGetVideoCount();
      FetchtreasureBoxCatogary({
        url: `${urls.retriveTreasureCategoryApi}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Treasure_Box')
        },
      });
    }
  }, [auth, page, rowsPerPage, statusInfo]);

  let loader = null;
  if (loading || gettingCategoryList) {
    loader = <Loader open />;
  }

     // For Permissions
     function getPermissonData(id) {
       console.log(id, "module Id in api call");
      axios.get(urls.getPermissons,{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      }).then(response => {
        setPermission(response.data.response)
      }).catch(error => {
        console.log(error);
      })
    }
  
    useEffect(() => {
      getPermissonData(localStorage.getItem('Treasure_Box'))
    }, [])

  const handleClose = () => {
    setOpen(false);
    setEditModule(false);
    setModuleIdNo('');
    setTitle('');
    setVideoData([]);
    functionToGetModules();
    functionToGetVideoCount();
  };

  function functionToAddVideo() {
    setVideoData((oldData) => [
      ...oldData,
      {
        title: null,
        file: null,
        text: null,
      },
    ]);
  }

  function functionToGetVideos(id) {
    setLoading(true);
    fetch(`${urls.creatingVideoForEachModuleApi}?module=${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Treasure_Box')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Videos Successfully Recieved');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => {
        setVideoData(data);
      });
  }

  function functionToDeleteVideo(index, videoId, created) {
    if (created) {
      setLoading(true);
      fetch(`${urls.creatingVideoForEachModuleApi}?id=${videoId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
        module: localStorage.getItem('Treasure_Box')
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            functionToGetVideos(moduleIdno);
            alert.success('Video Successfully Deleted');
            return res.json();
          }
          if (res.status !== 200) {
            setLoading(false);
            alert.warning('somthing went wrong please try again ');
          }
          return 0;
        });
    }
    if (!created) {
      setVideoData((previousData) => {
        previousData.splice(index, 1);
        return [...previousData];
      });
    }
  }

  function functionToHandleVideoData(e, i, key) {
    setVideoData((data) => {
      const newData = [...data];
      switch (key) {
        case 'title':
          newData[i][key] = e;
          return newData;
        case 'file':
          newData[i][key] = e;
          return newData;
        case 'text':
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    });
  }

  function functionToEditModule(id, TitleName) {
    setOpen(true);
    setEditModule(true);
    setModuleIdNo(id);
    setTitle(TitleName);
    functionToGetVideos(id);
  }

  // useEffect(() => {
  //   if (topic) {
  //     const client = window.location.hostname === 'localhost'
  //       ? mqtt.connect('ws://localhost:1883')
  //       : mqtt.connect('ws://13.233.131.192:1883');
  //     client.on('connect', () => {
  //       client.subscribe(topic, (err) => {
  //         // eslint-disable-next-line no-console
  //         console.log(err, '>>>>>>>>>>>>>>>>>');
  //       });
  //     });
  //     client.on('message', (c, message) => {
  //       setUploadProgress(message.toString());
  //     });
  //     if (Math.round(uploadProgress) === 100) {
  //       client.on('disconnect', () => {
  //         client.disconnect();
  //       });
  //     }
  //   }
  // }, [topic]);

  function functionToSaveVideo(file, titleInfo, text, created, videoId) {
    if (!file || !titleInfo) {
      alert.warning('Enter Video_Title and Upload Video');
      return;
    }
    // const toppic = Math.round(Math.random()) + moduleIdno + 201 + Math.round(Math.random());
    const formData = new FormData();
    // setTopic(toppic);

    if (!created) {
      formData.append('treasure_module', moduleIdno);
    }
    if (created) {
      formData.append('id', videoId);
    }
    if (typeof (file) === 'object') {
      formData.append('file', file);
      // formData.append('topic', toppic);
    }
    formData.append('title', titleInfo);
    formData.append('text', text);

    setLoading(true);
    fetch(urls.creatingVideoForEachModuleApi, {
      method: created ? 'PUT' : 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Treasure_Box')
      },
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          functionToGetVideos(moduleIdno);
          alert.success('Video Successfully Created');
          return res.json();
        }
        if (res.status !== 200 && res.status !== 201) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      });
  }

  function functionToCreateModule() {
    if (!title) {
      alert.warning('Enter Title');
      return;
    }

    let data;
    if (moduleIdno) {
      data = {
        title,
        id: moduleIdno,
      };
    } else {
      data = {
        title,
      };
    }

    setLoading(true);
    fetch(urls.creatingModuleApi, {
      method: EditModule ? 'PUT' : 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Treasure_Box')
      },
    })
      .then((res) => {
        if (res.status === 302) {
          setLoading(false);
          alert.warning('Module name already exist');
        }
        if (res.status === 201 || res.status === 200) {
          setLoading(false);
          alert.success('Successfully Created');
          return res.json();
        }
        if (res.status !== 200 && res.status !== 201 && res.status !== 302) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((Info) => {
        setmodelCretatingResponse(Info);
      });
  }

  const handleCloseView = () => {
    setOpenView(false);
  };

  function functionToOpenViewModel(id) {
    setVideoData([]);
    functionToGetVideos(id);
    setOpenView(true);
  }

  const handleCloseFullView = () => {
    setFullView(false);
    seFullViewData({ title: null, video: null, text: null });
  };

  function functionToOpenFullModelView(titleData, videoInfo, textData) {
    setFullView(true);
    seFullViewData({ title: titleData, video: videoInfo, text: textData });
  }

  function functionForFullViewVodeoModule() {
    let FullView = null;
    FullView = (
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
        {permission.can_view ?  
          <Dialog
            maxWidth="xl"
            fullWidth
            className={classes.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={openFullView}
          >
            <DialogTitle id="alert-dialog-title" onClose={handleCloseFullView}>
              {FullViewData.title || ''}
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={2} />
                <Grid item md={8} xs={12}>
                  <Box border={1} className={classes.fullViewBox}>
                    <Grid item md={12} xs={12}>
                      <video controlsList="nodownload" controls src={FullViewData.video} height="450" width="100%">
                        <track src={FullViewData.video} kind="captions" srcLang="en" label="english_captions" />
                      </video>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography varinat="h4" className={classes.TextTitle}>About Video</Typography>
                    </Grid>
                    <Divider className={classes.dividerD} />
                    <Grid item md={12} xs={12}>
                      <Typography style={{ color: 'black', padding: '25px' }}>{ReactHtmlParser(FullViewData.text)}</Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
           :null}
        </Grid>
      </Grid>
    );
    return FullView;
  }

  function functionTOOpenView() {
    let view = null;
    view = (
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {permission.can_view ?           
          <Dialog
            maxWidth="xl"
            fullWidth
            className={classes.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={openView}
          >
            <DialogTitle id="alert-dialog-title" onClose={handleCloseView}>
              View Videos
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              {videoData && videoData.length === 0 && (<Typography varinat="h1" className={classes.typText}> No Videos are added</Typography>) }
              <Grid container spacing={2}>
                {videoData && videoData.length !== 0 && videoData.map((data) => (
                  <Grid item md={4} xs={12} key={data.id + Math.round((Math.random() * 10) + 1)}>
                    <Box border={1} className={classes.contentBox}>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <video controlsList="nodownload" controls src={data.file && data.file} height="150" width="200">
                            <track src={data.file && data.file} kind="captions" srcLang="en" label="english_captions" />
                          </video>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography variant="h6" className={classes.typographyy}>{data.title}</Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Button className={classes.buttonview} onClick={() => functionToOpenFullModelView(data.title, data.file, data.text)}>Full View</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          </Dialog>
          :null}
        </Grid> 
      </Grid>
    );
    return view;
  }

  function handleVideoValidation(data, index, file) {
    if (data && data.type === 'video/mp4') {
      functionToHandleVideoData(data, index, file);
    } else {
      alert.warning('Upload  Video mp4 format Only');
    }
  }

  function functionToOpenModule() {
    let model = null;
    model = (
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Dialog
            maxWidth="xl"
            fullWidth
            className={classes.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={open}
            disableEnforceFocus
          >
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
              {(EditModule && 'Edit Module') || ' Create Module'}
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={10} xs={12}>
                  <TextField
                    className={classes.textField}
                    label="Module Title"
                    margin="dense"
                    required
                    value={title || ''}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12} className={classes.button}>
                  <Button variant="contained" color="primary" onClick={() => functionToCreateModule()}>
                    {(EditModule && 'Save Module') || ' Create Module'}
                  </Button>
                </Grid>
              </Grid>
              {moduleIdno && (
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Videos List</Typography>
                </Grid>
                <Grid item md={7} />
                <Grid item md={2} xs={12}>
                  <Button onClick={() => functionToAddVideo()} variant="contained" color="primary">
                    Add Video
                    <AddIcon />
                  </Button>
                </Grid>
              </Grid>
              )}
              <Divider className={classes.dividerD} />
              <Grid container spacing={2}>
                {videoData && videoData.length === 0
                && (
                <Grid item md={12} xs={12} style={{ color: 'blue', textAlign: 'center' }}>
                  <Typography variant="h5">
                    {' '}
                    No Videos Are Add in This Model Please Add Videos by Clicking &nbsp;
                    <u>Add Video +</u>
                    &nbsp;
                    Button
                  </Typography>
                </Grid>
                )}
                {videoData && videoData.length !== 0 && videoData.map((data, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item md={12} xs={12} key={index}>
                    <Box border={1} className={classes.contentBox}>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                          <TextField
                            className={classes.textField}
                            label="video Title"
                            margin="dense"
                            required
                            value={data.title || ''}
                            onChange={(e) => functionToHandleVideoData(e.target.value, index, 'title')}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <label htmlFor={index}>
                          {permission.can_import ? 

                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className={classes.button}
                              startIcon={<CloudUploadIcon />}
                            >
                              Browse
                            </Button>
                            :null}
                          </label>
                          <input
                            style={{ display: 'none' }}
                            className={classes.button}
                            id={index}
                            type="file"
                            onChange={(e) => handleVideoValidation(e.target.files[0], index, 'file')}
                          />
                          &nbsp;&nbsp;
                          {data && data.file && typeof (data.file) === 'object'
                            && <span style={{ color: 'black' }}>{data.file && data.file.name}</span>}
                        </Grid>
                        <Grid item md={5} xs={12}>
                          {data && data.file && typeof (data.file) === 'string'
                            && (
                            <video controlsList="nodownload" controls src={data.file && data.file} height="150" width="200">
                              <track src={data.file && data.file} kind="captions" srcLang="en" label="english_captions" />
                            </video>
                            )}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography style={{ color: 'black', float: 'left' }}>Enter Video description : </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TinyTextEditor
                            className={classes.textField}
                            id={`treasureBoxIdNo${index}`}
                            initialValue={data.text || ''}
                            value={data.text || ''}
                            onChange={(content) => functionToHandleVideoData(content, index, 'text')}
                          />
                        </Grid>
                        <Grid item md={10} xs={1} />
                        <Grid item md={1} xs={5}>
                          <Button
                            className={classes.button}
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() => functionToSaveVideo(data.file, data.title, data.text, data.created_by, data.id)}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item md={1} xs={5}>
                          <Button fullWidth variant="contained" className={classes.button} onClick={() => functionToDeleteVideo(index, data.id, data.created_by)} color="secondary"> Delete</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    );
    return model;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography variant="h4">Treasure Box</Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="h6" className={classes.typographyy}>
            Total Videos:
            {allVidoesCount ? allVidoesCount.total_videos : 0}
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="h6" className={classes.typographyy}>
            Total views :
            {allVidoesCount ? allVidoesCount.total_videos_viewed : 0}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        {/* <Grid item md={4} xs={12}>
          <Typography>
            {' '}
            Select Category
            <b style={{ color: 'red' }}>*</b>
          </Typography>
          <Select
            margin="dense"
            fullWidth
            value={categoryId || ''}
            onChange={(e) => setCategoryId(e.target.value)}
            className={classes.textField}
            variant="outlined"
            style={{ color: 'black' }}
          >
            {categoryList
            && categoryList.length !== 0
            && categoryList.map((data) => (
              <MenuItem
                value={data.id}
                key={data.id}
                name={data.title}
              >
                {data.title ? data.title : ''}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button variant="contained" color="primary" style={{ marginTop: '25px' }}>Get</Button>
        </Grid> */}
        <Grid item md={12} xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              color="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {StatusArray
                    && StatusArray.map((statusData) => (
                      <Tab
                        key={statusData.statusId}
                        style={{ marginRight: '4%', marginLeft: '4%' }}
                        label={statusData.statusName}
                      />
                    ))}
            </Tabs>
          </AppBar>
        </Grid>
        {allModules && allModules.results.length === 0 && (<Grid container><Grid item md={12} xs={12}><Typography varinat="h1" className={classes.typText}> No Modules are added</Typography></Grid></Grid>) }
        {allModules && allModules.results && allModules.results.length !== 0 && allModules.results.map((item) => (
          <Grid item md={3} xs={12} key={item.id}>
            <Box border={1} className={classes.contentBox}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6" className={classes.typographyy}>
                    {(item && item.title && item.title) || ''}
                  </Typography>
                  <Typography variant="overline">
                    {item.viewed_users ? item.viewed_users : 0}
                    {' '}
                    {item.viewed_users && item.viewed_users === 1 ? 'view' : 'views' }
                  </Typography>
                </Grid>
                <Grid item md={4} xs={3}>
                  <IconButton size="medium" onClick={() => functionToOpenViewModel(item.id)}><VisibilityIcon /></IconButton>
                </Grid>
                <Grid item md={4} xs={3}>
                  <IconButton size="medium" onClick={() => functionToEditModule(item.id, item.title)}><EditIcon /></IconButton>
                </Grid>
                <Grid item md={4} xs={3}>
                  {/* <IconButton size="medium" onClick={() => functionToActiveInactive(item.id, item.is_active)}> */}
                  {item.is_active ? (
                    <Switch
                      checked
                      onClick={() => functionToHandleActiveStatus(item.id, item.is_active)}
                      color="secondary"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                  ) : (
                    <Switch
                      checked={false}
                      // onChange
                      onClick={() => functionToHandleActiveStatus(item.id, item.is_active)}
                      color="primary"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                  ) }
                  {/* </IconButton> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
        {allModules && allModules.results.length !== 0 && (
        <Grid item md={12} xs={12}>
          <Paper
            style={{ backgroundColor: 'lightgray', marginTop: '10px' }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() => `Page ${page + 1} of ${+allModules.total_pages}`}
                    rowsPerPageOptions={[8, 20, 30]}
                    count={+allModules.count}
                    rowsPerPage={rowsPerPage || 8}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: '13px' }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(allModules.total_pages - 1)}
                      disabled={page === +allModules.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} />
        <Grid item md={4} xs={12}>
          <Box className={classes.appBar}>
            <Grid container spacing={2}>
              <Grid item md={8} xs={8}>
                <Typography variant="h5" className={classes.typography}>
                  Start Creating Module
                </Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <Button color="primary" variant="contained" onClick={() => setOpen(true)}>Start</Button>
              </Grid>
            </Grid>
          </Box>
          <Grid item md={12} xs={12} style={{ display: 'none' }}>
            <TinyTextEditor
              id="dummy"
              className={classes.textField}
            />
          </Grid>
        </Grid>
      </Grid>
      {functionToOpenModule()}
      {functionTOOpenView()}
      {functionForFullViewVodeoModule()}
      {loader}
    </>
  );
};
TreasureBox.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(TreasureBox);
