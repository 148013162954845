/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  // Modal,
  // Fade,
  // Backdrop,
  // IconButton,
  TextField,
  // TableFooter,
  // TablePagination,
  MenuItem,
  Select,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  // Input,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './issues.style';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import axios from 'axios';

const Issues = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // const [level, setLevel] = useState('');
  const [checkLoader, setCheckLoader] = useState(false);
  // const [levelUpdate, setLevelUpdate] = useState('');
  // const [levelId, setlevelId] = useState('');
  // const [open, setOpen] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [branch, setBranch] = useState('');
  // const [branchList, setBranchList] = useState([
  //   {
  //     'id': 1,
  //     'branch_name':'ERP'
  //   },
  //   {
  //     'id': 2,
  //     'branch_name':'Branch'
  //   },
  //   {
  //     'id': 3,
  //     'branch_name':'Module 5'
  //   }
  // ]);
  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);
  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRole,
  } = useFetch(null);
  const {
    data: userInfo,
    isLoading: userInfoLoading,
    doFetch: fetchUserDetails,
  } = useFetch(null);

 const {
   data: modulePermission,
   isLoading: modulePermissionLoading,
   doFetch: fetchModulePermission
 } = useFetch(null)


  const [issue, setIssue] = useState(null);
  const [email, setEmail] = useState('');
  const [erp, setErp] = useState('');
  const [newErp, setNewErp] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const alert = useAlert();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState('');
  const [confirmPassword, setConfrmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [newRole, setNewRole] = useState(0);
  const [permission, setPermission] = useState([])
  const [getDetailsOptionSelected, setGetDetailsOptionSelected] = useState(0);
  const [getDetailsOptions] = useState([
    {
      id: 1,
      name: 'Email',
    },
    {
      id: 2,
      name: 'Phone Number',
    },
  ]);
  const [issueList] = useState([
    {
      id: 1,
      name: 'Update ERP',
    },
    {
      id: 2,
      name: 'Update Branch',
    },
    {
      id: 3,
      name: 'Module 5 Issue',
    },
    {
      id: 4,
      name: 'Reset Password',
    },
    {
      id: 5,
      name: 'Update Role',
    },
    {
      id: 6,
      name: 'Get Details',
    },
  ]);
  let loader = null;
  if (checkLoader || branchListLoading || roleListLoading || userInfoLoading || modulePermissionLoading ) {
    loader = <Loader open />;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const issueChanged = (chosenIssue) => {
    setEmail('');
    setErp('');
    setNewErp('');
    setPhoneNo('');
    setBranch('');
    setIssue(chosenIssue);
    if (chosenIssue === 2) {
      fetchBranches({
        url: urls.consolidatedReportBranchApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Issues')
        },
      });
    }
    if (chosenIssue === 5) {
      fetchRole({
        url: urls.getCourseSettingRolesApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          module: localStorage.getItem('Issues')
        },
      });
    }
  };

  const handleErrors = (response) => {
    if (response.status !== 200) {
      if (response.status === 409) {
        alert.error(`A user already exists with ERP ${newErp}`);
        return response;
      }
      if (response.status === 404) {
        alert.error(`No user found for ERP ${erp}. Kindly register.`);
        return response;
      }
      if (response.status === 400) {
        alert.error(`Error: ${response.message}`);
        return response;
      }
      alert.error(`Error ${response.status} : ${response.message}`);
    }
    return response;
  };

  const handleErrorsForModule5 = (response) => {
    if (response.status !== 200) {
      // if (response.status === 409) {
      //   alert.error(`A user already exists with ERP ${newErp}`);
      //   return response;
      // }
      // if (response.status === 404) {
      //   alert.error(`No user found for ERP ${erp}. Kindly register.`);
      //   return response;
      // }
      // if (response.status === 400) {
      //   alert.error(`Error: ${response.message}`);
      //   return response;
      // }
      alert.error(`Error: ${response.message}`);
    }
    return response;
  };

  async function classicFetch(url) {
    setCheckLoader(true);
    const response = await fetch(url, {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Issues')
      },
    });
    const res = await response.json();
    return res;
  }

  const resetPassword = () => {
    const resetPasswordURL = `${urls.resetPasswordAPI}?erp=${erp}&email=${email}&password=${password}&mobile=${phoneNo}`;
    classicFetch(resetPasswordURL)
      .then(handleErrors)
      .then((res) => {
        setCheckLoader(false);
        if (res.status === 200) {
          alert.success(
            `Password updated successfully for user ${erp}`,
          );
        }
        // eslint-disable-next-line no-console
        console.log(res);
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.warning(`Error:${error}`);
      });
  };

  const branchUpdate = () => {
    if (!email) {
      alert.warning('Enter a valid Email');
      return;
    }
    if (!erp) {
      alert.warning('Enter a valid ERP');
      return;
    }
    if (!branch || branch.length === 0) {
      alert.warning('Enter a valid ERP');
      return;
    }

    const branchUpdaterURL = `${urls.updateUsersBranchAPI}?branch_ids=${[
      branch,
    ]}&email=${email}&erp=${erp}`;
    classicFetch(branchUpdaterURL)
      .then(handleErrors)
      .then((res) => {
        setCheckLoader(false);
        if (res.status === 200) {
          alert.success(`Branch updated for user ${erp}`);
        }
        // eslint-disable-next-line no-console
        console.log(res);
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.warning(`Error:${error}`);
      });
  };

  const runModule5 = () => {
    if (!erp) {
      alert.warning('Enter a valid ERP');
      return;
    }

    const runModule5URL = `${urls.runModule5API}?erp=${erp}`;
    classicFetch(runModule5URL)
      .then(handleErrorsForModule5)
      .then((res) => {
        setCheckLoader(false);
        if (res.status === 200) {
          alert.success(`Module 5 has been resolved for user ${erp}`);
        }
        // eslint-disable-next-line no-console
        console.log(res);
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.warning(`Error:${error}`);
      });
  };

  const erpUpdate = () => {
    if (!email) {
      alert.warning('Enter a valid Email');
      return;
    }

    if (!phoneNo) {
      alert.warning('Enter a valid Email');
      return;
    }

    if (!erp || !newErp) {
      alert.warning('Enter a valid ERP');
      return;
    }

    if (erp === newErp) {
      alert.warning('Both ERPs can not be same');
      return;
    }

    // adithya@orchids.edu.in
    // Mobile Number 7875651232
    // Erp Number 20130999090
    // Erp Number 20190428657
    // 20190428652

    // password qweDSA123

    const erpUpdateURL = `${urls.erpUpdateAPI}?email=${email}&mobile=${phoneNo}&old_erp=${erp}&new_erp=${newErp}`;
    async function loading(url) {
      setCheckLoader(true);
      const response = await fetch(url, {
        method: 'PUT',

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Issues')
        },
      });
      const res = await response.json();
      return res;
    }
    loading(erpUpdateURL)
      .then(handleErrors)
      .then((res) => {
        setCheckLoader(false);
        if (res.status === 200) {
          alert.success(`Your erp has been changed to ${newErp}.`);
        }
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.error(`Error:${error}`);
      });
  };

  const roleUpdate = () => {
    if (!erp || erp.length < 11) {
      alert.warning('Please enter ERP No');
      return;
    }

    if (!email) {
      alert.warning('Enter a valid Email');
      return;
    }

    if (!newRole) {
      alert.warning('Please select a New Role');
      return;
    }

    const roleUpdateURL = `${urls.roleUpdateAPI}?erp=${erp}&email=${email}&newrole_id=${newRole}`;
    async function loading(url) {
      setCheckLoader(true);
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Issues')
        },
      });
      const res = await response.json();
      return res;
    }
    loading(roleUpdateURL)
      .then(handleErrors)
      .then((res) => {
        setCheckLoader(false);
        if (res.status === 200) {
          alert.success(`${res.message}`);
        }
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.error(`Error:${error}`);
      });
  };

  function getPermissonIssues() {
    const auth = JSON.parse(localStorage.getItem('UserLogin'));
    axios.get(urls.getPermissons, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Issues')
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
      localStorage.setItem("issues_can_update", false )
    })
  }

  useEffect(() => {
    getPermissonIssues();
  }, [])

  const getDetails = () => {
    let getDetailsByEmailOrPhoneURL;
    if (getDetailsOptionSelected === 1) {
      getDetailsByEmailOrPhoneURL = `${urls.getDetailsByEmailOrPhoneAPI}?email=${email}`;
    } else if (getDetailsOptionSelected === 2) {
      getDetailsByEmailOrPhoneURL = `${urls.getDetailsByEmailOrPhoneAPI}?phone=${phoneNo}`;
    }
    fetchUserDetails({
      url: getDetailsByEmailOrPhoneURL,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Issues')
      },
    });
  };

  const btnDisable = () => {
    if (issue === 5) {
      if (newRole === 0 || newRole === null) {
        return true;
      }
      return (erp.length < 11) || (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email));
    }
    if (issue === 6) {
      if (getDetailsOptionSelected === 0) {
        return true;
      }
      if (getDetailsOptionSelected === 1) {
        return !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email);
      }
      if (getDetailsOptionSelected === 2) {
        return phoneNo.length < 10;
      }
    }
    return false;
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Issues</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.root}>
              {/* <Grid item md={4} xs={12}/> */}

              <Grid item md={4} xs={12}>
                <Typography>Choose your issue</Typography>

                <Select
                  margin="dense"
                  fullWidth
                  value={issue || ''}
                  onChange={(e) => {
                    issueChanged(e.target.value);
                  }}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {issueList
                    && issueList.length !== 0
                    && issueList.map((data) => (
                      <MenuItem
                        value={data.id}
                        key={data.id}
                        name={data.name}
                      >
                        {data.id ? data.name : ''}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item md={4} xs={12} />
            </Grid>
          </Paper>
        </div>
      </div>
      <>
        {issue && issue === 1 && (
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.root}>
            <Grid item md={6} xs={12}>
              <TextField
                label="Email"
                margin="dense"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />
              {email
                && !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
              {email
                && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Phone No"
                type="number"
                margin="dense"
                fullWidth
                required
                value={phoneNo}
                // eslint-disable-next-line max-len
                onChange={(e) => setPhoneNo(
                  Math.max(
                    0,
                    parseInt(e.target.value, 10),
                  )
                    .toString()
                    .slice(0, 10),
                )}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Old ERP No"
                margin="dense"
                fullWidth
                required
                value={erp}
                onChange={(e) => e.target.value.length < 12
                                        && setErp(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="New ERP No"
                margin="dense"
                fullWidth
                required
                value={newErp}
                onChange={(e) => e.target.value.length < 12
                                        && setNewErp(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {modulePermission && modulePermission.response.can_update ?
            <Grid item md={4} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={erpUpdate}
              >
                Update
              </Button>
            </Grid>:null}
          </Grid>
        </Paper>
        )}

        {issue && issue === 2 && (
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.root}>
            <Grid item md={6} xs={12}>
              <TextField
                label="ERP No"
                margin="dense"
                fullWidth
                required
                value={erp}
                onChange={(e) => e.target.value.length < 12
                                        && setErp(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Email"
                margin="dense"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />
              {email
                && !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
              {email
                && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>Choose Branches</Typography>
              <Select
                margin="dense"
                fullWidth
                multiple
                value={branch || []}
                onChange={(e) => setBranch(e.target.value)}
                className={classes.textField}
                variant="outlined"
                style={{ color: 'black' }}
              >
                {branchList
                  && branchList.length !== 0
                  && branchList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {data.branch_name
                        ? data.branch_name
                        : ''}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              {permission.can_update ?
              <Button
                style={{ marginTop: '25px' }}
                variant="contained"
                color="primary"
                onClick={branchUpdate}
              >
                Update
              </Button>: null}
            </Grid>
          </Grid>
        </Paper>
        )}
        {issue && issue === 3 && (
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.root}>
            <Grid item md={4} xs={12}>
              <TextField
                label="ERP No"
                margin="dense"
                fullWidth
                required
                value={erp}
                onChange={(e) => e.target.value.length < 12
                                        && setErp(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                style={{ marginTop: '10px' }}
                variant="contained"
                color="primary"
                onClick={runModule5}
              >
                Go
              </Button>
            </Grid>
          </Grid>
        </Paper>
        )}
        {issue && issue === 4 && (
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.root}>
            <Grid item md={4} xs={12}>
              <TextField
                label="Email"
                margin="dense"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />
              {email
                && !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
              {email
                && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Phone No"
                type="number"
                margin="dense"
                fullWidth
                required
                value={phoneNo}
                // eslint-disable-next-line max-len
                onChange={(e) => setPhoneNo(
                  Math.max(
                    0,
                    parseInt(e.target.value, 10),
                  )
                    .toString()
                    .slice(0, 10),
                )}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="ERP"
                margin="dense"
                fullWidth
                required
                value={erp}
                onChange={(e) => e.target.value.length < 12
                                        && setErp(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="New Password"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Confirm Password"
                margin="normal"
                type={showPassword1 ? 'text' : 'password'}
                required
                fullWidth
                value={confirmPassword || ''}
                onChange={(e) => setConfrmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCPassword}
                        onMouseDown={handleMouseDownCPassword}
                      >
                        {showPassword1 ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {password
                && confirmPassword
                && confirmPassword !== password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Passwords do not match
                  </FormHelperText>
                ) : null}
              {password
                && confirmPassword
                && confirmPassword === password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Passwords match
                  </FormHelperText>
                ) : null}
            </Grid>
            <Grid item md={4} xs={12} />
            <Grid item md={4} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={resetPassword}
                disabled={
                  !(
                    password
                      && confirmPassword
                      && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                        email,
                      )
                      && email
                      && phoneNo
                      && erp
                      && confirmPassword === password
                  )
                }
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Paper>
        )}
        {issue && issue === 5 && (
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.root}>
            <Grid item md={6} xs={12}>
              <TextField
                label="ERP No"
                margin="dense"
                fullWidth
                required
                value={erp}
                onChange={(e) => e.target.value.length < 12
                                        && setErp(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Email"
                margin="dense"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />
              {email
                && !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
              {email
                && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email,
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                margin="dense"
                variant="outlined"
                className={classes.textField}
                fullWidth
              >
                <InputLabel
                  id="demo-controlled-open-select-label"
                  style={{ color: 'black' }}
                >
                  Select New Role
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Select New Role"
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value)}
                  name="role_category"
                  style={{ color: 'black' }}
                >
                  <MenuItem value={0} key={0} disabled>
                    Select Role
                  </MenuItem>
                  {roleList
                    && roleList.length !== 0
                    && roleList.map((dropItem) => (
                      <MenuItem
                        value={dropItem.id}
                        key={dropItem.id}
                        name={dropItem.is_academic}
                      >
                        {dropItem.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              {permission && permission.can_update ?
              <Button
                style={{ marginTop: '10px' }}
                variant="contained"
                color="primary"
                onClick={roleUpdate}
                disabled={btnDisable()}
              >
                Update
              </Button>:null}
            </Grid>
          </Grid>
        </Paper>
        )}
        {issue && issue === 6 && (
          <>
            <Paper className={classes.paper}>
              <Grid container spacing={3} className={classes.root}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    margin="dense"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                  >
                    <InputLabel
                      id="demo-controlled-open-select-label"
                      style={{ color: 'black' }}
                    >
                      Select Email or Phone Number
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      label="Select Email or Phone Number"
                      value={getDetailsOptionSelected}
                      onChange={(e) => setGetDetailsOptionSelected(e.target.value)}
                      name="role_category"
                      style={{ color: 'black' }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Select Email or Phone Number
                      </MenuItem>
                      {getDetailsOptions
                        && getDetailsOptions.length !== 0
                        && getDetailsOptions.map((dropItem) => (
                          <MenuItem
                            value={dropItem.id}
                            key={dropItem.id}
                            name={dropItem.is_academic}
                          >
                            {dropItem.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item md={6} xs={12} />
                <Grid item md={6} xs={12}>
                  {getDetailsOptionSelected === 0 ? (
                    <>
                      <TextField
                        label="Enter Email/PhoneNo"
                        margin="dense"
                        fullWidth
                        disabled
                        defaultValue="Select one of the options above"
                        variant="outlined"
                      />
                    </>
                  ) : null}
                  {getDetailsOptionSelected === 1 ? (
                    <>
                      <TextField
                        label="Email"
                        margin="dense"
                        type="email"
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                      />
                      {email
                        && !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                          email,
                        ) ? (
                          <FormHelperText
                            id="component-error-text"
                            style={{ color: 'red' }}
                          >
                            Invalid Email Address
                          </FormHelperText>
                        ) : null}
                      {email
                        && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                          email,
                        ) ? (
                          <FormHelperText
                            id="component-error-text"
                            style={{ color: 'green' }}
                          >
                            Valid Email Address
                          </FormHelperText>
                        ) : null}
                    </>
                  ) : null}
                  {getDetailsOptionSelected === 2 ? (
                    <>
                      <TextField
                        label="Phone No"
                        type="number"
                        margin="dense"
                        fullWidth
                        required
                        value={phoneNo}
                          // eslint-disable-next-line max-len
                        onChange={(e) => setPhoneNo(
                          Math.max(0, parseInt(e.target.value, 10))
                            .toString()
                            .slice(0, 10),
                        )}
                        variant="outlined"
                      />
                    </>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button
                    style={{ marginTop: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={getDetails}
                    disabled={btnDisable()}
                  >
                    Get Details
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <>
              <br />
              {
                userInfo && userInfo.length !== 0
                  && (
                  <Paper className={classes.paper}>
                    <Table
                      className={classes.table}
                      aria-label="spanning table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }} align="left">
                            ERP
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} align="left">
                            Name
                            {' '}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} align="left">
                            Phone Number
                            {' '}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} align="left">
                            Email Id
                            {' '}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} align="left">
                            Role
                            {' '}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} align="left">
                            Address
                            {' '}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userInfo.data.map((info) => (
                          <TableRow>
                            <TableCell align="left">
                              {info.erp}
                            </TableCell>
                            <TableCell align="left">
                              {info.user.first_name}
                              {' '}
                              {' '}
                              {' '}
                              {info.user.last_name}
                            </TableCell>
                            <TableCell align="left">
                              {info.phone_number}
                            </TableCell>
                            <TableCell align="left">
                              {info.user.email}
                            </TableCell>
                            <TableCell align="left">
                              {info.role_category.name}
                            </TableCell>
                            <TableCell align="left">
                              {info.address || '---'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                  )
              }
            </>
          </>
        )}
      </>
      <div />
      {loader}
    </>
  );
};

Issues.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Issues);
