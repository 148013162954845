/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './learningVideos.style';
import { useAlert } from '../../../hoc/alert/alert';
import TinyTextEditor from '../../../hoc/tinyMce/tinyTextEditor';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';
import LearningModel from './learningMode';
import axios from 'axios';


const LearningVideos = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [learningList, setLearningList] = useState();
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [learningVideoData, setLearningVideoData] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const alert = useAlert();
  const [permission,setPermission]=useState([]);

  const {
    data: deleteVideoRes,
    isLoding: gettingLoaded,
    doFetch: fetchVideoDelete,
  } = useFetch(null);

  const {
    data: rolesList,
    isLoding: gettingRolesList,
    doFetch: fetchRolesList,
  } = useFetch([]);

  const {
    data: branchList,
    isLoding: gettingBranchList,
    doFetch: fetchBranchList,
  } = useFetch([]);

  function getData() {
    setLoading(true);
    fetch(`${urls.learningVideosApi}?page_size=${rowsPerPage || 10}&page=${page + 1}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Learnig_Videos')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Data Received');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      }).then((data) => {
        setLearningList(data);
      });
  }

  function handleEditOpenLearningModel(data) {
    const roleArray = [];
    const branchArr = [];
    if (data.role && data.role.length.length !== 0) {
      for (let i = 0; i < data.role.length; i += 1) {
        roleArray.push(data.role[i].id);
      }
    }
    if (data.branches && data.branches.length !== 0) {
      for (let i = 0; i < data.branches.length; i += 1) {
        branchArr.push(data.branches[i].id);
      }
    }
    setLearningVideoData({
      idNo: data.id,
      title: data.title,
      videoFile: data.video,
      videoDesc: data.text,
      branch: branchArr || [],
      role: roleArray || [],
      all_branches: data.all_branches
    });
    setEdit(true);
    setOpen(true);
  }

  const handleClose = (data) => {
    setOpen(false);
    setEdit(false);
    setLearningVideoData({
      idNo: '', title: '', videoFile: '', videoDesc: '', branch: [], role: [],
    });
    if (data === 'success') {
      getData();
    }
  };

  function handleOpenAddLearnignVideos() {
    setOpen(true);
    setEdit(false);
    setLearningVideoData({
      idNo: '', title: '', videoFile: '', videoDesc: '', branch: [], role: [],
    });
  }

  let loader = null;
  if (Loading || gettingLoaded || gettingRolesList || gettingBranchList) {
    loader = <Loader open />;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth) {
      fetchRolesList({
        url: `${urls.getCourseSettingRolesApi}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Learnig_Videos')
        },
      });
      fetchBranchList({
        url: `${urls.getBranchNames}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Learnig_Videos')
        },
      });
    }
  }, [auth.personal_info.token]);

  useEffect(() => {
    if (auth) {
      getData();
    }
  }, [auth.personal_info.token, page, rowsPerPage, deleteVideoRes]);

   // For Permissions
   function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Learnig_Videos'))
  }, [])


  function handleDeleteLearningVideo(id) {
    fetchVideoDelete({
      url: `${urls.learningVideosUpdateApi}${id}/create_learning_module/`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Learnig_Videos')
      },
    });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={6}>
          {permission.can_view ?
          <Typography variant="h4">Learning Videos</Typography>
          :null}    
        </Grid>
        <Grid item md={6} xs={6}>
          {permission.can_add ? 
          <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={() => handleOpenAddLearnignVideos()}>Add Learning Video +</Button>
          :null}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        {learningList && learningList.results && learningList.results.length === 0
        && (
        <Grid item md={12} xs={12}>
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}> No learning videos are added please Add learning videos while clicking Add Learning Video + button</Typography>
        </Grid>
        )}
        {learningList && learningList.results && learningList.results.length !== 0
        && (
        <>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              {learningList && learningList.results && learningList.results.length > 0
            && learningList.results.map((item) => (
              <Grid item md={3} xs={12} key={item.id}>
                <Grid container>
                  <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <video src={item.video} width="100%" height="200" controls controlsList="nodownload" className={classes.videoLoad}>
                          <source src={item.video} type="video/mp4" height="100" width="100" />
                          <source src={item.video} type="video/ogg" />
                          <track src={item.video} kind="captions" srcLang="en" label="english_captions" />
                        </video>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography style={{ color: 'blue', textAlign: 'center' }}>
                          {item.title || ''}
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12} className={classes.firstHead}>
                        <Typography title={item.role && item.role !== 0 && item.role.map((info) => (info.name))}>
                          Role:
                          {item.role && item.role !== 0 && item.role.map((info) => (
                            <span>
                              &nbsp;
                              {info.name}
                              ,&nbsp;
                            </span>
                          ))}
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12} className={classes.firstHead}>
                        <Typography title={item.branches && item.branches !== 0 && item.branches.map((info) => (info.branch_name))}>
                          Branch:
                          {item.branches && item.branches !== 0 && item.branches.map((info) => (
                            <span>
                              &nbsp;
                              {info.branch_name}
                              ,&nbsp;
                            </span>
                          ))}
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Button color="primary" fullWidth variant="outlined" onClick={() => handleEditOpenLearningModel(item)}>Edit</Button>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Button color="primary" fullWidth variant="outlined" onClick={() => handleDeleteLearningVideo(item.id)}>Delete</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            ))}
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TablePagination
                      colSpan={6}
                      labelDisplayedRows={() => `Page ${page + 1} of ${+learningList.total_pages}`}
                      rowsPerPageOptions={[5, 10, 20, 30]}
                      count={+learningList.count}
                      rowsPerPage={rowsPerPage || 10}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'Rows per page' },
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableCell style={{ marginTop: '13px' }}>
                      <IconButton
                        onClick={firstPageChange}
                        disabled={page === 0 || page === 1}
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => lastPageChange(learningList.total_pages - 1)}
                        disabled={page === +learningList.total_pages - 1}
                      >
                        <LastPageIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </>
        )}
      </Grid>
      <div style={{ display: 'none' }}>
        <TinyTextEditor
          id="testDataId"
        />
      </div>
      <LearningModel
        open={open}
        edit={edit}
        handleClose={handleClose}
        learningVideoData={learningVideoData}
        roleList={rolesList}
        branchList={branchList}
      />
      {loader}
    </>
  );
};
LearningVideos.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(LearningVideos);
