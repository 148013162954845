/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from '../../hoc/alert/alert';
import urls from '../../url';
import Loader from '../../hoc/loader';

const useStyles = makeStyles(() => ({
  profileStyle: {
    textAlign: 'left',
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: '0.7rem',
    fontSize: '1rem',
    display: 'block',
    width: '100%',
  },
  inputStyle: {
    textAlign: 'left',
    margin: '0.4rem 5%',
    borderRadius: '5px',
    padding: '0.5rem',
    fontWeight: 'bold',
    display: 'block',
    boxSizing: 'border-box',
    width: '90%',
  },

  labelStyle: {
    marginBottom: '5px',
  },
  saveBtn: {
    padding: '0.8rem',
    margiop: '10px',
    background: 'linear-gradient(to right,#bc4e9c ,#f80759)',
    color: 'white',
    border: '1px solid white',
    borderRadius: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '1rem',
    cursor: 'pointer',
  },
}));

const profileInfo = {
  username: '',
  email: '',
  phone_number: '',
  address: '',
  category: '',
  role_category: '',
  first_name: '',
};

function EditProfile() {
  const classes = useStyles();
  const [profileData, setProfileData] = useState(profileInfo);
  const [dataLoading, setdataLoading] = useState(false);
  // const PORT_NUM = "http://localhost:8000";
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));

  const alert = useAlert();

  let loader = null;
  if (dataLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    async function getProfileData() {
      setdataLoading(true);
      const response = await fetch(urls.retrieve_user_details, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
      const getData = await response.json();
      setdataLoading(false);
      return getData;
    }

    getProfileData().then((data) => {
      setProfileData({
        ...profileData,
        username: data.user.username,
        first_name: data.user.first_name,
        phone_number: data.phone_number,
        email: data.user.email,
        address: data.address,
        category: data.category.id,
        role_category: data.role_category.id,
      });
    });
  }, []);

  const changeProfileInfo = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  async function postProfileData() {
    const response = await fetch(urls.retrieve_user_details, {
      method: 'PUT', // or 'PUT'
      body: JSON.stringify(profileData), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
    if (response.status === 200) {
      const fetchData = await response.json();
      alert.success('Data Changed Successfully');
      window.location.href = '/profile';
      return fetchData;
    }
    alert.error('Data Not Changed');
    return 0;
  }

  const saveInfoHandle = (e) => {
    e.preventDefault();
    postProfileData().then((data) => {
      // eslint-disable-next-line no-console
      console.log(data);
      // console.log(data.status);
      // data.response === "user created successfully"
      //   ? console.log("Successfully Registered")
      //   : console.log("Already Registered");
    });
  };

  return (
    <>
      {loader}
      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          component="div"
          style={{ backgroundColor: '#fefefe', height: '100vh' }}
        >
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ marginBottom: '0px' }}>Edit Profile</h1>
            <p style={{ marginTop: '0px' }}>Edit Information about Yourself</p>
            <hr />
            <div className={classes.inputStyle}>
              <Typography htmlFor="" className={classes.labelStyle}>
                Username:
              </Typography>
              <input
                disabled
                className={classes.profileStyle}
                type="text"
                name="username"
                id=""
                value={profileData.username}
                onChange={changeProfileInfo}
              />
            </div>
            <div className={classes.inputStyle}>
              <Typography htmlFor="Firstname" className={classes.labelStyle}>
                Firstname:
              </Typography>
              <input
                className={classes.profileStyle}
                type="text"
                name="first_name"
                id=""
                value={profileData.first_name}
                onChange={changeProfileInfo}
              />
            </div>
            <div className={classes.inputStyle}>
              <Typography htmlFor="Mobile" className={classes.labelStyle}>
                Mobile:
              </Typography>
              <input
                className={classes.profileStyle}
                type="number"
                name="phone_number"
                id="Mobile"
                value={profileData.phone_number}
                onChange={changeProfileInfo}
              />
            </div>
            <div className={classes.inputStyle}>
              <Typography htmlFor="Email" className={classes.labelStyle}>
                Email:
              </Typography>
              <input
                className={classes.profileStyle}
                type="email"
                name="email"
                id="Email"
                value={profileData.email}
                onChange={changeProfileInfo}
              />
            </div>
            <div className={classes.inputStyle}>
              <Typography htmlFor="Address" className={classes.labelStyle}>
                Address:
              </Typography>
              <textarea
                className={classes.profileStyle}
                type="text"
                name="address"
                rows="5"
                id="Address"
                value={profileData.address}
                onChange={changeProfileInfo}
              />
            </div>
            <div className={classes.inputStyle}>
              <Button
                type="submit"
                className={classes.saveBtn}
                onClick={saveInfoHandle}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </Typography>
      </Container>
    </>
  );
}

export default EditProfile;
