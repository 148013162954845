import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  TextField,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './addCourseLevel.style';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';

const CourseLevel = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [level, setLevel] = useState('');
  const [checkLoader, setCheckLoader] = useState(false);
  const [levelUpdate, setLevelUpdate] = useState('');
  const [levelId, setlevelId] = useState('');
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const alert = useAlert();
  const {
    data: levelList,
    isLoading: levelListLoading,
    doFetch: fetchlevel,
  } = useFetch([]);
  // console.log(levelList.total_pages);
  // const {
  //   data: UpdateLevelResponse,
  //   isLoading: updateLevelLoading,
  //   doFetch: fetchupdateLevel,
  // } = useFetch([]);
  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)



  useEffect(() => {
    // module=localStorage.getItem('Course_Level')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Level')
      },
    });
  },[])
  useEffect(() => {
    if (page || rowsPerPage) {
      fetchlevel({
        url: `${urls.addlevelApi}?page=${page + 1}&page_size=${rowsPerPage
          || 10}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Course_Level')
        },
      });
    }
  }, [page, fetchlevel, rowsPerPage, auth.personal_info.token]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }
  useEffect(() => {
    fetchlevel({
      url: urls.addlevelApi,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Level')
      },
      fetchlevel,
    });
  }, [fetchlevel, auth.personal_info.token]);
  const updateLevelFunction = () => {
    // if (open) {
    //   if (!levelUpdate) {
    //     alert.warning('Empty Filled , please Filled All Field');
    //     return;
    //   }
    //   fetchupdateLevel({
    //     url: `${urls.updateLevel.replace('param-id-1', levelId)}`,
    //     method: 'PUT',
    //     body: data,
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${auth.personal_info.token}`,
    //     },
    //   });
    // }
    //

    if (!levelUpdate) {
      alert.warning('Enter Course Level');
      return;
    }
    async function getUserAsync() {
      setCheckLoader(true);
      const response = await fetch(`${urls.updateLevel.replace('param-id-1', levelId)}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Course_Level')
        },
        body: JSON.stringify({
          level_id: levelId,
          level_type: levelUpdate,
        }),
      });
      const data = await response.json();
      return data;
    }
    if (open) {
      getUserAsync()
        .then((response) => response)
        .then((data) => {
          setCheckLoader(false);
          if (data.level_type === 'level type already exist') {
            alert.warning('level type already exist');
          } else {
            setCheckLoader(false);
            setOpen(false);
            alert.success('Created Updated');
            fetchlevel({
              url: urls.addlevelApi,
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.personal_info.token}`,
                module: localStorage.getItem('Course_Level')
              },
            });
          }
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const UpdatelevelRow = (i) => {
    setOpen(true);
    setlevelId(levelList.results && levelList.results[i].id);
    setLevelUpdate(
      (levelList.results
        && levelList.results.length
        && levelList.results[i].level_type)
        || '',
    );
  };
  const addCourseLevel = () => {
    if (!level) {
      alert.warning('Select Level First');
      return;
    }
    async function getUserAsync() {
      setCheckLoader(true);
      const response = await fetch(`${urls.addlevelApi}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Course_Level')
        },
        body: JSON.stringify({
          level_type: level,
        }),
      });
      const data = await response.json();
      return data;
    }
    getUserAsync()
      .then((response) => response)
      .then((data) => {
        // console.log(data)
        setCheckLoader(false);
        if (data.level_type === 'level type already exist') {
          alert.warning('level type already exist');
        } else {
          setCheckLoader(false);
          alert.success('Created Successfully');
          fetchlevel({
            url: urls.addlevelApi,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.personal_info.token}`,
              module: localStorage.getItem('Course_Level')
            },
          });
        }
      });
  };
  let loader = null;
  if (levelListLoading || checkLoader || modulePermissionLoading) {
    loader = <Loader open />;
  }
  const levelTableData = () => {
    let levelTable = null;
    if (levelList.results && levelList.results.length) {
      // console.log(levelList.results);
      levelTable = (
        <>
          <TableBody>
            {levelList.results.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="left">{i + 1}</TableCell>
                <TableCell align="left">{data.level_type}</TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ?
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => UpdatelevelRow(i)}
                  >
                    Update
                  </Button>:null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>
      );
    }
    return levelTable;
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Add Course Level</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <TextField
                  className={classes.textField}
                  label="Course Level "
                  margin="dense"
                  required
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {modulePermission &&  modulePermission.response.can_add ?
              <Grid item md={4} xs={12}>
                <Button
                  className={classes.updateButton}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => addCourseLevel()}
                >
                  Add Course Level
                </Button>
              </Grid>:null}
            </Grid>
          </Paper>
        </div>
      </div>
      <>
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }} align="left">
                  S.No
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">
                  Level Name
                  {' '}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">
                  Update level
                  {' '}
                </TableCell>
              </TableRow>
            </TableHead>
            {levelTableData()}
          </Table>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelDisplayedRows={() => `Page ${page + 1} of ${+levelList.total_pages}`}
                  rowsPerPageOptions={[10, 20, 30]}
                  count={+levelList.count}
                  colSpan={3}
                  rowsPerPage={rowsPerPage || 10}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(levelList.total_pages - 1)}
                    disabled={page === +levelList.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <>
                <Typography variant="h5">Update Course Level</Typography>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      label="Course Level"
                      margin="dense"
                      required
                      value={levelUpdate}
                      onChange={(e) => setLevelUpdate(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      className={classes.updateButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateLevelFunction()}
                    >
                      Update level
                    </Button>
                  </Grid>
                </Grid>
              </>
            </div>
          </Fade>
        </Modal>
      </div>
      {loader}
    </>
  );
};

CourseLevel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CourseLevel);
