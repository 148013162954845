export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    overflow: true,
    backgroundColor: 'snow',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
  },
  button: {
    marginTop: '10px',
    marginLeft: '2px',
  },
  backgroundHead: {
    // background: '#ababab',
    margin: '3px',
  },
  textField: {
    width: '100%',
  },
});
