import React, { useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  Paper,
  Button,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import TinyTextEditor from '../../../hoc/tinyMce/tinyTextEditor';
import styles from './inHouseLearning.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import axios from 'axios';


const InHouseLearningModel = ({ classes, history }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const alert = useAlert();
  const [learningList] = useState(JSON.parse(sessionStorage.getItem('learningVideoFullData')));
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [permission,setPermission]=useState([]);


  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  function handleBackPage() {
    window.scrollTo(0, 0);
    sessionStorage.removeItem('learningVideoFullData');
    history.push('/inhouse_Learning_videos');
  }

  function handelSaveNotes() {
    if (!notes) {
      alert.warning('enter some notes');
      return;
    }
    const data = {
      learning_module: learningList.id,
      notes,
    };
    setLoading(true);
    fetch(`${urls.learningNotesApi}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Learning_Notes')
        
      },
    })
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          alert.success('Notes Saved Successfully');
          return res.json();
        }
        if (res.status !== 201) {
          setLoading(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      });
  }
  const inchs = 400;

  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Learning_Notes'))
  }, [])

  return (
    <>
      <div className={classes.LeanrningFullModule}>
        <Typography variant="h4" className={classes.typographys}>{learningList && learningList.title}</Typography>
        <Divider className={classes.divider} />
        <Grid container className={classes.paper2}>
          <Grid item md={7} sm={7} xs={12} style={{ border: '1px solid white' }}>
            <video src={learningList.video?`${urls.s3}${learningList.video.substring(32)}`:""} width="100%" height="400" controls controlsList="nodownload" preload="auto">
              <source src={learningList.video?`${urls.s3}${learningList.video.substring(32)}`:""} type="video/mp4" height="100" width="100" />
              <track src={learningList.video?`${urls.s3}${learningList.video.substring(32)}`:""} kind="captions" srcLang="en" label="english_captions" />
            </video>
          </Grid>
          <Grid item md={5} sm={5} xs={12} style={{ border: '1px solid white' }}>
            <Paper className={classes.textBox} style={{ width: '100%', height: '100%' }}>
              <Typography variant="h5">About Video :</Typography>
              <Divider className={classes.divider} />
              {ReactHtmlParser(learningList && learningList.text)}
            </Paper>
          </Grid>
          <Grid item md={12} sm={12} xs={12} style={{ marginTop: '20px' }}>
            <Paper className={classes.paper2}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>Make Notes :</Typography>
              <Divider className={classes.divider} />
              <TinyTextEditor
                heightInchs={inchs}
                id="LearningnotesId"
                initialValue={notes || ''}
                value={notes || ''}
                onChange={(content) => setNotes(content)}
              />
            </Paper>
            <Grid style={{ marginTop: '20px' }}>
              <Button style={{ float: 'left' }} variant="contained" color="primary" onClick={() => handleBackPage()}>Back To Learning</Button>
              {permission && permission.can_add ?
              <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={() => handelSaveNotes()}>Save Notes</Button>
              :null}
            </Grid>
          </Grid>
        </Grid>
        {loader}
      </div>
    </>
  );
};
InHouseLearningModel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InHouseLearningModel);
