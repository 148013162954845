export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1, 2, 1),
  },
  Modelpaper: {
    padding: theme.spacing(1, 2, 1),
    width: '50%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  applyButton: {
    margin: '9px 0px',
    // paddingTop : '5px'
  },
  updateButton: {
    marginTop: '27px',
  },
  AddButton: {
    marginTop: '25px',
  },
  tableMargin: {
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
