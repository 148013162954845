// export default () => ({});

export default (theme) => ({
  //   root: {
  //     display: "flex",
  //     flexWrap: "wrap"
  //   },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  tdMainDiv: {
    display: 'flex',
    width: '80%',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
  },
});
