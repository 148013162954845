import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from './registration.style';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import axios from 'axios';


const Registration = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [erp, setErp] = useState('');
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [branch, setBranch] = useState([]);
  const [address, setAddress] = useState('');
  // const PORTNum = 'http://localhost:8000';
  const [load, setLoad] = useState(false);
  const [permission, setPermission] = useState([]);


  const filterEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const alert = useAlert();

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch();

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranch,
  } = useFetch();

  useEffect(() => {
    fetchRoles({
      url: urls.getRoleRegistration,  
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Users')
      },
    });
    fetchBranch({
      url: urls.branchApi,
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Users')
      },
    });
  }, [fetchRoles, fetchBranch, auth.personal_info.token]);


  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Users')

      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Users'))
  }, [])

  const registerFunction = () => {
    if (!erp || !phoneNo || !name || !email || !role || !branch) {
      alert.warning('Select All Fields');
      return;
    }
    if (email.match(/[a-z@]/i)) {
      // eslint-disable-next-line no-useless-escape
      // const filter = /^([a-zA-Z0-9_\.\-])+\@gmail\.com$/;
      // const filterEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filterEmail.test(email)) {
        alert.warning('Please enter valid Email...');
        return;
      }
    }
    const data = {
      erp,
      name,
      phone_number: phoneNo,
      email,
      roles: role,
      branch,
      address,
    };
    async function loading() {
      setLoad(true);
      const response = await fetch(urls.formRegister, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Users')
        },
      });
      const res = await response.json();
      return res;
    }
    loading().then((res) => {
      setLoad(false);
      if (res === 'Success') {
        alert.success('user registration success');
      } else {
        alert.warning('user already exists');
      }
    });

    setErp('');
    setName('');
    setPhoneNo('');
    setEmail('');
    setRole('');
    setBranch('');
    setAddress('');
  };

  let loader = null;
  if (roleListLoading || branchListLoading) {
    loader = <Loader open />;
  }
  if (load) {
    loader = <Loader open />;
  }
  // eslint-disable-next-line no-useless-escape
  // const filterEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return (
    <>
    {permission.can_view ? 
    <>
      <Typography variant="h4">Registration Form</Typography>
      <Divider className={classes.divider} />
      <Paper>
        <Grid container spacing={3} className={classes.root}>
          <Grid item md={6} xs={12}>
            <TextField
              label="ERP No"
              margin="dense"
              fullWidth
              required
              value={erp}
              onChange={(e) => e.target.value.length < 12 && setErp(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Name"
              margin="dense"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Email"
              margin="dense"
              type="email"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
            {email && !filterEmail.test(email) ? (
              <FormHelperText
                id="component-error-text"
                style={{ color: 'red' }}
              >
                In Valid Email Address
              </FormHelperText>
            ) : null}
            {email && filterEmail.test(email) ? (
              <FormHelperText
                id="component-error-text"
                style={{ color: 'green' }}
              >
                Valid Email Address
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Phone No"
              type="number"
              margin="dense"
              fullWidth
              required
              value={phoneNo}
              // eslint-disable-next-line max-len
              onChange={(e) => setPhoneNo(
                Math.max(0, parseInt(e.target.value, 10))
                  .toString()
                  .slice(0, 10),
              )}
              variant="outlined"
            />
          </Grid>
            <Grid item md={6} xs={12}>
              <Typography>Select Role</Typography>
              <Select
                label="Role"
                margin="dense"
                fullWidth
                required
                value={role || ''}
                onChange={(e) => setRole(e.target.value)}
                variant="outlined"
              >
                {roleList
                  && roleList.response.map((data) => (
                    <MenuItem
                      value={data.role_id}
                      key={data.role_id}
                      name={data.role__name}
                    >
                      {data.role_id ? data.role_name : ''}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          <Grid item md={6} xs={12}>
            <Typography>Select Branch</Typography>
            <Select
              label="Branch"
              margin="dense"
              fullWidth
              required
              multiple
              value={branch || []}
              onChange={(e) => setBranch(e.target.value)}
              variant="outlined"
            >
              {branchList
                && branchList.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.branch_name}
                  >
                    {data.id ? data.branch_name : ''}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          {/* <Grid item md={12} xs={12}>
                        <TextField
                            label="Address"
                            margin="dense"
                            fullWidth
                            required
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                            variant="outlined"
                            multiline={true}
                            rows={2}
                            rowsMax={4}
                        />
                    </Grid> */}
          <Grid item md={4} xs={12}>
          {permission.can_add ? 
            <Button
              variant="contained"
              color="primary"
              onClick={() => registerFunction()}
            >
              Register
            </Button>
            :null}
          </Grid>
        </Grid>
      </Paper>
      </>
       :null}
      {loader}
    </>
  );
};
Registration.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(Registration);
