import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  Divider,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import styles from "../AddBranch/addBranch.style";
import CreateBranch from "./createResourse";
import axios from "axios";
import urls from "../../../url";
import { Page, Text, View, Document, StyleSheet } from "react-pdf";
import FileViewer from "react-file-viewer";
import "./createResourse.css";
// import ListBranch from "./listBranch";
import { useAlert } from "../../../hoc/alert/alert";
import ListResources from "./listResources";
import CreateResources from "./createResourse";

const addBranch = ({ classes }) => {
  return (
    <>
      <Typography variant="h4"> Add Resources</Typography>
      <Divider className={classNames.divider} />
      <Paper>
        <Grid container spacing={3} className={classes.root}>
          <Grid item md={12} xs={12}>
            <CreateResources />
          </Grid>
        </Grid>
      </Paper>
      <br />
      <br />
      <Grid xs={12}>
        <ListResources />
      </Grid>
    </>
  );
};
addBranch.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(addBranch);
