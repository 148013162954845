/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TablePagination,
  IconButton,
  Box,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './inHouseLearningNotes.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import axios from 'axios';



const InHouseLeanrningNotes = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [learningList, setLearningList] = useState();
  const [Loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const [modelData, setModelData] = useState('');
  const [permission,setPermission]=useState([]);


  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  const handleClose = () => {
    setModelData('');
    setOpen(false);
  };

  // function functionToDownloadPdf(id) {
  //   setLoading(true);
  //   fetch(`${urls.downloadNotesApi}?notes_id=${id}`, {
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setLoading(false);
  //         alert.success('Data Received');
  //         return res.json();
  //       }
  //       if (res.status !== 200) {
  //         setLoading(false);
  //         alert.warning('something went wrong please try again ');
  //       }
  //       return 0;
  //     }).then((data) => {
  //       setLearningList(data);
  //     });
  // }

  function downloadFunction() {
    // eslint-disable-next-line no-alert
    window.alert('Download Feature will be enabled at next release');
  }

  function functionForFullViewNotesModule() {
    let FullView = null;
    FullView = (
      <Grid container>
        <Grid item md={12} xs={12}>
          <Dialog
            maxWidth="xl"
            fullWidth
            className={classes.dialog}
            style={{ marginTop: '80px' }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={open}
          >
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
              {(modelData.learning_module && modelData.learning_module.title) || 'No Name'}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container>
                <Grid item md={12} xs={12}>
                  {modelData.notes && modelData.notes.length !== 0 && modelData.notes.map((item) => {
                    const Newdate = new Date(item.updated_at && item.updated_at);
                    return (
                      <>
                        <ExpansionPanel style={{ backgroundColor: 'snow' }} className={classes.paper3} key={item.id}>
                          <ExpansionPanelSummary
                            style={{ backgroundColor: 'lightgray' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Grid container>
                              <Grid item md={10} xs={6} style={{ marginTop: '15px' }}>
                                <Typography>
                                  Uploaded Date :&nbsp;
                                  <b style={{ color: 'blue' }}>
                                    {(item.updated_at && item.updated_at && Newdate.toString().split('G')[0]) || ''}
                                  </b>
                                </Typography>
                              </Grid>
                              <Grid item md={2} xs={6}>
                                {/* //  href={`${urls.downloadNotesApi}?notes_id=${item.id}`} */}
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => downloadFunction()}
                                >
                                  Download&nbsp;
                                  <CloudDownloadIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Box border={1} style={{ borderColor: 'lightgray', padding: '10px', margin: '10px' }} className={classes.notesPaper}>
                              <Grid container spacing={2} alignContent="center">
                                <Grid item md={12} xs={12}>
                                  <Typography style={{ color: 'black', padding: '25px' }}>{ReactHtmlParser(item.notes)}</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <Divider className={classes.divider} />
                      </>
                    );
                  })}
                </Grid>
                <Grid container>
                  <Grid item md={7} sm={7} xs={12} style={{ border: '1px solid lightgray' }}>
                    <video src={modelData?.learning_module && modelData?.learning_module.video?`${urls.s3}${modelData.learning_module.video.substring(32)}`:""} width="100%" height="400" controls controlsList="nodownload">
                      <source src={modelData?.learning_module && modelData.learning_module.video?`${urls.s3}${modelData.learning_module.video.substring(32)}`:""} type="video/mp4" height="300" width="100%" />
                      <track src={modelData?.learning_module && modelData.learning_module.video?`${urls.s3}${modelData.learning_module.video.substring(32)}`:""} kind="captions" srcLang="en" label="english_captions" />
                    </video>
                  </Grid>
                  <Grid item md={5} sm={5} xs={12} style={{ border: '1px solid lightgray' }}>
                    <Paper className={classes.textBox} style={{ width: '100%', height: '100%' }}>
                      <Typography variant="h5">About Video :</Typography>
                      <Divider className={classes.divider} />
                      {ReactHtmlParser(modelData && modelData.learning_module.text)}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    );
    return FullView;
  }

  function getData() {
    setLoading(true);
    fetch(`${urls.learningNotesApi}?page_size=${rowsPerPage || 10}&page=${page + 1}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Learning_Notes')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Data Received');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      }).then((data) => {
        setLearningList(data);
      });
  }

  let loader = null;
  if (Loading) {
    loader = <Loader open />;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth) {
      getData();
    }
  }, [auth.personal_info.token, page, rowsPerPage]);

  function handelOpenFullView(data) {
    setModelData(data);
    setOpen(true);
  }
     // For Permissions
     function getPermissonData(id) {
      axios.get(urls.getPermissons,{
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: id
        },
      }).then(response => {
        setPermission(response.data.response)
      }).catch(error => {
        console.log(error);
      })
    }
  
    useEffect(() => {
      getPermissonData(localStorage.getItem('Learning_Notes'))
    }, [])

  return (
    <>
      <div className={classes.LeanrningFullModule} style={{ height: learningList && learningList.results && learningList.results.length < 10 ? '100vh' : '100%' }}>
        <Typography variant="h4" className={classes.typographys}>MY NOTES</Typography>
        <Divider className={classes.divider} />
        <Grid container className={classes.paper2}>
          <Grid item md={12} sm={12} xs={12}>
            {learningList && learningList.results && learningList.results.length === 0
        && (
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}> No learning videos are added</Typography>
        )}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {learningList && learningList.results && learningList.results.length !== 0
        && (
        <>
          <Grid container spacing={2}>
            {learningList && learningList.results && learningList.results.length > 0
            && learningList.results.map((item, index) => {
              const colors = [
                '#54688c',
                '#f47a62',
                '#4a66da',
                '#75cba8',
                '#f2bf5e',
              ];
              const diffColors = index % 4;
              return (
                <Grid item md={3} sm={3} xs={12} key={item.id}>
                  <Grid container>
                    <Paper className={classes.paper} style={{ backgroundColor: colors[diffColors] }}>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                          <video src={item.learning_module && item.learning_module.video} width="150" height="100" controls controlsList="nodownload">
                            <source src={item.learning_module && item.learning_module.video} type="video/mp4" height="300" width="100%" />
                            <track src={item.learning_module && item.learning_module.video} kind="captions" srcLang="en" label="english_captions" />
                          </video>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography variant="h6" style={{ color: 'white' }}>
                            {(item.learning_module && item.learning_module.title) || ''}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          {permission.can_view ?
                          <Button style={{ color: 'white', borderColor: 'white' }} variant="outlined" onClick={() => handelOpenFullView(item)}>
                            <VisibilitySharpIcon />
                            &nbsp;Notes
                          </Button>
                          :null}
                        </Grid>
                        <Grid item md={6} xs={6}>
                        {permission.can_export ? 
                          <Button style={{ color: 'white', borderColor: 'white', width : '100%' }} variant="outlined" onClick={() => downloadFunction()}>
                            <CloudDownloadIcon />
                            Download
                          </Button>
                          :null}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </>
        )}
          </Grid>
          <Grid item md={12} xs={12}>
            {learningList && learningList.results && learningList.results.length > 0
            && (
            <Table className={classes.paper1}>
              <TableBody>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() => `Page ${page + 1} of ${+learningList.total_pages}`}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    count={+learningList.count}
                    rowsPerPage={rowsPerPage || 10}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: '13px' }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(learningList.total_pages - 1)}
                      disabled={page === +learningList.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            )}
          </Grid>
        </Grid>
        {loader}
        {functionForFullViewNotesModule()}
      </div>
    </>
  );
};
InHouseLeanrningNotes.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InHouseLeanrningNotes);
