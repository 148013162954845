/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  InputLabel,
  ListItemIcon,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";

import styles from "./randomMcqReport.style";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RandomMcqReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [checkLoader, setCheckLoader] = useState(false);

  const [role, setRole] = useState([]);
  const [branch, setBranch] = useState([]);
  const [SelectAll, setSelectedAll] = useState("");


  const alert = useAlert();
  const [permission, setPermission] = useState([]);

  const [bottomHRef, setBottomHRef] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [grade, setGradeId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [volumeId, setVolumeId] = useState("");
  const [courseId, setCourseId] = useState("");

  const [loading,setLoading]= useState(false);

  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  var verify = loginData.role_permission.is_orchids;

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);

  const {
    data: GradeList,
    isLoading: gradeListLoading,
    doFetch: fetchGradeList,
  } = useFetch([]);
  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);



  useEffect(() => {
    fetchBranches({
      url: urls.getBranchNamesApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Mcq_Report"),
      },
    });
    fetchGradeList({
      url: `${urls.grades}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Mcq_Report"),
      },
    });
    fetchRoles({
      url: `${urls.getRoleRegistration}?user_type=USER`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Mcq_Report"),
      },
    });
  }, [auth, page, fetchBranches, rowsPerPage]);

  const [subjectList,fetchSubjectList]= useState([]);
  console.log(subjectList,"subjectlist");
  useEffect(() => {
    setLoading(true);
    if(GradeList.length>0 && grade!== null){
    axios
      .get(`${urls.getSubjectByGrade}?grade_id=${grade}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Mcq_Report"),

        },
      })
      .then((response) => {
        setLoading(false);
        fetchSubjectList(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }

  },[grade])
 
  const [volumeList,fetchVolumeList]= useState([])
  useEffect(() => {
    setLoading(true);
    if(subjectList?.count > 0 && subjectId!== null){
    axios
      .get(`${urls.getVolumeBySubject}?subject_id=${subjectId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Mcq_Report"),

        },
      })
      .then((response) => {
        setLoading(false);
        fetchVolumeList(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }

  },[subjectId])
  const [courseList,setCourseList]=useState([]);
  console.log(courseList,"couseId")
  useEffect(() => {
    setLoading(true);
    if(GradeList.length>0 && subjectList?.count > 0 && volumeId!== null){
    axios
      .get(`${urls.inHouseModules}?subject=${subjectId}&volume=${volumeId}&grade=${grade}&subject_training=true`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Mcq_Report"),

        },
      })
      .then((response) => {
        console.log(response,"response")
        setLoading(false);
        setCourseList(response);
      })
      .catch((error) => {
        console.log(error);
      });
    }

  },[volumeId])

  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem("Mcq_Report"));
  }, []);

  const isAllBranchSelected =
    branchList?.length > 0 && branch?.length === branchList?.length;
  const handleBranchChange = (event) => {
    const value = event.target.value;
    // console.log(value[value.length - 1], "handleChange");
    const branchesList = branchList.map((item) => item?.branch_name);
    if (value[value.length - 1] === "all") {
      setBranch(branch?.length === branchList?.length ? [] : branchesList);
      return;
    }
    setBranch(value);
  };

  const handleGradeChange = (data) => {
    setGradeId(data);
  };

  const handleSubjectChange = (data) => {
    setSubjectId(data);
  
  };

  const handleVolumeChange = (data) => {
      setVolumeId(data);
    
  };
  const handleCourseChange = (data) => {
    setCourseId(data);
  
};
const handleSelectAllRole = (event) => {
  const value = event.target.value;

  setRole(value);
};

  useEffect(() => {

    const branchArr = [];
    for (let i = 0; i < branch.length; i += 1) {
      branchArr.push(
        branchList &&
        branchList.filter((item) => item.branch_name === branch[i])[0].id
      );
    }
    setBottomHRef([
      {
        csv: `${
          urls.randomMcqReport
        }?branch_id=${[branchArr]}&grade=${grade}&course_id=${courseId}&subject=${subjectId}&download=True&Authorization=Bearer ${
          auth.personal_info.token
        }&module=${localStorage.getItem(
          "Mcq_Report"
        )}&export_type=csv`,
      },
    ]);
  }, [grade, subjectId, volumeId,branch,courseId]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">New Mcq Report </Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container item spacing={1}>
                <Grid item md={4} xs={12} style={{marginTop:"-14px"}}>
                <Typography>
                  Select Branch
                  <b style={{ color: "red" }}>*</b>
                </Typography>

                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={branch || []}
                    // onChange={(e) => setSchool(e.target.value)}
                    onChange={handleBranchChange}
                    // onChange={(e) => handleSelectALlSchool(e)}
                    multiple
                    // className={classes.textField}
                    variant="filled"
                    style={{ color: "black", width: '400px' }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllBranchSelected}
                          indeterminate={
                            branch.length > 0 &&
                            branch.length < branchList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {branchList &&
                      branchList.length !== 0 &&
                      branchList.map((data) => (
                        <MenuItem
                          value={data?.branch_name}
                          key={data?.id}
                          name={data?.branch_name}
                        >
                          <Checkbox
                            checked={branch.indexOf(data?.branch_name) > -1}
                          />
                          <ListItemText primary={data?.branch_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {/* <Typography>
                    Select Branch
                    <b style={{ color: "red" }}>*</b>
                  </Typography> */}
                    {/* <TextField
                      margin="dense"
                      label="branch"
                      select
                      fullWidth
                      value={branch || []}
                      onChange={(e) => handleSelectALl(e)}
                      variant="filled"
                    >
                      {branchList &&
                        branchList.map((data) => (
                          <MenuItem
                            value={data.id}
                            key={data.id}
                            name={data.branch_name}
                          >
                            {data.branch_name ? data.branch_name : ""}
                          </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                 {/* <FormControl className={classes.formControl}> */}
                    {/* <TextField
                      margin="dense"
                      fullWidth
                      select
                      multiple
                      value={branch || []}
                      label="branch"
                      // onChange={(e) => setBranch(e.target.value)}
                      onChange={(e) => handleSelectALl(e)}
                      // className={classes.textField}
                      variant="filled"
                    >
                      <MenuItem key="0" value="0">
                        Select All
                      </MenuItem>
                      {branchList &&
                        branchList.length !== 0 &&
                        branchList.map((data) => (
                          <MenuItem
                            value={data.id}
                            key={data.id}
                            name={data.branch_name}
                          >
                            {data.branch_name ? data.branch_name : ""}
                          </MenuItem>
                        ))}
                    </TextField> */}
                  {/* </FormControl> */}
                </Grid>
                {/* <Grid item md={4} xs={12}>
                 
                    <TextField
                      margin="dense"
                      fullWidth
                      select
                      value={role || []}
                      label="Role"
                    
                      onChange={handleSelectAllRole}
                      variant="filled"
                    >
                      
                      {roleList &&
                        roleList.length !== 0 &&
                        roleList.response.map((data) => (
                          <MenuItem
                            value={data.role_name}
                            key={data.role_id}
                            name={data.role_name}
                          >
                            {data.role_name}
                          </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}

                <Grid item md={4} xs={12}>
              
                  <TextField
                    select
                    label="Select Grade"
                    value={grade}
                    fullWidth
                    onChange={(e) => handleGradeChange(e.target.value)}
                    margin="dense"
                    id="outlined-basic"
                    variant="filled"


                  >
                    {GradeList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.type_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                 
                 <TextField
                    select
                    id="outlined-basic"
                    label="Select Subject"
                    value={subjectId}
                    fullWidth
                    onChange={(e) => handleSubjectChange(e.target.value)}
                    margin="dense"
                    variant="filled"


                  >
                    {subjectList?.data?.map((option) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.subject_name}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Grid>
                <Grid item md={4} xs={12}>
                   <TextField
                    select
                    label="Select Volume"
                    value={volumeId}
                    fullWidth
                    // onChange={handleChange}
                    onChange={(e) => handleVolumeChange(e.target.value)}
                    margin="dense"
                    variant="filled"

                  >
                    {volumeList?.data?.map((option) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.volume_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                   <TextField
                    select
                    label="Select Course"
                    value={courseId}
                    fullWidth
                    // onChange={handleChange}
                    onChange={(e) => handleCourseChange(e.target.value)}
                    margin="dense"
                    variant="filled"

                  >
                    {courseList?.data?.map((option) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.course_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={1} xs={12} style={{marginTop:"-21px",marginLeft:"60px"}}>
                  <Button
                    style={{ marginTop: "33px" }}
                    className={classes.updateButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<GetAppIcon />}
                    href={bottomHRef && bottomHRef[0].csv}
                    disabled={grade && subjectId && volumeId ? false : true}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
      </div>
    </>
  );
};

RandomMcqReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(RandomMcqReport);
