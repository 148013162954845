export default (theme) => ({
  chapterBackground: {
    backgroundColor: '#055184',
    // backgroundColor: 'lightsteelblue',
    width: '100%',
    height: '100%',
    // paddingTop: '100px',
    // padding: theme.spacing(2, 4, 3),
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexDirection: 'column',
    // margin: '0 auto',
  },
  parentGrid: {
    width: '100%',
    height: 'auto',
    paddingTop: '150px',
    padding: theme.spacing(2, 4, 3),
    // backgroundColor: '#055184',
    // backgroundColor: 'lightsteelblue',
  },
  root: {
    position: 'relative',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
  },
  moduleCover: {
    width: '70%',
    height: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  moduleCards: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0.5rem 0.5rem 0',
    padding: ' 1rem',
  },
  firstHead: {
    display: '-webkit-box',
    textAlign: 'center',
    maxWidth: '200px',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondHead: {
    fontSize: '0.9rem',
    margin: '0.5rem 0.5rem 1rem 0.5rem',
  },
  linkDecor: {
    textDecoration: 'none',
    color: 'white',
  },
  backBtn: {
    textAlign: 'center',
  },
});
