/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  // withStyles,
} from '@material-ui/core/';
import {
  NavLink,
  //  Link,
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import {
  Input as InputIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import ComponentList from './componentList';
import styles from './layout.style';

function Layout() {
  const classes = styles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loginData] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [sidebarClicked, setSidebarClicked] = useState(false);
  // const [routeLink, setRouteLink] = useState("");

  const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
  ));

  // const role = loginData && loginData.personal_info && loginData.personal_info.role;
  const role = "ADMIN";

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const handleMenu = () => {
    localStorage.clear();
    window.location = '/';
  };

  const sideBarList = ComponentList;
  const roleList = () => {
    if (role) {
      const roleLower = role.toLowerCase();
      // console.log('>>>', roleLower);
      return sideBarList[roleLower];
    }
    return 0;
  };

  function onListClick(route) {
    setSidebarClicked((c) => !c);
    // eslint-disable-next-line no-console
    console.log(route);
    // setRouteLink(roleList().filter(item => item.link === route)[0]);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" style={{ color: 'white' }}>
            Sure Learning
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={handleMenu} color="inherit">
              <InputIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
        {loginData &&
                <Grid container>
                <Grid item md={12} xs={12} style={{textAlign:'right'}}>
                <Typography variant="h6"><strong>
                {(loginData && loginData.personal_info.role)}</strong>
                </Typography>
                </Grid>
                </Grid>
                }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <>
                
                <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
                </IconButton>
              </> 
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <BrowserRouter>
            {roleList()
              && roleList().length
              && roleList().map((text, index) => (text!==null && text!==undefined ? (
                <ListItem
                  key={index}
                  button
                  component={ForwardNavLink}
                  to={text.link}
                  onClick={() => onListClick(text.link)}
                >
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.name} />
                </ListItem>
              ) : (
                ''
              )))}
          </BrowserRouter>
          <Divider />
        </List>
      </Drawer>
      <main className={classes.content}>
        {sidebarClicked && (
          <BrowserRouter>
            <Switch>
              {roleList()
                && roleList().length
                && roleList().map((comp, index) =>(comp!==null && comp!==undefined)?(
                  <Route
                    key={index}
                    path={comp.link}
                    exact
                    component={comp.component}
                  />)
                :null)}
            </Switch>
          </BrowserRouter>
        )}
        {!sidebarClicked && (
          <BrowserRouter>
            <Switch>
              {roleList()
                && roleList().length
                && roleList().map((comp, index) => (comp!==null && comp!==undefined)?(
                  <Route
                    key={index}
                    path={comp.link}
                    exact
                    component={comp.component}
                  />
                ):null)}
            </Switch>
          </BrowserRouter>
        )}
        {/* {routeLink ? (
          <routeLink.component />
        ) : (
          <BrowserRouter>
            <Switch>
              {roleList() &&
                roleList().length &&
                roleList().map((comp, index) => (
                  <Route
                    key={comp.name}
                    path={comp.link}
                    exact
                    component={comp.component}
                  />
                ))}
            </Switch>
          </BrowserRouter>
        )} */}
      </main>
    </div>
  );
}

export default Layout;
