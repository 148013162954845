/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import urls from '../../url';
import Loader from '../../hoc/loader';

const useStyles = makeStyles(() => ({
  profileStyle: {
    textAlign: 'left',
    margin: '1.5rem 2rem',
  },
  dataValue: {
    border: '1px solid lightgrey',
    padding: '0.5rem',
    fontWeight: 'bold',
    margin: '0px',
  },
}));

const profileInfo = {
  username: '',
  email: '',
};

function YourProfile() {
  const [profileData, setProfileData] = useState(profileInfo);
  // const PORT_NUM = 'http://localhost:8000';
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [dataLoading, setdataLoading] = useState(false);

  let loader = null;
  if (dataLoading) {
    loader = <Loader open />;
  }
  useEffect(() => {
    setdataLoading(true);
    async function getProfileData() {
      const response = await fetch(urls.retrieve_user_details, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
      const getData = await response.json();
      setdataLoading(false);
      return getData;
    }

    getProfileData().then((data) => {
      setProfileData({
        ...profileData,
        username: data.user.username,
        firstname: data.user.first_name,
        mobile: data.phone_number,
        email: data.user.email,
        address: data.address,
        category: data.category.id,
        role_category: data.role_category.id,
      });
      // console.log(data);
    });
  }, []);

  // console.log(profileData);

  const classes = useStyles();
  return (
    <>
      {loader}
      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          component="div"
          style={{ backgroundColor: '#fefefe', height: '100vh' }}
        >
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ marginBottom: '0px' }}>Your Profile</h1>
            <p style={{ marginTop: '0px' }}>Information about Yourself</p>
            <hr />
            {/* {console.log(profileData)} */}
            {/*
            <p className={classes.profileStyle}>
              {profileData.user.first_name}
            </p> */}
            <div className={classes.profileStyle}>
              <Typography
                htmlFor=""
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                User Name:
              </Typography>
              <p className={classes.dataValue}>{profileData.username}</p>
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {!profileData.username && '* Please add your User Name'}
              </span>
            </div>
            <div className={classes.profileStyle}>
              <Typography
                htmlFor=""
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                First Name:
              </Typography>
              <p className={classes.dataValue}>{profileData.firstname}</p>
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {!profileData.firstname && '* Please add your first name'}
              </span>
            </div>
            <div className={classes.profileStyle}>
              <Typography
                htmlFor=""
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Email:
              </Typography>
              <p className={classes.dataValue}>{profileData.email}</p>
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {!profileData.email && '* Please add your Email'}
              </span>
            </div>
            <div className={classes.profileStyle}>
              <Typography
                htmlFor=""
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Mobile:
              </Typography>
              <p className={classes.dataValue}>{profileData.mobile}</p>
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {!profileData.mobile && '* Please add your Mobile'}
              </span>
            </div>
            <div className={classes.profileStyle}>
              <Typography
                htmlFor=""
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                Address:
              </Typography>
              <p className={classes.dataValue}>{profileData.address}</p>
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {!profileData.address && '* Please add your Address'}
              </span>
            </div>
          </div>
        </Typography>
      </Container>
    </>
  );
}

export default YourProfile;
