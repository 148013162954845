export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    //   position : 'absolute',
    //   overflow : true,
    padding: theme.spacing(2),
    // textAlign: "center",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  applyButton: {
    margin: '9px 0px',
    // paddingTop : '5px'
  },
  updateButton: {
    marginTop: '12px',
  },
  tableMargin: {
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
