/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import createHistory from "history/createBrowserHistory";
import Layout from "./layout/layout";
import Navbar from "./userLayout/Navbar";
import ProfessionalReg from "./userRegister/ProfessionalReg";
import MainPage from "./userLayout/MainPage";
import UserProfile from "./userLayout/UserProfile";
import Course from "./studentCourse/courses/course";
import CourseEnrol from "./studentCourse/courses/courseEnrol";
import ModelBody from "./studentCourse/courses/modelBody";
import StudentCourse from "./studentCourse/home/studentCourse";
import HomeEnrol from "./studentCourse/home/homeEnrol";
import CourseEnroleModle from "./components/reusableComponents/courseEnroleModle/courseEnroleModle";
import MoreInfo from "./components/reusableComponents/courseEnroleModle/MoreInfo";
import "./App.css";
import InHouseNavbar from "./InHouse/InHouseComponent/InHouseNavbar";
import InHouseMain from "./InHouse/InHouseComponent/MainPage/InHouseMain";
import InductionTraining from "./InHouse/InHouseComponent/InductionTraining/InductionTraining";
import TrainingModule from "./InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingModule";
import TrainingChapters from "./InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingChapters/TrainingChapters";
import TrainingLesson from "./InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingLessons/TrainingLesson";
import TrainingUnit from "./InHouse/InHouseComponent/InductionTraining/TrainingModule/TrainingUnit/TrainingUnit";
import ProfileUserView from "./InHouse/userProfile/userProfile";
import InHouseLearningVideos from "./InHouse/InHouseComponent/inHouseLearning/inHouseLearning";
import InHouseLearningModel from "./InHouse/InHouseComponent/inHouseLearning/learningFullView";
import InhouseCalendar from "./InHouse/InHouseComponent/inHouseViewWebinar/inhouseWibenarCalender";
import InHouseLeanrningNotes from "./InHouse/InHouseComponent/inHouseLearningNotes/inHouseLearningNotes";
import AboutInHouse from "./InHouse/InHouseComponent/About/AboutInHouse";
// import ContactUs from './InHouse/InHouseComponent/contactUs/contactUs';
import CoursesModule from "./InHouse/InHouseComponent/InhouseCourses/CoursesModule/CoursesModule";
import CoursesChapters from "./InHouse/InHouseComponent/InhouseCourses/CoursesModule/CoursesChapters/CoursesChapters";
import CoursesLesson from "./InHouse/InHouseComponent/InhouseCourses/CoursesModule/CoursesLessons/CoursesLesson";
import CoursesUnit from "./InHouse/InHouseComponent/InhouseCourses/CoursesModule/CoursesUnit/CoursesUnit";
import CoursesTraining from "./InHouse/InHouseComponent/InhouseCourses/CoursesTraining";
import TeacherToolBox from "./InHouse/InHouseComponent/InhouseTeacherToolbox/InhouseTeacherToolBox";
import PrincipalDashboard from "./components/PrincipalDashboard/PrincipalDashboard";
import TrainerDriven from "./components/TrainerDriven/TrainerDrivenDashboard";
import PrincipalRouting from "./components/PrincipalDashboard/PrincipalRouting";
import InHouseTreasureBox from "./InHouse/InHouseComponent/inHouseTreasureBox/inHouseTreasureBox";
import InHouseTreasureBoxVideos from "./InHouse/InHouseComponent/inHouseTreasureBox/InHouseTreasureBoxVideos/inHouseTreasureBoxVideos";

// import ForgetPassword from './forgetPassword/forgetPassword';
// import createHistory from 'history/createBrowserHistory';
// // import ReactGA from 'react-ga';

import ForgotPassword from "./components/forgotPassword/forgotPassword";
import CoursesView from "./components/PrincipalDashboard/PrincipalComponents/CoursesView";
// import ClassInitiate from './components/TrainerDriven/ClassInitiate/ClassInitiate';
// import TrainerDrivenForm from './components/TrainerDriven/ClassInitiateForm/TrainerDrivenForm';
// import EnrollCourse from './components/PrincipalDashboard/enrollCourses/enrollCourse';
// import AssignTeacher from './components/PrincipalDashboard/AssignTeachers/AssignTeacher';
// import ReAssignTeacher from './components/PrincipalDashboard/ReassignTeachers/ReAssignTeacher';
// import AssessmentReview from './components/PrincipalDashboard/assessmentReviews/assessmentReviews';
// import TeachersPerformance from './components/PrincipalDashboard/teachersPerformenceCard/teachersPerformenceCard';

import SideDrawer from "./InHouse/InHouseComponent/SideDrawer/SideDrawer";
import BackDrop from "./InHouse/InHouseComponent/BackDrop/BackDrop";
import BackgroundImage from "./userLayout/images/bookImg.jpg";
import changePassword from "./components/admin/changePassword/changePassword";
import Blog from "./InHouse/InHouseComponent/blog/Blog";
import Article from "./InHouse/InHouseComponent/blog/article";
import dashboard from "./components/PrincipalDashboard/visualReportsBranchwise/dashboard";
import consolidatedReport from "./components/PrincipalDashboard/consolidatedReport/consolidatedReport";
import Report from "./components/PrincipalDashboard/report/report";
import leadTeacher from "./components/PrincipalDashboard/leadTeacher/leadTeacher";
import inHouseNotification from "./InHouse/InHouseComponent/inHouseNotification/inHouseNotification";

import ModuleOrOnline from "./InHouse/InHouseComponent/moduleorOnlineClass/moduleorOnlineClass";
import InhouseOnlineClassStart from "./InHouse/InHouseComponent/onlineClassStart/onClassStart";
import TeacherDashboard from "./InHouse/InHouseComponent/InhouseCourses/TeacherDashboard/TeacherDashboard";
import contact from "./InHouse/InHouseComponent/contactUs/contactUs";
////////// subject Training ///////////
import SubjectTraining from "./InHouse/InHouseComponent/subjectTraining/SubjectTraining";
import SubjectTrainingModule from "./InHouse/InHouseComponent/subjectTraining/TrainingModule/TrainingModule";
import SubjectTrainingChapters from "./InHouse/InHouseComponent/subjectTraining/TrainingModule/TrainingChapters/TrainingChapters";
import SubjectTrainingLesson from "./InHouse/InHouseComponent/subjectTraining/TrainingModule/TrainingLessons/TrainingLesson";
import SubjectTrainingUnit from "./InHouse/InHouseComponent/subjectTraining/TrainingModule/TrainingUnit/TrainingUnit";
import TraintheTrainer from "./components/TrainerDriven/trainthetrainer/resourses";

import TeacherReport from "./InHouse/InHouseComponent/InhouseCourses/TeacherDashboard/TeacherReport";
import axios from "axios";
import urls from "./url";
import TrainerFolderTopic from "./components/TrainerDriven/trainthetrainer/trainer_folder_topic/trainer_folder_topic";
import Resourses from "./components/TrainerDriven/trainthetrainer/resourses";

// import OtpVerification from './userRegister/OtpVerification';

const history = createHistory();
ReactGA.initialize("UA-157976419-1");
history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

function App() {
  // const [loginData] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [loggedIn] = useState(JSON.parse(localStorage.getItem("isLoggedIn")));
  const [loginType] = useState(JSON.parse(localStorage.getItem("roleType")));
  const [is_allDataSet, setIs_AllDataSet] = useState(false);
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  const role =
    loginData && loginData.personal_info && loginData.personal_info.role;
  // console.log("r",role);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const array = [
    "Change User Status",
    "Change password",
    "Branch",
    "School",
    "Position Mapping",
    "Blog",
    "Authorize",
    "Course Language",
    "Course Level",
    "Grade",
    "Grade Subject Mapping",
    "Subject",
    "Notification",
    "PPT Video",
    "School Course Mapping",
    "Branch Wise Videos",
    "Chapter Sequencing",
    "Induction Training",
    "Self Driven",
    "Subject Training",
    "Trainer Driven",
    "About",
    "Dashboard",
    "Dashboard(Admin)",
    "Home",
    "Meeting & Webinar Report",
    "View all Notes",
    "Schedule Webinar",
    "Add Learnign Videos",
    "Consolidated Report",
    "School Wise Report",
    ,
    "Branch wise report",
    "Dashboard(ContentWriter)",
    "iashboard(Admin)",
    "Dashboard(Principal)",
    "Weekly Report",
    "User List Report",
    "Treasure Box",
  ];

  localStorage.getItem("isLoggedIn");

  const drawerTogglerClickHandler = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  function routingFunction() {
    if (
      role === "ContentWriter" ||
      role === "SuperAdmin" ||
      localStorage.getItem("userType") === "ADMIN" ||
      localStorage.getItem("userType") === "Admin" ||
      role === "Admin"
    ) {
      return (
        <>
          <Navbar />
          <Route path="/" component={Layout} />

          <Route path="/CourseEnroleModle" component={CourseEnroleModle} />
        </>
      );
    }
    function principalRouting() {
      // console.log("abcd");
      if (localStorage.getItem("principalCourseType") === null) {
        // console.log("aaya")
        return <Route path="/" exact component={PrincipalRouting} />;
      }
      if (localStorage.getItem("principalCourseType") === "trainer_driven") {
        // console.log("aaya2")
        return <Route path="/trainerDriven" component={TrainerDriven} />;
      }
      // console.log("abcd");
      return (
        <>
          <Route path="/principalDashboard" component={PrincipalDashboard} />
        </>
      );
    }

    if (
      role === "LeadTeacher" ||
      role === "Principal" ||
      localStorage.getItem("userType") === "USER" ||
      role === "Coordinator" ||
      role === "AcademicHeads" ||
      role === "Planner" ||
      role === "Coordinator" ||
      role === "AcademicManager"
    ) {
      let backdrop;
      if (sideDrawerOpen) {
        backdrop = <BackDrop click={backdropClickHandler} />;
      }
      return (
        <>
          <div style={{ height: "100%" }}>
            {localStorage.getItem("isLoggedIn") !== null ? (
              <InHouseNavbar drawerClickHandler={drawerTogglerClickHandler} />
            ) : null}
            {localStorage.getItem("isLoggedIn") !== null ? (
              <SideDrawer show={sideDrawerOpen} />
            ) : null}
            {backdrop}
          </div>
          {localStorage.getItem("Resources_data") !== null ? (
            <Route
              path="/trainerDriven/Resourses"
              exact
              component={Resourses}
            />
          ) : null}

          <Route path="/" exact component={InHouseMain} />
          <Route path="/about" component={AboutInHouse} />
          {/* <Route path="/contactUs" component={ContactUs} /> */}

          {localStorage.getItem("Subject_Training") !== null ? (
            <Route path="/SubjectTraining" exact component={SubjectTraining} />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route path="/volumes" exact component={SubjectTrainingModule} />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route
              path="/subject_chapters"
              exact
              component={SubjectTrainingChapters}
            />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route
              path="/subject_lessons"
              exact
              component={SubjectTrainingLesson}
            />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route path="/subject_unit" exact component={SubjectTrainingUnit} />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route
              path="/inductionTraining"
              exact
              component={InductionTraining}
            />
          ) : null}
          <Route path="/teacherReport" exact component={TeacherReport} />
          <Route path="/TeacherDashboard" component={TeacherDashboard} />
          <Route path="/user_profile" exact component={ProfileUserView} />
          {localStorage.getItem("Learnig_Videos") !== null ? (
            <Route
              path="/inhouse_Learning_videos"
              exact
              component={InHouseLearningVideos}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route
              path="/learning_full_view"
              exact
              component={InHouseLearningModel}
            />
          ) : null}
          {localStorage.getItem("Trainer_Driven") !== null ? (
            <Route
              path="/trainerDriven_Teacher"
              exact
              component={ModuleOrOnline}
            />
          ) : null}
          {/* {localStorage.getItem('Online_Class')!==null? */}
          <Route
            path="/online_Class_Start"
            exact
            component={InhouseOnlineClassStart}
          />
          {localStorage.getItem("Learning_Notes") !== null ? (
            <Route
              path="/learning_note_view"
              exact
              component={InHouseLeanrningNotes}
            />
          ) : null}
          {localStorage.getItem("Notification") !== null ? (
            <Route
              path="/view_notification"
              exact
              component={inHouseNotification}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/inhouse_calendar" exact component={InhouseCalendar} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/change_password" exact component={changePassword} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/inHouseCourses" exact component={CoursesTraining} />
          ) : null}
          {/* {alert('Checking Rout')} */}
          <Route path="/enroleModel" component={CourseEnroleModle} />

          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/modules" exact component={TrainingModule} />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/chapters" exact component={TrainingChapters} />
          ) : null}
          {/* <Route
             path="/principalDashboard/inHouseReport"
             exact  
             component={Report}
          /> */}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/lessons" exact component={TrainingLesson} />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/unit" exact component={TrainingUnit} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesModules" exact component={CoursesModule} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesChapters" exact component={CoursesChapters} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesLessons" exact component={CoursesLesson} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesUnit" exact component={CoursesUnit} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/activity" exact component={Blog} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/full-article" exact component={Article} />
          ) : null}
          {localStorage.getItem("Treasure_Box") !== null ? (
            <Route path="/teacherToolBox" exact component={TeacherToolBox} />
          ) : null}
          {/* <Route path="/enroleModel" component={CourseEnroleModle} /> */}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/visualDashboard" exact component={dashboard} />
          ) : null}
          {localStorage.getItem("Consolidated_Report") !== null ? (
            <Route
              path="/inHouseConsolidatedReport"
              exact
              component={consolidatedReport}
            />
          ) : null}

          {localStorage.getItem("Branch_Wise_Report") !== null ? (
            <Route path="/inHouseReport" exact component={Report} />
          ) : null}
          {/* <Route path="/testDrivenCoursesView" component={CoursesView} />
          <Route path="/classInitiationForm" component={TrainerDrivenForm} />
          <Route path="/initiateForm" component={ClassInitiate} /> */}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/modelBody" component={ModelBody} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/MoreInfo" component={MoreInfo} />
          ) : null}
          {/* <Route path="/enroleModel" component={CourseEnroleModle} /> */}
          <Route
            path="/principalDashboard/coursesView"
            exact
            component={CoursesView}
          />
          {/* <Route path="/principalDashboard/enroll_course" component={EnrollCourse} /> */}
          {/* <Route path="/principalDashboard/coursesView" exact component={CoursesView} />
          <Route path="/principalDashboard/assignTeacher" exact component={AssignTeacher} />
          <Route path="/principalDashboard/reAssignTeacher" exact component={ReAssignTeacher} />
          <Route path="/principalDashboard/assessmentReviews" exact component={AssessmentReview} />
          <Route path="/principalDashboard/teachers_performance" exact component={TeachersPerformance} /> */}
          {localStorage.getItem("Treasure_Box") !== null ? (
            <Route
              path="/inHouseTreasureBox"
              exact
              component={InHouseTreasureBox}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/leadTeacher" exact component={leadTeacher} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route
              path="/InhouseTreasureBoxModuleVideos"
              exact
              component={InHouseTreasureBoxVideos}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/contact" exact component={contact} />
          ) : null}
          {principalRouting()}
          {/* <Route
          path="/principalDashboard"
          component={PrincipalDashboard}
        /> */}
        </>
      );
    }
    function teacherRouting() {
      if (localStorage.getItem("coursesType") === "self_driven") {
        return <Route path="/TeacherDashboard" component={TeacherDashboard} />;
      }
      if (localStorage.getItem("coursesType") === "is_training_course") {
        return <Route path="/TeacherDashboard" component={TeacherDashboard} />;
      }
      return "";
    }
    if (
      role === "Teacher" ||
      localStorage.getItem("isLoggedIn") !== null ||
      role === "Deputy Zonal Head" ||
      role === "Business Development Manager" ||
      role === "Assistant Business Development Manager" ||
      role === "Zonal Head- Inbound Marketing" ||
      role === "Cluster Counselor" ||
      role === "Counselor" ||
      role === "Digital marketing head" ||
      role === "MarketingHead" ||
      role === "SEO head" ||
      role === "Digital marketing specialist" ||
      role === "Digital Marketing Executive" ||
      role === "Associate Content and Management Lead" ||
      role === "EA" ||
      role === "FOE" ||
      localStorage.getItem("userType") === "USER"
    ) {
      let backdrop;
      // if (localStorage.getItem('principalCourseType') === null) {
      //   return <Route path="/" exact component={PrincipalRouting} />;
      // }

      if (sideDrawerOpen || localStorage.getItem("isLoggedIn") !== null) {
        backdrop = <BackDrop click={backdropClickHandler} />;
      }
      return (
        <>
          <div style={{ height: "100%" }}>
            {localStorage.getItem("isLoggedIn") !== null ? (
              <InHouseNavbar drawerClickHandler={drawerTogglerClickHandler} />
            ) : null}
            {localStorage.getItem("isLoggedIn") !== null ? (
              <SideDrawer show={sideDrawerOpen} />
            ) : null}
            {backdrop}
          </div>
          {/* {localStorage.getItem('isLoggedIn */}
          <Route path="/" exact component={InHouseMain} />

          <Route path="/about" exact component={AboutInHouse} />
          {/* <Route path="/contactUs" component={ContactUs} /> */}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route path="/SubjectTraining" exact component={SubjectTraining} />
          ) : null}
          {localStorage.getItem("Trainer_Driven") !== null ? (
            <Route
              path="/trainerDriven_Teacher"
              exact
              component={ModuleOrOnline}
            />
          ) : null}
          {localStorage.getItem("Online_Class") !== null ? (
            <Route
              path="/online_Class_Start"
              exact
              component={InhouseOnlineClassStart}
            />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route
              path="/inductionTraining"
              exact
              component={InductionTraining}
            />
          ) : null}
          <Route path="/teacherReport" exact component={TeacherReport} />

          <Route path="/user_profile" exact component={ProfileUserView} />
          {localStorage.getItem("Learnig_Videos") !== null ? (
            <Route
              path="/inhouse_Learning_videos"
              exact
              component={InHouseLearningVideos}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route
              path="/learning_full_view"
              exact
              component={InHouseLearningModel}
            />
          ) : null}
          {localStorage.getItem("Learning_Notes") !== null ? (
            <Route
              path="/learning_note_view"
              exact
              component={InHouseLeanrningNotes}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/inhouse_calendar" exact component={InhouseCalendar} />
          ) : null}
          {localStorage.getItem("Notification") !== null ? (
            <Route
              path="/view_notification"
              exact
              component={inHouseNotification}
            />
          ) : null}
          {localStorage.getItem("Change_Password") !== null ? (
            <Route path="/change_password" exact component={changePassword} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/inHouseCourses" exact component={CoursesTraining} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/modules" exact component={TrainingModule} />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route path="/volumes" exact component={SubjectTrainingModule} />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/chapters" exact component={TrainingChapters} />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route
              path="/subject_chapters"
              exact
              component={SubjectTrainingChapters}
            />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/lessons" exact component={TrainingLesson} />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route
              path="/subject_lessons"
              exact
              component={SubjectTrainingLesson}
            />
          ) : null}
          {localStorage.getItem("Induction_Training") !== null ? (
            <Route path="/unit" exact component={TrainingUnit} />
          ) : null}
          {localStorage.getItem("Subject_Training") !== null ? (
            <Route path="/subject_unit" exact component={SubjectTrainingUnit} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesModules" exact component={CoursesModule} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesChapters" exact component={CoursesChapters} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesLessons" exact component={CoursesLesson} />
          ) : null}
          {localStorage.getItem("Courses") !== null ? (
            <Route path="/CoursesUnit" exact component={CoursesUnit} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/activity" exact component={Blog} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/full-article" exact component={Article} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/teacherToolBox" exact component={TeacherToolBox} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/visualDashboard" exact component={dashboard} />
          ) : null}
          {localStorage.getItem("Consolidated_Report") !== null ? (
            <Route
              path="/inHouseConsolidatedReport"
              exact
              component={consolidatedReport}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/inHouseReport" exact component={Report} />
          ) : null}
          {localStorage.getItem("Treasure_Box") !== null ? (
            <Route
              path="/inHouseTreasureBox"
              exact
              component={InHouseTreasureBox}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/leadTeacher" exact component={leadTeacher} />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route
              path="/InhouseTreasureBoxModuleVideos"
              exact
              component={InHouseTreasureBoxVideos}
            />
          ) : null}
          {localStorage.getItem("isLoggedIn") !== null ? (
            <Route path="/contact" exact component={contact} />
          ) : null}
          <Route path="/principalDashboard" component={PrincipalDashboard} />
          {teacherRouting()}
        </>
      );
    }

    return (
      <>
        <Navbar />
        {localStorage.getItem("isLoggedIn") !== null ? (
          <Route path="/" exact component={StudentCourse} />
        ) : null}
        {localStorage.getItem("isLoggedIn") !== null ? (
          <Route path="/homeEnrol" component={HomeEnrol} />
        ) : null}
        {localStorage.getItem("Courses") !== null ? (
          <Route path="/course" component={Course} />
        ) : null}
        {localStorage.getItem("Courses") !== null ? (
          <Route path="/CourseEnrol" component={CourseEnrol} />
        ) : null}
        {localStorage.getItem("Courses") !== null ? (
          <Route path="/modelBody" component={ModelBody} />
        ) : null}
        {localStorage.getItem("isLoggedIn") !== null ? (
          <Route path="/profile" exact component={UserProfile} />
        ) : null}
        {localStorage.getItem("isLoggedIn") !== null ? (
          <Route path="/register" exact component={ProfessionalReg} />
        ) : null}
      </>
    );
  }

  return (
    <div>
      {loggedIn ? (
        <Router history={history}>
          <Switch>{routingFunction()}</Switch>
        </Router>
      ) : (
        <Router history={history}>
          <div
            style={{
              background: `url(${BackgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
            }}
          >
            <Navbar />
            <Switch>
              <Route path="/" exact component={MainPage} />
              {/* <Route path="/otp" exact component={OtpVerification} /> */}
              <Route path="/register" exact component={ProfessionalReg} />
              <Route path="/forgotPassword" exact component={ForgotPassword} />
            </Switch>
          </div>
        </Router>
      )}
    </div>
  );
}

export default App;
