import React from 'react';
import { Grid } from '@material-ui/core';
import { HorizontalBar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const ModuleView = ({ moduleDetails }) => {
  const lineChart = (
    moduleDetails && moduleDetails.length
      ? (
        <HorizontalBar
          data={{
            labels: moduleDetails.map((data) => data.course__course_name),
            datasets: [{
              data: moduleDetails.map((data) => data.user_count),
              label: 'users',
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
            },
            ],
          }}
          options={{
            legend: { display: false },
            title: { display: true, text: 'Inductions Training Modules View' },
          }}
        />
      ) : null
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={10}>
        <article className="canvas-container">
          {lineChart}
        </article>
      </Grid>
    </Grid>

  );
};

ModuleView.propTypes = {
  moduleDetails: PropTypes.instanceOf(Array).isRequired,
};

export default ModuleView;
