import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./users.scss";
import FloatingActionButtonZoomUsers from "../../users/floating_button_users/floating_button_users.jsx";
import UsersTable from "../../users/users_table/users_table.jsx";
// import services from "../../services/organization.js"
import Urls from "../../../../url";
import axois from "axios";
// import Snackbar from "../../components/snackbar/snackbar.jsx";
// import Auth from '../../assets/image/unAuth.jpg'
// import ErrorPage from '../error_page/error_page.jsx'
import { Avatar } from "@material-ui/core";
import useFetch from '../../../../hoc/useFetch';
import { useAlert } from '../../../../hoc/alert/alert';
import axios from "axios";
import urls from "../../../../url";

const Users = () => {
  const [open, setOpen] = useState(false);
  const [snackbarServerty, setSnackbarServerty] = useState("success");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [data, setData] = useState([]);
  const [Alldata, setAllData] = useState('');
  const alert = useAlert();
  const [dataToEdit, setDataToEdit] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [accessRule, setAccessRule] = useState([]);
  const [isError, setIsError] = useState("progress");
  const [formType, setFormType] = useState("Add");
  const [page, setPage] = React.useState(0);
  const [next, setNext] = React.useState(Urls.getUserList);
  const [previous, setPrevious] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [Totalpage, setTotalPage] = React.useState(0);
  // const [currentPage, setCurrentPage] = React.useState(0);
  const module = localStorage.getItem("Users")
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));

  useEffect(() => {
    getUserRefreshList();
  }, [searchData]);

  const openDialog = () => {
    setDataToEdit([]);
    setOpen(true);
  };

  const handleDelete = (id, status) => {
    axios.get(Urls.userStatusChangeAPI+`?id=${id}&status=${status}` , {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : module
      },
    })
    .then(response => {
      getUserRefreshList();
      alert.success('user status changed');
    }).catch(error => {
      alert.error("Status not updated !!!");
    })
  };
  const closeDialog = () => {
    setOpen(false);
    setDataToEdit([]);
    setFormType("Add");
  };
  const editData = (incoming) => {
    setDataToEdit(incoming);
    setFormType("Update");
    setOpen(true);
    console.log("check edit methof", incoming);
  };

  const getUserList = (previouslink) => {
    let link=previouslink?previous:next;
    axois
      .get(link+`${searchData}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module : module
        },
      })
      .then((response) => {
        // console.log(response.data.results, "search1");
        setAllData(response.data)
        setPage(response.data.current_page-1)
        setTotalPage(response.data.current_page.total_pages)
        setPrevious(response.data.previous)
        setNext(response.data.next)
        setData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserRefreshList = () => {
    let link=Urls.getUserList;
    axois
      .get(link+`?search=${searchData}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module : module
        },
      })
      .then((response) => {
        // console.log(response.data.results, "search1");
        setAllData(response.data)
        setPage(0)
        setTotalPage(response.data.current_page.total_pages)
        setPrevious(response.data.previous)
        setNext(response.data.next)
        setData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccess = (moduleId) => {
    axios.get(urls.getPermissons, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : module
      },
    })
    .then(response => {
      setAccessRule(response.data.response);
    }).catch(error => {
      console.log(error.response, "auth response");
    })
  };

  useEffect(() => {
    getAccess(localStorage.getItem("Users"));
  }, []);

  return (
    <div>
      <Grid>
        <Paper>
          {accessRule.can_view ? 
          <UsersTable
            data={data}
            getUserList={getUserList}
            editUser={editData}
            handleDelete={handleDelete}
            setSearchData={setSearchData}
            searchData = {searchData}
            accessRule={accessRule}
            Alldata={Alldata}
            page={page}
            next={next}
            previous={previous}
            count={count}
            getUserRefreshList={getUserRefreshList}
            // currentPage={currentPage}
            Totalpage={Totalpage}
            module ={module}
          />
          :null}
        </Paper>
      </Grid>
      <div className="action-button">
         
        <FloatingActionButtonZoomUsers
          data={data}
          getUserList={getUserList}
          openDialog={openDialog}
          closeDialog={closeDialog}
          open={open}
          accessRule = {accessRule}
          dataToEdit={dataToEdit}
          formType={formType}
          module ={module}
        />

      </div>
      {/* <div className="fixed-footer">
                <Footer />
            </div> */}
      {/* <Snackbar
        open={openSnackBar}
        snackbarServerty={snackbarServerty}
        snackbarMessage={snackbarMessage}
        handleClose={handleClose}
      /> */}
    </div>
  );
};
export default Users;
