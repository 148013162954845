/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  withStyles,
  Button,
  Divider,
  TextField,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Add as AddIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import styles from './addCourseDetails.Style';
import useFetch from '../../../../hoc/useFetch';
import urls from '../../../../url';
import Loader from '../../../../hoc/loader';

const AddCourseDetails = ({
  classes,
  userIdNo,
  backPage,
  nextPage,
  pageNo,
}) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [Coursedetails, setcoursedetails] = useState('');
  const [sequencingNum, setsequencingNum] = useState(1);

  const {
    data: courseDetailssubmit,
    isLoading: courseDetailssubmitLoading,
    doFetch: fetchSubmitCourseDetails,
  } = useFetch(null);

  const {
    data: getCourseDetailsData,
    isLoading: getCourseDetailsDataLoading,
    doFetch: fetchCourseDetails,
  } = useFetch(null);

  const {
    data: deleteCourseSection,
    isLoading: deletingCourseDetail,
    doFetch: fetchToDeleteCourseDetail,
  } = useFetch(null);

  let loader = null;
  if (
    courseDetailssubmitLoading
    || getCourseDetailsDataLoading
    || deletingCourseDetail
  ) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (auth || courseDetailssubmit || deleteCourseSection) {
      fetchCourseDetails({
        url: `${urls.editCourseInfoApi}?course_id=${userIdNo}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });
    }
  }, [auth, deleteCourseSection, courseDetailssubmit]);

  useEffect(() => {
    if (
      getCourseDetailsData
      && getCourseDetailsData.length
      && getCourseDetailsData[0]
      && getCourseDetailsData[0].id
    ) {
      setsequencingNum(getCourseDetailsData.length + 1);
      setcoursedetails(getCourseDetailsData || '');
    }
  }, [getCourseDetailsData]);

  function addExtraDetail() {
    setsequencingNum(sequencingNum + 1);
    setcoursedetails((oldData) => [
      ...oldData,
      {
        question: null,
        example: [''],
        course: userIdNo,
        id: null,
        sequencing: sequencingNum,
      },
    ]);
  }

  useEffect(() => {
    if (auth) {
      addExtraDetail();
    }
  }, [auth]);

  function addExtraLable(key, index) {
    setcoursedetails((previousData) => {
      previousData[index][key].push('');
      return [...previousData];
    });
  }

  function DeleteLableFunction(index, labelIndex) {
    setcoursedetails((previousData) => {
      previousData[index].example.splice(labelIndex, 1);
      return [...previousData];
    });
  }

  function DeleteObjectFunction(index) {
    // console.log(Coursedetails && Coursedetails[index].id,'dddddddd')
    if (Coursedetails && Coursedetails[index].id === null) {
      setcoursedetails((previousData) => {
        previousData.splice(index, 1);
        return [...previousData];
      });
    } else {
      fetchToDeleteCourseDetail({
        url: `${urls.editCourseInfoApi}?id=${Coursedetails
          && Coursedetails[index].id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });
    }
  }

  const changeHandlerq = (e, key, i, id) => {
    if (!Coursedetails.length) {
      alert.warning('No data available');
      return;
    }

    setcoursedetails((data) => {
      const newData = [...data];
      switch (key) {
        case 'question':
          newData[i][key] = e;
          return newData;
        case 'example':
          newData[i][key][id] = e;
          return newData;
        default:
          return null;
      }
    });
  };

  function functionSubmitDatails() {
    fetchSubmitCourseDetails({
      url: `${urls.editCourseInfoApi}?course_id=${userIdNo}`,
      method: 'POST',
      body: Coursedetails,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
      },
    });
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.head}>
          Course Details
        </Typography>
      </Paper>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={10} xs={12}>
          <Typography variant="h5" style={{ color: 'blue', marginTop: '6px', textAlign: 'center' }}> This section is not compulsory for inhouse ignore and go to next section</Typography>
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '10px' }}
            onClick={() => addExtraDetail()}
          >
            Add Details
            {' '}
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      {Coursedetails
        && Coursedetails.length
        && Coursedetails.map((item, index) => (
          <Box border={5} key={item.id} style={{ color: 'lightgray' }}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item md={11} xs={9}>
                  <TextField
                    className={classes.textField}
                    label="Question"
                    margin="dense"
                    multiline
                    value={item.question || ''}
                    onChange={(e) => changeHandlerq(e.target.value, 'question', index)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={1} xs={2}>
                  <IconButton
                    size="small"
                    color="secondary"
                    className={classes.button}
                    onClick={() => DeleteObjectFunction(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item md={12} xs={12}>
                  {item.example
                    && item.example.length !== 0
                    && item.example.map((data, lebelIndex) => (
                      <Grid container spacing={2} key={lebelIndex}>
                        <Grid item md={10} xs={9}>
                          <TextField
                            className={classes.textField}
                            label="Answer"
                            margin="dense"
                            multiline
                            value={data || ''}
                            onChange={(e) => changeHandlerq(
                              e.target.value,
                              'example',
                              index,
                              lebelIndex,
                            )}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={2} xs={2}>
                          <IconButton
                            size="small"
                            className={classes.button}
                            onClick={() => DeleteLableFunction(index, lebelIndex)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Grid item md={12} />
                <Grid item md={12} xs={12}>
                  <Typography variant="subtitle1">
                    Add an Answer
                    {' '}
                    <Button
                      variant="contained"
                      onClick={() => addExtraLable('example', index)}
                    >
                      <AddIcon />
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        ))}
      {Coursedetails && Coursedetails.length !== 0 && (
        <Grid container spacing={2}>
          <Grid item md={5} xs={2} />
          <Grid item md={4} xs={9}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '15px' }}
              onClick={() => functionSubmitDatails()}
            >
              Submit Details
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item md={8} />
        <Grid item md={2} xs={6}>
          <Button
            className={classes.textField}
            color="primary"
            variant="contained"
            disabled={pageNo === '0'}
            onClick={() => backPage()}
          >
            Back
          </Button>
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            className={classes.textField}
            color="primary"
            variant="contained"
            disabled={pageNo === '7'}
            onClick={() => nextPage()}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      {loader}
    </>
  );
};
AddCourseDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  userIdNo: PropTypes.string.isRequired,
  backPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageNo: PropTypes.string.isRequired,
};
export default withStyles(styles)(AddCourseDetails);
