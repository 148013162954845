/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  withStyles,
  Button,
  Divider,
  TextField,
  Box,
  MenuItem,
  Modal,
  Checkbox,
  Select,
  LinearProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import mqtt from "mqtt";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Add as AddIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Radio from "@material-ui/core/Radio";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import TinyTextEditor from "../../../../hoc/tinyMce/tinyTextEditor";
import styles from "./courseSchedule.Style";
import { useAlert } from "../../../../hoc/alert/alert";
import useFetch from "../../../../hoc/useFetch";
import Loader from "../../../../hoc/loader";
import CreateMcqTest from "./createMcqTest/createMcqTest";
import urls from "../../../../url";
import AddLinksModel from "./addLinkModule/addLinkModule";

const CourseSchedule = ({ classes, userIdNo, backPage, nextPage, pageNo }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [Coursedetails, setcoursedetails] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [courseInduction, setCourseInduction] = useState("");
  const [zipTrue, setZipTrue] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [openLinkModel, setOpenLinkModel] = useState(false);
  const [courseSection, setCourseSection] = useState("");
  const [indexids, setIndexIds] = useState([
    {
      key1: "",
      index: "",
      lebelIndex: "",
      key2: "",
      index2: "",
    },
  ]);
  const [open, setOpen] = React.useState(false);
  const [fileDataInfo, setFileDataInfo] = useState([
    {
      fileData: [],
      title: null,
      description: null,
      activityConfirm: null,
      allowDownload: null,
      is_visible_to_principal: false,
      is_visible_to_teacher: false,
      is_visible_to_trainee_while_training: false,
      ppt_page_links: [],
    },
  ]);
  const [openvideo, setOpenVideo] = React.useState(false);
  const [videoDataInfo, setVideoDataInfo] = useState([
    {
      videoData: [],
      videoTitle: null,
      videoDescription: null,
      confirmActivity: null,
      is_visible_to_principal: false,
      is_visible_to_teacher: false,
      is_visible_to_trainee_while_training: false,
    },
  ]);
  const [openAssignment, setOpenAssignment] = React.useState(false);
  const [assignmentDataInfo, setAssignmentDataInfo] = useState([
    {
      assignmentFiles: [],
      assignmentTitle: "",
      is_download: false,
      assignmentDecription: null,
      confirmActivity: null,
      submitInTime: null,
      is_assessment_due_date: null,
      is_assessment_due_day: null,
      is_visible_to_principal: false,
      is_visible_to_teacher: false,
      is_visible_to_trainee_while_training: false,
    },
  ]);
  const [openText, setOpenText] = React.useState(false);
  const [textDataInfo, setTextDataInfo] = useState([
    {
      textTitle: null,
      textDocument: null,
      is_visible_to_principal: false,
      is_visible_to_teacher: false,
      is_visible_to_trainee_while_training: false,
    },
  ]);
  const [openMcq, setOpenMcq] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [contentId, setContentId] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [mcqAddQAModle, setmcqAddQAModle] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [openPPT, setOpenPPT] = useState(false);
  const [pptFileInfo, setPPTFileInfo] = useState([
    {
      allowDownload: false,
      compalsary: false,
      is_visible_to_principal: false,
      is_visible_to_teacher: false,
      is_visible_to_trainee_while_training: false,
    },
  ]);
  const [topic, setTopic] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const alert = useAlert();

  const {
    data: getStartEndDate,
    isLoading: gettingStartAndEndDate,
    doFetch: getStartEndData,
  } = useFetch(null);

  const {
    data: iconImages,
    isLoading: gettingIconImages,
    doFetch: FetchIconImages,
  } = useFetch(null);
  const {
    data: AddCourseSection,
    isLoading: addingCourseScection,
    doFetch: fetchtoAddCourseScection,
  } = useFetch(null);

  const {
    data: saveSectionTitle,
    isLoading: savingSectionTitle,
    doFetch: fetchtoSaveSectionTitle,
  } = useFetch(null);

  const {
    data: deleteSectionTitle,
    isLoading: deleatingSeactionTitle,
    doFetch: fetchToDeleteSectionTitle,
  } = useFetch(null);

  const {
    data: CreateCourseChapterList,
    isLoading: CreatingCourseChapterList,
    doFetch: fetchToCreateChapterList,
  } = useFetch(null);

  const {
    data: updateChapterTitle,
    isLoading: updatingChapterTitle,
    doFetch: fetchToUpdateChapterTitle,
  } = useFetch(null);

  const {
    data: deleteChapter,
    isLoading: deleatingChapter,
    doFetch: fetchToDeleteChapter,
  } = useFetch(null);

  const {
    data: createFileData,
    isLoading: creatingFileData,
    doFetch: fetchtoSaveFileData,
  } = useFetch(null);

  const {
    data: creatingVideoData,
    isLoading: creatingVideofile,
    doFetch: fetchtoCreateVideoData,
  } = useFetch(null);

  const {
    data: creatingAssignmentdata,
    isLoading: creatingAssignmentFile,
    doFetch: fetchtiCreateAssignment,
  } = useFetch(null);

  const {
    data: createTextData,
    isLoading: creatingtextData,
    doFetch: fetchToCreateTextData,
  } = useFetch(null);

  const {
    data: courseMcqSettings,
    isLoading: courseMcqSettingsLoading,
    doFetch: fetchToStoreMcqSettings,
  } = useFetch(null);

  const {
    data: mcqDetails,
    isLoading: gettingMcqDetails,
    doFetch: fetchtogetMcqDetails,
  } = useFetch(null);

  const {
    data: createMcqQuestionsAndAnswers,
    isLoading: creatingMcqQuestionAndAnswer,
    doFetch: fetchToCreateMcqQuestionAndAnswer,
  } = useFetch(null);

  const {
    data: deleteMcqQuestionAndAnswer,
    isLoading: deletingMcqQA,
    doFetch: fetchToDeleteMcqQA,
  } = useFetch(null);

  const {
    data: getmcqQuestionsList,
    isLoading: gettingQuestionsList,
    doFetch: fetchToGetQuestionsList,
  } = useFetch(null);

  const {
    data: UpdateMcqQuestionAndAnswer,
    isLoading: UpdatingMcqQuestion,
    doFetch: fetchToUpdateMcqQuestion,
  } = useFetch(null);

  const { data: QuestionTypeList, doFetch: FetchQuestionsType } = useFetch(
    null
  );

  const {
    data: deleteContent,
    isLoading: deletingcontentData,
    doFetch: fetchToDeleteContentData,
  } = useFetch(null);

  const {
    data: UploadPPTfile,
    isLoading: UploadingPPTFile,
    doFetch: fetchToUploadPPTFile,
  } = useFetch(null);

  const {
    data: fileData,
    isLoading: fileLoading,
    doFetch: fetchFile,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);

  useEffect(() => {
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }, []);

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  useEffect(() => {
    if (courseMcqSettings) {
      setQuestionId(courseMcqSettings.question_paper);
      console.log("Question", courseMcqSettings.question_paper);
    }
  }, [courseMcqSettings]);

  useEffect(() => {
    if (topic) {
      const client =
        window.location.hostname === "localhost"
          ? mqtt.connect("ws://localhost:1883")
          : mqtt.connect("wss://udaansurelearning.com:9001");
      client.on("connect", () => {
        client.subscribe(topic, (err) => {
          console.log(err);
        });
      });
      client.on("message", (t, message) => {
        // message is Buffer
        setTimeout(() => setUploadProgress(message.toString()), 100);
      });
      if (Math.round(uploadProgress) === 100) {
        client.on("disconnect", () => {
          client.disconnect();
        });
      }
    }
  }, [topic]);

  function functiontoSaveChapterName(index, labelIndex) {
    if (
      !Coursedetails[index].chapter[labelIndex].title ||
      !Coursedetails[index].chapter[labelIndex].file
    ) {
      alert.warning("Enter the Chapter Name and upload Content Image");
      return;
    }
    const formData = new FormData();

    const match = Coursedetails[index]?.chapter[labelIndex]?.file.match(
      /\/media\/(.*?)\?/
    );
    const desiredString = match[1];
    console.log(desiredString);

    formData.append("course_content", Coursedetails[index].id);
    formData.append("sequencing", null);
    formData.append("title", Coursedetails[index].chapter[labelIndex].title);
    formData.append("gcp_path", desiredString);

    // formData.append("file", Coursedetails[index].chapter[labelIndex].file);
    formData.append("id", Coursedetails[index].chapter[labelIndex].id);

    fetchToUpdateChapterTitle({
      url: urls.createChapterApi,
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  useEffect(() => {
    if (
      auth ||
      deleteContent ||
      UploadPPTfile ||
      AddCourseSection ||
      saveSectionTitle ||
      deleteSectionTitle ||
      CreateCourseChapterList ||
      updateChapterTitle ||
      deleteChapter ||
      createFileData ||
      creatingVideoData ||
      creatingAssignmentdata ||
      createTextData ||
      activeStep === 1
    ) {
      getStartEndData({
        url: `${urls.setStartandEndDateApiInCourseSchedule}?course_id=${userIdNo}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
      FetchIconImages({
        url: urls.ApiForCourseIcons,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
  }, [
    auth,
    UploadPPTfile,
    deleteContent,
    AddCourseSection,
    saveSectionTitle,
    deleteSectionTitle,
    CreateCourseChapterList,
    updateChapterTitle,
    deleteChapter,
    createFileData,
    creatingVideoData,
    creatingAssignmentdata,
    createTextData,
    activeStep,
  ]);

  useEffect(() => {
    if (deleteContent) {
      alert.success("Content Deleted Successfully");
    }
  }, [deleteContent]);
  useEffect(() => {
    if (deleteChapter) {
      alert.success("Chapter Deleted Successfully");
    }
  }, [deleteChapter]);
  useEffect(() => {
    if (updateChapterTitle) {
      alert.success("Chapter Details Updated Successfully");
    }
  }, [updateChapterTitle]);

  useEffect(() => {
    if (CreateCourseChapterList) {
      alert.success("New Chapter Created Successfully");
    }
  }, [CreateCourseChapterList]);

  useEffect(() => {
    if (saveSectionTitle) {
      alert.success("section Updated Successfully");
    }
  }, [saveSectionTitle]);

  useEffect(() => {
    if (deleteSectionTitle) {
      alert.success("section Deleted Successfully");
    }
  }, [deleteSectionTitle]);

  useEffect(() => {
    if (AddCourseSection) {
      alert.success("Course Section Added Successfully");
      setCourseSection("");
    }
  }, [AddCourseSection]);

  function addExtraDetail() {
    setCourseSection((oldData) => [
      ...oldData,
      { title: null, content_duration: "" },
    ]);
  }

  useEffect(() => {
    if (getStartEndDate) {
      // setStartDate(getStartEndDate.start_date);
      // setEndDate(getStartEndDate.end_date);
      setcoursedetails(getStartEndDate.section);
      setCourseDuration(getStartEndDate.is_course_duration);
      setCourseInduction(getStartEndDate.is_induction_training);
      if (Coursedetails.length === 0) {
        addExtraDetail();
      }
    }
  }, [getStartEndDate]);

  useEffect(() => {
    if (createFileData) {
      alert.success("file Crreated Successfully");
      setIndexIds([
        {
          key1: "",
          index: "",
          lebelIndex: "",
          key2: "",
          index2: "",
        },
      ]);
      setFileDataInfo([
        {
          fileData: [],
          title: null,
          description: null,
          activityConfirm: null,
          allowDownload: null,
          is_visible_to_principal: false,
          is_visible_to_teacher: false,
          is_visible_to_trainee_while_training: false,
          ppt_page_links: [],
        },
      ]);
      setOpen(false);
      setEdit(false);
      setContentId("");
    }
  }, [createFileData]);

  useEffect(() => {
    if (UploadPPTfile) {
      setIndexIds([
        {
          key1: "",
          index: "",
          lebelIndex: "",
          key2: "",
          index2: "",
        },
      ]);
      setPPTFileInfo([
        {
          allowDownload: false,
          compalsary: false,
          is_visible_to_principal: false,
          is_visible_to_teacher: false,
          is_visible_to_trainee_while_training: false,
        },
      ]);
      setOpenPPT(false);
      setEdit(false);
      setContentId("");
    }
  }, [UploadPPTfile]);

  useEffect(() => {
    if (creatingVideoData) {
      alert.success("video Created Successfully");
      setIndexIds([
        {
          key1: "",
          index: "",
          lebelIndex: "",
          key2: "",
          index2: "",
        },
      ]);
      setVideoDataInfo([
        {
          videoData: [],
          videoTitle: null,
          videoDescription: null,
          confirmActivity: null,
          is_visible_to_principal: false,
          is_visible_to_teacher: false,
          is_visible_to_trainee_while_training: false,
        },
      ]);
      setOpenVideo(false);
      setViewOpen(false);
      setEdit(false);
      setContentId("");
      setTopic(null);
      setUploadProgress(0);
      setUploading(false);
    }
  }, [creatingVideoData]);

  useEffect(() => {
    if (creatingAssignmentdata) {
      alert.success("assessment Created Successfully");
      setIndexIds([
        {
          key1: "",
          index: "",
          lebelIndex: "",
          key2: "",
          index2: "",
        },
      ]);
      setAssignmentDataInfo([
        {
          assignmentFiles: [],
          assignmentTitle: "",
          is_download: false,
          assignmentDecription: null,
          confirmActivity: null,
          submitInTime: null,
          is_assessment_due_date: null,
          is_assessment_due_day: null,
          is_visible_to_principal: false,
          is_visible_to_teacher: false,
          is_visible_to_trainee_while_training: false,
        },
      ]);
      setOpenAssignment(false);
      setEdit(false);
      setContentId("");
    }
  }, [creatingAssignmentdata]);

  useEffect(() => {
    if (createTextData) {
      alert.success("Test Date Created Successfully");
      setIndexIds([
        {
          key1: "",
          index: "",
          lebelIndex: "",
          key2: "",
          index2: "",
        },
      ]);
      setTextDataInfo([
        {
          textTitle: [],
          textDocument: "",
          is_visible_to_principal: false,
          is_visible_to_teacher: false,
          is_visible_to_trainee_while_training: false,
        },
      ]);
      setOpenText(false);
      setEdit(false);
      setContentId("");
    }
  }, [createTextData]);

  useEffect(() => {
    if (courseMcqSettings) {
      setActiveStep(1);
      // (mcqDetails && mcqDetails.id && parseInt(mcqDetails && mcqDetails.id, 10)) ||
      fetchToGetQuestionsList({
        url: `${
          urls.apiForToCreateMcqQuestionAndAnswer
        }?course_video_wise=${courseMcqSettings &&
          courseMcqSettings.question_paper &&
          parseInt(courseMcqSettings && courseMcqSettings.question_paper, 10)}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
  }, [
    courseMcqSettings,
    UpdateMcqQuestionAndAnswer,
    createMcqQuestionsAndAnswers,
    deleteMcqQuestionAndAnswer,
  ]);

  useEffect(() => {
    if (
      UpdateMcqQuestionAndAnswer ||
      createMcqQuestionsAndAnswers ||
      deleteMcqQuestionAndAnswer
    ) {
      setmcqAddQAModle(true);
    }
  }, [
    createMcqQuestionsAndAnswers,
    UpdateMcqQuestionAndAnswer,
    deleteMcqQuestionAndAnswer,
  ]);

  useEffect(() => {
    if (mcqDetails) {
      setOpenMcq(true);
    }
  }, [mcqDetails]);

  function functionToCreateSection() {
    if (!courseSection[0].title) {
      alert.warning("Enter the Title");
      return;
    }
    if (courseDuration && courseSection[0].content_duration === "") {
      alert.warning("Enter the Chapter Duration");
      return;
    }
    const data = {
      title: courseSection[0].title,
      course_fk: userIdNo,
      sequencing: null,
    };
    if (courseDuration) {
      data.content_duration =
        courseSection[0].content_duration &&
        courseSection[0].content_duration.length === 5
          ? `${courseSection[0].content_duration}:00`
          : courseSection[0].content_duration;
    }
    fetchtoAddCourseScection({
      url: urls.addSectionApi,
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  let loader = null;
  if (
    gettingIconImages ||
    gettingStartAndEndDate ||
    addingCourseScection ||
    savingSectionTitle ||
    deleatingSeactionTitle ||
    CreatingCourseChapterList ||
    updatingChapterTitle ||
    deleatingChapter ||
    creatingFileData ||
    creatingAssignmentFile ||
    creatingtextData ||
    courseMcqSettingsLoading ||
    creatingMcqQuestionAndAnswer ||
    gettingMcqDetails ||
    fileLoading ||
    gettingQuestionsList ||
    UpdatingMcqQuestion ||
    deletingcontentData ||
    UploadingPPTFile ||
    deletingMcqQA ||
    (typeof videoDataInfo[0].videoData === "string" && creatingVideofile)
  ) {
    loader = <Loader open />;
  }

  function functionToCreateChapter(index, labelIndex) {
    // console.log(Coursedetails[index].id,'ddddddddd')
    if (
      !Coursedetails[index].chapter[labelIndex].title ||
      !Coursedetails[index].chapter[labelIndex].file
    ) {
      alert.warning("Enter the Chapter Name and Upload Content Image");
      return;
    }
    const formData = new FormData();

    const match = Coursedetails[index]?.chapter[labelIndex]?.file.match(
      /\/media\/(.*?)\?/
    );
    const desiredString = match[1];
    console.log(desiredString);

    formData.append("course_content", Coursedetails[index].id);
    formData.append("sequencing", null);
    formData.append("title", Coursedetails[index].chapter[labelIndex].title);
    // formData.append("file", Coursedetails[index].chapter[labelIndex].file);
    formData.append("gcp_path", desiredString);

    fetchToCreateChapterList({
      url: urls.createChapterApi,
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function addExtraLable(key, index) {
    setcoursedetails((previousData) => {
      previousData[index][key].push({ title: null, content: null, file: null });
      return [...previousData];
    });
  }

  function functionToAddContant(index, labelIndex, key, secondKey) {
    setcoursedetails((previousData) => {
      previousData[index][key][labelIndex][secondKey].push({
        content_type: null,
        content: null,
      });
      return [...previousData];
    });
  }

  const activities = [
    { id: 1, activityName: "File" },
    { id: 2, activityName: "Video" },
    { id: 3, activityName: "Assignment" },
    { id: 4, activityName: "Text" },
    { id: 5, activityName: "McqTest" },
    { id: 6, activityName: "Branch Wise Video" },
  ];

  function DeleteLableFunction(index, labelIndex) {
    setcoursedetails((previousData) => {
      previousData[index].chapter.splice(labelIndex, 1);
      return [...previousData];
    });
  }

  function functionToDeleteChapter(index, labelIndex) {
    // console.log(Coursedetails[index].chapter[labelIndex].id,'ddddddd')
    fetchToDeleteChapter({
      url: `${urls.createChapterApi}?id=${Coursedetails[index].chapter[labelIndex].id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function DeleteObjectFunction(index) {
    fetchToDeleteSectionTitle({
      url: `${urls.addSectionApi}?id=${Coursedetails[index].id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function functiontoSavetheSectionTitle(index) {
    if (!Coursedetails[index].title) {
      alert.warning("Fill the Title");
      return;
    }
    if (courseDuration && Coursedetails[0].content_duration === "") {
      alert.warning("Enter the Chapter Duration");
      return;
    }
    const data = {
      title: Coursedetails[index].title,
      course_fk: userIdNo,
      id: Coursedetails[index].id,
      sequencing: null,
    };
    if (courseDuration) {
      data.content_duration =
        Coursedetails[index].content_duration &&
        Coursedetails[index].content_duration.length === 5
          ? `${Coursedetails[index].content_duration}:00`
          : Coursedetails[index].content_duration;
    }
    fetchtoSaveSectionTitle({
      url: `${urls.addSectionApi}`,
      method: "PUT",
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  const handleSectionTitle = (e, key, i) => {
    setCourseSection((data) => {
      const newData = [...data];
      switch (key) {
        case "title":
          newData[i][key] = e;
          return newData;
        case "content_duration":
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    });
  };

  const changeHandlerq = (e, key, i, id, Name, index2, secondKey) => {
    // console.log(e, key,i,id,Name,index2,secondKey,'dddddddddd')
    if (!Coursedetails.length) {
      alert.warning("No data available");
      return;
    }
    setcoursedetails((data) => {
      const newData = [...data];
      switch (key) {
        case "title":
          newData[i][key] = e;
          return newData;
        case "content_duration":
          newData[i][key] = e;
          return newData;
        case "file":
          newData[i][key] = e;
          return newData;
        case "chapter":
          if (index2 === undefined && secondKey === undefined) {
            newData[i][key][id][Name] = e;
          }
          if (index2 !== undefined && secondKey !== undefined) {
            newData[i][key][id][Name][index2][secondKey] = e;
          }
          return newData;
        default:
          return null;
      }
    });
  };
  function functionRemoveContent(key, index, lebelIndex, key2, index2) {
    setcoursedetails((previousData) => {
      previousData[index][key][lebelIndex][key2].splice(index2, 1);
      return [...previousData];
    });
  }

  function functionDeleteContent(key, index, lebelIndex, key2, index2) {
    // console.log(key,index,lebelIndex,key2,index2,'dddddddd dataaaaaaaaa')
    fetchToDeleteContentData({
      url: `${urls.createCourseContentApi}?id=${Coursedetails &&
        Coursedetails[index] &&
        Coursedetails[index][key][lebelIndex].content[index2].id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function functionEditUploadData(key, index, lebelIndex, key2, index2) {
    setEdit(true);
    setContentId(
      Coursedetails &&
        Coursedetails[index] &&
        Coursedetails[index][key][lebelIndex].content[index2].id
    );
    if (
      Coursedetails &&
      Coursedetails[index] &&
      Coursedetails[index][key][lebelIndex].content[index2].content_type ===
        "File"
    ) {
      const res = Coursedetails[index][key][lebelIndex].content[
        index2
      ].file.substring(
        Coursedetails[index][key][lebelIndex].content[index2].file.length - 3,
        Coursedetails[index][key][lebelIndex].content[index2].file.length
      );
      if (res === "zip") {
        setZipTrue(true);
      } else {
        setZipTrue(false);
      }
      fetchFile({
        url: Coursedetails[index][key][lebelIndex].content[index2].file,
        method: "get",
        type: "blob",
        headers: {
          // 'Content-Type': 'application/json',
          // Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });

      setFileDataInfo([
        {
          fileData:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].file,
          title:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].title,
          description:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].description,
          activityConfirm:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_compulsory,
          allowDownload:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_download,
          is_visible_to_principal:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_principal,
          is_visible_to_teacher:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_teacher,
          ppt_page_links:
            JSON.parse(
              Coursedetails &&
                Coursedetails[index] &&
                Coursedetails[index][key][lebelIndex].content[index2]
                  .ppt_page_links
            ) || [],
          is_visible_to_trainee_while_training:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_trainee_while_training,
        },
      ]);
      setIndexIds([
        {
          key1: key,
          index,
          lebelIndex,
          key2,
          index2,
        },
      ]);
      setOpen(true);
      setUploading(false);
    }
    if (
      Coursedetails &&
      Coursedetails[index] &&
      Coursedetails[index][key][lebelIndex].content[index2].content_type ===
        "Video"
    ) {
      setVideoDataInfo([
        {
          videoData:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].file,
          videoTitle:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].title,
          videoDescription:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].description,
          confirmActivity:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_compulsory,
          is_visible_to_principal:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_principal,
          is_visible_to_teacher:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_teacher,
          is_visible_to_trainee_while_training:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_trainee_while_training,
        },
      ]);
      setIndexIds([
        {
          key1: key,
          index,
          lebelIndex,
          key2,
          index2,
        },
      ]);
      setOpenVideo(true);
    }
    if (
      Coursedetails &&
      Coursedetails[index] &&
      Coursedetails[index][key][lebelIndex].content[index2].content_type ===
        "Assignment"
    ) {
      fetchFile({
        url: Coursedetails[index][key][lebelIndex].content[index2].file,
        method: "get",
        type: "blob",
        headers: {
          // 'Content-Type': 'application/json',
          // Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
      setAssignmentDataInfo([
        {
          assignmentFiles:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].file,
          assignmentTitle:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].title,
          assignmentDecription:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].description,
          confirmActivity:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_compulsory,
          is_visible_to_principal:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_principal,
          is_visible_to_teacher:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_teacher,
          is_visible_to_trainee_while_training:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_trainee_while_training,
          submitInTime:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[
              index2
            ].is_assessment_due.toString(),
          is_assessment_due_date:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_assessment_due_date,
          is_assessment_due_day:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_assessment_due_day,
          is_download:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_download,
        },
      ]);
      setIndexIds([
        {
          key1: key,
          index,
          lebelIndex,
          key2,
          index2,
        },
      ]);
      setOpenAssignment(true);
    }
    if (
      Coursedetails &&
      Coursedetails[index] &&
      Coursedetails[index][key][lebelIndex].content[index2].content_type ===
        "Text"
    ) {
      setTextDataInfo([
        {
          textTitle:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].title,
          textDocument:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].description,
          is_visible_to_principal:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_principal,
          is_visible_to_teacher:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_teacher,
          is_visible_to_trainee_while_training:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_trainee_while_training,
        },
      ]);
      setIndexIds([
        {
          key1: key,
          index,
          lebelIndex,
          key2,
          index2,
        },
      ]);
      setOpenText(true);
    }
    if (
      Coursedetails &&
      Coursedetails[index] &&
      Coursedetails[index][key][lebelIndex].content[index2].content_type ===
        "McqTest"
    ) {
      setIndexIds([
        {
          key1: key,
          index,
          lebelIndex,
          key2,
          index2,
        },
      ]);
      fetchtogetMcqDetails({
        url: `${
          urls.apiToGetCourseSettings
        }?question_paper_id=${Coursedetails &&
          Coursedetails[index] &&
          Coursedetails[index][key][lebelIndex].content[index2]
            .question_paper}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
      FetchQuestionsType({
        url: urls.apiToGetQuestionType,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
    if (
      Coursedetails &&
      Coursedetails[index] &&
      Coursedetails[index][key][lebelIndex].content[index2].content_type ===
        "Branch Wise Video"
    ) {
      setPPTFileInfo([
        {
          allowDownload:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_download,
          compalsary:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2].is_compulsory,
          is_visible_to_principal:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_principal,
          is_visible_to_teacher:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_teacher,
          is_visible_to_trainee_while_training:
            Coursedetails &&
            Coursedetails[index] &&
            Coursedetails[index][key][lebelIndex].content[index2]
              .is_visible_to_trainee_while_training,
        },
      ]);
      setOpenPPT(true);
      setIndexIds([
        {
          key1: key,
          index,
          lebelIndex,
          key2,
          index2,
        },
      ]);
    }
  }

  // Code for Upload File Modle start

  const handleClose = () => {
    setOpen(false);
    setIndexIds([
      {
        key1: null,
        index: null,
        lebelIndex: null,
        key2: null,
        index2: null,
      },
    ]);
    setFileDataInfo([
      {
        fileData: [],
        title: null,
        description: null,
        activityConfirm: null,
        allowDownload: null,
        is_visible_to_principal: false,
        is_visible_to_teacher: false,
        is_visible_to_trainee_while_training: false,
        ppt_page_links: [],
      },
    ]);
    setEdit(false);
    setContentId("");
  };

  const functionForFielUplaodData = (information, key) => {
    setFileDataInfo((data) => {
      const newData = [...data];
      switch (key) {
        case "fileData":
          newData[0][key] = information;
          return newData;
        case "title":
          newData[0][key] = information;
          return newData;
        case "description":
          newData[0][key] = information;
          return newData;
        case "allowDownload":
          newData[0][key] = information;
          return newData;
        case "activityConfirm":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_principal":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_teacher":
          newData[0][key] = information;
          return newData;
        case "ppt_page_links":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_trainee_while_training":
          newData[0][key] = information;
          return newData;
        default:
          return null;
      }
    });
  };

  function functionToSaveFileData() {
    if (fileDataInfo[0].fileData.length === 0 || !fileDataInfo[0].title) {
      alert.warning("Enter Title and browse File");
      return;
    }
    const RadNumber = Math.round(Math.random()) + userIdNo + 101;
    const formData = new FormData();
    formData.append("content_type", "File");
    formData.append("title", fileDataInfo[0].title);
    formData.append("description", fileDataInfo[0].description);
    if (typeof fileDataInfo[0].fileData !== "string") {
      formData.append("file", fileDataInfo[0].fileData[0]);
      formData.append("topic", RadNumber);
    }
    formData.append("is_download", fileDataInfo[0].allowDownload || false);
    formData.append("is_compulsory", fileDataInfo[0].activityConfirm || false);
    formData.append(
      "is_visible_to_trainee_while_training",
      fileDataInfo[0].is_visible_to_trainee_while_training || false
    );
    formData.append(
      "is_visible_to_principal",
      fileDataInfo[0].is_visible_to_principal
    );
    formData.append(
      "is_visible_to_teacher",
      fileDataInfo[0].is_visible_to_teacher
    );
    formData.append(
      "content_related_chapter",
      Coursedetails[parseInt(indexids[0].index, 10)].chapter[
        parseInt(indexids[0].lebelIndex, 10)
      ].id
    );
    formData.append(
      "ppt_page_links",
      JSON.stringify(fileDataInfo[0].ppt_page_links) || []
    );
    if (edit) {
      formData.append("id", contentId);
    }
    formData.append("sequencing", null);

    fetchtoSaveFileData({
      url: urls.createCourseContentApi,
      method: edit ? "PUT" : "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function functionToValidateFile(data) {
    // console.log(Object.values(data)[0].type, 'ddddddd');
    if (
      Object.values(data)[0].type === "application/pdf" ||
      Object.values(data)[0].type === "application/zip"
    ) {
      functionForFielUplaodData(data, "fileData");
    } else {
      alert.warning("Upload File in PDF format only");
    }
    // functionForFielUplaodData(data,'fileData')
  }

  function fuctionforOpenFileModle(key1, index, lebelIndex, key2, index2) {
    setIndexIds([
      {
        key1,
        index,
        lebelIndex,
        key2,
        index2,
      },
    ]);
    setOpen(true);
  }

  function getFileUrl() {
    let url;
    if (fileData && window && URL) {
      if (zipTrue === true) {
        url = window.URL.createObjectURL(
          new Blob([fileData], { type: "application/zip" })
        );
      } else {
        url = window.URL.createObjectURL(
          new Blob([fileData], { type: "application/pdf" })
        );
      }
    }
    return url;
  }

  function handleCloseLinkModel() {
    setOpenLinkModel(false);
  }
  function openLinkUploadModel() {
    setOpenLinkModel(true);
  }

  function saveLinksData(data) {
    functionForFielUplaodData(data, "ppt_page_links");
    setOpenLinkModel(false);
  }

  function UploadFileOpen() {
    let modal = null;
    modal = (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Paper className={classes.modalPaper}>
              <>
                <Grid container spacing={2}>
                  <Grid item md={11} xs={11}>
                    <Typography variant="h4">
                      {edit ? "Edit File" : "Upload File"}
                    </Typography>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <IconButton onClick={() => handleClose()}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <input
                      style={{ display: "none" }}
                      className={classes.fileUpload}
                      id="outlined-button-file"
                      type="file"
                      onChange={(e) => functionToValidateFile(e.target.files)}
                    />
                    <label htmlFor="outlined-button-file">
                      <b style={{ fontFamily: "" }}>Upload File</b>
                      &nbsp;&nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.fileUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Browse
                      </Button>{" "}
                      &nbsp;&nbsp;
                      {(typeof fileDataInfo[0].fileData === "string" &&
                        edit &&
                        getFileUrl() && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getFileUrl()}
                          >
                            click to View
                          </a>
                        )) ||
                        Object.values(fileDataInfo[0].fileData).map(
                          (item, index) => (
                            <b key={index}>
                              {item.name}
                              &nbsp;&nbsp;
                            </b>
                          )
                        )}
                    </label>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      margin="dense"
                      label="File Name"
                      required
                      value={fileDataInfo[0].title || ""}
                      onChange={(e) =>
                        functionForFielUplaodData(e.target.value, "title")
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      margin="dense"
                      label="Description"
                      value={fileDataInfo[0].description || ""}
                      onChange={(e) =>
                        functionForFielUplaodData(e.target.value, "description")
                      }
                      multiline
                      rows="4"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Checkbox
                    checked={fileDataInfo[0].allowDownload || false}
                    onChange={(e) =>
                      functionForFielUplaodData(
                        e.target.checked,
                        "allowDownload"
                      )
                    }
                    value="primary"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />{" "}
                  Allow users to download the File
                </Grid>
                {courseInduction === false && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          fileDataInfo[0].is_visible_to_principal || false
                        }
                        onChange={(e) =>
                          functionForFielUplaodData(
                            e.target.checked,
                            "is_visible_to_principal"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      visible to trainer
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={fileDataInfo[0].is_visible_to_teacher || false}
                        onChange={(e) =>
                          functionForFielUplaodData(
                            e.target.checked,
                            "is_visible_to_teacher"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      visible to trainee
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          fileDataInfo[0]
                            .is_visible_to_trainee_while_training || false
                        }
                        onChange={(e) =>
                          functionForFielUplaodData(
                            e.target.checked,
                            "is_visible_to_trainee_while_training"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      is visible to trainer while training
                    </Grid>
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openLinkUploadModel()}
                >
                  {edit &&
                  fileDataInfo[0].ppt_page_links &&
                  fileDataInfo[0].ppt_page_links.length !== 0
                    ? "Update Pdf Links"
                    : "Upload Pdf Links"}
                </Button>
                &nbsp;
                {fileDataInfo[0].ppt_page_links &&
                  fileDataInfo[0].ppt_page_links.length !== 0 && (
                    <Button
                      onClick={() => openLinkUploadModel()}
                      style={{ color: "blue" }}
                    >
                      View All Links
                    </Button>
                  )}
                <AddLinksModel
                  key={1}
                  open={openLinkModel}
                  handleClose={handleCloseLinkModel}
                  updataList={edit}
                  saveDataFunc={saveLinksData}
                  linkList={fileDataInfo[0].ppt_page_links}
                />
                <Divider className={classes.divider} />
                <Grid item md={4} />
                <Grid item md={2} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.textField}
                    onClick={() => functionToSaveFileData()}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            </Paper>
          </Fade>
        </Modal>
      </>
    );
    return modal;
  }
  // End modle File modle

  // VIDEO MODLE CODE

  function functionToSaveVideoData() {
    if (
      videoDataInfo[0].videoData.length === 0 ||
      !videoDataInfo[0].videoTitle
    ) {
      alert.warning("Fill all the Fields");
      return;
    }
    setUploading(true);
    const RadNO = Math.round(Math.random()) + userIdNo + 101;
    setTopic(RadNO);
    const formData = new FormData();
    formData.append("content_type", "Video");
    formData.append("title", videoDataInfo[0].videoTitle);
    formData.append("description", videoDataInfo[0].videoDescription);
    formData.append("is_compulsory", videoDataInfo[0].confirmActivity || false);
    formData.append(
      "is_visible_to_principal",
      videoDataInfo[0].is_visible_to_principal || false
    );
    formData.append(
      "is_visible_to_teacher",
      videoDataInfo[0].is_visible_to_teacher || false
    );
    formData.append(
      "is_visible_to_trainee_while_training",
      videoDataInfo[0].is_visible_to_trainee_while_training || false
    );
    if (typeof videoDataInfo[0].videoData !== "string") {
      formData.append("file", videoDataInfo[0].videoData[0]);
      formData.append("topic", RadNO);
    }
    formData.append(
      "content_related_chapter",
      Coursedetails[parseInt(indexids[0].index, 10)].chapter[
        parseInt(indexids[0].lebelIndex, 10)
      ].id
    );
    if (edit) {
      formData.append("id", contentId);
    }
    formData.append("sequencing", null);

    fetchtoCreateVideoData({
      url: urls.createCourseContentApi,
      method: edit ? "PUT" : "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  const handleCloseVideo = () => {
    setIndexIds([
      {
        key1: null,
        index: null,
        lebelIndex: null,
        key2: null,
        index2: null,
      },
    ]);
    setVideoDataInfo([
      {
        videoData: [],
        videoTitle: null,
        videoDescription: null,
        confirmActivity: null,
        is_visible_to_principal: false,
        is_visible_to_teacher: false,
        is_visible_to_trainee_while_training: false,
      },
    ]);
    setOpenVideo(false);
    setEdit(false);
    setContentId("");
    setViewOpen(false);
    setUploading(false);
  };

  function functionforOpenVideoModle(key1, index, lebelIndex, key2, index2) {
    setOpenVideo(true);
    setIndexIds([
      {
        key1,
        index,
        lebelIndex,
        key2,
        index2,
      },
    ]);
  }

  const functionForVideoUplaodData = (information, key) => {
    setVideoDataInfo((data) => {
      const newData = [...data];
      switch (key) {
        case "videoData":
          newData[0][key] = information;
          return newData;
        case "videoTitle":
          newData[0][key] = information;
          return newData;
        case "videoDescription":
          newData[0][key] = information;
          return newData;
        case "confirmActivity":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_principal":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_teacher":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_trainee_while_training":
          newData[0][key] = information;
          return newData;
        default:
          return null;
      }
    });
  };

  function functionToValidateVideo(data) {
    if (Object.values(data)[0].type === "video/mp4") {
      functionForVideoUplaodData(data, "videoData");
    } else {
      alert.warning("Upload Video in MP4 Format");
    }
  }

  function UploadVideoOpen() {
    let modal = null;
    modal = (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openvideo}
          onClose={handleCloseVideo}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openvideo}>
            <Paper className={classes.modalPaper}>
              <>
                <Grid container spacing={2}>
                  <Grid item md={11} xs={11}>
                    <Typography variant="h4">
                      {edit ? "Edit Video" : "Upload Video"}
                    </Typography>
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <IconButton onClick={() => handleCloseVideo()}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <input
                      style={{ display: "none" }}
                      className={classes.fileUpload}
                      id="outlined-button-file"
                      type="file"
                      onChange={(e) => functionToValidateVideo(e.target.files)}
                    />
                    <label htmlFor="outlined-button-file">
                      <b style={{ fontFamily: "" }}>Upload Video</b>{" "}
                      &nbsp;&nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.fileUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Browse
                      </Button>{" "}
                      &nbsp;&nbsp;
                      {(typeof videoDataInfo[0].videoData === "string" &&
                        edit && (
                          <>
                            <Button
                              onClick={() => setViewOpen(true)}
                              size="small"
                            >
                              Click To View
                            </Button>
                          </>
                        )) ||
                        Object.values(videoDataInfo[0].videoData).map(
                          (item, index) => (
                            <b key={index}>
                              {item.name}
                              &nbsp;&nbsp;
                            </b>
                          )
                        )}
                    </label>

                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12}>
                        {viewOpen &&
                          videoDataInfo[0] &&
                          videoDataInfo[0].videoData &&
                          typeof videoDataInfo[0].videoData === "string" &&
                          edit && (
                            <video
                              controls
                              controlsList="nodownload"
                              autoPlay
                              src={
                                videoDataInfo[0] &&
                                videoDataInfo[0] &&
                                videoDataInfo[0].videoData
                              }
                              height="150"
                              width="200"
                            >
                              <track
                                src={
                                  videoDataInfo[0] &&
                                  videoDataInfo[0] &&
                                  videoDataInfo[0].videoData
                                }
                                kind="captions"
                                srcLang="en"
                                label="english_captions"
                              />
                            </video>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      margin="dense"
                      label="Video Name"
                      required
                      value={videoDataInfo[0].videoTitle || ""}
                      onChange={(e) =>
                        functionForVideoUplaodData(e.target.value, "videoTitle")
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      margin="dense"
                      label="Description"
                      value={videoDataInfo[0].videoDescription || ""}
                      onChange={(e) =>
                        functionForVideoUplaodData(
                          e.target.value,
                          "videoDescription"
                        )
                      }
                      multiline
                      rows="4"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {courseInduction === false && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          videoDataInfo[0].is_visible_to_principal || false
                        }
                        onChange={(e) =>
                          functionForVideoUplaodData(
                            e.target.checked,
                            "is_visible_to_principal"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      visible to trainer
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          videoDataInfo[0].is_visible_to_teacher || false
                        }
                        onChange={(e) =>
                          functionForVideoUplaodData(
                            e.target.checked,
                            "is_visible_to_teacher"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      visible to trainee
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          videoDataInfo[0]
                            .is_visible_to_trainee_while_training || false
                        }
                        onChange={(e) =>
                          functionForVideoUplaodData(
                            e.target.checked,
                            "is_visible_to_trainee_while_training"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      is visible to trainer while training
                    </Grid>
                  </>
                )}
                <Divider className={classes.divider} />
                <Grid item md={12} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.textField}
                    onClick={() => functionToSaveVideoData()}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    {Math.round(uploadProgress) < 100 &&
                      Math.round(uploadProgress) > 0 && (
                        <LinearProgress
                          variant="determinate"
                          color="primary"
                          value={uploadProgress}
                        />
                      )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ color: "blue", textAlign: "center" }}
                >
                  <Typography variant="h6">
                    {Math.round(uploadProgress) < 100 &&
                    Math.round(uploadProgress) > 0
                      ? `Uploading..( ${Math.round(uploadProgress * 100) /
                          100}% )`
                      : ""}
                    <b>
                      {Math.round(uploadProgress) === 100
                        ? " Video Uploaded Successfully"
                        : ""}
                    </b>
                  </Typography>
                </Grid>
                {uploading && (
                  <Typography>Uploading Started... Please Wait</Typography>
                )}
              </>
            </Paper>
          </Fade>
        </Modal>
      </>
    );
    return modal;
  }
  // VIDEO MODLE END

  // Assignment Modle Code Start

  const functionForAssignmentUplaodData = (information, key) => {
    // console.log(information,'dddddddd')
    setAssignmentDataInfo((data) => {
      const newData = [...data];
      switch (key) {
        case "assignmentFiles":
          newData[0][key] = information;
          return newData;
        case "assignmentTitle":
          newData[0][key] = information;
          return newData;
        case "assignmentDecription":
          newData[0][key] = information;
          return newData;
        case "confirmActivity":
          newData[0][key] = information;
          return newData;
        case "submitInTime":
          newData[0][key] = information;
          return newData;
        case "is_assessment_due_date":
          newData[0][key] = information;
          return newData;
        case "is_assessment_due_day":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_principal":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_teacher":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_trainee_while_training":
          newData[0][key] = information;
          return newData;
        case "is_download":
          newData[0][key] = information;
          return newData;
        default:
          return null;
      }
    });
  };

  const handleCloseAssignment = () => {
    setOpenAssignment(false);
    setIndexIds([
      {
        key1: null,
        index: null,
        lebelIndex: null,
        key2: null,
        index2: null,
      },
    ]);
    setAssignmentDataInfo([
      {
        assignmentFiles: [],
        assignmentTitle: null,
        assignmentDecription: null,
        confirmActivity: null,
        submitInTime: null,
        is_visible_to_principal: false,
        is_visible_to_teacher: false,
        is_visible_to_trainee_while_training: false,
        is_download: false,
      },
    ]);
    setEdit(false);
    setContentId("");
  };

  function functionforOpenAssignmentModle(
    key1,
    index,
    lebelIndex,
    key2,
    index2
  ) {
    setOpenAssignment(true);
    setIndexIds([
      {
        key1,
        index,
        lebelIndex,
        key2,
        index2,
      },
    ]);
  }

  function functionToSaveAssignmantData() {
    if (
      assignmentDataInfo[0].assignmentFiles.length === 0 ||
      !assignmentDataInfo[0].assignmentTitle
    ) {
      alert.warning("fill all the fields");
      return;
    }
    if (assignmentDataInfo[0].submitInTime === "1") {
      if (!assignmentDataInfo[0].is_assessment_due_date) {
        alert.warning("enter due date");
        return;
      }
    }
    if (assignmentDataInfo[0].submitInTime === 2) {
      if (!assignmentDataInfo[0].is_assessment_due_day) {
        alert.warning("enter due days");
        return;
      }
    }
    const RadNumberAssessment = Math.round(Math.random()) + userIdNo + 101;
    const formData = new FormData();
    formData.append("content_type", "Assignment");
    formData.append("title", assignmentDataInfo[0].assignmentTitle);
    formData.append("is_download", assignmentDataInfo[0].is_download);
    formData.append("description", assignmentDataInfo[0].assignmentDecription);
    formData.append(
      "is_compulsory",
      assignmentDataInfo[0].confirmActivity || false
    );
    formData.append("is_assessment_due", assignmentDataInfo[0].submitInTime);
    formData.append(
      "is_visible_to_principal",
      assignmentDataInfo[0].is_visible_to_principal || false
    );
    formData.append(
      "is_visible_to_trainee_while_training",
      assignmentDataInfo[0].is_visible_to_trainee_while_training || false
    );
    formData.append(
      "is_visible_to_teacher",
      assignmentDataInfo[0].is_visible_to_teacher || false
    );
    if (typeof assignmentDataInfo[0].assignmentFiles !== "string") {
      formData.append("file", assignmentDataInfo[0].assignmentFiles[0]);
      formData.append("topic", RadNumberAssessment);
    }
    if (assignmentDataInfo[0].submitInTime === "1") {
      formData.append(
        "is_assessment_due_date",
        assignmentDataInfo[0].is_assessment_due_date
      );
    }
    if (assignmentDataInfo[0].submitInTime === "2") {
      formData.append(
        "is_assessment_due_day",
        assignmentDataInfo[0].is_assessment_due_day
      );
    }
    formData.append(
      "content_related_chapter",
      Coursedetails[parseInt(indexids[0].index, 10)].chapter[
        parseInt(indexids[0].lebelIndex, 10)
      ].id
    );
    if (edit) {
      formData.append("id", contentId);
    }
    formData.append("sequencing", null);

    fetchtiCreateAssignment({
      url: urls.createCourseContentApi,
      method: edit ? "PUT" : "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function functionToValidateAssessment(data) {
    // console.log(Object.values(data)[0].type, 'ddddddd');
    if (Object.values(data)[0].type === "application/pdf") {
      functionForAssignmentUplaodData(data, "assignmentFiles");
    } else {
      alert.warning("Upload File in PDF format only");
    }
  }

  function UploadAssignmentOpen() {
    let modal = null;
    modal = (
      <>
        <Dialog
          disableEnforceFocus
          maxWidth="xl"
          open={openAssignment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.mcqmodal}
        >
          <DialogTitle id="alert-dialog-title" onClose={handleCloseAssignment}>
            {edit ? "Edit Assignment" : "Upload Assignment"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <input
                  style={{ display: "none" }}
                  className={classes.fileUpload}
                  id="outlined-button-file"
                  type="file"
                  onChange={(e) => functionToValidateAssessment(e.target.files)}
                />
                <label htmlFor="outlined-button-file">
                  <b style={{ fontFamily: "" }}>Upload File</b>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.fileUpload}
                    startIcon={<CloudUploadIcon />}
                  >
                    Browse
                  </Button>
                  &nbsp;&nbsp;
                  {(typeof assignmentDataInfo[0].assignmentFiles === "string" &&
                    edit &&
                    getFileUrl() && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getFileUrl()}
                      >
                        click to View
                      </a>
                    )) ||
                    Object.values(assignmentDataInfo[0].assignmentFiles).map(
                      (item, index) => (
                        <b key={index}>
                          {item.name}
                          &nbsp;&nbsp;
                        </b>
                      )
                    )}
                </label>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  label="Assignment Title"
                  required
                  value={assignmentDataInfo[0].assignmentTitle || ""}
                  onChange={(e) =>
                    functionForAssignmentUplaodData(
                      e.target.value,
                      "assignmentTitle"
                    )
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  label="Description"
                  value={assignmentDataInfo[0].assignmentDecription || ""}
                  onChange={(e) =>
                    functionForAssignmentUplaodData(
                      e.target.value,
                      "assignmentDecription"
                    )
                  }
                  multiline
                  rows="4"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Checkbox
                checked={assignmentDataInfo[0].is_download || false}
                onChange={(e) =>
                  functionForAssignmentUplaodData(
                    e.target.checked,
                    "is_download"
                  )
                }
                value="primary"
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />{" "}
              Allow users to download the File
            </Grid>
            {courseInduction === false && (
              <>
                <Grid item md={12} xs={12}>
                  <Checkbox
                    checked={
                      assignmentDataInfo[0].is_visible_to_principal || false
                    }
                    onChange={(e) =>
                      functionForAssignmentUplaodData(
                        e.target.checked,
                        "is_visible_to_principal"
                      )
                    }
                    value="primary"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />{" "}
                  visible to trainer
                </Grid>
                <Grid item md={12} xs={12}>
                  <Checkbox
                    checked={
                      assignmentDataInfo[0].is_visible_to_teacher || false
                    }
                    onChange={(e) =>
                      functionForAssignmentUplaodData(
                        e.target.checked,
                        "is_visible_to_teacher"
                      )
                    }
                    value="primary"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />{" "}
                  visible to trainee
                </Grid>
                <Grid item md={12} xs={12}>
                  <Checkbox
                    checked={
                      assignmentDataInfo[0]
                        .is_visible_to_trainee_while_training || false
                    }
                    onChange={(e) =>
                      functionForAssignmentUplaodData(
                        e.target.checked,
                        "is_visible_to_trainee_while_training"
                      )
                    }
                    value="primary"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />{" "}
                  is visible to trainer while training
                </Grid>
              </>
            )}
            <Grid item md={12} xs={12} className={classes.TypographyDate}>
              <Radio
                checked={assignmentDataInfo[0].submitInTime === "0"}
                onChange={(e) =>
                  functionForAssignmentUplaodData(
                    e.target.value,
                    "submitInTime"
                  )
                }
                value="0"
                name="radio-button-demo"
                color="primary"
                inputProps={{ "aria-label": "B" }}
              />{" "}
              Submit any time
            </Grid>
            <Grid item md={6} xs={6} className={classes.TypographyDate}>
              <Radio
                checked={assignmentDataInfo[0].submitInTime === "1"}
                onChange={(e) =>
                  functionForAssignmentUplaodData(
                    e.target.value,
                    "submitInTime"
                  )
                }
                value="1"
                color="primary"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />{" "}
              Submit on date&nbsp;&nbsp;
              {assignmentDataInfo[0].submitInTime === "1" && (
                <TextField
                  type="date"
                  helperText="Enter Submition Date"
                  variant="outlined"
                  margin="dense"
                  value={assignmentDataInfo[0].is_assessment_due_date}
                  onChange={(e) =>
                    functionForAssignmentUplaodData(
                      e.target.value,
                      "is_assessment_due_date"
                    )
                  }
                />
              )}
            </Grid>
            <Grid item md={12} xs={12} className={classes.TypographyDate}>
              <Radio
                checked={assignmentDataInfo[0].submitInTime === "2"}
                onChange={(e) =>
                  functionForAssignmentUplaodData(
                    e.target.value,
                    "submitInTime"
                  )
                }
                value="2"
                color="primary"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />{" "}
              Submission days&nbsp;&nbsp;
              {assignmentDataInfo[0].submitInTime === "2" && (
                <TextField
                  type="number"
                  label="Enter No of Days"
                  variant="outlined"
                  margin="dense"
                  value={assignmentDataInfo[0].is_assessment_due_day}
                  onChange={(e) =>
                    e.target.value.length > -1 &&
                    e.target.value.length < 4 &&
                    functionForAssignmentUplaodData(
                      e.target.value,
                      "is_assessment_due_day"
                    )
                  }
                />
              )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item md={2} xs={12}>
              <Button
                color="primary"
                variant="contained"
                className={classes.textField}
                onClick={() => functionToSaveAssignmantData()}
              >
                Save
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
    return modal;
  }
  // Assignment Modle End

  // Text Modle Code Start
  const functionForTextUplaodData = (information, key) => {
    setTextDataInfo((data) => {
      const newData = [...data];
      switch (key) {
        case "textTitle":
          newData[0][key] = information;
          return newData;
        case "textDocument":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_principal":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_teacher":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_trainee_while_training":
          newData[0][key] = information;
          return newData;
        default:
          return null;
      }
    });
  };

  const handleCloseText = () => {
    setOpenText(false);
    setIndexIds([
      {
        key1: null,
        index: null,
        lebelIndex: null,
        key2: null,
        index2: null,
      },
    ]);
    setTextDataInfo([
      {
        textTitle: [],
        textDocument: null,
        is_visible_to_principal: false,
        is_visible_to_teacher: false,
        is_visible_to_trainee_while_training: false,
      },
    ]);
    setEdit(false);
    setContentId("");
  };

  function functionforOpenTextModle(key1, index, lebelIndex, key2, index2) {
    setOpenText(true);
    setIndexIds([
      {
        key1,
        index,
        lebelIndex,
        key2,
        index2,
      },
    ]);
  }

  function functionToSaveTextData() {
    if (!textDataInfo[0].textTitle || !textDataInfo[0].textDocument) {
      alert.warning("Fill all the fields");
      return;
    }
    const formData = new FormData();
    formData.append("content_type", "Text");
    formData.append("title", textDataInfo[0].textTitle);
    formData.append("description", textDataInfo[0].textDocument);
    formData.append(
      "is_visible_to_principal",
      textDataInfo[0].is_visible_to_principal || false
    );
    formData.append(
      "is_visible_to_teacher",
      textDataInfo[0].is_visible_to_teacher || false
    );
    formData.append(
      "is_visible_to_trainee_while_training",
      textDataInfo[0].is_visible_to_trainee_while_training || false
    );
    formData.append(
      "content_related_chapter",
      Coursedetails[parseInt(indexids[0].index, 10)].chapter[
        parseInt(indexids[0].lebelIndex, 10)
      ].id
    );
    if (edit) {
      formData.append("id", contentId);
    }
    formData.append("sequencing", null);

    fetchToCreateTextData({
      url: urls.createCourseContentApi,
      method: edit ? "PUT" : "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function UploadTextOpen() {
    let modal = null;
    modal = (
      <>
        <Modal
          disableEnforceFocus
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openText}
          onClose={handleCloseText}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper
            className={classes.modalPaper}
            style={{ width: "80%", maxHeight: 500, overflow: "auto" }}
          >
            <>
              <Grid container spacing={2}>
                <Grid item md={11} xs={11}>
                  <Typography variant="h4">
                    {edit ? "Edit Text" : "Upload Text"}
                  </Typography>
                </Grid>
                <Grid item md={1} xs={1}>
                  <IconButton onClick={() => handleCloseText()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TextField
                    className={classes.textField}
                    margin="dense"
                    label="Text Title"
                    required
                    value={textDataInfo[0].textTitle || ""}
                    onChange={(e) =>
                      functionForTextUplaodData(e.target.value, "textTitle")
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">Enter Your Text : </Typography>
                  <TinyTextEditor
                    id="testData"
                    initialValue={textDataInfo[0].textDocument || ""}
                    value={textDataInfo[0].textDocument || ""}
                    onChange={(content) =>
                      functionForTextUplaodData(content, "textDocument")
                    }
                  />
                </Grid>
                {courseInduction === false && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          textDataInfo[0].is_visible_to_principal || false
                        }
                        onChange={(e) =>
                          functionForTextUplaodData(
                            e.target.checked,
                            "is_visible_to_principal"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      visible to trainer
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={textDataInfo[0].is_visible_to_teacher || false}
                        onChange={(e) =>
                          functionForTextUplaodData(
                            e.target.checked,
                            "is_visible_to_teacher"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      visible to trainee
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Checkbox
                        checked={
                          textDataInfo[0]
                            .is_visible_to_trainee_while_training || false
                        }
                        onChange={(e) =>
                          functionForTextUplaodData(
                            e.target.checked,
                            "is_visible_to_trainee_while_training"
                          )
                        }
                        value="primary"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />{" "}
                      is visible to trainer while training
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider className={classes.divider} />
              <Grid item md={4} />
              <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.textField}
                  onClick={() => functionToSaveTextData()}
                >
                  Save
                </Button>
              </Grid>
            </>
          </Paper>
        </Modal>
      </>
    );
    return modal;
  }
  // TEXT Modle End

  // Mcq Modle Code

  function functionforOpenMcqModle(key1, index, lebelIndex, key2, index2) {
    setOpenMcq(true);
    setEdit(false);
    setIndexIds([
      {
        key1,
        index,
        lebelIndex,
        key2,
        index2,
      },
    ]);
    FetchQuestionsType({
      url: urls.apiToGetQuestionType,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }
  const handleCloseMcq = () => {
    setOpenMcq(false);
    setActiveStep(0);
    setIndexIds([
      {
        key1: null,
        index: null,
        lebelIndex: null,
        key2: null,
        index2: null,
      },
    ]);
    setEdit(false);
    setContentId("");
  };

  function functionToSaveMcqQuestions(
    mcqQuestionType,
    mcqQuestionAnswers,
    answerforSingleChoice,
    answerForMultipleChoise,
    marksFortheQuestion,
    negativeMarks,
    negativeYesorNo,
    QuestionType,
    editQuestion,
    questionId,
    matrixQuestion,
    choiceArray,
    mcqActualMarksBool,
    synonymsQuestion,
    synonymsArray,
    synonymsAnswerArray,
    matchQandA,
    MatchQuestion
  ) {
    // console.log('b++',"Image: " , mcqImage,"mcqQuestionType", mcqQuestionType, "mcqQuestionAnswer : ", mcqQuestionAnswers, "answerforSingleChoice: " ,answerforSingleChoice,"answerForMultipleChoice ",  answerForMultipleChoise, "marksFortheQuestion", marksFortheQuestion,"negMarks ", negativeMarks, "negYesorNo ",negativeYesorNo, "questionType", QuestionType, "editQuestion" , editQuestion, "questionId", questionId, "matrixQuestion" ,matrixQuestion, "choiceArray", choiceArray, "mcqActualMarksBool" , mcqActualMarksBool, "synonymsQuestion", synonymsQuestion, "synonymsArray", synonymsArray, "synonysAnswerArray " ,synonymsAnswerArray, "matchAandA ", matchQandA, "MatchQuestion" ,MatchQuestion)
    // console.log("mcqQuestionType: "+ mcqQuestionType, "mcqQuestionAnswer: "+ mcqQuestionAnswers,"answerForSingleChoice: "+ answerforSingleChoice, "answerForMultipleChoice: " + answerForMultipleChoise, "marksFortheQuestion: "+marksFortheQuestion, "negativeMarks: " + negativeMarks, "negativeYesorNO: " + negativeYesorNo, "QuestionType: " + QuestionType, "editQuestion: " + editQuestion, "questionId: "+ questionId, "matrixQuestion: "+ matrixQuestion, "choiceArray: " + choiceArray, "mcqActualMarksBool: " + mcqActualMarksBool, "synonymsQuestion: " + synonymsQuestion,"synonymsAray: " + synonymsArray, "synonymsAnswerArray: " + synonymsAnswerArray, "matchQandA: " + matchQandA,"MatchQuestion: " +  MatchQuestion)
    // if(!mcqImage) {
    //   // console.log(mcqImage)
    //   alert.warning('Please Upload Image');
    //   return;
    // }
    if (!mcqQuestionType) {
      alert.warning("Enter Question");
      return;
    }
    if (negativeYesorNo) {
      if (!negativeMarks) {
        alert.warning("Enter Negative Marks");
        return;
      }
    }
    if (
      mcqActualMarksBool === false &&
      !marksFortheQuestion &&
      QuestionTypeList &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0] &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0].question_type_name !== "Match The Following"
    ) {
      alert.warning("Please Enter Actual Marks");
      return;
    }
    if (
      QuestionTypeList &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0] &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0].question_type_name === "Synonyms"
    ) {
      if (
        (synonymsArray && synonymsArray.length === 0) ||
        (synonymsArray &&
          synonymsArray.filter((item) => item.synonyms === null).length !== 0)
      ) {
        alert.warning("Create and Fill the Words");
        return;
      }
      if (!synonymsQuestion) {
        alert.warning("Enter Question");
        return;
      }
      if (synonymsAnswerArray && synonymsAnswerArray.length === 0) {
        alert.warning("Choose Correct Answer");
        return;
      }
      if (!marksFortheQuestion) {
        alert.warning("Please Enter Actual Marks");
        return;
      }
    }
    if (
      QuestionTypeList &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0] &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0].question_type_name === "Single Choice"
    ) {
      if (!answerforSingleChoice) {
        alert.warning("Choose Correct Answer");
        return;
      }
    }
    if (
      QuestionTypeList &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0] &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0].question_type_name === "Multiple Choice"
    ) {
      if (answerForMultipleChoise.length === 0) {
        alert.warning("Choose Correct Answer");
        return;
      }
    }
    if (
      QuestionTypeList &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0] &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0].question_type_name === "Choice Table Matrix"
    ) {
      if (
        (choiceArray &&
          choiceArray.filter((item) => item.correctAnswer === null).length) !==
          0 ||
        !matrixQuestion
      ) {
        alert.warning("Choose Correct Answer");
        return;
      }
    }
    if (
      QuestionTypeList &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0] &&
      QuestionTypeList.filter(
        (item) => item.id === parseInt(mcqQuestionType, 10)
      )[0].question_type_name === "Match The Following"
    ) {
      if (!MatchQuestion) {
        alert.warning("Enter Question");
        return;
      }
      if (
        (matchQandA &&
          matchQandA.filter((item) => item.question === "").length) !== 0 ||
        (matchQandA &&
          matchQandA.filter((item) => item.answer === "").length) !== 0
      ) {
        alert.warning("fill the  Question and Answer field");
        return;
      }
    }

    function bodyData() {
      // console.log("Test",mcqImage)
      // let imageData=mcqImage
      // let fileData = JSON.stringify(mcqImage)
      // console.log("Image",mcqImage)
      // let form = new FormData();
      // form.append('file', mcqImage)
      // form.append('question', JSON.stringify(mcqQuestionAnswers))
      // form.append('correct_ans', JSON.stringify(answerforSingleChoice))
      // console.log(form)

      if (
        QuestionTypeList &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0] &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0].question_type_name === "Single Choice"
      ) {
        const data = {
          question_paper:
            (edit && mcqDetails && mcqDetails.id) ||
            (courseMcqSettings && courseMcqSettings.question_paper),
          question: mcqQuestionAnswers,
          type_question: mcqQuestionType,
          sequence: null,
          file: null,
          is_graded_question: QuestionType || false,
          is_negative_marking: negativeYesorNo || false,
          negative_marking_marks: negativeMarks || 0,
          actual_question_mark: marksFortheQuestion || 0,
          correct_ans: JSON.stringify(answerforSingleChoice),
        };
        return data;
      }
      if (
        QuestionTypeList &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0] &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0].question_type_name === "Multiple Choice"
      ) {
        const multiData = {
          question_paper:
            (edit && mcqDetails && mcqDetails.id) ||
            (courseMcqSettings && courseMcqSettings.question_paper),
          question: mcqQuestionAnswers,
          type_question: mcqQuestionType,
          sequence: null,
          file: null,
          is_graded_question: QuestionType || false,
          is_negative_marking: negativeYesorNo || false,
          negative_marking_marks: negativeMarks || 0,
          actual_question_mark: marksFortheQuestion || 0,
          correct_ans: JSON.stringify(answerForMultipleChoise),
        };
        return multiData;
      }
      if (
        QuestionTypeList &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0] &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0].question_type_name === "Choice Table Matrix"
      ) {
        const ChoiceMatix = {
          question_paper:
            (edit && mcqDetails && mcqDetails.id) ||
            (courseMcqSettings && courseMcqSettings.question_paper),
          question: choiceArray,
          type_question: mcqQuestionType,
          sequence: null,
          file: null,
          is_graded_question: QuestionType || false,
          is_negative_marking: negativeYesorNo || false,
          negative_marking_marks: negativeMarks || 0,
          actual_question_mark: marksFortheQuestion || 0,
          choice_table_matrix_question_title: matrixQuestion,
          correct_ans: "nan",
        };
        return ChoiceMatix;
      }
      if (
        QuestionTypeList &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0] &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0].question_type_name === "Synonyms"
      ) {
        const SynonymsMcq = {
          question_paper:
            (edit && mcqDetails && mcqDetails.id) ||
            (courseMcqSettings && courseMcqSettings.question_paper),
          question: synonymsArray,
          type_question: mcqQuestionType,
          sequence: null,
          file: null,
          is_graded_question: QuestionType || false,
          is_negative_marking: negativeYesorNo || false,
          negative_marking_marks: negativeMarks || 0,
          actual_question_mark: marksFortheQuestion || 0,
          choice_table_matrix_question_title: synonymsQuestion,
          correct_ans: JSON.stringify(synonymsAnswerArray),
        };
        return SynonymsMcq;
      }
      if (
        QuestionTypeList &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0] &&
        QuestionTypeList.filter(
          (item) => item.id === parseInt(mcqQuestionType, 10)
        )[0].question_type_name === "Match The Following"
      ) {
        const MatchTheFollowing = {
          question_paper:
            (edit && mcqDetails && mcqDetails.id) ||
            (courseMcqSettings && courseMcqSettings.question_paper),
          question: matchQandA,
          type_question: mcqQuestionType,
          sequence: null,
          file: null,
          is_graded_question: QuestionType || false,
          is_negative_marking: negativeYesorNo || false,
          negative_marking_marks: negativeMarks || 0,
          actual_question_mark: marksFortheQuestion || 0,
          choice_table_matrix_question_title: MatchQuestion,
          correct_ans: "nan",
        };
        return MatchTheFollowing;
      }
      return 0;
    }

    if (editQuestion === true) {
      setmcqAddQAModle(false);
      fetchToUpdateMcqQuestion({
        url: `${urls.apiToUpdateMcqQuestion}${questionId}/each_video_wise_mcq_question_detail/`,
        method: "PUT",
        body: bodyData(),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
    if (editQuestion === false) {
      // console.log('__BODY__',bodyData())
      // let form = new formData

      setmcqAddQAModle(false);
      fetchToCreateMcqQuestionAndAnswer({
        url: `${urls.apiForToCreateMcqQuestionAndAnswer}`,
        method: "POST",
        body: bodyData(),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
  }

  function functionToDeleteMcqQuestion(id) {
    fetchToDeleteMcqQA({
      url: `${urls.apiForDeleteMcqQuestionAndAnswer}${parseInt(
        id,
        10
      )}/each_video_wise_mcq_question_detail/`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function functionToSaveMcqSettings(
    mcqTitle,
    mcqDescription,
    mcqInstruction,
    mcqLimit,
    mcqLimitedTime,
    mcqPassingScore,
    randomQuestion,
    mcqCompulsoryActivity,
    mcqTestAnswers,
    mcqShuffleQuestion,
    mcqShuffleAnswerChoise,
    mcqNegitive,
    mcqNegaitiveMarks,
    mcqActualMarks,
    teachersVisibility,
    PrincipalVisibility,
    teacherTrineeVisibility,
    mcqActualMarksBool
  ) {
    if (!mcqTitle) {
      alert.warning("Enter Title");
      return;
    }
    if (mcqLimit === "limited") {
      if (!mcqLimitedTime) {
        alert.warning("Enter no of Min");
        return;
      }
    }
    if (mcqNegitive === true) {
      if (!mcqNegaitiveMarks) {
        alert.warning("Enter Negitive Marks");
        return;
      }
    }

    const data = {
      title: mcqTitle,
      description: mcqDescription,
      instruction: mcqInstruction,
      is_limit: (mcqLimit === "limited" && true) || false,
      duration: (mcqLimit === "limited" && mcqLimitedTime) || null,
      passing_score: mcqPassingScore || 0,
      is_compulsory_activity: mcqCompulsoryActivity || false,
      content_type: "McqTest",
      show_correct_answer: mcqTestAnswers === "yes",
      questions_shuffle: mcqShuffleQuestion || false,
      answer_choice_shuffle: mcqShuffleAnswerChoise || false,
      is_question_paper_negative_marking: mcqNegitive || false,
      question_paper_negative_marking_marks: mcqNegaitiveMarks || 0,
      is_question_paper_actual_marks: mcqActualMarksBool || false,
      no_of_ques: randomQuestion || 5,
      actual_marks: mcqActualMarksBool === true ? mcqActualMarks : 0,
      is_visible_to_teacher: teachersVisibility || false,
      is_visible_to_trainee_while_training: teacherTrineeVisibility || false,
      is_visible_to_principal: PrincipalVisibility || false,
      content_related_chapter:
        Coursedetails[parseInt(indexids[0].index, 10)].chapter[
          parseInt(indexids[0].lebelIndex, 10)
        ].id,
    };
    if (edit) {
      fetchToStoreMcqSettings({
        url: `${urls.apiForUpdateCourseSettings}${mcqDetails.id}/create_chapter_related_question_mapping/`,
        method: "PUT",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
    if (!edit) {
      fetchToStoreMcqSettings({
        url: `${urls.apiForCreateCourseSetttings}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
  }

  function handleBack() {
    setActiveStep(0);
    setEdit(true);
    if (courseMcqSettings && courseMcqSettings.question_paper) {
      fetchtogetMcqDetails({
        url: `${
          urls.apiToGetCourseSettings
        }?question_paper_id=${courseMcqSettings &&
          courseMcqSettings.question_paper}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Courses"),
        },
      });
    }
  }

  function UploadMcqOpen() {
    let modal = null;
    modal = (
      <Dialog
        disableEnforceFocus
        maxWidth="xl"
        open={openMcq}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.mcqmodal}
      >
        <DialogTitle id="alert-dialog-title" onClose={handleCloseMcq}>
          {edit ? "Edit Test" : "Create Test"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <CreateMcqTest
            createMcqQuestions={functionToSaveMcqQuestions}
            mcqSettings={functionToSaveMcqSettings}
            activeStepInfo={activeStep}
            settingsData={mcqDetails}
            editData={edit}
            handleBackStep={handleBack}
            questionsListinfo={getmcqQuestionsList}
            openToAddMcq={mcqAddQAModle}
            deleteMcqQA={functionToDeleteMcqQuestion}
            InductionCourse={courseInduction}
            TempquestionId={questionId}
          />
        </DialogContent>
      </Dialog>
    );
    return modal;
  }

  // Mcq Modle Code End

  const functionForPPTUplaodData = (information, key) => {
    setPPTFileInfo((data) => {
      const newData = [...data];
      switch (key) {
        case "allowDownload":
          newData[0][key] = information;
          return newData;
        case "compalsary":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_principal":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_teacher":
          newData[0][key] = information;
          return newData;
        case "is_visible_to_trainee_while_training":
          newData[0][key] = information;
          return newData;
        default:
          return null;
      }
    });
  };

  function SubmitPPTFileData() {
    const formData = new FormData();
    formData.append("content_type", "Branch Wise Video");
    formData.append("title", "Branch Wise Video");
    formData.append(
      "content_related_chapter",
      Coursedetails[parseInt(indexids[0].index, 10)].chapter[
        parseInt(indexids[0].lebelIndex, 10)
      ].id
    );
    if (edit) {
      formData.append("id", contentId);
    }
    formData.append("is_download", pptFileInfo[0].allowDownload || false);
    formData.append("is_compulsory", pptFileInfo[0].compalsary || false);
    formData.append(
      "is_visible_to_principal",
      pptFileInfo[0].is_visible_to_principal || false
    );
    formData.append(
      "is_visible_to_teacher",
      pptFileInfo[0].is_visible_to_teacher || false
    );
    formData.append(
      "is_visible_to_trainee_while_training",
      pptFileInfo[0].is_visible_to_trainee_while_training || false
    );
    formData.append("duration", "00:00:00");
    formData.append("sequencing", null);

    fetchToUploadPPTFile({
      url: urls.createCourseContentApi,
      method: edit ? "PUT" : "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Courses"),
      },
    });
  }

  function functionforOpenPPTModle(key1, index, lebelIndex, key2, index2) {
    setOpenPPT(true);
    setIndexIds([
      {
        key1,
        index,
        lebelIndex,
        key2,
        index2,
      },
    ]);
  }

  const handleClosePPT = () => {
    setOpenPPT(false);
    setIndexIds([
      {
        key1: null,
        index: null,
        lebelIndex: null,
        key2: null,
        index2: null,
      },
    ]);
    setPPTFileInfo([
      {
        allowDownload: false,
        compalsary: false,
        is_visible_to_principal: false,
        is_visible_to_teacher: false,
        is_visible_to_trainee_while_training: false,
      },
    ]);
    setEdit(false);
    setContentId("");
  };

  function UploadPPTFileOpen() {
    let modal = null;
    modal = (
      <>
        <Dialog
          maxWidth="xl"
          open={openPPT}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.mcqmodal}
        >
          <DialogTitle id="alert-dialog-title" onClose={handleClosePPT}>
            {edit ? "Edit Branch Wise Video" : "Add Branch Wise Video"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              {courseInduction === false && (
                <>
                  <Grid item md={12} xs={12}>
                    <Checkbox
                      checked={pptFileInfo[0].is_visible_to_principal || false}
                      onChange={(e) =>
                        functionForPPTUplaodData(
                          e.target.checked,
                          "is_visible_to_principal"
                        )
                      }
                      value="primary"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />{" "}
                    visible to trainer
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Checkbox
                      checked={pptFileInfo[0].is_visible_to_teacher || false}
                      onChange={(e) =>
                        functionForPPTUplaodData(
                          e.target.checked,
                          "is_visible_to_teacher"
                        )
                      }
                      value="primary"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />{" "}
                    visible to trainee
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Checkbox
                      checked={pptFileInfo[0].allowDownload || false}
                      onChange={(e) =>
                        functionForPPTUplaodData(
                          e.target.checked,
                          "allowDownload"
                        )
                      }
                      value="primary"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />{" "}
                    Allow users to download the File
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Checkbox
                      checked={
                        pptFileInfo[0].is_visible_to_trainee_while_training ||
                        false
                      }
                      onChange={(e) =>
                        functionForPPTUplaodData(
                          e.target.checked,
                          "is_visible_to_trainee_while_training"
                        )
                      }
                      value="primary"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />{" "}
                    is visible to trainer while training
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Button
                      className={classes.textField}
                      onClick={() => SubmitPPTFileData()}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
    return modal;
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.head}>
          Course Content
        </Typography>
      </Paper>
      <Divider className={classes.divider} />
      <Button
        color="primary"
        variant="contained"
        disabled={courseSection.length > 0}
        style={{ float: "right", marginTop: "15px" }}
        onClick={() => addExtraDetail()}
      >
        Add Details <AddIcon />
      </Button>
      <br />
      <br />
      <br />
      {courseSection &&
        courseSection.map((item, index) => (
          <Paper className={classes.paper} key={index}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  label="Chapter Title"
                  margin="dense"
                  multiline
                  required
                  value={item.title || ""}
                  onChange={(e) =>
                    handleSectionTitle(e.target.value, "title", index)
                  }
                  variant="outlined"
                />
              </Grid>
              {courseDuration && (
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    type="time"
                    helperText="Enter Chapter Duration"
                    margin="dense"
                    required
                    value={item.content_duration || ""}
                    onChange={(e) =>
                      handleSectionTitle(
                        e.target.value,
                        "content_duration",
                        index
                      )
                    }
                    variant="outlined"
                  />
                </Grid>
              )}
              {modulePermission && modulePermission.response.can_add ? (
                <Grid item md={2} xs={12}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "10px", fontSize: "10px" }}
                    onClick={() => functionToCreateSection()}
                  >
                    Create Chapter
                  </Button>
                </Grid>
              ) : null}
              <Grid item md={2} xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{ marginTop: "10px", fontSize: "10px" }}
                  onClick={() => setCourseSection("")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}
      {Coursedetails &&
        Coursedetails.map((item, index) => (
          <Paper className={classes.paper} key={index}>
            <Typography variant="h5" className={classes.TypographyDate}>
              Step
              {index + 1}
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={7} xs={12}>
                <TextField
                  className={classes.textField}
                  label="Chapter Title"
                  margin="dense"
                  required
                  multiline
                  value={item.title || ""}
                  onChange={(e) =>
                    changeHandlerq(e.target.value, "title", index)
                  }
                  variant="outlined"
                />
              </Grid>
              {courseDuration && (
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    type="time"
                    helperText="Chapter Duration"
                    margin="dense"
                    required
                    value={item.content_duration || ""}
                    onChange={(e) =>
                      changeHandlerq(e.target.value, "content_duration", index)
                    }
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item md={2} xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => functiontoSavetheSectionTitle(index)}
                >
                  Save Chapter Details
                </Button>
              </Grid>
              {/* {modulePermission.response.can_delete ? */}
              <Grid item md={1} xs={12}>
                <IconButton
                  size="small"
                  color="secondary"
                  className={classes.Deletebutton}
                  onClick={() => DeleteObjectFunction(index)}
                >
                  <DeleteIcon size="small" />
                </IconButton>
              </Grid>
              {item.chapter.length !== 0 &&
                item.chapter.map((data, lebelIndex) => (
                  <Box
                    border={1}
                    className={classes.answerPaper}
                    key={lebelIndex}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          className={classes.textField}
                          label="Content Title"
                          margin="dense"
                          required
                          multiline
                          value={data.title || ""}
                          onChange={(e) =>
                            changeHandlerq(
                              e.target.value,
                              "chapter",
                              index,
                              lebelIndex,
                              "title"
                            )
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <span style={{ color: "black" }}>Choose Icon :</span>
                        <Select
                          className={classes.textField}
                          margin="dense"
                          value={data.file}
                          onChange={(e) =>
                            changeHandlerq(
                              e.target.value,
                              "chapter",
                              index,
                              lebelIndex,
                              "file"
                            )
                          }
                          variant="outlined"
                        >
                          {iconImages &&
                            iconImages.length !== 0 &&
                            iconImages.map((ITEM, indexIdNO) => (
                              <MenuItem key={indexIdNO} value={ITEM.icon_file}>
                                {ITEM.icon_title}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      {data && data.file && data.file && (
                        <Grid item md={2} xs={12}>
                          <img
                            alt="Smiley face"
                            controls
                            src={data && data.file && data.file}
                            width="100%"
                            height="100"
                          />
                        </Grid>
                      )}
                      {(data.content === null && (
                        <>
                          <Grid item md={2} xs={12}>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={() =>
                                functionToCreateChapter(index, lebelIndex)
                              }
                            >
                              {" "}
                              Create Content
                            </Button>
                          </Grid>
                          <Grid item md={1} xs={12}>
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              className={classes.button}
                              onClick={() =>
                                DeleteLableFunction(index, lebelIndex)
                              }
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </>
                      )) ||
                        ""}

                      {(data.title !== null && data.content !== null && (
                        <>
                          <Grid item md={1} xs={12}>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={() =>
                                functiontoSaveChapterName(index, lebelIndex)
                              }
                            >
                              Save
                              {/* CONTENT title */}
                            </Button>
                          </Grid>
                          <Grid item md={1} xs={12}>
                            <IconButton
                              size="small"
                              color="secondary"
                              className={classes.Deletebutton}
                              onClick={() =>
                                functionToDeleteChapter(index, lebelIndex)
                              }
                            >
                              <DeleteIcon size="small" />
                            </IconButton>
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <Button
                              size="small"
                              className={classes.button}
                              onClick={() =>
                                functionToAddContant(
                                  index,
                                  lebelIndex,
                                  "chapter",
                                  "content"
                                )
                              }
                            >
                              {" "}
                              Add Content
                              <AddIcon size="small" />
                            </Button>
                          </Grid>
                        </>
                      )) ||
                        ""}

                      {(data.content &&
                        data.content.length > 0 &&
                        data.content.map((info, contentIndex) => (
                          <Box
                            border={1}
                            className={classes.contentBox}
                            key={contentIndex}
                          >
                            <Grid container spacing={2}>
                              <Grid item md={3} xs={12}>
                                <Select
                                  className={classes.textField}
                                  margin="dense"
                                  disabled={!!info.title}
                                  value={info.content_type || ""}
                                  onChange={(e) =>
                                    changeHandlerq(
                                      e.target.value,
                                      "chapter",
                                      index,
                                      lebelIndex,
                                      "content",
                                      contentIndex,
                                      "content_type"
                                    )
                                  }
                                  variant="outlined"
                                >
                                  {activities.map((option, indexNo) => (
                                    <MenuItem
                                      key={indexNo}
                                      value={option.activityName}
                                    >
                                      {option.activityName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item md={2} xs={12}>
                                {info.content_type &&
                                info.content_type === "File" ? (
                                  <Button
                                    size="small"
                                    disabled={!!info.title}
                                    onClick={() =>
                                      fuctionforOpenFileModle(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                    variant="outlined"
                                    className={classes.button}
                                  >
                                    Upload File
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {info.content_type &&
                                info.content_type === "Video" ? (
                                  <Button
                                    size="small"
                                    disabled={!!info.title}
                                    onClick={() =>
                                      functionforOpenVideoModle(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                    variant="outlined"
                                    className={classes.button}
                                  >
                                    Upload Video
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {info.content_type &&
                                info.content_type === "Assignment" ? (
                                  <Button
                                    size="small"
                                    disabled={!!info.title}
                                    onClick={() =>
                                      functionforOpenAssignmentModle(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                    variant="outlined"
                                    className={classes.button}
                                  >
                                    Upload Assignment
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {info.content_type &&
                                info.content_type === "Text" ? (
                                  <Button
                                    size="small"
                                    disabled={!!info.title}
                                    onClick={() =>
                                      functionforOpenTextModle(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                    variant="outlined"
                                    className={classes.button}
                                  >
                                    Upload Text
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {info.content_type &&
                                info.content_type === "McqTest" ? (
                                  <Button
                                    size="small"
                                    disabled={!!info.title}
                                    onClick={() =>
                                      functionforOpenMcqModle(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                    variant="outlined"
                                    className={classes.button}
                                  >
                                    Create Mcq Test
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {info.content_type &&
                                info.content_type === "Branch Wise Video" ? (
                                  <Button
                                    size="small"
                                    disabled={!!info.title}
                                    onClick={() =>
                                      functionforOpenPPTModle(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                    variant="outlined"
                                    className={classes.button}
                                  >
                                    Add Branch Wise Video
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Grid>
                              {info.content === null && (
                                <Grid>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    className={classes.removeContentButton}
                                    onClick={() =>
                                      functionRemoveContent(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                              )}
                              <Grid item md={3} xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.typography}
                                >
                                  {info.title}
                                </Typography>
                              </Grid>
                              <Grid item md={1} xs={12}>
                                {info.title && (
                                  <IconButton
                                    size="small"
                                    className={classes.Deletebutton}
                                    onClick={() =>
                                      functionEditUploadData(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                  >
                                    <EditIcon size="small" />
                                  </IconButton>
                                )}
                              </Grid>
                              <Grid item md={1} xs={12}>
                                {info.title && (
                                  <IconButton
                                    size="small"
                                    className={classes.Deletebutton}
                                    onClick={() =>
                                      functionDeleteContent(
                                        "chapter",
                                        index,
                                        lebelIndex,
                                        "content",
                                        contentIndex
                                      )
                                    }
                                  >
                                    <DeleteIcon size="small" />
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        ))) ||
                        ""}
                    </Grid>
                  </Box>
                ))}
              <Grid item md={12} />
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ fontSize: "10px" }}
                  onClick={() => addExtraLable("chapter", index)}
                >
                  Add New Content
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}

      {UploadFileOpen()}
      {UploadVideoOpen()}
      {UploadAssignmentOpen()}
      {UploadTextOpen()}
      {UploadMcqOpen()}
      {UploadPPTFileOpen()}

      <Grid
        justify="space-between"
        container
        spacing={2}
        style={{ marginTop: "10px" }}
      >
        <Grid item md={8} />
        <Grid item md={2} xs={6}>
          <Button
            color="primary"
            variant="contained"
            className={classes.textField}
            disabled={pageNo === "0"}
            onClick={() => backPage()}
          >
            Back
          </Button>
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            color="primary"
            variant="contained"
            className={classes.textField}
            disabled={pageNo === "7"}
            onClick={() => nextPage()}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      {loader}
    </>
  );
};

CourseSchedule.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  userIdNo: PropTypes.string.isRequired,
  backPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(CourseSchedule);
