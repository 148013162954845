/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  // InputLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./SchoolWiseReport.style";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let link1;
let link2;
let link3;
let link4;
let finalUrl;
let schoolArray = [];
const SchoolWiseReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [checkLoader, setCheckLoader] = useState(false);
  const [school, setSchool] = useState("");
  const [role, setRole] = useState([]);
  const [branch, setBranch] = useState("");
  const [email, setEmail] = useState("");
  const alert = useAlert();
  const [SelectAll, setSelectedAll] = useState("");
  const [permission, setPermission] = useState([]);

  // const verifyEmail = auth
  // && auth.academic_profile
  // && auth.academic_profile.user.email;

  // let verify=verifyEmail.includes("@orchids.edu.in")
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  var verify = loginData.role_permission.is_orchids;

  const {
    data: schoolList,
    isLoading: schoolListLoading,
    doFetch: fetchSchool,
  } = useFetch(null);

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);

  useEffect(() => {
    fetchSchool({
      url: urls.editSchoolApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("School_Wise_Report"),
      },
    });
    // link1=`${urls.getRoleRegistration}?search=orchids&user_type=USER`
    //     link2=`${urls.getRoleRegistration}?search=other&user_type=USER`
    //     link3=`${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`
    //     link4=`${urls.getRoleRegistration}?search=other&user_type=ADMIN`

    // finalUrl = localStorage.getItem("userType")==="USER" ? verify ? link1 : link2 :
    // localStorage.getItem("userType")==="ADMIN" ? verify ? link3 :link4 :null
    finalUrl = `${urls.getRoleRegistration}?user_type=USER`;
    fetchRoles({
      url: finalUrl,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("School_Wise_Report"),
      },
    });
    schoolArray.splice(0, schoolArray.length);
    for (let i = 0; i < school.length; i += 1) {
      schoolArray.push(
        schoolList && schoolList.filter((item) => item.name === school[i])[0].id
      );
    }
    // console.log(schoolArray)
    fetchBranches({
      url: `${urls.branchApi}?school_id=${schoolArray}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("School_Wise_Report"),
      },
    });
  }, [
    fetchRoles,
    fetchSchool,
    auth.personal_info.token,
    fetchBranches,
    school,
  ]);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // useEffect(() => {
  //   fetchBranches({
  //     url: `${urls.branchApi}?school_id=${school}`,
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   });

  // },[school, auth.personal_info.token, fetchBranches])

  const sendEmail = () => {
    if (!school || school.length === 0) {
      alert.warning("Select School");
      return;
    }
    if (!branch || branch.length === 0) {
      alert.warning("Select Branch");
      return;
    }
    if (!role || role.length === 0) {
      alert.warning("Select Role");
      return;
    }
    if (
      !email ||
      !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
        email
      )
    ) {
      alert.warning("Enter a valid email");
      return;
    }
    const roleArr = [];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList &&
          roleList.response.filter((item) => item.role_name === role[i])[0]
            .role_id
      );
    }

    // array.splice(0,array.length);
    // console.log(array);

    async function loading() {
      setCheckLoader(true);

      const response = await fetch(
        `${urls.schoolWiseReportSendMailApi}?school=${schoolArray}&branch=${[
          branch,
        ]}&role=${[roleArr]}&email=${email}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("School_Wise_Report"),
          },
        }
      );
      const res = await response.json();
      return res;
    }
    loading()
      .then((res) => {
        // console.log("test",res)
        if (res === "success") {
          setCheckLoader(false);
          alert.success(`Your consolidated report has been sent to ${email}.`);
          // // eslint-disable-next-line no-console
        } else {
          setCheckLoader(false);
          alert.warning(res);
          //  window.alert(res)
        }
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.warning(`Error:${error}`);
      });
    setSchool([]);
    setBranch([]);
    setEmail("");
    setRole([]);
  };

  let loader = null;
  if (
    schoolListLoading ||
    roleListLoading ||
    branchListLoading ||
    checkLoader
  ) {
    loader = <Loader open />;
  }

  function handleSelectALl(e) {
    if (SelectAll === true && e.target.value.length - 1 === branchList.length) {
      setSelectedAll(false);
      setBranch([]);
      return;
    }
    if (e.target.value.length !== 0) {
      if (
        e.target.value.filter((data) => data === "0").length === 1 &&
        SelectAll === false
      ) {
        const setarray = [];
        for (let i = 0; i < branchList.length; i += 1) {
          setarray.push(branchList[i].id);
        }
        setSelectedAll(true);
        setBranch(setarray);
      } else {
        setBranch(e.target.value);
        setSelectedAll(false);
      }
    } else {
      setBranch(e.target.value);
      setSelectedAll(false);
    }
  }

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem("School_Wise_Report"));
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4"> School Wise Report </Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography>Select School</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={school || []}
                    onChange={(e) => setSchool(e.target.value)}
                    multiple
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black" }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {schoolList &&
                      schoolList.length !== 0 &&
                      schoolList.map((data) => (
                        <MenuItem
                          value={data.name}
                          key={data.id}
                          name={data.name}
                        >
                          <Checkbox checked={school.indexOf(data.name) > -1} />
                          <ListItemText primary={data.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <Typography>
                  Select Branch
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  multiple
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  style={{ minWidth: 120, maxWidth: 550 }}
                  value={branch || ''}
                  onChange={(e) => setBranch(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  MenuProps={MenuProps}
                  // style={{ color: 'black' }}
                >
                  {branchList && branchList.length !== 0 && branchList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {data.branch_name ? data.branch_name : ''}
                    </MenuItem>
                  ))}
                </Select> */}

                <FormControl className={classes.formControl}>
                  <Typography>Select branch</Typography>
                  <Select
                    margin="dense"
                    variant="outlined"
                    multiple
                    fullWidth
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={branch || []}
                    // style={{ minWidth: 120, maxWidth: 550 }}
                    onChange={(e) => handleSelectALl(e)}
                    className={classes.normal}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>Select Branch</MenuItem>
                    <MenuItem key="0" value="0">
                      Select All
                    </MenuItem>
                    {branchList &&
                      branchList.length !== 0 &&
                      branchList.map((dropItem) => (
                        <MenuItem
                          style={{
                            color:
                              branch &&
                              branch.filter(
                                (gradeData) => gradeData === dropItem.id
                              ).length === 1
                                ? "white"
                                : "",
                            backgroundColor:
                              branch &&
                              branch.filter(
                                (gradeData) => gradeData === dropItem.id
                              ).length === 1
                                ? "darkslateblue"
                                : "",
                          }}
                          value={dropItem.id}
                          key={dropItem.id}
                        >
                          {((auth &&
                          auth.personal_info.role &&
                          auth.personal_info.role !== "Principal"
                            ? dropItem.branch_name
                            : dropItem.name) &&
                            (auth &&
                            auth.personal_info.role &&
                            auth.personal_info.role !== "Principal"
                              ? dropItem.branch_name
                              : dropItem.name)) ||
                            ""}
                          {/* {dropItem.branch_name ? dropItem.branch_name : ''} */}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Typography>
                  Select Role
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  single
                  value={role || []}
                  isRequired
                  onChange={(e) => setRole(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {roleList && roleList.length !== 0 && roleList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.name}
                    >
                      {data.id ? data.name : ''}
                    </MenuItem>
                  ))}

                </Select>
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Typography>Select Role</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    fullWidth
                    multiple
                    value={role || []}
                    isRequired
                    onChange={(e) => setRole(e.target.value)}
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black" }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {roleList &&
                      roleList.length !== 0 &&
                      roleList.response.map((data) => (
                        <MenuItem
                          value={data.role_name}
                          key={data.role_id}
                          // name={data.name}
                        >
                          <Checkbox
                            checked={role.indexOf(data.role_name) > -1}
                          />
                          <ListItemText primary={data.role_name} />
                          {/* {data.id ? data.name : ''} */}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>Enter Email Address</Typography>
                <TextField
                  label=""
                  margin="dense"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                />
                {email &&
                !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "red" }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
                {email &&
                /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  email
                ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "green" }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item md={1} xs={12}>
                {permission.can_send ? (
                  <Button
                    style={{ marginTop: "33px" }}
                    className={classes.updateButton}
                    color="primary"
                    variant="contained"
                    onClick={() => sendEmail()}
                  >
                    Send
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
      {loader}
    </>
  );
};

SchoolWiseReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SchoolWiseReport);
