import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './AboutInHouse.style';
import FooterDiv from '../ReUsableComponents/FooterDiv';
import './AboutInHouse.css';

function AboutInHouse({ classes }) {
  // useEffect(() => {
  //   document.getElementById('aboutDiv1Id').animate(
  //     [
  //       // keyframes
  //       { transform: 'translateX(500px)', opacity: 0 },
  //       { transform: 'translateX(0px)', opacity: 1 },
  //     ],
  //     {
  //       // timing options
  //       duration: 1000,
  //     },
  //   );
  //   document.getElementById('aboutDiv2Id').animate(
  //     [
  //       // keyframes
  //       { transform: 'translateX(-500px)' },
  //       { transform: 'translateX(0px)' },
  //     ],
  //     {
  //       // timing options
  //       duration: 1000,
  //     },
  //   );
  // }, []);

  return (
    <div className="wholeAboutUs">
      <div
        // style={{ marginTop: '12vh' }}
        className={classes.aboutDiv1}
        id="aboutDiv1Id"
      >
        <div className={classes.aboutDiv1p}>
          <h1> About Us</h1>
          <p>
            When Sure Learning first started out, the focus was on providing an
            opportunity for learners to enjoy the learning process at every
            stage. We still adhere to this outlook and are thrilled to be a part
            of the jovial wing of the Education industry.
            <br />
            <br />
            {' '}
            It is often understood that not all the best minds are the
            best teachers. It is a greater deal than just knowing the facts,
            which is where Sure Learning plays a vital role.
            <br />
            <br />
            {' '}
            Sure Learning is an online training programme that offers a
            diverse variety of courses for the teaching professionals, as well
            as the learners, in order to help them identify their strengths
            &#038; build an effective practice, leading to improved outcomes.
            Each course is an amalgamation of interesting riddles, crossword
            puzzles, videos, brainteasers, and so much more, designed to unravel
            a joyful and engaging way of learning.
            <br />
            <br />
            {' '}
            We are here to make learning experience&apos; Inspirational,
            Fruitful &#038; Extraordinary!
          </p>
        </div>
      </div>
      <div className={classes.aboutDiv2} id="aboutDiv2Id">
        <div className={classes.aboutDiv2Box1} />
        <div className={classes.aboutDiv2Box2}>
          <div className="arcBtn">
            <p className="arcBtnText">VISION</p>
          </div>
          <p className={classes.aboutDiv2p}>
            To empower the teaching &#038; learning fraternity, unleashing their
            &#039;I CAN&#039; mindset.
          </p>
        </div>
      </div>
      <div className={classes.aboutDiv3} id="aboutDiv3Id" style={{ paddingBottom: '10px' }}>
        <div className={classes.aboutDiv3Box2} id="aboutDiv3Box2Id">
          <div className="arcBtn">
            <p className="arcBtnText">MISSION</p>
          </div>
          <p className={classes.aboutDiv3p}>
            To establish an interactive platform for learners &#038; academic
            professionals&apos; dwelling into a new arena of learning.
          </p>
        </div>
        <div className={classes.aboutDiv3Box1} />
      </div>
      {/* <div className={classes.aboutDiv3}>
        <div className={classes.box1Div3} />
        <div className={classes.box2Div3}>
          <span className={classes.div3Text}>Extended Learning in</span>
          <span className={classes.div3Text}>Science, Technology,</span>
          <span className={classes.div3Text}> Engineering and Math</span>
          <p className={classes.div3Para}>
            I&apos;m a paragraph. Click here to add your own text and edit me.
            It’s easy. Just click “Edit Text” or double click me to add your own
            content and make changes to the font. I’m a great place for you to
            tell a story and let your users know a little more about you.
          </p>
        </div>
        <div className={classes.box3Div3} />
      </div>
      <MainPageForm />
      <MapDiv /> */}
      <FooterDiv />
    </div>
  );
}
AboutInHouse.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(AboutInHouse);
