import React from 'react';
import './CSS/InHouseMainPage.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import mainVideo from '../Media/Video/mainVideo.mp4';
import internetIcon from '../Media/UdaanHome/internet.svg';
import orchidsLogo from '../Media/UdaanHome/orchids-logo.png';
import justLogo from '../Media/UdaanHome/Just Logo-01.png';
import SlickSlider from './Slider/SlickSlider';
import planer from './images/planer.svg';
// import arc from './images/arc.svg';

function InHouseMainPage() {
  return (
    <>
      <div className="wholeDiv">
        <div className="videoDiv">
          <div className="flex">
            <div className="checkVideoBtn">
              <a
                href="https://www.loom.com/share/9ac3aac3622240f19f07cd4b6e27e875"
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                Check out this video to know how to get started
              </a>
            </div>
            <div className="instructions">
              <h3 className="iHead">Instructions</h3>
              <p>
                <span className="stepName">Step 1:</span>
                Click Induction tab.
              </p>
              <br />
              <p>
                <span className="stepName">Step 2:</span>
                {' '}
                Select
                your details and in case you have chosen 2
                subject, choose the first one.
              </p>
              <br />
              <p>
                <span className="stepName">Step 3:</span>
                Courses will appear which are sequenced from
                Module 1 to 8, you can&apos;t jump the modules.
              </p>
              <br />
              <p>
                <span className="stepName">Step 4:</span>
                {' '}
                Each
                Module has multiple chapters which has to be
                completed in sequence.
              </p>
              <br />
              <p>
                <span className="stepName">Step 5:</span>
                Each Chapter has Steps given on the book, you
                have to follow the sequence and take the quiz.
              </p>
            </div>
          </div>
          <video
            loop
            muted
            width="100%"
            height="auto"
            autoPlay
            className="mainVideo"
          >
            <source src={mainVideo} type="video/mp4" />
          </video>
        </div>

        <div className="firstDiv">
          <div className="firstDivLeftBox">
            <a href="/">Home</a>
            <a href="/about">
              <img
                src={planer}
                alt="crash"
                className="planeImg"
              />
              About
            </a>
            <a href="/inductionTraining">Induction Training</a>
            <a href="/inHouseTreasureBox">Treasure Box</a>
            <a href="/contact">Contact</a>
          </div>
          <div className="firstDivRightBox" />
        </div>
        <div className="secondDiv">
          <div className="secondDivLeftBox" />
          <div className="secondDivRightBox">
            <h2>Welcome to Sure Learning,</h2>
            <p>
              an upgraded online training programme that provides
              an interactive learning space
              {' '}
            </p>
            <p>
              for teaching professionals as well as students,
              guiding them to a new arena of learning. Founded in
              2019, Sure Learning aims at exposing the teachers as
              well as students
            </p>
            <p>
              {' '}
              <img
                src={planer}
                alt="crash"
                className="planeImg"
              />
              to innovative teaching methods advancing with time,
              to ensure learning is never outdated.
            </p>
            <div className="arcBtn">
              <a href="/about" style={{}}>
                {/* <img src={arc} className='arcBtn' width='100px' height='100px' /> */}
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="thirdDiv">
          <div className="thirdDivLeftBox">
            <a href="/">Enlighten</a>
            <a href="/inductionTraining">Engage</a>
            <a href="/teacherToolBox">
              <img
                src={planer}
                alt="crash"
                className="planeImg"
              />
              Empower
            </a>
            <p>
              Combination of an assured path to the mastery of Art
              that Teaching is.
            </p>
          </div>
          <div className="thirdDivRightBox" />
        </div>
        <div className="fourthDiv">
          <div className="fourthDivLeftBox" />
          <div className="fourthDivRightBox">
            <div className="arcBtn">
              <a href="/" style={{}}>
                {/* <img src={arc} className='arcBtn' width='100px' height='100px' /> */}
                Services
              </a>
            </div>
            <a href="/inductionTraining">Induction Training</a>
            <a href="/teacherToolBox">Teacher Toolbox</a>
            <a href="/inHouseCourses">Courses</a>
          </div>
        </div>
        {/* <div className='testimonials'> */}
        <SlickSlider />
        {/* </div> */}
        <footer className="mainPageFooterDiv">
          <div className="contactDiv">
            <h2>Address</h2>
            <p>MUMBAI OFFICE :</p>
            <p>Prince House 51/3,</p>
            <p>Marol Co-op. Industrial Estate,</p>
            <p>M.V. Road, Mumbai - 400059</p>
            <iframe
              title="iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.925112339994!2d72.88256064285281!3d19.110940961803664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c81569afae91:0x16a1a5b60f33144f!2sPrince House!5e0!3m2!1sen!2sin!4v1613454046662!5m2!1sen!2sin"
              width="100%"
              height="100%"
              frameBorder="0"
              className="mapFrame"
              style={{ border: '0' }}
              allowFullScreen=""
            />
            <div className="emailLink">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.orchids.edu.in"
                style={{ textDecoration: 'none' }}
              >
                <img
                  alt="crash"
                  src={internetIcon}
                  className="internetIcon"
                  width="25px"
                  height="25px"
                />
                www.orchids.edu.in
              </a>
              <div className="socialMediaDiv">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Sure-Learning-108204044308528/"
                >
                  <span className="socialMediaLink">
                    <FacebookIcon fontSize="large" />
                  </span>
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/surelearning?igshid=1i8rs81ym0hag"
                >
                  <span className="socialMediaLink">
                    <InstagramIcon fontSize="large" />
                  </span>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCcwkcto7bF2zeP74Spy9cvg"
                >
                  <span className="socialMediaLink">
                    <YouTubeIcon fontSize="large" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div>
            <img
              alt="crash"
              src={justLogo}
              width="100%"
              height="100%"
            />
            <h1>Sure Learning</h1>
            <p>Empowered 2000+ teachers for</p>
            <img
              alt="crash"
              src={orchidsLogo}
              width="60%"
              height="60%"
            />
          </div>
        </footer>
      </div>
    </>
  );
}

export default InHouseMainPage;
