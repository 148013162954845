/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './InhouseTeacherToolBox.style';
// import FooterDiv from '../ReUsableComponents/FooterDiv';
import urls from '../../../url';

function TeacherToolBox({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [loader, setLoader] = useState(false);
  const [allVideos, setAllToolBoxVideos] = useState(false);

  const getAllTeacherToolBoxVideos = async () => {
    setLoader(true);

    const response = await fetch(
      `${urls.teacherToolboxVideo}`,

      // &page_size=${rowsPerPage ||
      //   5}&page=${page + 1}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      },
    );
    const getDataInfo = await response.json();
    // setTotalPages(getDataInfo.total_pages);

    setLoader(false);
    return getDataInfo;
  };

  useEffect(() => {
    getAllTeacherToolBoxVideos(urls.getBranchNames).then((data) => {
      setAllToolBoxVideos(data);
    });
  }, []);
  return (
    <>
      {loader === true && (
        <img
          // open={open}
          className={classes.progress}
          alt="crash"
          src={require('../../../hoc/loder.gif')}
          width="100%"
          height="100%"
        />
      )}
      <div className={classes.teacherToolboxMainDiv}>
        {allVideos
          && allVideos.map((item) => (
            <div key={item.id} className={classes.eachCard}>
              <video width="100%" controls controlsList="nodownload">
                <source src={item.file} type="video/mp4" />
                <track
                  src={item.file}
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
      </div>
      <div className={classes.backgroundImg} />
      {/* <FooterDiv /> */}
    </>
  );
}
TeacherToolBox.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TeacherToolBox);
