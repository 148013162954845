import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import styles from "./addResourceStyle.style";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import urls from "../../../url";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import { Page, Text, View, Document, StyleSheet } from "react-pdf";
import FileViewer from "react-file-viewer";
import "./createResourse.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ResourceDetailViewer from "./Resource_Deails/resources_details";
import SearchBar from "material-ui-search-bar";

const ListResources = ({ classes }) => {
  const [resourseList, setResourseList] = useState("");
  const [editData, setEditData] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [edit, setEdit] = useState(false);
  const [roles, setRoles] = useState([]);
  const [resourceTitle, setResourceTitle] = useState("");
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [selectedRolesName, setSelectedRolesName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const alert = useAlert();
  // const [fileDataInfo, setFileDataInfo] = useState("");
  const [fileName, setFileName] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [fileDataInfo, setFileDataInfo] = useState(null);
  // const [fileUrl, setFileUrl] = useState(null);
  const [folderList, setFolderList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState([]);
  const [selectedFolderName, setSelectedFolderName] = useState(null);
  const [searched, setSearched] = useState("");

  const [isOpenResouurceDialog, setIsOpenResouurceDialog] = useState(false);
  const [selectedResourcePath, setSelectedResourcePath] = useState(null);
  // const [fileDataInfo, setFileDataInfo] = useState([
  //   {
  //     fileData: [],
  //   },
  // ]);
  const column = [
    "Sl No.",
    "Role Name",
    "Folder Name",
    "Resource Title",
    "Resource File",
    "",
    "Status",
    "Actions",
  ];
  const type = ".jpeg, .png";

  const getResourceList = () => {
    let temp = [];
    axios
      .get(`${urls.GetResource}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        alert.success("Resources retrieved successfully");
        // alert.success(response.data.message);
        // console.log(response, "ohhhhh");
        response.data.map((all) => {
          temp.push({
            id: all.id,
            documents: all.documents,
            roles_ids: all.roles_ids[0],
            roles_name: all.roles_name[0],
            file_name: all.file_name[0],
            resources_title: all.resources_title,
            folder_name: all.folder_name[0],
            folders: all.folders,
            is_approved: all.is_approved,
          });
        });
        // console.log(response.data, temp);
        // setResourseList(temp);
        setResourseList(response.data);
      })
      .catch((error) => {
        // alert.warning("Status not updated !!!");
      });
    // console.log(props.data, "search1 from table");
    // setData(props.data)
    // setAllData(props.Alldata)
  };

  const getAllFolders = () => {
    axios
      .get(urls.getResourceFolder, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        setFolderList(response.data);

        // handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenResourceDetail = (path) => {
    setSelectedResourcePath(path);
    setIsOpenResouurceDialog(true);
  };

  const handleCloseResourceDetail = (path) => {
    setSelectedResourcePath(null);
    setIsOpenResouurceDialog(false);
  };

  useEffect(() => {
    getResourceList();
    getParticularRoleData();
    getAllFolders();
  }, []);

  const newItem = () => {
    setActionItems([...actionItems, ""]);
  };

  const getParticularRoleData = (id) => {
    axios
      .get(urls.getRole, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("User_Roles"),
        },
      })
      .then((response) => {
        setRoles(response.data.response);
        // console.log(response.data.response, "dddwww");
        // handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const handleSchools = (event, value) => {
  //     console.log(value, "nnnnn");
  //     setSelectedSchool(value);
  //     setSchoolID(value.id);
  //   };
  //   const handleBranchChange = (event) => {
  //     setBranch(event.target.value);
  //   };

  const handleResourceTitleChange = (event) => {
    setResourceTitle(event.target.value);
  };

  const handleRole = (event, value) => {
    let selected_role = roles.filter(
      (each) => each.role_id === event.target.value
    );
    // console.log(selected_role[0]?.role_name, "selected_role");
    setSelectedRolesName(selected_role[0]?.role_name);
    // console.log(event.target.value);
    setSelectedRoles(value);
    console.log(value);
  };

  const handleFolder = (event) => {
    let selected_folder = folderList.filter(
      (each) => each.id === event.target.value
    );
    // console.log(selected_folder[0]?.folder_name, "selected_folder");
    setSelectedFolderName(selected_folder[0]?.folder_name);
    console.log(event.target.value);
    setSelectedFolder(event.target.value);
  };

  //   const updateSchoolList = (id) => {
  //     console.log(editData, "jjjjj");
  //     let temp = {};
  //     for (let i = 0; i < actionItems.length; i += 1) {
  //       temp = {
  //         ...temp,
  //         [`${i}`]: {
  //           id: editData?.id,
  //           branch_name: branch,
  //           school_id: editData?.school_id,
  //         },
  //       };
  //     }

  //     const temp2 = {
  //       branches: temp,
  //     };
  //     // console.log(temp2, "jjjjjk");
  //     // let editObj = {
  //     //   branches: {
  //     //     0: {
  //     //       id: editData?.id,
  //     //       branch_name: editData?.branch_name,
  //     //       school_id: editData?.school_id,
  //     //     },
  //     //   },
  //     // };
  //     // console.log(editObj, "jjjjj");
  //     axios
  //       .put(`${urls.addBranchApi}`, temp2, {
  //         headers: {
  //           Authorization: `Bearer ${auth.personal_info.token}`,
  //           module: localStorage.getItem("Branch"),
  //           // "Content-Type": "application/json",
  //         },
  //       })
  //       .then((result) => {
  //         console.log(result.data.message, "uppda");
  //         // setSchoolList(result.data);
  //         alert.success(result.data.message);
  //         setDialogOpen(false);
  //         // window.location.reload();
  //         getBranchList();
  //         setBranch(null);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         // alert.warning(error.response.data.message);
  //       });
  //   };

  useEffect(() => {
    // console.log(editData);
    if (editData !== null) {
      let tempRoles = roles.filter((each) =>
        editData?.roles_ids.includes(each.role_id)
      );
      // let tempFolder = folderList.filter((each) =>
      //   editData?.folder_name.includes(each.folder_name)
      // );
      // console.log(tempFolder);
      setSelectedRoles(tempRoles);
      setSelectedRolesName(editData?.roles_name);
      // setSelectedRoles(roleTemp);
      setResourceTitle(editData?.resources_title);
      setFileUrl(editData?.documents);
      setFileName(editData?.file_name);
      setSelectedFolder(editData?.folders);
      setSelectedFolderName(editData?.folder_name);
    }
  }, [dialogOpen]);

  function functionToValidateFiles(event) {
    // console.log(event, "ddddddd1www");
    setFileDataInfo(event);
    // setFileUrl(URL.createObjectURL(event));
  }

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const updateResources = (id) => {
    let tempRolesIds = selectedRoles.map((each) => each.role_id);
    // let tempFolderIds = selectedFolder.map((each) => each.id);
    console.log("documents", tempRolesIds);
    let formData = new FormData();

    if (fileDataInfo !== null) {
      formData.append("id", editData.id);
      formData.append("resources_title", resourceTitle);
      formData.append("roles_id_s", tempRolesIds);
      formData.append("folders_s", selectedFolder);

      formData.append("documents", fileDataInfo);
    } else {
      formData.append("id", editData.id);
      formData.append("resources_title", resourceTitle);
      formData.append("roles_id_s", tempRolesIds);
      formData.append("folders_s", selectedFolder);
    }

    axios
      .put(urls.UpdateResource, formData, {
        // method: 'POST',
        // body: JSON.stringify(data),
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        // alert.success("user updated successfully");
        alert.success(response.data.message);

        // props.close();
        // window.location.reload();
        setDialogOpen(false);
        getResourceList();
        setFileDataInfo(null);
      })
      .catch((error) => {
        alert.warning("Something went wrong try again");
      });
  };

  const deleteResourse = (eachData) => {
    let data = {
      id: `${eachData.id}`,
    };
    // console.log("Inside delete");
    axios
      .delete(urls.DeletResource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
        data,
      })
      .then((res) => {
        alert.success(res.data.message);
        getResourceList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestSearch = (searchValue) => {
    // console.log(searchValue?.length, searchValue, "searchValue");
    let temp = [];
    if (searchValue !== "") {
      axios
        .get(`${urls.searchResources}?search_query=${searchValue}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Resources_data"),
          },
        })
        .then((response) => {
          // console.log(response);
          response.data.map((all) => {
            temp.push({
              id: all.id,
              documents: all.documents,
              roles_ids: all.roles_ids[0],
              roles_name: all.roles_name[0],
              file_name: all.file_name[0],
              resources_title: all.resources_title,
              folder_name: all.folder_name[0],
              folders: all.folders,
              is_approved: all.is_approved,
            });
          });
          setResourseList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getResourceList();
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    getResourceList();
  };

  // let role = roles && roles.map((ele) => ele.role_name);
  // console.log(fileDataInfo.name, "lllds", fileUrl, "ccccc");
  return (
    <>
      <Paper elevation={24}>
        <SearchBar
          style={{
            width: "30%",
            float: "right",
            margin: "0 0 15px 0",
          }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Paper>

      <Grid xs={12}>
        <div className="table-container">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {column &&
                    column.map((eachColumn, index) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "20px",
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          {eachColumn}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {resourseList &&
                  resourseList.map((eachData, index) => {
                    return (
                      <TableRow key={eachData.id}>
                        {/* {console.log(eachData, "sc")} */}
                        <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {eachData?.roles_name?.map((each) => {
                            return <>{each}, </>;
                          })}
                        </TableCell>
                        <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {eachData.folder_name}
                        </TableCell>
                        <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {/* {console.log(eachData)} */}
                          {eachData.resources_title}
                        </TableCell>
                        {/* <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            // window.open(eachData?.documents, "_blank");
                            handleOpenResourceFolderDetail(eachData?.documents);
                          }}
                        >
                          Blob
                        </TableCell> */}
                        <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign:
                              eachData?.documents?.endsWith(".mp4") ||
                              eachData?.documents?.endsWith(".mp3") ||
                              eachData?.documents?.includes(".mp4") ||
                              eachData?.documents?.includes(".mp3") ||
                              eachData?.documents?.endsWith(".docx") ||
                              eachData?.documents?.endsWith(".doc") ||
                              eachData?.documents?.endsWith(".xlsx") ||
                              eachData?.documents?.endsWith(".pdf") ||
                              eachData?.documents?.endsWith(".csv") ||
                              eachData?.documents?.endsWith(".pptx") ||
                              eachData?.documents?.endsWith(".ppt")
                                ? "left"
                                : "right",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            // window.open(eachData?.documents, "_blank");
                            handleOpenResourceDetail(eachData?.documents);
                          }}
                        >
                          {/* <FileViewer
                            // fileType={type}
                            filePath={eachData.documents}
                            // errorComponent={CustomErrorComponent}
                            // onError={this.onError}
                          /> */}
                          {eachData?.documents?.endsWith(".mp4") ||
                          eachData?.documents?.endsWith(".mp3") ||
                          eachData?.documents?.includes(".mp4") ||
                          eachData?.documents?.includes(".mp3") ? (
                            <video
                              id="documents-iframe"
                              style={{
                                width: "150px",
                                // objectFit: 'contain',
                                height: "150px",
                                // height: fullscreen ? '92vh' : '69vh',
                              }}
                              controls
                              // autoPlay
                              controlsList="nodownload"
                            >
                              {eachData?.documents?.endsWith(".mp4") ||
                              eachData?.documents?.includes(".mp4") ? (
                                <source
                                  src={`${urls.s3}${eachData?.documents.substring(32)}`}
                                  type="video/mp4"
                                />
                              ) : (
                                <source
                                  src={eachData?.documents}
                                  type="audio/mp3"
                                />
                              )}
                              Your browser does not support HTML5 video.
                            </video>
                          ) : eachData?.documents?.endsWith(".docx") ||
                            eachData?.documents?.endsWith(".doc") ||
                            eachData?.documents?.endsWith(".xlsx") ||
                            eachData?.documents?.endsWith(".pdf") ||
                            eachData?.documents?.endsWith(".csv") ? (
                            <div style={{ height: "100px", width: "200px" }}>
                              <FileViewer
                                fileType={
                                  eachData?.documents?.endsWith(".docx")
                                    ? "docx"
                                    : eachData?.documents?.endsWith(".doc")
                                    ? "doc"
                                    : eachData?.documents?.endsWith(".xlsx")
                                    ? "xlsx"
                                    : eachData?.documents?.endsWith(".pdf")
                                    ? "pdf"
                                    : "csv"
                                }
                                filePath={eachData?.documents}
                              />
                            </div>
                          ) : eachData?.documents?.endsWith(".pptx") ||
                            eachData?.documents?.endsWith(".ppt") ? (
                            <iframe
                              id="documents-iframe"
                              title="documents-iframe"
                              src={
                                `${urls.s3}${eachData?.documents.substring(32)?.endsWith(".pptx")}`
                                  ? `https://view.officeapps.live.com/op/embed.aspx?src=${eachData?.documents}`
                                  : `https://view.officeapps.live.com/op/embed.aspx?src=${eachData?.documents}`
                              }
                              // src={isPPt ? pptFileSrc : `${src}#toolbar=0&navpanes=0&scrollbar=0`}
                              // src={isPPt ? pptFileSrc : `http://docs.google.com/gview?url=${src}&embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                              className="documents-viewer-frame-preview-iframe"
                            />
                          ) : (
                            <img
                              style={{ height: "50px", width: "100px" }}
                              src={`${urls.s3}${eachData?.documents.substring(32)}`}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {/* {console.log(eachData)} */}
                        </TableCell>
                        <TableCell
                          style={{
                            height: "10px",
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {/* {console.log(eachData)} */}
                          <p
                            style={{
                              backgroundColor:
                                eachData?.is_approved === true
                                  ? "#3F51B5"
                                  : "#C70039",
                              color: "#FFF",
                              fontWidth: "bold",
                              padding: "12%",
                              width: "120%",
                            }}
                          >
                            {/* {console.log(eachData)} */}
                            {eachData?.is_approved === true
                              ? "Published"
                              : "Not Published"}
                          </p>
                        </TableCell>
                        <TableCell
                          // style={{ margin: "0 auto" }}
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                          className="action-buttons"
                        >
                          <Button
                            size="small"
                            className="host-meeting-button"
                            //   disabled={eachData.is_pdf_generated}
                            // style={{ margin: "0 15px" }}
                            onClick={(e) => {
                              setDialogOpen(true);
                              //     setDialogDetail('Edit');
                              setEditData(eachData);
                              newItem();
                            }}
                          >
                            <EditOutlinedIcon />
                          </Button>
                          <Button
                            size="small"
                            className="host-meeting-button"
                            style={{ margin: "0 15px" }}
                            //   disabled={eachData.is_pdf_generated}
                            onClick={(e) => {
                              //     setDialogOpens(true);
                              //     //   setDialogDetail('Edit');
                              // newItem();

                              deleteResourse(eachData);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={dialogOpen}
            style={{ margin: "35px 0 0 0" }}
            className="create-weekly-report-dialog"
          >
            <DialogTitle className="dialog-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Edit Resources</Typography>
                <Button
                  className="weekly-report-actions-butons"
                  onClick={() => closeDialog()}
                >
                  <CloseIcon />
                </Button>
              </div>
            </DialogTitle>
            <>
              {/* {console.log(selectedRoles, "dddssa")} */}
              <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    fullWidth
                    size="small"
                    className="filter-student meeting-form-input"
                    options={roles || []}
                    getOptionLabel={(option) => option?.role_name || ""}
                    filterSelectedOptions
                    value={selectedRoles || []}
                    onChange={(event, value) => {
                      handleRole(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        variant="outlined"
                        label="Select Role"
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        {option?.role_name}
                        {option.is_orchids ? "  - Orchids" : ""}
                      </React.Fragment>
                    )}
                  />
                  {/* <FormControl
                    margin="dense"
                    variant="outlined"
                    className="role-name"
                  >
                    <InputLabel id="role_level">Role</InputLabel>
                    <Select
                      labelId="role_level"
                      id="role_level"
                      style={{
                        width: "300px",
                      }}
                      value={selectedRoles}
                      // onChange={(e) => setSelectedRoles(e.target.value)}
                      onChange={handleRole}
                      label="Select Role Level"
                    >
                      <MenuItem value={null}>
                        <em>__Select__</em>
                      </MenuItem>

                      {roles &&
                        roles.map((eachRoleLevel) => {
                          return (
                            <MenuItem
                              id={eachRoleLevel?.role_id}
                              value={eachRoleLevel?.role_id}
                            >
                              {eachRoleLevel?.role_name
                                ? eachRoleLevel.role_name
                                : ""}{" "}
                              {eachRoleLevel.is_orchids ? "  - Orchids" : ""}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl> */}
                </Grid>
                <Grid item xs={12}>
                  {/* <Autocomplete
                    multiple
                    fullWidth
                    size="small"
                    className="filter-student meeting-form-input"
                    options={folderList || []}
                    getOptionLabel={(option) => option?.folder_name || ""}
                    filterSelectedOptions
                    value={selectedFolder || []}
                    onChange={(event, value) => {
                      handleFolder(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        variant="outlined"
                        label="Select Folder"
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option?.folder_name}</React.Fragment>
                    )}
                  /> */}
                  <FormControl
                    margin="dense"
                    variant="outlined"
                    className="folder-name"
                  >
                    <InputLabel id="role_level">Folder</InputLabel>
                    <Select
                      labelId="folder_level"
                      id="folder_level"
                      style={{
                        width: "300px",
                      }}
                      value={selectedFolder || ""}
                      onChange={handleFolder}
                      label="Select Folder"
                    >
                      <MenuItem value={null}>
                        <em>__Select__</em>
                      </MenuItem>

                      {folderList &&
                        folderList.map((eachFolder) => {
                          return (
                            <MenuItem
                              id={eachFolder?.id}
                              value={eachFolder?.id}
                            >
                              {eachFolder?.folder_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Resource Title"
                    margin="dense"
                    fullWidth
                    required
                    variant="outlined"
                    multiline
                    rows={1}
                    rowsMax={4}
                    style={{
                      width: "300px",
                    }}
                    value={resourceTitle}
                    onChange={handleResourceTitleChange}
                    // disabled={school !== null}
                  />
                </Grid>
                {/* {console.log(branch, "ooo branch")} */}
                <Grid item xs={12}>
                  {/* <TextField
                    label="Edit Resource Title"
                    margin="dense"
                    fullWidth
                    required
                    variant="outlined"
                    multiline
                    rows={1}
                    rowsMax={4}
                    value={branch}
                    onChange={handleBranchChange}
                  /> */}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid item md={12} xs={12}>
                    <input
                      style={{ display: "none" }}
                      className={classes.fileUpload}
                      id="outlined-button-files"
                      type="file"
                      // onChange={functionToValidateFiles}
                      onChange={(e) =>
                        functionToValidateFiles(e.target.files[0])
                      }
                    />
                    <label htmlFor="outlined-button-files">
                      {/* <b style={{ fontFamily: "" }}>Upload File</b>
                      &nbsp;&nbsp; */}
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.fileUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Browse
                      </Button>{" "}
                      &nbsp;&nbsp;
                      {/* {(typeof fileDataInfo[0] === "string" &&
                    getFileUrl() && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getFileUrl()}
                      >
                        click to View
                      </a>
                    )) ||
                    Object.values(fileDataInfo).map((item, index) => (
                     
                    ))} */}
                      {/* {console.log(fileName)} */}
                      <Typography>Resource File</Typography>
                      <b>
                        &nbsp;&nbsp;
                        {fileName}
                        &nbsp;&nbsp;
                      </b>
                    </label>
                    <div style={{ margin: "3%" }}>
                      {fileUrl?.endsWith(".mp4") ||
                      fileUrl?.endsWith(".mp3") ||
                      fileUrl?.includes(".mp4") ||
                      fileUrl?.includes(".mp3") ? (
                        <video
                          id="attachment-iframe"
                          style={{
                            width: "150px",
                            // objectFit: 'contain',
                            height: "150px",
                            // height: fullscreen ? '92vh' : '69vh',
                          }}
                          controls
                          autoPlay
                          controlsList="nodownload"
                        >
                          {fileUrl?.endsWith(".mp4") ||
                          fileUrl?.includes(".mp4") ? (
                            <source src={fileUrl} type="video/mp4" />
                          ) : (
                            <source src={fileUrl} type="audio/mp3" />
                          )}
                          Your browser does not support HTML5 video.
                        </video>
                      ) : fileUrl?.endsWith(".docx") ||
                        fileUrl?.endsWith(".doc") ||
                        fileUrl?.endsWith(".xlsx") ||
                        fileUrl?.endsWith(".pdf") ||
                        fileUrl?.endsWith(".csv") ? (
                        <FileViewer
                          fileType={
                            fileUrl?.endsWith(".docx")
                              ? "docx"
                              : fileUrl?.endsWith(".doc")
                              ? "doc"
                              : fileUrl?.endsWith(".xlsx")
                              ? "xlsx"
                              : fileUrl?.endsWith(".pdf")
                              ? "pdf"
                              : "csv"
                          }
                          filePath={fileUrl}
                        />
                      ) : fileUrl?.endsWith(".pptx") ||
                        fileUrl?.endsWith(".ppt") ? (
                        <iframe
                          id="attachment-iframe"
                          title="attachment-iframe"
                          src={
                            fileUrl?.endsWith(".pptx")
                              ? `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
                              : `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
                          }
                          className="attachment-viewer-frame-preview-iframe"
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "200px" }}
                          src={fileUrl}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                {/* {console.log(school, "ssss")} */}
                <Grid item md={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(id) => updateResources(editData)}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </>
          </Dialog>
        </div>
      </Grid>
      <Dialog
        maxWidth="xl"
        open={isOpenResouurceDialog}
        onClose={handleCloseResourceDetail}
      >
        <ResourceDetailViewer
          close={handleCloseResourceDetail}
          resource={selectedResourcePath}
          eachData={resourseList}
        />
      </Dialog>
    </>
  );
};

ListResources.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ListResources);

// export default ListSchool;
