/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Divider,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import urls from '../../../url';
import styles from './startOnlineClass.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';

function UpdateMeetingModel({
  classes, handelClose, open, fullData,
}) {
  const alert = useAlert();
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [topic, setTopic] = useState((fullData.zoom_details && fullData.zoom_details.topic) || '');
  const [loading, setLoader] = useState(false);
  const [dateTime, setDateTime] = useState((fullData.zoom_details && fullData.zoom_details.start_time) || '');
  const [duration, setDuration] = useState((fullData.zoom_details && fullData.zoom_details.duration) || '');
  const [initiatedCoursesVal, setInitiatedCoursesVal] = useState((fullData.class_initiate && fullData.class_initiate.id) || []);

  useEffect(() => {
    if (fullData) {
      setTopic((fullData.zoom_details && fullData.zoom_details.topic) || '');
      setDateTime((fullData.zoom_details && fullData.zoom_details.start_time.replace(' ', 'T')) || '');
      setDuration((fullData.zoom_details && fullData.zoom_details.duration) || '');
      setInitiatedCoursesVal((fullData.class_initiate && fullData.class_initiate.id) || []);
    }
  }, [fullData]);

  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function functionToUpdateMeeting() {
    if (!topic) {
      alert.warning('enter topic name');
      return;
    }
    if (!dateTime) {
      alert.warning('enter Date and time');
      return;
    }
    if (!duration) {
      alert.warning('enter duration');
      return;
    }
    if (!initiatedCoursesVal) {
      alert.warning('Select Class');
      return;
    }

    const data = {
      topic,
      start_time: dateTime && dateTime.length === 16 ? `${dateTime.replace('T', ' ')}:00` : dateTime.replace('T', ' '),
      duration: parseInt(duration, 10),
      class_type: 'online',
    };
    setLoader(true);
    fetch(`${urls.updateAndDeleteScheduleOnlineClassApi}${fullData.id}/update_class/`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        // alert.success('Online Class Successfully Updated');
        return res.json();
      }
      // if (res.status !== 200) {
      //   setLoader(false);
      //   alert.error('Somthing went wrong please try again');
      //   return res.json();
      // }
      return res;
    }).then((res) => {
      if (res.status_code === 201) {
        handelClose('success');
        alert.success('Online Class Successfully Rescheduled');
      } else if (res.status_code !== 201) {
        alert.error(res.message);
      }
    });
  }

  return (
    <>
      <Dialog
        maxWidth="xl"
        className={classes.modal}
        open={open}
        disableEnforceFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <DialogTitle id="alert-dialog-title" onClose={handelClose}>
          Re Schedule for &nbsp;
          <b style={{ color: 'blue' }}>{(fullData.zoom_details && fullData.zoom_details.topic) || ''}</b>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                id="standard-full-width"
                label="Topic"
                className={classes.textField}
                placeholder="Enter Topic"
                fullWidth
                value={topic || ''}
                onChange={(e) => setTopic(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ marginTop: '16px' }}>
              <TextField
                id="standard-full-width"
                helperText="Enter Date and Time (dd/mm/yyyy , hh:mm)"
                type="datetime-local"
                className={classes.textField}
                placeholder="Enter Date and Time"
                fullWidth
                value={dateTime || ''}
                onChange={(e) => setDateTime(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                helperText="Enter Duration in (minutes) "
                type="number"
                className={classes.textField}
                fullWidth
                value={duration || ''}
                onChange={(e) => setDuration(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button fullWidth color="primary" variant="contained" onClick={() => functionToUpdateMeeting()}>Update Class Schedule</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {loader}
    </>
  );
}

UpdateMeetingModel.defaultProps = {
  fullData: '',
};

UpdateMeetingModel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  fullData: PropTypes.instanceOf(Object),
  handelClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(UpdateMeetingModel);
