import React, { useState, useEffect } from "react";
import {
  withStyles,
  Grid,
  Typography,
  Divider,
  IconButton,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import urls from "../../../url";
import Loader from "../../../hoc/loader";
import TinyTextEditor from "../../../hoc/tinyMce/tinyTextEditor";
import { useAlert } from "../../../hoc/alert/alert";
import styles from "./learningVideos.style";

function LearningModel({
  classes,
  handleClose,
  open,
  edit,
  learningVideoData,
  branchList,
  roleList,
}) {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescreption, setVideoDescreption] = useState("");
  const [selectedbranch, setselectedBranch] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [SelectedAll, setSelectedAll] = useState(false);
  const [allBranchSelected, setAllBranchSelected] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    if (learningVideoData) {
      console.log(learningVideoData, "utpal switch");
      setVideo(learningVideoData.videoFile);
      setVideoTitle(learningVideoData.title);
      setVideoDescreption(learningVideoData.videoDesc);
      setselectedBranch(learningVideoData.branch);
      setSelectedRole(learningVideoData.role);
      setSelectedAll(false);
      setAllBranchSelected(learningVideoData.all_branches);
    }
  }, [learningVideoData]);

  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  function functionToValidateVideo(data) {
    if (data.type === "video/mp4") {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", data);
      fetch(`${urls.convetVideoToS3LinkApi}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Learnig_Videos"),
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            alert.success("Uploaded");
            return res.json();
          }
          if (res.status !== 200) {
            setLoading(false);
            alert.warning("something went wrong please try again ");
          }
          return 0;
        })
        .then((res) => {
          if (res && res.url) {
            setVideo(res.url);
          } else {
            setVideo("error");
          }
        });
    } else {
      alert.warning("Upload Video in MP4 Format");
    }
  }

  function saveLearningData() {
    if (!selectedRole || selectedRole.length === 0) {
      alert.warning("select Role");
      return;
    }
    if (allBranchSelected !== true) {
      if (!selectedbranch || selectedbranch.length === 0) {
        alert.warning("select Branch");
        return;
      }
    }
    if (!videoTitle) {
      alert.warning("enter video title");
      return;
    }
    if (!video || video === "error") {
      alert.warning("Browse Video");
      return;
    }
    if (!videoDescreption) {
      alert.warning("enter video Text");
      return;
    }

    let data;
    if (allBranchSelected === true) {
      data = {
        title: videoTitle,
        video,
        text: videoDescreption,
        role: selectedRole,
        // branches: selectedbranch,
        all_branches: true,
      };
    } else {
      data = {
        title: videoTitle,
        video,
        text: videoDescreption,
        role: selectedRole,
        branches: selectedbranch,
        // all_branches: true
      };
    }

    const path = edit
      ? `${urls.learningVideosUpdateApi}${learningVideoData.idNo}/create_learning_module/`
      : `${urls.learningVideosApi}`;
    setLoading(true);
    fetch(path, {
      method: edit ? "PUT" : "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
        module: localStorage.getItem("Learnig_Videos"),
      },
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        alert.success("Video Created Successfully");
        handleClose("success");
        return res.json();
      }
      if (res.status !== 200) {
        setLoading(false);
        alert.warning("something went wrong please try again ");
      }
      return 0;
    });
  }

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function handleSelectALl(e) {
    if (
      SelectedAll === true &&
      e.target.value.length - 1 === branchList.length
    ) {
      setSelectedAll(false);
      setselectedBranch([]);
      return;
    }
    if (e.target.value.length !== 0) {
      if (
        e.target.value.filter((data) => data === "0").length === 1 &&
        SelectedAll === false
      ) {
        const setarray = [];
        for (let i = 0; i < branchList.length; i += 1) {
          setarray.push(branchList[i].id);
        }
        setSelectedAll(true);
        setselectedBranch(setarray);
      } else {
        setselectedBranch(e.target.value);
        setSelectedAll(false);
      }
    } else {
      setselectedBranch([]);
      setSelectedAll(false);
    }
  }

  const handleChangeSelectAllBranch = (event) => {
    setAllBranchSelected(event.target.checked);
  };

  return (
    <Dialog
      maxWidth="xl"
      className={classes.modal}
      open={open}
      disableEnforceFocus
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
    >
      <DialogTitle id="alert-dialog-title" onClose={handleClose}>
        {edit ? "Edit Branch Wise Video" : "Add Branch Wise Video"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-controlled-open-select-label">
                  Select Role
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={selectedRole || []}
                  style={{ minWidth: 120, maxWidth: 550 }}
                  MenuProps={MenuProps}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <MenuItem value={1} key={1} disabled>
                    Select Role
                  </MenuItem>
                  {roleList &&
                    roleList.length !== 0 &&
                    roleList.map((dropItem) => (
                      <MenuItem
                        style={{
                          color:
                            selectedRole &&
                            selectedRole.filter(
                              (gradeData) => gradeData === dropItem.id
                            ).length === 1
                              ? "white"
                              : "",
                          backgroundColor:
                            selectedRole &&
                            selectedRole.filter(
                              (gradeData) => gradeData === dropItem.id
                            ).length === 1
                              ? "darkslateblue"
                              : "",
                        }}
                        value={dropItem.id}
                        key={dropItem.id}
                      >
                        {dropItem.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {allBranchSelected !== true && (
              <Grid item md={6} xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-controlled-open-select-label">
                    Select branches
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={selectedbranch || []}
                    style={{ minWidth: 120, maxWidth: 550 }}
                    onChange={(e) => handleSelectALl(e)}
                    className={classes.normal}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>Select Branch</MenuItem>
                    <MenuItem key="0" value="0">
                      Select All
                    </MenuItem>
                    {branchList &&
                      branchList.length !== 0 &&
                      branchList.map((dropItem) => (
                        <MenuItem
                          style={{
                            color:
                              selectedbranch &&
                              selectedbranch.filter(
                                (gradeData) => gradeData === dropItem.id
                              ).length === 1
                                ? "white"
                                : "",
                            backgroundColor:
                              selectedbranch &&
                              selectedbranch.filter(
                                (gradeData) => gradeData === dropItem.id
                              ).length === 1
                                ? "darkslateblue"
                                : "",
                          }}
                          value={dropItem.id}
                          key={dropItem.id}
                        >
                          {dropItem.branch_name ? dropItem.branch_name : ""}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.textField}
                margin="dense"
                label="Video Title"
                required
                value={videoTitle || ""}
                onChange={(e) => setVideoTitle(e.target.value)}
                variant="outlined"
              />
            </Grid>

            <Grid md={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={allBranchSelected}
                    onChange={handleChangeSelectAllBranch}
                  />
                }
                label="Select All Branch"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <input
                style={{ display: "none" }}
                className={classes.fileUpload}
                id="outlined-button-file"
                type="file"
                onChange={(e) => functionToValidateVideo(e.target.files[0])}
              />
              <label htmlFor="outlined-button-file">
                <b style={{ fontFamily: "" }}>Browse Video</b>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.fileUpload}
                  startIcon={<CloudUploadIcon />}
                >
                  Browse
                </Button>{" "}
                &nbsp;&nbsp;
                {video && video !== "error" && typeof video === "string" && (
                  <>
                    <video
                      src={video}
                      width="150"
                      height="100"
                      controls
                      controlsList="nodownload"
                      preload="auto"
                    >
                      <source
                        src={video}
                        type="video/mp4"
                        height="100"
                        width="100"
                      />
                      <track
                        src={video}
                        kind="captions"
                        srcLang="en"
                        label="english_captions"
                      />
                    </video>
                  </>
                )}
              </label>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant="h6">Enter Video Text : </Typography>
              <TinyTextEditor
                id="LearningvideoDescreption"
                initialValue={videoDescreption || ""}
                value={videoDescreption || ""}
                onChange={(content) => setVideoDescreption(content)}
              />
            </Grid>
            <Divider className={classes.divider} />
            <Grid item md={5} />
            <Grid item md={2} xs={12}>
              <Button
                color="primary"
                variant="contained"
                className={classes.textField}
                onClick={() => saveLearningData()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      </DialogContent>
      {loader}
    </Dialog>
  );
}

LearningModel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  learningVideoData: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  branchList: PropTypes.instanceOf(Array).isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
};

export default withStyles(styles)(LearningModel);
