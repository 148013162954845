/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  // Modal,
  // Fade,
  // Backdrop,
  IconButton,
  TextField,
  TableFooter,
  TablePagination,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@material-ui/core";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from "@material-ui/icons/LastPage";
import styles from "./report.style";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import course from "../../../studentCourse/courses/course";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
let array = [];
let courseVal = [];

let link1;
let link2;
let link3;
let link4;
let finalUrl;
const Report = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  // const [level, setLevel] = useState('');
  const [checkLoader] = useState(false);
  // const [levelUpdate, setLevelUpdate] = useState('');
  // const [levelId, setlevelId] = useState('');
  // const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [roleId, setRoleId] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState("");
  const [bottomHRef, setBottomHRef] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [permission, setPermission] = useState([]);

  // const [courseList,setCourseList]=useState('');

  // const verifyEmail = auth
  // && auth.academic_profile
  // && auth.academic_profile.user.email;

  // const verify=verifyEmail.includes("@orchids.edu.in")
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  var verify = loginData.role_permission.is_orchids;

  const [courseIdVal, setCourseIdVal] = useState([]);
  const alert = useAlert();
  const {
    data: levelList,
    isLoading: levelListLoading,
    doFetch: fetchlevel,
  } = useFetch([]);
  // const {
  //   data: roleList,
  //   isLoading: roleListLoading,
  //   doFetch: fetchRoles,
  // } = useFetch(null);

  const {
    data: courseList,
    isLoading: courseListLoading,
    doFetch: fetchcourseList,
  } = useFetch(null);
  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);
  // console.log(levelList.total_pages);
  // const {
  //   data: UpdateLevelResponse,
  //   isLoading: updateLevelLoading,
  //   doFetch: fetchupdateLevel,
  // } = useFetch([]);

  const [trainingTypeList] = useState([
    {
      id: "1",
      name: "Induction Training",
    },
    {
      id: "4",
      name: "Subject Training",
    },
    // {
    //   id: '3',
    //   name: 'Trainer Driven',
    // },
  ]);

  const [trainingType, setTrainingType] = useState("");
  let isRoleChosen = false;
  let isTrainingTypeChosen = false;
  // let array=[];

  useEffect(() => {
    // console.log(courseId,courseIdVal)
    // courseVal.length=0;
    courseVal.splice(0, courseVal.length);
    for (let i = 0; i < courseId.length; i += 1) {
      courseVal.push(
        courseList &&
          courseList.filter((item) => item.course_name === courseId[i])[0].id
      );
    }
    // console.log("Test",courseVal);
    // if (page || rowsPerPage) {
    if (branch && trainingType && roleId && courseId) {
      if (startDate && endDate) {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page +
            1}&page_size=${rowsPerPage ||
            10}&role_id=${array}&course_type=${trainingType}&course_id=${courseVal}&start_date=${startDate}&end_date=${endDate}&branch=${branch}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Course_Wise_Report"),
          },
        });
      } else if (startDate) {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page +
            1}&page_size=${rowsPerPage ||
            10}&role_id=${array}&course_type=${trainingType}&course_id=${courseVal}&start_date=${startDate}&branch=${branch}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Course_Wise_Report"),
          },
        });
      } else if (endDate) {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page +
            1}&page_size=${rowsPerPage ||
            10}&role_id=${array}&course_type=${trainingType}&course_id=${courseVal}&end_date=${endDate}&branch=${branch}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Course_Wise_Report"),
          },
        });
      } else {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page +
            1}&page_size=${rowsPerPage ||
            10}&role_id=${array}&course_type=${trainingType}&course_id=${courseVal}&branch=${branch}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Course_Wise_Report"),
          },
        });
      }
    }
  }, [searchClicked, page, fetchlevel, rowsPerPage, auth.personal_info.token]);

  useEffect(() => {
    courseVal.splice(0, courseVal.length);
    for (let i = 0; i < courseId.length; i += 1) {
      courseVal.push(
        courseList &&
          courseList.filter((item) => item.course_name === courseId[i])[0].id
      );
    }
    if (roleId && trainingType && courseId) {
      if (startDate && endDate) {
        setBottomHRef([
          {
            csv: `${
              urls.courseWiseReportExcelApi
            }?course_id=${courseVal}&role_id=${array}&course_type=${trainingType}&start_date=${startDate}&end_date=${endDate}&branch=${branch}&Authorization=Bearer ${
              auth.personal_info.token
            }&module=${localStorage.getItem(
              "Course_Wise_Report"
            )}&export_type=csv`,
          },
        ]);
      } else if (startDate) {
        setBottomHRef([
          {
            csv: `${
              urls.courseWiseReportExcelApi
            }?course_id=${courseVal}&role_id=${array}&course_type=${trainingType}&start_date=${startDate}&branch=${branch}&Authorization=Bearer ${
              auth.personal_info.token
            }&module=${localStorage.getItem(
              "Course_Wise_Report"
            )}&export_type=csv`,
          },
        ]);
      } else if (endDate) {
        setBottomHRef([
          {
            csv: `${
              urls.courseWiseReportExcelApi
            }?course_id=${courseVal}&role_id=${array}&course_type=${trainingType}&end_date=${endDate}&branch=${branch}&Authorization=Bearer ${
              auth.personal_info.token
            }&module=${localStorage.getItem(
              "Course_Wise_Report"
            )}&export_type=csv`,
          },
        ]);
      } else {
        setBottomHRef([
          {
            csv: `${
              urls.courseWiseReportExcelApi
            }?course_id=${courseVal}&role_id=${array}&course_type=${trainingType}&branch=${branch}&Authorization=Bearer ${
              auth.personal_info.token
            }&module=${localStorage.getItem(
              "Course_Wise_Report"
            )}&export_type=csv`,
          },
        ]);
      }
    }
  }, [
    setBottomHRef,
    roleId,
    trainingType,
    courseId,
    startDate,
    endDate,
    branch,
  ]);

  const handleStartDateChange = (val) => {
    // console.log(array)
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
    // console.log(array)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }
  useEffect(() => {
    // fetchlevel({
    //   url: `${urls.userReportApi}?course_id=${courseId}`,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //   },
    //   fetchlevel,
    // });
    link1 = `${urls.getRoleRegistration}?search=orchids&user_type=USER`;
    link2 = `${urls.getRoleRegistration}?search=other&user_type=USER`;
    link3 = `${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`;
    link4 = `${urls.getRoleRegistration}?search=other&user_type=ADMIN`;

    finalUrl =
      localStorage.getItem("userType") === "USER"
        ? verify
          ? link1
          : link2
        : localStorage.getItem("userType") === "ADMIN"
        ? verify
          ? link3
          : link4
        : null;
    async function getRole() {
      const response = await fetch(finalUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Course_Wise_Report"),
        },
      });
      const getData = await response.json();
      return getData;
    }
    getRole().then((data) => {
      // console.log(data);
      setRoleList(data);
    });

    // fetchRoles({
    //   url: urls.courseRoleApi,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //   },
    // });
    fetchBranches({
      url: urls.getBranchNamesApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Course_Wise_Report"),
      },
    });
  }, [fetchlevel, fetchBranches, auth.personal_info.token]);

  let loader = null;
  if (levelListLoading || checkLoader || courseListLoading) {
    loader = <Loader open />;
  }

  const getReports = () => {
    setSearchClicked(!searchClicked);
    setPage(0);
    if (!branch) {
      alert.warning("Select a branch");
      return;
    }
    if (!roleId) {
      alert.warning("Select a role");
      return;
    }
    if (!trainingType) {
      alert.warning("Select a training type");
      return;
    }
    if (!courseId) {
      alert.warning("Select a course");
    }
  };

  const roleChosen = (e) => {
    // setRoleId(e.target.value);
    // console.log(branch)
    setRoleId(e.target.value);
    isRoleChosen = true;
    setCourseId("");
    setCourseIdVal([]);
    setTrainingType("");
  };

  // async function getCourse(e) {
  //   const response = await fetch(
  //     `${urls.courseListApi}?role_id=${array}&course_type=${e.target.value}`,
  //     {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   });
  //   const getData = await response.json();
  //   return getData;
  // }

  const trainingTypeChosen = (e) => {
    // console.log(isRoleChosen);
    if (branch === "") {
      alert.warning("select Branch");
      return;
    }
    if (roleId.length === 0) {
      alert.warning("Select Role");
      return;
    }

    // const array = [];
    // array.length=0;
    // console.log("role"+roleId);
    array.splice(0, array.length);
    console.log("name", roleId);
    for (let i = 0; i < roleId.length; i += 1) {
      array.push(
        roleList &&
          roleList.response.filter((item) => item.role_name === roleId[i])[0]
            .role_id
      );
    }
    console.log("role id", array);

    setTrainingType(e.target.value);
    isTrainingTypeChosen = true;

    fetchcourseList({
      url: `${urls.courseListApi}?role_id=${array}&course_type=${e.target.value}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Course_Wise_Report"),
      },
    });
    // getCourse(e).then((data) => {
    //   console.log(data);
    //   setCourseList(data);
    // });

    setCourseId("");
    // console.log(array)
  };

  const setCourse = (e) => {
    setCourseId(e.target.value);
    setCourseIdVal(e.target.value);
  };

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem("Course_Wise_Report"));
  }, []);

  const isAllSelected =
    roleList?.response?.length > 0 &&
    roleId?.length === roleList?.response?.length;
  const handleChange = (event) => {
    const value = event.target.value;
    console.log(value[value.length - 1], "handleChange");
    const roleeList = roleList.response.map((item) => item?.role_name);
    if (value[value.length - 1] === "all") {
      setRoleId(roleId?.length === roleList?.response?.length ? [] : roleeList);

      return;
    }
    setRoleId(value);
  };

  const isAllCoursesSelected =
    courseList?.length > 0 && courseIdVal?.length === courseList?.length;

  console.log(
    isAllCoursesSelected,
    courseList?.length,
    courseIdVal?.length,
    "isAllCoursesSelected"
  );

  const handleCourseChange = (event) => {
    const value = event.target.value;
    console.log(value[value.length - 1], "handleCourseChange");
    const coursesList = courseList.map((item) => item?.course_name);
    if (value[value.length - 1] === "all") {
      setCourseIdVal(
        courseIdVal?.length === courseList?.length ? [] : coursesList
      );
      setCourseId(
        courseIdVal?.length === courseList?.length ? [] : coursesList
      );
      return;
    }
    setCourseIdVal(value);
    setCourseId(value);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Course Wise User Report</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <Typography>
                  Select Branch
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={branch || ""}
                  onChange={(e) => setBranch(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black" }}
                >
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((data) => (
                      <MenuItem
                        value={data.id}
                        key={data.id}
                        name={data.branch_name}
                      >
                        {data.branch_name ? data.branch_name : ""}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item md={2} xs={12}>
                {/* <FormControl> */}
                {/* <InputLabel id="demo-mutiple-checkbox-label" style={{width:'90%'}}/> */}
                <Typography>
                  Select Role
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={roleId || []}
                    // onChange={(e) => setSchool(e.target.value)}
                    onChange={handleChange}
                    // onChange={(e) => handleSelectALlSchool(e)}
                    multiple
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black" }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            roleId.length > 0 && roleId.length < roleList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {roleList &&
                      roleList.length !== 0 &&
                      roleList.response.map((data) => (
                        <MenuItem
                          value={data.role_name}
                          key={data.role_id}
                          name={data.role_name}
                        >
                          <Checkbox
                            checked={roleId.indexOf(data.role_name) > -1}
                          />
                          <ListItemText primary={data.role_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* <Typography>
                      Select Role
                      <b style={{ color: 'red' }}>*</b>
                    </Typography>
                    <Select
                      margin="dense"
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      fullWidth
                      className={classes.textField}
                      variant="outlined"
                      style={{ color: 'black',height:'60%',}}
                      multiple
                      value={roleId || ''}
                      // onChange={handleChange}
                      onChange={(e) => {roleChosen(e); }}
                      // input={<Input/>}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {roleList
                        && roleList.length !== 0
                        && roleList.response.map((data) => (
                        <MenuItem key={data.role_id} value={data.role_name} >
                          <Checkbox checked={roleId.indexOf(data.role_name) > -1} />
                          <ListItemText primary={data.role_name} />
                        </MenuItem>
                      ))}
                    </Select> */}

                {/* </FormControl> */}
              </Grid>

              <Grid item md={2} xs={12}>
                <Typography>
                  Select Training
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={trainingType || ""}
                  onChange={(e) => trainingTypeChosen(e)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: "black", marginLeft: "10px" }}
                >
                  {trainingTypeList &&
                    trainingTypeList.length !== 0 &&
                    trainingTypeList.map((data, i) =>
                      verify || data.id === "4" ? (
                        <MenuItem
                          value={data.id}
                          key={data.id}
                          name={data.name}
                        >
                          {data.id ? data.name : ""}
                        </MenuItem>
                      ) : (
                        ""
                      )
                    )}
                </Select>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography>
                  Select Course
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    style={{
                      color: "black",
                      height: "60%",
                      marginLeft: "10px",
                    }}
                    multiple
                    value={courseIdVal || []}
                    onChange={handleCourseChange}
                    // onChange={(e) => setCourse(e)}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllCoursesSelected}
                          indeterminate={
                            courseIdVal.length > 0 &&
                            courseIdVal.length < courseList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {courseList &&
                      courseList.length !== 0 &&
                      courseList.map((data) => (
                        <MenuItem
                          name={data.course_name}
                          key={data.id}
                          value={data.course_name}
                        >
                          <Checkbox
                            checked={courseIdVal.indexOf(data.course_name) > -1}
                          />
                          <ListItemText primary={data.course_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item md={3} xs={12}>
                <Typography>
                  Select Course
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={courseId || ''}
                  onChange={(e) => setCourse(e)}
                  // onChange={(e) => trainingTypeChosen(e)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {courseList
                && courseList.length !== 0
                && courseList.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.course_name}
                  >
                    {data.course_name ? data.course_name : ''}
                  </MenuItem>
                ))}

                </Select>
              </Grid> */}
              <Grid item md={2} xs={12}>
                <Typography>Start Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleStartDateChange(e.target.value)}
                  type="date"
                  value={startDate || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography>End Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleEndDateChange(e.target.value)}
                  type="date"
                  value={endDate || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Button
                  style={{ marginTop: "30px" }}
                  className={classes.updateButton}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => getReports()}
                >
                  Search
                </Button>
                {/* </Grid>

              <Grid item md={1} xs={12}> */}
                {permission.can_export ? (
                  <Button
                    style={{ marginTop: "30px", marginLeft: "5%" }}
                    className={classes.updateButton}
                    size="small"
                    variant="contained"
                    color="primary"
                    href={bottomHRef && bottomHRef[0].csv}
                    disabled={
                      !(
                        roleId !== "" &&
                        roleId !== undefined &&
                        trainingType !== "" &&
                        trainingType !== undefined &&
                        courseId !== "" &&
                        courseId !== undefined &&
                        branch !== "" &&
                        branch !== undefined
                      )
                    }
                  >
                    Download
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
      <>
        {levelList && levelList.length !== 0 && (
          <Paper className={classes.paper}>
            {levelList &&
              levelList.courses.map((courseItr, itr) => (
                <>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="spanning table"
                    style={{ marginTop: "4%" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan="7"
                          style={{ fontWeight: "bold", fontSize: "120%" }}
                          align="left"
                        >
                          ​ Courses:{" "}
                          {levelList &&
                            levelList.length !== 0 &&
                            levelList.courses[itr].course_name}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          S.No
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          Name{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          ERP{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          Branch{" "}
                        </TableCell>
                        {/* <TableCell style={{ fontWeight: 'bold' }} align="left">
                      {console.log("hi",levelList.courses[itr].result[0].chapters.length)}
                    </TableCell> */}
                        {levelList &&
                          levelList.length !== 0 &&
                          levelList.courses[itr] &&
                          levelList.courses[itr].chapter_title &&
                          levelList.courses[itr].chapter_title.length > 0 &&
                          levelList.courses[itr].chapter_title.map(
                            (chapterTitle, i) => (
                              <TableCell
                                colSpan={
                                  levelList &&
                                  levelList.courses[itr].result[0] &&
                                  levelList.courses[itr].result[0].chapters[
                                    i
                                  ] !== undefined
                                    ? (Object.keys(
                                        Object.values(
                                          levelList.courses[itr].result[0]
                                            .chapters[i]
                                        )[0]
                                      ).length /
                                        3) *
                                      2
                                    : 1
                                }
                                key={i}
                                style={{ fontWeight: "bold" }}
                                align="center"
                              >
                                {chapterTitle !== ""
                                  ? chapterTitle
                                  : "No Chapter Name"}
                              </TableCell>
                            )
                          )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" colSpan={4} />
                        {levelList &&
                          levelList.length !== 0 &&
                          levelList &&
                          levelList.courses[itr].result &&
                          levelList.courses[itr].result[0] &&
                          levelList.courses[itr].result[0].chapters.map(
                            (chapter) =>
                              Object.entries(chapter).map(([key, value], i) => (
                                <>
                                  {Object.entries(value).map(
                                    ([keyy, valuee]) => {
                                      if (keyy.toString().startsWith("title")) {
                                        return (
                                          <>
                                            <TableCell
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {valuee.toString() !== ""
                                                ? `${valuee}`
                                                : `Quiz ${parseInt(
                                                    keyy.split("title")[1],
                                                    10
                                                  ) + 1}`}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Attempts
                                            </TableCell>
                                          </>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </>
                              ))
                          )}
                        {/* <TableCell>
                          {console.log(levelList.courses[0].result[0].chapters)}
                          hi
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <>
                      <TableBody>
                        {levelList &&
                        levelList.length !== 0 &&
                        levelList &&
                        levelList.courses[itr].result &&
                        levelList.courses[itr].result.length === 0 ? (
                          <Typography
                            variant="h5"
                            style={{ textAlign: "center", color: "blue" }}
                          >
                            Records Not Found
                          </Typography>
                        ) : (
                          levelList &&
                          levelList.courses[itr].result &&
                          levelList.courses[itr].result.map((data, i) => (
                            <TableRow key={data.user_id}>
                              <TableCell align="left">{i + 1}</TableCell>
                              <TableCell align="left">{data.name}</TableCell>
                              <TableCell align="left">{data.erp}</TableCell>
                              <TableCell align="left">
                                {data.branch && data.branch.length !== 0
                                  ? data.branch.reduce(
                                      (accumulator, eachBranch) =>
                                        `${accumulator}, ${eachBranch}`
                                    )
                                  : null}
                              </TableCell>
                              {data.chapters &&
                                data.chapters.length > 0 &&
                                data.chapters.map((chapter) =>
                                  Object.entries(chapter).map(
                                    ([key, value]) => (
                                      <>
                                        {Object.entries(value).map(
                                          ([keyy, valuee]) => {
                                            if (
                                              !keyy
                                                .toString()
                                                .startsWith("title")
                                            ) {
                                              return (
                                                <TableCell align="center">
                                                  {valuee}
                                                </TableCell>
                                              );
                                            }
                                            return null;
                                          }
                                        )}
                                      </>
                                    )
                                  )
                                )}
                            </TableRow>
                          ))
                        )}
                        <TableRow></TableRow>
                      </TableBody>
                    </>
                  </Table>
                </>
              ))}
            <Table>
              {levelList !== "undefined" && ( //.result.length
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelDisplayedRows={() =>
                        `Page ${page + 1} of ${+levelList.total_pages}`
                      }
                      rowsPerPageOptions={[10, 20, 30]}
                      count={+levelList.count}
                      colSpan={3}
                      rowsPerPage={rowsPerPage || 10}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableCell>
                      <IconButton
                        onClick={firstPageChange}
                        disabled={page === 0 || page === 1}
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          lastPageChange(levelList.total_pages - 1)
                        }
                        disabled={page === +levelList.total_pages - 1}
                      >
                        <LastPageIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Paper>
        )}
      </>
      <div />
      {loader}
    </>
  );
};

Report.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Report);
