/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  withStyles,
  Divider,
  Grid,
  Button,
  TextField,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './courseMessage.Style';
import useFetch from '../../../../hoc/useFetch';
import { useAlert } from '../../../../hoc/alert/alert';
import Loader from '../../../../hoc/loader';
import urls from '../../../../url';

const CourseMessage = ({
  classes,
  userIdNo,
  backPage,
  // nextPage,
  pageNo,
}) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [welcomeMsg, setWelcomeMsg] = useState('');
  const [completionMsg, setCompletionMsg] = useState('');
  const [loading, setloading] = useState(false);
  const alert = useAlert();

  const {
    data: courseMesRes,
    isLoading: courseMesLoadding,
    doFetch: courseMesFetch,
  } = useFetch();
  const {
    data: saveDataResponse,
    isLoading: saveLoading,
    doFetch: saveMegFetch,
  } = useFetch();

  useEffect(() => {
    setWelcomeMsg(
      (courseMesRes && courseMesRes && courseMesRes.welcome_message) || '',
    );
    setCompletionMsg(
      (courseMesRes && courseMesRes && courseMesRes.completion_message) || '',
    );
  }, [courseMesRes]);

  function functionToFetchCourse() {
    courseMesFetch({
      url: `${urls.coursesMsg}?course_id=${userIdNo}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
  }

  useEffect(() => {
    if (auth) {
      functionToFetchCourse();
    }
  }, [courseMesFetch, auth.personal_info.token, saveDataResponse]);

  const PublichCourseFunction = () => {
    const publishEdit = {
      is_authorize_course: true,
    };
    setloading(true);
    fetch(`${urls.publishCourse}/${userIdNo}/update_is_course_to_publish/`, {
      method: 'PUT',
      body: JSON.stringify(publishEdit),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Courses')
      },
    }).then((res) => {
      if (res.status === 302) {
        setloading(false);
        alert.warning('This Course is already send to Authorize');
      }
      if (res.status === 200) {
        setloading(false);
        alert.success('Course is successfully send to Authorize');
        functionToFetchCourse();
        return res.json();
      }
      if (res.status !== 200 && res.status !== 302) {
        setloading(false);
        alert.warning('somthing went wrong please try again ');
      }
      return 0;
    });
  };

  const updateFunction = () => {
    // const editId = userIdNo;
    if (welcomeMsg || completionMsg) {
      const data = {
        id: userIdNo,
        welcome_message: welcomeMsg,
        completion_message: completionMsg,
      };
      saveMegFetch({
        url: `${urls.coursesMsg}`,
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
        },
      });
    }

    if (!welcomeMsg || !completionMsg) {
      alert.warning('Empty Filled , please Fill All Field');
    }
  };

  let loader = null;
  if (saveLoading || courseMesLoadding || loading) {
    loader = <Loader open />;
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.head}>
          Course Message
        </Typography>
      </Paper>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                className={classes.textField}
                id="outlined-full-width"
                label="Welcome message"
                margin="dense"
                required
                value={welcomeMsg || ''}
                onChange={(e) => setWelcomeMsg(e.target.value)}
                variant="outlined"
                multiline
                rows={6}
                rowsMax={8}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                className={classes.textField}
                id="outlined-full-width"
                label="Completion message"
                margin="dense"
                required
                value={completionMsg || ''}
                onChange={(e) => setCompletionMsg(e.target.value)}
                variant="outlined"
                multiline
                rows={6}
                rowsMax={8}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                className={classes.saveButton}
                variant="contained"
                color="primary"
                onClick={() => updateFunction()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>

      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item md={7} xs={12}>
          {courseMesRes && courseMesRes.is_authorize_course && (
            <Typography variant="h6" style={{ color: 'blue' }}>
              Your Course has been sent for Authorization
            </Typography>
          )}
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            className={classes.textField}
            color="primary"
            variant="contained"
            disabled={pageNo === '0'}
            onClick={() => backPage()}
          >
            Back
          </Button>
        </Grid>
        <Grid item md={3} xs={12}>
          <Button
            className={classes.textField}
            color="primary"
            variant="contained"
            disabled={courseMesRes && courseMesRes.is_authorize_course}
            onClick={() => PublichCourseFunction()}
          >
            Publish Course
          </Button>
        </Grid>
      </Grid>
      {loader}
    </>
  );
};

CourseMessage.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  userIdNo: PropTypes.string.isRequired,
  backPage: PropTypes.func.isRequired,
  // nextPage: PropTypes.func.isRequired,
  pageNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(CourseMessage);
