/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { useTheme } from '@material-ui/core/styles';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Button from '@material-ui/core/Button';

import {
  Drawer,
  // AppBar,
  // Toolbar,
  // Typography,
  IconButton,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  // withStyles,
} from '@material-ui/core/';
import {
  NavLink,
  //  Link,
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import {
  // Input as InputIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import styles from './TeacherDashboard.style';
import ComponentList from './TeacherComponentList';

function TeacherDashboard() {
  const classes = styles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [pathnName] = useState('/principalDashboard/modules');
  const [loginData] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [sidebarClicked, setSidebarClicked] = useState(false);
  // const [routeLink, setRouteLink] = useState("");

  const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
  ));

  // const role = loginData && loginData.personal_info && loginData.personal_info.role;
    const role= "teacher"
  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  // const handleMenu = () => {
  //   localStorage.clear();
  //   window.location = '/';
  // };

  // const handleBack = () => {
  //   localStorage.removeItem('principalCourseType');
  //   window.location = '/inHouseCourses';
  // };

  const sideBarList = ComponentList;
  const roleList = () => {
    if (role) {
      const roleLower = role.toLowerCase();
      // console.log('>>>', roleLower);
      return sideBarList[roleLower];
    }
    return 0;
  };

  function onListClick(route) {
    setSidebarClicked((c) => !c);
    // eslint-disable-next-line no-console
    console.log(route);
    // setRouteLink(roleList().filter(item => item.link === route)[0]);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" style={{ color: 'white' }}>
            Sure Learning Principal Dashboard
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={handleBack} color="inherit">
              <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={handleMenu} color="inherit">
              <InputIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar} style={{ marginTop: '12vh' }}>
          {open === false ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              // className={clsx(classes.menuButton, {
              //   [classes.hide]: open
              // })}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <BrowserRouter>
            {roleList()
              && roleList().length
              && roleList().map((text, index) => (text && text.name ? (
                <ListItem
                  key={index}
                  button
                  component={ForwardNavLink}
                  to={text.link}
                  onClick={() => onListClick(text.link)}
                >
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    style={{ paddingRight: '1rem' }}
                  />
                </ListItem>
              ) : (
                ''
              )))}
          </BrowserRouter>
          <Divider />
        </List>
      </Drawer>
      <main className={classes.content} style={{ backgroundColor: pathnName === window.location.pathname ? '' : '' }}>
        <div className={classes.toolbar} />
        {sidebarClicked && (
          <BrowserRouter>
            <Switch>
              {roleList()
                && roleList().length
                && roleList().map((comp, index) => (
                  <Route
                    key={index}
                    path={comp && comp.link}
                    exact
                    component={comp && comp.component}
                  />
                ))}
            </Switch>
          </BrowserRouter>
        )}
        {!sidebarClicked && (
          <BrowserRouter>
            <Switch>
              {roleList()
                && roleList().length
                && roleList().map((comp, index) => (
                  <Route
                    key={index}
                    path={comp && comp.link}
                    exact
                    component={comp && comp.component}
                  />
                ))}
            </Switch>
          </BrowserRouter>
        )}
        {/* {routeLink ? (
          <routeLink.component />
        ) : (
          <BrowserRouter>
            <Switch>
              {roleList() &&
                roleList().length &&
                roleList().map((comp, index) => (
                  <Route
                    key={comp.name}
                    path={comp.link}
                    exact
                    component={comp.component}
                  />
                ))}
            </Switch>
          </BrowserRouter>
        )} */}
      </main>
    </div>
  );
}

export default TeacherDashboard;
