/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Grid, Typography, Button, Divider, TextField, IconButton,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Loader from '../../../hoc/loader';
import styles from './pptVideo.Style';
import { useAlert } from '../../../hoc/alert/alert';
import urls from '../../../url';
import axios from 'axios';

const PptVideo = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [pptVideoTitle, setPptVideoTitle] = useState('');
  const [VideoFile, setVideoFile] = useState('');
  const [videoResponseData, setvideoResponseData] = useState('');
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [permission,setPermission]=useState([]);


  function functionToSubmit() {
    if (!VideoFile) {
      alert.warning('Browse Video');
      return;
    } if (!pptVideoTitle) {
      alert.warning('Enter Title');
      return;
    }

    const toppic = Math.round(Math.random()) + 201 + Math.round(Math.random());

    const formData = new FormData();
    if (typeof (VideoFile) === 'object') {
      formData.append('video_file', VideoFile);
      formData.append('topic', toppic);
    }
    formData.append('video_title', pptVideoTitle);

    setLoading(true);
    fetch(urls.PPTVideoApi, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('PPT_Video')

      },
    })
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          alert.success('Videos Successfully Converted');
          return res.json();
        }
        if (res.status !== 201) {
          setLoading(false);
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => {
        setvideoResponseData(data);
      });
  }
  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  function copyFunction() {
    const copyText = document.getElementById('MyLink');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    alert.success('Successfully Copied');
  }
  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id

      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('PPT_Video'))
  }, [])


  return (
    <>
      <Typography variant="h4">PPT Video</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TextField
            className={classes.textField}
            label="PPT Video Title"
            margin="dense"
            fullWidth
            required
            value={pptVideoTitle || ''}
            onChange={(e) => setPptVideoTitle(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item md={11} xs={12}>       
          <label htmlFor="Video-Id">
          {permission.can_import ?   
            <Button
              variant="contained"
              color="primary"
              component="span"  
              className={classes.button}
              startIcon={<CloudUploadIcon />}
            >
              Browse
            </Button>
            :null}

          </label>
            &nbsp; &nbsp; &nbsp;
          <input
            style={{ display: 'none' }}
            className={classes.button}
            id="Video-Id"
            type="file"
            onChange={(e) => setVideoFile(e.target.files[0])}
          />
          {VideoFile && typeof (VideoFile) === 'object' && <span style={{ color: 'black' }}>{VideoFile && VideoFile.name}</span>}
        </Grid>
        <Grid item md={1} xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={functionToSubmit}
          >
            Submit
          </Button>
        </Grid>
        {videoResponseData && videoResponseData.video_title && (
          <>
            <Grid item md={12} xs={12}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>{`${videoResponseData && videoResponseData.video_title && videoResponseData.video_title}   link` || ''}</Typography>
            </Grid>
            <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
              <input id="MyLink" style={{ textAlign: 'center', width: '50%' }} value={(videoResponseData && videoResponseData.video_file && videoResponseData.video_file) || ''} />
              <IconButton toolTip="Copy Link" onClick={() => copyFunction()}><FileCopyIcon size="small" /></IconButton>
            </Grid>
          </>
        )}
      </Grid>
      {loader}
    </>
  );
};
PptVideo.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(PptVideo);
