/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles, Button, Grid, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import urls from '../../../../../url';
import styles from './TrainingChapters.style';
// import useFetch from '../../../../../hoc/useFetch';
import SubmitRating from '../TrainingUnit/SubmitRating';
import { useAlert } from '../../../../../hoc/alert';
import axios from 'axios';

function TrainingChapters({ classes, history }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [courseType] = useState(localStorage.getItem('coursesType'));
  // const [trainingChapters, setTrainingChapters] = useState();
  // const [loader, setLoader] = useState(false);
  const alert = useAlert();
  const moduleId = sessionStorage.getItem('moduleId');
  const classIdNo = sessionStorage.getItem('classId');
  const ratingStatus = sessionStorage.getItem('ratingStatus');

  const [bottomHRef, setBottomHRef] = useState('');
  const userId = auth.personal_info.user_id;
  const [certificateBtn, setCertificateBtn] = useState(true);
  // const modelName = sessionStorage.getItem('moduleName');
  const [opendialogue, setOpendialogue] = useState(false);
  const [permission, setPermission] = useState([]);

  const getLessonsId = (id, courseId, name) => {
    localStorage.setItem('contentId', id);
    localStorage.setItem('courseIdNO', courseId);
    sessionStorage.setItem('chapterName', name);
    if (auth.personal_info.role === 'Teacher' && (courseType === 'is_training_course' || courseType === 'self_driven')) {
      history.push('/teacherDashboard/lessons');
    } else {
      history.push('/subject_lessons');
    }
    // console.log(id);
    // inHouseModules
    // inHouesCouresModules
  };
  const [trainingChapters, setTrainingChapters] = useState();
  const [gettingTrainingChapters, setGettingTrainingChapters] = useState();
  // const {
  //   data: trainingChapters,
  //   isLoading: gettingTrainingChapters,
  //   doFetch: fetchTrainingChapters,
  // } = useFetch(null);

  const handleCloseDialog = () => {
    setOpendialogue(false);
  };
  // eslint-disable-next-line no-unused-vars
  const testButton = () => {
    setOpendialogue(true);
  };
useEffect(() => {
    setBottomHRef([
      {
        pdf: `${urls.getCourseCertificateUrl}?course_id=${moduleId}&user_id=${userId}&Authorization=${`Bearer ${auth.personal_info.token}`}&module=${localStorage.getItem('Subject_Training')}`,
      },
    ]);
  }, [setBottomHRef, moduleId, userId]);

  useEffect(() => {
    // getChapters(moduleId).then((data) => setTrainingChapters(data));
    let URL;
    if (
      ((auth.personal_info && auth.personal_info.role === 'AcademicHeads')
        || (auth.personal_info && auth.personal_info.role === 'Planner')
        || (auth.personal_info && auth.personal_info.role === 'Coordinator')
        || (auth.personal_info && auth.personal_info.role === 'AcademicManager')
        || (auth.personal_info && auth.personal_info.role === 'Principal')
        || (auth.personal_info && auth.personal_info.role === 'LeadTeacher'))
      && courseType === 'trainer'
    ) {
      URL = `${urls.inHouseModules}?course_id=${moduleId}&${courseType}=true&class_id=${classIdNo}`;
    } else {
      URL = `${urls.inHouseModules}?course_id=${moduleId}&${courseType}=true`;
    }
    // const URL = `${urls.inHouseModules}?course_id=${moduleId}&${courseType}=true`;
    // fetchTrainingChapters({
    //   url: URL,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //   },
    // });
    setGettingTrainingChapters(true);
    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Subject_Training')
      },
    })
      .then((res) => {
        setGettingTrainingChapters(false);
        if (res.status === 200) {
          alert.success('Successfully Fetched');
          return res.json();
        }
        // if (res.status !== 200) {
        //   alert.warning('something went wrong please try again ');
        // }
        return res.json();
      })
      .then((data) => {
        setTrainingChapters(data);
        if (courseType === 'subject_training' || courseType === 'self_driven' || courseType === 'is_training_course') {
          setOpendialogue(data.every((currentValue) => currentValue.is_completed) && ratingStatus === 'false');
        }
      });
  }, []);

  useEffect(() => {
    const isAllComplete = trainingChapters
      ? !trainingChapters.some((element) => element.is_completed === false)
      : false;

    if(isAllComplete === true) {
      setCertificateBtn(false);
      if(ratingStatus === 'false'){
        setOpendialogue(true);
      }
      else{
        setOpendialogue(false)
      }
    }
  }, [trainingChapters, ratingStatus]);

  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
   getPermissonData(localStorage.getItem('Subject_Training'))
  }, [])

  return (
    <>
      <div
        className={classes.chapterBackground}
        style={{
          height:
            trainingChapters && trainingChapters.length >= 8 ? 'auto' : '100vh',
          paddingTop:
            trainingChapters && trainingChapters.length >= 8 ? '15vh' : '0',
        }}
      >
        {gettingTrainingChapters ? (
          <>
            <img
              // open={open}
              className={classes.progress}
              alt="crash"
              src={require('../../../../../hoc/loder.gif')}
              width="100%"
              height="100%"
            />
          </>
        ) : (
          <>
            {/* <div className={classes.moduleCover}> */}
            {/* <Grid container>
              <Grid item md={12} xs={12} style={{ paddingTop: '100px', textAlign: 'center', color: 'white' }}>
                <Typography variant="h5">
                  <u>Module Name</u>
                  {' '}
                  :
                  {' '}
                  {modelName}
                </Typography>
              </Grid>
            </Grid> */}
            {trainingChapters && trainingChapters.length === 0 && (
              <Grid container justify="center">
                <Typography
                  variant="h5"
                  style={{
                    marginTop: '50px',
                    textAlign: 'center',
                    color: 'blue',
                  }}
                >
                  No Chapters Are Added
                </Typography>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              justify="center"
              className={classes.parentGrid}
            >
              {trainingChapters
                && trainingChapters.length !== 0
                && trainingChapters.map((chapter, index) => {
                  const colors = [
                    '#54688c',
                    '#f47a62',
                    '#4a66da',
                    '#75cba8',
                    // "#f2bf5e"
                  ];
                  const diffColors = index % 4;
                  return (
                    <Grid item md={3} xs={12} sm={6} key={chapter.id}>
                      <div
                        className={classes.moduleCards}
                        key={chapter.id}
                        style={{ backgroundColor: colors[diffColors] }}
                      >
                        <p className={classes.firstHead} title={chapter.title}>
                          {chapter.title}
                        </p>
                        <p className={classes.secondHead}>
                          {(courseType === 'trainer_driven' && 'Trainer Driven') || ''}
                          {(courseType === 'subject_training' && 'Induction') || ''}
                          {(courseType === 'self_driven' && 'Self Driven') || ''}
                          {(courseType === 'is_training_course' && 'Training Course') || ''}
                          {(courseType === 'trainer' && 'Trainer') || ''}
                        </p>
                        <Button
                          disabled={chapter.to_show === false}
                          onClick={() => getLessonsId(chapter.id, chapter.course_id, chapter.title)}
                        >
                          Click Here
                        </Button>
                        {chapter.is_completed === true && (
                          <span className={classes.secondHead}>Completed</span>
                        )}
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
        <div className={classes.backBtn}>
          <Link
            // eslint-disable-next-line no-nested-ternary
            to={(
              ((auth.personal_info && auth.personal_info.role === 'AcademicHeads')
              || (auth.personal_info && auth.personal_info.role === 'Planner')
              || (auth.personal_info && auth.personal_info.role === 'Coordinator')
              || (auth.personal_info && auth.personal_info.role === 'AcademicManager')
              || (auth.personal_info && auth.personal_info.role === 'Principal')
              || (auth.personal_info && auth.personal_info.role === 'LeadTeacher'))
              && courseType === 'self_driven'
            ) ? './principalDashboard/modules' : courseType === 'is_training_course' ? '/teacherDashboard/training_course' : './volumes'}
            style={{ textDecoration: 'none' }}
          >
            <Button color="secondary" variant="contained">
              Back to Modules
            </Button>
          </Link>
        </div>
        <Grid item md={12} xs={12} style={{ textAlign: 'center', padding: '5px' }}>
          <Button
            disabled={certificateBtn}
            color="secondary"
            variant="contained"
            // style={{ marginLeft: '0px' }}
            // onClick={() => handleDownloadCertificate()}
            href={bottomHRef && bottomHRef[0].pdf}
          >
            <GetAppRoundedIcon />
            Download Certificate
          </Button>
        </Grid>
      </div>
      {/* <Button onClick={testButton}>
        Test
      </Button> */}
      <SubmitRating open={opendialogue} history={history} moduleId={moduleId} onDialogClose={handleCloseDialog} />
    </>
  );
}
TrainingChapters.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TrainingChapters);
