export default (theme) => ({
  root: {
    margin: "auto",
    width: "90%",
    flexGrow: 1,
  },
  gridTwo: {
    marginTop: "15px",
    display: "flex",
  },
  gridOne: {
    marginTop: theme.spacing(2),
  },
  qulification: {
    marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
    display: "flex",
  },
  paperContentLarge: {
    padding: "15px",
    width: "50%",
    margin: "auto",
    overflowY: "hidden",
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
