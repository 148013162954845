/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  withStyles,
  Divider,
  Button,
  MenuItem,
  Modal,
  Fade,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Backdrop,
  LinearProgress,
  Select,
  Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import mqtt from 'mqtt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TinyTextEditor from '../../../../hoc/tinyMce/tinyTextEditor';
import styles from './landingPage.Style';
import useFetch from '../../../../hoc/useFetch';
import { useAlert } from '../../../../hoc/alert/alert';
import Loader from '../../../../hoc/loader';
import urls from '../../../../url';

const LandingPage = ({
  classes, userIdNo, backPage, nextPage, pageNo,
}) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [courseTitle, setCourseTitle] = useState('');
  const [description, setDescription] = useState('');
  const [titleSubheading, setTitleSubheading] = useState('');
  const [level, setLevel] = useState('');
  const [language, setLanguage] = useState('');
  const [subTitle, setSubTitle] = useState([]);
  const [video, setVideo] = useState('');
  const [image, setImage] = useState('');
  const [creatorImage, setCreatorImage] = useState('');
  const [creatorProDescription, setCreatorProDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [editCreater, setEditCreater] = useState(false);
  const [createrId, setCreaterId] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [topic, setTopic] = useState(0);
  const [loading, setloading] = useState(false);
  const [durationType, setDurationType] = useState('');

  const [hours, setHours] = useState('');
  const [minitus, setMinitus] = useState('');
  const [seconds, setSeconds] = useState('');
  // localStorage.setItem('pagerefreash', 0);
  const alert = useAlert();

  const {
    data: levelRes,
    isLoading: levelLoading,
    doFetch: levelFetch,
  } = useFetch(null);
  const {
    data: langRes,
    isLoading: langLoading,
    doFetch: langFetch,
  } = useFetch(null);
  const {
    // data: SubHeadRes,
    isLoading: SubHeadLoading,
    doFetch: SubHeadFetch,
  } = useFetch(null);

  const {
    data: CreatorProfileResponse,
    isLoading: addCreatorProfileFetchLoading,
    doFetch: addCreatorProfileFetch,
  } = useFetch(null);

  const {
    data: landingPageFetchResponse,
    isLoading: landingPageFetchResponseLoading,
    doFetch: landingPageFetchRes,
  } = useFetch();

  const {
    data: createrDeletedRes,
    isLoading: deletingCreaterData,
    doFetch: deleteCreterData,
  } = useFetch(null);

  let loader = null;
  if (
    addCreatorProfileFetchLoading
    || (video && video.type ? '' : loading)
    || addCreatorProfileFetchLoading
    || SubHeadLoading
    || langLoading
    || levelLoading
    || landingPageFetchResponseLoading
    || deletingCreaterData
  ) {
    loader = <Loader open />;
  }

  // function refreshPage() {
  //   localStorage.setItem('pagE', 1);
  //   window.location.reload(false);
  // }
  // useEffect(() => {
  //   if (parseInt(localStorage.getItem('pagerefreash'), 10) === 0 && parseInt(localStorage.getItem('pagE'), 10) !== 1) {
  //     refreshPage();
  //   }
  // }, []);

  useEffect(() => {
    if (landingPageFetchResponse) {
      setDescription(landingPageFetchResponse && landingPageFetchResponse.course_description);
      setCourseTitle(
        landingPageFetchResponse && landingPageFetchResponse.course_name,
      );
      setTitleSubheading(
        landingPageFetchResponse && landingPageFetchResponse.course_sub_heading,
      );
      setLevel(
        landingPageFetchResponse
          && landingPageFetchResponse.course_level
          && landingPageFetchResponse.course_level,
      );
      setLanguage(
        landingPageFetchResponse
          && landingPageFetchResponse.course_language
          && landingPageFetchResponse.course_language,
      );
      setSubTitle(
        landingPageFetchResponse
          && landingPageFetchResponse.course_subtitle
          && landingPageFetchResponse.course_subtitle,
      );
      setVideo(
        landingPageFetchResponse
          && landingPageFetchResponse.course_demo
          && landingPageFetchResponse.course_demo,
      );
      setImage(
        landingPageFetchResponse
          && landingPageFetchResponse
          && landingPageFetchResponse.course_image,
      );
      setDurationType(landingPageFetchResponse
        && landingPageFetchResponse
        && JSON.stringify(landingPageFetchResponse.is_course_duration));

      const words = landingPageFetchResponse
      && landingPageFetchResponse.course_duration.split(':');
      setHours(words[0]);
      setMinitus(words[1]);
      setSeconds(words[2]);

      setUploading(false);
    }
  }, [landingPageFetchResponse]);

  function functionForToGetData() {
    levelFetch({
      url: `${urls.coursesLevel}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
    langFetch({
      url: `${urls.coursesLang}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
    SubHeadFetch({
      url: `${urls.coursesSub}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
      },
    });
    landingPageFetchRes({
      url: `${urls.landingPage}?course_id=${userIdNo}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
      },
    });
    setTopic('');
    setUploadProgress(0);
  }
  useEffect(() => {
    functionForToGetData();
  }, [
    levelFetch,
    langFetch,
    SubHeadFetch,
    auth.personal_info.token,
    createrDeletedRes,
    CreatorProfileResponse,
  ]);

  useEffect(() => {
    if (CreatorProfileResponse) {
      setEditCreater(false);
      setOpen(false);
      setCreatorProDescription(null);
      setCreatorImage(null);
      setCreaterId(null);
    }
  }, [CreatorProfileResponse]);

  const handleClose = () => {
    setOpen(false);
    setEditCreater(false);
    setCreaterId(null);
    setCreatorImage(null);
    setCreatorProDescription(null);
  };

  // const addNewCreator = () => {
  //   setOpen(true);
  // };

  const SaveCreatorDetail = () => {
    if (!creatorImage || !creatorProDescription) {
      alert.warning('Fill all fields');
      return;
    }
    if (open) {
      const formData = new FormData();
      formData.append('course', userIdNo);
      if (creatorImage.name) {
        formData.append('image', creatorImage);
      }
      if (editCreater) {
        formData.append('id', createrId);
      }
      formData.append('title', creatorProDescription);

      addCreatorProfileFetch({
        url: `${urls.AddCreatorProfile}`,
        method: editCreater ? 'PUT' : 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
        },
      });
    }
    setOpen(false);
  };

  function editCreaterInf(data) {
    setEditCreater(true);
    setOpen(true);
    setCreatorProDescription(data.title);
    setCreatorImage(data.image);
    setCreaterId(data.id);
  }

  function deleteCreater(data) {
    deleteCreterData({
      url: `${urls.deleteCreaterApi}${data.id}/delete_creator_profile/`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        
      },
    });
  }

  useEffect(() => {
    // In prod use -> "mqtt.connect('wss://13.233.131.192:1883')" base on hostname
    if (topic) {
      const client = window.location.hostname === 'localhost'
        ? mqtt.connect('ws://localhost:1883')
        : mqtt.connect('wss://udaansurelearning.com:9001');
      client.on('connect', () => {
        client.subscribe(topic, (err) => {
          console.log(err, '>>>>>>>>>>>>>>>>>');
        });
      });
      client.on('message', (c, message) => {
        // message is Buffer
        setUploadProgress(message.toString());
      });
      if (Math.round(uploadProgress) === 100) {
        client.on('disconnect', () => {
          client.disconnect();
        });
      }
    }
  }, [topic]);

  const landingPageSubmitFunc = () => {
    if (!courseTitle) {
      alert.warning('Enter course Title');
      return;
    }
    setUploading(true);
    const a = Math.round(Math.random()) + userIdNo + 201;
    setTopic(a);
    const formData = new FormData();
    formData.append('id', userIdNo);
    if (video && video.name) {
      formData.append('course_demo', video);
      formData.append('topic', a);
    }
    if (image && image.name) {
      formData.append('course_image', image);
      console.log("submit"+image)
    }
    formData.append('course_name', courseTitle);
    formData.append('course_sub_heading', titleSubheading);
    formData.append('course_description', description);
    formData.append('course_language', language);
    formData.append('course_level', level);
    formData.append('course_subtitle', subTitle);
    formData.append('is_course_duration', durationType);
    if (durationType === 'false') {
      formData.append('course_duration', `${hours || '00'}:${minitus || '00'}:${seconds || '00'}`);
    } else {
      formData.append('course_duration', 0);
    }

    setloading(true);
    fetch(`${urls.landingPage}`, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
      },
    }).then((res) => {
      if (res.status === 200) {
        setloading(false);
        setUploading(false);
        functionForToGetData();
        alert.success('Successfully Uploaded');
        return res.json();
      }
      if (res.status !== 200) {
        setloading(false);
        setUploading(false);
        alert.warning('somthing went wrong please try again ');
      }
      return 0;
    });
  };

  function functionToHandleVideo(data) {
    if (data && data.type === 'video/mp4') {
      setVideo(data);
    } else {
      alert.warning('Upload  Video mp4 format Only');
    }
  }

  function functionToHandleImage(data) {
    if ((data && data.type === 'image/jpeg') || (data && data.type === 'image/png')) {
      setImage(data);
      console.log("img"+Image)
    } else {
      alert.warning('Upload Image in JPEG && PNG format Only');
    }
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.head}>
          Landing Page
        </Typography>
      </Paper>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        {/* Write Code Here */}
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                className={classes.textField}
                id="outlined-full-width"
                label="Course Title"
                margin="dense"
                required
                color="secondary"
                inputProps={{ style: { fontFamily: 'Times', color: 'black' } }}
                value={courseTitle || ''}
                onChange={(e) => setCourseTitle(e.target.value)}
                variant="outlined"
                multiline
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                className={classes.textField}
                id="outlined-full-width"
                label="Course Sub Title "
                margin="dense"
                color="secondary"
                inputProps={{ style: { fontFamily: 'Times', color: 'black' } }}
                value={titleSubheading || ''}
                onChange={(e) => setTitleSubheading(e.target.value)}
                variant="outlined"
                multiline
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography>Course Description  : </Typography>
              <TinyTextEditor
                id="courseDesc"
                onChange={(content) => setDescription(content)}
                initialValue={(landingPageFetchResponse && landingPageFetchResponse.course_description) || (description) || ''}
                value={(landingPageFetchResponse && landingPageFetchResponse.course_description) || (description) || ''}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item md={12} xs={12}>
            <Typography variant="h6">Set Course Duration : </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Radio
                checked={!!(durationType === 'true')}
                onChange={(e) => setDurationType(e.target.value)}
                value="true"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'B' }}
                color="primary"
              />
              Chapter Wise
            </Grid>
            <Grid item md={3} xs={12}>
              <Radio
                checked={!!(durationType === 'false')}
                onChange={(e) => setDurationType(e.target.value)}
                value="false"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'B' }}
                color="primary"
              />
              Course Wise
            </Grid>
            {durationType === 'false'
            && (
            <Grid item md={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                  <TextField
                    margin="dense"
                    required
                    type="number"
                    placeholder="000"
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 3); }}
                    value={hours || ''}
                    onChange={(e) => (e.target.value.length < 4 && (e.target.value > -1) ? setHours(e.target.value) : '')}
                    variant="outlined"
                    helperText="Hrs"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    margin="dense"
                    required
                    type="number"
                    placeholder="00"
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2); }}
                    value={minitus || ''}
                    onChange={(e) => (e.target.value.length < 3 && (e.target.value > -1) && e.target.value < 61 ? setMinitus(e.target.value) : '')}
                    variant="outlined"
                    helperText="Min"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    margin="dense"
                    required
                    type="number"
                    placeholder="00"
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2); }}
                    value={seconds || ''}
                    onChange={(e) => (e.target.value.length < 3 && (e.target.value > -1) && e.target.value < 61 ? setSeconds(e.target.value) : '')}
                    variant="outlined"
                    helperText="Sec"
                  />
                </Grid>
              </Grid>
            </Grid>
            )}
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography>Select language</Typography>
              <Select
                // multiple
                label="language"
                margin="dense"
                value={language || ''}
                onChange={(e) => setLanguage(e.target.value)}
                className={classes.textField}
                variant="outlined"
                style={{ color: 'black' }}
              >
                {langRes
                  && langRes.results.length !== 0
                  && langRes.results.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.language_name}
                    >
                      {data.language_name ? data.language_name : ''}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>Select Level</Typography>
              <Select
                id="Level"
                label="Level"
                margin="dense"
                value={level || ''}
                onChange={(e) => setLevel(e.target.value)}
                className={classes.textField}
                variant="outlined"
                style={{ color: 'black' }}
              >
                {levelRes
                  && levelRes.results.length !== 0
                  && levelRes.results.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.level_type}
                    >
                      {data.level_type ? data.level_type : ''}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {/* <Grid item md={4} xs={12}>
              <Typography>Select SubTitle</Typography>
              <Select
                multiple
                label="SubTitle"
                margin="dense"
                value={subTitle || ''}
                onChange={(e) => setSubTitle(e.target.value)}
                className={classes.textField}
                variant="outlined"
                style={{ color: 'black' }}
              >
                {SubHeadRes
                  && SubHeadRes.results.length !== 0
                  && SubHeadRes.results.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.subtitle_name}
                    >
                      {data.subtitle_name ? data.subtitle_name : ''}
                    </MenuItem>
                  ))}
              </Select>
            </Grid> */}
            <Grid item md={6} xs={12}>
              <div>
                <Typography variant="h5">Upload Video</Typography>
                {!video ? (
                  <>
                    <input
                      style={{ display: 'none' }}
                      className={classes.fileUpload}
                      id="outlined-button-file"
                      type="file"
                      onChange={(e) => functionToHandleVideo(e.target.files[0])}
                    />
                    <label htmlFor="outlined-button-file">
                      <Button
                        variant="outlined"
                        component="span"
                        size="small"
                        className={classes.fileUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Browse
                      </Button>
                      {' '}
                      &nbsp;&nbsp;
                      {video && video.name}
                    </label>
                  </>
                ) : (
                  <Card className={classes.card}>
                    <CardActionArea>
                      <video
                        width="320"
                        height="240"
                        controls
                        controlsList="nodownload"
                        className={classes.videoLoad}
                      >
                        <source
                          src={video}
                          type="video/mp4"
                          height="100"
                          width="100"
                        />
                        <track
                          src={video}
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                      </video>
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {/* {data.title} */}
                          Content....
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <span className={classes.spanUpdateBtn}>Update</span>
                      <input
                        style={{ display: 'none' }}
                        className={classes.fileUpload}
                        id="outlined-button-file"
                        type="file"
                        onChange={(e) => functionToHandleVideo(e.target.files[0])}
                      />
                      <label htmlFor="outlined-button-file">
                        <Button
                          variant="outlined"
                          component="span"
                          size="small"
                          className={classes.fileUpload}
                          startIcon={<CloudUploadIcon />}
                        >
                          Browse
                        </Button>
                        {' '}
                        &nbsp;&nbsp;
                        {video && video.name}
                      </label>
                    </CardActions>
                  </Card>
                )}
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <Typography variant="h5">Upload Image</Typography>
                {!image ? (
                  <>
                    <input
                      style={{ display: 'none' }}
                      className={classes.fileUpload}
                      id="outlined-button-filee"
                      type="file"
                      onChange={(e) => functionToHandleImage(e.target.files[0])}
                    />
                    <label htmlFor="outlined-button-filee">
                      <Button
                        variant="outlined"
                        component="span"
                        size="small"
                        className={classes.fileUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Browse
                      </Button>
                      {' '}
                      &nbsp; &nbsp;
                      {image && image.name}
                    </label>
                  </>
                ) : (
                  <Card className={classes.card}>
                    <CardActionArea>
                      <img
                        src={image}
                        alt="crashed"
                        className={classes.proImg}
                        width="484px"
                        height="240px"
                      />
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {/* {data.title} */}
                          Content....
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <span className={classes.spanUpdateBtn}>Update</span>
                      <input
                        style={{ display: 'none' }}
                        className={classes.fileUpload}
                        id="outlined-button-filee"
                        type="file"
                        onChange={(e) => functionToHandleImage(e.target.files[0])}
                      />
                      <label htmlFor="outlined-button-filee">
                        <Button
                          variant="outlined"
                          component="span"
                          size="small"
                          className={classes.fileUpload}
                          startIcon={<CloudUploadIcon />}
                        >
                          Browse
                        </Button>
                        {' '}
                        &nbsp; &nbsp;
                        {image && image.name}
                      </label>
                    </CardActions>
                  </Card>
                )}
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12} />
              <Grid item md={4} xs={12} />
              {/* <Grid item md={4} xs={12} className={classes.addUserbtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addUserbutton}
                  startIcon={<AddIcon />}
                  onClick={() => addNewCreator()}
                >
                  Add New Creator
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.creatorProSection}>
            {/* {CreatorProfileFunction()} */}
            {landingPageFetchResponse
              && landingPageFetchResponse.creator_profile.map((data) => (
                <Grid item md={4} xs={12} key={data.id}>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <img
                        src={data.image}
                        width="150"
                        alt="crashed"
                        height="150"
                        className={classes.cPro}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {/* Lizard */}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {data.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => deleteCreater(data)}
                      >
                        Delete
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => editCreaterInf(data)}
                      >
                        Update
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item md={4} xs={4}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => landingPageSubmitFunc()}
              disabled={
                (Math.round(uploadProgress) < 100
                  && Math.round(uploadProgress) > 0)
                || uploading
              }
            >
              Submit
            </Button>
            {uploading && (
              <Typography>Uploading Started... Please Wait</Typography>
            )}
          </Grid>
          <Grid container>
            <Grid item md={12} xs={12}>
              {Math.round(uploadProgress) < 100
                && Math.round(uploadProgress) > 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={parseInt(uploadProgress, 10) || 0}
                  />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            style={{ color: 'blue', textAlign: 'center' }}
          >
            <Typography variant="h6">
              {Math.round(uploadProgress) < 100
              && Math.round(uploadProgress) > 0
                ? `Uploading..( ${Math.round(uploadProgress * 100) / 100}% )`
                : ''}
              <b>
                {Math.round(uploadProgress) === 100 && uploading
                  ? ' Video Uploaded Successfully'
                  : ''}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <>
                <Typography variant="h5">
                  {editCreater ? 'Update Role' : 'Create Role'}
                </Typography>
                <Divider className={classes.divider} />
                <Grid container spacing={4}>
                  <Grid item md={4} xs={12} className={classes.backgroundHead}>
                    <Typography variant="h5" className={classes.uploadModal}>
                      Page creator Profile
                    </Typography>
                    <input
                      type="file"
                      onChange={(e) => setCreatorImage(e.target.files[0])}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.backgroundHead}>
                    <Typography variant="h5" className={classes.uploadModal}>
                      Page creator Description
                    </Typography>
                    <TextField
                      className={classes.textField}
                      margin="dense"
                      required
                      value={creatorProDescription || ''}
                      onChange={(e) => setCreatorProDescription(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      className={classes.saveCreatorButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => SaveCreatorDetail()}
                    >
                      Save Creator
                    </Button>
                  </Grid>
                  {editCreater && !creatorImage.name && (
                    <>
                      <Typography variant="h6">Uploaded Profile</Typography>
                      <Grid item md={12} xs={12}>
                        <img
                          src={creatorImage}
                          alt="Crashed"
                          width="200"
                          height="200"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            </div>
          </Fade>
        </Modal>
      </div>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item md={8} />
        <Grid item md={2} xs={6}>
          <Button
            color="primary"
            variant="contained"
            className={classes.textField}
            disabled={pageNo === '0'}
            onClick={() => backPage()}
          >
            Back
          </Button>
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            color="primary"
            variant="contained"
            className={classes.textField}
            disabled={pageNo === '7'}
            onClick={() => nextPage()}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      {loader}
    </>
  );
};
LandingPage.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  userIdNo: PropTypes.string.isRequired,
  backPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageNo: PropTypes.string.isRequired,
};
export default withStyles(styles)(LandingPage);
