/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  withStyles,
  Divider,
  Grid,
  Button,
  Paper,
  Checkbox,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import useFetch from '../../../../hoc/useFetch';
import urls from '../../../../url';
import styles from './courseSettings.Style';
import Loader from '../../../../hoc/loader';

const CourseSettings = ({
  classes, userIdNo, backPage, nextPage, pageNo,
}) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [selectCourseType, setSelectCourseType] = useState(null);
  const [selectedCourseVisibility, setSelectedCourseVisibility] = useState(null);
  const [enforceSequencing, setEnforceSequencing] = useState(false);
  const [enableDiscussion, setEnableDiscussion] = useState(false);
  const [inHouse, setInHouse] = useState(false);
  // const [subTraining,setSubTraining] = useState(false);
  const [volume, setVolume] = useState('')
  const [teachersVisibility, setTeachersVisibility] = useState('');
  const [selfDriven, setSelfDriven] = useState(false);
  const [trinerDriven, setTrinerDriven] = useState(false);
  const [subjectType, setSubjectType] = useState('');
  const [subjectId, setSubjectId] = useState(null);
  const [gradeId, setGradeId] = useState([]);
  const [selfAssign, setSelfAssign] = useState(false);
  const [selfCourse, setSelfCourse] = useState(false);
  const [trainingCourse, setTrainingCourse] = useState(false);
  const [asssignTo, setAssignTo] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [count, setCount] = useState(0);
  const [courseCategory, setCourseCategory] = useState('');
  const [courseSubCategory, setCourseSubCategory] = useState('');
  const [subjectsList, setsubjectsList] = useState([]);
  const [SelectedAllGrade, setSelectedAllGrade] = useState(false);
  const [trainingCourseVisibility, settrainingCourseVisibility] = useState('');
  const [volumeList, setVolumeList] = useState([]);
  const [attempt, setAttempt] = useState('');
  // const [state, setState] = React.useState({
  // trainingCourse: true,
  // });

  // const {
  //   data: subjectsList,
  //   isLoading: gettingSubjects,
  //   doFetch: fetchSubjects,
  // } = useFetch(null);

  const {
    data: gradeList,
    isLoading: gettingGrageList,
    doFetch: fetchGrade,
  } = useFetch(null);

  const {
    // data: roleList,
    isLoading: gettingRoles,
    doFetch: fetchRols,
  } = useFetch(null);

  const {
    data: CourseRoleList,
    isLoading: gettingCourseRoleList,
    doFetch: fetchCourseRoleList,
  } = useFetch([]);

  const handleVolume = (e) => {
    setVolume(e.target.value);
  }

  const {
    data: courseSettingsData,
    isLoading: courseSettingsDataLoading,
    doFetch: fetchCourseSettingData,
  } = useFetch(null);

  const {
    // data:submitCourseSettiongInfo,
    isLoading: submittingCouseSettingInfoLoading,
    doFetch: fetchSubmitCourseSetting,
  } = useFetch(null);

  const {
    data: categoryCourseList,
    isLoading: gettingCourseCategoryList,
    doFetch: fetchCourseCategoryList,
  } = useFetch([]);

  const {
    data: subCategoryCourseList,
    isLoading: gettingCourseSubCategoryList,
    doFetch: fetchCourseSubCategoryList,
  } = useFetch([]);

  let loader = null;
  if (submittingCouseSettingInfoLoading || courseSettingsDataLoading
    || gettingRoles || gettingGrageList || gettingCourseRoleList
    || gettingCourseCategoryList || gettingCourseSubCategoryList) {
    loader = <Loader open />;
  }

  const attemptSettings = [
    {
      id: 1,
      value: 'Single',
    },
    {
      id: 2,
      value: 'Multiple',
    }
  ]

  useEffect(() => {
    if (courseCategory) {
      fetchCourseSubCategoryList({
        url: `${urls.courseSubCategoryListApi}?category=${courseCategory}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });
    }
  }, [courseCategory]);

  useEffect(() => {
    if (courseSettingsData) {
      if (courseSettingsData && courseSettingsData.time_bounded === true) {
        setSelectCourseType('TimeBounded');
      }
      if (courseSettingsData.self_paced === true) {
        setSelectCourseType('selfPaced');
      }
      if (courseSettingsData && courseSettingsData.is_student === true) {
        setSelectedCourseVisibility('Student');
      }
      if (courseSettingsData.is_teacher_professional === true) {
        setSelectedCourseVisibility('TeachingProfessional');
      }
      setEnforceSequencing(courseSettingsData && courseSettingsData.enforce_sequencing);
      setEnableDiscussion(courseSettingsData && courseSettingsData.enable_discussion);
      setInHouse(courseSettingsData && courseSettingsData.is_inhouse_product);
    }
    if (courseSettingsData && courseSettingsData.is_induction_training) {
      setTeachersVisibility('InductionTraining');
    }
    else if (courseSettingsData && courseSettingsData.is_subject_training) {
      setTeachersVisibility('SubjectTraining');
    }
    else if (courseSettingsData && courseSettingsData.is_course) {
      setTeachersVisibility('courses');
    }
    setSelfDriven(courseSettingsData && courseSettingsData.is_self_driven);
    setTrinerDriven(courseSettingsData && courseSettingsData.is_trainer_driven);

    if (courseSettingsData && courseSettingsData.is_generic_subject) {
      setSubjectType('generic');
    } else {
      setSubjectType('subject');
    }
    if (courseSettingsData && courseSettingsData.volume_name) {
      let tempVolume = [...volumeList]
      tempVolume.push({ name: courseSettingsData.volume_name, id: courseSettingsData.volume_name })
      setVolumeList(tempVolume)
    }
    setVolume(courseSettingsData && courseSettingsData.volume_name);
    setSubjectId(courseSettingsData && courseSettingsData.course_sub_type);
    setGradeId((courseSettingsData && courseSettingsData.course_type) || []);
    setRoleId(courseSettingsData && courseSettingsData.role);
    setSelfAssign(courseSettingsData && courseSettingsData.is_assign_course);
    setSelfCourse(courseSettingsData && courseSettingsData.is_self_course);
    setTrainingCourse(courseSettingsData && courseSettingsData.is_training_course);
    setAssignTo(courseSettingsData && courseSettingsData.assigned_to);
    setCourseCategory((courseSettingsData && courseSettingsData.course_category) || '');
    setCourseSubCategory((courseSettingsData && courseSettingsData.course_sub_category) || '');
    settrainingCourseVisibility(courseSettingsData
      && courseSettingsData.role.some((item) => item === 9 || item === 13));
    setAttempt((courseSettingsData && courseSettingsData.attempt_setting) || '');

    if (courseSettingsData && courseSettingsData.course_type !== undefined) {
      let idArr = [];
      idArr = courseSettingsData.course_type && courseSettingsData.course_type.map((item) => item);
      if (idArr.length !== 0) {
        axios.get(`${urls.subjectSelector_Webinar}?grade_id=${idArr.toString()}`, {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Courses')
          },
        }).then((res) => {
          const subjectArr = res.data.map((item) => (item.subject_fk));
          setsubjectsList(subjectArr);
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  }, [courseSettingsData]);

  useEffect(() => {
    if (teachersVisibility && count === 1) {
      setTrinerDriven(false);
      setSelfDriven(false);
    }
  }, [teachersVisibility]);



  useEffect(() => {
    if (selectedCourseVisibility && count === 1) {
      setTeachersVisibility('');
      setTrinerDriven(false);
      setSelfDriven(false);
    }
  }, [selectedCourseVisibility]);

  function getVolumeList(subjectType) {
    axios.get(`${urls.volumePermissionList}?subject_id=${subjectType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    }).then(response => {

      // setVolume(response.data.response)
      let tempArray = [...volumeList]
      response && response.data && response.data.volume_name && response.data.volume_name.map(data => {
        tempArray.push({ name: data, id: data })

      })
      const obj = [...new Map(tempArray.map(item => [JSON.stringify(item), item])).values()];

      setVolumeList(obj);
      console.log("Filteerd Object ", obj);
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (subjectId !== null) {
      getVolumeList(subjectId)
    }
  }, [subjectId])

  useEffect(() => {
    if (auth) {
      fetchCourseRoleList({
        url: `${urls.coureWiseRoleListApi}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });
      fetchCourseCategoryList({
        url: `${urls.courseSubCategoryListApi}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });

      fetchCourseSettingData({
        url: `${urls.getCourseSettingsDataApi}?course_id=${userIdNo}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },

      });


      fetchRols({
        url: `${urls.getCourseSettingRolesApi}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });
      // fetchSubjects({
      //   url: `${urls.getAllSubjectsList}`,
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${auth.personal_info.token}`,
      //     'Content-Type': 'application/json',
      //   },
      // });
      fetchGrade({
        url: `${urls.categoryTypeApiList}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
          module: localStorage.getItem('Courses')
        },
      });
    }
  }, [auth]);

  function submitCourseSettingData() {
    if (teachersVisibility === 'courses' && !courseCategory) {
      alert.warning('select Category');
      return;
    }
    const data = {
      id: userIdNo,
      time_bounded: selectCourseType === 'TimeBounded',
      self_paced: selectCourseType === 'selfPaced',
      is_inhouse_product: (selectedCourseVisibility === 'TeachingProfessional' && inHouse) || false,
      enforce_sequencing: enforceSequencing || false,
      enable_discussion: enableDiscussion || false,
      is_student: selectedCourseVisibility === 'Student',
      is_teacher_professional: selectedCourseVisibility === 'TeachingProfessional',
      is_induction_training: (teachersVisibility === 'InductionTraining') || false,
      is_subject_training: (teachersVisibility === 'SubjectTraining') || false,
      volume_name: volume,
      is_course: (teachersVisibility === 'courses') || false,
      is_trainer_driven: ((teachersVisibility === 'courses') && trinerDriven) || false,
      is_self_driven: ((teachersVisibility === 'courses') && selfDriven) || false,
      is_generic_subject: ((subjectType === 'generic' && true) || false),
      course_sub_type: ((subjectType === 'subject' && subjectId) || null),
      course_type: gradeId || [],
      is_self_course: ((teachersVisibility === 'courses' && selfDriven === true) && selfCourse) || false,
      is_assign_course: ((teachersVisibility === 'courses' && selfDriven === true) && selfAssign) || false,
      is_training_course: ((teachersVisibility === 'courses' && selfDriven === true) && trainingCourse) || false,
      assigned_to: ((((teachersVisibility === 'courses' && selfDriven === true && selfAssign === true) || (teachersVisibility === 'courses' && trinerDriven === true)) && asssignTo)) || [],
      attempt_setting: attempt ? attempt : null,
    };
    if (teachersVisibility === 'courses' && courseCategory) {
      data.course_category = courseCategory;
    }
    if (teachersVisibility === 'courses' && courseCategory && courseSubCategory) {
      data.course_sub_category = (subCategoryCourseList && subCategoryCourseList.length !== 0 && subCategoryCourseList.filter((item) => item.course_sub_category.id === courseSubCategory).length !== 0 && courseSubCategory) || '';
    }
    if (roleId.length !== 0) {
      data.role = roleId;
    }
    fetchSubmitCourseSetting({
      url: urls.getCourseSettingsDataApi,
      method: 'PUT',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      },
    });
  }

  function functionToHandle(e) {
    setSelectedCourseVisibility(e.target.value);
    setCount(1);
  }

  function functionToHandleProfession(e) {
    setTeachersVisibility(e.target.value);
    setCount(1);
  }

  // useEffect(()=>{
  //   if (teachersVisibility) {
  //     setRoleId([]);
  //   }
  // },[teachersVisibility])

  // fetchSubjects({
  //   url: `${urls.subjectSelector_Webinar}?grade_id=${e.target.value}`,
  //   method: 'GET',
  //   headers: {
  //     Authorization: `Bearer ${auth.personal_info.token}`,
  //     'Content-Type': 'application/json',
  //   },
  // });
  function selectAllgrade(e) {
    if (SelectedAllGrade === true && e.target.value.length - 1 === gradeList.length) {
      setSelectedAllGrade(false);
      setGradeId([]);
      return;
    }
    if (e.target.value.length !== 0) {
      if (e.target.value.filter((data) => data === '0').length === 1 && SelectedAllGrade === false) {
        const setarray = [];
        for (let i = 0; i < gradeList.length; i += 1) {
          setarray.push(gradeList[i].id);
        }
        setSelectedAllGrade(true);
        setGradeId(setarray);
        axios.get(`${urls.subjectSelector_Webinar}?grade_id=${setarray.toString()}`, {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Courses')
          },
        }).then((res) => {
          const subjectArr = res.data.map((item) => (item.subject_fk));
          // setgradeIdArr(gradeidArr);
          setsubjectsList(subjectArr);
        }).catch((err) => {
          console.log(err);
        });
      } else {
        axios.get(`${urls.subjectSelector_Webinar}?grade_id=${e.target.value.toString()}`, {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Courses')
          },
        }).then((res) => {
          const subjectArr = res.data.map((item) => (item.subject_fk));
          setGradeId(e.target.value);
          setsubjectsList(subjectArr);
        }).catch((err) => {
          console.log(err);
        });
        // setgradeIdArr(e.target.value);
        setSelectedAllGrade(false);
      }
    } else {
      setGradeId([]);
      setSelectedAllGrade(false);
    }
  }
  function handleRoleSelect(e) {
    settrainingCourseVisibility(e.target.value.some((item) => item === 9 || item === 13));
    setRoleId(e.target.value);
  }
  function rolefunction() {
    return (
      <Grid item md={12} xs={12}>
        <Typography>Select Role</Typography>
        <Select
          multiple
          label="language"
          margin="dense"
          value={roleId || ''}
          onChange={(e) => handleRoleSelect(e)}
          className={classes.textField}
          variant="outlined"
          style={{ color: 'black' }}
        >
          {CourseRoleList
            && CourseRoleList.length !== 0
            && CourseRoleList.map((data) => (
              <MenuItem
                value={data.id}
                key={data.id}
                name={data.name}
              >
                {data.name ? data.name : ''}
              </MenuItem>
            ))}
        </Select>
      </Grid>
    );
  }
  function rolefunctionSubject() {
    return (
      <>
        <Grid item md={12} xs={12} >
          <Typography>Select Role</Typography>
          <Select
            multiple
            label="language"
            margin="dense"
            value={roleId || ''}
            onChange={(e) => handleRoleSelect(e)}
            className={classes.textField}
            variant="outlined"
            style={{ color: 'black' }}
          >
            {CourseRoleList
              && CourseRoleList.length !== 0
              && CourseRoleList.map((data) => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.name}
                >
                  {data.name ? data.name : ''}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item md={12} xs={12}>
          {/* {courseSettingsData && courseSettingsData.volume_name?<Typography>Volume Selected</Typography>:
        <Typography>Select Volume</Typography>}
        {courseSettingsData && courseSettingsData.volume_name?<TextField margin="dense" defaultValue={volume && volume} disabled fullWidth variant="outlined">
          </TextField>: */}
          <Select
            label="Select Volume"
            margin="dense"
            value={volume || ""}
            onChange={(e) => handleVolume(e)}
            className={classes.textField}
            variant="outlined"
            style={{ color: 'black' }}
          >
            {volumeList
              && volumeList.map((data) => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.name}
                >
                  {data.name ? data.name : ''}
                </MenuItem>

              ))}
          </Select>
          {/* } */}

          {/* <Typography>{volume && volume}</Typography> */}

        </Grid>
      </>

    );
  }

  function selectGradeFunction() {
    return (
      <Grid item md={4} xs={12}>
        <span>
          Select Grade
          <strong style={{ color: 'red' }}>*</strong>
          :
        </span>
        <Select
          margin="dense"
          multiple
          value={gradeId || []}
          onChange={(e) => selectAllgrade(e)}
          className={classes.textField}
          variant="outlined"
          style={{ color: 'black', minWidth: 120, maxWidth: 300 }}
        >
          <MenuItem
            key="0"
            value="0"
          >
            Select All
          </MenuItem>
          {gradeList
            && gradeList.length !== 0
            && gradeList.map((dropItem) => (
              <MenuItem
                style={{
                  color: gradeId && gradeId.filter((gradeData) => gradeData === dropItem.id).length === 1 ? 'white' : '',
                  backgroundColor: gradeId && gradeId.filter((gradeData) => gradeData === dropItem.id).length === 1 ? 'darkslateblue' : '',
                }}
                value={dropItem.id}
                key={dropItem.id}
              >
                {dropItem.type_name}
              </MenuItem>
            ))}
        </Select>
      </Grid>
    );
  }
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.head}>
          Course Settings
        </Typography>
      </Paper>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid item md={4} xs={12}>
          <Typography variant="h6" className={classes.typography}>
            {' '}
            Select Type of Course
            <strong style={{ color: 'red' }}>*</strong>
          </Typography>
          <Radio
            checked={selectCourseType === 'selfPaced'}
            onChange={(e) => setSelectCourseType(e.target.value)}
            value="selfPaced"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
            color="primary"
          />
          {' '}
          Self Paced
          <br />
          <Radio
            checked={selectCourseType === 'TimeBounded'}
            onChange={(e) => setSelectCourseType(e.target.value)}
            value="TimeBounded"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'B' }}
            color="primary"
          />
          Time Bounded
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Typography variant="h6" className={classes.typography}>
              Select Subject
              <strong style={{ color: 'red' }}>*</strong>
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <Radio
              checked={subjectType === 'generic'}
              onChange={(e) => setSubjectType(e.target.value)}
              value="generic"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
              color="primary"
            />
            Generic
          </Grid>
          <Grid item md={10} xs={12}>
            {subjectType === 'generic' && selectGradeFunction()}
          </Grid>
          <Grid item md={3} xs={12}>
            <Radio
              checked={subjectType === 'subject'}
              onChange={(e) => setSubjectType(e.target.value)}
              value="subject"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'B' }}
              color="primary"
            />
            Subject
          </Grid>
          {subjectType === 'subject'
            && (
              <>
                {selectGradeFunction()}
                &nbsp;&nbsp;
                <Grid item md={4} xs={12}>
                  <span>
                    Select Subject
                    <strong style={{ color: 'red' }}>*</strong>
                    :
                  </span>
                  <Select
                    margin="dense"
                    value={subjectId || ''}
                    onChange={(e) => { setSubjectId(e.target.value) }}
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: 'black' }}
                  >

                    {subjectsList
                      && subjectsList.length !== 0
                      && subjectsList.map((data) => (
                        <MenuItem
                          value={data.id}
                          key={data.id}
                          name={data.sub_type_name}
                        >
                          {data.sub_type_name ? data.sub_type_name : ''}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

              </>
            )}
        </Grid>
        <Divider className={classes.divider} />
        <Typography variant="h6">
          {' '}
          Course Visibility
          <strong style={{ color: 'red' }}>*</strong>
        </Typography>
        <Grid container justify="space-between" spacing={2}>
          <Grid item md={4} xs={12}>
            <Radio
              checked={selectedCourseVisibility === 'Student'}
              onChange={(e) => functionToHandle(e)}
              value="Student"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
              color="primary"
            />
            {' '}
            Student
            <br />
            <Radio
              checked={selectedCourseVisibility === 'TeachingProfessional'}
              onChange={(e) => functionToHandle(e)}
              value="TeachingProfessional"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'B' }}
              color="primary"
            />
            Teaching Professional
          </Grid>
        </Grid>
        {selectedCourseVisibility === 'TeachingProfessional' && (
          <>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Radio
                  checked={!!(teachersVisibility === 'InductionTraining')}
                  onChange={(e) => functionToHandleProfession(e)}
                  value="InductionTraining"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                  color="primary"
                />
                Induction Training
              </Grid>
              <Grid item md={4} xs={12}>
                <Radio
                  checked={!!(teachersVisibility === 'courses')}
                  onChange={(e) => functionToHandleProfession(e)}
                  value="courses"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                  color="primary"
                />
                Courses
              </Grid>
              <Grid item md={4} xs={12}>
                <Radio
                  checked={!!(teachersVisibility === 'SubjectTraining')}
                  onChange={(e) => functionToHandleProfession(e)}
                  value="SubjectTraining"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                  color="primary"
                />
                Subject Training
              </Grid>
            </Grid>
          </>
        )}
        {teachersVisibility === 'InductionTraining' && (
          <>
            <Divider className={classes.divider} />
            {rolefunction()}
          </>

        )}
        {teachersVisibility === 'SubjectTraining' && (
          <>
            <Divider className={classes.divider} />
            {rolefunctionSubject()}
          </>
        )}

        {teachersVisibility === 'courses' && (
          <>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <span>
                  Select Category
                  <strong style={{ color: 'red' }}>*</strong>
                  :
                </span>
                <Select
                  margin="dense"
                  value={courseCategory || ''}
                  onChange={(e) => setCourseCategory(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {categoryCourseList && categoryCourseList.length !== 0
                    && categoryCourseList.map((data) => (
                      <MenuItem
                        value={data.id}
                        key={data.id}
                        name={data.category_name}
                      >
                        {data.category_name ? data.category_name : ''}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                {courseCategory
                  && (
                    <>
                      <span>
                        Select Sub Category :
                      </span>
                      <Select
                        margin="dense"
                        value={courseSubCategory || ''}
                        onChange={(e) => setCourseSubCategory(e.target.value)}
                        className={classes.textField}
                        variant="outlined"
                        style={{ color: 'black' }}
                      >
                        {subCategoryCourseList && subCategoryCourseList.length !== 0
                          && subCategoryCourseList.map((data) => (
                            <MenuItem
                              value={data.course_sub_category.id}
                              key={data.course_sub_category.id}
                              name={data.course_sub_category.sub_category_name}
                            >
                              {data.course_sub_category.sub_category_name ? data.course_sub_category.sub_category_name : ''}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
              </Grid>
              <Grid item md={4} xs={12}>
                <Checkbox
                  checked={selfDriven || false}
                  onChange={(e) => setSelfDriven(e.target.checked)}
                  value="primary"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Self Driven
              </Grid>
              <Grid item md={8} xs={12}>
                <Checkbox
                  checked={trinerDriven || false}
                  onChange={(e) => setTrinerDriven(e.target.checked)}
                  value="primary"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Trainer Driven
              </Grid>
              {selfDriven === true
                && (
                  <>
                    {rolefunction()}
                    <Grid item md={4} xs={12}>
                      <Checkbox
                        checked={selfAssign || false}
                        onChange={(e) => setSelfAssign(e.target.checked)}
                        value="primary"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      Assign to Other
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Checkbox
                        checked={selfCourse || false}
                        onChange={(e) => setSelfCourse(e.target.checked)}
                        value="primary"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      Self Course by Principal/ Co-Ordinator
                    </Grid>
                    {trainingCourseVisibility && (
                      <Grid item md={8} xs={12}>
                        <Checkbox
                          checked={trainingCourse || false}
                          onChange={(e) => setTrainingCourse(e.target.checked)}
                          name="TrainingCourse"
                          color="primary"
                        />
                        {/* <Checkbox
                      checked={trainingCourse}
                      onChange={(e) => setTrainingCourse(e.target.checked)}
                      value="primary"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> */}
                        Self Course by Teacher
                      </Grid>
                    )}
                  </>
                )}
            </Grid>
            {((selfAssign === true && selfDriven === true) || (trinerDriven === true))
              && (
                <>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2}>
                    {(trinerDriven === true && selfDriven === false) && (
                      <Grid item md={4} xs={12}>
                        {rolefunction()}
                      </Grid>
                    )}
                    <Grid item md={4} xs={12}>
                      <Typography>Assigned to</Typography>
                      <Select
                        margin="dense"
                        multiple
                        value={asssignTo || []}
                        onChange={(e) => setAssignTo(e.target.value)}
                        className={classes.textField}
                        variant="outlined"
                      >
                        {CourseRoleList
                          && CourseRoleList.length !== 0
                          && CourseRoleList.map((data) => (
                            <MenuItem
                              value={data.id}
                              key={data.id}
                              name={data.name}
                            >
                              {data.name ? data.name : ''}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </>
              )}
          </>
        )}

        <Divider className={classes.divider} />

        <Typography variant="h6">Other Settings </Typography>
        <Grid justify="space-between" container spacing={2}>
          <Grid item md={12} xs={12}>
            <Checkbox
              checked={enforceSequencing}
              onChange={(e) => setEnforceSequencing(e.target.checked)}
              value="primary"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {' '}
            Enforce Sequencing
          </Grid>
          {/* <Grid item md={12} xs={12}>
            <Checkbox
              checked={enableDiscussion}
              onChange={(e) => setEnableDiscussion(e.target.checked)}
              value="primary"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {' '}
            Enable Discussion
          </Grid> */}
        </Grid>

        <Divider className={classes.divider} />

        <Typography variant="h6">Attempt Settings</Typography>
        <Grid justify="space-between" container spacing={2}>
          <Grid item md={6} xs={12}>
            {/* <Typography>Single/Multiple</Typography> */}
            <Select
              margin="dense"
              value={attempt || []}
              onChange={(e) => setAttempt(e.target.value)}
              className={classes.textField}
              variant="outlined"
            >
              {attemptSettings
                && attemptSettings.length !== 0
                && attemptSettings.map((data) => (
                  <MenuItem
                    value={data.value}
                    key={data.id}
                    name={data.value}
                  >
                    {data.value ? data.value : ''}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid justify="space-between" container spacing={2}>
          <Grid item md={3} />
          <Grid item md={2} xs={12}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => submitCourseSettingData()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        justify="space-between"
        container
        spacing={2}
        style={{ marginTop: '10px' }}
      >
        <Grid item md={8} />
        <Grid item md={2} xs={6}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={pageNo === '0'}
            onClick={() => backPage()}
          >
            Back
          </Button>
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={pageNo === '7'}
            onClick={() => nextPage()}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      {loader}
    </>
  );
};
CourseSettings.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  userIdNo: PropTypes.string.isRequired,
  backPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageNo: PropTypes.string.isRequired,
};
export default withStyles(styles)(CourseSettings);
