/* eslint-disable no-console */
import React, { useState ,useEffect} from 'react';
import {
  Paper,
  Grid,
  Typography,
  withStyles,
  Divider,
  Select,
  Button,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TextField,
  TablePagination,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './meetingWebinarReport.style';
import urls from '../../../url';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import axios from 'axios';

const MeetingWebinarReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [classType, setClassType] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [permission,setPermission]=useState([]);


  const alert = useAlert();

  const classTypeList = [
    { id: 0, name: 'Meeting' },
    { id: 1, name: 'Webinar' },
  ];

  // const {
  //   // data: xCelResponse,
  //   // isLoading: xCelResponseData,
  //   doFetch: fetchxCelResponse,
  // } = useFetch(null);
  const {
    data: reportsData,
    isLoading: gettingReportsData,
    doFetch: fetchReports,
  } = useFetch(null);

  let loader = null;
  if (gettingReportsData) {
    loader = <Loader open />;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(5);
    }
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  //   function functionTodateFormate(item) {
  //     const a = item.split(' ');
  //     const p = a[0].split(/\D/g);
  //     const b = [p[2], p[1], p[0]].join('/');
  //     const c = `${b}, ${a[1]} ${a[2]}`;
  //     return c !== 'undefined' ? c.split(',')[0] : '';
  //   }

  function functionToGetReports() {
    if (!startDate) {
      alert.warning('Select Start Date');
      return;
    }
    if (!endDate) {
      alert.warning('Select End Date');
      return;
    }
    if (classType.length === 0) {
      alert.warning('Select Class Type');
      return;
    }
    fetchReports({
      url: `${urls.getWebinarMeetingsListApi}?from_date=${startDate}&to_date=${endDate}&online_class_type=${classType}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Meeting_Webinar_Report')
      },
    });
  }
  const isSelected = (name) => selectedUsers.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selectedUsers.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      );
    }
    setSelectedUsers(newSelected);
  };

  function handelSelectAll() {
    if (selectedUsers.length === (reportsData && reportsData.length)) {
      setSelectedUsers([]);
    } else {
      const array = [];
      const n = reportsData && reportsData.length;
      for (let i = 0; i < n; i += 1) {
        array.push(reportsData && reportsData[i].id);
      }
      setSelectedUsers(array);
    }
  }

  // eslint-disable-next-line no-unused-vars
  const downloadReport = () => {
    // const body = {
    //   webinar_id: selectedUsers,
    // };
    // fetchxCelResponse({
    //   url: `${urls.getExcelDownLoad}`,
    //   method: 'GET',
    //   body,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //   },
    // });
    const fetchReport = async () => {
      const response = await fetch(`${urls.getExcelDownLoad}?webinar_id=${selectedUsers}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'text/ms-excel',
        module: localStorage.getItem('Meeting_Webinar_Report')
        },
      });
      const res = await response.blob();
      return res;
    };
    fetchReport()
      .then((blob) => {
        // setLoading(false);
        // if (res.status === 200) {
        //   alert.success('Successfully Downloaded');
        //   // return res.json();
        // }
        // if (res.status !== 200) {
        //   alert.warning('something went wrong please try again ');
        // }
        // download(blob); // good option, but this needs download.js, so not using.
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', 'webinar_meeting_report.xls');
        a.click();
        // window.location.assign(window.URL.createObjectURL(blob));
        // return 0;
      })
      .catch(console.error);
  };

  const converTime = (time) => {
    if (time) {
      let hour = (time.split(':'))[0];
      let min = (time.split(':'))[1];
      const part = hour > 11 ? 'PM' : 'AM';
      min = (`${min}`).length === 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (`${hour}`).length === 1 ? `0${hour}` : hour;
      return (`${hour}:${min} ${part}`);
    }
    return null;
  };

    // For Permissions
    function getPermissonData(id) {
      axios.get(urls.getPermissons,{
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'text/ms-excel',
        module: id
        },
      }).then(response => {
        setPermission(response.data.response)
      }).catch(error => {
        console.log(error);
      })
    }
  
    useEffect(() => {
      getPermissonData(localStorage.getItem('Meeting_Webinar_Report'))
    }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Typography variant="h4">Meeting & Webinar Reports</Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Typography>
              Select Start Date &nbsp;
              <strong style={{ color: 'red' }}>*</strong>
            </Typography>
            <TextField
              type="date"
              required
              fullWidth
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>
              Select End Date &nbsp;
              <strong style={{ color: 'red' }}>*</strong>
            </Typography>
            <TextField
              type="date"
              value={endDate}
              fullWidth
              required
              onChange={(e) => setEndDate(e.target.value)}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>
              Select Class Type &nbsp;
              <strong style={{ color: 'red' }}>*</strong>
            </Typography>
            <Select
              multiple
              margin="dense"
              value={classType || ''}
              onChange={(e) => setClassType(e.target.value)}
              fullWidth
              variant="outlined"
              style={{ color: 'black', marginTop: '8px' }}
            >
              {classTypeList
                && classTypeList.length !== 0
                && classTypeList.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button style={{ marginTop: '30px' }} color="primary" variant="contained" onClick={() => functionToGetReports()}>Get</Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {reportsData && reportsData.length === 0 && <Typography variant="h4" style={{ color: 'blue', textAlign: 'center' }}>Records Not Found</Typography>}
      {reportsData && reportsData.length !== 0
      && (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell float="left">
                    <Typography>Select</Typography>
                  </TableCell>
                  <TableCell float="left">
                    <Typography>S.No</Typography>
                  </TableCell>
                  <TableCell float="left">
                    <Typography>Topic</Typography>
                  </TableCell>
                  <TableCell float="left">
                    <Typography>Speaker Name</Typography>
                  </TableCell>
                  <TableCell float="left">
                    <Typography>Date & Time</Typography>
                  </TableCell>
                  <TableCell float="left">
                    <Typography>Type</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  hover
                  onClick={() => handelSelectAll()}
                  role="checkbox"
                  tabIndex={-1}
                  key="100000"
                  selected={selectedUsers.length === (reportsData && reportsData.length)}
                >
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedUsers.length === (reportsData && reportsData.length)}
                      onClick={() => handelSelectAll()}
                    />
                  </TableCell>
                  <TableCell align="center">Select All</TableCell>
                </TableRow>
                {reportsData && reportsData.length !== 0
                && reportsData.map((item, index) => {
                  const isItemSelectedId = isSelected(item.id);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, item.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={item.id}
                      selected={isItemSelectedId}
                    >
                      <TableCell align="center">
                        <Checkbox
                          checked={isItemSelectedId}
                          id={item.id + item.speaker}
                          key={item.id + item.speaker}
                        />
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{index + 1}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{(item.topic && item.topic) || ''}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{(item.speaker && item.speaker) || ''}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{(item.date && new Date(item.date).toString().split('G')[0].substring(0, 16)) || ''}</Typography>
                        <Typography>{(item.date && converTime(new Date(item.date).toTimeString())) || ''}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{(item.type && item.type === '0' ? 'Meeting' : 'Webinar') || ''}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ textAlign: 'right', padding: '20px' }}>
          {permission.can_export ? 
            <Button
              color="primary"
              variant="contained"
              disabled={!(selectedUsers && selectedUsers.length !== 0)}
              // onClick={downloadReport}
              href={`${urls.getExcelDownLoad}?webinar_id=${selectedUsers}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Meeting_Webinar_Report')}`}
            >
              Download Report
            </Button>
            :null}
          </Grid>
        </Grid>
      </Paper>
      )}
      {reportsData
        && reportsData.results
        && reportsData.results.length !== 0 && (
        <Paper style={{ backgroundColor: 'lightgray', marginTop: '10px' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+reportsData.total_pages}`}
                  rowsPerPageOptions={[5, 20, 30]}
                  count={+reportsData.count}
                  rowsPerPage={rowsPerPage || 5}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(reportsData.total_pages - 1)}
                    disabled={page === +reportsData.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      )}
      {loader}
    </>
  );
};
MeetingWebinarReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(MeetingWebinarReport);
