import React, { useState } from 'react';
import { withStyles, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import './CoursesTraining.css';
// import CoursesForm from './CoursesForm';
import styles from './CoursesForm.style';
// import MainPageForm from '../ReUsableComponents/MainPageForm';
// import MapDiv from '../ReUsableComponents/MapDiv';
// import FooterDiv from '../ReUsableComponents/FooterDiv';
// import PrincipalRouting from '../../../components/PrincipalDashboard/PrincipalRouting';

function CoursesTraining({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // const handleSelfDriven = () => {
  //   localStorage.setItem('principalCourseType', 'self_driven');
  //   window.location = '/modules';
  // };

  // const handleTestDriven = () => {
  //   localStorage.setItem('principalCourseType', 'trainer_driven');
  //   window.location = '/principalRouting';
  // };
  localStorage.removeItem('principalCourseType');

  const handleSelfDriven = () => {
    if ((auth && auth.personal_info && auth.personal_info.role === 'Teacher') || auth.personal_info.role === 'Deputy Zonal Head' || auth.personal_info.role === 'Business Development Manager' || auth.personal_info.role === 'Assistant Business Development Manager' || auth.personal_info.role === 'Zonal Head- Inbound Marketing' || auth.personal_info.role === 'Cluster Counselor' || auth.personal_info.role === 'Counselor' || auth.personal_info.role === 'Digital marketing head' || auth.personal_info.role === 'MarketingHead' || auth.personal_info.role === 'SEO head' || auth.personal_info.role === 'Digital marketing specialist' || auth.personal_info.role === 'Digital Marketing Executive' || auth.personal_info.role === 'Associate Content and Management Lead' || auth.personal_info.role === 'EA' || auth.personal_info.role === 'FOE') {
      localStorage.setItem('principalCourseType', 'self_driven');
      localStorage.setItem('coursesType', 'self_driven');
      // window.location = '/modules';
      window.location = '/TeacherDashboard';
    } else {
      localStorage.setItem('principalCourseType', 'self_driven');
      localStorage.setItem('principalCourseType', 'self_driven');
      localStorage.setItem('coursesType', 'self_driven');
      window.location = '/principalDashboard';
    }
  };

  const handleTestDriven = () => {
    if (auth && auth.personal_info && (auth.personal_info.role === 'Teacher' || auth.personal_info.role === 'Deputy Zonal Head' || auth.personal_info.role === 'Business Development Manager' || auth.personal_info.role === 'Assistant Business Development Manager' || auth.personal_info.role === 'Zonal Head- Inbound Marketing' || auth.personal_info.role === 'Cluster Counselor' || auth.personal_info.role === 'Counselor' || auth.personal_info.role === 'Digital marketing head' || auth.personal_info.role === 'MarketingHead' || auth.personal_info.role === 'SEO head' || auth.personal_info.role === 'Digital marketing specialist' || auth.personal_info.role === 'Digital Marketing Executive' || auth.personal_info.role === 'Associate Content and Management Lead' || auth.personal_info.role === 'EA' || auth.personal_info.role === 'FOE' || auth.personal_info.role === 'LeadTeacher')) {
      localStorage.setItem('principalCourseType', 'trainer_driven');
      localStorage.setItem('coursesType', 'trainer_driven');

      // window.location = '/modules';
      window.location = '/trainerDriven_Teacher';
    } else {
      localStorage.setItem('principalCourseType', 'trainer_driven');
      localStorage.setItem('principalCourseType', 'trainer_driven');
      localStorage.setItem('coursesType', 'trainer_driven');
      localStorage.setItem('trainer', 'trainer');
      window.location = '/trainerDriven';
    }
  };
  return (
    <Grid container className={`${classes.formDiv} ${classes.formColor}`}>
      <Grid item md={6} xs={12} className="responsive" style={{ textAlign: 'center', backgroundColor: 'white' }}>
        <Button
          className="arcButton"
          style={{
            padding: '1rem 1.2rem',
            marginRight: '1rem',
            color: 'black',
            cursor: 'pointer',
            fontSize: '30px',
            fontFamily: 'caviarDreams',
            fontWeight: '800',
            backgroundColor: 'transparent',
          }}
          onClick={handleSelfDriven}
        >
          <div className="arcBtn">
            Self Driven
          </div>
        </Button>
      </Grid>
      <Grid item md={6} xs={12} className="responsive" style={{ textAlign: 'center', backgroundColor: 'lightsteelblue' }}>
        <Button
          className="arcButton"
          style={{
            padding: '1rem 1.2rem',
            marginRight: '1rem',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            fontWeight: '800',
            fontFamily: 'caviarDreams',
            backgroundColor: 'transparent',
          }}
          onClick={handleTestDriven}
        >
          <div className="arcBtn">
            Trainer Driven
          </div>
        </Button>
      </Grid>
      {/* <PrincipalRouting /> */}
      {/* <MainPageForm />
      <MapDiv /> */}
      {/* <FooterDiv /> */}
    </Grid>
  );
}

CoursesTraining.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CoursesTraining);
