/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {
  // Fragment,
  useState,
  useEffect,
  // useRef,
} from "react";
import TextField from "@material-ui/core/TextField";
// import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Radio,
  FormHelperText,
  Checkbox,
  ListItemText,
  Input,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Container from "@material-ui/core/Container";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// import BackgroundImage from "../userLayout/images/bookImg.jpg";

// import {
//   BrowserRouter, Switch, Route, Link,
// } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import VerifiedIcon from "@material-ui/icons/VerifiedUser";
import urls from "../url";
import Loader from "../hoc/loader";

// import Snackbar from "@material-ui/core/Snackbar";
// import SnackbarContent from "@material-ui/core/SnackbarContent";
// import CloseIcon from "@material-ui/icons/Close";
import { useAlert } from "../hoc/alert/alert";

import OtpVerification from "./otpVerification/OtpVerification";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      flexWrap: "wrap",
      margin: " 0 auto",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "&  .MuiSelect-icon": {
      color: "white",
    },
  },
  normal: {
    "& label.Mui-focused": {
      color: "white",
    },
    "&  .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& .MuiInput-input": {
      color: "white",
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiFormHelperText-root": {
      color: "white",
    },
    "& .MuiSelect-selectMenu:before": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-selectMenu:after": {
      borderBottomColor: "white",
    },
  },

  errorClass: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "50px auto",
    border: "1px solid blue",
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 500,
  },
  close: {
    padding: theme.spacing(0.5),
  },
  warning: {
    backgroundColor: "amber",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  formHeadAlign: {
    textAlign: "center",
    fontWeight: "bold",
  },
  // input: {
  //   color: "white",
  //   "&::placeholder": {
  //     textOverflow: "ellipsis !important",
  //     color: "blue"
  //   }
  // }
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const errorStyles = makeStyles({
//   error: {
//     '& label.Mui-focused': {
//       color: 'red',
//     },
//     '& .MuiInputLabel-root': {
//       color: 'red',
//     },
//     '& .MuiInput-underline:before': {
//       borderBottomColor: 'red',
//     },
//   },
// });

const registerData = {
  first_name: "",
  last_name: "",
  username: "",
  phone_number: "",
  joining_date: "",
  total_experience: "",
  email: "",
  password: "",
  confirmPassword: "",
  roles_category: "",
  branch: [],
  course_type: [],
  course_sub_type: [],
  gender: "",
  school: "",
};

const registerError = {
  firstnameError: "",
  lastnameError: "",
  usernameError: "",
  phoneNumberError: "",
  emailError: "",
  passwordError: "",
  want_to_becomeError: "",
  roleError: "",
  confirmPasswordError: "",
  schoolError: "",
  // registerError: ""
};
const otherEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const orchidsEmail = /^([a-zA-Z0-9_\.\-])+\@(([Oo][Rr][Cc][Hh][Ii][Dd][Ss])+\.)+([Ee][Dd][Uu]+\.)+([Ii][Nn])+$/;
// const otherFilterErp = /[^2][0-9]{10}/;
const otherFilterErp = /[^2][0-9]/;
// const orchidsFilterErp = /(20)[0-9][0-9][0-9]{7}/;
const orchidsFilterErp = /(20)[0-9][0-9][0-9]{7}/;

function UserRegister() {
  const classes = useStyles();
  const [profileData, setProfileData] = useState(registerData);

  const [subjectList, setSubjects] = useState("");
  const [branchList, setBranch] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [dataLoading, setdataLoading] = useState(false);
  const [errorData, setError] = useState(registerError);
  const [gradeList, setGradeList] = useState("");
  const [roleList, setRolesList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [roleType, setRoleType] = useState(false);
  const [selectOrganisation, setSelectOrganisation] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [filterEmail, setFilterEmail] = useState(otherEmail);

  const [open, setOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [filterErp, setFilterErp] = useState(otherFilterErp);
  // filter validations
  // const FilterEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // var filterEmail = FilterEmail;
  // const orchidsEmail= /^([a-zA-Z0-9_\.\-])+\@(([Oo][Rr][Cc][Hh][Ii][Dd][Ss])+\.)+([Ee][Dd][Uu]+\.)+([Ii][Nn])+$/;

  // const filterErp = /(19|20)[0-9][0-9][0-9]{7}/;

  const {
    first_name,
    last_name,
    username,
    // email,
    phone_number,
    password,
    roles_category,
    confirmPassword,
    school,
  } = profileData;
  const {
    // firstnameError,
    // lastnameError,
    // usernameError,
    // phoneNumberError,
    // emailError,
    // passwordError,
    // want_to_becomeError,
    // roleError,
    confirmPasswordError,
  } = errorData;
  let loader = null;
  if (dataLoading) {
    loader = <Loader open />;
  }

  async function getbranches() {
    setdataLoading(true);
    const response = await fetch(`${urls.getBranchNames}?is_orchids=True`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const getData = await response.json();
    setdataLoading(false);
    return getData;
  }
  const selectOrgani = (e) => {
    setSelectOrganisation(e);
    if (e === true) {
      setFilterEmail(orchidsEmail);
      setFilterErp(orchidsFilterErp);
      getRolesList(
        `${urls.getRoleRegistration}?search=orchids&user_type=USER`
      ).then((data) => {
        setRolesList(data);
      });
      // filterErp = /(19|20)[0-9][0-9][0-9]{7}/;
    } else {
      setFilterEmail(otherEmail);
      setFilterErp(otherFilterErp);
      getRolesList(
        `${urls.getRoleRegistration}?search=other&user_type=USER`
      ).then((data) => {
        setRolesList(data);
      });
      //  filterErp = /[^2][0-9]/;
    }
    getbranches().then((data) => {
      setBranch(data);
    });
  };
  async function getSchoolBranch(id) {
    // console.log('school',id)
    const gradeResult = fetch(`${urls.getBranchNames}?school_id=${id}`, {
      method: "GET",
      cache: "reload",
      headers: {
        "Content-Type": "Application/json",
      },
    });

    const response = await gradeResult;
    const gradeData = await response.json();
    return gradeData;
  }

  const registerUserData = (name, data) => {
    setProfileData({
      ...profileData,
      [name]: data,
    });
    if (name === "school") {
      getSchoolBranch(data).then((datas) => {
        setBranch(datas);
      });
      //  getSchoolBranch(data).then((data) => setAllValues({ ...values, inductionGrade: data }));
    }
    //  console.log("role type"+profileData.gradeData);
  };

  const alert = useAlert();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // POST To Submit Register Form Data
  async function postRegData() {
    // console.log("post "+profileData)
    const response = await fetch(urls.nonOrchidsregistration, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(profileData), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const fetchData = await response.json();
    return fetchData;
  }

  function checkRegisterValid() {
    let firstnameError = "";
    let lastnameError = "";
    let usernameError = "";
    let phoneNumberError = "";
    let totalExperienceError = "";
    let schoolError = "";
    let branchError = "";
    let roleError = "";
    let gradeError = "";
    let subjectError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    // let schoolError= '';
    // let emailError = '';
    // let passwordError = '';
    // let want_to_becomeError = '';
    // let roleError = '';
    // let confirmPasswordError = '';
    if (!first_name) {
      firstnameError = "First Name Field is Blank";
    }
    if (!last_name) {
      lastnameError = "Last name field is blank";
    }
    if (!username) {
      usernameError = "User Name field is blank";
    }
    if (!phone_number) {
      phoneNumberError = "Phone Number Field is Blank";
    }

    // if(mailOrchids===false){
    //   if (!schoolError) {
    //     schoolError = 'School Name Field is Blank';
    //   }
    // }
    if (!roles_category) {
      // roleError = 'Please enter your role';
    }
    if (!confirmPassword) {
      confirmPasswordError = "Please Enter this Field";
    }

    if (firstnameError || lastnameError || usernameError || phoneNumberError) {
      setError({
        firstnameError,
        lastnameError,
        usernameError,
        phoneNumberError,
      });
      return true;
    }

    return false;
  }

  function functioSuccess() {
    alert.success("Successfully Registered");
    window.location.href = "/";
    setdataLoading(false);
  }
  function fail() {
    alert.error("Already Registered");
    setdataLoading(false);
  }

  const registerHandle = (e) => {
    console.log(profileData);
    if (!profileData.first_name) {
      alert.warning("Enter First Name");
      return 1;
    }
    if (!profileData.last_name) {
      alert.warning("Enter Last Name");
      return 1;
    }
    if (!profileData.username) {
      alert.warning("Enter Your Erp Number");
      return 1;
    }
    if (!profileData.phone_number) {
      alert.warning("Enter Your Valid 10 Digit Phone Number");
      return 1;
    }
    if (profileData.phone_number.length < 10) {
      alert.warning("Enter Your Valid 10 Digit Phone Number");
      return 1;
    }
    if (!profileData.email) {
      alert.warning("Enter Your Valid Email Address");
      return 1;
    }
    if (!profileData.gender) {
      alert.warning("Select Your Gender");
      return 1;
    }
    if (!profileData.joining_date) {
      alert.warning("Enter Your Joining Date");
      return 1;
    }
    if (selectOrganisation == true ? profileData.username.length !== 11 : profileData.username.length <= 8 ) {
      alert.warning("Enter Your Valid Erp Number");
      return 1;
    }
    // if (verifyEmail === false) {
    //   alert.warning("Please verify Email first");
    //   return 1;
    // }
    if (!profileData.total_experience) {
      alert.warning("Please enter your total experience");
      return 1;
    }
    // if (!profileData.school) {
    //   alert.warning("Please enter your school");
    //   return 1;
    // }
    if (profileData.branch.length < 1) {
      alert.warning("Please enter your branch");
      return 1;
    }
    if (!profileData.roles_category) {
      alert.warning("Please enter your role");
      return 1;
    }
    if (profileData.course_type.length < 1) {
      alert.warning("Please enter your grade");
      return 1;
    }
    if (profileData.course_sub_type.length < 1) {
      alert.warning("Please enter your subject");
      return 1;
    }
    if (!profileData.password) {
      alert.warning("Please enter your password");
      return 1;
    }
    if (!profileData.confirmPassword) {
      alert.warning("Please re-enter your password");
      return 1;
    }
    e.preventDefault();
    if (checkRegisterValid()) {
      return 1;
    }
    if (filterEmail.test(profileData.email) === false) {
      alert.error("Please Enter Email Id of Orchids");
      return 1;
    }
    if (filterErp.test(profileData.username) === false) {
      alert.error("Please Enter Valid Erp Code");
      return 1;
    }
    // if (!filterErp.test(profileData.username) === true ) {
    //   alert.error("Please Enter Valid Erp Code");
    //   return 1;
    // }

    if (password !== confirmPassword) {
      alert.error("Please Enter Same Passwords");
    } else {
      // console.log("sab thik h regHan ")
      setdataLoading(true);
      delete profileData.confirmPassword;
      postRegData().then((data) =>
        data === "user created successfully" ? functioSuccess() : fail()
      );
      setProfileData(registerData);
      setSelectedGrade([]);
      setSelectedSubject([]);
      setSelectedBranch([]);
      setSchoolList([]);
      setRoleType(false);
      setVerifyEmail(false);
    }
    // else {
    //   setdataLoading(true);
    //   delete profileData.confirmPassword;
    //   postRegData().then((data) =>
    //     data === "user created successfully" ? functioSuccess() : fail()
    //   );
    //   setProfileData(registerData);
    //   setSelectedGrade([]);
    //   setSelectedSubject([]);
    //   setSelectedBranch([]);
    // }
    return 1;
  };

  // GET to add Type of Roles
  async function getSubjects(gradeids) {
    setdataLoading(true);
    const response = await fetch(
      `${urls.subjectSelector_Webinar}?grade_id=${gradeids.toString()}`,
      {
        // const response = await fetch(urls.getAllSubjectsList, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const getData = await response.json();
    setdataLoading(false);
    return getData;
  }
  async function getRolesList(URL) {
    setdataLoading(true);
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const getData = await response.json();
    setdataLoading(false);
    return getData;
  }

  useEffect(() => {
    async function getGrades() {
      setdataLoading(true);
      const response = await fetch(urls.categoryTypeApiList, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const getData = await response.json();
      setdataLoading(false);
      return getData;
    }
    getGrades().then((data) => {
      setGradeList(data);
    });

    getRolesList(
      `${urls.getRoleRegistration}?search=other&user_type=USER`
    ).then((data) => {
      setRolesList(data);
    });
  }, []);

  useEffect(() => {
    if (selectOrganisation === false) {
      async function getSchoolList() {
        let isOrchids = selectOrganisation ? "orchids" : "other";
        setdataLoading(true);
        const response = await fetch(
          urls.getSchoolListApi + `?search=${isOrchids}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const getData = await response.json();
        setdataLoading(false);
        return getData;
      }

      getSchoolList().then((data) => {
        setSchoolList(data);
      });
    }
  }, [selectOrganisation]);

  // Get what the user wants to become

  function JoiningDateFunc(e) {
    const UserDate = document.getElementById("joiningDate").value;
    const ToDate = new Date();
    if (new Date(UserDate).getTime() > ToDate.getTime()) {
      alert.warning("The Date must be less than today date");
      return false;
    }
    registerUserData("joining_date", e.target.value);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function handleGradeData(e) {
    const array = [];
    for (let i = 0; i < e.target.value.length; i += 1) {
      array.push(
        gradeList &&
          gradeList.filter((item) => item.type_name === e.target.value[i])[0].id
      );
    }
    setSelectedGrade(e.target.value);
    if (array.length !== 0) {
      getSubjects(array).then((data) => {
        setSubjects(data);
      });
      registerUserData("course_type", array);
    }
  }

  function handleSubject(e) {
    const array = [];
    for (let j = 0; j < e.target.value.length; j += 1) {
      array.push(
        subjectList &&
          subjectList.filter(
            (item) => item.subject_fk.sub_type_name === e.target.value[j]
          )[0].subject_fk.id
      );
    }
    setSelectedSubject(e.target.value);
    registerUserData("course_sub_type", array);
  }

  function handleBranch(e) {
    const array = [];
    for (let k = 0; k < e.target.value.length; k += 1) {
      array.push(
        branchList &&
          branchList.filter((item) => item.branch_name === e.target.value[k])[0]
            .id
      );
    }
    setSelectedBranch(e.target.value);
    registerUserData("branch", array);
  }
  let status;
  async function sendEmailForOtp() {
    const response = await fetch(urls.sendOtp, {
      method: "POST", // or 'PUT'
      body: JSON.stringify({ email: profileData.email }), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const fetchData = await response.json();
    status = response.status;
    return fetchData;
  }

  const handleClickOpen = () => {
    localStorage.setItem("email", JSON.stringify(profileData.email));

    sendEmailForOtp()
      .then((data) => {
        if (status === 200) {
          alert.success(data.response);
          //  console.log("succ")
          setOpen(true);
        } else {
          alert.warning(data.response);
        }
      })
      .catch(() => {
        alert.warning("Something went wrong");
      });
  };
  const handleVerifyEmail = () => {
    // console.log("veri"+verifyEmail);
    setVerifyEmail(true);
  };
  const handleByProp = () => {
    handleClose();
    handleVerifyEmail();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loader}
      {/* <Paper className={classes.paper}> */}
      <div>
        <Container
          maxWidth="sm"
          style={{
            textAlign: "center",
            marginTop: "8vh",
            paddingTop: "10px",
            backgroundColor: "rgba(135,187,190 , 0.6)",
            overflowY: "scroll",
            height: "80vh",
          }}
        >
          <LockOpenIcon style={{ color: "white" }} />
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              // background: "linear-gradient(to right,#bc4e9c ,#f80759)",
              color: "white",
              fontWeight: "bold",
              // padding: "5px 0px"
            }}
            className="formHeadAlign"
            color="primary"
            component="h2"
          >
            Registration
          </Typography>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl className={classes.normal} fullWidth>
                  <InputLabel
                    id="demo-controlled-open-select-label"
                    style={{ color: "white" }}
                  >
                    Select Organisation
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    className={classes.normal}
                    // open={open}
                    // onClose={handleClose}
                    // onOpen={handleOpen}
                    value={selectOrganisation}
                    onChange={(e) => selectOrgani(e.target.value)}
                    style={{ color: "white", textAlign: "left" }}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value>Orchids</MenuItem>
                    <MenuItem value={false}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="first_name"
                  label="First Name"
                  className={classes.normal}
                  //   className={usernamecolor ? classes.normal : errorClass.error}
                  value={profileData.first_name}
                  onChange={(e) =>
                    registerUserData("first_name", e.target.value)
                  }
                  name="first_name"
                />
                <div style={{ color: "yellow" }}>
                  {errorData.firstnameError}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  //   className={usernamecolor ? classes.normal : errorClass.error}
                  className={classes.normal}
                  // inputRef={regNameRef}
                  value={profileData.last_name}
                  onChange={(e) =>
                    registerUserData("last_name", e.target.value)
                  }
                />
                <div style={{ color: "yellow" }}>{errorData.lastnameError}</div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="userName"
                  name="username"
                  label="Erp Number"
                  className={classes.normal}
                  type="number"
                  inputProps={{
                    maxLength: 11,
                  }}
                  onChange={(e) =>
                    e.target.value.length < 12 &&
                    registerUserData("username", e.target.value)
                  }
                  value={profileData.username}
                />
                <div style={{ color: "yellow" }}>{errorData.usernameError}</div>
                {profileData.username &&
                profileData.username.length <= 8 &&
                !filterErp.test(profileData.username) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white" }}
                  >
                    invalid Erp number
                  </FormHelperText>
                ) : null}
                {profileData.username &&
                profileData.username.length > 8 || profileData.username.length === 11 &&
                // selectOrganisation == true ? profileData.username.length === 11 : profileData.username.length > 8 &&
                filterErp.test(profileData.username) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white" }}
                  >
                    Valid Erp Number
                  </FormHelperText>
                ) : null}
              </Grid>
              <br />
              <Grid item xs={12}>
                <TextField
                  type="number"
                  id="registerMobile"
                  label="Mobile Number"
                  className={classes.normal}
                  fullWidth
                  value={profileData.phone_number}
                  // eslint-disable-next-line max-len
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value, 10))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(e) =>
                    e.target.value.length < 11 && e.target.value > -1
                      ? registerUserData("phone_number", e.target.value)
                      : ""
                  }
                  name="phone_number"
                />
                <div style={{ color: "yellow" }}>
                  {errorData.phoneNumberError}
                </div>
                {profileData.phone_number &&
                profileData.phone_number.length !== 10 ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white" }}
                  >
                    Enter Your 10 digit Phone Number
                  </FormHelperText>
                ) : null}
                {profileData.phone_number &&
                profileData.phone_number.length === 10 ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white" }}
                  >
                    Valid Phone Number
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // style={{ width: "72%" }}
                  type="email"
                  id="registerEmail"
                  label="Email Id"
                  className={classes.normal}
                  fullWidth
                  value={profileData.email}
                  onChange={(e) => registerUserData("email", e.target.value)}
                  name="email"
                />

                {/* <Button
                  variant="contained"
                  style={{
                    background: "white",
                    paddingRight: "0.5%",
                    marginTop: "2.5%",
                  }}
                  onClick={handleClickOpen}
                  disabled={verifyEmail === true}
                >
                  verify Email
                  {verifyEmail ? (
                    <VerifiedIcon />
                  ) : (
                    <VerifiedIcon style={{ color: "white" }} />
                  )}
                </Button> */}

                {profileData.email && !filterEmail.test(profileData.email) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
                {profileData.email && filterEmail.test(profileData.email) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
                <div style={{ color: "yellow" }}>{errorData.emailError}</div>
              </Grid>

              <Grid item xs={9}>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  fullWidth
                  className={classes.modalBgc}
                  PaperProps={{
                    style: {
                      background: "rgba(135,187,190 , 0.8)",
                    },
                  }}
                >
                  {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
                  <DialogContent>
                    <OtpVerification handleClose={handleByProp} />
                  </DialogContent>

                  <DialogActions />
                </Dialog>
              </Grid>

              <br />
              <Grid item xs={12}>
                <Typography style={{ color: "white", float: "left" }}>
                  Select Gender
                </Typography>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <Radio
                      checked={profileData.gender === "Male"}
                      onChange={(e) =>
                        registerUserData("gender", e.target.value)
                      }
                      value="Male"
                      color="primary"
                      name="gender"
                      inputProps={{ "aria-label": "B" }}
                    />{" "}
                    <b style={{ color: "white" }}>Male</b>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Radio
                      checked={profileData.gender === "Female"}
                      onChange={(e) =>
                        registerUserData("gender", e.target.value)
                      }
                      value="Female"
                      color="primary"
                      name="gender"
                      inputProps={{ "aria-label": "B" }}
                    />
                    <b style={{ color: "white" }}>Female</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ color: "white", float: "left" }}>
                  Date of joining
                </Typography>
                <TextField
                  type="date"
                  id="joiningDate"
                  className={classes.normal}
                  fullWidth
                  required
                  value={profileData.joining_date}
                  onChange={(e) => JoiningDateFunc(e)}
                  name="joining_date"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  id="totalExperience"
                  label="Total Experience"
                  className={classes.normal}
                  required
                  fullWidth
                  // step="0.01"
                  value={profileData.total_experience}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value, 10))
                      .toString()
                      .slice(0, 3);
                  }}
                  // onInput={(e) => { e.target.value = Math.max(0,
                  // parseInt(e.target.value, 10)).toString().slice(0, 3); }}
                  onChange={(e) =>
                    e.target.value.length < 5 &&
                    registerUserData("total_experience", e.target.value)
                  }
                  name="total_experience"
                />
              </Grid>
              {selectOrganisation ? (
                <Grid item xs={12}>
                  <FormControl className={classes.normal} fullWidth>
                    <InputLabel
                      id="demo-controlled-open-select-label"
                      style={{ color: "white" }}
                      required
                    >
                      Select branches
                    </InputLabel>
                    <Select
                      required
                      multiple
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedBranch}
                      onChange={(e) => handleBranch(e)}
                      className={classes.normal}
                      name="branch"
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{ color: "white", textAlign: "left" }}
                    >
                      <MenuItem value={1} key={1} disabled>
                        Select branches
                      </MenuItem>
                      {branchList &&
                        branchList.length !== 0 &&
                        branchList.map((dropItem) => (
                          <MenuItem
                            value={dropItem.branch_name}
                            key={dropItem.id}
                          >
                            <Checkbox
                              checked={
                                selectedBranch.indexOf(dropItem.branch_name) >
                                -1
                              }
                            />
                            <ListItemText primary={dropItem.branch_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <FormControl className={classes.normal} fullWidth>
                    <InputLabel
                      id="demo-controlled-open-select-label"
                      style={{ color: "white" }}
                    >
                      Select School
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={profileData.school}
                      // let c onchange
                      onChange={(e, actionMeta) => {
                        registerUserData("school", e.target.value);
                        setRoleType(actionMeta.props.name);
                        setSelectedGrade([]);
                        setSelectedSubject([]);
                      }}
                      name="school"
                      style={{ color: "white", textAlign: "left" }}
                    >
                      <MenuItem value={1} key={1} disabled>
                        Select School
                      </MenuItem>
                      {schoolList &&
                        schoolList.length !== 0 &&
                        schoolList.map((dropItem) => (
                          <MenuItem
                            value={dropItem.id}
                            key={dropItem.id}
                            name={dropItem.is_academic}
                          >
                            {dropItem.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.normal} fullWidth>
                    <InputLabel
                      id="demo-controlled-open-select-label"
                      style={{ color: "white" }}
                    >
                      Select branches
                    </InputLabel>
                    <Select
                      required
                      multiple
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedBranch}
                      onChange={(e) => handleBranch(e)}
                      className={classes.normal}
                      name="branch"
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{ color: "white", textAlign: "left" }}
                    >
                      <MenuItem value={1} key={1} disabled>
                        Select branches
                      </MenuItem>
                      {branchList &&
                        branchList.length !== 0 &&
                        branchList.map((dropItem) => (
                          <MenuItem
                            value={dropItem.branch_name}
                            key={dropItem.id}
                          >
                            <Checkbox
                              checked={
                                selectedBranch.indexOf(dropItem.branch_name) >
                                -1
                              }
                            />
                            <ListItemText primary={dropItem.branch_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                {/* classes.formControl */}
                <FormControl className={classes.normal} fullWidth>
                  <InputLabel
                    id="demo-controlled-open-select-label"
                    style={{ color: "white" }}
                  >
                    Select Role
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={profileData.roles_category}
                    onChange={(e, actionMeta) => {
                      registerUserData("roles_category", e.target.value);
                      setRoleType(actionMeta.props.name);
                      setSelectedGrade([]);
                      setSelectedSubject([]);
                    }}
                    name="roles_category"
                    style={{ color: "white", textAlign: "left" }}
                  >
                    <MenuItem value={1} key={1} disabled>
                      Select Role
                    </MenuItem>
                    {roleList &&
                      roleList.length !== 0 &&
                      roleList.response.map((dropItem) => (
                        <MenuItem
                          value={dropItem.role_id}
                          key={dropItem.role_id}
                          name={dropItem.role_name}
                        >
                          {dropItem.role_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* {roleType
                && ( */}
              <>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel
                      id="demo-controlled-open-select-label"
                      style={{ color: "white" }}
                    >
                      Select Grade
                    </InputLabel>
                    <Select
                      required
                      multiple
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedGrade}
                      onChange={(e) => handleGradeData(e)}
                      name="course_type"
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{ color: "white", textAlign: "left" }}
                    >
                      <MenuItem value={1} key={1} disabled>
                        Select Grade
                      </MenuItem>
                      {gradeList &&
                        gradeList.length !== 0 &&
                        gradeList.map((dropItem) => (
                          <MenuItem
                            value={dropItem.type_name}
                            key={dropItem.id}
                          >
                            <Checkbox
                              checked={
                                selectedGrade.indexOf(dropItem.type_name) > -1
                              }
                            />
                            <ListItemText primary={dropItem.type_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel
                      id="demo-controlled-open-select-label"
                      style={{ color: "white" }}
                    >
                      Select Subject
                    </InputLabel>
                    <Select
                      required
                      multiple
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedSubject}
                      onChange={(e) => handleSubject(e)}
                      name="course_sub_type"
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      style={{ color: "white", textAlign: "left" }}
                    >
                      <MenuItem value={1} key={1} disabled>
                        Select Subject
                      </MenuItem>
                      {subjectList &&
                        subjectList.length !== 0 &&
                        subjectList.map((dropItem) => (
                          <MenuItem
                            value={dropItem.subject_fk.sub_type_name}
                            key={dropItem.subject_fk.id}
                          >
                            <Checkbox
                              checked={
                                selectedSubject.indexOf(
                                  dropItem.subject_fk.sub_type_name
                                ) > -1
                              }
                            />
                            <ListItemText
                              primary={dropItem.subject_fk.sub_type_name}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
              {/* )} */}
              <br />
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="registerPassword"
                  label="Enter Password"
                  //   className={passwordcolor ? classes.normal : errorClass.error}
                  className={classes.normal}
                  value={profileData.password}
                  onChange={(e) => registerUserData("password", e.target.value)}
                  fullWidth
                  name="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ color: "yellow" }}>{errorData.passwordError}</div>
              </Grid>
              <br />
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="registerPassword"
                  label="Confirm Password"
                  required
                  //   className={passwordcolor ? classes.normal : errorClass.error}
                  className={classes.normal}
                  value={profileData.confirmPassword}
                  onChange={(e) =>
                    registerUserData("confirmPassword", e.target.value)
                  }
                  fullWidth
                  name="confirmPassword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {profileData.password &&
                profileData.confirmPassword &&
                profileData.confirmPassword !== profileData.password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white" }}
                  >
                    Confirm Password should match password
                  </FormHelperText>
                ) : null}
                {profileData.password &&
                profileData.confirmPassword &&
                profileData.confirmPassword === profileData.password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "white" }}
                  >
                    Confirm Password matched
                  </FormHelperText>
                ) : null}
                <div style={{ color: "yellow" }}>{confirmPasswordError}</div>
              </Grid>
              <br />
              <br />
              <Button
                type="submit"
                id=""
                variant="contained"
                value="Submit"
                color="primary"
                fullWidth
                onClick={registerHandle}
                style={{
                  background: "white",
                  color: "black",
                  margin: "2rem 0",
                }}
              >
                Submit
              </Button>
              <br />
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
}

export default UserRegister;
