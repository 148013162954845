import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider
} from "@material-ui/core";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Bar } from "react-chartjs-2";

const RegisterUserCount = ({ userDetails, branchWiseUsers }) => {
  const auth = JSON.parse(localStorage.getItem("UserLogin"));
  console.log(userDetails, "props data comming for user");
  const displayDetails =
    userDetails && userDetails.length ? (
      <div>
        {userDetails.map((roleName) => (
          <Grid>
            <Typography variant="h5" component="h2">
              {roleName.role_count}
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {roleName.roles_category__name}
            </Typography>
          </Grid>
        ))}
      </div>
    ) : null;

  const lineChart =
    branchWiseUsers && branchWiseUsers.length ? (
      <Bar
        data={{
          labels: branchWiseUsers.map((data) => data.branch__branch_name),
          datasets: [
            {
              data: branchWiseUsers.map((data) => data.user_count),
              label: "users",
              backgroundColor: "rgba(0, 0, 255, 0.5)",
            },
          ],
        }}
        options={{
          // maintainAspectRatio: false,
          legend: { display: false },
          title: { display: true, text: "Branch Wise Register Users" },
        }}
      />
    ) : null;

  return (
    <Grid container spacing={4}>
      <Grid
        item
        md={
          (auth &&
            auth.personal_info.role &&
            auth.personal_info.role === "Principal") ||
          (auth &&
            auth.personal_info.role &&
            auth.personal_info.role === "LeadTeacher")
            ? 12
            : 12
        }
        xs={
          (auth &&
            auth.personal_info.role &&
            auth.personal_info.role === "Principal") ||
          (auth &&
            auth.personal_info.role &&
            auth.personal_info.role === "LeadTeacher")
            ? 12
            : 12
        }
      >
        {userDetails && userDetails.length !== 0 && (
          <Card>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" component="h2" align="center">
                  Total Register Users
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <CardContent>{displayDetails}</CardContent>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Card>
        )}
      </Grid>
      {auth &&
        auth.personal_info.role &&
        auth.personal_info.role !== "Principal" &&
        auth &&
        auth.personal_info.role &&
        auth.personal_info.role !== "LeadTeacher" && (
          <Grid
            item
            md={
              (auth &&
                auth.personal_info.role &&
                auth.personal_info.role === "Principal") ||
              (auth &&
                auth.personal_info.role &&
                auth.personal_info.role === "LeadTeacher")
                ? 12
                : 12
            }
            xs={12}
          >
            <article style={{}} className="canvas-container">
              {lineChart}
            </article>
          </Grid>
        )}
    </Grid>
  );
};

RegisterUserCount.propTypes = {
  userDetails: PropTypes.instanceOf(Array).isRequired,
  branchWiseUsers: PropTypes.instanceOf(Array).isRequired,
};

export default RegisterUserCount;
