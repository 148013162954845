/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import urls from '../../../../../url';
import styles from './CoursesChapters.style';

function CoursesChapters({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [trainingChapters, setTrainingChapters] = useState();
  const [loader, setLoader] = useState(false);

  const moduleId = sessionStorage.getItem('moduleId');

  const getLessonsId = (id) => {
    localStorage.setItem('contentId', id);
  };
  async function getChapters(id) {
    setLoader(true);

    const URL = urls.inHouseModules;
    const fetchResult = fetch(`${URL}?course_id=${id}`, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
    const response = await fetchResult;
    const jsonData = await response.json();
    setLoader(false);

    return jsonData;
  }

  useEffect(() => {
    getChapters(moduleId).then((data) => setTrainingChapters(data));
  }, []);

  return (
    <>
      <div className={classes.chapterBackground}>
        {loader ? (
          <>
            <img
              // open={open}
              className={classes.progress}
              alt="crash"
              src={require('../../../../../hoc/loder.gif')}
            />
          </>
        ) : (
          <Grid container>
            <Grid item sm={2} md={4}>
              {trainingChapters
                && trainingChapters.map((chapter, index) => {
                  const colors = [
                    '#54688c',
                    '#f47a62',
                    '#4a66da',
                    '#75cba8',
                    // "#f2bf5e"
                  ];
                  const diffColors = index % 4;
                  return (
                    <div
                      className={classes.moduleCards}
                      key={chapter.id}
                      style={{ backgroundColor: colors[diffColors] }}
                    >
                      <p className={classes.firstHead}>{chapter.title}</p>
                      <p className={classes.secondHead}>Induction</p>
                      <Link to="/CoursesLessons" className={classes.linkDecor}>
                        <Button onClick={() => getLessonsId(chapter.id)}>
                          Click Here
                        </Button>
                      </Link>
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
CoursesChapters.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CoursesChapters);
