/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  InputAdornment,
  IconButton,
  TextField,

  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import styles from './changePassword.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import { useHistory, useLocation } from 'react-router-dom';
import urls from '../../../url';
import axios from 'axios';


const ChangePassword = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [checkLoader, setCheckLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState('');
  const [confirmPassword, setConfrmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [permission, setPermission] = useState([]);
  const history = useHistory();


  const alert = useAlert();
  let loader = null;
  if (checkLoader) {
    loader = <Loader open />;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleMouseDownCurrentPassword = (event) => {
    event.preventDefault();
  };

  async function classicFetch(url) {
    setCheckLoader(true);
    const response = await fetch(url, {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        // module: localStorage.getItem('Change_Password')
        
      },
    });
    const res = await response.json();
    return res;
  }

  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        // module: localStorage.getItem('Change_Password')
        
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    // getPermissonData(localStorage.getItem('Change_Password'))
  }, [])

  const handleErrors = (response) => {
    if (response.status !== 200) {
      // if (response.status === 409) {
      //   alert.error(`A user already exists with ERP ${newErp}`);
      //   return response;
      // }
      // if (response.status === 404) {
      //   alert.error(`No user found for ERP ${erp}. Kindly register.`);
      //   return response;
      // }
      // if (response.status === 400) {
      //   alert.error(`Error: ${response.message}`);
      //   return response;
      // }
      alert.error(`Error : ${response.message}`);
    }
    return response;
  };

  const resetPassword = () => {
    const resetPasswordURL = `${urls.changeYourPassword}?old_password=${currentPassword}&new_password=${password}`;
    classicFetch(resetPasswordURL)
      .then(handleErrors)
      .then((res) => {
        setCheckLoader(false);
        if (res.status === 200) alert.success('Password changed.');
        // eslint-disable-next-line no-console
        console.log(res);
        history.push('/change_password')
      })
      .catch(
        (error) => {
          setCheckLoader(false);
          alert.warning(`Error:${error}`);
        },
      );
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          {/* {permission.can_view ?  */}
          <>
          <Typography variant="h4" align="center"> Change Your Password</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.root}>
              <Grid item md={4} />
              <Grid item md={4} xs={12}>
                <TextField
                  label="Current Password"
                  margin="normal"
                  type={showCurrentPassword ? 'text' : 'password'}
                  fullWidth
                  required
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownCurrentPassword}
                        >
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} />
              <Grid item md={4} />
              <Grid item md={4} xs={12}>
                <TextField
                  label="New Password"
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} />
              <Grid item md={4} />
              <Grid item md={4} xs={12}>
                <TextField
                  label="Confirm New Password"
                  margin="normal"
                  type={showPassword1 ? 'text' : 'password'}
                  required
                  fullWidth
                  value={confirmPassword || ''}
                  onChange={(e) => setConfrmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCPassword}
                          onMouseDown={handleMouseDownCPassword}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                {password && confirmPassword && confirmPassword !== password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'red' }}
                  >
                    Passwords do not match
                  </FormHelperText>
                ) : null}
                {password && confirmPassword && confirmPassword === password ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: 'green' }}
                  >
                    Passwords match
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4} />
                <Grid item md={4} xs={12}>
                  <Button
                    style={{ marginTop: '33px' }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={resetPassword}
                    disabled={!(password && confirmPassword && currentPassword
                  && confirmPassword === password)}
                  >
                    Change Your Password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          </>
          {/* :null} */}
        </div>
      </div>
      <div />
      {loader}
    </>
  );
};

ChangePassword.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ChangePassword);
