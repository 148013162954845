/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Backdrop,
  Select,
  MenuItem,
} from '@material-ui/core';
import styles from './positionMapping.style';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';

const PositionMapping = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [open, setOpen] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [assignedBy, setAssignedBy] = useState('');
  const [assignedTo, setAssignedTo] = useState([]);
  const [updateId, setUpdateId] = useState('');
  const [copyRoleList, setCopyRoleList] = useState([]);
  const [sortedRoleList, setSortesRoleList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [loading] = useState(false);

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);

  const {
    data: createdPositionRes,
    isLoading: creatingNewPosition,
    doFetch: fetchPosition,
  } = useFetch(null);

  const {
    data: rolewisePositionData,
    isLoading: gettingRoleWisePosition,
    doFetch: fetchRoleWisePosition,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)


  useEffect(() => {
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Role_Position_Mapping')
      },
    });
  },[])


  useEffect(() => {
    if (roleList) {
      const rrrrrr = [];
      for (let i = 0; i < roleList.length; i += 1) {
        rrrrrr.push(roleList[i]);
      }
      setCopyRoleList(rrrrrr);
    }
  }, [roleList]);

  function fetchRolesListFunc() {
    fetchRoles({
      url: urls.updateDeleteRoleApi,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Role_Position_Mapping')
      },
    });
  }

  useEffect(() => {
    fetchRolesListFunc();
  }, [fetchRoles, auth.personal_info.token]);

  function functionRemove() {
    if (assignedBy) {
      let i;
      const arrayDate = copyRoleList;
      for (i = 0; i < arrayDate.length; i += 1) {
        if (arrayDate[i].id === assignedBy) {
          arrayDate.splice(i, 0);
        }
      }
      setSortesRoleList(arrayDate);
    }
  }

  useEffect(() => {
    functionRemove();
  }, [assignedBy]);

  const alert = useAlert();

  let loader = null;
  if (roleListLoading || loading || gettingRoleWisePosition || creatingNewPosition) {
    loader = <Loader open />;
  }

  function getRoleMappedList() {
    if (!roleId) {
      alert.warning('Select Role');
      return;
    }
    fetchRoleWisePosition({
      url: `${urls.rolewiesPositionApi}?role_id=${roleId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Role_Position_Mapping')
      },
    });
  }

  useEffect(() => {
    if (createdPositionRes) {
      setOpen(false);
      setAssignedBy('');
      setAssignedTo([]);
      setIsEdit(false);
      setOpen(false);
      setUpdateId('');
      getRoleMappedList();
    }
  }, [createdPositionRes]);

  function AddPositionMapping() {
    setOpen(true);
  }
  const handleClose = () => {
    setAssignedTo([]);
    setAssignedBy('');
    setIsEdit(false);
    setOpen(false);
    setUpdateId('');
  };

  function createPositionFunc() {
    if (assignedTo.length === 0 || !assignedBy) {
      alert.warning('fill all fields');
      return;
    }
    const data = {
      position: assignedBy,
      assigned_positions: assignedTo,
    };
    const info = {
      assigned_positions: assignedTo,
    };
    if (isEdit === false) {
      fetchPosition({
        url: urls.createRolePositionMappingApi,
        body: data,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Role_Position_Mapping')
        },
      });
    } else {
      fetchPosition({
        url: `${urls.updatePositionMapping}${updateId}/retrieve_role_positions/`,
        body: info,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Role_Position_Mapping')
        },
      });
    }
  }
  function deleteFunction(id) {
    fetchPosition({
      url: `${urls.updatePositionMapping}${id}/retrieve_role_positions/`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Role_Position_Mapping')
        
      },
    });
  }
  function editFunction(fullData) {
    setUpdateId(fullData.id);
    setIsEdit(true);
    setAssignedBy(roleId);
    setOpen(true);
    const arrayList = [];
    for (let i = 0; i < fullData.assigned_positions.length; i += 1) {
      arrayList.push(fullData.assigned_positions[i].id);
    }
    setAssignedTo(arrayList);
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Position Mapping</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Typography>
                  Select Role
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={roleId || ''}
                  onChange={(e) => setRoleId(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {roleList
                  && roleList.length !== 0
                  && roleList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.name}
                    >
                      {data.name ? data.name : ''}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={1} xs={12}>
                <Button
                  className={classes.updateButton}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => getRoleMappedList()}
                >
                  Get
                </Button>
              </Grid>
              {modulePermission && modulePermission.response.can_add?
              <Grid item md={1} xs={12} className={classes.AddButton}>
                <Button variant="contained" color="primary" onClick={() => AddPositionMapping()}>Add</Button>
              </Grid>:null}
            </Grid>
          </Paper>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {rolewisePositionData && rolewisePositionData
          && (
          <Paper className={classes.paper}>
            {rolewisePositionData && rolewisePositionData.length === 0
          && <Typography variant="h5" style={{ textAlign: 'center', color: 'blue' }}>Records Not Found</Typography>}
            {rolewisePositionData && rolewisePositionData.length !== 0
            && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell float="left">
                    <Typography>S.No</Typography>
                  </TableCell>
                  <TableCell float="left">
                    <Typography>Name</Typography>
                  </TableCell>
                  {modulePermission && modulePermission.response.can_update?
                  <TableCell float="left">
                    <Typography>Edit</Typography>
                  </TableCell>:null}
                  {modulePermission && modulePermission.response.can_delete?
                  <TableCell float="left">
                    <Typography>Delete</Typography>
                  </TableCell>:null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rolewisePositionData && rolewisePositionData.length !== 0
                && rolewisePositionData.map((data, index) => (
                  <TableRow key={data.id}>
                    <TableCell float="left">
                      <Typography>{index + 1}</Typography>
                    </TableCell>
                    <TableCell float="left">
                      {data.assigned_positions.map((info) => (
                        <Typography key={info.id}>{info.name}</Typography>
                      ))}
                    </TableCell>
                    {modulePermission && modulePermission.response.can_update?
                    <TableCell float="left">
                      <Button variant="contained" color="primary" onClick={() => editFunction(rolewisePositionData[index])}>Edit</Button>
                    </TableCell>:null}
                    {modulePermission && modulePermission.response.can_delete?
                    <TableCell float="left">
                      <Button variant="contained" color="primary" onClick={() => deleteFunction(data.id)}>Delete</Button>
                    </TableCell>:null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            )}
          </Paper>
          )}
        </Grid>
      </Grid>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper className={classes.Modelpaper}>
            <Typography variant="h5">
              {' '}
              {isEdit ? 'Edit Role Mapping' : 'Create Role Mapping'}
            </Typography>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography>
                  Assigned By
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={assignedBy || ''}
                  disabled={isEdit}
                  onChange={(e) => setAssignedBy(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {roleList
                && roleList.length !== 0
                && roleList.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.name}
                  >
                    {data.name ? data.name : ''}
                  </MenuItem>
                ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  Assigned To
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  multiple
                  value={assignedTo || ''}
                  onChange={(e) => setAssignedTo(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {sortedRoleList
                && sortedRoleList.length !== 0
                && sortedRoleList.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.name}
                  >
                    {data.name ? data.name : ''}
                  </MenuItem>
                ))}
                </Select>
              </Grid>
              <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={() => createPositionFunc()}>{isEdit ? 'Update Position Mapping' : 'Create Position Mapping'}</Button>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </Paper>
        </Modal>
      </div>
      {loader}
    </>
  );
};
PositionMapping.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(PositionMapping);
