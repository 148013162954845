/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import './InductionForm.css';
import {
  withStyles, Typography, Grid, Button,
} from '@material-ui/core';
import styles from './InductionForm.style';
import urls from '../../../url';
import FeedBackForm from '../feedBack/feedBackForm';
import axios from 'axios';


const initialSubmit = {
  branchName: [],
  subjectName: [],
};

const initialValues = {
  inductionBranch: [],
  inductionCategory: [],
  inductionGrade: [],
  inductionSubject: [],
};

// let initialSelected = {
//   selectedCategory: null,
//   selectedGrade: null
// };
function InductionForm({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // const [inductionBranch, setInductionBranch] = useState();
  // const [inductionCategory, setInductionCategory] = useState();
  // const [inductionGrade, setInductionGrade] = useState(null);
  // const [inductionSubject, setInductionSubject] = useState(null);

  const [values, setAllValues] = useState(initialValues);
  const [submitData, setSubmitData] = useState(initialSubmit);
  const [submit, setSubmit] = useState(false);
  const [inductionData, setInductionData] = useState('');
  const [permission, setPermission] = useState([]);


  // useEffect(() => {
  //   if (auth.personal_info.role !== 'Teacher') {
  //     setSubmit(true);
  //   }
  // }, [auth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
  };

  // async function getBranch() {
  //   let branchesResult = fetch(urls.getBranchNames, {
  //     method: "GET",
  //     cache: "reload",
  //     headers: {
  //       "Content-Type": "Application/json",
  //       Authorization: `Bearer ${auth.personal_info.token}`
  //     }
  //   });

  //   const response = await branchesResult;
  //   const branchData = await response.json();
  //   return branchData;
  // }

  // async function getCategory() {
  //   let categoryResult = fetch(urls.getCategoryNames, {
  //     method: "GET",
  //     cache: "reload",
  //     headers: {
  //       "Content-Type": "Application/json",
  //       Authorization: `Bearer ${auth.personal_info.token}`
  //     }
  //   });

  //   const response = await categoryResult;
  //   const categoryData = await response.json();
  //   return categoryData;
  // }

  async function getData(url) {
    const dataResult = fetch(url, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Induction_Training')
      },
    });

    const response = await dataResult;
    const finalData = await response.json();
    return finalData;
  }

  async function getGrade(id) {
    const gradeResult = fetch(`${urls.getGradeNames}?category_id=${id}`, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Induction_Training')
      },
    });

    const response = await gradeResult;
    const gradeData = await response.json();
    return gradeData;
  }

  async function getSubject(categoryId, gradeId) {
    const subjectResult = fetch(
      `${urls.getGradeNames}?category_id=${categoryId}&category_type_id=${gradeId}`,
      {
        method: 'GET',
        cache: 'reload',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Induction_Training')
        },
      },
    );

    const response = await subjectResult;
    const subjectData = await response.json();
    return subjectData;
  }
  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Induction_Training'));
  }, [])

  useEffect(() => {
    // let inductionBranch = [];
    // let inductionCategory = [];
    let inductionFullData = null;
    // getBranch().then(data => setInductionBranch(data));
    // getBranch().then(data => {
    //   inductionBranch = data;
    //   setAllValues({ ...values, inductionCategory, inductionBranch });
    // });
    // getCategory().then(data => {
    //   inductionCategory = data;
    //   setAllValues({ ...values, inductionCategory, inductionBranch });
    // });

    // getData(urls.getCategoryNames).then((data) => {
    //   inductionCategory = data;
    //   setAllValues({ ...values, inductionCategory, inductionBranch });
    // });
    // getData(urls.getBranchNames).then((data) => {
    //   inductionBranch = data;
    //   setAllValues({ ...values, inductionCategory, inductionBranch });
    // });
    getData(urls.fetchInductionFormData).then((data) => {
      console.log('login data comming like', data);
      inductionFullData = data;
      setInductionData(inductionFullData);
    });
  }, []);

  const handleCategory = (id) => {
    sessionStorage.setItem('categoryId', id);
    getGrade(id).then((data) => setAllValues({ ...values, inductionGrade: data }));
  };

  const handleGrade = (gradeId) => {
    const catId = inductionData.roles_category !== null ? inductionData.roles_category.id : inductionData.role_category.id
    const categoryId = sessionStorage.getItem('categoryId');
    // eslint-disable-next-line max-len
    getSubject(catId, gradeId).then((data) => setAllValues({ ...values, inductionSubject: data }));
  };

  const handleBranchChange = (event) => {
    setSubmitData({ ...submitData, branchName: event.target.value });
  };

  const handleSubjectChange = (event) => {
    if (event.target.value !== 'DEFAULT') {
      setSubmitData({ ...submitData, subjectName: event.target.value });
    }
  };

  if (submit) {
    localStorage.setItem('subjectDataID', submitData.subjectName);
    return <Redirect to="/modules" />;
  }
  return (
    <>
      {/* sub_type_name  --- > subject key */}
      {/* branch_name --- > branch key */}
      <Grid container className={classes.backGroundColor}>
        <Grid item md={12} xs={12}>
          <div className={classes.formDiv}>
            <form className={classes.inductionForm} onSubmit={handleSubmit}>
              <div className="selectBranch">
                <Typography style={{
                  width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                }}
                >
                  Branch
                </Typography>
                <select
                  className="selectInput"
                  onChange={handleBranchChange}
                  defaultValue="DEFAULT"
                >
                  <option disabled value="DEFAULT">
                    Select Branch update
                  </option>
                  {inductionData
                && inductionData.branch
                && inductionData.branch.length !== 0
                && inductionData.branch.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.branch_name}
                  </option>
                ))}
                </select>
              </div>
              <div className="selectBranch">
                <Typography style={{
                  width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                }}
                >
                  Category
                </Typography>
                <select
                  onChange={(e) => handleCategory(e.target.value)}
                  className="selectInput"
                >
                  <option
                    value={
                  inductionData
                  && inductionData.role_category
                  && inductionData.role_category.id
                  &&inductionData.roles_category
                  &&inductionData.roles_category.id
                }
                    key={1}
                  >
                    {inductionData
                  && inductionData.role_category
                  && inductionData.role_category.name}
                  </option>
                </select>
                {/* <select
              defaultValue="DEFAULT"
              className={classes.selectInput}
              onChange={(e) => handleCategory(e.target.value)}
            >
              <option disabled value="DEFAULT">
                Select Category
              </option>
              {values.inductionCategory
                && values.inductionCategory.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.course_category_name}
                  </option>
                ))}
            </select> */}
              </div>

              {/* {(auth.personal_info.role === 'Teacher' || auth.personal_info.role === 'LeadTeacher') && ( */}
                <>
                  <div className="selectBranch">
                    <Typography style={{
                      width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                    }}
                    >
                      Grade
                    </Typography>
                    <select
                      defaultValue="DEFAULT"
                      className="selectInput"
                      onChange={(e) => handleGrade(e.target.value)}
                    >
                      <option disabled value="DEFAULT">
                        Select Grade
                      </option>
                      {inductionData
                    && inductionData.course_type
                    && inductionData.course_type.length !== 0
                    && inductionData.course_type.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.type_name}
                      </option>
                    ))}
                    </select>
                  </div>
                  <div className="selectBranch">
                    <Typography style={{
                      width: '40%', color: 'white', fontSize: '20px', fontWeight: '100',
                    }}
                    >
                      Subject
                    </Typography>
                    <select
                      defaultValue="DEFAULT"
                      className="selectInput"
                      onChange={handleSubjectChange}
                    >
                      <option value="DEFAULT">
                        Select Subject
                      </option>
                      {inductionData
                    && inductionData.course_sub_type
                    && inductionData.course_sub_type.length !== 0
                    && inductionData.course_sub_type.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.sub_type_name}
                      </option>
                    ))}
                    </select>
                  </div>
                </>
           
              {permission.can_view ? 
              <Button style={{ lineHeight: '0', fontSize: '25px', color: 'white' }} type="submit" className={classes.inductionFormSubmit}>
                <div className="arcButtonback">
                  Submit
                </div>
              </Button>
              :null}
            </form>
          </div>
        </Grid>
        <Grid item md={12} xs={12} className={classes.feedBackDiv}>
          <FeedBackForm />
        </Grid>
      </Grid>
    </>
  );
}
InductionForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(InductionForm);
