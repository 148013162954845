/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, withStyles, Button, Divider, TextField, Box, Checkbox, Radio, FormControlLabel, Tooltip,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { Add as AddIcon} from '@material-ui/icons';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import useFetch from '../../../../../hoc/useFetch';
import MatrixMCQ from '../matrixMCQ/matrixMCQ';
import SynonymsMcq from '../synonymsMcq/synonyms';
import MatchTheFollowing from '../matchTheFollowing/matchTheFollowing';
import styles from './createMcqTest.Style';
import urls from '../../../../../url';
import TinyTextEditor from '../../../../../hoc/tinyMce/tinyTextEditor';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import Loader from '../../../../../hoc/loader';
import axios from 'axios';
// import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
// import HowToVoteIcon from '@material-ui/icons/HowToVote';
// import Axios from 'axios';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useAlert } from '../../../../../hoc/alert/alert';


function getSteps() { return ['About The Test', 'Add Question']; }

const CreateMcqTest = ({
  classes, editData, createMcqQuestions, mcqSettings, activeStepInfo, settingsData, handleBackStep, questionsListinfo, openToAddMcq, deleteMcqQA, InductionCourse, TempquestionId
}) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [activeStep, setActiveStep] = React.useState(0);
  const [mcqTitle, setMcqTitle] = React.useState((editData && settingsData && settingsData.title) || '');
  const [mcqDescription, setMcqDescription] = React.useState((editData && settingsData && settingsData.description) || '');
  const [mcqInstruction, setMcqInstruction] = React.useState((editData && settingsData && settingsData.instruction) || '');
  const [mcqLimit, setmcqLimit] = React.useState((editData && settingsData && ((settingsData.is_limit === true && 'limited') || (settingsData.is_limit === false && 'noLimit'))) || '');
  const [mcqLimitedTime, setMcqLimitedTime] = React.useState((editData && settingsData && settingsData.duration) || '');
  const durationSplit = mcqLimitedTime.split(':');
  const [hours, setHours] = useState(durationSplit[0] || '');
  const [minitus, setMinitus] = useState(durationSplit[1] || '');
  const [seconds, setSeconds] = useState(durationSplit[2] || '');
  const [mcqActualMarksBool, setmcqActualMarksBool] = useState((editData && settingsData && settingsData.is_question_paper_actual_marks) || false);
  const [mcqActualMarks, setMcqActualMarks] = React.useState((editData && settingsData && settingsData.actual_marks) || '');
  const [mcqNegitive, setMcqNegitive] = React.useState((editData && settingsData && settingsData.is_question_paper_negative_marking) || false);
  const [mcqNegaitiveMarks, setMcqNegaitiveMarks] = React.useState((editData && settingsData && settingsData.question_paper_negative_marking_marks) || '');
  const [mcqPassingScore, setMcqPassingScore] = React.useState((editData && settingsData && settingsData.passing_score) || '');
  const [randomQuestion, setRandomQuestion] = React.useState((editData && settingsData && settingsData.no_of_ques) || '');
  const [mcqCompulsoryActivity] = React.useState((editData && settingsData && settingsData.is_compulsory_activity) || '');
  const [teachersVisibility, setTeachersVisibility] = React.useState((editData && settingsData && settingsData.courses_video_and_curriculum && settingsData.courses_video_and_curriculum.is_visible_to_teacher) || false);
  const [PrincipalVisibility, setPrincipalVisibility] = React.useState((editData && settingsData && settingsData.courses_video_and_curriculum && settingsData.courses_video_and_curriculum.is_visible_to_principal) || false);
  const [teacherTrineeVisibility, setTeacherTrineeVisibility] = React.useState((editData && settingsData && settingsData.courses_video_and_curriculum && settingsData.courses_video_and_curriculum.is_visible_to_trainee_while_training) || false);
  const [mcqTestAnswers, setMcqTestAnswers] = React.useState((editData && settingsData && ((settingsData.show_correct_answer === true && 'yes') || 'no')) || '');
  const [mcqShuffleQuestion, setMcqShuffleQuestion] = React.useState((editData && settingsData && settingsData.questions_shuffle) || false);
  const [mcqShuffleAnswerChoise, setMcqShffleAnswerChoise] = React.useState((editData && settingsData && settingsData.answer_choice_shuffle) || false);
  const [addQuestionOpen, setAddQuestionOpen] = React.useState(false);
  const [mcqQuestionType, setMcqQuestionType] = React.useState('');
  const [mcqQuestionAnswers, setMcqQuestionAnswers] = React.useState([{
    mcqQuestion: '', option1: '', option2: '', option3: '', option4: '',
  }]);
  const [answerforSingleChoice, setAnswerforSingleChoice] = React.useState('');
  const [answerForMultipleChoise, setAnswerForMultipleChoise] = useState([]);
  const [marksFortheQuestion, setMarksForTheQuestion] = useState('');
  const [negativeMarks, setNegativeMarks] = useState('');
  const [negativeYesorNo, setNegativeYesorNo] = useState(false);
  const [QuestionType, setQuestionType] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [choiceTabeMatrixData, setchoiceTabeMatrixData] = useState([]);
  const [SynonymsData, setSynonymsData] = useState('');
  const [MatchTheFollowingInfo, setMatchTheFollowingInfo] = useState('');
  const alert = useAlert();
  const [uploadLoader, setUploadLoader] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false)


  const steps = getSteps();
  const { data: QuestionTypeList, doFetch: FetchQuestionsType } = useFetch([]);
  const {
    data: createFileData,
    isLoading: creatingFileDataLoading,
    doFetch: fetchToSaveFileData,
  } = useFetch(null)

  // const {
  //   data: courseMcqSettings,
  //   isLoading: courseMcqSettingsLoading,
  //   doFetch: fetchToStoreMcqSettings,
  // } = useFetch(null);

  const [bulkUpload, setBulkUpload] = useState(false);
  // const [mcqImage, setMcqImage] = useState('')
  // const alert = useAlert();


  useEffect(() => {
    if (auth) {
      FetchQuestionsType({
        url: urls.apiToGetQuestionType,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Courses')
        },
      });
    }
  }, [auth, FetchQuestionsType]);

  useEffect(() => {
    if (activeStepInfo === 0 || activeStepInfo === 1) {
      setActiveStep(activeStepInfo);
    }
  }, [activeStepInfo]);

  useEffect(() => {
    if (openToAddMcq) {
      setAddQuestionOpen(false);
    }
  }, [openToAddMcq]);

  const handleNext = () => {
    mcqSettings(
      mcqTitle,
      mcqDescription,
      mcqInstruction,
      mcqLimit,
      mcqLimitedTime,
      mcqPassingScore,
      randomQuestion,
      mcqCompulsoryActivity,
      // mcqNoOfAttempts,
      // mcqFixedAttempts,
      // mcqTestValidity,
      // mcqTestStartDate,
      // mcqTestEndDate,
      // mcqTestPause,
      // mcqTestReview,
      mcqTestAnswers,
      // mcqShuffleSection,
      mcqShuffleQuestion,
      mcqShuffleAnswerChoise,
      mcqNegitive,
      mcqNegaitiveMarks,
      mcqActualMarks,
      teachersVisibility,
      PrincipalVisibility,
      teacherTrineeVisibility,
      mcqActualMarksBool,
    );
  };

  useEffect(() => {
    if (hours || minitus || seconds) {
      setMcqLimitedTime(`${hours || '00'}:${minitus || '00'}:${seconds || '00'}`);
    }
  }, [hours, minitus, seconds]);

  const handleCloseMcq = () => {
    setAddQuestionOpen(false);
    setEditQuestion(false);
  };

  function hadleSingleChoiseQuestionAnswer(value, option) {
    setAnswerforSingleChoice({ [option]: value });
  }

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function functionToSetQuestionAndAnswer(information, key) {
    setMcqQuestionAnswers((data) => {
      const newData = [...data];
      switch (key) {
        case 'mcqQuestion':
          newData[0][key] = information;
          return newData;
        case 'option1':
          newData[0][key] = information;
          return newData;
        case 'option2':
          newData[0][key] = information;
          return newData;
        case 'option3':
          newData[0][key] = information;
          return newData;
        case 'option4':
          newData[0][key] = information;
          return newData;
        default:
          return null;
      }
    });
  }

  function handleChange(event, option) {
    const currentIndex = answerForMultipleChoise.indexOf(option);
    const newChecked = [...answerForMultipleChoise];
    if (currentIndex === -1) {
      newChecked.push(option);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setAnswerForMultipleChoise(newChecked);
  }

  function functionToCreateMcq(matrixQuestion, marksForQuestion, negativeMarksQuestion, negativeYesorNoType, QuestionTypeInfo, choiceArray) {
    createMcqQuestions(
      // mcqImage,
      mcqQuestionType,
      mcqQuestionAnswers,
      answerforSingleChoice,
      answerForMultipleChoise,
      (marksFortheQuestion || marksForQuestion),
      (negativeMarks || negativeMarksQuestion),
      (negativeYesorNo || negativeYesorNoType),
      (QuestionType || QuestionTypeInfo),
      editQuestion,
      questionId,
      matrixQuestion,
      choiceArray,
      mcqActualMarksBool,
    );
  }

  // function functionToHandleImage(data) {
  //   if((data && data.type === 'image/jpeg') || (data.type === 'image/png')) {
  //     setMcqImage(data);
  //   } else {
  //     alert.warning('Upload Image in JPEG && PNG format Only')
  //   }
  // }
  function functionToCreateSynonmes(synonymsQuestion, marksForQuestion, negativeMarksQuestion, negativeYesorNoType, QuestionTypeInfo, synonymsArray, synonymsAnswerArray, matrixQuestion, choiceArray) {
    createMcqQuestions(mcqQuestionType, mcqQuestionAnswers, answerforSingleChoice, answerForMultipleChoise, marksForQuestion, negativeMarksQuestion, negativeYesorNoType, QuestionTypeInfo, editQuestion, questionId, matrixQuestion, choiceArray, mcqActualMarksBool, synonymsQuestion, synonymsArray, synonymsAnswerArray);
  }

  function functionToCreateMatchTheFollowing(matchQandA, MatchQuestion, synonymsQuestion, marksForQuestion, negativeMarksQuestion, negativeYesorNoType, QuestionTypeInfo, synonymsArray, synonymsAnswerArray, matrixQuestion, choiceArray) {
    createMcqQuestions(mcqQuestionType, mcqQuestionAnswers, answerforSingleChoice, answerForMultipleChoise, marksForQuestion, negativeMarksQuestion, negativeYesorNoType, QuestionTypeInfo, editQuestion, questionId, matrixQuestion, choiceArray, mcqActualMarksBool, synonymsQuestion, synonymsArray, synonymsAnswerArray, matchQandA, MatchQuestion);
  }

  function choiseMatrixModel() {
    return (<MatrixMCQ handleClose={handleCloseMcq} createMatrix={functionToCreateMcq} matrixData={choiceTabeMatrixData} actiualMarks={mcqActualMarksBool} negativeMarksStatus={mcqNegitive} />);
  }

  function SynonymsModel() {
    return (<SynonymsMcq actiualMarks={mcqActualMarksBool} negativeMarksStatus={mcqNegitive} submitSynonmemos={functionToCreateSynonmes} synonymsInfoData={SynonymsData || {}} />);
  }

  function matchTheFollowingModel() {
    return (<MatchTheFollowing submitMatchTheFollowing={functionToCreateMatchTheFollowing} MatchTheFollowingData={MatchTheFollowingInfo || {}} />);
  }
  function addMcqQuestion() {
    let modal = null;
    modal = (
      <>
        <Dialog
          disableEnforceFocus
          maxWidth="xl"
          fullWidth
          open={addQuestionOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.mcqmodal}
        >
          <DialogTitle id="alert-dialog-title" onClose={handleCloseMcq}>
            {editQuestion ? 'Edit Question' : 'Create Question '}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={1} />
              {QuestionTypeList
                && QuestionTypeList.map((item, index) => (
                  <Grid item md={2} xs={12} key={index}>
                    <Radio
                      checked={parseInt(mcqQuestionType, 10) === item.id}
                      onChange={(e) => (setMcqQuestionType(e.target.value))}
                      value={item.id}
                      color="primary"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                    {item.question_type_name}
                  </Grid>
                ))}
            </Grid>
            {mcqQuestionType
              && (
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Box border={2} className={classes.answerPaper}>
                      <Typography variant="h6" className={classes.titlestyle}>
                        {QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name}
                      </Typography>
                      <Divider className={classes.divider} />
                      {QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && ((QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Single Choice') || (QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Multiple Choice'))
                        && (
                          <>
                            <ExpansionPanel style={{ backgroundColor: 'snow' }}>
                              <ExpansionPanelSummary
                                style={{ backgroundColor: 'lightgray' }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  Question Editor
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                  <Grid item md={12} xs={12}>
                                    <Typography variant="h6">Question : </Typography>
                                    <TinyTextEditor
                                      id="QuestionId"
                                      initialValue={mcqQuestionAnswers[0].mcqQuestion || ''}
                                      value={mcqQuestionAnswers[0].mcqQuestion || ''}
                                      onChange={(content) => functionToSetQuestionAndAnswer(content, 'mcqQuestion')}
                                    />
                                  </Grid>
                                  {/* <Grid item md={12} xs={12}>
                                    <Typography variant="h6">
                                      Upload Image &nbsp;
                                      <b style={{ color: 'red' }}>*</b>
                                    </Typography>
                                    <input
                                    style={{ display: 'none' }}
                                    className={classes.fileUpload}
                                    id="outlined-button-filee"
                                    type="file"
                                    onChange={(e) => functionToHandleImage(e.target.files[0])}
                                  />
                                  <label htmlFor="outlined-button-filee">
                                    <Button
                                      variant="outlined"
                                      component="span"
                                      size="small"
                                      className={classes.fileUpload}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Browse
                                    </Button>
                                    {' '}
                                        &nbsp; &nbsp;
                                        {mcqImage && typeof(mcqImage) === 'object' && mcqImage.name}
                                    
                                  </label>
                                  {mcqImage && typeof (mcqImage) === 'string'
                                  && <a href={mcqImage} target="_blank" rel="noopener noreferrer">Click To View</a>}
                                  </Grid> */}
                                  <Grid item md={12} xs={12}>
                                    <Typography variant="h6">Choice 1 : </Typography>
                                    <TinyTextEditor
                                      id="Option1Id"
                                      initialValue={mcqQuestionAnswers[0].option1 || ''}
                                      value={mcqQuestionAnswers[0].option1 || ''}
                                      onChange={(content) => functionToSetQuestionAndAnswer(content, 'option1')}
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Typography variant="h6">Choice 2 : </Typography>
                                    <TinyTextEditor
                                      id="option2Id"
                                      initialValue={mcqQuestionAnswers[0].option2 || ''}
                                      value={mcqQuestionAnswers[0].option2 || ''}
                                      onChange={(content) => functionToSetQuestionAndAnswer(content, 'option2')}
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Typography variant="h6">Choice 3 : </Typography>
                                    <TinyTextEditor
                                      id="option3Id"
                                      initialValue={mcqQuestionAnswers[0].option3 || ''}
                                      value={mcqQuestionAnswers[0].option3 || ''}
                                      onChange={(content) => functionToSetQuestionAndAnswer(content, 'option3')}
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Typography variant="h6">Choice 4 : </Typography>
                                    <TinyTextEditor
                                      id="option4Id"
                                      initialValue={mcqQuestionAnswers[0].option4 || ''}
                                      value={mcqQuestionAnswers[0].option4 || ''}
                                      onChange={(content) => functionToSetQuestionAndAnswer(content, 'option4')}
                                    />
                                  </Grid>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                              style={{ backgroundColor: 'snow', marginTop: '20px' }}
                            >
                              <ExpansionPanelSummary
                                style={{ backgroundColor: 'lightgray' }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  Answer
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                  {QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Single Choice'
                                    && mcqQuestionAnswers[0].option1 && mcqQuestionAnswers[0].option2 && mcqQuestionAnswers[0].option3 && mcqQuestionAnswers[0].option4 && (
                                      <>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => hadleSingleChoiseQuestionAnswer(mcqQuestionAnswers[0].option1, 'option1')}>
                                            <Radio
                                              checked={answerforSingleChoice.option1 === mcqQuestionAnswers[0].option1}
                                              onChange={(e) => hadleSingleChoiseQuestionAnswer(e.target.value, 'option1')}
                                              value={mcqQuestionAnswers[0].option1}
                                              color="primary"
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <label htmlFor="option1" variant="h6">
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option1)}
                                            </label>
                                          </Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => hadleSingleChoiseQuestionAnswer(mcqQuestionAnswers[0].option2, 'option2')}>
                                            <Radio
                                              checked={answerforSingleChoice.option2 === mcqQuestionAnswers[0].option2}
                                              onChange={(e) => hadleSingleChoiseQuestionAnswer(e.target.value, 'option2')}
                                              value={mcqQuestionAnswers[0].option2}
                                              color="primary"
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <label htmlFor="option2" variant="h6">
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option2)}
                                            </label>
                                          </Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => hadleSingleChoiseQuestionAnswer(mcqQuestionAnswers[0].option3, 'option3')}>
                                            <Radio
                                              checked={answerforSingleChoice.option3 === mcqQuestionAnswers[0].option3}
                                              onChange={(e) => hadleSingleChoiseQuestionAnswer(e.target.value, 'option3')}
                                              value={mcqQuestionAnswers[0].option3}
                                              color="primary"
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <label htmlFor="option3" variant="h6">
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option3)}
                                            </label>
                                          </Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => hadleSingleChoiseQuestionAnswer(mcqQuestionAnswers[0].option4, 'option4')}>
                                            <Radio
                                              checked={answerforSingleChoice.option4 === mcqQuestionAnswers[0].option4}
                                              onChange={(e) => hadleSingleChoiseQuestionAnswer(e.target.value, 'option4')}
                                              value={mcqQuestionAnswers[0].option4}
                                              color="primary"
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <label htmlFor="option4" variant="h6">
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option4)}
                                            </label>
                                          </Button>
                                        </Grid>
                                      </>
                                    )}
                                  {QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Multiple Choice'
                                    && mcqQuestionAnswers[0].option1 && mcqQuestionAnswers[0].option2 && mcqQuestionAnswers[0].option3 && mcqQuestionAnswers[0].option4 && (
                                      <>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => handleChange('e', 'option1')}>
                                            <Checkbox
                                              checked={answerForMultipleChoise.indexOf('option1') !== -1}
                                              onChange={(e) => handleChange(e, 'option1')}
                                              value={mcqQuestionAnswers[0].option1}
                                              color="primary"
                                            />
                                            <label htmlFor="option1" className={classes.texttitle}>
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option1)}
                                            </label>
                                          </Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => handleChange('e', 'option2')}>
                                            <Checkbox
                                              checked={answerForMultipleChoise.indexOf('option2') !== -1}
                                              onChange={(e) => handleChange(e, 'option2')}
                                              value={mcqQuestionAnswers[0].option2}
                                              color="primary"
                                            />
                                            <label htmlFor="option2" className={classes.texttitle}>
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option2)}
                                            </label>
                                          </Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => handleChange('e', 'option3')}>
                                            <Checkbox
                                              checked={answerForMultipleChoise.indexOf('option3') !== -1}
                                              onChange={(e) => handleChange(e, 'option3')}
                                              value={mcqQuestionAnswers[0].option3}
                                              color="primary"
                                            />
                                            <label htmlFor="option3" className={classes.texttitle}>
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option3)}
                                            </label>
                                          </Button>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                          <Button style={{ textTransform: 'inherit' }} onClick={() => handleChange('e', 'option4')}>
                                            <Checkbox
                                              checked={answerForMultipleChoise.indexOf('option4') !== -1}
                                              onChange={(e) => handleChange(e, 'option4')}
                                              value={mcqQuestionAnswers[0].option4}
                                              color="primary"
                                            />
                                            <label htmlFor="option4" className={classes.texttitle}>
                                              {ReactHtmlParser(mcqQuestionAnswers[0].option4)}
                                            </label>
                                          </Button>
                                        </Grid>
                                      </>
                                    )}
                                  <Grid container spacing={2}>
                                    <Grid item md={2} x={12}>
                                      <Typography
                                        variant="h6"
                                        className={classes.titlestylee}
                                      >
                                        {' '}
                                        Graded Question
                                        {' '}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={1}
                                      xs={6}
                                      style={{ marginTop: '15px' }}
                                    >
                                      <Switch
                                        checked={QuestionType}
                                        onChange={(e) => setQuestionType(e.target.checked)}
                                        color="default"
                                        inputProps={{
                                          'aria-label': 'primary checkbox',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item md={2} x={12}>
                                      <Typography
                                        variant="h6"
                                        className={classes.titlestylee}
                                      >
                                        {' '}
                                        Practice Question
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  {!mcqActualMarksBool
                                    && (
                                      <Grid container spacing={2}>
                                        <Grid item md={3} x={12}>
                                          <Typography
                                            variant="h6"
                                            className={classes.titlestylee}
                                          >
                                            Marks for Question :
                                      </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={1}
                                          xs={12}
                                          className={classes.TypographyDate}
                                        >
                                          <TextField
                                            label="Marks"
                                            margin="dense"
                                            className={classes.textField}
                                            required
                                            type="number"
                                            value={marksFortheQuestion || ''}
                                            onChange={(e) => setMarksForTheQuestion(e.target.value)}
                                            variant="outlined"
                                          />
                                        </Grid>
                                      </Grid>
                                    )}

                                  {!mcqNegitive
                                    && (
                                      <Grid container spacing={2}>
                                        <Grid item md={2} x={12}>
                                          <Typography
                                            variant="h6"
                                            className={classes.titlestylee}
                                          >
                                            Negative Marking :
                                      </Typography>
                                        </Grid>
                                        <Grid item md={1} xs={6} style={{ marginTop: '15px' }}>
                                          <Switch
                                            checked={negativeYesorNo}
                                            onChange={(e) => setNegativeYesorNo(e.target.checked)}
                                            color="primary"
                                            inputProps={{
                                              'aria-label': 'primary checkbox',
                                            }}
                                          />
                                        </Grid>
                                        {negativeYesorNo && (
                                          <Grid item md={1} xs={6} className={classes.TypographyDate}>
                                            <TextField
                                              label="Marks"
                                              margin="dense"
                                              className={classes.textField}
                                              required
                                              type="number"
                                              value={negativeMarks || ''}
                                              onChange={(e) => setNegativeMarks(e.target.value)}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        )}
                      {mcqQuestionType && QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Choice Table Matrix' && choiseMatrixModel()}
                      {mcqQuestionType && QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Synonyms' && SynonymsModel()}
                      {mcqQuestionType && QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Match The Following' && matchTheFollowingModel()}
                      <Grid container spacing={1} style={{ marginTop: '10px' }}>
                        <Grid item md={10} />
                        {mcqQuestionType && QuestionTypeList && QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0] && ((QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Single Choice') || (QuestionTypeList.filter((item) => item.id === parseInt(mcqQuestionType, 10))[0].question_type_name === 'Multiple Choice'))
                          && (
                            <Grid item md={2} xs={6}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => functionToCreateMcq()}
                              >
                                Save
                              </Button>
                            </Grid>
                          )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )}
          </DialogContent>
        </Dialog>
      </>
    );
    return modal;
  }

  function functionToEditMcqQuestion(id) {
   
    setEditQuestion(true);
    setQuestionId(questionsListinfo && questionsListinfo[id].id);
    setAddQuestionOpen(true);
    setMcqQuestionType(questionsListinfo && questionsListinfo[id].type_question.id);
    setMarksForTheQuestion(questionsListinfo && questionsListinfo[id].actual_question_mark);
    setNegativeMarks(questionsListinfo && questionsListinfo[id].negative_marking_marks);
    setNegativeYesorNo(questionsListinfo && questionsListinfo[id].is_negative_marking);
    setQuestionType(questionsListinfo && questionsListinfo[id].is_graded_question);

    if ((questionsListinfo && questionsListinfo[id] && questionsListinfo[id].type_question.question_type_name === 'Single Choice') || (questionsListinfo && questionsListinfo[id] && questionsListinfo[id].type_question.question_type_name === 'Multiple Choice')) {
      setMcqQuestionAnswers(questionsListinfo && questionsListinfo[id].question);
      if (questionsListinfo && questionsListinfo[id] && questionsListinfo[id].type_question && questionsListinfo[id].type_question.question_type_name === 'Multiple Choice') {
        setAnswerForMultipleChoise(JSON.parse(questionsListinfo && questionsListinfo[id].correct_ans));
      } else {
        setAnswerforSingleChoice(JSON.parse(questionsListinfo && questionsListinfo[id].correct_ans));
      }
    }
    if (questionsListinfo && questionsListinfo[id] && questionsListinfo[id].type_question.question_type_name === 'Choice Table Matrix') {
      setchoiceTabeMatrixData(questionsListinfo && questionsListinfo[id]);
    }
    if (questionsListinfo && questionsListinfo[id] && questionsListinfo[id].type_question.question_type_name === 'Synonyms') {
      setSynonymsData(questionsListinfo && questionsListinfo[id]);
    }
    if (questionsListinfo && questionsListinfo[id] && questionsListinfo[id].type_question.question_type_name === 'Match The Following') {
      setMatchTheFollowingInfo(questionsListinfo && questionsListinfo[id]);
    }
  }

  function functionToAddQuestion() {
    setAddQuestionOpen(true);
    setEditQuestion(false);
    setMcqQuestionType('');
    setMcqQuestionAnswers([
      {
        mcqQuestion: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
      },
    ]);
    setAnswerforSingleChoice('');
    setAnswerForMultipleChoise([]);
    setchoiceTabeMatrixData([]);
    setSynonymsData({});
    setMatchTheFollowingInfo({});
    setMarksForTheQuestion(0);
    setNegativeMarks(0);
    setNegativeYesorNo(false);
    setQuestionType(false);
  }
  function functionForDurationField() {
    return (
      <Grid container spacing={1}>
        <Grid item md={4} xs={2}>
          <TextField
            margin="dense"
            required
            type="number"
            placeholder="000"
            value={hours || ''}
            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 3); }}
            onChange={(e) => (e.target.value.length < 4 && e.target.value > -1 ? setHours(e.target.value) : '')}
            variant="outlined"
            helperText="Hrs"
          />
        </Grid>
        <Grid item md={4} xs={2}>
          <TextField
            margin="dense"
            required
            type="number"
            placeholder="00"
            value={minitus || ''}
            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2); }}
            onChange={(e) => (e.target.value.length < 3 && e.target.value > -1 && e.target.value < 61 ? setMinitus(e.target.value) : '')}
            variant="outlined"
            helperText="Min"
          />
        </Grid>
        <Grid item md={4} xs={2}>
          <TextField
            margin="dense"
            required
            type="number"
            placeholder="00"
            value={seconds || ''}
            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2); }}
            onChange={(e) => (e.target.value.length < 3 && e.target.value > -1 && e.target.value < 61 ? setSeconds(e.target.value) : '')}
            variant="outlined"
            helperText="Sec"
          />
        </Grid>
      </Grid>
    );
  }

  const downloadCSV = (fileName, data) => {
    var data, filename, link;
    var csv = data;
    if (csv == null) return;

    filename = fileName || "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  };

  const importItemDataFormExcel = (file) => {
    // console.log('BULK',file)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('question_paper_id', TempquestionId)
    setUploadLoader(true)
    axios.post(urls.bulkUploadApi, formData, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Courses')
      }
    }).then(response =>{
        setIsFileUploaded(true)
        setUploadLoader(false)
        alert.success('Uploaded Successfully')
        console.log(response, "error logs");
    }).catch(error => {
      setUploadLoader(false)
      alert.error('Something went wrong please check log !!!')
      downloadCSV('response_log', error.response.data.error)
      console.log(error.response.data.error, "error response");
    })

    // fetchToSaveFileData({
    //   url: urls.bulkUploadApi,
    //   method: 'POST',
    //   body: formData,
    //   headers: {
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //     module: localStorage.getItem('Courses')
    //   },
    // });
  }


  let loader = null;
  if (creatingFileDataLoading || uploadLoader) {
    loader = <Loader open />
  }

  // useEffect(() => {
  //   if(bulkUpload){
  //     fetchToStoreMcqSettings({
  //       url: `${urls.apiForUpdateCourseSettings}${mcqDetails.id}/create_chapter_related_question_mapping/`,
  //       method: 'PUT',
  //       body: data,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${auth.personal_info.token}`,
  //       },
  //     });
  //   }
  // }, [bulkUpload])

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Box border={2} className={classes.answerPaper}>
              <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                  <Typography className={classes.titlestyle}>
                    Title
                    <strong style={{ color: 'red' }}>*</strong>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    margin="dense"
                    className={classes.textField}
                    required
                    value={mcqTitle || ''}
                    onChange={(e) => setMcqTitle(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Clone existing Test
                  </Button>
                </Grid>

                <Grid item md={2} xs={12}>
                  <Typography className={classes.Descstyle}>
                    Description :
                  </Typography>
                </Grid>
                <Grid item md={10} xs={12}>
                  <TinyTextEditor
                    id="mcqDescreptionId"
                    initialValue={mcqDescription || ''}
                    value={mcqDescription || ''}
                    onChange={(content) => setMcqDescription(content)}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography className={classes.Descstyle}>
                    Instruction :
                    {' '}
                  </Typography>
                </Grid>
                <Grid item md={10} xs={12}>
                  <TinyTextEditor
                    id="mcqInstructionsId"
                    initialValue={mcqInstruction || ''}
                    value={mcqInstruction || ''}
                    onChange={(content) => setMcqInstruction(content)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box border={2} className={classes.answerPaper}>
              <Typography variant="h6" className={classes.texttitle}>
                {' '}
                Basic Settings
              </Typography>
              <Divider className={classes.divider} />
              <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                  <Typography className={classes.subtitlestyle}>
                    Test Duration :
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.TypographyDate}>
                  <Radio
                    checked={mcqLimit === 'noLimit'}
                    onChange={(e) => setmcqLimit(e.target.value)}
                    value="noLimit"
                    color="primary"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  {' '}
                  No Limit
                </Grid>
                <Grid item md={2} xs={12} className={classes.TypographyDate}>
                  <Radio
                    checked={mcqLimit === 'limited'}
                    onChange={(e) => setmcqLimit(e.target.value)}
                    value="limited"
                    color="primary"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                  Limited
                </Grid>
                <Grid item md={3} xs={12}>
                  {mcqLimit === 'limited' && functionForDurationField()}
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={2} xs={6}>
                  <Typography className={classes.subtitlestyle}>
                    Passing Score :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={6}>
                  <TextField
                    label="Score"
                    className={classes.textField}
                    type="number"
                    margin="dense"
                    value={mcqPassingScore || ''}
                    onChange={(e) => setMcqPassingScore(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={9} />
                <Grid item md={3} xs={6}>
                  <Typography className={classes.subtitlestyle}>
                    Question wise Marking :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={4}>
                  <Switch
                    checked={mcqActualMarksBool}
                    onChange={(e) => setmcqActualMarksBool(e.target.checked)}
                    color="primary"
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  {mcqActualMarksBool
                    && (
                      <Typography className={classes.subtitlestyle}>
                        Question Paper wise Marking :
                      </Typography>
                    )}
                </Grid>
                <Grid item md={2} xs={6}>
                  {mcqActualMarksBool
                    && (
                      <TextField
                        label="Actual Marks"
                        margin="dense"
                        type="number"
                        required
                        value={mcqActualMarks || ''}
                        onChange={(e) => setMcqActualMarks(e.target.value)}
                        variant="outlined"
                      />
                    )}
                </Grid>
                <Grid item md={2} />
                <Grid item md={4} xs={4}>
                  <Typography className={classes.subtitlestyle}>
                    Question Wise Negative Marks :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={4}>
                  <Switch
                    checked={mcqNegitive}
                    onChange={(e) => setMcqNegitive(e.target.checked)}
                    color="primary"
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </Grid>
                {mcqNegitive
                  && (
                    <>
                      <Grid item md={4} xs={4}>
                        <Typography className={classes.subtitlestyle}>
                          Question Paper Wise Negative Marks :
                          </Typography>
                      </Grid>
                      <Grid item md={2} xs={4}>
                        <TextField
                          label="Negitive marks"
                          margin="dense"
                          type="number"
                          required
                          value={mcqNegaitiveMarks || ''}
                          onChange={(e) => setMcqNegaitiveMarks(e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                <Grid item md={1} />
                {InductionCourse === false
                  && (
                    <>
                      <Grid item md={12} xs={12}>
                        <Checkbox
                          checked={PrincipalVisibility || false}
                          onChange={(e) => setPrincipalVisibility(e.target.checked)}
                          value="primary"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span className={classes.texttitle}>
                          visible to trainer
                      </span>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Checkbox
                          checked={teachersVisibility || false}
                          onChange={(e) => setTeachersVisibility(e.target.checked)}
                          value="primary"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span className={classes.texttitle}>
                          visible to trainee
                      </span>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Checkbox
                          checked={teacherTrineeVisibility || false}
                          onChange={(e) => setTeacherTrineeVisibility(e.target.checked)}
                          value="primary"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span className={classes.texttitle}>
                          is visible to trainee while training
                      </span>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Box>
          </Grid>

          <Grid item md={12} xs={12}>
            <Box border={2} className={classes.answerPaper}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <Typography className={classes.titlestyle}>
                    Show Correct Answer :
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.TypographyDate}>
                  <Radio
                    checked={mcqTestAnswers === 'yes'}
                    onChange={(e) => setMcqTestAnswers(e.target.value)}
                    value="yes"
                    color="primary"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                  Yes
                </Grid>
                <Grid item md={2} xs={12} className={classes.TypographyDate}>
                  <Radio
                    checked={mcqTestAnswers === 'no'}
                    onChange={(e) => setMcqTestAnswers(e.target.value)}
                    value="no"
                    color="primary"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                  No
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                  <span className={classes.titlestyle}>
                    Shuffle :
                  </span>
                </Grid>
                <Grid item md={4} xs={12} className={classes.TypographyDate}>
                  <Checkbox
                    checked={mcqShuffleQuestion || false}
                    onChange={(e) => setMcqShuffleQuestion(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <span className={classes.texttitle}>
                    Question
                    {' '}
                  </span>
                </Grid>
                <Grid item md={2} xs={12} className={classes.TypographyDate}>
                  <Checkbox
                    checked={mcqShuffleAnswerChoise || false}
                    onChange={(e) => setMcqShffleAnswerChoise(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <span
                    className={classes.texttitle}
                  >
                    Answer Choice
                    {' '}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                  <Typography className={classes.subtitlestyle}>
                  Total Random Questions :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={12}>
                  <TextField
                    label="Question"
                    className={classes.textField}
                    type="number"
                    margin="dense"
                    // value={mcqPassingScore || ''}
                    value={randomQuestion || ''}
                    // onChange={(e) => setMcqPassingScore(e.target.value)}
                    onChange={(e) => setRandomQuestion(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {activeStep === 1 && (
        <>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={bulkUpload}
                  color="primary"
                  onChange={(e) => setBulkUpload(e.target.checked)}
                  // style={{color:'blue',fontSize:"20px"}}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label='Bulk Upload Question'

            />

          </Grid>
          {
            bulkUpload ? (
              <>
                <Grid container spacing={2}>

                  {/* <Grid md={12} xs={12}>
                    <Typography >
                      Upload File
                    </Typography>
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <input
                      accept=".csv"
                      hidden
                      id="icon-button-file"
                      type="file"
                      onChange={(e) => importItemDataFormExcel(e.target.files[0])}
                      style={{ fontSize: '20px' }}
                    // variant="h4"z
                    />
                    {createFileData || isFileUploaded ?(
                       <p style={{textAlign:"center",color:"green",fontSize:'18px',padding:"8px"}}>Uploaded successfully <p> < CheckCircleRoundedIcon style={{marginTop:"5px",fontSize:26,color:"green"}}/></p> </p>
                    ):(
                    
                    <Grid md={12} xs={12}>
                      <label htmlFor="icon-button-file">
                        <Tooltip title="import file">
                          <Button
                            variant="outlined"
                            color="secondary"
                            aria-label="upload picture"
                            component="span"
                            style={{ margin: "auto", display: 'block', textAlign: 'center', padding: '0px', marginTop: '10px', marginBottom: '10px' }}
                          >
                            Upload File  <SaveAltRoundedIcon style={{ fontSize: '15px' }} />
                          </Button>
                        </Tooltip>
                      </label>
                    </Grid>)}
                    {/* {createFileData &&(
                        "successfully uploaded"
                    )} */}
                  </Grid>
                </Grid>

              </>)
              : (
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Box border={2} className={classes.answerPaper}>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                          <Button
                            variant="contained"
                            className={classes.textField}
                            color="primary"
                            onClick={() => functionToAddQuestion()}
                          >
                            Add Question
                    <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>

                    {(((questionsListinfo && questionsListinfo.length === 0)
                      || questionsListinfo === null) && (
                        <Box border={2} className={classes.answerPaper}>
                          <Typography
                            variant="h6"
                            style={{ textAlign: 'center', color: 'blue' }}
                          >
                            No Questions are Added
                    {' '}
                          </Typography>
                        </Box>
                      ))
                      || ''}
                    <Grid container spacing={2}>
                      {(questionsListinfo
                        && questionsListinfo.length !== 0
                        && questionsListinfo.map((item, index) => (
                          <Grid item md={6} xs={12} key={index}>
                            <Box border={2} className={classes.answerPaper}>
                              <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                  <Typography variant="h6" className={classes.titlestyle}>
                                    {ReactHtmlParser(item.question && item.question[0].mcqQuestion) || ''}
                                    {ReactHtmlParser(item.choice_table_matrix_question_title) || ''}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Button
                                    className={classes.textField}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => functionToEditMcqQuestion(index)}
                                  >
                                    Edit
                          </Button>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Button
                                    className={classes.textField}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => deleteMcqQA(item.id)}
                                  >
                                    Delete
                          </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        )))
                        || ''}
                    </Grid>
                  </Grid>
                </Grid>
              )
          }

        </>
      )}
      <Grid container spacing={1}>
        <Grid item md={6} />
        <Grid item md={3} xs={6}>
          <Button
            disabled={activeStep === 0}
            onClick={() => handleBackStep(editData && settingsData && settingsData.id)}
            className={classes.textField}
            variant="contained"
            color="primary"
          >
            Back
          </Button>
        </Grid>
        <Grid item md={3} xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.textField}
            disabled={activeStep === 1}
          >
            {activeStep === steps.length - 1 ? 'Next' : 'Next'}
          </Button>
        </Grid>
      </Grid>
      {addMcqQuestion()}
      {loader}
    </>
  );
};
CreateMcqTest.defaultProps = {
  editData: null,
  createMcqQuestions: null,
  mcqSettings: null,
  activeStepInfo: null,
  settingsData: null,
  handleBackStep: null,
  questionsListinfo: null,
  openToAddMcq: null,
  deleteMcqQA: null,
};

CreateMcqTest.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  editData: PropTypes.bool,
  createMcqQuestions: PropTypes.func,
  mcqSettings: PropTypes.func,
  activeStepInfo: PropTypes.number,
  settingsData: PropTypes.instanceOf(Object),
  handleBackStep: PropTypes.func,
  questionsListinfo: PropTypes.instanceOf(Object),
  openToAddMcq: PropTypes.bool,
  deleteMcqQA: PropTypes.instanceOf(Object),
  InductionCourse: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CreateMcqTest);
