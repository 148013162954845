export default () => ({
  teacherToolboxMainDiv: {
    width: '80%',
    height: 'auto',
    display: 'grid',
    paddingTop: '8rem',
    gridTemplateColumns: 'repeat(4 , 1fr)',
    gridGap: '1rem',
    margin: '0 auto',
    zIndex: '-100',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4a66da',
    backgroundColor: 'white',
  },
});
