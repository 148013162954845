/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  Grid, TablePagination, IconButton, Typography, Divider, TextField, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './courseSequence.Style';
import { useAlert } from '../../../hoc/alert';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';



function CourseSequence({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [indexId, setIndexId] = useState('');
  const alert = useAlert();

  const {
    data: sequencingCoursesList,
    isLoading: gettingSequencingCourseList,
    doFetch: fetchAllSeequencingCourseList,
  } = useFetch([]);

  const {
    data: sequencingUpdateRes,
    isLoading: updatingSequencing,
    doFetch: fetchSubmitting,
  } = useFetch([]);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)

// if(modulePermission)
  // console.log(modulePermission.response.can_update)

  
  useEffect(() => {
    // module=localStorage.getItem('Course_Sequence')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Sequence')
      },
    });
  },[])

  useEffect(() => {
    fetchAllSeequencingCourseList({
      url: `${urls.sequencingCourseListApi}?course_type=1&page_size=${rowsPerPage || 12}&page=${page + 1}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Course_Sequence')
      },
    });
    if (indexId) {
      document.getElementById(`sequencing${parseInt(indexId, 10)}`).value = '';
    }
  }, [auth, page, rowsPerPage, sequencingUpdateRes,indexId]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(5);
    }
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  let loader = null;
  if (gettingSequencingCourseList || updatingSequencing || modulePermissionLoading) {
    loader = <Loader open />;
  }

  function functionToUpdate(id) {
    setIndexId(id);
    if (!document.getElementById(`sequencing${id}`).value) {
      alert.warning('enter sequencing number');
      return;
    }
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('are you confirm to change the sequencing number');
    if (confirm) {
      const data = {
        sequencing: document.getElementById(`sequencing${id}`).value,
      };
      fetchSubmitting({
        url: `${urls.updateCourseSequencingApi}${id}/course_sequencing/?course_type=1`,
        body: data,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
        module: localStorage.getItem('Course_Sequence')
        },
      });
      window.history.go()
    }
  }

  return (
    <>
      <Typography variant="h4"> Course Sequencing </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {sequencingCoursesList && sequencingCoursesList.results
          && sequencingCoursesList.results.length === 0
          && <Typography variant="h6" style={{ color: 'blue', marginTop: '20px', textAlign: 'center' }}>Teachers Not Found</Typography>}
          {sequencingCoursesList && sequencingCoursesList.results
          && sequencingCoursesList.results.length !== 0
        && (
        <Paper className={classes.paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">S.No</TableCell>
                <TableCell align="left">Course Name</TableCell>
                <TableCell align="left">Old Sequencing Number</TableCell>
                <TableCell align="left">Enter New Sequencing Number</TableCell>
                {modulePermission && modulePermission.response.can_update ?
                <TableCell align="left">Change</TableCell>:null}
              </TableRow>
            </TableHead>
            <TableBody>
              {sequencingCoursesList
            && sequencingCoursesList.results
            && sequencingCoursesList.results.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">{item.course_name}</TableCell>
                <TableCell align="left">{item.sequencing}</TableCell>
                <TableCell align="left">
                  <TextField
                    helperText="Enter New Sequencing Number"
                    margin="dense"
                    required
                    id={`sequencing${item.id}`}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString().slice(0, 10);
                    }}
                    type="number"
                    inputProps={{ style: { fontFamily: 'Times', color: 'black' } }}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ?
                  <Button variant="contained" color="primary" onClick={() => functionToUpdate(item.id)}>Change</Button>:null}
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </Paper>
        )}
        </Grid>
        <Grid item md={12} xs={12}>
          {sequencingCoursesList
        && sequencingCoursesList.results
        && sequencingCoursesList.results.length !== 0 && (
        <Paper style={{ backgroundColor: 'lightgray', marginTop: '10px' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+sequencingCoursesList.total_pages}`}
                  rowsPerPageOptions={[5, 20, 30]}
                  count={+sequencingCoursesList.count}
                  rowsPerPage={rowsPerPage || 5}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(sequencingCoursesList.total_pages - 1)}
                    disabled={page === +sequencingCoursesList.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
          )}
        </Grid>
      </Grid>
      {loader}
    </>
  );
}

CourseSequence.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CourseSequence);
