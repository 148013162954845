/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  withStyles,
  Divider,
  Button,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Modal,
  Fade,
  Backdrop,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './courseLanguage.style';
import urls from '../../../../url';
import Loader from '../../../../hoc/loader';
import useFetch from '../../../../hoc/useFetch';
import { useAlert } from '../../../../hoc/alert/alert';

const CourseLanguage = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [languageName, setLanguageName] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [loaging, setloading] = useState(false);
  const [AddLanguageData, setAddLanguageData] = useState('');
  const [UpdateLanguageData, setUpdateLanguageData] = useState('');
  const [editLanguageName, setEditLanguageName] = useState('');
  const alert = useAlert();

  const {
    data: GetLanguagesData,
    isLoading: gettingLanguageData,
    doFetch: FetchLanguageData,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)

  let loader = null;
  if (gettingLanguageData || loaging || modulePermissionLoading) {
    loader = <Loader open />;
  }

  const handleClose = () => {
    setOpen(false);
    setEditLanguageName('');
  };

  function editLanguageFunction(name, IDno) {
    setOpen(true);
    setEditLanguageName(name);
    setId(IDno);
  }
useEffect(() => {
  // module=localStorage.getItem('Course_Language')
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Language')
      },
    });
  },[])
  function UpdataLanguageFunction() {
    if (!editLanguageName) {
      alert.warning('fill the Field');
      return;
    }
    const updateData = {
      language_name: editLanguageName,
    };
    setloading(true);
    fetch(`${urls.UpdateLanguageApi}${id}/create_retrieve_course_language/`, {
      method: 'PUT',
      body: JSON.stringify(updateData),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Course_Language')
      },
    })
      .then((res) => {
        if (res.status === 302) {
          setloading(false);
          setLanguageName('');
          alert.warning('language name already exist');
        }
        if (res.status === 200) {
          setloading(false);
          alert.success('Successfully Updated');
          return res.json();
        }
        if (res.status !== 200 && res.status !== 302) {
          setloading(false);
          setLanguageName('');
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => setUpdateLanguageData(data));
  }
  const getTableData = () => {
    FetchLanguageData({
      url: `${urls.GetLanguageDataApi}?page_size=${rowsPerPage
        || 10}&page=${page + 1}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Language')
      },
    });
    return 0;
  };

  useEffect(() => {
    if (AddLanguageData && AddLanguageData.id) {
      getTableData();
      setLanguageName('');
    }
  }, [AddLanguageData]);

  useEffect(() => {
    if (UpdateLanguageData && UpdateLanguageData.id) {
      setOpen(false);
      getTableData();
      setLanguageName('');
    }
  }, [UpdateLanguageData]);

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [auth, page, rowsPerPage]);

  function CreateLanguageFunction() {
    if (!languageName) {
      alert.warning('Enter the Language');
      return;
    }
    const formData = {
      language_name: languageName,
    };
    setloading(true);
    fetch(urls.createLanguageApi, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Course_Language')
        
      },
    })
      .then((res) => {
        if (res.status === 302) {
          setloading(false);
          setLanguageName('');
          alert.warning('language name already exist');
        }
        if (res.status === 201) {
          setloading(false);
          alert.success('Successfully Created');
          return res.json();
        }
        if (res.status !== 201 && res.status !== 302) {
          setloading(false);
          setLanguageName('');
          alert.warning('somthing went wrong please try again ');
        }
        return 0;
      })
      .then((data) => {
        setAddLanguageData(data);
      });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  let Updatemodal = null;
  Updatemodal = (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant="h4">Update Language</Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={10} xs={12}>
              <TextField
                className={classes.textField}
                label="Language Name"
                margin="dense"
                required
                value={editLanguageName || ''}
                onChange={(e) => setEditLanguageName(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <Button
                className={classes.textField}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => UpdataLanguageFunction()}
              >
                Update Language
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Paper>
      </Fade>
    </Modal>
  );

  return (
    <>
      <Typography variant="h4">Course Language</Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              className={classes.textField}
              label="Language Name"
              margin="dense"
              required
              value={languageName}
              onChange={(e) => setLanguageName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          {modulePermission && modulePermission.response.can_add ?
          <Grid item md={4} xs={12}>
            <Button
              className={classes.applyButton}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => CreateLanguageFunction()}
            >
              Create Language
            </Button>
          </Grid>:null}
        </Grid>
      </Paper>
      {(GetLanguagesData
        && GetLanguagesData.results
        && GetLanguagesData.results === 0 && (
          <Typography
            variant="h4"
            style={{ color: 'blue', textAlign: 'center', marginTop: '50px' }}
          >
            Language are not Found
          </Typography>
      ))
        || ''}
      {(GetLanguagesData
        && GetLanguagesData.results
        && GetLanguagesData.results !== 0 && (
        <>
          <Paper style={{ marginTop: '15px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Language Name</TableCell>
                  <TableCell align="left">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {GetLanguagesData
                    && GetLanguagesData.results.map((data, indexId) => (
                      <TableRow key={data.id}>
                        <TableCell align="left">{indexId + 1}</TableCell>
                        <TableCell align="left">{data.language_name}</TableCell>
                        <TableCell align="left">
                          {modulePermission && modulePermission.response.can_update ?
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => editLanguageFunction(data.language_name, data.id)}
                          >
                            Update
                          </Button>:null}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() => `Page ${page + 1} of ${+GetLanguagesData.total_pages}`}
                    rowsPerPageOptions={[10, 20, 30]}
                    count={+GetLanguagesData.count}
                    rowsPerPage={rowsPerPage || 10}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: '13px' }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(GetLanguagesData.total_pages - 1)}
                      disabled={page === +GetLanguagesData.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </>
      ))
        || ''}
      {Updatemodal}
      {loader}
    </>
  );
};
CourseLanguage.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CourseLanguage);
