/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withStyles, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './CoursesModules.css';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ideabulb from '../../Media/idea.png';
import styles from './CoursesModules.style';

import urls from '../../../../url';

function CoursesModule({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [modules, setModulesData] = useState();
  const [loader, setLoader] = useState(false);

  async function getModules() {
    setLoader(true);
    const URL = urls.inHouseModules;
    const fetchResult = fetch(URL, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
    const response = await fetchResult;
    const jsonData = await response.json();
    setLoader(false);
    return jsonData;
  }

  useEffect(() => {
    getModules().then((data) => setModulesData(data));
  }, []);

  const moduleClick = (id) => {
    sessionStorage.setItem('moduleId', id);
  };

  return (
    <div>
      <div className={classes.trainingModule}>
        {loader ? (
          <>
            <CircularProgress
              variant="determinate"
              value={100}
              className={classes.top}
              size={60}
              thickness={8}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className={classes.bottom}
              size={60}
              thickness={8}
            />
          </>
        ) : (
          // <div className={`box ${classes.moduleCover}`}>
          <Grid container>
            <Grid item sm={2} md={4}>
              {modules
                && modules.map((module, index) => {
                  const colors = [
                    '#54688c',
                    '#f47a62',
                    '#4a66da',
                    '#75cba8',
                    // "#f2bf5e"
                  ];
                  const diffColors = index % 4;
                  return (
                    <div
                      className={classes.moduleCards}
                      key={module.id}
                      style={{ backgroundColor: colors[diffColors] }}
                    >
                      <div className={classes.introBox}>
                        <div>
                          <span className={classes.firstHead}>
                            {module.course_name}
                            {/* Modern HTML & CSS From The Beginning (Including Sass)
                          asdad sdsd asdsd sds */}
                          </span>
                        </div>
                        <p className={classes.secondHead}>Induction</p>
                      </div>
                      <Link to="/CoursesChapters">
                        <Button onClick={() => moduleClick(module.id)}>
                          <img
                            src={ideabulb}
                            alt="crash"
                            className={classes.bulbImg}
                          />
                        </Button>
                      </Link>
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
CoursesModule.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CoursesModule);
