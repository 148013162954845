export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
    overflow: true,
  },
  typographys: {
    padding: theme.spacing(1, 2, 1),
    paddingTop: '100px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
