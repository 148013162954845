export default (theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerD: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'black',
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  fullViewBox: {
    borderColor: 'lightgray',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
    width: '100%',
    marginBottom: '5px',
  },
  contentBox: {
    borderColor: 'lightgray',
    textAlign: 'center',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
    width: '100%',
    marginBottom: '5px',
  },
  textField: {
    color: 'black',
    width: '100%',
  },
  typography: {
    fontFamily: 'Charter Bd BT',
    color: 'darkslategray',
    marginRight: '20px',
  },
  appBar: {
    padding: theme.spacing(4, 3, 3),
    backgroundColor: 'lightgray',
  },
  paper: {
    padding: theme.spacing(1, 2, 1),
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: '9px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typographyy: {
    color: 'blue',
  },
  buttonview: {
    alignItems: 'center',
    textAlign: 'center',
  },
  typText: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    color: 'blue',
    fontSize: '30px',
  },
  TextDesc: {
    color: 'black',
  },
  TextTitle: {
    marginTop: '15px',
    color: 'black',
    fontSize: '25px',
    textAlign: 'center',
  },
  IconButtons: {
    alignItems: 'center',
    textAlign: 'center',
  },
});
