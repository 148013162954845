import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, TextField, Grid,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import styles from './matchTheFollowing.style';
// import urls from '../../../../../url';

const INITIAL_STATE = {
  // id: 0,
  question: '',
  answer: '',
};
function MatchTheFollowing({ classes, submitMatchTheFollowing, MatchTheFollowingData }) {
  const individualRow = INITIAL_STATE;
  const [MatchQuestion, setMatchQuestion] = useState('');
  const [matchQandA, setMatchQandA] = useState([{ ...individualRow }]);

  useEffect(() => {
    if (MatchTheFollowingData && MatchTheFollowingData.question_paper) {
      setMatchQandA(MatchTheFollowingData && MatchTheFollowingData.question);
      setMatchQuestion(MatchTheFollowingData
         && MatchTheFollowingData.choice_table_matrix_question_title);
    }
  }, [MatchTheFollowingData]);

  const handleChange = (e, i, key) => {
    setMatchQandA((data) => {
      const newData = [...data];
      switch (key) {
        case 'question':
          newData[i][key] = e;
          return newData;
        case 'answer':
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    });
  };

  const addMatchTheFollowingRow = () => {
    setMatchQandA([...matchQandA, { ...individualRow }]);
  };

  function DeleteOptionFunction(index) {
    setMatchQandA((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  return (
    <div className={classes.mainBox}>
      <div className={classes.paper}>
        <TextField
          label="Main Question"
          multiline
          variant="outlined"
          onChange={(e) => setMatchQuestion(e.target.value)}
          value={MatchQuestion || ''}
          fullWidth
        />
      </div>
      <div className={classes.paperr}>
        <button type="button" className={classes.mtfBtns} onClick={addMatchTheFollowingRow}>
          Add Row
        </button>
        <div className={classes.singleRow} style={{ marginBottom: '1rem' }}>
          <h3 style={{ textAlign: 'center', color: 'black' }}>Question</h3>
          <h3 style={{ textAlign: 'center', color: 'black' }}>Answers</h3>
        </div>
        {matchQandA.map((item, index) => {
          const question = `question-${index}`;
          const answer = `answer-${index}`;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid container spacing={2} key={index}>
              <Grid item md={5} xs={5}>
                <TextField
                  label="Question"
                  multiline
                  variant="outlined"
                  onChange={(e) => handleChange(e.target.value, index, 'question')}
                  value={item.question}
                  name={question}
                  fullWidth
                  id={question}
                  className="question"
                  data-idx={index}
                />
              </Grid>
              <Grid item md={5} xs={5}>
                <TextField
                  label="Answer"
                  multiline
                  fullWidth
                  onChange={(e) => handleChange(e.target.value, index, 'answer')}
                  value={item.answer}
                  name={answer}
                  variant="outlined"
                  id={answer}
                  className="answer"
                  data-idx={index}
                />
              </Grid>
              <Grid item md={2} xs={2}>
                <IconButton data-idx={index} onClick={() => DeleteOptionFunction(index)}>
                  <DeleteIcon size="small" color="secondary" />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
        <button
          className={classes.mtfBtns}
          type="submit"
          onClick={() => submitMatchTheFollowing(matchQandA, MatchQuestion)}
          style={{ display: 'block', marginLeft: 'auto', marginRight: '8%' }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
MatchTheFollowing.defaultProps = {
  MatchTheFollowingData: {},
};

MatchTheFollowing.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  submitMatchTheFollowing: PropTypes.func.isRequired,
  MatchTheFollowingData: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(MatchTheFollowing);
