// import React, { useEffect } from "react";
// import { withStyles, Button, useTheme } from "@material-ui/core";
// import { Link } from "react-router-dom";
// // import styles from "./InHouseNavbar.style";
// // import udanLogo from './Media/SureLearning.webp';
// import udanLogo from "./Media/mainLogo.jpeg";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import "./CSS/inHouseNavbar.css";

// function InHouseNavbar() {
//   // const theme = useTheme();
//   // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const handleLogoutClose = () => {
//     localStorage.removeItem("UserLogin");
//     localStorage.setItem("isLoggedIn", false);
//     window.location.href = "/";
//   };

//   // useEffect(() => {
//   //   navSlide();
//   // }, []);

//   const burger = document.getElementById(".burger");
//   const nav = document.querySelector(".nav-links");

//   if (burger) {
//     console.log(burger, "aaaaaaaaaaaaaaaaaaa");
//     burger.addEventListener("click", () => {
//       console.log("Hello");
//       nav.classList.toggle("nav-active");
//     });
//   }

//   // navSlide();

//   // let prevScrollPos = window.pageYOffset;
//   // // eslint-disable-next-line func-names
//   // window.onscroll = function() {
//   //   const currentScrollPos = window.pageYOffset;
//   //   // console.log(currentScrollPos);
//   //   if (prevScrollPos > currentScrollPos) {
//   //     document.getElementById("navbar").style.opacity = "1";
//   //   } else {
//   //     document.getElementById("navbar").style.opacity = "0";
//   //   }
//   //   prevScrollPos = currentScrollPos;
//   // };
//   return (
//     <>
//       <nav
//         className='navbar'
//         id='navbar'
//         // style={{ overflowX: "hidden" }}
//         // fullScreen={fullScreen}
//       >
//         <div className='navbarBrand'>
//           <a
//             href='/'
//             style={{
//               textDecoration: "none",
//               color: "white"
//             }}
//           >
//             <img src={udanLogo} alt='' height='70px' width='150px' />
//           </a>
//         </div>
//         <ul className='nav-links'>
//           <li className='submenu'>
//             <a className='submenuLinks' href='/'>
//               Home
//             </a>
//           </li>
//           <li className='submenu'>
//             <Link className='submenuLinks' to='/about'>
//               About
//             </Link>
//           </li>
//           <li className='submenu'>
//             <a className='submenuLinks' href='/inductionTraining'>
//               Induction Training
//             </a>
//           </li>
//           <li className='submenu'>
//             <a className='submenuLinks' href='/inHouseCourses'>
//               Courses
//             </a>
//           </li>
//           <li className='submenu'>
//             <a className='submenuLinks' href='/inHouseTreasureBox'>
//               Treasure Box
//             </a>
//           </li>
//           <li className='submenu'>
//             <Link className='submenuLinks' to='/activity'>
//               Activities
//             </Link>
//           </li>
//           <li
//             className='submenu'
//             style={{ borderRight: "1px solid transparent" }}
//           >
//             <a className='submenuLinks' href='/'>
//               Contact
//             </a>
//           </li>
//         </ul>
//         <div className='burger'>
//           <div className='line1'></div>
//           <div className='line2'></div>
//           <div className='line3'></div>
//         </div>
//         {/* <Button onClick={handleLogoutClose} className='logoutLink'>
//           Logout
//         </Button> */}
//       </nav>
//     </>
//   );
// }
// InHouseNavbar.propTypes = {
//   classes: PropTypes.instanceOf(Object).isRequired
// };

// // export default withStyles(styles)(InHouseNavbar);
// export default InHouseNavbar;

import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import udanLogo from "./Media/SureLearning.webp";
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import EventIcon from '@material-ui/icons/Event';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NotesIcon from '@material-ui/icons/Notes';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import udanLogo from './Media/mainLogo.jpeg';
import './InHouseNavbar.css';
import DrawerToggleButton from './SideDrawer/DrawerToggleButton';
import urls from '../../url';
import axios from 'axios';


// import urls from '../../url'
// import { Email } from '@material-ui/icons';


function InHouseNavbar({ drawerClickHandler }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [profileanchorEl, setProfileAnchorEl] = useState(null);
  const [notesList, setNotesList] = useState(null);
  const [changePass, setchangePass] = useState([]);

  const [email,setEmail] = useState(true);
  // const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const loginData = JSON.parse(localStorage.getItem('UserLogin'))
  const role = loginData && loginData.personal_info && loginData.personal_info.role;

  const handleLogoutClose = () => {
    localStorage.removeItem('UserLogin');
    localStorage.setItem('isLoggedIn', false);
    window.location.href = '/';
  };

  const handleNotesClick = (event) => {
    setNotesList(event.currentTarget);
    setProfileAnchorEl(null);
  };

  const handleProfileClick = (event) => {
    // console.log(event.currentTarget , 'sdsdsdsds')
    setProfileAnchorEl(event.currentTarget);
    setNotesList(null);
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };
  const handleNotesClose = () => {
    setNotesList(null);
  };
  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        // module: localStorage.getItem('Change_Password')
        
      },
    }).then(response => {
      setchangePass(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  // useEffect(() => {
  //   getPermissonData(localStorage.getItem('Change_Password'))
  // }, [])

  // var isOrchids = loginData.academic_profile.user.email.includes("@orchids.edu.in");
  var isOrchids = loginData.role_permission.is_orchids;
  // console.log("isorchids ",isOrchids)
  useEffect(() => {
    // async function getEmail() {
    //   // setdataLoading(true);
    //   const response = await fetch(urls.profileFetchApi, { //url
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${auth.personal_info.token}`,
    //     },
    //   });
    //   const getData = await response.json();
    //   // setdataLoading(false);
    //   return getData;
    // }
    // getEmail().then((data) => {
    //   var n = data.user.email.includes("@orchids.edu.in");
    //   // console.log(n);
    //   if(n){
    //     setEmail(false);
    //   }else{
    //     setEmail(true);
    //   }
    // });
    // console.log("trying"+trying.academic_profile.user.email);
    
    // console.log("n "+n);
    if(!isOrchids){
        setEmail(false);
    }else{
      setEmail(true);
    }
  });
  const handleSelfDriven = () => {
    localStorage.setItem('principalCourseType', 'self_driven');
  };
  const urlFun = ()=>{
    if(localStorage.getItem('Principal_Dashboard')!=="null"){
      return  <a href="/principalDashboard/" onClick={handleSelfDriven}>Reports</a>
    }
    if(localStorage.getItem('Principal_Dashboard')!=="null"){
      return  <a href="/principalDashboard/visualDashboard" onClick={handleSelfDriven}>Reports</a>
    }
    if (localStorage.getItem('Teacher_Dashboard')!=="null"){
      return  <a href="/teacherReport" onClick={handleSelfDriven}>Reports</a>
    }
    return null
  }
  return (
    <header className="toolbar">
      <nav className="toolbarNavigation">
        <div className="toolbarToggleButton">
          <DrawerToggleButton click={drawerClickHandler} />
        </div>
        <div className="toolbarLogo">
          <a href="/">
            <img src={udanLogo} alt="udanLogo" height="100%" width="100%" />
          </a>
        </div>
        <div className="spacer" />
        <div className="toolbarNavigationItems">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            {localStorage.getItem('Subject_Training')!=="null"?
            <li>
              <a href="/SubjectTraining">Subject Training</a>
            </li>:null}
            {localStorage.getItem('Induction_Training')!=="null" && isOrchids?
            <li>
              <a href="/inductionTraining">Induction Training</a>
            </li>:null}
            {isOrchids &&  localStorage.getItem('Self_Driven_Training')!=="null" && localStorage.getItem('Trainer_Driven')!=="null"?
            <li>
              <a href="/inHouseCourses">Courses</a>
            </li>:null}
            {(localStorage.getItem('Weekly_Report')!=="null" || localStorage.getItem('Branch_Wise_Report')!=="null" ||
            localStorage.getItem('Consolidated_Report')!=="null" ||localStorage.getItem('Course_Wise_Report')!=="null" ||
            localStorage.getItem('Reports')!=="null")
            && !(isOrchids)?
            <li>
              {urlFun()}
            </li>:null}
            {/* <li>
              <a href="/inductionTraining">Induction Training</a>
            </li> */}
            {localStorage.getItem('Treasure_Box')!=="null"?
            <li>
              <a href="/inHouseTreasureBox">Treasure Box</a>
            </li>:null}
            {localStorage.getItem('Blogs')!=="null"?
            <li>
              <Link to="/activity">Blog</Link>
            </li>:null}
            {/* <li style={{ borderRight: '1px solid transparent' }}>
              <a href="/contactUs">Contact</a>
            </li> */}
            {localStorage.getItem('Learnig_Videos')!=="null" 
            || localStorage.getItem('Learning_Notes')!=="null"
            || localStorage.getItem('Notification')!=="null"
            || localStorage.getItem('Webinar')!=="null" ?
            <li>
              <button
                type="submit"
                tabIndex={0}
                onClick={handleNotesClick}
                className="logoutLink"  
              >
                Learning
              </button>
              <Menu
                id="simple-menu"
                anchorEl={notesList}
                keepMounted
                style={{ zIndex: 1650, marginTop: '50px' }}
                open={Boolean(notesList)}
                onClose={handleNotesClose}
              >
                {localStorage.getItem('Learnig_Videos')!=="null"?
                <MenuItem style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <MenuBookIcon />
                    </Badge>
                  </IconButton>
                  <a href="/inhouse_Learning_videos" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="secondary">
                      Learning
                    </Button>
                  </a>
                </MenuItem>:null}
                {localStorage.getItem('Learning_Notes')!=="null"?
                <MenuItem style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <NotesIcon />
                    </Badge>
                  </IconButton>
                  <a href="/learning_note_view" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="secondary">
                      My Notes
                    </Button>
                  </a>
                </MenuItem>:null}
                {localStorage.getItem('Notification')!=="null"?
                <MenuItem style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <NotificationsNoneIcon />
                    </Badge>
                  </IconButton>
                  <a href="/view_notification" style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      // onClick={handleLogoutClose}
                    >
                      Notifications
                    </Button>
                  </a>
                </MenuItem>:null}
                {(localStorage.getItem('Webinar')!=="null" || localStorage.getItem('Webinar')!=="null")?
                <MenuItem style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <EventIcon />
                    </Badge>
                  </IconButton>
                  <a href="/inhouse_calendar" style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      // onClick={handleLogoutClose}
                    >
                      Calendar
                    </Button>
                  </a>
                </MenuItem>:null}
              </Menu>
            </li>:null}
            <li>
              <button
                type="submit"
                tabIndex={0}
                onClick={handleProfileClick}
                className="logoutLink"
              >
                Profile
              </button>
              <Menu
                id="simple-menu"
                anchorEl={profileanchorEl}
                keepMounted
                style={{ zIndex: 1650, marginTop: '50px' }}
                open={Boolean(profileanchorEl)}
                onClose={handleProfileClose}
              >
                <MenuItem style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <AccountCircle />
                    </Badge>
                  </IconButton>
                  <a href="/user_profile" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="secondary">
                      View Profile
                    </Button>
                  </a>
                </MenuItem>
                <MenuItem style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <VpnKey />
                    </Badge>
                  </IconButton>
                  
                  <a href="/change_password" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="secondary">
                      Change Password
                    </Button>
                  </a>
                </MenuItem>
                <MenuItem onClick={handleLogoutClose} style={{ zIndex: 1650 }}>
                  <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <ExitToAppIcon />
                    </Badge>
                  </IconButton>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleLogoutClose}
                  >
                    Logout
                  </Button>
                </MenuItem>
              </Menu>
            </li>
            {/* <li>
              <button
                type="submit"
                tabIndex={0}
                onClick={handleLogoutClose}
                className='logoutLink'
              >
                Logout
              </button>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
}
InHouseNavbar.defaultProps = {
  drawerClickHandler: () => {},
};

InHouseNavbar.propTypes = {
  drawerClickHandler: PropTypes.func,
};

export default InHouseNavbar;
