/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withStyles, Button } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { useAlert } from "../../../hoc/alert/alert";
import styles from "./TrainerDrivenForm.style";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import axios from "axios";

function TrainerDrivenForm({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [courses, setCourses] = useState([]);
  const [phoneEmail, setPhoneEmail] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [correctOtp, setCorrectOtp] = useState(false);
  const [loading, setLoader] = useState(false);
  const [permission, setPermission] = useState([]);

  const [courseNameSelected, setCourseNameSelected] = useState([]);
  const [otpVerifyResponce, setOTPVerifyResponce] = useState("");
  const [classTitle, setClassTitle] = useState("");

  const alert = useAlert();
  async function getData(url) {
    setLoader(true);
    const dataResult = fetch(url, {
      method: "GET",
      cache: "reload",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Class_Initiation_Form"),
      },
    });
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    return finalData;
  }

  useEffect(() => {
    getData(
      `${urls.principalCompletedViewCourse}?course_type=${localStorage.getItem(
        "principalCourseType"
      )}`
    ).then((data) => {
      setCourses(data.results);
    });
    console.log("class initatiation getting called");
  }, []);

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem("Class_Initiation_Form"));
  }, []);

  const handleChange = (event) => {
    setCourseNameSelected(event.target.value);
  };

  const handleClassTitleChange = (event) => {
    setClassTitle(event.target.value);
  };

  const sendOtpFunc = async () => {
    const regexMobile = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    setLoader(true);
    const dataResult = fetch(
      `${urls.otpSending}${
        phoneEmail.match(regexMobile)
          ? `?mobile_number=${phoneEmail}`
          : phoneEmail.match(regexEmail)
          ? `?email=${phoneEmail}`
          : ""
      }`,
      {
        method: "GET",
        cache: "reload",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Class_Initiation_Form"),
        },
      }
    );
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    if (
      (response?.status === 200 && finalData?.status_code !== 304) ||
      finalData?.status_code !== 400
    ) {
      alert.success(finalData.response);
    }
    if (finalData?.status_code === 304 || finalData?.status_code === 400) {
      alert.warning(finalData.message);
    }
    if (response?.status === 304 || response?.status === 400) {
      alert.warning(finalData.response);
    }

    console.log(finalData, response, dataResult, "finalData");
    return finalData;
  };

  const verifyOtpFunc = async () => {
    setLoader(true);
    const dataResult = fetch(
      `${urls.otpVerification}?mobile_number=${phoneEmail}&otp=${verifyOtp}`,
      {
        method: "GET",
        cache: "reload",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Class_Initiation_Form"),
        },
      }
    );
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    const data = finalData;
    setOTPVerifyResponce(data);
    return finalData;
  };

  useEffect(() => {
    if (otpVerifyResponce && otpVerifyResponce.response === "incorrect otp") {
      alert.warning("enter Correct otp");
    } else if (otpVerifyResponce && otpVerifyResponce.response === "success") {
      setCorrectOtp(true);
    }
  }, [otpVerifyResponce]);

  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  const submitClassInitiationFunc = async () => {
    const data = {
      course: courseNameSelected,
      class_title: classTitle,
    };
    setLoader(true);
    const response = await fetch(urls.classInitiationFormSubmit, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Class_Initiation_Form"),
      },
    });
    const getDatainfo = await response.json();
    setLoader(false);
    setClassTitle("");
    setPhoneEmail("");
    setVerifyOtp("");
    setCourseNameSelected([]);

    // window.location = "/initiateForm";
    return getDatainfo;
  };
  return (
    <div className={classes.tdMainDiv}>
      <>
        <h2>Class Initiation Form</h2>
        <TextField
          id="standard-full-width"
          label="Class Title"
          className={classes.textField}
          placeholder="Enter the Class Title"
          fullWidth
          value={classTitle}
          onChange={handleClassTitleChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div style={{ marginTop: "1rem" }}>
          <InputLabel shrink id="demo-mutiple-name-label">
            Select Course
          </InputLabel>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            value={courseNameSelected}
            onChange={handleChange}
            input={<Input />}
            className={classes.selectEmpty}
            style={{ width: 500, paddingBottom: "3px" }}
          >
            {courses &&
              courses.map((item) => (
                <MenuItem key={item.course.id} value={item.course.id}>
                  {item.course.course_name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gridGap: "1rem",
            width: 500,
          }}
        >
          <TextField
            id="standard-full-width"
            label="Email"
            placeholder="Enter the Email"
            fullWidth
            onChange={(e) => setPhoneEmail(e.target.value)}
            value={phoneEmail}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ height: 40, marginTop: 25 }}
            disabled={!phoneEmail}
            onClick={sendOtpFunc}
          >
            Send OTP
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gridGap: "1rem",
            width: 500,
          }}
        >
          <TextField
            id="standard-full-width"
            label="Enter OTP"
            value={verifyOtp}
            onChange={(e) => setVerifyOtp(e.target.value)}
            placeholder="Enter OTP"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ height: 40, marginTop: 25 }}
            disabled={!(phoneEmail && verifyOtp)}
            onClick={verifyOtpFunc}
          >
            Verify OTP
          </Button>
        </div>
        {permission.can_add ? (
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ width: 500, height: 40, marginTop: 25 }}
            disabled={correctOtp !== true}
            onClick={submitClassInitiationFunc}
          >
            Submit Class Initiation Form
          </Button>
        ) : null}
      </>

      {loader}
    </div>
  );
}
TrainerDrivenForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TrainerDrivenForm);
