/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Block as BlockIcon,
  Done as DoneIcon,
} from '@material-ui/icons';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  // Modal,
  // Fade,
  // Backdrop,
  IconButton,
  TextField,
  TableFooter,
  TablePagination,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import FormControl from '@material-ui/core/FormControl';
import { green, red } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import urls from '../../../url';
import Loader from '../../../hoc/loader';
import { useAlert } from '../../../hoc/alert/alert';
// import useFetch from '../../../hoc/useFetch';
import styles from './updateUserStatus.style';
import axios from 'axios';


const RedCheckBox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const UpdateUserStatus = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [checkLoader, setCheckLoader] = useState(false);
  const [erp, setErp] = useState('');
  const alert = useAlert();
  const [userInfo, setUserInfo] = useState();
  const [removeEmailData, setRemoveEmailData] = useState(false);
  const [checked, setCheck] = useState(false);
  const [permission, setPermission] = useState([]);

  let loader = null;
  if (checkLoader) {
    loader = <Loader open />;
  }

  const apiCall = (message, status, updatedStatus) => {
    if (updatedStatus) {
      setCheck(false);
    } else {
      setCheck(false);
    }
    const loadingUser = async () => {
      setCheckLoader(true);
      const data = await fetch(`${urls.userStatusChangeAPI}?erp=${erp}&status=${status}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Change_User_Status')
        },
      })
        .then((res) => {
          if (res.status === 404) {
            setCheckLoader(false);
            alert.error(`Invalid erp : ${erp}`);
            return null;
          }
          alert.success(message);
          return res.json();
        })
        .catch((err) => {
          alert.warning(err);
        });
      setUserInfo(data);
      // SetactiveRadio(data.is_active)
      setCheckLoader(false);
    };
    loadingUser();
  };
  const removeUpdateAPI = async (status, deleteEmailPhone) => {
    setRemoveEmailData(true);
    await fetch(`${urls.removeUpdateAPI}?erp=${erp}&status=${status}&delete_email_phone=${deleteEmailPhone}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Change_User_Status')

      },
    })
      .then((res) => {
        if (res.status === 404) {
          setCheckLoader(false);
          alert.error(`Invalid erp :${erp}`);
          return null;
        }
        alert.success('Email Id & Phone number is been Removed');
        return res.json();
      })
      .catch((err) => {
        alert.warning(err);
      });
    setRemoveEmailData(false);
  };

  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id

      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Change_User_Status'))
  }, [])

  const deactivate = (userStatus) => {
    if (checked) {
      if (userStatus) {
        removeUpdateAPI(1, 1);
        apiCall(`User ${erp} has been Deactivated`, 1, userStatus);
      }
    } else {
      apiCall(`User ${erp} has been Deactivated`, 1, userStatus);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          {permission.can_view ? 
          <>
          <Typography variant="h4">Update User Status</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.root}>
              <Grid item md={4} xs={12}>
                <TextField
                  label="ERP No"
                  margin="dense"
                  fullWidth
                  required
                  value={erp}
                  onChange={(e) => e.target.value.length < 12 && setErp(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Button
                  style={{ marginTop: '10px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => apiCall('User info retrieved', 3)}
                >
                  Go
                </Button>
              </Grid>
            </Grid>
          </Paper>
          </>
          :null}
        </div>
      </div>
      <>
        {userInfo && Object.keys(userInfo).length !== 0
          && (
            <Paper className={classes.paper}>
              <Table
                className={classes.table}
                aria-label="spanning table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      Name
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      ERP
                      {' '}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      Phone
                      {' '}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      Branch
                      {' '}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      Remove Email & Phone Number
                      {' '}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      Actions
                      {' '}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {userInfo.name}
                    </TableCell>
                    <TableCell align="center">
                      {userInfo.erp}
                    </TableCell>
                    <TableCell align="center">
                      {userInfo.phone_number}
                    </TableCell>
                    <TableCell align="center">
                      {userInfo.branch && userInfo !== undefined && userInfo.length !== 0
                        ? userInfo.branch.reduce(
                          (accumulator, eachBranch) => `${accumulator}, ${eachBranch.branch_name}`, '',
                        ) : null}
                    </TableCell>
                    <TableCell align="center">

                      <FormControlLabel
                        control={(
                          <RedCheckBox
                            checked={checked}
                            onChange={(e) => {
                              if (userInfo.is_active) {
                                setCheck(e.target.checked);
                              }
                            }}
                            name="checkedG"
                            inputProps={{ 'arial-label': 'primaery checkbox' }}
                            disable={userInfo.is_active}
                          />
)}
                      />

                    </TableCell>
                    <TableCell align="center">
                      {permission.can_update ? 
                      <Grid container spacing={0} className={classes.root}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ height: '90%', width: '90%' }}
                            disabled={userInfo.is_active}
                            onClick={() => apiCall(`User ${erp} has been Activated`, 2, userInfo.is_active)}
                          >
                            {/* <DoneIcon /> */}
                            <Typography variant="span">Activate</Typography>
                          </Button>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ height: '90%', width: '90%' }}
                            disabled={!userInfo.is_active}
                            onClick={() => deactivate(userInfo.is_active)}
                          >
                            {/* <BlockIcon /> */}
                            <Typography variant="span"> De-Activate</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                      :null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          )}
      </>
      <div />
      {loader}
    </>
  );
};

UpdateUserStatus.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(UpdateUserStatus);
