export default (theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: 375,
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
});
