/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  // Modal,
  // Fade,
  // Backdrop,
  IconButton,
  TextField,
  TableFooter,
  TablePagination,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './report.style';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import urls from '../../../url';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};


let link1
let link2
let link3
let link4
let finalUrl

const Report = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // const [level, setLevel] = useState('');
  const [checkLoader] = useState(false);
  // const [levelUpdate, setLevelUpdate] = useState('');
  // const [levelId, setlevelId] = useState('');
  // const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [roleId, setRoleId] = useState('');
  const [courseId, setCourseId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState('');
  const [bottomHRef, setBottomHRef] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [role, setRole] = useState([]);
  const alert = useAlert();
  const {
    data: levelList,
    isLoading: levelListLoading,
    doFetch: fetchlevel,
  } = useFetch([]);
  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);

  const {
    data: courseList,
    isLoading: courseListLoading,
    doFetch: fetchcourseList,
  } = useFetch(null);
  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);
  // console.log(levelList.total_pages);
  // const {
  //   data: UpdateLevelResponse,
  //   isLoading: updateLevelLoading,
  //   doFetch: fetchupdateLevel,
  // } = useFetch([]);
  //   const verifyEmail = auth 
  // && auth.academic_profile 
  // && auth.academic_profile.user.email;

  // const verify=verifyEmail.includes("@orchids.edu.in")
  const loginData = JSON.parse(localStorage.getItem('UserLogin'))
  var verify = loginData.role_permission.is_orchids;

  const [trainingTypeList] = useState([
    {
      id: '1',
      name: 'Induction Training',
    },
    {
      id: '2',
      name: 'Self Driven',
    },
    {
      id: '3',
      name: 'Trainer Driven',
    },
    {
      id:'4',
      name:'Subject Training'
    }
  ]);

  const [trainingType, setTrainingType] = useState('');
  let isRoleChosen = false;
  let isTrainingTypeChosen = false;
  

  useEffect(() => {
    // if (page || rowsPerPage) {
      const roleArr=[];
      for (let i = 0; i < role.length; i += 1) {
        roleArr.push(
          roleList
          && roleList.response.filter((item) => item.role_name === role[i])[0].role_id,
        );
      }
    if (branch && trainingType && role && courseId) {
      if (startDate && endDate) {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page + 1}&page_size=${rowsPerPage || 10}&role_id=${[roleArr]}&course_type=${trainingType}&course_id=${courseId}&start_date=${startDate}&end_date=${endDate}&branch=${branch}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Course_Wise_Report')
          },
        });
      } else if (startDate) {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page + 1}&page_size=${rowsPerPage || 10}&role_id=${[roleArr]}&course_type=${trainingType}&course_id=${courseId}&start_date=${startDate}&branch=${branch}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Course_Wise_Report')
          },
        });
      } else if (endDate) {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page + 1}&page_size=${rowsPerPage || 10}&role_id=${[roleArr]}&course_type=${trainingType}&course_id=${courseId}&end_date=${endDate}&branch=${branch}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Course_Wise_Report')
          },
        });
      } else {
        fetchlevel({
          url: `${urls.userReportApi}?page=${page + 1}&page_size=${rowsPerPage || 10}&role_id=${[roleArr]}&course_type=${trainingType}&course_id=${courseId}&branch=${branch}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem('Course_Wise_Report')
          },
        });
      }
    }
  }, [searchClicked, page, fetchlevel, rowsPerPage, auth.personal_info.token]);

  useEffect(() => {
    const roleArr=[];
      for (let i = 0; i < role.length; i += 1) {
        roleArr.push(
          roleList
          && roleList.response.filter((item) => item.role_name === role[i])[0].role_id,
        );
      }
    if (role && trainingType && courseId) {
      if (startDate && endDate) {
        setBottomHRef([
          {
            csv: `${urls.courseWiseReportExcelApi}?course_id=${courseId}&role_id=${[roleArr]}&course_type=${trainingType}&start_date=${startDate}&end_date=${endDate}&branch=${branch}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Course_Wise_Report')}&export_type=csv`,
          },
        ]);
      } else if (startDate) {
        setBottomHRef([
          {
            csv: `${urls.courseWiseReportExcelApi}?course_id=${courseId}&role_id=${[roleArr]}&course_type=${trainingType}&start_date=${startDate}&branch=${branch}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Course_Wise_Report')}&export_type=csv`,
          },
        ]);
      } else if (endDate) {
        setBottomHRef([
          {
            csv: `${urls.courseWiseReportExcelApi}?course_id=${courseId}&role_id=${[roleArr]}&course_type=${trainingType}&end_date=${endDate}&branch=${branch}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Course_Wise_Report')}&export_type=csv`,
          },
        ]);
      } else {
        setBottomHRef([
          {
            csv: `${urls.courseWiseReportExcelApi}?course_id=${courseId}&role_id=${[roleArr]}&course_type=${trainingType}&branch=${branch}&Authorization=${`Bearer ${auth.personal_info.token}`}&module=${localStorage.getItem('Course_Wise_Report')}&export_type=csv`,
          },
        ]);
      }
    }
  }, [setBottomHRef, role, trainingType, courseId, startDate, endDate]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }
  useEffect(() => {
    // fetchlevel({
    //   url: `${urls.userReportApi}?course_id=${courseId}`,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${auth.personal_info.token}`,
    //   },
    //   fetchlevel,
    // });


//kept for futute if we need

    // link1=`${urls.getRoleRegistration}?search=orchids&user_type=USER`
    // link2=`${urls.getRoleRegistration}?search=other&user_type=USER`
    // link3=`${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`
    // link4=`${urls.getRoleRegistration}?search=other&user_type=ADMIN`

    finalUrl = localStorage.getItem("userType")==="USER" ? verify ? link1 : link2 :
    localStorage.getItem("userType")==="ADMIN" ? verify ? link3 :link4 :null

    fetchRoles({
      url: `${urls.getRoleRegistration}?user_type=USER`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Wise_Report')
      },
    });
    fetchBranches({
      url: urls.consolidatedReportBranchApi,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Wise_Report')
      },
    });
  }, [fetchRoles, fetchlevel, fetchBranches, auth.personal_info.token]);

  let loader = null;
  if (levelListLoading || checkLoader || roleListLoading || courseListLoading) {
    loader = <Loader open />;
  }

  const getReports = () => {
   
    setSearchClicked(!searchClicked);
    setPage(0);
    if (!branch) {
      alert.warning('Select a branch');
      return;
    }
    if (!role) {
      alert.warning('Select a role');
      return;
    }
    if (!trainingType) {
      alert.warning('Select a training type');
      return;
    }
    if (!courseId) {
      alert.warning('Select a course');
    }
  };

  const roleChosen = () => {
    isRoleChosen = true;
    setCourseId('');
    setTrainingType('');
  };

  const trainingTypeChosen = (e) => {
    setTrainingType(e.target.value);
    isTrainingTypeChosen = true;

    const roleArr=[];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList
        && roleList.response.filter((item) => item.role_name === role[i])[0].role_id,
      );
    }

    fetchcourseList({
      url: `${urls.courseListApi}?role_id=${[roleArr]}&course_type=${e.target.value}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Course_Wise_Report')
      },
    });

    setCourseId('');
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Course Wise Users Report</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <Typography>
                  Select Branch
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={branch || ''}
                  onChange={(e) => setBranch(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {branchList && branchList.length !== 0 && branchList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {data.branch_name ? data.branch_name : ''}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={3} xs={12}>
                
              <Typography>
                  Select Role
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  multiple
                  value={role || []}
                  isRequired
                  onChange={(e) => setRole(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {roleList && roleList.length !== 0 && roleList.response.map((data) => (
                    <MenuItem
                      value={data.role_name}
                      key={data.role_id}
                      name={data.role_name}
                    >
                      <Checkbox checked={role.indexOf(data.role_name) > -1} />
                      <ListItemText primary={data.role_name} />
                      {/* {data.id ? data.name : ''} */}
                    </MenuItem>
                  ))}

                </Select>


                {/* <Typography>
                  Select Role
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  multiple
                  value={roleId || []}
                  onChange={(e) => { setRoleId(e.target.value); roleChosen(); }}
                  className={classes.textField}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {roleList
                  && roleList.length !== 0
                  && roleList.response.map((data) => (
                    <MenuItem
                      value={data.role_id}
                      key={data.role_id}
                      name={data.role_name}
                    >
                        <Checkbox checked={data.indexOf(data.role_name) > -1} />
                        <ListItemText primary={data.role_name} />
                    </MenuItem>
                  ))}
                </Select> */}
              </Grid>

              <Grid item md={3} xs={12}>
                <Typography>
                  Select Training Type
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={trainingType || ''}
                  onChange={(e) => trainingTypeChosen(e)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {trainingTypeList
                  && trainingTypeList.length !== 0
                  && trainingTypeList.map((data, i) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.name}
                    >
                      {data.id ? data.name : ''}
                    </MenuItem>
                  ))}

                </Select>
              </Grid>

              <Grid item md={3} xs={12}>
                <Typography>
                  Select Course
                  <b style={{ color: 'red' }}>*</b>
                </Typography>
                <Select
                  margin="dense"
                  fullWidth
                  value={courseId || ''}
                  onChange={(e) => setCourseId(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  style={{ color: 'black' }}
                >
                  {courseList
                && courseList.length !== 0
                && courseList.map((data) => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.course_name}
                  >
                    {data.course_name ? data.course_name : ''}
                  </MenuItem>
                ))}

                </Select>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography>Start Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleStartDateChange(e.target.value)}
                  type="date"
                  value={startDate || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography>End Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleEndDateChange(e.target.value)}
                  type="date"
                  value={endDate || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={1} xs={12}>
                <Button
                  style={{ marginTop: '33px' }}
                  className={classes.updateButton}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => getReports()}
                >
                  Search
                </Button>
              </Grid>

              <Grid item md={1} xs={12}>
                <Button
                  style={{ marginTop: '33px' }}
                  className={classes.updateButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) =>{
                      e.preventDefault();
                      window.location.href= (bottomHRef && bottomHRef[0].csv);
                      }}
                  disabled={
                  !((role.length !== 0 && role !== undefined
                  && trainingType !== '' && trainingType !== undefined
                  && courseId !== '' && courseId !== undefined)
                  && branch !== '' && branch !== undefined)
                }
                >
                  CSV
                </Button>
              </Grid>

            </Grid>
          </Paper>
        </div>
      </div>
      <>
        {(levelList && levelList.length !== 0) && (
          <Paper className={classes.paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="spanning table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">
                    S.No
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">
                    Name
                    {' '}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">
                    ERP
                    {' '}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">
                    Role
                    {' '}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">
                    Branch
                    {' '}
                  </TableCell>
                  {
                  levelList.courses[0].chapter_title
                  && levelList.courses[0].chapter_title.length > 0
                  && levelList.courses[0].chapter_title.map(
                    (chapterTitle, i) => (
                      <TableCell
                        colSpan={
                        levelList && levelList.courses[0].result[0] && levelList.courses[0].result[0].chapters && i < (levelList.courses[0].result[0].chapters.length)
                          ? ((Object.keys(Object.values(levelList.courses[0].result[0].chapters[i])[0]).length) / 3) * 2
                          : 1
                      }
                        key={i}
                        style={{ fontWeight: 'bold' }}
                        align="center"
                      >
                        {chapterTitle !== '' ? chapterTitle : 'No Chapter Name' }
                      </TableCell>
                    ),
                  )
                }
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={1} />
                  <TableCell align="left" colSpan={4} />
                  {
                        levelList && levelList.courses[0].result && levelList.courses[0].result[0]
                          && levelList.courses[0].result[0].chapters.map(
                            (chapter) => (Object.entries(chapter).map(
                              ([key, value], i) => (
                                <>
                                  {Object.entries(value).map(
                                    ([keyy, valuee]) => {
                                      if (keyy.toString().startsWith('title')) {
                                        return (
                                          <>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                              {
                                                valuee.toString() !== ''
                                                  ? `${valuee}`
                                                  : `Quiz ${parseInt(keyy.split('title')[1], 10) + 1}`
                                              }
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                              Attempts
                                            </TableCell>
                                          </>
                                        );
                                      }
                                      return null;
                                    },
                                  )}
                                </>
                              ),
                            )),
                          )
                      }
                </TableRow>
              </TableHead>
              <>
                <TableBody>
                  {
                        (levelList && levelList.courses[0].result && levelList.courses[0].result.length === 0)
                          ? <Typography variant="h5" style={{ textAlign: 'center', color: 'blue' }}>Records Not Found</Typography>
                          : (levelList && levelList.courses[0].result && levelList.courses[0].result.map(
                            (data, i) => (
                              <TableRow key={data.user_id}>
                                <TableCell align="left">{i + 1}</TableCell>
                                <TableCell align="left">{data.name}</TableCell>
                                <TableCell align="left">{data.erp}</TableCell>
                                <TableCell align="left">{data.role_id[0].roles_category__name}</TableCell>
                                <TableCell align="left">
                                  {
                                    data.branch && data.branch.length !== 0 ? data.branch.reduce(
                                      (accumulator, eachBranch) => `${accumulator}, ${eachBranch}`,
                                    ) : null
                                  }
                                </TableCell>
                                {data.chapters && data.chapters.length > 0 && data.chapters.map(
                                  (chapter) => (Object.entries(chapter).map(
                                    ([key, value]) => (
                                      <>
                                        {Object.entries(value).map(
                                          ([keyy, valuee]) => {
                                            if (!(keyy.toString().startsWith('title'))) {
                                              return (
                                                <TableCell align="center">
                                                  {valuee}
                                                </TableCell>
                                              );
                                            }
                                            return null;
                                          },

                                        )}
                                      </>
                                    ),
                                  )),
                                )}
                              </TableRow>
                            ),
                          ))
                      }
                </TableBody>
              </>
              {/* {reportTableData()} */}
            </Table>
            <Table>
              {levelList.courses[0].result.length !== 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelDisplayedRows={() => `Page ${page + 1} of ${+levelList.total_pages}`}
                    rowsPerPageOptions={[10, 20, 30]}
                    count={+levelList.count}
                    colSpan={3}
                    rowsPerPage={rowsPerPage || 10}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(levelList.total_pages - 1)}
                      disabled={page === +levelList.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
              )}
            </Table>
          </Paper>
        )}
      </>
      <div />
      {loader}
    </>
  );
};

Report.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Report);

