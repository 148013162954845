import para from '../Media/UdaanHome/para.jpg';
import butterfly from '../Media/UdaanHome/butterfly.jpg';

export default () => ({
  aboutDiv1: {
    width: '100%',
    height: '100vh',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '12vh',
    // backgroundColor: '#f8c853',
    flexDirection: 'column',
    // zIndex: "-999"
    // position: "relative"
  },

  aboutDiv1p: {
    width: '60%',
    textAlign: 'center',
    lineHeight: '1.4rem',
    fontSize: '17px',
    fontWeight: 'bold',
    margin: '0 auto',
    padding: '2rem  0 2rem 0',
  },
  aboutDiv2: {
    width: '100%',
    height: '80vh',
    color: 'black',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  aboutDiv2Box1: {
    width: '100%',
    height: '100%',
    backgroundColor: 'teal',
    backgroundImage: `url(${para})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  aboutDiv2Box2: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  aboutDiv2p: {
    width: '65%',
    textAlign: 'center',
    lineHeight: '1.4rem',
    fontSize: '17px',
    fontWeight: '600',
    letterSpacing: '0.1rem',
  },

  aboutDiv3: {
    width: '100%',
    height: '80vh',
    color: 'black',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  aboutDiv3Box1: {
    width: '100%',
    height: '100%',
    backgroundColor: 'teal',
    backgroundImage: `url(${butterfly})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  aboutDiv3Box2: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  aboutDiv3p: {
    width: '65%',
    textAlign: 'center',
    lineHeight: '1.4rem',
    fontSize: '17px',
    fontWeight: '600',
    letterSpacing: '0.1rem',
  },
  // aboutDiv3: {
  //   width: "100%",
  //   height: "70vh",
  //   display: "grid",
  //   gridTemplateColumns: "1fr 2fr 1fr"
  // },
  // box1Div3: {
  //   backgroundImage: `url(${StarLeft})`,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center"
  // },
  // box3Div3: {
  //   backgroundImage: `url(${StarRight})`,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center"
  // },
  // box2Div3: {
  //   backgroundColor: "#4a66da",
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   color: "white"
  // },
  // div3Text: {
  //   fontSize: "2.2rem",
  //   fontWeight: "bold"
  // },
  // div3Para: {
  //   width: "70%",
  //   marginTop: "4rem",
  //   textAlign: "center"
  // }
});
