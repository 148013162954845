/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import LockOpenIcon from "@material-ui/icons/LockOpen";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useAlert } from "../hoc/alert/alert";
import Loader from "../hoc/loader";
import axios from "axios";
import urls from "../url";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      margin: " 0 auto",
      width: "100%",
      textAlign: "center",
    },
    "& .MuiIconButton-root": {
      color: "white",
    },
  },

  normal: {
    "& label.Mui-focused": {
      color: "white",
    },
    "&  .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& .MuiInput-input": {
      color: "white",
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiFormHelperText-root": {
      color: "white",
    },
  },

  errorClass: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "50px auto",
    border: "1px solid blue",
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 500,
  },
  close: {
    padding: theme.spacing(0.5),
  },
  warning: {
    backgroundColor: "amber",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  formHeadAlign: {
    textAlign: "center",
    fontWeight: "bold",
  },
}));

const errorStyles = makeStyles({
  error: {
    "& label.Mui-focused": {
      color: "red",
    },
    "& .MuiInputLabel-root": {
      color: "red",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "red",
    },
  },
});

function UserLogin() {
  const classes = useStyles();
  const errorClass = errorStyles();
  const alert = useAlert();

  const emailRef = useRef();
  const passRef = useRef();

  // const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernamecolor] = useState(true);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordcolor] = useState(true);
  const [loading, setLoading] = useState(false);

  // const PORT_NUM = 'http://localhost:8000';
  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }
  const getAllModules = (initialValues) => {
    Axios.get(urls.getAllRoles).then((response) => {
      response.data.response.map((eachModule) => {
        localStorage.setItem(eachModule.name, initialValues);
      });
    });
  };
  useEffect(() => {
    getAllModules(null);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const loginData = (data) => {
    localStorage.setItem("UserLogin", JSON.stringify(data));
    localStorage.setItem("roleType", JSON.stringify(data.personal_info.role));
    // const roleType = data.personal_info.role;
    localStorage.setItem("isLoggedIn", true);

    // console.log(roleType);
    // window.location.reload();
    // if (
    //   roleType === "ContentWriter" ||
    //   roleType === "SuperAdmin" ||
    //   roleType === "Admin"
    // ) {
    //   window.location.href = "/";
    //   // console.log(roleType);
    // } else {
    //   window.location.href = "/";
    // }
    window.location.href = "/";
  };

  const loginHandler = (e) => {
    e.preventDefault();
    const username = emailRef.current.value;
    // eslint-disable-next-line no-shadow
    const password = passRef.current.value;
    const data = {
      username,
      password,
    };
    if (!username.length) {
      alert.warning("Enter Your UserName");
      return;
    }
    if (!password.length) {
      alert.warning("Enter Your Password");
      return;
    }
    setLoading(true);

    async function postLoginData() {
      const response = await fetch(urls.userLogin, {
        method: "POST", // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const fetchData = await response.json();
      return fetchData;
      // console.log(fetchData, "postLoginData");
    }
    postLoginData().then((dataInfo) => {
      e.preventDefault();
      console.log(dataInfo, "postLoginData");
      if (
        dataInfo.personal_info !== undefined &&
        dataInfo.personal_info !== null
      ) {
        // if(dataInfo.personal_info.is_superuser){
        //     getAllModules(true);
        //     localStorage.setItem("is_super_user",true)
        // }
        localStorage.setItem("userType", dataInfo.role_permission.user_type);
        localStorage.setItem("is_orchids", dataInfo.role_permission.is_orchids);
        dataInfo &&
          dataInfo.role_permission &&
          dataInfo.role_permission.modules.map((eachModule) => {
            localStorage.setItem(eachModule.module_name, eachModule.module);
          });
      }
      // if(dataInfo.role_permission )

      if (
        dataInfo !== "invalid_credentials" &&
        dataInfo !== "please_register" &&
        dataInfo !== "user_is_inactive"
      ) {
        alert.success("You are successfully logged in");
        if (dataInfo.personal_info.is_superuser) {
          getAllModules(true);
          localStorage.setItem("is_super_user", true);
        }
        setLoading(false);
        loginData(dataInfo);
        return;
      }
      if (dataInfo === "please_register") {
        setLoading(false);
        alert.error(
          "The erp and password that you've entered doesn't match our records, sign up for an account"
        );
      } else if (dataInfo === "user_is_inactive") {
        setLoading(false);
        alert.error("User is Inactive, cannot Login");
      } else {
        // "invalid_credentials"
        setLoading(false);
        alert.error("Incorrect password, please try again");
      }
      // console.log(dataInfo);
    });
  };
  // if(localStorage.getItem("roleType") !== null) {
  //   window.location.href = "/adminside";
  // }

  const fetchSearch = (e) => {
    if (e.keyCode === 13) {
      loginHandler(e);
    }
  };

  return (
    <div
      style={{ margin: "0 auto", textAlign: "center" }}
      className={classes.root}
    >
      <VpnKeyIcon style={{ color: "white" }} />
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
        }}
        className="formHeadAlign"
        component="h2"
      >
        LOGIN
      </Typography>
      <br />
      <hr style={{ borderBottom: "4px solid white" }} />
      <br />
      <TextField
        fullWidth
        id="userName"
        label="User Name"
        helperText="username erp code only"
        // variant='outlined'
        className={usernamecolor ? classes.normal : errorClass.error}
        inputRef={emailRef}
        onChange={(e) => setUserName(e.target.value)}
      />
      <br />
      <br />
      <TextField
        type={showPassword ? "text" : "password"}
        id="registerPassword"
        label="Enter Password"
        // variant='outlined'
        className={passwordcolor ? classes.normal : errorClass.error}
        onChange={(e) => setPassword(e.target.value)}
        inputRef={passRef}
        fullWidth
        onKeyDown={fetchSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                //   onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <br />
      <Button
        type="submit"
        id=""
        variant="contained"
        value="Submit"
        color="primary"
        onKeyDown={fetchSearch}
        fullWidth
        onClick={loginHandler}
        style={{
          background: "white",
          color: "black",
          fontWeight: "bold",
        }}
      >
        Login
      </Button>
      <a
        href="/forgotPassword"
        style={{ textAlign: "right", color: "white", padding: "15px" }}
      >
        Forget Password ??
      </a>
      {loader}
    </div>
  );
}

export default UserLogin;
