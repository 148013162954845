/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  withStyles,
  Button,
  Divider,
  TextField,
  Switch,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Radio,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './matrixMcq.Style';

const MatrixMCQ = ({
  classes, createMatrix, matrixData, negativeMarksStatus, actiualMarks,
}) => {
  const [matrixQuestion, setMatricQuestion] = useState('');
  const [marksFortheQuestion, setMarksForTheQuestion] = useState('');
  const [negativeMarks, setNegativeMarks] = useState('');
  const [negativeYesorNo, setNegativeYesorNo] = useState(false);
  const [QuestionType, setQuestionType] = useState(false);
  const [optionArray, setOptionArray] = useState([]);
  const [choiceArray, setchoiceArray] = useState([]);
  const [refresh, setRefresh] = useState(false);

  function addExtrachoice() {
    setchoiceArray((oldData) => [
      ...oldData,
      {
        choice: null,
        optionsArray: optionArray,
        correctAnswer: null,
      },
    ]);
  }

  useEffect(() => {
    if (refresh === true) {
      const updatedChoiceArray = choiceArray.map((choiceItem) => ({
        ...choiceItem,
        optionsArray: optionArray,
        correctAnswer: null,
      }));
      setchoiceArray(updatedChoiceArray);
    }
  }, [optionArray]);

  useEffect(() => {
    if (matrixData && matrixData && matrixData.id) {
      setMatricQuestion(matrixData && matrixData.choice_table_matrix_question_title
         && matrixData.choice_table_matrix_question_title);
      setchoiceArray(matrixData && matrixData.question && matrixData.question);
      setOptionArray(matrixData && matrixData.question && matrixData.question[0].optionsArray);
      setMarksForTheQuestion(matrixData && matrixData.actual_question_mark);
      setNegativeMarks(matrixData && matrixData.negative_marking_marks);
      setNegativeYesorNo(matrixData && matrixData.is_negative_marking);
      setQuestionType(matrixData && matrixData.is_graded_question);
    }
  }, [matrixData]);

  function handleChoice(e, i, key) {
    if (!setchoiceArray.length) {
      alert.warning('No data available');
      return;
    }

    setchoiceArray((data) => {
      const newData = [...data];
      switch (key) {
        case 'choice':
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    });
  }

  function DeleteChoiceunction(index) {
    setchoiceArray((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function addExtraOptions() {
    setRefresh(true);
    setOptionArray((oldData) => [
      ...oldData,
      {
        option: null,
      },
    ]);
  }

  function handleOption(e, i, key) {
    if (!optionArray.length) {
      alert.warning('No data available');
      return;
    }
    setRefresh(true);
    setOptionArray((data) => {
      const newData = [...data];
      switch (key) {
        case 'option':
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    });
  }
  function DeleteOptionFunction(index) {
    setRefresh(true);
    setOptionArray((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function handleCorrectAns(e, index, key) {
    setchoiceArray((data) => {
      const newData = [...data];
      switch (key) {
        case 'correctAnswer':
          newData[index][key] = e;
          return newData;
        default:
          return null;
      }
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <ExpansionPanel style={{ backgroundColor: 'snow' }}>
          <ExpansionPanelSummary
            style={{ backgroundColor: 'lightgray' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Question Editor
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Question"
                  margin="dense"
                  className={classes.textField}
                  required
                  multiline
                  rows={3}
                  value={matrixQuestion || ''}
                  onChange={(e) => setMatricQuestion(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={10} xs={12}>
                  <Typography variant="h5">Option</Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => addExtraOptions()}
                  >
                    Add Option
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box border={1} className={classes.box}>
                  {optionArray
                    && optionArray.length !== 0
                    && optionArray.map((item, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item md={11} xs={12}>
                          <TextField
                            label={`Option  ${index + 1}`}
                            margin="dense"
                            className={classes.textField}
                            required
                            value={item.option || ''}
                            onChange={(e) => handleOption(e.target.value, index, 'option')}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <IconButton onClick={() => DeleteOptionFunction(index)}>
                            {' '}
                            <DeleteIcon size="small" color="secondary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={10} xs={12}>
                  <Typography variant="h5">Stems</Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => addExtrachoice()}
                  >
                    Add Choice
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box border={1} className={classes.box}>
                  {choiceArray
                      && choiceArray.length !== 0
                      && choiceArray.map((item, index) => (
                        <Grid container spacing={2} key={index}>
                          <Grid item md={11} xs={12}>
                            <TextField
                              label={`Choice  ${index + 1}`}
                              margin="dense"
                              className={classes.textField}
                              required
                              value={item.choice || ''}
                              onChange={(e) => handleChoice(e.target.value, index, 'choice')}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={1} xs={12}>
                            <IconButton onClick={() => DeleteChoiceunction(index)}>
                              {' '}
                              <DeleteIcon size="small" color="secondary" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                </Box>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* ANSWER DROP */}

        <ExpansionPanel
          style={{ backgroundColor: 'snow', marginTop: '20px' }}
        >
          <ExpansionPanelSummary
            style={{ backgroundColor: 'lightgray' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Answer</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Box border={1} className={classes.box}>
                  <Table>
                    {choiceArray
                        && choiceArray.length !== 0
                        && choiceArray.map((item, index) => (
                          <TableBody key={index}>
                            <TableRow>
                              <TableCell>{item.choice}</TableCell>
                              {item
                              && item.optionsArray
                              && item.optionsArray.length !== 0
                              && item.optionsArray.map((itemData, OptionIndex) => (
                                <TableCell key={OptionIndex}>
                                  <Radio
                                    checked={
                                      !!(
                                        itemData.option
                                          === item.correctAnswer
                                        && item.correctAnswer
                                      )
                                    }
                                    onChange={(e) => handleCorrectAns(
                                      e.target.value,
                                      index,
                                      'correctAnswer',
                                    )}
                                    value={itemData.option}
                                    color="primary"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'B' }}
                                  />
                                  {itemData.option}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        ))}
                  </Table>
                </Box>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    {' '}
                    Graded Question
                    {' '}
                  </Typography>
                </Grid>
                <Grid item md={1} xs={6} style={{ marginTop: '15px' }}>
                  <Switch
                    checked={QuestionType}
                    onChange={(e) => setQuestionType(e.target.checked)}
                    color="default"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    {' '}
                    Practice Question
                  </Typography>
                </Grid>
              </Grid>
              {!actiualMarks && (
              <Grid container spacing={2}>
                <Grid item md={3} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    Marks for Question :
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12} className={classes.TypographyDate}>
                  <TextField
                    label="Marks"
                    margin="dense"
                    className={classes.textField}
                    required
                    type="number"
                    value={marksFortheQuestion || ''}
                    onChange={(e) => setMarksForTheQuestion(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              )}
              {!negativeMarksStatus
              && (
              <Grid container spacing={2}>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    Negative Marking :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={6} style={{ marginTop: '15px' }}>
                  <Switch
                    checked={negativeYesorNo}
                    onChange={(e) => setNegativeYesorNo(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                {negativeYesorNo && (
                <Grid item md={1} xs={6} className={classes.TypographyDate}>
                  <TextField
                    label="Marks"
                    margin="dense"
                    className={classes.textField}
                    required
                    type="number"
                    value={negativeMarks || ''}
                    onChange={(e) => setNegativeMarks(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                )}
              </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item md={10} xs={12} />
      <Grid item md={2} xs={12}>
        <Button fullWidth color="primary" variant="contained" onClick={() => createMatrix(matrixQuestion, marksFortheQuestion, negativeMarks, negativeYesorNo, QuestionType, choiceArray)}>Save</Button>
      </Grid>
    </Grid>
  );
};
MatrixMCQ.defaultProps = {
  matrixData: {},
  negativeMarksStatus: false,
  actiualMarks: false,
};
MatrixMCQ.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  createMatrix: PropTypes.func.isRequired,
  matrixData: PropTypes.instanceOf(Object),
  negativeMarksStatus: PropTypes.bool,
  actiualMarks: PropTypes.bool,
};
export default withStyles(styles)(MatrixMCQ);
