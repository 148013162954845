export default (theme) => ({
  mainBox: {
    margin: '0 auto',
    width: '100%',
    height: 'auto',
    padding: '0.7rem 1rem',
  },
  paper: {
    border: '1px solid black',
    boxShadow: '1px 5px 12px #0000005e',
    padding: theme.spacing(1, 2, 3),
  },
  paperr: {
    marginTop: '10px',
    border: '1px solid black',
    boxShadow: '1px 5px 12px #0000005e',
    padding: theme.spacing(1, 2, 3),
  },
  singleRow: {
    display: 'grid',
    margin: '1rem',
    gridTemplateColumns: 'repeat(3 , 1fr)',
    gridGap: '1.5rem',
    width: '90%',
    height: 'auto',
  },
  mtfBtns: {
    border: '1px solid transparent',
    padding: '0.4rem  0.7rem',
    backgroundColor: '#4054b5',
    color: '#fff',
    borderRadius: '5px',
    margin: '1rem',
    cursor: 'pointer',
  },
});
