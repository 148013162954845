// const HOSTNAME = '127.0.0.1:8000/qbox';
// const PROTO = 'http';
// const BASE = `${PROTO}://${HOSTNAME}`;
// const MEDIA_BASE = BASE;
const HOSTNAME = "dev.udaansurelearning.com/qbox";
const PROTO = "https";
// const BASE = `${PROTO}://${HOSTNAME}`;
const BASE = window.location.origin + "/qbox";
const MEDIA_BASE = BASE;
const BUCKET = "https://cdn.udaansurelearning.com";

const urls = {
  base: `${BASE}`,
  login: `${BASE}/authenticate/login/`,
  roleApi: `${BASE}/authenticate/RetrieveRole/`,
  updateDeleteRoleApi: `${BASE}/authenticate/roleview/`,
  addTreasureBoxCategoryApi: `${BASE}/courses/create_treasure_box_category/`,
  retriveTreasureCategoryApi: `${BASE}/courses/treasure_box_category_list/`,
  updateTreasureCategory: `${BASE}/courses/`,
  rolewiesPositionApi: `${BASE}/instructors/retrieve_role_positions/`,
  createRolePositionMappingApi: `${BASE}/instructors/create_position_mapping/`,
  updatePositionMapping: `${BASE}/instructors/`,
  userListApi: `${BASE}/authenticate/listletseduvateuser/`,
  roleUserIdApi: `${BASE}/authenticate/createlistrolemapping/`,
  getUserList: `${BASE}/authenticate/user_list/`,
  getUserById: `${BASE}/authenticate/user_details/`,
  updateUser: `${BASE}/authenticate/user_update/`,
  addAndGetCourseCategory: `${BASE}/courses/add_course_category/`,
  updateCategoryApi: `${BASE}/courses/`,
  branchApi: `${BASE}/authenticate/RetrieveBranch/`,
  formRegister: `${BASE}/authenticate/register/`,
  sendOtp: `${BASE}/authenticate/sendotp/`,
  verifyotp: `${BASE}/authenticate/verifyotp/`,

  addlevelApi: `${BASE}/courses/create_retrieve_course_level/`,
  updateLevel: `${BASE}/courses/param-id-1/create_retrieve_course_level/`,
  createLanguageApi: `${BASE}/courses/create_retrieve_course_language/`,
  GetLanguageDataApi: `${BASE}/courses/create_retrieve_course_language/`,
  UpdateLanguageApi: `${BASE}/courses/`,
  createSubTitleApi: `${BASE}/courses/create_retrieve_course_subtitle/`,
  getSubTitleDataApi: `${BASE}/courses/create_retrieve_course_subtitle/`,
  updateSubTitleApi: `${BASE}/courses/`,
  getCourseDetailsApi: `${BASE}/courses/CreateCourse/`,
  getStatusInformationApi: `${BASE}/courses/status_courses/`,
  editCourseInfoApi: `${BASE}/courses/AddCourseDetail/`,
  coursesMsg: `${BASE}/courses/AddCourseMessage/`,
  coursesLang: `${BASE}/courses/create_retrieve_course_language/`,
  coursesSub: `${BASE}/courses/create_retrieve_course_subtitle/`,
  coursesLevel: `${BASE}/courses/create_retrieve_course_level/`,
  landingPage: `${BASE}/courses/AddLandingPage/`,
  AddCreatorProfile: `${BASE}/courses/AddCreatorProfile/`,
  getTeachersProfessionListApi: `${BASE}/courses/CourseRelatedRole/`,
  publishCourse: `${BASE}/courses`,
  getAuthorizeCourses: `${BASE}/courses/retrieve_is_course_to_publish/`,
  getCategoryListApi: `${BASE}/courses/RoleRelatedCategory/`,
  getSubCategoryListApi: `${BASE}/courses/show_coursetype/`,
  getSecondSubCategoryListApi: `${BASE}/courses/show_coursesubtype/`,

  getCourseSettingsDataApi: `${BASE}/courses/AddCourseSetting/`,
  allCourses: `${BASE}/courses/course_category_wise_courses/`,
  setStartandEndDateApiInCourseSchedule: `${BASE}/courses/AddCourseSchedule/`,
  addSectionApi: `${BASE}/courses/AddCourseContent/`,
  createChapterApi: `${BASE}/courses/AddCourseChapter/`,
  createCourseContentApi: `${BASE}/courses/AddChapterRelatedVideo/`,
  courses: `${BASE}/courses/customer_all_enroll_courses/`,
  apiForCreateCourseSetttings: `${BASE}/courses/create_chapter_related_question_mapping/`,
  apiToGetCourseSettings: `${BASE}/assessment/chapter_related_question/`,
  apiForUpdateCourseSettings: `${BASE}/courses/`,
  apiForToCreateMcqQuestionAndAnswer: `${BASE}/assessment/each_video_wise_mcq_question_detail/`,
  apiForDeleteMcqQuestionAndAnswer: `${BASE}/assessment/`,
  addCourseCategoryTypeApi: `${BASE}/courses/course_type/`,
  apiForUpdateCourseType: `${BASE}/courses/`,
  addCourseCategorySubTypeApi: `${BASE}/courses/course_sub_type/`,
  deleteCourse: `${BASE}/courses/`,
  deleteSubject: `${BASE}/courses/course_sub_type/?pk=`,
  apiForUpdateCourseSubType: `${BASE}/courses/`,
  apiToCreateCategotyMapping: `${BASE}/courses/course_category_type_view_mapping/`,
  registrationRoles: `${BASE}/authenticate/non_orchids_role/`,
  nonOrchidsregistration: `${BASE}/authenticate/non_orchids_registration/`,
  getWantBecome: `${BASE}/authenticate/get_want_become/`,
  userLogin: `${BASE}/authenticate/login/`,
  retrieve_user_details: `${BASE}/authenticate/retrieve_user_details/`,
  apiForUpdateCourseMapping: `${BASE}/courses/`,
  apiToUpdateMcqQuestion: `${BASE}/assessment/`,
  apiToGetQuestionType: `${BASE}/assessment/question_type/`,
  roleListApi: `${BASE}/authenticate/list_roles/`,
  categorysApiList: `${BASE}/courses/list_courses_category/`,
  categoryTypeApiList: `${BASE}/courses/list_create_coursetype/`,
  categorySubTypeApiList: `${BASE}/courses/list_create_coursesubtype/`,
  apiToFinishMcqTest: `${BASE}/courses/applicant_quiz_details/`,
  deleteCreaterApi: `${BASE}/courses/`,
  inHouseModules: `${BASE}/courses/retrieve_in_house_user_courses_role_wise/`,
  // inHouseVolumes: `${BASE}/courses/retrieve_in_house_user_courses_role_wise/`,

  inHouesCouresModules: `${BASE}/instructors/self_driven_in_house_users/`,
  getPerformanceCardApi: `${BASE}/assessment/performance_card_main_get_view/`,
  getCourseScoreApi: `${BASE}/assessment/get_quizzes_each_video_wise/`,
  getBranchNames: `${BASE}/authenticate/RetrieveBranch/`,

  // for getting school api in mapping
  getSchoolList: `${BASE}/courses/list_schools/`,
  getCoursesList: `${BASE}/courses/list_courses/`,
  getListCourses: `${BASE}/courses/courses_list/`,
  getSchoolMapping: `${BASE}/courses/retrieve_school_course_mapping/`,
  postSchoolMapping: `${BASE}/courses/school_course_mapping/`,
  deleteSchoolMapping: `${BASE}/courses/`,

  getBranchNamesApi: `${BASE}/instructors/get_branches/`,
  getCoursesNames: `${BASE}/courses/list_create_coursesubtype/`,
  getCategoryNames: `${BASE}/courses/list_courses_category/`,
  getGradeNames: `${BASE}/courses/list_grade_subject_mapping/`,
  // getGradeNames: `${BASE}/courses/list_category_grade_subjects/`,

  toggleActive: `${BASE}/courses/active_inactive_course/`,
  branchVideosApi: `${BASE}/courses/create_list_upload_branch_video/`,
  branchWiseVideosApi: `${BASE}/courses/list_branch_wise_video/`,
  branchUpdateVideosApi: `${BASE}/courses`,
  matchTheFollowingApi: `${BASE}/assessment/each_video_wise_mcq_question_detail/`,
  teacherToolboxVideo: `${BASE}/courses/teacher_toolbox_video/`,
  creatingModuleApi: `${BASE}/courses/treasure_box_module/`,
  treasureBoxVideoCount: `${BASE}/courses/treasure_box_video_count/`,
  creatingVideoForEachModuleApi: `${BASE}/courses/treasure_box_video/`,
  principalAllViewCourse: `${BASE}/instructors/courses_list/`,
  principalCompletedViewCourse: `${BASE}/instructors/instructors_course_complete_details/`,
  principalCompletedViewCourseApi: `${BASE}/instructors/role_wise_instructors_completed_courses/`,
  getCourseSettingRolesApi: `${BASE}/courses/CourseRelatedRole/`,

  getSchoolListApi: `${BASE}/authenticate/schools/`,
  ReducePendingHours: `${BASE}/instructors/duration_extension/`,
  branchWiseTeachers: `${BASE}/instructors/branch_wise_teachers/`,
  assignTheTeachers: `${BASE}/instructors/assign_applicants/`,
  reassignTheTeachers: `${BASE}/instructors/reassign_applicant_list/`,
  reassignTheTeachersPost: `${BASE}/instructors/update_reassign_applicant_list/`,
  classInitiationFormSubmit: `${BASE}/instructors/create_trainer_class_initiate/`,
  classInitiateAttendance: `${BASE}/instructors/retrieve_branch_wise_teacher_attendance/`,
  classInitiateAttendanceApi: `${BASE}/instructors/retrieve_branch_wise_teacher_attendance/`,
  otpSending: `${BASE}/instructors/otp_sending/`,
  otpVerification: `${BASE}/instructors/otp_verification/`,
  createTeacherAttendance: `${BASE}/instructors/create_teacher_attendance/`,
  PPTVideoApi: `${BASE}/courses/ppt_videos_url/`,
  ApiForCourseIcons: `${BASE}/assessment/retrieve_icons/`,
  AssessmentReviewApi: `${BASE}/assessment/upload_user_assessment_details/`,
  principalviewBranches: `${BASE}/instructors/get_branches/`,
  uploadReviewMarksApi: `${BASE}/assessment/`,
  getAllSubjectsList: `${BASE}/courses/list_create_coursesubtype/`,
  FinishChapterApi: `${BASE}/courses/create_user_course_completion/`,
  teachersPerformanceData: `${BASE}/assessment/get_performance_card_list/`,
  // teachersScoreApi: `${BASE}/assessment/detail_view_of_performance_card/`,
  teachersScoreApi: `${BASE}/assessment/performance_card_modules_and_duration/`,
  teachersResultsApi: `${BASE}/assessment/module_wise_performance_report/`,
  branchListApi: `${BASE}/instructors/get_branches/`,
  fetchInductionFormData: `${BASE}/courses/user_logged_in_details/`,
  enrollCouresListApi: `${BASE}/courses/create_retrieve_users_self_courses/`,
  coureEnrollApi: `${BASE}/instructors/retrieve_role_positions/`,
  assignTeacherRoleList: `${BASE}/instructors/retrieve_role_positions/ `,
  forgetPasswordOtpGeneratedApi: `${BASE}/authenticate/send_otp_for_password_verification/`,
  otpVerifyurl: `${BASE}/authenticate/otp_verification/`,
  forgetPasswordApi: `${BASE}/authenticate/update_password/`,
  profileFetchApi: `${BASE}/authenticate/retrieve_update_trainee_profile/`,
  userReportApi: `${BASE}/assessment/course_wise_report_list_of_users/`,
  courseRoleApi: `${BASE}/courses/CourseRelatedRole/`,
  courseListApi: `${BASE}/courses/course_filter/`,
  courseWiseReportExcelApi: `${BASE}/assessment/course_wise_report_excel/`,
  consolidatedReportBranchApi: `${BASE}/courses/list_branches/`,
  consolidatedReportSendMailApi: `${BASE}/assessment/send_excel_of_report_in_mail/`,
  schoolWiseReportSendMailApi: `${BASE}/assessment/school_wise_report_in_mail/`,
  // forgotPasswordSendOTP: `${BASE}/authenticate/send_otp_for_password_verification/`
  feedBackSubmitApi: `${BASE}/authenticate/feedback_view/`,
  erpUpdateAPI: `${BASE}/authenticate/update_erp/`,
  updateUsersBranchAPI: `${BASE}/authenticate/update_users_branch/`,
  resetPasswordAPI: `${BASE}/authenticate/reset_password_from_admin_panel/`,
  runModule5API: `${BASE}/courses/module_5_issues/`,
  changeYourPassword: `${BASE}/authenticate/change_password_of_logged_in_user/`,
  createListBlog: `${BASE}/courses/list_create_blogs/`,
  updateBlogApi: `${BASE}/courses/`,
  updateDeleteBlog: `${BASE}/courses/`,
  getBlogAPI: `${BASE}/courses/list_create_blogs/`,
  getBlogCategoriesList: `${BASE}/courses/list_blog_category/`,
  getCategoryFilteredBlogsApi: `${BASE}/courses/list_blogs`,
  getVisualReportSearchAPI: `${BASE}/assessment/branch_wise_visual_report/`,
  getBranchWiseVisualReport: `${BASE}/assessment/branch_wise_teacher_visual_report/`,
  userStatusChangeAPI: `${BASE}/assessment/update_user_status_active_inactive/`,
  getDetailedVisualReport: `${BASE}/assessment/detailed_visual_report/`,
  getRolesChangeUserStatusAPI: `${BASE}/instructors/retrieve_role_positions/`,
  makeUserInactiveAPI: `${BASE}/assessment/make_user_inactive/`,
  getUserDetailAPI: `${BASE}/assessment/get_user_detail/`,
  registerUsersReport: `${BASE}/courses/total_registered_users/`,
  starRatingSubmitApi: `${BASE}/courses/add_course_rating/`,
  leadTeachersAPI: `${BASE}/instructors/retrieve_assign_lead_teachers/`,
  leadTeacherRoleAPI: `${BASE}/instructors/teachers_roles/`,
  courseSubCategoryListApi: `${BASE}/courses/categories/`,
  assignTeacherCoursesListAPI: `${BASE}/instructors/instructor_courses_list/`,
  learningVideosApi: `${BASE}/courses/create_learning_module/`,
  convetVideoToS3LinkApi: `${BASE}/courses/files_upload/`,
  learningVideosUpdateApi: `${BASE}/courses/`,
  learningNotesApi: `${BASE}/instructors/learning_notes/`,
  getRoleBranchUserDetailsAPI: `${BASE}/courses/fetch_role_branch_user_details/`,
  sendNotificationAPI: `${BASE}/courses/send_notification/`,
  viewAllNotesApi: `${BASE}/instructors/all_notes/`,
  downloadNotesApi: `${BASE}/instructors/notes_pdf/`,
  scheduleOnlineClassApi: `${BASE}/instructors/create_class/`,
  updateAndDeleteScheduleOnlineClassApi: `${BASE}/instructors/`,
  onLineClassJoinApi: `${BASE}/instructors/join_class/`,
  onlineMeetingApi: `${BASE}/instructors/`,
  getAllWibenarScheduledContentWritterApi: `${BASE}/instructors/create_webinar/`,
  createwibenarApi: `${BASE}/instructors/create_webinar/`,
  updateWibemarApi: `${BASE}/instructors/`,
  leadTeachersListApi: `${BASE}/instructors/lead_teachers_list/`,
  asssignTeachesLeadApi: `${BASE}/instructors/lead_teachers_user_list/`,
  sequencingCourseListApi: `${BASE}/courses/course_sequencing/`,
  updateCourseSequencingApi: `${BASE}/courses/`,
  coureWiseRoleListApi: `${BASE}/courses/cw_course_roles/`,
  getWebinarMeetingsListApi: `${BASE}/instructors/meetings_webinar_list/`,
  getExcelDownLoad: `${BASE}/instructors/meeting_webinar_report/`,
  downloadUsersList: `${BASE}/authenticate/export_user_lists/`,
  meetingRoleAPI: `${BASE}/authenticate/meeting_and_webinar_roles`,
  grades: `${BASE}/courses/list_grades/`,
  getSubjectByGrade: `${BASE}/courses/get_subjects/`,
  getMultipleSubjectsByGrade: `${BASE}/courses/get_multiple_subjects/`,
  getVolumeBySubject: `${BASE}/courses/get_volumes/`,
  getChaptersByVolume: `${BASE}/courses/get_chapters/`,
  setChapterSequencing: `${BASE}/courses/`,
  subjects: `${BASE}/courses/list_subjects/`,
  gradeMapping2: `${BASE}/courses/retrieve_grade_subject_mapping/`,
  gradeMapping: `${BASE}/courses/grade_subject_mapping/`,
  edit_deleteMapping: `${BASE}/courses/`,
  subjectSelector_Webinar: `${BASE}/courses/list_grade_subject_mapping/`,
  weeklyReportDownloadApi: `${BASE}/courses/sure-learning-weekly-report/`,
  enrollCouresListTeacher: `${BASE}/courses/create_list_teacher_training_courses/`,
  trainingCourses: `${BASE}/courses/list_teacher_self_enrolled_courses/`,
  assessmentUpdate: `${BASE}/courses/update_assessment_answer_files/`,
  roleUpdateAPI: `${BASE}/authenticate/update_users_role/`,
  removeUpdateAPI: `${BASE}/assessment/update_user_status_active_inactive/`,
  getDetailsByEmailOrPhoneAPI: `${BASE}/authenticate/get_customer_details_by_email_or_phone/`,
  getCourseCertificateUrl: `${BASE}/courses/course_completion_cert/`,
  editSchoolApi: `${BASE}/authenticate/schools/`,
  addBranchApi: `${BASE}/authenticate/branches/`,
  bulkUploadApi: `${BASE}/assessment/bulk_upload_mcq/`,

  //role end-point start here
  getAllRoles: `${BASE}/user_management/role_based_auth/modules/`,

  AuthorizationModules: `${BASE}/user_management/role_based_auth/modules/`,
  AuthorizationModulesClassified: `${BASE}/user_management/role_based_auth/modules/?classified=true`,
  AddRole: `${BASE}/user_management/role_based_auth/roles/`,
  getRole: `${BASE}/user_management/role_based_auth/roles/`,

  AuthorizationModulesEndPoint: `${BASE}/user_management/role_based_auth/module_list/`,
  /*********************** Resources API ***********************/
  AddResource: `${BASE}/courses/add_resources/`,
  GetAllResources: `${BASE}/courses/roles_wise_data_retrive/`,
  GetResource: `${BASE}/courses/retrive_Resources/`,
  DeletResource: `${BASE}/courses/delete_resource/`,
  UpdateResource: `${BASE}/courses/update_resource/`,
  addResourceFolder: `${BASE}/courses/create_folders/`,
  getResourceFolder: `${BASE}/courses/retrive_resources_folders/`,
  folderWiseDataRetrieve: `${BASE}/courses/folder_wise_retrive_resoures/`,
  approveResources: `${BASE}/courses/approve_resource/`,
  searchResources: `${BASE}/courses/search_resources/`,
  /*********************** ------------ ***********************/

  /*********************** Assessment Scores API ***********************/
  getAssessmentScores: `${BASE}/assessment/get_user_assessment_details/`,
  /*********************** ------------ ***********************/

  /*********************** Update Attendance API ***********************/
  getAllUserDurations: `${BASE}/instructors/all_users_duration/`,
  /*********************** ------------ ***********************/

  /*********************** Branch and Role Wise Report API ***********************/
  downloadBranchAndRoleWiseReport: `${BASE}/assessment/get_branch_wise_and_role_wise/`,
  /*********************** ------------ ***********************/

  /*********************** Schedule Webinar API ***********************/
  userAttendanceList: `${BASE}/instructors/Zoom_duration_Update_users_date`,
  updateMeetingHours: `${BASE}/instructors/Zoom_durations_update`,
  downloadDurationReport: `${BASE}/instructors/Zoom_duration_Update_users_date_excel/`,
  /*********************** ------------ ***********************/

  getAllUser: `${BASE}/user_management/role_based_auth/users_list/`,
  getAllMappedUser: `${BASE}/user_management/role_based_auth/user_role_mapping/`,
  getPermissons: `${BASE}/user_management/check_access/ `,
  getRoleRegistration: `${BASE}/user_management/role_based_auth/roles_list/`,
  volumePermissionList: `${BASE}/courses/get_other_volumes/`,
  /*********************** MCQ REPORT ***********************/

  mcqReport: `${BASE}/assessment/generate_mcq_reports/`,
  randomMcqReport: `${BASE}/assessment/generate_random_mcq_reports/`,
  /*********************** CDN ***********************/
  s3: `${BUCKET}/`,
};
export default urls;
