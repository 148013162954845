/* eslint-disable no-console */
import React, { useState ,useEffect} from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Button,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './userListDownload.style';
import urls from '../../../url';
import axios from 'axios';


const UserListDownload = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [permission,setPermission]=useState([]);
  const downloadUsersReport = () => {
    const fetchReport = async () => {
      const response = await fetch(`${urls.downloadUsersList}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'text/ms-excel',
          module: localStorage.getItem('User_List_Report')
        },
      });
      const res = await response.blob();
      return res;
    };
    fetchReport()
      .then((blob) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', 'sure_learning_branch_list.xls');
        a.click();
      })
      .catch(console.error);
  };

     // For Permissions
     function getPermissonData(id) {
      axios.get(urls.getPermissons,{
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'text/ms-excel',
          module: id
        },
      }).then(response => {
        setPermission(response.data.response)
      }).catch(error => {
        console.log(error);
      })
    }
  
    useEffect(() => {
      getPermissonData(localStorage.getItem('User_List_Report'))
    }, [])

  return (
    <div className={classes.root}>
      <Typography variant="h4">User List Download</Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        {permission.can_export ? 
        <Button
          style={{ marginTop: '10px' }}
          variant="contained"
          color="primary"
          size="medium"
          // href={urls.downloadUsersList}
          onClick={downloadUsersReport}
        >
          Download User List
        </Button>
        :null}
      </Paper>
    </div>
  );
};
UserListDownload.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(UserListDownload);
