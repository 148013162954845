import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import styles from "./addBranch.style";
import CreateBranch from "./CreateBranch";
import ListBranch from "./listBranch";

const addBranch = ({ classes }) => {
  return (
    <>
      <Typography variant="h4"> Add School Branch</Typography>
      <Divider className={classNames.divider} />
      <Paper>
        <Grid container spacing={3} className={classes.root}>
          <Grid item md={6} xs={12}>
            <CreateBranch />
          </Grid>
        </Grid>
      </Paper>
      <br />
      <br />
      <Grid xs={12}>
        <ListBranch />
      </Grid>
    </>
  );
};
addBranch.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(addBranch);
