export default (theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerD: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'black',
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  contentBox: {
    color: 'lightgray',
    textAlign: 'center',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
    width: '100%',
    marginBottom: '5px',
  },
  textField: {
    color: 'black',
    width: '100%',
  },
  typography: {
    fontFamily: 'Charter Bd BT',
    color: 'darkslategray',
    marginRight: '20px',
  },
  appBar: {
    padding: theme.spacing(4, 3, 3),
    backgroundColor: 'lightgray',
  },
  paper: {
    padding: theme.spacing(1, 2, 1),
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: '9px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typographyy: {
    color: 'blue',
  },
  buttonview: {
    alignItems: 'center',
    textAlign: 'center',
  },
  typText: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    color: 'blue',
    fontSize: '30px',
  },
  TextDesc: {
    color: 'black',
  },
  TextTitle: {
    marginTop: '15px',
    color: 'black',
    fontSize: '25px',
  },
  IconButtons: {
    alignItems: 'center',
    textAlign: 'center',
  },
  activityBox1: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4a66da',
    backgroundColor: '#bada55',
  },
  introBox: {
    width: '10rem',
    textAlign: 'center',
  },
  moduleCards: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0.5rem 0.5rem 0',
    padding: '2rem',
    '&:hover': {
      boxShadow: '3px 3px 3px 3px grey',
      transition: 'all 1s ease',
      borderRadius: '10px',
    },
  },
  secondHead: {
    fontSize: '0.9rem',
    margin: '0.5rem 0.5rem 1rem 0.5rem',
    textAlign: 'center',
    display: 'block',
  },

  viewButton: {
    marginTop: '30px',
    textDecoration: 'none',
    color: 'white',
    borderColor: 'white !important',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
    // left: 0
  },
  moduleCover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    paddingTop: '12vh',
    margin: '0 auto',
  },
});
