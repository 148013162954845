/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TablePagination,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TableHead,
} from '@material-ui/core';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import styles from './allNotes.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import ViewAllNotes from './viewallNotesModel';
import useFetch from '../../../hoc/useFetch';
import axios from 'axios';

const AllNotesView = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [allNotesData, setAllNotesData] = useState();
  const [Loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [permission,setPermission]=useState([]);


  const {
    data: roleList,
    isLoding: gettingRoleList,
    doFetch: fetchRoleList,
  } = useFetch([]);

  const {
    data: branchList,
    isLoding: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([]);

  function getData(srole, sbranch) {
    setLoading(true);
    let path;
    if (srole.length !== 0 && sbranch.length !== 0) {
      path = `${urls.learningNotesApi}?page_size=${rowsPerPage || 10}&page=${page + 1}&type=1&role=${srole}&branch=${sbranch}`;
    } else {
      path = `${urls.learningNotesApi}?page_size=${rowsPerPage || 10}&page=${page + 1}&type=1`;
    }
    fetch(path, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Learning_Notes')

      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success('Data Received');
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      }).then((data) => {
        setAllNotesData(data);
      });
  }

  let loader = null;
  if (Loading || gettingBranches || gettingRoleList) {
    loader = <Loader open />;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth) {
      fetchBranches({
        url: urls.getBranchNames,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Learning_Notes')
        },
      });
      fetchRoleList({
        url: urls.getCourseSettingRolesApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Learning_Notes')
        },
      });
    }
  }, [auth]);

  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Learning_Notes')
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Learning_Notes'))
  }, [])

  useEffect(() => {
    if (auth) {
      getData(selectedRole, selectedBranch);
    }
  }, [auth.personal_info.token, page, rowsPerPage]);

  function clearFilterRecord() {
    setSelectedRole([]);
    setSelectedBranch([]);
    getData([], []);
  }

  function getFilterRecord() {
    if (selectedBranch.length === 0) {
      alert.warning('Select Branch');
      return;
    }
    if (selectedRole.length === 0) {
      alert.warning('Select Role');
      return;
    }
    getData(selectedBranch, selectedRole);
  }

  function viewNotes(data) {
    setOpen(true);
    setRecordData(data);
  }
  const handelCloseFunc = () => {
    setOpen(false);
    setRecordData({});
  };

  return (
    <>
      <Typography variant="h4">All Notes</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} sm={12}>
          <Paper className={classes.paper1}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-controlled-open-select-label">
                    Select branches
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    className={classes.normal}
                    name="branch"
                  >
                    <MenuItem value={1} key={1} disabled>
                      Select branches
                    </MenuItem>
                    {branchList
                      && branchList.length !== 0
                      && branchList.map((dropItem) => (
                        <MenuItem
                          value={dropItem.id}
                          key={dropItem.id}
                        >
                          {dropItem.branch_name ? dropItem.branch_name : ''}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-controlled-open-select-label">
                    Select Role
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                    name="role_category"
                  >
                    <MenuItem value={1} key={1} disabled>
                      Select Role
                    </MenuItem>
                    {roleList
                      && roleList.length !== 0
                      && roleList.map((dropItem) => (
                        <MenuItem value={dropItem.id} key={dropItem.id}>
                          {dropItem.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={6} style={{ marginTop: '10px' }}>
                <Button variant="contained" fullWidth color="primary" onClick={() => getFilterRecord()}>Search</Button>
              </Grid>
              <Grid item md={2} xs={6} style={{ marginTop: '10px' }}>
                <Button variant="contained" fullWidth color="primary" onClick={() => clearFilterRecord()}>Clear Filter</Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {allNotesData && allNotesData.results && allNotesData.results.length === 0
        && (
        <Grid item md={12} xs={12}>
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}> Records Not Found</Typography>
        </Grid>
        )}
        {allNotesData && allNotesData.results && allNotesData.results.length !== 0
        && (
        <>
          <Grid item md={12} xs={12}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell float="left">S.No</TableCell>
                    <TableCell float="left">Name</TableCell>
                    <TableCell float="left">ERP</TableCell>
                    <TableCell float="left">Branch</TableCell>
                    <TableCell float="left">Role</TableCell>
                    <TableCell float="left">Video Name</TableCell>
                    <TableCell float="left">View Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allNotesData && allNotesData.results && allNotesData.results.length > 0
                      && allNotesData.results.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell float="left">{index + 1}</TableCell>
                          <TableCell float="left">{(item.user_details.user && item.user_details.user.first_name) || ''}</TableCell>
                          <TableCell float="left">{(item.user_details.user && item.user_details.user.username) || ''}</TableCell>
                          <TableCell float="left">{(item.user_details && item.user_details && item.user_details.branch && item.user_details.branch[0].branch_name && item.user_details.branch[0].branch_name) || ''}</TableCell>
                          <TableCell float="left">{(item.user_details && item.user_details && item.user_details.branch && item.user_details.role_category && item.user_details.role_category.name) || ''}</TableCell>
                          <TableCell float="left">{(item.learning_module && item.learning_module.title) || ''}</TableCell>
                          <TableCell float="left">
                            {permission.can_view ?
                            <Button variant="contained" color="primary" onClick={() => viewNotes(item)}>
                              View&nbsp;
                              <VisibilitySharpIcon />
                            </Button>
                            :null}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item md={12} xs={12}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TablePagination
                      colSpan={6}
                      labelDisplayedRows={() => `Page ${page + 1} of ${+allNotesData.total_pages}`}
                      rowsPerPageOptions={[5, 10, 20, 30]}
                      count={+allNotesData.count}
                      rowsPerPage={rowsPerPage || 10}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'Rows per page' },
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableCell style={{ marginTop: '13px' }}>
                      <IconButton
                        onClick={firstPageChange}
                        disabled={page === 0 || page === 1}
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => lastPageChange(allNotesData.total_pages - 1)}
                        disabled={page === +allNotesData.total_pages - 1}
                      >
                        <LastPageIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </>
        )}
      </Grid>
      <ViewAllNotes
        open={open}
        handelClose={handelCloseFunc}
        fullData={recordData}
      />
      {loader}
    </>
  );
};
AllNotesView.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AllNotesView);
