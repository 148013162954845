export default (theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  para: {
    padding: '10px',
  },
  nextBack: {
    padding: '10px',
  },
  head: {
    padding: '10px',
    fontWeight: 'bold',
  },
  saveButton: {
    margin: '5px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});
