import React from 'react';
import InHouseMainPage from './InHouseMainPage';

function InHouseMain() {
  // let prevScrollPos = window.pageYOffset;

  // console.log(prevScrollPos);

  // eslint-disable-next-line func-names
  // window.onscroll = function() {
  //   const currentScrollPos = window.pageYOffset;
  //   // console.log(currentScrollPos);
  //   if (prevScrollPos > currentScrollPos) {
  //     document.getElementById("navbar").style.opacity = "1";
  //   } else {
  //     document.getElementById("navbar").style.opacity = "0";
  //   }
  //   prevScrollPos = currentScrollPos;
  // };
  return (
    <>
      {/* <InHouseBody /> */}
      <div style={{ paddingTop: '12vh' }}>
        <InHouseMainPage />
      </div>
    </>
  );
}

export default InHouseMain;
