export default (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  tdMainDiv: {
    display: 'flex',
    width: '80%',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
  },
  tablePart: {
    border: '1px solid black',
    padding: '0.1rem',
    marginTop: '1rem',
    width: '500px',
    maxHeight: 350,
    overflow: 'auto',
    paddingBottom: '1px',
  },
  classBox: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2, 4, 3),
    // '&:hover': {
    //   boxShadow: '5px 5px 10px 10px grey',
    //   transition: 'all 1s ease',
    //   borderRadius: '10px',
    //   // transitionDelay: "1s"
    // },
  },
});
