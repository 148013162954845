import { Typography, Grid, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import urls from "../../../../url";
import folder from "../../../../assets/images/Folder.png";
import { useHistory } from "react-router-dom";
import "../resourses.css";

const TrainerFolderTopic = () => {
  const [folderList, setFolderList] = useState([]);
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const history = useHistory();
  useEffect(() => {
    getAllFolders();
  }, []);

  const getAllFolders = () => {
    axios
      .get(urls.getResourceFolder, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Resources_data"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        setFolderList(response.data);

        // handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResourceList = (eachData) => {
    history.push("/trainerDriven/Resourses");
    sessionStorage.setItem("folderDetails", JSON.stringify(eachData));
    // console.log(eachData);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Typography
            className="folder-heading"
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              margin: "auto",
              fontWeight: "bolder",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          >
            Resource Folders
          </Typography>
          <Grid
            className="folder-list"
            style={{ margin: "2% 0 0 0" }}
            container
            item
            spacing={3}
          >
            {folderList &&
              folderList.map((eachData, index) => {
                return (
                  <Grid item xs={3}>
                    <div
                      className="folders"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleResourceList(eachData)}

                      // onClick={handleResourceList}
                    >
                      <img
                        style={{ height: "50px", width: "100px" }}
                        src={folder}
                      />
                      <Typography
                        style={{ margin: "0 0 0 8%" }}
                        key={eachData.id}
                      >
                        {eachData.folder_name}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TrainerFolderTopic;
