import React from 'react';
import {
// Dashboard as DashboardIcon,
// Add as AddIcon,
// Edit as EditIcon,
// NoteAdd as NoteAddIcon,
// FileCopy as FileCopyIcon,
// CloudUpload as CloudUploadIcon,
} from '@material-ui/icons';
// import LockOpenIcon from '@material-ui/icons/LockOpen';

// import AdminDashboard from "../components/admin/dashboard/dashboard";
// import ContentDashboard from "../components/contentWriter/dashboard/dashboard";
// import SuperAdminDashboard from "../components/superAdmin/dashboard/dashboard";
// import Role from '../components/superAdmin/role/role';
// import RoleMapping from '../components/superAdmin/roleMapping/roleMapping';
// import AddCategory from "../components/contentWriter/addCategory/AddCategory";
// import CourseLevel from "../components/contentWriter/addCourseLevel/addCourseLevel";
// import Courses from '../components/admin/courses/courses';
// import Approve from '../components/admin/approve/approve';
// import Registration from "../components/superAdmin/registration/registration";
// import CourseLanguage from "../components/contentWriter/dashboard/courseLanguage/courseLanguage";
// import CourseSubTitle from "../components/contentWriter/courseSubTitle/courseSubTitle";
// import StudentCourse from '../components/contentWriter/studentCourse/studentCourse';
// import CreateCourse from "../components/contentWriter/createCourse/createCourse";
// import CreateCourseType from "../components/contentWriter/courseType/courseType";
// import CategorySubType from "../components/contentWriter/categorySubType/categorySubType";
// import CategoryMapping from "../components/contentWriter/categoryMapping/categoryMapping";
// import Authorize from "../components/admin/authorize/Authorize";
// import BranchwiseVideos from "../components/contentWriter/BranchwiseVideos/BranchwiseVideos";
// import StartCourseCreation
//  from "../components/contentWriter/createCourse/startCourseCreation/startCourseCreation";

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import RedoIcon from '@material-ui/icons/Redo';
// // import MoreInfo from '../reusableComponents/courseEnroleModle/MoreInfo';
// // import TeacherToolBox from "../components/contentWriter/teacherToolBox/TeacherToolBox";
// import CoursesView from './PrincipalComponents/CoursesView';
// import AssignTeacher from './AssignTeachers/AssignTeacher';
// import AssessmentReview from './assessmentReviews/assessmentReviews';
// // import PrincipalRouting from './PrincipalRouting';
// import ModelBody from '../../studentCourse/courses/modelBody';
// import ReAssignTeacher from './ReassignTeachers/ReAssignTeacher';
// import TeachersPerformance from './teachersPerformenceCard/teachersPerformenceCard';
// import CourseEnroleModle from '../reusableComponents/courseEnroleModle/courseEnroleModle';
import TeacherEnrollCourse from '../enrollCourses/enrollCourse';
import CoursesView from '../ViewCourses/CoursesView';
// import consolidatedReport from './consolidatedReport/consolidatedReport';
// import Report from './report/report';

// import AdminDashboard from '../admin/dashboard/dashboard';
import TrainingModule from '../../InductionTraining/TrainingModule/TrainingModule';
import TrainingChapters from '../../InductionTraining/TrainingModule/TrainingChapters/TrainingChapters';
import TrainingLesson from '../../InductionTraining/TrainingModule/TrainingLessons/TrainingLesson';
import TrainingUnit from '../../InductionTraining/TrainingModule/TrainingUnit/TrainingUnit';
// // import dashboard from './visualReportsBranchwise/dashboard';
// import dashboard from '../admin/visual-dashboard/dashboard';
// import leadTeacher from './leadTeacher/leadTeacher';
// import PrincipalLeadTeacher from './principalAssignTeacher/principalAssignTeacher';
// import WeeklyReport from '../common/weekly-report/weeklyReport';
import LandingPage from './LandingPage';
// import dashboard from '../../../../components/admin/visual-dashboard/dashboard';
import TeacherReport from './TeacherReport';

const ComponentList = {
  teacher: [
    localStorage.getItem('Teacher_Dashboard')!=="null"?
    {
      name: 'Dashboard',
      icon: <AssignmentIndIcon />,
      link: '/teacherDashboard',
      component: LandingPage,
    }:null,
    localStorage.getItem('Enroll_for_Self_Courses')!=="null"?
    {
      name: 'Enroll for Self Courses',
      icon: <AssignmentIndIcon />,
      link: '/teacherDashboard/enroll_course',
      component: TeacherEnrollCourse,
    }:null,
    localStorage.getItem('Enrolled_Self_Courses')!=="null"?
    {
      name: 'Enrolled Self Courses',
      icon: <AssignmentIndIcon />,
      link: '/teacherDashboard/training_course',
      component: CoursesView,
    }:null,
    localStorage.getItem('Assigned_by_Principal_CoOrdinator')!=="null"?
    {
      name: 'Self Driven Assigned by Principal/Co-Ordinator',
      icon: <AssignmentIndIcon />,
      link: '/teacherDashboard/modules',
      component: TrainingModule,
    }:null,
    localStorage.getItem('Reports')!=="null"?
    {
      name:'Report',
      icon:<RedoIcon/>,
      link: '/teacherReport',
      component:TeacherReport, 
    }:null,
    {
      link: '/teacherDashboard/chapters',
      component: TrainingChapters,
    },
    {
      link: '/teacherDashboard/lessons',
      component: TrainingLesson,
    },
    {
      link: '/teacherDashboard/unit',
      component: TrainingUnit,
    },
  ],
};
export default ComponentList;
