import React from 'react';
// import {
//   BrowserRouter,
//   //  Switch, Route, Link,
// } from 'react-router-dom';
// import BackgroundImage from '../writing.jpg';
import BackgroundImage from './images/bookImg.jpg';
import logo from './images/Logo.png';
import './MainPage.css';

// import MissionPage from './MissionPage';

function MainPage() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100%',
          height: '90vh',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50% , -50%)',
            // backgroundColor: "rgba(0,0,0,0.5)",
            // padding: "15px",
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          <img src={logo} alt="crash" className="centerImg" />
          {/* <Typography
            variant='h3'
            style={{
              fontWeight: "bold"
            }}
            className='formHeadAlign'
            component='h2'
          >
            Empower Yourself
          </Typography> */}
          {/* <p>The Freedom to Learn. The Tools to Succeed </p> */}
        </div>
        {/* <div style={{ textAlign: "center" }}>
          <Grid container style={{ position: "absolute", top: "70%" }}>
            <Grid item xs={6}>
              <Button
                //   color='secondary'
                variant='contained'
                style={{
                  // padding: "2rem 5rem",
                  backgroundColor: "rgba(0,0,0,0.6)",
                  color: "white",
                  fontWeight: "bold"
                  // fontSize: "1.2rem"
                }}
                //   className={classes.navBtns}
              >
                Student
              </Button>
            </Grid>
            <Grid item xs={6}>
              <BrowserRouter>
                <a href='/register'>
                  <Button
                    //   color='secondary'
                    variant='contained'
                    style={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      color: "white",
                      fontWeight: "bold"
                      // fontSize: "1.2rem"
                    }}

                    //   className={classes.navBtns}
                  >
                    Professional
                  </Button>
                </a>
              </BrowserRouter>
            </Grid>
          </Grid>
        </div> */}
      </div>
      {/* <MissionPage /> */}
    </>
  );
}

export default MainPage;
