import React, { Component } from 'react';

class TeacherLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (<p />);
  }
}

export default TeacherLandingPage;
