/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  FormControl,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Loader from '../../../hoc/loader';
import styles from './ClassInitiate.style';
import { useAlert } from '../../../hoc/alert/alert';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';

function OnlinForm({ classes }) {
  const alert = useAlert();
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [initiatedCourses, setInitiatedCourses] = useState([]);
  const [topic, setTopic] = useState('');
  const [loading, setLoader] = useState(false);
  const [dateTime, setDateTime] = useState('');
  const [duration, setDuration] = useState('');
  const [initiatedCoursesVal, setInitiatedCoursesVal] = useState([]);
  const [selectedbranch, setselectedBranch] = useState([]);
  const [SelectedAll, setSelectedAll] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    data: branchList,
    isLoding: gettingBranchList,
    doFetch: fetchBranchList,
  } = useFetch([]);

  function handleClose() {
    setTopic('');
    setDateTime('');
    setDuration('');
    setselectedBranch([]);
    setInitiatedCoursesVal([]);
  }

  async function getData(url) {
    setLoader(true);
    const dataResult = fetch(url, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Initiate_Class')
      },
    });
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    return finalData;
  }
  let loader = null;
  if (loading || gettingBranchList) {
    loader = <Loader open />;
  }

  useEffect(() => {
    getData(`${urls.classInitiationFormSubmit}?type=1`).then((data) => {
      setInitiatedCourses(data);
    });
    fetchBranchList({
      url: `${urls.getBranchNames}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Initiate_Class')
        
      },
    });
  }, []);

  const handleChange = (event) => {
    setInitiatedCoursesVal(event.target.value);
  };

  function functionToCreateMeeting() {
    if (!topic) {
      alert.warning('enter class name');
      return;
    }
    if (!dateTime) {
      alert.warning('enter Date and time');
      return;
    }
    if (!duration) {
      alert.warning('enter duration');
      return;
    }
    if (!selectedbranch || selectedbranch.length === 0) {
      alert.warning('select Branch');
      return;
    }
    if (!initiatedCoursesVal) {
      alert.warning('Select Class');
      return;
    }

    const data = {
      topic,
      start_time: `${dateTime.replace('T', ' ')}:00`,
      duration,
      class_id: initiatedCoursesVal,
      class_type: 'online',
      branch: selectedbranch,
    };
    setLoader(true);
    fetch(`${urls.scheduleOnlineClassApi}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Initiate_Class')
      },
    }).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        // alert.success('Online Class Successfully Created');
        return res.json();
      }
      // if (res.status !== 200) {
      //   setLoader(false);
      //   alert.error('Something went wrong please try again');
      //   return res.json();
      // }
      return res;
    }).then((res) => {
      if (res.status_code === 201) {
        handleClose();
        alert.success('Online Class Successfully Created');
      } else if (res.status_code !== 201) {
        alert.error(res.message);
      }
    });
  }

  function handleSelectALl(e) {
    if (SelectedAll === true && e.target.value.length - 1 === branchList.length) {
      setSelectedAll(false);
      setselectedBranch([]);
      return;
    }
    if (e.target.value.length !== 0) {
      if (e.target.value.filter((data) => data === '0').length === 1 && SelectedAll === false) {
        const setarray = [];
        for (let i = 0; i < branchList.length; i += 1) {
          setarray.push(branchList[i].id);
        }
        setSelectedAll(true);
        setselectedBranch(setarray);
      } else {
        setselectedBranch(e.target.value);
        setSelectedAll(false);
      }
    } else {
      setselectedBranch([]);
      setSelectedAll(false);
    }
  }

  return (
    <>
      <div className={classes.tdMainDiv}>
        <TextField
          id="standard-full-width"
          label="Class Name"
          className={classes.textField}
          placeholder="Enter Class Name"
          fullWidth
          value={topic || ''}
          onChange={(e) => setTopic(e.target.value)}
          margin="normal"
        />
        <div style={{ marginTop: '1rem' }}>
          <TextField
            id="standard-full-width"
            helperText="Enter Date and Time (dd/mm/yyyy , hh:mm)"
            type="datetime-local"
            className={classes.textField}
            placeholder="Enter Date and Time"
            fullWidth
            value={dateTime || ''}
            onChange={(e) => setDateTime(e.target.value)}
            margin="normal"
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <TextField
            helperText="Enter Duration in (minutes)"
            type="number"
            className={classes.textField}
            fullWidth
            value={duration || ''}
            onChange={(e) => setDuration(e.target.value)}
            margin="normal"
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="demo-controlled-open-select-label">
              Select branches
            </InputLabel>
            <Select
              multiple
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={selectedbranch}
              style={{ width: 500, paddingBottom: '3px' }}
              onChange={(e) => handleSelectALl(e)}
              className={classes.normal}
              MenuProps={MenuProps}
            >
              <MenuItem disabled>
                Select Branch
              </MenuItem>
              <MenuItem key="0" value="0">
                Select All
              </MenuItem>
              {branchList
                && branchList.length !== 0
                && branchList.map((dropItem) => (
                  <MenuItem
                    style={{
                      color: selectedbranch && selectedbranch.filter((gradeData) => gradeData === dropItem.id).length === 1 ? 'white' : '',
                      backgroundColor: selectedbranch && selectedbranch.filter((gradeData) => gradeData === dropItem.id).length === 1 ? 'darkslateblue' : '',
                    }}
                    value={dropItem.id}
                    key={dropItem.id}
                  >
                    {dropItem.branch_name ? dropItem.branch_name : ''}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="demo-controlled-open-select-label">
              Select Class
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={initiatedCoursesVal || []}
              onChange={(event, actionMeta) => handleChange(event, actionMeta.props.name)}
              input={<Input />}
              MenuProps={MenuProps}
              className={classes.selectEmpty}
              style={{ width: 500, paddingBottom: '3px' }}
            >
              <MenuItem value={1} key={1} disabled>
                Select Class
              </MenuItem>
              {initiatedCourses
              && initiatedCourses.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  name={item.course.id}
                  id={item.course.id + item.id}
                >
                  {item.class_title}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {item.course.course_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ marginTop: '1rem' }}>
            <Button fullWidth color="primary" variant="contained" onClick={() => functionToCreateMeeting()}>Class Schedule</Button>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}
OnlinForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OnlinForm);
