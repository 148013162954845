export default () => ({
  formDiv: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f8c853',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '5vh',
  },
  inductionForm: {
    width: '70%',
    height: '50vh',
    display: 'flex',
    // border: "1px solid green",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formColor: {
    color: '#b75c4a',
  },
  selectBranch: {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
  selectInput: {
    padding: '1rem 2rem',
    backgroundColor: '#53db7b',
    border: '1px solid #53db7b',
    borderRadius: '0.4rem',
    cursor: 'pointer',
    color: 'white',
    width: '50%',
  },
  inductionFormSubmit: {
    padding: '0.7rem 2rem',
    backgroundColor: '#f47a62',
    color: 'white',
    border: '1px solid #f47a62',
    fontWeight: 'bold',
    fontSize: '1rem',
    borderRadius: '0.2rem',
    marginTop: '1rem',
    cursor: 'pointer',
  },
});
