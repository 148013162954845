import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import InductionForm from './InductionForm';
import styles from './InductionForm.style';
// import FooterDiv from '../ReUsableComponents/FooterDiv';

function InductionTraining({ classes }) {
  localStorage.setItem('coursesType', 'subject_training');
  // localStorage.setItem('coursesType', 'induction_training');
  return (
    <>
      <div className={`${classes.formDiv} ${classes.formColor}`}>
        <InductionForm />
      </div>
      {/* <FooterDiv /> */}
    </>
  );
}

InductionTraining.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InductionTraining);
