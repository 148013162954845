export default () => ({
  formDiv: {
    width: '100%',
    height: '88vh',
    // backgroundColor: '#f8c853',
    // backgroundColor: 'lightsteelblue',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10vh',
  },
  backGroundColor: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'lightsteelblue',
  },
  inductionForm: {
    width: '70%',
    height: '50vh',
    display: 'flex',
    // border: "1px solid green",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formColor: {
    color: '#b75c4a',
  },
  feedBackDiv: {
    // backgroundColor: '#f8c853',
    // backgroundColor: 'lightsteelblue',
    display: 'flex',
    padding: '10px',
    // marginBottom: '80px',
  },
  inductionFormSubmit: {
    // padding: '0.7rem 2rem',
    // backgroundColor: '#f47a62',
    // color: 'white',
    // border: '1px solid #f47a62',
    // fontWeight: 'bold',
    // fontSize: '1rem',
    // borderRadius: '0.2rem',
    // marginTop: '1rem',
    cursor: 'pointer',
  },
});
