import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import styles from "./addSchool.style";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import "./listSchool.scss";
import urls from "../../../url";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { useAlert } from "../../../hoc/alert/alert";

const ListSchool = ({
  schoolList,
  classes,
  setSchoolList,
  setEditData,
  editData,
  addSchoolLevel,
  getSchoolList,
}) => {
  const column = ["Sl No.", "School Name", "Actions"];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [school, setSchool] = useState("");
  const [schoolID, setSchoolID] = useState(undefined);
  const alert = useAlert();

  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    setSchool(editData?.name);
  }, [dialogOpen]);

  const handleSchoolChange = (event) => {
    setSchool(event.target.value);
  };

  const updateSchoolList = (id) => {
    let editObj = {
      id: editData?.id,
      name: school,
    };

    axios
      .put(`${urls.editSchoolApi}`, JSON.stringify(editObj), {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("School"),
        },
      })
      .then((result) => {
        console.log(result, "uppda");
        // setSchoolList(result.data);
        alert.success(result.data);
        setDialogOpen(false);
        // window.location.reload();
        getSchoolList();
        setSchool("");
      })
      .catch((error) => {
        console.log(error);
        alert.warning(error.response.data.message);
      });
  };

  const deleteSchoolList = (id) => {
    console.log(id.toString(), "delll");
    let data = {
      id: JSON.stringify(id),
    };
    axios
      .delete(`${urls.editSchoolApi}`, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("School"),
        },
        data,
      })
      .then((result) => {
        console.log(result.data, "paginate");
        // setSchoolList(result.data);
        // setDialogOpen(false);
        // window.location.reload();
        alert.success(result.data);
        getSchoolList();
        // setSchool("");
      })
      .catch((error) => {
        console.log("err", error.response);
        alert.warning(error.response.data);
      });
  };

  return (
    <>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {column &&
                  column.map((eachColumn, index) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "20px",
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        {eachColumn}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolList &&
                schoolList.map((eachData, index) => {
                  return (
                    <TableRow key={eachData.id}>
                      <TableCell
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        {/* {data.currentPage == 1
                          ? index + data.currentPage
                          : (data.currentPage - 1) * data.dataPerPage + (index + 1)} */}
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        {eachData.name}
                      </TableCell>
                      {/* <TableCell>{eachData.student_name}</TableCell>
                      <TableCell>{eachData.teacher_name}</TableCell>
                      <TableCell>{eachData.grade}</TableCell>
                      <TableCell>{eachData.course}</TableCell> */}

                      <TableCell
                        // style={{ margin: "0 auto" }}
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                        className="action-buttons"
                      >
                        <Button
                          size="small"
                          className="host-meeting-button"
                          //   disabled={eachData.is_pdf_generated}
                          style={{ margin: "0 15px" }}
                          onClick={(e) => {
                            setDialogOpen(true);
                            //     setDialogDetail('Edit');
                            setEditData(eachData);
                          }}
                        >
                          <EditOutlinedIcon />
                        </Button>
                        <Button
                          size="small"
                          className="host-meeting-button"
                          style={{ margin: "0 15px" }}
                          //   disabled={eachData.is_pdf_generated}
                          onClick={(e) => {
                            //     setDialogOpens(true);
                            //     //   setDialogDetail('Edit');
                            // setDeleteData(eachData.id);
                            deleteSchoolList(eachData.id);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={dialogOpen}
          style={{ margin: "35px 0 0 0" }}
          className="create-weekly-report-dialog"
        >
          <DialogTitle className="dialog-title">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Edit School</Typography>
              <Button
                className="weekly-report-actions-butons"
                onClick={() => closeDialog()}
              >
                <CloseIcon />
              </Button>
            </div>
          </DialogTitle>
          <>
            <Grid container spacing={3} className={classes.root}>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Edit School"
                  margin="dense"
                  fullWidth
                  required
                  variant="outlined"
                  multiline
                  rows={1}
                  rowsMax={4}
                  value={school}
                  name="school"
                  onChange={handleSchoolChange}
                />
              </Grid>
              {console.log(school, "ssss")}
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(id) => {
                    console.log(editData, "data edited");
                    updateSchoolList(editData);
                  }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </>
        </Dialog>
      </div>
    </>
  );
};

ListSchool.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ListSchool);

// export default ListSchool;
