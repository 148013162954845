import React from 'react'
import {
    withStyles,
    Button,
    TableCell,
    TableBody,
    Table,
    TableHead,
    Grid,
    Divider,
    Typography,
    Paper,
    TableRow,
  } from '@material-ui/core';
function AssementScores() {
    return (
        <>
      <Typography variant="h4">Assement Scores</Typography>
      <Divider  />
        </>
    )
}

export default AssementScores
