import React from 'react';
import PropTypes from 'prop-types';
import './DrawerToggleButton.css';

function DrawerToggleButton({ click }) {
  return (
    <button className="toggleButton" type="submit" onClick={click}>
      <div className="toggleButtonLine" />
      <div className="toggleButtonLine" />
      <div className="toggleButtonLine" />
    </button>
  );
}

DrawerToggleButton.defaultProps = {
  click: () => {},
};
DrawerToggleButton.propTypes = {
  click: PropTypes.func,
};

export default DrawerToggleButton;
