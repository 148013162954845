import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import useFetch from "../../../hoc/useFetch";
import urls from "../../../url";
import { useAlert } from "../../../hoc/alert/alert";
import "./CreateBranchStyle.css";
import Loader from "../../../hoc/loader";
import axios from "axios";
// import uuidv4 from "uuid/v4";
// import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  rootSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function CreateBranch() {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const classes = useStyles();
  const alert = useAlert();
  const [schoolId, setSchoolId] = React.useState(0);
  const [actionItems, setActionItems] = useState([]);
  const [permission, setPermission] = useState([]);
  const [checkLoader, setCheckLoader] = useState(false);
  const {
    data: showSchoolList,
    isLoading: showSchoolListLoading,
    doFetch: fetchSchool,
  } = useFetch(null);

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null);

  function getPermissonBranch(id) {
    const auth = JSON.parse(localStorage.getItem("UserLogin"));
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        localStorage.setItem("branch_can_add", false);
      });
  }

  useEffect(() => {
    getPermissonBranch(localStorage.getItem("Branch"));
  }, []);

  let loader = null;
  if (showSchoolListLoading || checkLoader || modulePermissionLoading) {
    loader = <Loader open />;
  }
  useEffect(() => {
    // console.log('TEST 2', fetchSchool)
    fetchSchool({
      url: urls.editSchoolApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Branch"),
      },
    });
  }, [fetchSchool, auth.personal_info.token]);
  const newItem = () => {
    setActionItems([...actionItems, ""]);
  };
  const handleErrors = (response) => {
    if (response.status !== 201) {
      // if (response.status === 409) {
      //   alert.error(`A user already exists with ERP ${newErp}`);
      //   return response;
      // }
      // if (response.status === 404) {
      //   alert.error(`No user found for ERP ${erp}. Kindly register.`);
      //   return response;
      // }
      // if (response.status === 400) {
      //   alert.error(`Error: ${response.message}`);
      //   return response;
      // }
      alert.error(`Error : ${response.message}`);
    }
    return response;
  };

  const updateItem = (userInput, index) => {
    // console.log("item",userInput, index)
    const newActionItems = [...actionItems];
    newActionItems[index] = userInput;
    // console.log("newActionItems ", newActionItems);
    setActionItems(newActionItems);
  };
  const deleteItem = (index) => {
    setActionItems(
      actionItems.filter((item, currentIndex) => currentIndex !== index)
    );
  };
  const handleSubmit = () => {
    let temp = {};
    for (let i = 0; i < actionItems.length; i += 1) {
      temp = {
        ...temp,
        [`${i}`]: {
          branch_name: actionItems[i],
          school_id: schoolId,
        },
      };
    }

    const temp2 = {
      branches: temp,
    };
    // console.log("TEMP", temp)
    async function postBranches() {
      setCheckLoader(true);
      const response = await fetch(`${urls.addBranchApi}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("Branch"),
        },
        body: JSON.stringify(temp2),
      });
      const data = await response.json();
      setCheckLoader(false);
      return data;
    }
    postBranches()
      .then(handleErrors)
      .then((response) => {
        if (response.status === 201) alert.success("Successfully Added Branch");
        // console.log(response);
        setCheckLoader(false);
        // alert.success(res)
        setActionItems([]);
        setSchoolId("");
        window.location.reload();
      })
      .catch((err) => {
        setCheckLoader(false);
        alert.warning(`Error:${err}`);
      });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} lg={12}>
              <Typography>
                Select School
                <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                id="demo-simple-select-required"
                labelId="demo-simple-select-required-label"
                margin="dense"
                fullWidth
                value={schoolId || ""}
                onChange={(e) => setSchoolId(e.target.value)}
                className={classes.textField}
                variant="outlined"
                style={{ color: "black" }}
              >
                {showSchoolList &&
                  showSchoolList.length !== 0 &&
                  showSchoolList.map((data) => (
                    <MenuItem value={data.id} key={data.id} name={data.name}>
                      {data.name ? data.name : ""}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={12} xs={12}>
              {actionItems.map((item, index) => (
                <Grid item md={12} xs={12}>
                  <div className="row">
                    <form className={classes.rootSpacing}>
                      <TextField
                        id="standard-basic"
                        value={item}
                        placeholder="Branch Name"
                        aria-label="Branch"
                        onChange={(e) => updateItem(e.target.value, index)}
                      />
                      {item.length === 0 ? (
                        <FormHelperText
                          id="component-error-text"
                          style={{ color: "red" }}
                        >
                          Put Proper Branch Name
                        </FormHelperText>
                      ) : null}
                    </form>
                    {/* </div> */}
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      onClick={() => deleteItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
              ))}
              {permission.can_add ? (
                <Button
                  className="btn "
                  onClick={newItem}
                  color="primary"
                  disabled={schoolId === 0}
                  variant="contained"
                >
                  Add Branch
                </Button>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12}>
              {permission.can_add ? (
                <Button
                  variant="contained"
                  onClick={(e) => handleSubmit(e)}
                  color="primary"
                  disabled={actionItems.length === 0}
                >
                  Submit
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </div>
      {loader}
    </>
  );
}

export default CreateBranch;
