/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles, Grid, Typography, Divider, TextField, Button, IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './feedBackForm.style';
import urls from '../../../url';
import useFetch from '../../../hoc/useFetch';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';

function FeedBackForm({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [comments, setComments] = useState('');
  const [open, setOpen] = useState(false);
  const alert = useAlert();
  const [flag, setFlag] = useState(true);

  const {
    data: submitFeedBackRes,
    isLoading: submittingFeedBack,
    doFetch: fetchFeedBack,
  } = useFetch(null);

  // const {
  //   data: feedbackGetRes,
  //   isLoading: gettingfeedbackResponce,
  //   doFetch: fetchgetgeedbackRespoice,
  // } = useFetch(null);

  // useEffect(() => {
  //   if (auth) {
  //     fetchgetgeedbackRespoice({
  //       url: urls.feedBackSubmitApi,
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'Application/json',
  //         Authorization: `Bearer ${auth.personal_info.token}`,
  //       },
  //     });
  //   }
  // }, [auth]);

  function submitFeedBackFunction() {
    if (!comments) {
      alert.warning('Please Enter Your FeedBack');
      return;
    }
    const data = {
      feedback: comments,
    };
    fetchFeedBack({
      url: urls.feedBackSubmitApi,
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  let loader = null;
  if (submittingFeedBack) {
    loader = <Loader open />;
  }

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  DialogTitle.defaultProps = {
    children: null,
  };

  DialogTitle.propTypes = {
    children: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  };

  const handleClose = () => {
    setComments('');
    setOpen(false);
  };

  useEffect(() => {
    if (submitFeedBackRes) {
      alert.success('Thank you for your valuable time, this will help us improve!');
      setFlag(false);
      setTimeout(() => {
        handleClose();
        setFlag(true);
      }, 3000);
    }
  }, [submitFeedBackRes]);

  return (
    <>
      <Grid container justify="flex-end" alignItems="flex-end">
        <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>
          {/* <Button style={{ float: 'right', backgroundColor: '#f47a62', color: 'white' }}
           onClick={() => setOpen(true)} size="medium" variant="outlined">FeedBack</Button> */}
          <Button style={{ lineHeight: '0', fontSize: '20px', color: 'white' }} onClick={() => setOpen(true)} className={classes.inductionFormSubmit}>
            <div className="arcButtonback">
              FeedBack
            </div>
          </Button>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="xl"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.mcqmodal}
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose}>
          {flag ? 'FeedBack Form' : ''}
        </DialogTitle>
        {flag ? (<Divider />) : null}
        <DialogContent>
          {flag ? (
            <>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Please Enter Your Feedback</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Comments"
                  required
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  multiline
                  rows={10}
                  value={comments || ''}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
                <Button variant="outlined" color="primary" onClick={() => submitFeedBackFunction()}>Submit</Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={12} xs={12} style={{ justifyItems: 'center', textAlign: 'center' }}>
                <img src={require('./feedbackimage.svg')} width="100%" height="50%" alt="crash" />
                <Typography variant="h5" style={{ textAlign: 'center', color: 'black', padding: '5px' }}>
                  We appreciate your feedback.
                </Typography>
                <Typography variant="h5" style={{ textAlign: 'center', color: 'black', padding: '5px' }}>
                  Your opinion means world to us
                </Typography>
                {/* <Button variant="contained"
                style={{ marginTop: '20px', color: 'white', backgroundColor: 'blue' }}
                onClick={() => handleClose()}>Done</Button> */}
              </Grid>
            </>
          )}

        </DialogContent>
      </Dialog>
      {loader}
    </>
  );
}
FeedBackForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(FeedBackForm);
