import React, { useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  withStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import styles from './startCourseCreation.Style';

import CourseSchedule from '../CourseSchedule/courseSchedule';
import AddCourseDetails from '../addCourseDetails/addCourseDetails';
// import CourseStructure from "../courseStructure/courseStructure";
import CourseSettings from '../courseSettings/courseSettings';
// import Captions from "../capitons/captions";
import LandingPage from '../landingPage/landingPage';
import CourseMessage from '../courseMessage/courseMessage';
// import Promotion from "../promotion/ promotion";

const StartCourseCreation = ({ classes }) => {
  // const [auth] = useState(JSON.parse(localStorage.getItem('admin')));

  const [page, setPage] = useState('0');

  const userId = localStorage.getItem('userId');

  function FunctionPageChangeBack() {
    if (page > 0) {
      setPage((parseInt(page, 10) - 1).toString());
    }
  }
  function FunctionPageChangeNext() {
    if (page < 8) {
      setPage((parseInt(page, 10) + 1).toString());
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Course Planning</Typography>

            <ListItem dense button onClick={() => setPage('0')}>
              <ListItemIcon>
                <Radio
                  checked={page === '0'}
                  onChange={(e) => setPage(e.target.value)}
                  value="0"
                  // hideRadio
                  color="primary"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </ListItemIcon>
              <ListItemText primary="Landing Page" />
            </ListItem>

            <ListItem dense button onClick={() => setPage('1')}>
              <ListItemIcon>
                <Radio
                  checked={page === '1'}
                  onChange={(e) => setPage(e.target.value)}
                  value="1"
                  // hideRadio
                  color="primary"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </ListItemIcon>
              <ListItemText primary="Course Details" />
            </ListItem>

            <Typography variant="h6">Course Creation</Typography>

            <ListItem dense button onClick={() => setPage('2')}>
              <ListItemIcon>
                <Radio
                  checked={page === '2'}
                  onChange={(e) => setPage(e.target.value)}
                  value="2"
                  // hideRadio
                  color="primary"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </ListItemIcon>
              <ListItemText primary="Course Settings" />
            </ListItem>

            <ListItem dense button onClick={() => setPage('3')}>
              <ListItemIcon>
                <Radio
                  checked={page === '3'}
                  onChange={(e) => setPage(e.target.value)}
                  value="3"
                  // hideRadio
                  color="primary"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </ListItemIcon>
              <ListItemText primary="Course Content" />
            </ListItem>

            <ListItem dense button onClick={() => setPage('4')}>
              <ListItemIcon>
                <Radio
                  checked={page === '4'}
                  onChange={(e) => setPage(e.target.value)}
                  value="4"
                  // hideRadio
                  color="primary"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </ListItemIcon>
              <ListItemText primary="Course Message" />
            </ListItem>

            {/* <ListItem  dense button onClick={e=>setPage('5')}>
                    <ListItemIcon>
                    <Radio
                        checked={page === '5'}
                        onChange={e=>setPage(e.target.value)}
                        value={'1'}
                        hideRadio
                        color="primary"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    </ListItemIcon>
                    <ListItemText primary="Course Structure " />
                    </ListItem>

                        <ListItem  dense button onClick={e=>setPage('6')}>
                        <ListItemIcon>
                        <Radio
                            checked={page === '6'}
                            onChange={e=>setPage(e.target.value)}
                            value={'6'}
                            hideRadio
                            color="primary"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        </ListItemIcon>
                        <ListItemText primary="Captions" />
                       </ListItem>

                        <ListItem  dense button onClick={e=>setPage('7')}>
                        <ListItemIcon>
                        <Radio
                            checked={page === '7'}
                            onChange={e=>setPage(e.target.value)}
                            value={'7'}
                            hideRadio

                            color="primary"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        </ListItemIcon>
                        <ListItemText primary="Promotion" />
                        </ListItem> */}
          </Paper>
        </Grid>
        <Grid item md={9} xs={12}>
          <Paper className={classes.paper}>
            {page === '0' ? (
              <LandingPage
                key={0}
                userIdNo={userId}
                backPage={FunctionPageChangeBack}
                nextPage={FunctionPageChangeNext}
                pageNo={page}
              />
            ) : (
              ''
            )}
            {page === '1' ? (
              <AddCourseDetails
                key={1}
                userIdNo={userId}
                backPage={FunctionPageChangeBack}
                nextPage={FunctionPageChangeNext}
                pageNo={page}
              />
            ) : (
              ''
            )}
            {page === '2' ? (
              <CourseSettings
                key={2}
                userIdNo={userId}
                backPage={FunctionPageChangeBack}
                nextPage={FunctionPageChangeNext}
                pageNo={page}
              />
            ) : (
              ''
            )}
            {page === '3' ? (
              <CourseSchedule
                key={3}
                userIdNo={userId}
                backPage={FunctionPageChangeBack}
                nextPage={FunctionPageChangeNext}
                pageNo={page}
              />
            ) : (
              ''
            )}
            {page === '4' ? (
              <CourseMessage
                key={4}
                userIdNo={userId}
                backPage={FunctionPageChangeBack}
                nextPage={FunctionPageChangeNext}
                pageNo={page}
              />
            ) : (
              ''
            )}
            {/* {page === '5' ?
             <CourseStructure
              userIdNo={userId}
              backPage={FunctionPageChangeBack}
              nextPage={FunctionPageChangeNext}
              pageNo={page}/>  :''} */}
            {/* {page === '6' ?
               <Captions
                userIdNo={userId}
                backPage={FunctionPageChangeBack}
                nextPage={FunctionPageChangeNext}
                pageNo={page}/> :''} */}
            {/* {page === '7' ?
                  <Promotion
                  userIdNo={userId}
                  backPage={FunctionPageChangeBack}
                  nextPage={FunctionPageChangeNext}
                  pageNo={page}/> :''} */}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
StartCourseCreation.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(StartCourseCreation);
