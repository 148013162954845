/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./inHouseTreasureBox.css";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  TablePagination,
  TableRow,
  Table,
  TableBody,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./inHouseTreasureBox.style";
import { useAlert } from "../../../hoc/alert/alert";
import axios from "axios";
import urls from "../../../url";

const InHouseTreasureBox = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [allVidoesCount, setAllVidoesCount] = useState("");
  const [allModules, setAllModules] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [permission, setPermission] = useState([]);
  const [page, setPage] = React.useState(0);
  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(8);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function functionToGetModules() {
    setLoading(true);
    fetch(
      `${urls.creatingModuleApi}?page_size=${rowsPerPage || 8}&page=${page +
        1}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("Treasure_Box"),
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success("Successfully Fetched");
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning("somthing went wrong please try again ");
        }
        return 0;
      })
      .then((data) => {
        setAllModules(data);
      });
  }

  const functionToGetVideoCount = () => {
    setLoading(true);
    fetch(`${urls.treasureBoxVideoCount}?is_active=active`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
        module: localStorage.getItem("Treasure_Box"),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert.success("Successfully Fetched");
          return res.json();
        }
        if (res.status !== 200) {
          setLoading(false);
          alert.warning("somthing went wrong please try again ");
        }
        return 0;
      })
      .then((data) => {
        setAllVidoesCount(data);
      });
  };

  useEffect(() => {
    if (auth) {
      functionToGetVideoCount();
    }
  }, [auth]);

  function getPermissonData(id) {
    console.log(id, "module Id in api call");
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (auth) {
      functionToGetVideoCount();
      functionToGetModules();
      getPermissonData(localStorage.getItem("Treasure_Box"));
    }
  }, [auth, page, rowsPerPage]);

  function functionToOpenViewModel(id) {
    localStorage.setItem("module", id);
  }

  return (
    <>
      <div
        className={classes.activityBox1}
        style={{
          height:
            allModules && allModules.results && allModules.results.length < 10
              ? "100vh"
              : "100%",
        }}
      >
        {/* <div className='treasureBoxBtns'>
          <button className='managementButton'>Classroom Management</button>
          <button className=''>Classroom Teaching</button>
          <button className=''>Subject Related</button>
          <button className=''>Parent Engagement</button>
        </div> */}
        {/* <Typography variant="h4">Treasure Box</Typography>
        <Divider className={classes.dividerD} /> */}
        {loading ? (
          <>
            {/* <CircularProgress
              variant='determinate'
              value={100}
              className={classes.top}
              size={60}
              thickness={8}
            />
            <CircularProgress
              variant='indeterminate'
              disableShrink
              className={classes.bottom}
              size={60}
              thickness={8}
            /> */}
            <img
              // open={open}
              className={classes.progress}
              alt="crash"
              src={require("../../../hoc/loder.gif")}
              width="100%"
              height="100%"
            />
          </>
        ) : (
          <>
            <div className={classes.moduleCover}>
              <Typography variant="h6" className={classes.typographyy}>
                Total Videos : &nbsp;
                {allVidoesCount ? allVidoesCount.total_videos : 0}
                &nbsp;&nbsp;&nbsp; Total views :&nbsp;
                {allVidoesCount ? allVidoesCount.total_videos_viewed : 0}
              </Typography>
            </div>
            <div className="moduleCover">
              {allModules &&
                allModules.results &&
                allModules.results.length !== 0 &&
                allModules.results.map((item, index) => {
                  const colors = [
                    "#54688c",
                    "#f47a62",
                    "#4a66da",
                    "#75cba8",
                    // "#f2bf5e"
                  ];
                  const diffColors = index % 4;
                  return (
                    <div
                      className={classes.moduleCards}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{ backgroundColor: colors[diffColors] }}
                    >
                      <div className={classes.introBox}>
                        <div>
                          <span className={classes.firstHead}>
                            {(item && item.title && item.title) || ""}
                          </span>
                          <br />
                        </div>
                        <div>
                          <span style={{ padding: "5px" }}>
                            {(item && item.viewed_users && item.viewed_users) ||
                              "0"}{" "}
                            Videos
                          </span>
                        </div>
                      </div>

                      <Link
                        to="/InhouseTreasureBoxModuleVideos"
                        style={{ textDecoration: "none" }}
                      >
                        {permission.can_view ? (
                          <Button
                            className={classes.viewButton}
                            variant="outlined"
                            onClick={() => functionToOpenViewModel(item.id)}
                          >
                            View
                          </Button>
                        ) : null}
                      </Link>
                    </div>
                  );
                })}
            </div>
            <div>
              {allModules && allModules.results.length !== 0 && (
                <Grid item md={12} xs={12}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TablePagination
                          colSpan={6}
                          labelDisplayedRows={() =>
                            `Page ${page + 1} of ${+allModules.total_pages}`
                          }
                          rowsPerPageOptions={[8, 20, 30]}
                          count={+allModules.count}
                          rowsPerPage={rowsPerPage || 8}
                          page={page}
                          SelectProps={{
                            inputProps: { "aria-label": "Rows per page" },
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
InHouseTreasureBox.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(InHouseTreasureBox);
