/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  withStyles,
  Button,
  Divider,
  TextField,
  Switch,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './synonyms.style';

const SynonymsMcq = ({
  classes, negativeMarksStatus, submitSynonmemos, synonymsInfoData, actiualMarks,
}) => {
  const [synonymsQuestion, setSynonymsQuestion] = useState('');
  const [marksFortheQuestion, setMarksForTheQuestion] = useState('');
  const [negativeMarks, setNegativeMarks] = useState('');
  const [negativeYesorNo, setNegativeYesorNo] = useState(false);
  const [QuestionType, setQuestionType] = useState(false);
  const [synonymsArray, setSynonymsArray] = useState([]);
  const [synonymsAnswerArray, setSynonymsAnswerArray] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (synonymsInfoData) {
      setSynonymsQuestion((synonymsInfoData && synonymsInfoData.choice_table_matrix_question_title && synonymsInfoData.choice_table_matrix_question_title) || '');
      setSynonymsArray((synonymsInfoData && synonymsInfoData.question && synonymsInfoData.question) || []);
      setSynonymsAnswerArray((synonymsInfoData && synonymsInfoData.correct_ans && JSON.parse(synonymsInfoData.correct_ans)) || []);
      setMarksForTheQuestion(synonymsInfoData && synonymsInfoData.actual_question_mark);
      setNegativeMarks(synonymsInfoData && synonymsInfoData.negative_marking_marks);
      setNegativeYesorNo(synonymsInfoData && synonymsInfoData.is_negative_marking);
      setQuestionType(synonymsInfoData && synonymsInfoData.is_graded_question);
    }
  }, [synonymsInfoData]);

  useEffect(() => {
    if (synonymsArray.length !== 0 && refresh === true) {
      setSynonymsAnswerArray([]);
    }
  }, [synonymsArray]);

  function addWords() {
    setRefresh(true);
    setSynonymsArray((oldData) => [
      ...oldData,
      {
        synonyms: null,
      },
    ]);
  }

  function handleChange(event) {
    setRefresh(true);
    const currentIndex = synonymsAnswerArray.indexOf(event);
    const newChecked = [...synonymsAnswerArray];
    if (currentIndex === -1) {
      newChecked.push(event);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSynonymsAnswerArray(newChecked);
  }
  function handleOption(e, i, key) {
    setRefresh(true);
    if (!synonymsArray.length) {
      alert.warning('No data available');
      return;
    }
    setSynonymsArray((data) => {
      const newData = [...data];
      switch (key) {
        case 'synonyms':
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    });
  }
  function DeleteOptionFunction(index) {
    setRefresh(true);
    setSynonymsArray((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <ExpansionPanel style={{ backgroundColor: 'snow' }}>
          <ExpansionPanelSummary
            style={{ backgroundColor: 'lightgray' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Question Editor
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Question"
                  margin="dense"
                  className={classes.textField}
                  required
                  value={synonymsQuestion || ''}
                  onChange={(e) => setSynonymsQuestion(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={10} xs={12}>
                  <Typography variant="h5">synonyms</Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => addWords()}
                  >
                    Add Synonyms
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box border={1} className={classes.box}>
                  {synonymsArray
                    && synonymsArray.length !== 0
                    && synonymsArray.map((item, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item md={11} xs={12}>
                          <TextField
                            label={`Enter Synonyms  ${index + 1}`}
                            margin="dense"
                            className={classes.textField}
                            required
                            value={item.synonyms || ''}
                            onChange={(e) => handleOption(e.target.value, index, 'synonyms')}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={1} xs={12}>
                          <IconButton onClick={() => DeleteOptionFunction(index)}>
                            {' '}
                            <DeleteIcon size="small" color="secondary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* ANSWER DROP */}

        <ExpansionPanel
          style={{ backgroundColor: 'snow', marginTop: '20px' }}
        >
          <ExpansionPanelSummary
            style={{ backgroundColor: 'lightgray' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Answer</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Box border={1} className={classes.box}>
                  {synonymsArray
                  && synonymsArray.length !== 0 && synonymsArray.map((item, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item md={1} xs={4}>
                        <Checkbox
                          checked={synonymsAnswerArray.indexOf(item.synonyms) !== -1}
                          value={item.synonyms || ''}
                          color="primary"
                          onChange={(e) => handleChange(e.target.value)}
                        />
                      </Grid>
                      <Grid item md={11} xs={8}>
                        <Typography variant="h5" className={classes.texttitle}>{item.synonyms}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    {' '}
                    Graded Question
                    {' '}
                  </Typography>
                </Grid>
                <Grid item md={1} xs={6} style={{ marginTop: '15px' }}>
                  <Switch
                    checked={QuestionType}
                    onChange={(e) => setQuestionType(e.target.checked)}
                    color="default"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    {' '}
                    Practice Question
                  </Typography>
                </Grid>
              </Grid>
              {!actiualMarks && (
              <Grid container spacing={2}>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    Marks for Question :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={12} className={classes.TypographyDate}>
                  <TextField
                    label="Marks"
                    margin="dense"
                    className={classes.textField}
                    required
                    type="number"
                    value={marksFortheQuestion || ''}
                    onChange={(e) => setMarksForTheQuestion(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              )}
              {!negativeMarksStatus
              && (
              <Grid container spacing={2}>
                <Grid item md={2} x={12}>
                  <Typography variant="h6" className={classes.titlestylee}>
                    Negative Marking :
                  </Typography>
                </Grid>
                <Grid item md={1} xs={6} style={{ marginTop: '15px' }}>
                  <Switch
                    checked={negativeYesorNo}
                    onChange={(e) => setNegativeYesorNo(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                {negativeYesorNo && (
                <Grid item md={1} xs={6} className={classes.TypographyDate}>
                  <TextField
                    label="Marks"
                    margin="dense"
                    className={classes.textField}
                    required
                    type="number"
                    value={negativeMarks || ''}
                    onChange={(e) => setNegativeMarks(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                )}
              </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item md={10} xs={12} />
      <Grid item md={2} xs={12}>
        <Button fullWidth color="primary" variant="contained" onClick={() => submitSynonmemos(synonymsQuestion, marksFortheQuestion, negativeMarks, negativeYesorNo, QuestionType, synonymsArray, synonymsAnswerArray)}>Save</Button>
      </Grid>
    </Grid>
  );
};
SynonymsMcq.defaultProps = {
  negativeMarksStatus: false,
  actiualMarks: false,
  submitSynonmemos: null,
  synonymsInfoData: null,
};
SynonymsMcq.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  negativeMarksStatus: PropTypes.bool,
  actiualMarks: PropTypes.bool,
  submitSynonmemos: PropTypes.func,
  synonymsInfoData: PropTypes.instanceOf(Object),
};
export default withStyles(styles)(SynonymsMcq);
