/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Grid,
  Select,
  Typography,
  Paper,
  withStyles,
  Divider,
  Button,
  Table,
  TableBody,
  MenuItem,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Fade from '@material-ui/core/Fade';
import ReactHtmlParser from 'react-html-parser';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import styles from './AddBlogs.style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import urls from '../../../url';
import TinyTextEditor from '../../../hoc/tinyMce/tinyTextEditor';
import useFetch from '../../../hoc/useFetch';

const AddBlogs = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [blogLists, setBlogLists] = useState();
  const [blogCategory, setBlogCategory] = useState('');
  const [blogImage, setBlogImage] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [topic, setTopic] = useState(0);
  const [open, setOpen] = useState(false);
  const [LoaderOnn, setLoaderOnn] = useState(false);
  const [edit, setEdit] = useState(false);
  const [blogId, setBlogId] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const alert = useAlert();

  const categoryListMenu = [
    { id: 1, name: 'Enlighten' },
    { id: 2, name: 'Engage' },
    { id: 3, name: 'Empower' },

  ];

  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)

  useEffect(() => {
    // module = localStorage.getItem('Notification')	
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Blogs')	
      },
    }); 
    
  },[])


  
  function handleOpen(data) {
    console.log(data, "incoming data to update");
    // id, categoryid, blogtitleInfo, blogcontentInfo, blogheaderimageInfo, 
    //data.id, data.category.id, data.blog_title, data.blog_content, data.blog_header_image, 
    setEdit(true);
    setBlogId(data.id);
    setBlogImage(data.blog_header_image);
    setBlogContent(data.blog_content);
    if(data.category !== null){
      setBlogCategory(data.category.id,);
    }
    setBlogTitle(data.blog_title);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setBlogId('');
    setBlogImage('');
    setBlogContent('');
    setBlogCategory('');
    setBlogTitle('');
  };

  function handleOpenAddBlog() {
    setEdit(false);
    setBlogImage();
    setBlogContent();
    setBlogCategory();
    setBlogTitle();
    setOpen(true);
  }

  let loader = null;
  if (LoaderOnn || modulePermissionLoading) {
    loader = <Loader open />;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(10);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function getData() {
    setLoaderOnn(true);
    fetch(`${urls.createListBlog}?page_size=${rowsPerPage || 10}&page=${page + 1}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Blogs')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoaderOnn(false);
          alert.success('Data Received');
          return res.json();
        }
        if (res.status !== 200) {
          setLoaderOnn(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      }).then((data) => {
        setBlogLists(data);
      });
  }

  useEffect(() => {
    if (auth) {
      getData();
    }
  }, [auth.personal_info.token, page, rowsPerPage]);

  function functionToHandleImage(data) {
    if ((data && data.type === 'image/jpeg') || (data && data.type === 'image/png')) {
      setBlogImage(data);
    } else {
      alert.warning('Upload Image in JPEG && PNG format Only');
    }
  }

  function functionToUpdateBlog() {
    if (!blogCategory) {
      alert.warning('Select Blog Category');
      return;
    }
    if (!blogImage) {
      alert.warning('upload Blog Image');
      return;
    } if (!blogTitle) {
      alert.warning('Enter Blog Title');
      return;
    }
    const toppic = Math.round(Math.random()) + 201 + Math.round(Math.random());
    const formData = new FormData();
    setTopic(toppic);

    if (typeof (blogImage) === 'object') {
      formData.append('blog_header_image', blogImage);
      formData.append('topic', topic);
    }
    formData.append('blog_title', blogTitle);
    formData.append('category', blogCategory);
    formData.append('blog_content', blogContent);

    setLoaderOnn(true);
    fetch(`${urls.updateBlogApi}${blogId}/update_delete_blogs/`, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Blogs')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoaderOnn(false);
          getData();
          handleClose();
          alert.success('Blog Successfully Updated');
          return res.json();
        }
        if (res.status !== 200) {
          setLoaderOnn(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      });
  }
  function functionToDeleteBlog(blogIdInfo) {
    setLoaderOnn(true);
    fetch(`${urls.updateBlogApi}${blogIdInfo}/update_delete_blogs/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Blogs')
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoaderOnn(false);
          getData();
          handleClose();
          alert.success('Blog Deleted Successfully');
          return res.json();
        }
        if (res.status !== 200) {
          setLoaderOnn(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      });
  }
  const addBlogFunction = () => {
    if (!blogCategory) {
      alert.warning('Select Blog Category');
      return;
    }
    if (!blogImage) {
      alert.warning('upload Blog Image');
      return;
    } if (!blogTitle) {
      alert.warning('Enter Blog Title');
      return;
    }
    const toppic = Math.round(Math.random()) + 201 + Math.round(Math.random());
    const formData = new FormData();
    setTopic(toppic);

    if (typeof (blogImage) === 'object') {
      formData.append('blog_header_image', blogImage);
      formData.append('topic', topic);
    }
    formData.append('blog_title', blogTitle);
    formData.append('category', blogCategory);
    formData.append('blog_content', blogContent);

    setLoaderOnn(true);
    fetch(urls.createListBlog, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Blogs')
      },
    })
      .then((res) => {
        if (res.status === 201) {
          setLoaderOnn(false);
          getData();
          handleClose();
          alert.success('Blog Successfully Created');
          return res.json();
        }
        if (res.status !== 201) {
          setLoaderOnn(false);
          alert.warning('something went wrong please try again ');
        }
        return 0;
      });
  };

  function functionTOOpenModal() {
    let view = null;
    view = (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableEnforceFocus
        >
          <Fade in={open}>
            <Paper className={classes.paperr}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Typography variant="h4" style={{ float: 'left' }}>{edit ? 'Edit Blog' : 'Add Blog'}</Typography>
                  <IconButton onClick={handleClose} style={{ float: 'right' }}><CloseIcon /></IconButton>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography>
                    Select Category &nbsp;
                    <b style={{ color: 'red' }}>*</b>
                  </Typography>
                  <Select
                    margin="dense"
                    fullWidth
                    value={blogCategory || ''}
                    onChange={(e) => setBlogCategory(e.target.value)}
                    variant="outlined"
                  >
                    {categoryListMenu && categoryListMenu.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">
                    Upload Image &nbsp;
                    <b style={{ color: 'red' }}>*</b>
                  </Typography>
                  <input
                    style={{ display: 'none' }}
                    className={classes.fileUpload}
                    id="outlined-button-filee"
                    type="file"
                    onChange={(e) => functionToHandleImage(e.target.files[0])}
                  />
                  <label htmlFor="outlined-button-filee">
                    <Button
                      variant="outlined"
                      component="span"
                      size="small"
                      className={classes.fileUpload}
                      startIcon={<CloudUploadIcon />}
                    >
                      Browse
                    </Button>
                    {' '}
                        &nbsp; &nbsp;
                    {blogImage && typeof (blogImage) === 'object' && blogImage.name}
                  </label>
                  {blogImage && typeof (blogImage) === 'string'
                  && <a href={blogImage} target="_blank" rel="noopener noreferrer">Click To View</a>}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">
                    Title  &nbsp;
                    <b style={{ color: 'red' }}>*</b>
                  </Typography>
                  <TinyTextEditor
                    id="title"
                    initialValue={blogTitle || ''}
                    value={blogTitle || ''}
                    onChange={(content) => setBlogTitle(content)}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">Description : </Typography>
                  <TinyTextEditor
                    id="Description"
                    initialValue={blogContent || ''}
                    value={blogContent || ''}
                    onChange={(content) => setBlogContent(content)}
                  />
                </Grid>
                <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => (edit === true && functionToUpdateBlog()) || (edit === false && addBlogFunction())}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        </Modal>
      </>
    );
    return view;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={6} xs={6}>
          <Typography variant="h4">Add Blogs</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          {modulePermission && modulePermission.response.can_add ? 
          
          <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={() => handleOpenAddBlog()}>Add Blog+</Button>
        :null}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        {blogLists && blogLists.results && blogLists.results.length === 0
        && (
        <Grid item md={12} xs={12}>
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}> No Blogs are Added please Add Blogs While Clicking Add Blog + Button</Typography>
        </Grid>
        )}
        {blogLists && blogLists.results && blogLists.results.length !== 0
        && (
        <Grid item md={12} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">S.No</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="left">Blog Title</TableCell>
                <TableCell align="left">Edit</TableCell>
                <TableCell align="left">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogLists && blogLists.results && blogLists.results.length > 0
            && blogLists.results.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="left">
                  {i + 1}
                </TableCell>
                <TableCell align="left">
                  {data.category && data.category.category_title}
                </TableCell>
                <TableCell align="left">
                  {ReactHtmlParser(data.blog_title)}
                </TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_update ? 
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => handleOpen(data)}
                  >
                    EDIT
                  </Button>
                    :null}
                </TableCell>
                <TableCell align="left">
                  {modulePermission && modulePermission.response.can_delete ?   
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => functionToDeleteBlog(data.id)}
                  >
                    DELETE
                  </Button>
                :null}
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+blogLists.total_pages}`}
                  rowsPerPageOptions={[5, 10, 20, 30]}
                  count={+blogLists.count}
                  rowsPerPage={rowsPerPage || 10}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(blogLists.total_pages - 1)}
                    disabled={page === +blogLists.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid item md={12} xs={12} style={{ display: 'none' }}>
            {/* <TinyTextEditor
              id="courseDescIdsdkfjlfk"
            /> */}
          </Grid>
        </Grid>
        )}
        {functionTOOpenModal()}
      </Grid>
      {loader}
    </>
  );
};
AddBlogs.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AddBlogs);
