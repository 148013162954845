/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  // Modal,
  // Fade,
  // Backdrop,
  IconButton,
  // TextField,
  TableFooter,
  TablePagination,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import TinyTextEditor from '../../../../hoc/tinyMce/tinyTextEditor';
import styles from './dashboard.style';
import useFetch from '../../../../hoc/useFetch';
import { useAlert } from '../../../../hoc/alert/alert';
import Loader from '../../../../hoc/loader';
import urls from '../../../../url';

const TeacherReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [roleId, setRoleId] = useState('');
  const [bottomHRef, setButtonRef] = useState('');
//   const [searchClicked, setSearchClicked] = useState(false);

  const [branchId,setBranchId]= useState('');
  const alert = useAlert();

  const {
    data: detailedVisualReport,
    isLoading: detailedVisualReportLoading,
    doFetch: fetchDetailedVisualReport,
  } = useFetch([]);

  const [trainingTypeList] = useState([
    {
      id: '1',
      name: 'Subject Training',
    },
    {
      id: '2',
      name: 'Self Driven',
    },
    {
      id: '3',
      name: 'Trainer Driven',
    },
  ]);

  const [trainingType, setTrainingType] = useState('');
 

  const getModuleId=()=>{
    if(localStorage.getItem('Branch_Wise_Report')!=="null"){
      return localStorage.getItem('Branch_Wise_Report')
    }else if(localStorage.getItem('Branch_level_detailed_report')!=="null"){
      return localStorage.getItem('Branch_level_detailed_report')
    }else if(localStorage.getItem('Reports')!=="null"){
      return localStorage.getItem('Reports')
    }
  }

  const userId=auth.academic_profile.user.id;
  useEffect(() => {
    //   console.log(userId);
    fetchDetailedVisualReport({
      url: `${urls.getDetailedVisualReport}?user=${userId}&role=9&course_type=1`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: getModuleId()
      },
    });
  },[])

  let loader = null;
  if (detailedVisualReportLoading) {
    loader = <Loader open />;
  }


  const thirdTableData = () => {
    let thirdTable = null;
    thirdTable = (
      <>
        <Paper className={classes.paper} style={{marginTop:'5%'}}>
          <Typography variant="div">
            Name:
          </Typography>
          <Typography variant="div">
            {detailedVisualReport ? detailedVisualReport.name : ''}
          </Typography>
          <div />
          <Typography variant="div">
            ERP:
          </Typography>
          <Typography variant="div">
            {detailedVisualReport ? detailedVisualReport.erp : ''}
          </Typography>
          {
            detailedVisualReport && detailedVisualReport.result && detailedVisualReport.result.length !== 0 && detailedVisualReport.result.map((obj, i) => (
              <Table
                key={i}
                className={classes.table}
                size="small"
                aria-label="spanning table"
                style={{ border: '1.5px solid' }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontWeight: 'bold', border: '1.5px solid' }}
                      colSpan={obj.chapters.length !== 0 && obj.chapters.length !== 1 ? obj.chapters.length : Object.values(obj.chapters && obj.chapters.length !== 0 && obj.chapters[0]) && Object.values(obj.chapters && obj.chapters.length !== 0 && obj.chapters[0]).length !== 0 && Object.values(obj.chapters && obj.chapters.length !== 0 && obj.chapters[0])[0].length}
                    >
                      {obj.name}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      obj.chapters.map((chapter) => (
                        <>
                          {Object.values(chapter)[0].map((res) => (
                            <>
                              {
                                Object.entries(res).map(([key, value]) => {
                                  if (key.toString().startsWith('title')) {
                                    return (
                                      <TableCell>
                                        {`${Object.values(chapter)[1]}`}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })
                              }
                            </>
                          ))}
                        </>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {
                      obj.chapters.map((chapter) => (
                        <>
                          {Object.values(chapter)[0].map((res) => (
                            <>
                              {
                                Object.entries(res).map(([key, value]) => {
                                  if (key.toString().startsWith('title')) {
                                    return (
                                      <TableCell>
                                        {value !== '' ? `${value}` : `Quiz ${parseInt(key.split('title')[1], 10) + 1}`}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })
                              }
                            </>
                          ))}
                        </>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {
                      obj.chapters.map((chapter) => (
                        <>
                          {Object.values(chapter)[0].map((res) => (
                            <>
                              {
                                Object.entries(res).map(([key, value]) => {
                                  if (key.toString().startsWith('quiz')) {
                                    return (
                                      <TableCell>
                                        {value}
                                      </TableCell>
                                    );
                                  }
                                  return null;
                                })
                              }
                            </>
                          ))}
                        </>
                      ))
                    }
                  </TableRow>
                </TableBody>
              </Table>
            ))
          }
        </Paper>
      </>
    );
    return thirdTable;
  };

  useEffect(() => {
    setButtonRef([
      {
        csv: `${urls.getBranchWiseVisualReport}?course_type=${trainingType}&role=${roleId}&branch=${branchId}&csv=True`,
      },
    ]);
  }, [branchId, roleId, trainingType]);


  return (
    <>
      <div>
          <Typography variant="h4">Teacher Report</Typography>
      </div>
      {thirdTableData()}
      {loader}
    </>
  );
};

TeacherReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TeacherReport);
