/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
  //   Table,
  //   TableBody,
  //   TableCell,
  //   TableHead,
  //   TableRow,
  Paper,
  //   Modal,
  //   Fade,
  //   Backdrop,
  //   IconButton,
  TextField,
  //   TableFooter,
  //   TablePagination,
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import styles from "./consolidatedReport.style";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import urls from "../../../url";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Autocomplete from '@material-ui/lab/Autocomplete';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let status;
let link1;
let link2;
let link3;
let link4;
let finalUrl;
const ConsolidatedReport = ({ classes }) => {
  // const classes = useStyles();
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [checkLoader, setCheckLoader] = useState(false);
  //   const [open, setOpen] = React.useState(false);
  //   const [rowsPerPage, setRowsPerPage] = React.useState(null);
  //   const [page, setPage] = React.useState(0);

  const [role, setRole] = useState([]);
  const [branch, setBranch] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bottomHRef, setBottomHRef] = useState("");
  const alert = useAlert();
  const [trainingType, setTrainingType] = useState("");
  const [permission, setPermission] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [page, setPage] = useState(0);
  const [indexId, setIndexId] = useState('');
  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [isSingleAttempted, setIsSingleAttempted] = useState(false);

  const [trainingTypeList] = useState([
    {
      id: "1",
      name: "Induction Training",
    },
    {
      id: "2",
      name: "Self Training",
    },
    {
      id: "4",
      name: "Subject Training",
    },
    // {
    //   id: '3',
    //   name: 'Trainer Driven',
    // },
  ]);
  // const verifyEmail = auth
  // && auth.academic_profile
  // && auth.academic_profile.user.email;

  // const verify=verifyEmail.includes("@orchids.edu.in")
  const loginData = JSON.parse(localStorage.getItem("UserLogin"));
  var verify = loginData.role_permission.is_orchids;

  const {
    data: roleList,
    isLoading: roleListLoading,
    doFetch: fetchRoles,
  } = useFetch(null);

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranches,
  } = useFetch(null);

  const {
    data: GradeList,
    isLoading: gradeListLoading,
    doFetch: fetchGradeList
  } = useFetch([]);

  const {
    data: subjectList,
    isLoading: subjectLoading,
    doFetch: fetchSubjectList
  } = useFetch([])

  const {
    data: volumeList,
    isLoading: volumeListLoading,
    doFetch: fetchVolumeList
  } = useFetch([])

  useEffect(() => {
    link1 = `${urls.getRoleRegistration}?search=orchids&user_type=USER`;
    link2 = `${urls.getRoleRegistration}?search=other&user_type=USER`;
    link3 = `${urls.getRoleRegistration}?search=orchids&user_type=ADMIN`;
    link4 = `${urls.getRoleRegistration}?search=other&user_type=ADMIN`;

    finalUrl =
      localStorage.getItem("userType") === "USER"
        ? verify
          ? link1
          : link2
        : localStorage.getItem("userType") === "ADMIN"
          ? verify
            ? link3
            : link4
          : null;
    fetchRoles({
      url: finalUrl,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Consolidated_Report"),
      },
    });
    fetchBranches({
      url: urls.getBranchNamesApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Consolidated_Report"),
      },
    });
  }, [fetchRoles, fetchBranches, auth.personal_info.token]);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const sendEmail = () => {
    if (!branch || branch.length === 0) {
      alert.warning("Select Branch");
      return;
    }
    if (!role || role.length === 0) {
      alert.warning("Select Role");
      return;
    }
    if (!trainingType) {
      alert.warning("Select Training Type");
      return;
    }
    if (
      !email ||
      !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
        email
      )
    ) {
      alert.warning("Enter a valid email");
      return;
    }
    const roleArr = [];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList &&
        roleList.response.filter((item) => item.role_name === role[i])[0]
          .role_id
      );
    }

    const branchArr = [];
    for (let i = 0; i < branch.length; i += 1) {
      branchArr.push(
        branchList &&
        branchList.filter((item) => item.branch_name === branch[i])[0].id
      );
    }

    const gradeArr = [];
    for (let i = 0; i < grade.length; i += 1) {
      gradeArr.push(
        GradeList &&
        GradeList.filter((item) => item.type_name === grade[i])[0].id
      );
    }

    const subjectArr = [];
    for (let i = 0; i < subject.length; i += 1) {
      subjectArr.push(
        subjectList &&
        subjectList.data.filter((item) => item.subject_name === subject[i])[0].id
      );
    }

    async function loading() {
      setCheckLoader(true);
      const response = await fetch(
        `${urls.consolidatedReportSendMailApi}?branch=${[branchArr]}&role=${[
          roleArr,
        ]}&training_type=${trainingType}&email=${email}&grade=${gradeArr}&subject=${subjectArr}&start_date=${startDate}&end_date=${endDate}&download=False&first_attempt=${isSingleAttempted}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
            module: localStorage.getItem("Consolidated_Report"),
          },
        }
      );
      const res = await response.json();
      // console.log("s",response.status)
      status = response.status;
      return res;
    }
    loading()
      .then(() => {
        // console.log("status",status)
        if (status === 200) {
          setCheckLoader(false);
          alert.success(`Your consolidated report has been sent to ${email}.`);
          // eslint-disable-next-line no-console
        }
        if (status === 404) {
          setCheckLoader(false);
          alert.warning(`Data not found on ${email}.`);
        }
        if (status === 400) {
          setCheckLoader(false);
          alert.warning(`Bad Request`);
        }
      })
      .catch((error) => {
        setCheckLoader(false);
        alert.warning(`Error:${error}`);
      });

    setBranch([]);
    setEmail("");
    setRole([]);
    setTrainingType("");
    setGrade([]);
    setSubject([]);
  };

  let loader = null;
  if (roleListLoading || branchListLoading || checkLoader || volumeListLoading || subjectLoading || gradeListLoading) {
    loader = <Loader open />;
  }

  const isAllSelected =
    roleList?.length > 0 && role?.length === roleList?.length;

  const handleChange = (event) => {
    const value = event.target.value;
    console.log(value[value.length - 1], "handleChange");
    const roleeList = roleList.response.map((item) => item?.role_name);
    if (value[value.length - 1] === "all") {
      setRole(role?.length === roleList?.length ? [] : roleeList);
      return;
    }
    setRole(value);
  };

  const isAllBranchSelected =
    branchList?.length > 0 && branch?.length === branchList?.length;
  const handleBranchChange = (event) => {
    const value = event.target.value;
    // console.log(value[value.length - 1], "handleChange");
    const branchesList = branchList.map((item) => item?.branch_name);
    if (value[value.length - 1] === "all") {
      setBranch(branch?.length === branchList?.length ? [] : branchesList);
      return;
    }
    setBranch(value);
  };

  const handleStartDateChange = (val) => {
    // console.log(array)
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
    // console.log(array)
  };

  useEffect(() => {
    if (!branch || branch.length === 0) {
      // alert.warning("Select Branch");
      return;
    }
    if (!role || role.length === 0) {
      // alert.warning("Select Role");
      return;
    }
    if (!trainingType) {
      // alert.warning("Select Training Type");
      return;
    }
    const roleArr = [];
    for (let i = 0; i < role.length; i += 1) {
      roleArr.push(
        roleList &&
        roleList.response.filter((item) => item.role_name === role[i])[0]
          .role_id
      );
    }

    const branchArr = [];
    for (let i = 0; i < branch.length; i += 1) {
      branchArr.push(
        branchList &&
        branchList.filter((item) => item.branch_name === branch[i])[0].id
      );
    }

    const gradeArr = [];
    for (let i = 0; i < grade.length; i += 1) {
      gradeArr.push(
        GradeList &&
        GradeList.filter((item) => item.type_name === grade[i])[0].id
      );
    }

    const subjectArr = [];
    for (let i = 0; i < subject.length; i += 1) {
      subjectArr.push(
        subjectList &&
        subjectList.data.filter((item) => item.subject_name === subject[i])[0].id
      );
    }
    setBottomHRef([
      {
        csv: `${urls.consolidatedReportSendMailApi}?branch=${[branchArr]}&role=${[
          roleArr,
        ]}&training_type=${trainingType}&email=${email}&grade=${gradeArr}&subject=${subjectArr}&start_date=${startDate}&end_date=${endDate}&download=True&first_attempt=${isSingleAttempted}&Authorization=Bearer ${auth.personal_info.token}&module=${localStorage.getItem('Consolidated_Report')}&export_type=csv`
      },
    ]);
  }, [branch, role, trainingType, grade, subject, isSingleAttempted])

  useEffect(() => {
    fetchGradeList({
      url: `${urls.grades}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Consolidated_Report')
      },
    });
  }, [auth, page, rowsPerPage, indexId]);


  useEffect(() => {
    getPermissonData(localStorage.getItem("Consolidated_Report"));
  }, []);

  // For Permissions
  function getPermissonData(id) {
    axios
      .get(urls.getPermissons, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: id,
        },
      })
      .then((response) => {
        setPermission(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const isAllGradeSelected = GradeList?.length > 0 && grade?.length === GradeList?.length

  const handleGradeChange = (event) => {
    const value = event.target.value;
    const gradeLists = GradeList.map((item) => item?.type_name);
    if (value[value.length - 1] === "all") {
      setGrade(grade?.length === GradeList?.length ? [] : gradeLists);
      return;
    }
    setGrade(value);
    setSubject([]);
  }

  useEffect(() => {
    if (grade.length > 0) {
      const gradeArr = [];
      for (let i = 0; i < grade.length; i += 1) {
        gradeArr.push(
          GradeList &&
          GradeList.filter((item) => item.type_name === grade[i])[0].id
        );
      }
      fetchSubjectList({
        url: `${urls.getMultipleSubjectsByGrade}?grade_id=${gradeArr}`,
        method: `GET`,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem('Consolidated_Report')
        },
      })
    }
  }, [grade])
  const isAllSubjectsSelected = subjectList.data?.length > 0 && subject?.length === subjectList.data?.length

  const handleSubjectChange = (event) => {
    const value = event.target.value;
    const subjectLists = subjectList.data.map((item) => item?.subject_name);
    if (value[value.length - 1] === "all") {
      setSubject(grade?.length === subjectList?.length ? [] : subjectLists);
      return;
    }
    setSubject(value);
  }

  function handleClearAll() {
    setBranch([]);
    setEmail("");
    setRole([]);
    setTrainingType("");
    setGrade([]);
    setSubject([]);
  }

  function handleAttemptsCheck() {
    setIsSingleAttempted(!isSingleAttempted);
  }


  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableMargin}>
          <Typography variant="h4">Consolidated Report</Typography>
          <Divider className={classes.divider} />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Typography>
                  Select Branch
                  <b style={{ color: "red" }}>*</b>
                </Typography>

                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={branch || []}
                    // onChange={(e) => setSchool(e.target.value)}
                    onChange={handleBranchChange}
                    // onChange={(e) => handleSelectALlSchool(e)}
                    multiple
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black", width: '400px' }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllBranchSelected}
                          indeterminate={
                            branch.length > 0 &&
                            branch.length < branchList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {branchList &&
                      branchList.length !== 0 &&
                      branchList.map((data) => (
                        <MenuItem
                          value={data?.branch_name}
                          key={data?.id}
                          name={data?.branch_name}
                        >
                          <Checkbox
                            checked={branch.indexOf(data?.branch_name) > -1}
                          />
                          <ListItemText primary={data?.branch_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>
                  Select Role
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={role || []}
                    onChange={handleChange}
                    multiple
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black", width: '400px' }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            role.length > 0 && role.length < roleList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {roleList &&
                      roleList.length !== 0 &&
                      roleList.response.map((data) => (
                        <MenuItem
                          value={data.role_name}
                          key={data.role_id}
                          name={data.role_name}
                        >
                          <Checkbox
                            checked={role.indexOf(data.role_name) > -1}
                          />
                          <ListItemText primary={data.role_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>
                  Select Grade
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={grade || []}
                    onChange={handleGradeChange}
                    multiple
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black", width: '400px' }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllGradeSelected}
                          indeterminate={
                            role.length > 0 && role.length < GradeList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {GradeList &&
                      GradeList.length !== 0 &&
                      GradeList.map((data) => (
                        <MenuItem
                          value={data.type_name}
                          key={data.id}
                          name={data.type_name}
                        >
                          <Checkbox
                            checked={grade.indexOf(data.type_name) > -1}
                          />
                          <ListItemText primary={data.type_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>
                  Select Subject
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={subject || []}
                    onChange={handleSubjectChange}
                    multiple
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black", width: '400px' }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSubjectsSelected}
                          indeterminate={
                            subject.length > 0 && subject.length < subjectList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {subjectList &&
                      subjectList.length !== 0 &&
                      subjectList.data.map((data) => (
                        <MenuItem
                          value={data.subject_name}
                          key={data.id}
                          name={data.subject_name}
                        >
                          <Checkbox
                            checked={subject.indexOf(data.subject_name) > -1}
                          />
                          <ListItemText primary={data.subject_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>
                  Select Training
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    margin="dense"
                    fullWidth
                    value={trainingType || ""}
                    onChange={(e) => setTrainingType(e.target.value)}
                    className={classes.textField}
                    variant="outlined"
                    style={{ color: "black", width: '400px' }}
                  >
                    {trainingTypeList &&
                      trainingTypeList.length !== 0 &&
                      trainingTypeList.map((data, i) =>
                        verify || data.id === "4" ? (
                          <MenuItem
                            value={data.id}
                            key={data.id}
                            name={data.name}
                          >
                            {data.id ? data.name : ""}
                          </MenuItem>
                        ) : (
                          ""
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography>Start Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleStartDateChange(e.target.value)}
                  type="date"
                  value={startDate || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography>End Date</Typography>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  required
                  fullWidth
                  onChange={(e) => handleEndDateChange(e.target.value)}
                  type="date"
                  value={endDate || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>
                  Enter Email Address
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <TextField
                  label=""
                  margin="dense"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                />
                {email &&
                  !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                    email
                  ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "red" }}
                  >
                    Invalid Email Address
                  </FormHelperText>
                ) : null}
                {email &&
                  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                    email
                  ) ? (
                  <FormHelperText
                    id="component-error-text"
                    style={{ color: "green" }}
                  >
                    Valid Email Address
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item md={1} xs={12}>
                <Button
                  style={{ marginTop: "33px" }}
                  className={classes.updateButton}
                  color="primary"
                  variant="contained"
                  disabled={(branch && role && trainingType && grade && subject && startDate && endDate && email) ? false : true}
                  onClick={() => sendEmail()}
                >
                  Send
                </Button>
              </Grid>
              <Grid md={2} xs={12}>
                <FormControlLabel
                  className={classes.textField}
                  style={{ marginTop: "33px" }}
                  control={
                    <Checkbox
                      checked={isSingleAttempted}
                      onChange={handleAttemptsCheck}
                    />}
                  label="First Attempt Report"
                />
              </Grid>
              <Grid item md={1} xs={12}>
                {/* {permission.can_export ? ( */}
                <Button
                  style={{ marginTop: "33px" }}
                  className={classes.updateButton}
                  variant="contained"
                  color="primary"
                  href={bottomHRef && bottomHRef[0].csv}
                  disabled={(branch && role && trainingType && grade && subject && startDate && endDate) ? false : true}
                >
                  Download
                </Button>
                {/* )
                  :
                  null
                } */}
              </Grid>
              <Grid item md={1} xs={12}>
                {/* {permission.can_export ? ( */}
                <Button
                  style={{ marginTop: "33px" }}
                  className={classes.updateButton}
                  variant="contained"
                  color="primary"
                  disabled={(branch.length > 0 || role.length > 0 || trainingType || grade.length > 0 || subject.length > 0 || startDate || endDate) ? false : true}
                  onClick={handleClearAll}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
      {loader}
    </>
  );
};

ConsolidatedReport.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ConsolidatedReport);
