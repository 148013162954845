/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withStyles, Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../../../hoc/loader";
import styles from "./ClassInitiate.style";
import { useAlert } from "../../../hoc/alert/alert";
import urls from "../../../url";

function ClassInitiate({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [initiatedCourses, setInitiatedCourses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selected, setSelected] = useState([]);
  const [classRoomName, setclassRoomName] = useState("");
  const [phoneEmail, setPhoneEmail] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [correctOtp, setCorrectOtp] = useState(false);
  const [loading, setLoader] = useState(false);

  const [initiatedCoursesVal, setInitiatedCoursesVal] = useState("");
  const [initiatedCoursesValName, setInitiatedCoursesValName] = useState(0);
  const [branchChange, setBranchChange] = useState([]);
  const [classAndBranch, setClassandBranch] = useState("");
  const [attendenceMessge, setAttedenceMessage] = useState(false);
  const alert = useAlert();

  async function getData(url) {
    setLoader(true);
    const dataResult = fetch(url, {
      method: "GET",
      cache: "reload",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Initiate_Class"),
      },
    });
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    return finalData;
  }
  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }
  const assignTeachersSubmit = async () => {
    if (selected.length === 0 || !initiatedCoursesVal || !classRoomName) {
      alert.warning("Fill all Fields");
      return 0;
    }
    const data = {
      teachers: selected,
      class_name: classRoomName,
      course: initiatedCoursesValName,
      class_details: initiatedCoursesVal,
    };
    setLoader(true);
    const response = await fetch(urls.createTeacherAttendance, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Initiate_Class"),
      },
    });
    const getDataiNFO = await response.json();
    setSelected([]);
    setAttedenceMessage(true);
    alert.success("Attendence Marked Successfully");
    setLoader(false);
    setClassandBranch([]);
    // setBranchChange([]);
    return getDataiNFO;
  };

  useEffect(() => {
    // getData(
    //   `${urls.principalCompletedViewCourse}?course_type=${localStorage.getItem(
    //     "principalCourseType"
    //   )}`
    // ).then(data => {
    //   setCourses(data.results);
    // });
    getData(`${urls.classInitiationFormSubmit}`).then((data) => {
      setInitiatedCourses(data);
    });
    getData(`${urls.getBranchNamesApi}`).then((data) => {
      setBranches(data);
    });
  }, []);

  const handleChange = (event, name) => {
    setInitiatedCoursesVal(event.target.value);
    setInitiatedCoursesValName(name);
  };

  const handleBranchChange = (event) => {
    setAttedenceMessage(false);
    setBranchChange(event.target.value);
    if (!initiatedCoursesVal) {
      alert.warning("select Course");
    }
    if (event.target.value.length !== 0 && initiatedCoursesVal) {
      getData(
        `${urls.classInitiateAttendanceApi}?branch_id=${event.target.value}&class_id=${initiatedCoursesVal}`
      ).then((data) => {
        setClassandBranch(data);
      });
    }
  };

  // useEffect(() => {
  //   getData(
  //     `${urls.classInitiateAttendanceApi}?branch_id=${branchChange}`,
  //   ).then((data) => {
  //     setClassandBranch(data);
  //   });
  // }, [page, rowsPerPage]);

  const sendOtpFunc = async () => {
    const regexMobile = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    setLoader(true);
    const dataResult = fetch(
      `${urls.otpSending}${
        phoneEmail.match(regexMobile)
          ? `?mobile_number=${phoneEmail}`
          : phoneEmail.match(regexEmail)
          ? `?email=${phoneEmail}`
          : ""
      }`,
      {
        method: "GET",
        cache: "reload",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Initiate_Class"),
        },
      }
    );
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    return finalData;
  };

  const verifyOtpFunc = async () => {
    setLoader(true);
    fetch(
      `${urls.otpVerification}?mobile_number=${phoneEmail}&otp=${verifyOtp}`,
      {
        method: "GET",
        cache: "reload",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("Initiate_Class"),
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        alert.success("Successfully");
        setCorrectOtp(true);
        return res.json();
      }
      if (res.status !== 200) {
        setLoader(false);
        alert.error("Enter Correct Otp");
        return res.json();
      }
      return 0;
    });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const courseRedirect = () => {
    if (!initiatedCoursesVal) {
      alert.warning("select course");
      return;
    }
    localStorage.setItem("coursesType", "trainer");
    sessionStorage.setItem("classId", initiatedCoursesVal);
    window.location = "/modules";
  };

  function handleSelctAll() {
    if (classAndBranch.length === selected.length) {
      setSelected([]);
    } else {
      const array = [];
      let i = 0;
      for (i = 0; i < (classAndBranch && classAndBranch.length); i += 1) {
        array.push(classAndBranch[i].user.id);
      }
      setSelected(array);
    }
  }

  // const setClassTitleId = (id) => {
  //   console.log(id);
  //   console.log('abcd');
  //   sessionStorage.setItem('class_title', id);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <>
      <div className={classes.tdMainDiv}>
        <TextField
          id="standard-full-width"
          label="Class Room Name"
          className={classes.textField}
          placeholder="Enter the Class Room Name"
          fullWidth
          value={classRoomName || ""}
          onChange={(e) => setclassRoomName(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div style={{ marginTop: "1rem" }}>
          <InputLabel shrink id="demo-mutiple-name-label">
            Select Course
          </InputLabel>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            value={initiatedCoursesVal}
            onChange={(event, actionMeta) =>
              handleChange(event, actionMeta.props.name)
            }
            input={<Input />}
            className={classes.selectEmpty}
            style={{ width: 500, paddingBottom: "3px" }}
          >
            {initiatedCourses &&
              initiatedCourses.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  name={item.course.id}
                  id={item.course.id + item.id}
                >
                  {item.class_title}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {item.course.course_name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gridGap: "1rem",
            width: 500,
          }}
        >
          <TextField
            id="standard-full-width"
            label="Email"
            placeholder="Enter the Email"
            fullWidth
            onChange={(e) => setPhoneEmail(e.target.value)}
            value={phoneEmail}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ height: 40, marginTop: 25 }}
            disabled={!phoneEmail}
            onClick={sendOtpFunc}
          >
            Send OTP
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gridGap: "1rem",
            width: 500,
          }}
        >
          <TextField
            id="standard-full-width"
            label="Enter OTP"
            value={verifyOtp}
            onChange={(e) => setVerifyOtp(e.target.value)}
            placeholder="Enter OTP"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ height: 40, marginTop: 25 }}
            disabled={!(phoneEmail && verifyOtp)}
            onClick={verifyOtpFunc}
          >
            Verify OTP
          </Button>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <InputLabel shrink id="demo-mutiple-name-label">
            Select Branches
          </InputLabel>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            value={branchChange}
            onChange={handleBranchChange}
            input={<Input />}
            multiple
            className={classes.selectEmpty}
            style={{ width: 500, paddingBottom: "3px" }}
          >
            {branches &&
              branches.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.branch_name}
                </MenuItem>
              ))}
          </Select>
        </div>
        {attendenceMessge && (
          <div className={classes.tablePart}>
            <Typography
              variant="h5"
              style={{ color: "blue", textAlign: "center" }}
            >
              Attendance Marked Successfully
            </Typography>
          </div>
        )}
        {classAndBranch &&
          classAndBranch.length === 0 &&
          attendenceMessge === false && (
            <div className={classes.tablePart}>
              <Typography
                variant="h5"
                style={{ color: "blue", textAlign: "center" }}
              >
                No Records Found
              </Typography>
            </div>
          )}
        {classAndBranch && classAndBranch.length !== 0 && (
          <div className={classes.tablePart}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Attendanc</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">ERP No</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover onClick={() => handleSelctAll()}>
                  <TableCell align="center">
                    <Checkbox
                      checked={classAndBranch.length === selected.length}
                      onClick={() => handleSelctAll()}
                    />
                  </TableCell>
                  <TableCell align="center">Select All</TableCell>
                  <TableCell align="center" />
                </TableRow>
                {classAndBranch &&
                  classAndBranch.length !== 0 &&
                  classAndBranch.map((item) => {
                    const isItemSelectedId = isSelected(item.user.id);
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, item.user.id)}
                        role="checkbox"
                        tabIndex={-1}
                        key={item.user.first_name}
                        selected={isItemSelectedId}
                      >
                        <TableCell align="center">
                          {" "}
                          <Checkbox
                            checked={isItemSelectedId}
                            id={item.user.first_name + item.id}
                            key={item.user.first_name + item.id}
                            // inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {item.user.first_name}
                        </TableCell>
                        <TableCell align="center">{item.erp || null}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        )}
        {classAndBranch && classAndBranch.length !== 0 && (
          <button
            type="submit"
            style={{
              backgroundColor: "royalblue",
              padding: "0.5rem 1rem",
              color: "white",
              cursor: "pointer",
              border: "1px solid transparent",
              borderRadius: "4px",
              marginTop: "1rem",
            }}
            onClick={assignTeachersSubmit}
          >
            Attendance
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          type="submit"
          variant="contained"
          style={{ width: 500, height: 40, marginTop: 25 }}
          disabled={correctOtp !== true}
          onClick={courseRedirect}
        >
          Start Course
        </Button>
      </div>

      {loader}
    </>
  );
}
ClassInitiate.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ClassInitiate);
