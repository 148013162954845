/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Button,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Grid,
  Divider,
  Typography,
  Paper,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import dateFns from 'date-fns';
import Loader from '../../../hoc/loader';
import styles from './startOnlineClass.style';
import urls from '../../../url';
import { useAlert } from '../../../hoc/alert/alert';
import UpdateMeetingModel from './updateMeetingModel';
import axios from 'axios';


function StartOnlineClass({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [meetingRecords, setMeetingRecords] = useState('');
  const [loading, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [fulldata, setFulldata] = useState({});
  const [permission, setPermission] = useState([]);

  const alert = useAlert();
  async function getData(url) {
    setLoader(true);
    const dataResult = fetch(url, {
      method: 'GET',
      cache: 'reload',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Start_Class')
      },
    });
    const response = await dataResult;
    const finalData = await response.json();
    setLoader(false);
    return finalData;
  }
  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    getData(`${urls.scheduleOnlineClassApi}`).then((data) => {
      setMeetingRecords(data);
    });
  }, []);

  // For Permissions
  function getPermissonData(id) {
    axios.get(urls.getPermissons,{
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: id
      },
    }).then(response => {
      setPermission(response.data.response)
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getPermissonData(localStorage.getItem('Start_Class'))
  }, [])


  function startMeetingFunction(data) {
    setLoader(true);
    fetch(`${urls.onlineMeetingApi}${data.id}/start_class/`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Start_Class')
      },
    }).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        alert.success('attendence marked successfully');
        if (data.zoom_details && data.zoom_details.start_url) {
          window.open(data.zoom_details && data.zoom_details.start_url, '_blank');
        }
        if (data.class_initiate && data.class_initiate.id) {
          localStorage.setItem('coursesType', 'trainer');
          sessionStorage.setItem('classId', data.class_initiate && data.class_initiate.id);
          window.location = '/modules';
        }
        return res.json();
      }
      if (res.status !== 200) {
        setLoader(false);
        alert.error('Somthing went wrong please try again');
        return res.json();
      }
      return 0;
    });
  }

  function openUpdateMeetingModel(data) {
    setOpen(true);
    setFulldata(data);
  }

  function handleClose(info) {
    setOpen(false);
    setFulldata('');
    if (info === 'success') {
      getData(`${urls.scheduleOnlineClassApi}`).then((data) => {
        setMeetingRecords(data);
      });
    }
  }

  function CancleMeetingFunction(id) {
    setLoader(true);
    fetch(`${urls.updateAndDeleteScheduleOnlineClassApi}${id}/update_class/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        module: localStorage.getItem('Start_Class')
      },
    }).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        // alert.success('Online Class Successfully Cancelled');
        return res.json();
      }
      // if (res.status !== 200) {
      //   setLoader(false);
      //   alert.error('Something went wrong. Please try again');
      //   return res.json();
      // }
      return res;
    }).then((res) => {
      if (res.status_code === 204) {
        alert.success('Online Class Successfully Cancelled');
        getData(`${urls.scheduleOnlineClassApi}`).then((data) => {
          setMeetingRecords(data);
        });
      } else if (res.status_code !== 204) {
        alert.error('Something went wrong. Please try again');
      }
    });
  }

  return (
    <>
      <Typography variant="h4">Start Class</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {meetingRecords && meetingRecords.length === 0
        && <Typography variant="h5" style={{ marginTop: '20px', textAlign: 'center', color: 'blue' }}>No Meeting are Scheduled</Typography>}
          {meetingRecords && meetingRecords.length !== 0
          && (
          <Paper className={classes.paper2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell float="left">S.No</TableCell>
                  <TableCell float="left">Class Name</TableCell>
                  <TableCell float="left">Start Date & Time</TableCell>
                  <TableCell float="left">End Date & Time</TableCell>
                  <TableCell float="left">Start</TableCell>
                  <TableCell float="left">Re schedule</TableCell>
                  <TableCell float="left">Cancel</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetingRecords && meetingRecords.length !== 0
                 && meetingRecords.map((item, index) => {
                   const startDate = item.zoom_details && item.zoom_details.start_time && new Date(item.zoom_details.start_time);
                   const endDate = item.zoom_details && item.zoom_details.start_time && new Date(item.zoom_details.end_time);
                   return (
                     <TableRow key={item.id}>
                       <TableCell float="left">{index + 1}</TableCell>
                       <TableCell float="left">{(item.zoom_details && item.zoom_details.topic) || ''}</TableCell>
                       <TableCell float="left">{(item.zoom_details && item.zoom_details.start_time && startDate.toString().split('G')[0]) || ''}</TableCell>
                       <TableCell float="left">{(item.zoom_details && item.zoom_details.end_time && endDate.toString().split('G')[0]) || ''}</TableCell>
                       <TableCell float="left">
                         
                         <Button
                           style={{
                             backgroundColor: !((dateFns.isSameDay(new Date(), new Date(item.zoom_details.start_time))) && (((new Date().getTime() - new Date(item.zoom_details.start_time).getTime()) >= 0))) ? 'lightgray' : 'green',
                             color: 'white',
                           }}
                           disabled={!((dateFns.isSameDay(new Date(), new Date(item.zoom_details.start_time))) && (((new Date().getTime() - new Date(item.zoom_details.start_time).getTime()) >= 0)))}
                           variant="contained"
                           onClick={() => startMeetingFunction(item)}
                         >
                           Start
                         </Button>
                         
                       </TableCell>
                       <TableCell float="left">
                       {permission.can_update ? 
                         <Button color="primary" variant="contained" onClick={() => openUpdateMeetingModel(item)}>Reschedule</Button>
                         :null}
                       </TableCell>
                       <TableCell float="left">
                         <Button color="secondary" variant="contained" onClick={() => CancleMeetingFunction(item.id)}>Cancel</Button>
                       </TableCell>
                     </TableRow>
                   );
                 })}
              </TableBody>
            </Table>
          </Paper>
          )}
        </Grid>
        <UpdateMeetingModel
          fullData={fulldata}
          handelClose={handleClose}
          open={open}
        />
      </Grid>
      {loader}
    </>
  );
}
StartOnlineClass.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StartOnlineClass);
