/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { Typography, Grid, Divider } from '@material-ui/core';
import Dashboard from '../visual-dashboard/dashboard';
import useFetch from '../../../hoc/useFetch';
import urls from '../../../url';
import Loader from '../../../hoc/loader';
import RegisterUserCount from './reports/registeredUsers';
import ModuleView from './reports/modulesCount';
import TinyTextEditor from '../../../hoc/tinyMce/tinyTextEditor';
import axios from 'axios';

const AdminDashboard = () => {
  const [toggleState, setToggleState] = useState(true);
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const [permissions, setPermissions] = useState([]);

  const {
    data: categoryList,
    isLoading: categoryListLoading,
    doFetch: fetchCategory,
  } = useFetch([]);
  const {
    data: modulePermission,
    isLoading: modulePermissionLoading,
    doFetch: fetchModulePermission,
  } = useFetch(null)

  let loader = null;
  if (
    categoryListLoading
  ) {
    loader = <Loader open />;
  }

  function userRegisterCount() {
    let Path;
    // if ((auth && auth.personal_info.role && auth.personal_info.role === 'Principal') || (auth && auth.personal_info.role && auth.personal_info.role === 'LeadTeacher')) {
    //   Path = `${urls.registerUsersReport}?type=1`;
    // } else {
    //   Path = `${urls.registerUsersReport}`;
    // }
    Path = `${urls.registerUsersReport}`;
    fetchCategory({
      url: Path,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Principal_Dashboard')
      },
    });
    
  }

  useEffect(() => {
    if (auth) {
      userRegisterCount();
    }
    fetchModulePermission({
      url: `${urls.getPermissons}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('Principal_Dashboard')
      },
    });
  }, [auth]);




  const handleToggleChange = (event) => {
    setToggleState(event.target.checked);
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <TinyTextEditor
          id="10"
        />
      </div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} />
        <Grid item md={4} xs={12} />
        {/* <Grid item md={3} xs={12} /> */}
        {/* <Grid item md={2} xs={12} />
        <Grid item md={2} xs={12} /> */}
        {/* <Grid item md={2} xs={12} /> */}
        {auth && auth.personal_info.role && auth.personal_info.role !== 'Principal' && auth && auth.personal_info.role && auth.personal_info.role !== 'LeadTeacher'
          && (
          <Grid item md={2} xs={12}>
            <Typography variant="span">visual reports</Typography>
            <Switch
              checked={toggleState}
              onChange={handleToggleChange}
              color="primary"
              name="toggleButton"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="span">Branch level detailed report</Typography>
          </Grid>
          )}
      </Grid>
      {toggleState
        ? (
          <div key={1}>
            <Grid container spacing={2}>
              {auth && auth.personal_info.role && auth.personal_info.role !== 'Principal' && auth && auth.personal_info.role && auth.personal_info.role !== 'LeadTeacher'
                ? (
                  <Grid item md={12} xs={12}>
                    <RegisterUserCount
                      userDetails={categoryList.role_wise_register_users}
                      branchWiseUsers={categoryList.branch_wise_registered_users}
                    />
                    <Divider style={{padding:'2px', margin:'5px'}}/>
                    <ModuleView moduleDetails={categoryList.module_wise_count} />
                  </Grid>
                )
                : (
                  <>
                    <Grid item md={3} xs={12}>
                      <RegisterUserCount
                        userDetails={categoryList.role_wise_register_users}
                        branchWiseUsers={categoryList.branch_wise_registered_users}
                      />
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <ModuleView moduleDetails={categoryList.module_wise_count} />
                    </Grid>
                  </>
                )}
            </Grid>
            {loader}
          </div>
        )
        : <Dashboard key={2} />}
    </>
  );
};

export default AdminDashboard;
