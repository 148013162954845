export default (theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerD: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'black',
  },
  contentBox: {
    borderColor: 'lightgray',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
    width: '100%',
    marginBottom: '5px',
  },
  button: {
    marginTop: '9px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typographyy: {
    color: 'blue',
  },
  buttonview: {
    alignItems: 'center',
    textAlign: 'center',
    color: 'blue',
    borderColor: 'white !important',
  },
  typText: {
    textAlign: 'center',
    color: 'blue',
    fontSize: '30px',
    paddingTop: '10vh',
  },
  TextDesc: {
    color: 'black',
    padding: '25px',
  },
  TextTitle: {
    marginTop: '15px',
    color: 'black',
    fontSize: '25px',
    textAlign: 'center',
  },
  IconButtons: {
    alignItems: 'center',
    textAlign: 'center',
  },
  activityBox1: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    overFlow: 'hidden',
    paddingTop: '20vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4a66da',
    backgroundColor: '#bada55',
  },
  introBox: {
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  moduleCards: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    border: '1px solid white',
    margin: '0 0.5rem 0.5rem 0',
    padding: '1rem 0',
    '&:hover': {
      boxShadow: '3px 2px 2px 3px gray',
      transition: 'all 1s ease',
      borderRadius: '10px',
    },
  },
  modelcard1: {
    width: '100%',
    height: 'auto',
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0.5rem 0.5rem 0',
  },

  moduleCover: {
    width: '70%',
    display: 'grid',
    margin: '0 auto',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '1rem',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
    // left: 0
  },
  dialog: {
    paddingTop: '50px',
  },
});
