// export const keyFrameExampleOne = keyframes`
// 0% {
//   height: 200px;
// }
// 100% {
//   height: 600px;
//   background: orange;
// }
// `;

export default (theme) => ({
  trainingModule: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#bada55',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dummyBox: {
    width: '15%',
    height: '80vh',
  },
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: 'teal',
    animationDuration: '550ms',
    position: 'absolute',
    // left: 0
  },
  moduleCover: {
    width: '70%',
    height: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  moduleCards: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0.5rem 0.5rem 0',
    padding: '2rem',
  },
  introBox: {
    width: '10rem',
    textAlign: 'center',
  },
  firstHead: {
    display: '-webkit-box',
    maxWidth: '200px',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondHead: {
    fontSize: '0.9rem',
    margin: '0.5rem 0.5rem 1rem 0.5rem',
    textAlign: 'center',
    display: 'block',
  },
  bulbImg: {
    paddingBottom: '1rem',
    transition: 'transform 500ms ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'rotate(360deg)',
    },
  },
  topCards: {
    display: 'flex',
    width: '100%',
    height: 'auto',
  },
  bottomCards: {
    display: 'flex',
    marginTop: '1rem',
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});
