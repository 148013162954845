import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import styles from "./addBranch.style";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import "./listBranch.scss";
import urls from "../../../url";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";

const ListBranch = ({ classes }) => {
  const column = ["Sl No.", "School Name", "Branch Name", "Actions"];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [school, setSchool] = useState(null);
  const [schoolID, setSchoolID] = useState(undefined);
  const [editData, setEditData] = useState(null);
  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [branch, setBranch] = useState(null);
  const {
    data: showSchoolList,
    isLoading: showSchoolListLoading,
    doFetch: fetchSchool,
  } = useFetch(null);

  const [branchList, setBranchList] = useState([]);
  const alert = useAlert();
  const [actionItems, setActionItems] = useState([]);

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedSchool(null);
  };

  useEffect(() => {
    getBranchList();
  }, []);

  useEffect(() => {
    // console.log('TEST 2', fetchSchool)
    fetchSchool({
      url: urls.editSchoolApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Branch"),
      },
    });
  }, [fetchSchool, auth.personal_info.token]);

  useEffect(() => {
    setBranch(editData?.branch_name);
    setSchool(editData?.school_name);
  }, [dialogOpen]);

  const getBranchList = () => {
    axios
      .get(`${urls.addBranchApi}`, {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Branch"),
      })
      .then((result) => {
        // if (result.data.status_code === 200) {
        console.log(result.data, "pagination");
        setBranchList(result.data);
      })
      .catch((error) => {
        //   setLoading(false);
        //   setAlert('error', error.message);
        console.log("err", error);
      });
  };
  useEffect(() => {
    getSchoolList();
  }, []);

  const getSchoolList = () => {
    axios
      .get(`${urls.editSchoolApi}`, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("School"),
        },
      })
      .then((result) => {
        // if (result.data.status_code === 200) {
        console.log(result.data, "pagination");
        setSchoolList(result.data);
        //   setData({
        //     ...data,
        //     totalData: result.data.count,
        //     totalPages: result.data.total_pages,
        //     currentPage: result.data.current_page,
        //   });
        // }
      })
      .catch((error) => {
        //   setLoading(false);
        //   setAlert('error', error.message);
        console.log("err", error);
      });
  };

  const handleSchools = (event, value) => {
    console.log(value, "nnnnn");
    setSelectedSchool(value);
    setSchoolID(value.id);
  };
  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };
  const handleSchoolChange = (event) => {
    setSchool(event.target.value);
  };

  const newItem = () => {
    setActionItems([...actionItems, ""]);
  };

  const updateSchoolList = (id) => {
    console.log(editData, "jjjjj");
    let temp = {};
    for (let i = 0; i < actionItems.length; i += 1) {
      temp = {
        ...temp,
        [`${i}`]: {
          id: editData?.id,
          branch_name: branch,
          school_id: editData?.school_id,
        },
      };
    }

    const temp2 = {
      branches: temp,
    };
    // console.log(temp2, "jjjjjk");
    // let editObj = {
    //   branches: {
    //     0: {
    //       id: editData?.id,
    //       branch_name: editData?.branch_name,
    //       school_id: editData?.school_id,
    //     },
    //   },
    // };
    // console.log(editObj, "jjjjj");
    axios
      .put(`${urls.addBranchApi}`, temp2, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          module: localStorage.getItem("Branch"),
          // "Content-Type": "application/json",
        },
      })
      .then((result) => {
        console.log(result.data.message, "uppda");
        // setSchoolList(result.data);
        alert.success(result.data.message);
        setDialogOpen(false);
        // window.location.reload();
        getBranchList();
        setBranch(null);
      })
      .catch((error) => {
        console.log(error);
        // alert.warning(error.response.data.message);
      });
  };

  const deleteBranch = (deleteData) => {
    console.log(deleteData);
    // let temp = {};
    // for (let i = 0; i < actionItems.length; i += 1) {
    //   temp = {
    //     ...temp,
    //     [`${i}`]: {
    //       id: deleteData?.id,
    //       branch_name: deleteData?.branch_name,
    //       school_id: deleteData?.school_id,
    //     },
    //   };
    // }
    // console.log(temp, "tempp");
    // const data = {
    //   branches: temp,
    // };
    let data = {
      branches: {
        0: {
          id: deleteData?.id,
          branch_name: deleteData?.branch_name,
          school_id: deleteData?.school_id,
        },
      },
    };

    console.log(data, "kkkkk");

    axios
      .delete(`${urls.addBranchApi}`, {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
          module: localStorage.getItem("Branch"),
        },
        data,
      })
      .then((result) => {
        console.log(result.data.message, "upcha");
        // setSchoolList(result.data);
        // setDialogOpen(false);
        // window.location.reload();
        alert.success(result.data.message);
        getBranchList();
        // setSchool("");
      })
      .catch((error) => {
        console.log("err", error);
        // alert.warning(error.response.data);
      });
  };

  return (
    <>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {column &&
                  column.map((eachColumn, index) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "20px",
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        {eachColumn}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {branchList &&
                branchList.map((eachData, index) => {
                  return (
                    <TableRow key={eachData.id}>
                      {console.log(eachData, "sc")}
                      <TableCell
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        {console.log(eachData)}
                        {eachData.school_name}
                      </TableCell>

                      <TableCell
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        {eachData.branch_name}
                      </TableCell>

                      <TableCell
                        // style={{ margin: "0 auto" }}
                        style={{
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                        className="action-buttons"
                      >
                        <Button
                          size="small"
                          className="host-meeting-button"
                          //   disabled={eachData.is_pdf_generated}
                          style={{ margin: "0 15px" }}
                          onClick={(e) => {
                            setDialogOpen(true);
                            //     setDialogDetail('Edit');
                            setEditData(eachData);
                            newItem();
                          }}
                        >
                          <EditOutlinedIcon />
                        </Button>
                        <Button
                          size="small"
                          className="host-meeting-button"
                          style={{ margin: "0 15px" }}
                          //   disabled={eachData.is_pdf_generated}
                          onClick={(e) => {
                            //     setDialogOpens(true);
                            //     //   setDialogDetail('Edit');
                            // newItem();

                            deleteBranch(eachData);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {console.log(editData?.name, "editaaaa")} */}
        <Dialog
          open={dialogOpen}
          style={{ margin: "35px 0 0 0" }}
          className="create-weekly-report-dialog"
        >
          <DialogTitle className="dialog-title">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Edit Branch</Typography>
              <Button
                className="weekly-report-actions-butons"
                onClick={() => closeDialog()}
              >
                <CloseIcon />
              </Button>
            </div>
          </DialogTitle>
          <>
            {console.log(selectedSchool, "oooo")}
            <Grid container spacing={3} className={classes.root}>
              <Grid item xs={12}>
                {/* <Autocomplete
                  fullWidth
                  size="small"
                  className="filter-student weekly-report-input"
                  options={(schoolList && schoolList) || []}
                  getOptionLabel={(option) => option?.name}
                  filterSelectedOptions
                  value={selectedSchool}
                  // defaultValue={selectedSchool}
                  onChange={handleSchools}
                  // disabled={selectedSchool !== null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant="outlined"
                      label="School Name"
                      // error={helperText.courseListError}
                    />
                  )}
                /> */}
                <TextField
                  label="School Name"
                  margin="dense"
                  fullWidth
                  required
                  variant="outlined"
                  multiline
                  rows={1}
                  rowsMax={4}
                  value={school}
                  onChange={handleSchoolChange}
                  disabled={school !== null}
                />
              </Grid>
              {console.log(branch, "ooo branch")}
              <Grid item xs={12}>
                <TextField
                  label="Edit Branch"
                  margin="dense"
                  fullWidth
                  required
                  variant="outlined"
                  multiline
                  rows={1}
                  rowsMax={4}
                  value={branch}
                  onChange={handleBranchChange}
                />
              </Grid>
              {console.log(school, "ssss")}
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(id) => updateSchoolList(editData)}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </>
        </Dialog>
      </div>
    </>
  );
};

ListBranch.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ListBranch);

// export default ListSchool;
