/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withStyles, Button, Grid, Typography } from "@material-ui/core";
// import MovieIcon from '@material-ui/icons/Movie';
import { Link } from "react-router-dom";
// import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import useFetch from "../../../../../hoc/useFetch";

// import bookImage from '../../../Media/bookImage.webp';
import urls from "../../../../../url";
import styles from "./TrainingLesson.style";
import icon1 from "../../../Media/ICONS-01.svg";
import icon2 from "../../../Media/ICONS-02.svg";
import icon3 from "../../../Media/ICONS-03.svg";
import icon4 from "../../../Media/ICONS-04.svg";
import icon5 from "../../../Media/ICONS-05.svg";
import icon6 from "../../../Media/ICONS-06.svg";

function TrainingLessons({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  // const [trainingLessons, setTrainingLessons] = useState();
  const [courseType] = useState(localStorage.getItem("coursesType"));
  // const [loader, setLoader] = useState(false);
  const contentId = localStorage.getItem("contentId");
  const courseId = localStorage.getItem("courseIdNO");
  const classIdNo = sessionStorage.getItem("classId");

  // const modelName = sessionStorage.getItem('moduleName');
  // const chapterName = sessionStorage.getItem('chapterName');

  const {
    data: trainingLessons,
    isLoading: gettingTrainingLessons,
    doFetch: fetchTrainerLessons,
  } = useFetch(null);

  const iconImages = [
    {
      icon_title: "Videos",
      icon_file: "https://udanta.s3.amazonaws.com/media/Videos_YYV6e49.jpeg",
    },
    {
      icon_title: "Quiz",
      icon_file: "https://udanta.s3.amazonaws.com/media/quiz_lUqazla.jpeg",
    },
    {
      icon_title: "PPT",
      icon_file: "https://udanta.s3.amazonaws.com/media/PPT_GEGmpg1.jpeg",
    },
    {
      icon_title: "Learning Objective",
      icon_file:
        "https://udanta.s3.amazonaws.com/media/learning_objective_fC66Lxz.jpeg",
    },
    {
      icon_title: "Reading Material",
      icon_file:
        "https://udanta.s3.amazonaws.com/media/reading_material_FhLUEEG.jpeg",
    },
    {
      icon_title: "Downloadable",
      icon_file:
        "https://udanta.s3.amazonaws.com/media/downloadable_Y9AYSsW.jpeg",
    },
    {
      icon_title: "Assessment",
      icon_file:
        "https://udanta.s3.amazonaws.com/media/Assignment_Icon_1_7VMlyQL.jpeg",
    },
    {
      icon_title: "Case Study",
      icon_file:
        "https://udanta.s3.amazonaws.com/media/Case_Study_Icon_1_pyLCJQV.jpeg",
    },
    {
      icon_title: "Case Study",
      icon_file: "https://udanta.s3.amazonaws.com/media/Case_Study_Icon_1.jpeg",
    },
    {
      icon_title: "Assessment",
      icon_file: "https://udanta.s3.amazonaws.com/media/Assignment_Icon_1.jpeg",
    },
  ];

  function functionFilterIconNames(link) {
    if (iconImages && iconImages.length !== 0 && link) {
      return (
        <Typography
          variant="h6"
          style={{ textAlign: "center", color: "black" }}
        >
          {
            iconImages?.filter((item) => item?.icon_file === link)[0]
              ?.icon_title
          }
        </Typography>
      );
    }
    return (
      <Typography variant="h6" style={{ textAlign: "center", color: "black" }}>
        null
      </Typography>
    );
  }

  useEffect(() => {
    let URL;
    if (
      ((auth.personal_info && auth.personal_info.role === "AcademicHeads") ||
        (auth.personal_info && auth.personal_info.role === "Planner") ||
        (auth.personal_info && auth.personal_info.role === "Coordinator") ||
        (auth.personal_info && auth.personal_info.role === "AcademicManager") ||
        (auth.personal_info && auth.personal_info.role === "Principal") ||
        (auth.personal_info && auth.personal_info.role === "LeadTeacher")) &&
      courseType === "trainer"
    ) {
      URL = `${urls.inHouseModules}?content_id=${contentId}&course_instance_id=${courseId}&${courseType}=true&class_id=${classIdNo}`;
    } else {
      URL = `${urls.inHouseModules}?content_id=${contentId}&course_instance_id=${courseId}&${courseType}=true`;
    }
    fetchTrainerLessons({
      url: URL,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("Subject_Training"),
      },
    });
  }, []);

  const showLessons = () => {
    if (trainingLessons) {
      if (trainingLessons.length === 0) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "5rem",
              color: "blue",
            }}
          >
            <div>
              <Typography variant="h5">No Lessons Are Added</Typography>
            </div>
          </div>
        );
      }
      if (trainingLessons.length === 1) {
        return (
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              style={{ textAlign: "center", marginTop: "5rem" }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: trainingLessons[0].to_show
                    ? auth.personal_info.role === "Teacher" &&
                      (courseType === "is_training_course" ||
                        courseType === "self_driven")
                      ? "/teacherDashboard/unit"
                      : "/subject_unit"
                    : "",
                  state: {
                    chapterData: trainingLessons[0].course_wise_videos,
                    currentIndexNo: 0,
                    nextInfo: trainingLessons,
                  },
                }}
              >
                <Grid container className={classes.imageSettings}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Step 1
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <img
                      alt="crash"
                      src={trainingLessons[0].file || icon1}
                      width="100px"
                      height="100px"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {functionFilterIconNames(trainingLessons[0].file)}
                    </Typography>
                    {trainingLessons[0].is_completed === true ? (
                      <Typography
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Completed
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        );
      }
      if (trainingLessons.length === 2) {
        return (
          <Grid
            container
            spacing={2}
            style={{ textAlign: "center", marginTop: "5rem" }}
          >
            <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: trainingLessons[0].to_show
                    ? auth.personal_info.role === "Teacher" &&
                      (courseType === "is_training_course" ||
                        courseType === "self_driven")
                      ? "/teacherDashboard/unit"
                      : "/subject_unit"
                    : "",
                  state: {
                    chapterData: trainingLessons[0].course_wise_videos,
                    currentIndexNo: 0,
                    nextInfo: trainingLessons,
                  },
                }}
              >
                <Grid container className={classes.imageSettings}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Step 1
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <img
                      alt="crash"
                      src={trainingLessons[0].file || icon1}
                      width="100px"
                      height="100px"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {functionFilterIconNames(trainingLessons[0].file)}
                    </Typography>
                    {trainingLessons[0].is_completed === true ? (
                      <Typography
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Completed
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: trainingLessons[1].to_show
                    ? auth.personal_info.role === "Teacher" &&
                      (courseType === "is_training_course" ||
                        courseType === "self_driven")
                      ? "/teacherDashboard/unit"
                      : "/subject_unit"
                    : "",
                  state: {
                    chapterData: trainingLessons[1].course_wise_videos,
                    currentIndexNo: 1,
                    nextInfo: trainingLessons,
                  },
                }}
              >
                <Grid container className={classes.imageSettings}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Step 2
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <img
                      alt="crash"
                      src={trainingLessons[1].file || icon2}
                      width="100px"
                      height="100px"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {functionFilterIconNames(trainingLessons[1].file)}
                    </Typography>
                    {trainingLessons[1].is_completed === true ? (
                      <Typography
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Completed
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        );
      }
      if (trainingLessons.length === 3) {
        return (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: trainingLessons[1].to_show
                    ? auth.personal_info.role === "Teacher" &&
                      (courseType === "is_training_course" ||
                        courseType === "self_driven")
                      ? "/teacherDashboard/unit"
                      : "/subject_unit"
                    : "",
                  state: {
                    chapterData: trainingLessons[1].course_wise_videos,
                    currentIndexNo: 1,
                    nextInfo: trainingLessons,
                  },
                }}
              >
                <Grid container className={classes.imageSettings}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Step 2
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <img
                      alt="crash"
                      src={trainingLessons[1].file || icon1}
                      width="100px"
                      height="100px"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {functionFilterIconNames(trainingLessons[1].file)}
                    </Typography>
                    {trainingLessons[1].is_completed === true ? (
                      <Typography
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Completed
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: trainingLessons[0].to_show
                      ? auth.personal_info.role === "Teacher" &&
                        (courseType === "is_training_course" ||
                          courseType === "self_driven")
                        ? "/teacherDashboard/unit"
                        : "/subject_unit"
                      : "",
                    state: {
                      chapterData: trainingLessons[0].course_wise_videos,
                      currentIndexNo: 0,
                      nextInfo: trainingLessons,
                    },
                  }}
                >
                  <Grid container className={classes.imageSettings}>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Step 1
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <img
                        alt="crash"
                        src={trainingLessons[0].file || icon2}
                        width="100px"
                        height="100px"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "center", color: "black" }}
                      >
                        {functionFilterIconNames(trainingLessons[0].file)}
                      </Typography>
                      {trainingLessons[0].is_completed === true ? (
                        <Typography
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Completed
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: trainingLessons[2].to_show
                      ? auth.personal_info.role === "Teacher" &&
                        (courseType === "is_training_course" ||
                          courseType === "self_driven")
                        ? "/teacherDashboard/unit"
                        : "/subject_unit"
                      : "",
                    state: {
                      chapterData: trainingLessons[2].course_wise_videos,
                      currentIndexNo: 2,
                      nextInfo: trainingLessons,
                    },
                  }}
                >
                  <Grid container className={classes.imageSettings}>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Step 3
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <img
                        alt="crash"
                        src={trainingLessons[2].file || icon3}
                        width="100px"
                        height="100px"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "center", color: "black" }}
                      >
                        {functionFilterIconNames(trainingLessons[2].file)}
                      </Typography>
                      {trainingLessons[2].is_completed === true ? (
                        <Typography
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Completed
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      if (trainingLessons.length === 4) {
        return (
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={3} />
                <Grid item md={3} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[1].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[1].course_wise_videos,
                        currentIndexNo: 1,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 2
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[1].file || icon1}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[1].file)}
                        </Typography>
                        {trainingLessons[1].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={3} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[2].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[2].course_wise_videos,
                        currentIndexNo: 2,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 3
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[2].file || icon2}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[2].file)}
                        </Typography>
                        {trainingLessons[2].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={3} />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[0].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[0].course_wise_videos,
                        currentIndexNo: 0,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 1
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[0].file || icon3}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[0].file)}
                        </Typography>
                        {trainingLessons[0].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[3].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[3].course_wise_videos,
                        currentIndexNo: 3,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 4
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[3].file || icon4}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[3].file)}
                        </Typography>
                        {trainingLessons[3].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      if (trainingLessons.length === 5) {
        return (
          <Grid container>
            <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: trainingLessons[2].to_show
                    ? auth.personal_info.role === "Teacher" &&
                      (courseType === "is_training_course" ||
                        courseType === "self_driven")
                      ? "/teacherDashboard/unit"
                      : "/subject_unit"
                    : "",
                  state: {
                    chapterData: trainingLessons[2].course_wise_videos,
                    currentIndexNo: 2,
                    nextInfo: trainingLessons,
                  },
                }}
              >
                <Grid container className={classes.imageSettings}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Step 3
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <img
                      alt="crash"
                      src={trainingLessons[2].file || icon1}
                      width="100px"
                      height="100px"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      {functionFilterIconNames(trainingLessons[2].file)}
                    </Typography>
                    {trainingLessons[2].is_completed === true ? (
                      <Typography
                        style={{ textAlign: "center", color: "black" }}
                      >
                        Completed
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={2} />
                <Grid item md={4} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[1].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[1].course_wise_videos,
                        currentIndexNo: 1,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 2
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[1].file || icon2}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[1].file)}
                        </Typography>
                        {trainingLessons[1].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={4} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[3].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[3].course_wise_videos,
                        currentIndexNo: 3,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 4
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[3].file || icon3}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[3].file)}
                        </Typography>
                        {trainingLessons[3].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[0].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[0].course_wise_videos,
                        currentIndexNo: 0,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 1
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[0].file || icon4}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[0].file)}
                        </Typography>
                        {trainingLessons[0].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      // eslint-disable-next-line no-nested-ternary
                      pathname: trainingLessons[4].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? auth.personal_info.role === "Teacher"
                            ? "/teacherDashboard/unit"
                            : "/subject_unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[4].course_wise_videos,
                        currentIndexNo: 4,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 5
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[4].file || icon5}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[4].file)}
                        </Typography>
                        {trainingLessons[4].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      if (trainingLessons.length === 6) {
        return (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={4} />
                <Grid item md={2} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[2].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[2].course_wise_videos,
                        currentIndexNo: 2,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 3
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[2].file || icon3}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[2].file)}
                        </Typography>
                        {trainingLessons[2].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={2} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[3].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[3].course_wise_videos,
                        currentIndexNo: 3,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 4
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[3].file || icon4}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[3].file)}
                        </Typography>
                        {trainingLessons[3].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={4} />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={2} />
                <Grid item md={4} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[1].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[1].course_wise_videos,
                        currentIndexNo: 1,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 2
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[1].file || icon2}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[1].file)}
                        </Typography>
                        {trainingLessons[1].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={4} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[4].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[4].course_wise_videos,
                        currentIndexNo: 4,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 5
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[4].file || icon5}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[4].file)}
                        </Typography>
                        {trainingLessons[4].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={3} />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container>
                <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[0].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[0].course_wise_videos,
                        currentIndexNo: 0,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 1
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[0].file || icon1}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[0].file)}
                        </Typography>
                        {trainingLessons[0].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item md={6} xs={6} style={{ textAlign: "center" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: trainingLessons[5].to_show
                        ? auth.personal_info.role === "Teacher" &&
                          (courseType === "is_training_course" ||
                            courseType === "self_driven")
                          ? "/teacherDashboard/unit"
                          : "/subject_unit"
                        : "",
                      state: {
                        chapterData: trainingLessons[5].course_wise_videos,
                        currentIndexNo: 5,
                        nextInfo: trainingLessons,
                      },
                    }}
                  >
                    <Grid container className={classes.imageSettings}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          Step 6
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <img
                          alt="crash"
                          src={trainingLessons[5].file || icon6}
                          width="100px"
                          height="100px"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h6"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          {functionFilterIconNames(trainingLessons[5].file)}
                        </Typography>
                        {trainingLessons[5].is_completed === true ? (
                          <Typography
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Completed
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      if (trainingLessons.length === 0) {
        return (
          <>
            <Typography
              variant="h5"
              style={{ textAlign: "center", color: "black", marginTop: "20%" }}
            >
              No Content is Present in This Chapter
            </Typography>
          </>
        );
      }
    }
    return 0;
  };

  return (
    <>
      <Grid container className={classes.lessonBackground}>
        {gettingTrainingLessons ? (
          <>
            <img
              // open={open}
              className={classes.progress}
              alt="crash"
              src={require("../../../../../hoc/loder.gif")}
              width="100%"
              height="100%"
            />
          </>
        ) : (
          <Grid container className={classes.bookImgDiv}>
            {/* <Grid container>
              <Grid item md={12} xs={12} style={{ paddingTop: '50px' }}>
                <Typography style={{ padding: '10px' }}>
                  <b>ModelName</b>
                  {' '}
                  :
                  {' '}
                  {modelName}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12} style={{ paddingTop: '10px' }}>
                <Typography style={{ padding: '10px' }}>
                  <b>ChapterName </b>
                  {' '}
                  :
                  {' '}
                  {chapterName}
                </Typography>
              </Grid>
            </Grid> */}
            <Grid item md={12} xs={12}>
              {showLessons()}
            </Grid>
            <Grid container>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  paddingTop: "50px",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Link
                  to={
                    auth.personal_info.role === "Teacher" &&
                    (courseType === "is_training_course" ||
                      courseType === "self_driven")
                      ? "/teacherDashboard/chapters"
                      : "/subject_chapters"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ alignItems: "center", textAlign: "center" }}
                    color="primary"
                    variant="contained"
                  >
                    Back To Chapters
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* </div> */}
    </>
  );
}
TrainingLessons.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TrainingLessons);
