/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Box,
  Button,
  Radio,
  FormHelperText,
  TextField,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
// import axios from 'axios';
import urls from '../../url';
import useFetch from '../../hoc/useFetch';
import styles from './userProfile.style';
import { useAlert } from '../../hoc/alert/alert';
import axios from 'axios';


const ProfileUserView = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  // eslint-disable-next-line no-useless-escape
  const filterEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [totalExperience, setTotalExerience] = useState('');
  const [gender, setGender] = useState('');
  const [gradeID, setGradeId] = useState([]);
  const [SubjectId, setSubjectId] = useState([]);
  const [edit, setEdit] = useState(false);


  const alert = useAlert();

  const {
    data: profileData,
    isLoading: gettingUserProfile,
    doFetch: fetchUserProfile,
  } = useFetch(null);

  const {
    data: getGradeList,
    isLoading: gettingGradeList,
    doFetch: fetchGradeList,
  } = useFetch(null);

  const {
    data: getSubjectList,
    isLoading: gettingSubjectList,
    doFetch: fetchSubjectList,
  } = useFetch(null);

  const {
    data: updateProfileResponse,
    isLoading: updatingProfileData,
    doFetch: fetchUpdateProfile,
  } = useFetch(null);

  useEffect(() => {
    if (profileData) {
      setFirstName(profileData && profileData.user && profileData.user.first_name && profileData.user.first_name);
      setLastName(profileData && profileData.user && profileData.user.last_name && profileData.user.last_name);
      setEmail(profileData && profileData.user && profileData.user.email && profileData.user.email);
      setPhoneNumber(profileData && profileData.phone_number && profileData.phone_number);
      setJoiningDate(profileData && profileData.joining_date && profileData.joining_date);
      setTotalExerience(profileData && profileData.total_experience && profileData.total_experience);
      setGender((profileData && profileData.gender && profileData.gender) || '');
      if (profileData && profileData.course_type.length !== 0) {
        // eslint-disable-next-line prefer-const
        let gradeArray = [];
        for (let i = 0; i < profileData.course_type.length; i += 1) {
          gradeArray.push(profileData.course_type[i].id);
        }
        setGradeId(gradeArray);
        fetchSubjectList({
          url: `${urls.subjectSelector_Webinar}?grade_id=${gradeArray.toString()}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
          // module: localStorage.getItem('Profile')
          },
        });
      }
      if (profileData && profileData.course_sub_type.length !== 0) {
        const subjectArray = [];
        const n = profileData && profileData.course_sub_type.length;
        for (let i = 0; i < n; i += 1) {
          subjectArray.push(profileData.course_sub_type[i].id);
        }
        setSubjectId(subjectArray);
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (auth) {
      fetchUserProfile({
        url: urls.profileFetchApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          // module: localStorage.getItem('Profile')
        },
      });
      fetchGradeList({
        url: urls.categoryTypeApiList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
          // module: localStorage.getItem('Profile')
        },
      });
      // fetchSubjectList({
      //   url: urls.getAllSubjectsList,
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${auth.personal_info.token}`,
      //   },
      // });
    }
    setEdit(false);
  }, [auth, updateProfileResponse]);

  function handleGradeChange(e) {
    setGradeId(e.target.value);
    fetchSubjectList({
      url: `${urls.subjectSelector_Webinar}?grade_id=${e.target.value.toString()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
          // module: localStorage.getItem('Profile')
      },
    });
    // axios.get(`${urls.subjectSelector_Webinar}?grade_id=${e.target.value.toString()}`, {
    //   headers: {
    //     Authorization: `Bearer Page Changeser ${auth.personal_info.token}`,
    //   },
    // }).then((res) => {
    //   setSubjectId(res.data);
    // }).catch((err) => {
    //   console.log(err);
    // });
  }
  useEffect(() => {
    if (profileData) {
      setEdit(false);
    }
  }, [profileData]);

  function JoiningDateFunc(e) {
    const UserDate = document.getElementById('joiningDateId').value;
    const ToDate = new Date();
    if (new Date(UserDate).getTime() > ToDate.getTime()) {
      alert.warning('The Date must be less than today date');
      return false;
    }
    setJoiningDate(e.target.value);
  }

  function functionTosaveProfileData() {
    if (!firstName) {
      alert.warning('Enter First Name');
      return;
    } if (!lastName) {
      alert.warning('Enter Last Name');
      return;
    } if (!email) {
      alert.warning('Enter Email');
      return;
    } if (!phoneNumber) {
      alert.warning('Enter Phone Number');
      return;
    } if (!joiningDate) {
      alert.warning('Enter Joining Date');
      return;
    } if (!gender) {
      alert.warning('Select Gender');
      return;
    } if (!totalExperience) {
      alert.warning('Enter Experience');
      return;
    } if (gradeID.length === 0) {
      alert.warning('Select Grade');
      return;
    } if (SubjectId.length === 0) {
      alert.warning('Select Subject');
      return;
    }
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      joining_date: joiningDate,
      total_experience: totalExperience,
      course_type: gradeID,
      course_sub_type: SubjectId,
      email,
      gender,
    };
    fetchUpdateProfile({
      url: urls.profileFetchApi,
      method: 'PUT',
      body: data,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json',
        // module: localStorage.getItem('Profile')
      },
    });
  }

  return (
    <>
      {(gettingUserProfile || gettingSubjectList || gettingGradeList || updatingProfileData)
        && (
        <img
          className={classes.progress}
          alt="crash"
          src={require('../../hoc/loder.gif')}
          width="100%"
          height="100%"
        />
        )}
      {!gettingUserProfile && !gettingSubjectList && !gettingGradeList && !updatingProfileData
        && (
          <>
          <>
            <Typography variant="h4" className={classes.typographys}>
              Profile Information
            </Typography>
            <Divider className={classes.divider} />
            <Grid container className={classes.paper}>
              <Grid item md={12} xs={12} style={{ paddingBottom: '20px' }}>
                <Button variant="contained" style={{ float: 'right' }} color="primary" disabled={edit} onClick={() => setEdit(true)}>
                  <AccountCircle />
                  &nbsp;
                  Edit Profile
                </Button>
              </Grid>
              <Grid item md={2} />
              <Grid item md={8} xs={12}>
                <Box
                  border={1}
                  style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}
                >
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black', paddingTop: '10px' }}>First Name :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && <Typography variant="h6" style={{ color: 'blue', paddingTop: '10px' }}>{firstName}</Typography> }
                      {edit
                        && (
                        <TextField
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          value={firstName || ''}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        )}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '10px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black', paddingTop: '10px' }}>Last Name :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && <Typography variant="h6" style={{ color: 'blue', paddingTop: '10px' }}>{lastName}</Typography> }
                      {edit
                        && (
                        <TextField
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          value={lastName || ''}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        )}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={6}>
                      <Typography variant="h6" style={{ color: 'black', paddingTop: '5px' }}>User Name :</Typography>
                    </Grid>
                    <Grid item md={9} xs={6}>
                      <Typography style={{ color: 'blue', paddingTop: '10px' }}>
                        {(profileData && profileData.user && profileData.user.username && profileData.user.username) || '' }
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black', paddingTop: '10px' }}>PhoneNumber :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && <Typography variant="h6" style={{ color: 'blue', paddingTop: '10px' }}>{phoneNumber}</Typography> }
                      {edit
                        && (
                        <TextField
                          margin="dense"
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={phoneNumber || ''}
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value, 10))
                              .toString()
                              .slice(0, 10);
                          }}
                          onChange={(e) => e.target.value.length < 11 && setPhoneNumber(e.target.value)}
                        />
                        )}
                      {phoneNumber && phoneNumber.length !== 10 ? (
                        <FormHelperText
                          id="component-error-text"
                          style={{ color: 'red', fontSize: '15px' }}
                        >
                          Invalid Phone Number
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black', paddingTop: '10px' }}>Email Id :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && <Typography variant="h6" style={{ color: 'blue', paddingTop: '10px', overflow: 'auto' }}>{email}</Typography> }
                      {edit
                        && (
                        <TextField
                          margin="dense"
                          type="email"
                          variant="outlined"
                          fullWidth
                          value={email || ''}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        )}
                      {email && !filterEmail.test(email) ? (
                        <FormHelperText
                          id="component-error-text"
                          style={{ color: 'red', fontSize: '15px' }}
                        >
                          Invalid Email Address
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black', paddingTop: '10px' }}>Experience :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && (
                      <Typography variant="h6" style={{ color: 'blue', paddingTop: '10px' }}>
                        {totalExperience}
                        {' '}
                        Years
                      </Typography>
                      ) }
                      {edit
                        && (
                        <TextField
                          margin="dense"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={totalExperience || ''}
                          onChange={(e) => e.target.value.length < 5 && setTotalExerience(e.target.value)}
                        />
                        )}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black' }}>Gender :</Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Radio
                        checked={gender === 'Male'}
                        onChange={(e) => setGender(e.target.value)}
                        value="Male"
                        color="primary"
                        name="gender"
                        disabled={!edit}
                        inputProps={{ 'aria-label': 'B' }}
                      />
                      {' '}
                      <b style={{ color: 'black' }}>Male</b>
                    </Grid>
                    <Grid item md={4} xs={5}>
                      <Radio
                        checked={gender === 'Female'}
                        onChange={(e) => setGender(e.target.value)}
                        value="Female"
                        color="primary"
                        name="gender"
                        disabled={!edit}
                        inputProps={{ 'aria-label': 'B' }}
                      />
                      <b style={{ color: 'black' }}>Female</b>
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black' }}>Date of Joining(ORCHIDS) :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && <Typography variant="h6" style={{ color: 'blue', paddingTop: '10px' }}>{joiningDate}</Typography> }
                      {edit
                        && (
                        <TextField
                          margin="dense"
                          id="joiningDateId"
                          type="date"
                          fullWidth
                          variant="outlined"
                          value={joiningDate || ''}
                          onChange={(e) => JoiningDateFunc(e)}
                        />
                        )}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={6}>
                      <Typography variant="h6" style={{ color: 'black' }}>Role :</Typography>
                    </Grid>
                    <Grid item md={9} xs={6}>
                      <Typography style={{ color: 'blue', paddingTop: '7px' }}>{(profileData && profileData.roles_category && profileData.roles_category.name) || '' }</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={6}>
                      <Typography variant="h6" style={{ color: 'black' }}>Branch :</Typography>
                    </Grid>
                    <Grid item md={9} xs={6} style={{ paddingTop: '5px' }}>
                      {profileData && profileData.branch && profileData.branch.length !== 0 && profileData.branch.map((info) => (
                        <span key={info.id} style={{ color: 'blue' }}>
                          {info.branch_name || ''}
                          ,
                          &nbsp;
                        </span>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black' }}>Grade :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && profileData && profileData.course_type && profileData.course_type.length !== 0 && profileData.course_type.map((info) => (
                        <span key={info.id} style={{ color: 'black' }}>
                          {info.type_name || ''}
                          ,
                          &nbsp;
                        </span>
                      ))}
                      {edit
                      && (
                        <>
                          <Typography style={{ color: 'black' }}>Select Grade</Typography>
                          <Select
                            multiple
                            margin="dense"
                            value={gradeID || ''}
                            fullWidth
                            style={{ minWidth: '250px', maxWidth: '500px', color: 'black' }}
                            onChange={(e) => handleGradeChange(e)}
                            className={classes.textField}
                            variant="outlined"
                          >
                            {getGradeList
                            && getGradeList.length !== 0
                            && getGradeList.map((data) => (
                              <MenuItem
                                value={data.id}
                                key={data.id}
                                name={data.type_name}
                              >
                                {data.type_name ? data.type_name : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '25px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Typography variant="h6" style={{ color: 'black' }}>Subject :</Typography>
                    </Grid>
                    <Grid item md={8} xs={11}>
                      {!edit && profileData && profileData.course_sub_type && profileData.course_sub_type.length !== 0 && profileData.course_sub_type.map((info) => (
                        <span key={info.id} style={{ color: 'black' }}>
                          {info.sub_type_name || ''}
                          ,
                          &nbsp;
                        </span>
                      ))}
                      {edit
                      && (
                      <>
                        <Typography style={{ color: 'black' }}>Select Subject</Typography>
                        <Select
                          multiple
                          margin="dense"
                          fullWidth
                          value={SubjectId || ''}
                          onChange={(e) => setSubjectId(e.target.value)}
                          className={classes.textField}
                          variant="outlined"
                          style={{ minWidth: '250px', maxWidth: '500px', color: 'black' }}
                        >
                          {getSubjectList
                          && getSubjectList.length !== 0
                          && getSubjectList.map((data) => (
                            <MenuItem
                              value={data.subject_fk.id}
                              key={data.subject_fk.id}
                              name={data.subject_fk.sub_type_name}
                            >
                              {data.subject_fk.sub_type_name ? data.subject_fk.sub_type_name : ''}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                      )}
                    </Grid>
                    <Grid item md={1} xs={1} style={{ paddingTop: '25px', paddingLeft: '5px' }}>
                      {edit && <EditIcon margin="dense" color="primary" />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2} />
              <Grid item md={2} />
              <Grid item md={8} xs={12} style={{ marginTop: '10px' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!edit}
                  onClick={functionTosaveProfileData}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            </>
          </>
        )}
    </>
  );
};

ProfileUserView.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ProfileUserView);
