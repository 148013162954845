import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { BrowserRouter } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import urls from '../url';



// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import YourProfile from './UserProfile/YourProfile';
import EditProfile from './UserProfile/EditProfile';
// import ComponentList from "./UserComponentList";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    marginTop: '5vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: '10vh',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3)
    // marginTop: "5vh"
  },
}));

function UserProfile() {
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));
  const classes = useStyles();
  const [component, setComponent] = useState('YourProfile');
  const [permission, setPermission] = useState([]);


  function mainProfile() {
    if (component === 'EditProfile') {
      return <EditProfile />;
    }
    if (component === 'YourProfile') {
      return <YourProfile />;
    }
    return 0;
  }

   // For Permissions
  //  function getPermissonData() {
  //   axios.get(urls.getPermissons,{
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       // module: localStorage.getItem('Profile')

  //     },
  //   }).then(response => {
  //     setPermission(response.data.response)
  //   }).catch(error => {
  //     console.log(error);
  //   })
  // }

  // useEffect(() => {
  //   getPermissonData(localStorage.getItem('Profile'))
  // }, [])

  return (
    <div style={{ marginTop: '10vh' }}>
      <CssBaseline />
      <Container maxWidth="lg">
        {permission.can_view ? 
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            {/* <Toolbar>
              <Typography variant='h5' noWrap style={{ fontWeight: "bold" }}>
                Your Profile
              </Typography>
            </Toolbar> */}
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className={classes.toolbar} />
            <Divider />
            <List>
              <BrowserRouter>
                <ListItem
                  button
                  key="profile"
                  onClick={() => setComponent('YourProfile')}
                  style={{
                    color: component === 'YourProfile' && 'blue',
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleIcon
                      style={{
                        color: component === 'YourProfile' && 'blue',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="User Profile" />
                </ListItem>
                {permission.can_update ? 
                <ListItem
                  button
                  key="editProfile"
                  onClick={() => setComponent('EditProfile')}
                  style={{
                    color: component === 'EditProfile' && 'blue',
                  }}
                >
                  <ListItemIcon>
                    <EditIcon
                      style={{
                        color: component === 'EditProfile' && 'blue',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Edit Profile" />
                </ListItem>
                :null}
              </BrowserRouter>
            </List>
            <Divider />
          </Drawer>
          <main className={classes.content}>
            {/* {component === 'EditProfile' ? (
              <EditProfile />
            ) : component === 'YourProfile' ? (
              <YourProfile />
            ) : (
              ''
            )} */}
            {mainProfile()}
          </main>
        </div>
        :null}
      </Container>
    </div>
  );
}

export default UserProfile;
