import React, { useState } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
} from '@material-ui/core';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
// import urls from '../../../url';
import styles from './allNotes.style';
import IndividualNotesModel from './individualNotesModel';

function ViewAllNotes({
  classes, handelClose, open, fullData,
}) {
  const [openModel, setOpenModel] = useState(false);
  const [modelData, setModelData] = useState({});

  function openModelFunction(selectedRecord) {
    setOpenModel(true);
    setModelData(selectedRecord);
  }

  const handelCloseModel = () => {
    setOpenModel(false);
    setModelData({});
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  DialogTitle.propTypes = {
    children: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  function downloadFunction() {
    // eslint-disable-next-line no-alert
    window.alert('Download Feature will be enabled at next release');
  }

  return (
    <>
      <Dialog
        maxWidth="xl"
        className={classes.modal}
        open={open}
        disableEnforceFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <DialogTitle id="alert-dialog-title" onClose={handelClose}>
          View Notes on &nbsp;
          <b style={{ color: 'blue' }}>{(fullData.learning_module && fullData.learning_module.title) || ''}</b>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell float="left">S.No</TableCell>
                    <TableCell float="left">Video Name</TableCell>
                    <TableCell float="left">Uploaded Date</TableCell>
                    <TableCell float="left">View Notes</TableCell>
                    <TableCell float="left">Download Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fullData.notes && fullData.notes.length !== 0
                  && fullData.notes.map((item, index) => {
                    const Newdate = new Date(item.updated_at && item.updated_at);
                    return (
                      <TableRow key={item.id}>
                        <TableCell float="left">{index + 1}</TableCell>
                        <TableCell float="left">{(fullData.learning_module && fullData.learning_module.title) || ''}</TableCell>
                        <TableCell float="left">{(item.updated_at && item.updated_at && Newdate.toString().split('G')[0]) || ''}</TableCell>
                        <TableCell float="left">
                          <Button variant="contained" color="primary" onClick={() => openModelFunction(item)}>
                            View&nbsp;
                            <VisibilitySharpIcon />
                          </Button>
                        </TableCell>
                        <TableCell float="left">
                          {/* href={`${urls.downloadNotesApi}?notes_id=${item.id}`} */}
                          <Button onClick={() => downloadFunction()} variant="contained" color="primary">
                            Download&nbsp;
                            <CloudDownloadIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <IndividualNotesModel
        open={openModel}
        information={modelData}
        fullInformaion={fullData}
        handelClose={handelCloseModel}
      />
    </>
  );
}

ViewAllNotes.defaultProps = {
  fullData: '',
};

ViewAllNotes.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  fullData: PropTypes.instanceOf(Object),
  handelClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ViewAllNotes);
