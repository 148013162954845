import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
// import "./styles.css";
// import Snackbar from "../../snackbar/snackbar.jsx";
// import services from "../../../services/organization.js";
import Loader from "@material-ui/core/CircularProgress";
import { AccessAlarm } from "@material-ui/icons";
import Axios from "axios";
import urls from "../../../../../url";
import { makeStyles } from "@material-ui/core/styles";
// import CircularIndeterminate from "../../loader/loader.jsx";
import { useAlert } from "../../../../../hoc/alert/alert";
import "./role_from.scss";
import axios from "axios";

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: "initial",
  },
});

const AddRoleFrom = (props) => {
  const [roleName, setRoleName] = useState("");
  const [roleNameFlag, setRoleNameFlag] = useState(false);
  const [roleNameHelperText, setRoleNameHelperText] = useState("");
  const alert = useAlert();
  const [userTypeFlag, setUserTypeFlag] = useState(false);
  const [userTypeHelperText, setUserTypeHelperText] = useState("");

  const [isOrchidsFlag, setIsOrchidsFlag] = useState(false);
  const [isorchidsHelperText, setIsOrchidsHelperText] = useState("");

  const [open, setOpen] = useState(false);
  const [snackbarServerty, setSnackbarServerty] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [auth] = useState(JSON.parse(localStorage.getItem("UserLogin")));
  const [allModule, setAllModule] = useState([]);
  const [grantedPermissions, setGrantedPermissions] = useState([]);
  const [grantedModuleAssigned, setGrantedModuleAssigned] = useState(false);
  const [formType, setFormType] = useState("Add");
  const [userType, setUserType] = useState("");
  const [userBelong, setUserBelong] = useState("");
  const classes = useStyles();

  const [allPermissionSelected, setAllPermissionSelected] = useState(false);
  const [loader, setLoader] = useState(false);

  const [clicked, setClicked] = useState(false);

  const getAllModle = () => {
    Axios.get(urls.AuthorizationModules, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem("User_Roles"),
      },
    })
      .then((response) => {
        const tempModules = [];
        response.data.response.map((eachModule) => {
          let moduleId = eachModule.id;
          tempModules[moduleId] = {
            module: eachModule.id,
            can_add: false,
            can_cancel: false,
            can_delete: false,
            can_enroll: false,
            can_export: false,
            can_import: false,
            can_send: false,
            can_publish: false,
            can_activate_inactivate: false,
            can_update: false,
            can_view: false,
          };
        });
        setGrantedPermissions(tempModules);
        setGrantedModuleAssigned(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const getModuleForForm = (typeOfUser) => {
    Axios.get(urls.AuthorizationModulesClassified + `&user_type=${typeOfUser}`)
      .then((response) => {
        setAllModule(response.data.response);
        // getAllModle()
        // getModuleList(true)
        // alert.success('Data recieved');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRuleAdd = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: !tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleUpdate = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: !tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleDelete = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: !tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleEnroll = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: !tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleCancel = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: !tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleCanSendReport = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: !tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleCanPublish = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: !tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleCanActiveInactive = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate: !tempGrantedPermissions[moduleId]
        .can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleCanExport = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: !tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleImport = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: !tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleView = (e, moduleId, rule) => {
    let tempGrantedPermissions = [...grantedPermissions];
    tempGrantedPermissions[moduleId] = {
      module: tempGrantedPermissions[moduleId].module,
      can_add: tempGrantedPermissions[moduleId].can_add,
      can_update: tempGrantedPermissions[moduleId].can_update,
      can_delete: tempGrantedPermissions[moduleId].can_delete,
      can_view: !tempGrantedPermissions[moduleId].can_view,
      can_export: tempGrantedPermissions[moduleId].can_export,
      can_import: tempGrantedPermissions[moduleId].can_import,
      can_send: tempGrantedPermissions[moduleId].can_send,
      can_publish: tempGrantedPermissions[moduleId].can_publish,
      can_activate_inactivate:
        tempGrantedPermissions[moduleId].can_activate_inactivate,
      can_enroll: tempGrantedPermissions[moduleId].can_enroll,
      can_cancel: tempGrantedPermissions[moduleId].can_cancel,
    };
    setGrantedPermissions(tempGrantedPermissions);
  };

  const handleRuleSelectAll = (e, moduleId, type, moduleName) => {
    let tempGrantedPermissions = [...grantedPermissions];
    if (
      tempGrantedPermissions[moduleId].can_add === true ||
      tempGrantedPermissions[moduleId].can_cancel === true ||
      tempGrantedPermissions[moduleId].can_delete === true ||
      tempGrantedPermissions[moduleId].can_enroll === true ||
      tempGrantedPermissions[moduleId].can_export === true ||
      tempGrantedPermissions[moduleId].can_import === true ||
      tempGrantedPermissions[moduleId].can_send === true ||
      tempGrantedPermissions[moduleId].can_publish === true ||
      tempGrantedPermissions[moduleId].can_activate_inactivate === true ||
      tempGrantedPermissions[moduleId].can_update === true ||
      tempGrantedPermissions[moduleId].can_view === true
    ) {
      tempGrantedPermissions[moduleId] = {
        module: tempGrantedPermissions[moduleId].module,
        can_add: false,
        can_cancel: false,
        can_delete: false,
        can_enroll: false,
        can_export: false,
        can_import: false,
        can_send: false,
        can_publish: false,
        can_activate_inactivate: false,
        can_update: false,
        can_view: false,
      };
    } else {
      tempGrantedPermissions[moduleId] = {
        module: tempGrantedPermissions[moduleId].module,
        can_add: true,
        can_cancel: true,
        can_delete: true,
        can_enroll: true,
        can_export: true,
        can_import: true,
        can_send: true,
        can_publish: true,
        can_activate_inactivate: true,
        can_update: true,
        can_view: true,
      };
    }

    setGrantedPermissions(tempGrantedPermissions);
  };

  useEffect(() => {
    getAllModle();
  }, []);

  useEffect(() => {
    getModuleForForm(userType);
  }, [formType, userType]);

  useEffect(() => {
    let tempGrantedPermissions = [...grantedPermissions];
    if (
      props.specificRoleData != undefined &&
      props.specificRoleData.length !== 0
    ) {
      setFormType("Update");
      setRoleName(props.specificRoleData.role_name);
      setUserType(props.specificRoleData.user_type);
      if (props.specificRoleData.is_orchids) {
        setUserBelong("orchids");
      } else {
        setUserBelong("nonOrchids");
      }
      props.specificRoleData.module &&
        props.specificRoleData.module.map((eachModule) => {
          tempGrantedPermissions[eachModule.module_id] = {
            module: eachModule.module_id,
            can_add: eachModule.can_add,
            can_update: eachModule.can_update,
            can_delete: eachModule.can_delete,
            can_view: eachModule.can_view,
            can_cancel: eachModule.can_cancel,
            can_send: eachModule.can_send,
            can_publish: eachModule.can_publish,
            can_activate_inactivate: eachModule.can_activate_inactivate,
            can_export: eachModule.can_export,
            can_import: eachModule.can_import,
            can_enroll: eachModule.can_enroll,
          };
        });
    }

    setGrantedPermissions(tempGrantedPermissions);
  }, [grantedModuleAssigned === true]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    props.close();
    setGrantedModuleAssigned(false);
  };

  const checkValidation = () => {
    if (roleName.length < 1) {
      setRoleNameFlag(true);
      setRoleNameHelperText("Required");
      return false;
    } else {
      setRoleNameFlag(false);
      setRoleNameHelperText("");
      return true;
    }
  };

  const checkValidationOfUserType = () => {
    if (userType.length < 1) {
      setUserTypeFlag(true);
      setUserTypeHelperText("Required");
      return false;
    } else {
      setUserTypeFlag(false);
      setUserTypeHelperText("");
      return true;
    }
  };

  const checkValidationIsUserOrchids = () => {
    if (userBelong.length < 1) {
      setIsOrchidsFlag(true);
      setIsOrchidsHelperText("Required");
      return false;
    } else {
      setIsOrchidsFlag(false);
      setIsOrchidsHelperText("");
      return true;
    }
  };

  const handleSelectAll = () => {
    let tempSelectedRole = [];
    if (allPermissionSelected === false) {
      grantedPermissions.map((eachPerm) => {
        console.log(eachPerm, "changed permisstions");
        if (eachPerm !== undefined) {
          tempSelectedRole[eachPerm.module] = {
            module: eachPerm.module,
            can_add: true,
            can_cancel: true,
            can_delete: true,
            can_enroll: true,
            can_export: true,
            can_import: true,
            can_send: true,
            can_publish: true,
            can_activate_inactivate: true,
            can_update: true,
            can_view: true,
          };
        }
      });
      setAllPermissionSelected(true);
    } else {
      grantedPermissions.map((eachPerm) => {
        if (eachPerm !== undefined) {
          tempSelectedRole[eachPerm.module] = {
            module: eachPerm.module,
            can_add: false,
            can_cancel: false,
            can_delete: false,
            can_enroll: false,
            can_export: false,
            can_import: false,
            can_send: false,
            can_publish: false,
            can_activate_inactivate: false,
            can_update: false,
            can_view: false,
          };
        }
      });
      setAllPermissionSelected(false);
    }

    console.log(tempSelectedRole, "changed permisstions");
    setGrantedPermissions(tempSelectedRole);
  };

  console.log(grantedPermissions);

  const addRole = () => {
    let finalStructure = [];
    grantedPermissions.map((eachPerm) => {
      if (eachPerm !== undefined) {
        finalStructure.push(eachPerm);
      }
    });

    let addRoleObj = {
      name: roleName,
      modules: finalStructure,
      user_type: userType,
      is_orchids: userBelong === "orchids" ? true : false,
    };
    if (
      checkValidation() &&
      checkValidationIsUserOrchids() &&
      checkValidationOfUserType()
    ) {
      if (formType !== "Update") {
        axios
          .post(`${urls.AddRole}`, addRoleObj, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.personal_info.token}`,
              module: localStorage.getItem("User_Roles"),
            },
          })
          .then((response) => {
            props.getRole();
            props.close();
            console.log(response, "API response");
            alert.success("Role Added successfully");
          })
          .catch((error) => {
            console.log(error);
            alert.error("Something went wrong try again");
          });
      } else {
        console.log("Inside else condition");
        axios
          .put(
            `${urls.AddRole}${props.specificRoleData.role_id}/details/`,
            addRoleObj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.personal_info.token}`,
                module: localStorage.getItem("User_Roles"),
              },
            }
          )
          .then((response) => {
            props.getRole();
            props.close();
            console.log(response);
            alert.success("Role Updated successfully");
          })
          .catch((error) => {
            console.log(error);
            alert.error("Something went wrong try again");
          });
      }
    }
  };

  return (
    <div className="role-details-from">
      <div className="header">
        <Typography variant="h5" className="role-details-from-title">
          {formType} Role
        </Typography>

        <div className="add-role-from" margin="dense">
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            label="Role Name"
            required
            autoFocus
            error={roleNameFlag}
            helperText={roleNameHelperText}
            value={roleName}
            onBlur={() => checkValidation()}
            onChange={(e) => setRoleName(e.target.value)}
            className="role-name"
          />

          <FormControl
            variant="outlined"
            className="user_type"
            margin="dense"
            error={userTypeFlag}
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Select user type
            </InputLabel>
            <Select
              native
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              label="user_type"
              inputProps={{
                name: "user_type",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value="">--select--</option>
              <option value="ADMIN">Admin</option>
              <option value="USER">User</option>
            </Select>
            <FormHelperText>{userTypeHelperText}</FormHelperText>
          </FormControl>

          <FormControl
            variant="outlined"
            className="user_belong"
            margin="dense"
            error={isOrchidsFlag}
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Belonging of user
            </InputLabel>
            <Select
              native
              value={userBelong}
              onChange={(e) => setUserBelong(e.target.value)}
              label="user_belong"
              inputProps={{
                name: "user_belong",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value="">--select--</option>
              <option value="orchids">orchids</option>
              <option value="nonOrchids">Non Orchids</option>
            </Select>
            <FormHelperText>{isorchidsHelperText}</FormHelperText>
          </FormControl>
        </div>

        <div>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={allPermissionSelected}
                onChange={(e) => handleSelectAll(e)}
                name="all_permission"
              />
            }
            className="each-module-permission-body-can-complete"
            label="Select all permssions"
          /> */}
        </div>

        <TableContainer classes={{ root: classes.customTableContainer }}>
          <Table stickyHeader>
            <TableHead style={{ backgroundColor: "#4051B5", color: "#4051B5" }}>
              <TableRow>
                <TableCell>Permission</TableCell>
                <TableCell>All</TableCell>
                <TableCell>View</TableCell>
                <TableCell>Add</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Delete</TableCell>
                <TableCell>Cancel</TableCell>
                <TableCell>Send</TableCell>
                <TableCell>Publish</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Export</TableCell>
                <TableCell>Import</TableCell>
                <TableCell>Enroll</TableCell>
              </TableRow>
            </TableHead>

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Dashboards
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Dashboards &&
              allModule.Dashboards.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Blogs
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>

            <TableRow>
              {allModule.Blogs &&
                allModule.Blogs.map((eachModule) => {
                  return (
                    <>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </>
                  );
                })}
            </TableRow>

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Calendar
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Calendar &&
              allModule.Calendar.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType !== "USER" && userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Masters
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Masters &&
              allModule.Masters.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Notification
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Notification &&
              allModule.Notification.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Trainings
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Trainings &&
              allModule.Trainings.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Resources
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Resources &&
              allModule.Resources.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType !== "ADMIN" && userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Trainer Driven
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Trainer_Driven &&
              allModule.Trainer_Driven.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType !== "ADMIN" && userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Self Driven{" "}
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Self_Driven &&
              allModule.Self_Driven.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#4051B5",
                    }}
                  >
                    Reports
                  </Typography>{" "}
                </TableCell>
              ) : null}
            </TableRow>
            {allModule.Reports &&
              allModule.Reports.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            <TableRow>
              {userType.length > 0 ? (
                <TableCell colSpan={13}> Reports </TableCell>
              ) : null}
            </TableRow>
            {allModule.Reports &&
              allModule.Reports.map((eachModule) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{eachModule.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                grantedPermissions[eachModule.id] &&
                                grantedPermissions[eachModule.id].can_add &&
                                grantedPermissions[eachModule.id].can_update &&
                                grantedPermissions[eachModule.id].can_view &&
                                grantedPermissions[eachModule.id].can_delete
                              }
                              onChange={(e) =>
                                handleRuleSelectAll(
                                  e,
                                  eachModule.id,
                                  "blog",
                                  eachModule.name
                                )
                              }
                              name="all"
                            />
                          }
                          className="each-module-permission-body-can-complete"
                        />
                      </TableCell>
                      <TableCell>
                        {eachModule.can_view ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_view
                                  }
                                  onChange={(e) =>
                                    handleRuleView(e, eachModule.id)
                                  }
                                  name="can_view"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_add ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_add
                                  }
                                  onChange={(e) =>
                                    handleRuleAdd(e, eachModule.id)
                                  }
                                  name="can_add"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_update ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_update
                                  }
                                  onChange={(e) =>
                                    handleRuleUpdate(e, eachModule.id)
                                  }
                                  name="can_update"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_delete ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_delete
                                  }
                                  onChange={(e) =>
                                    handleRuleDelete(e, eachModule.id)
                                  }
                                  name="can_delete"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_cancel ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_cancel
                                  }
                                  onChange={(e) =>
                                    handleRuleCancel(e, eachModule.id)
                                  }
                                  name="can_cancel"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_send ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_send
                                  }
                                  onChange={(e) =>
                                    handleRuleCanSendReport(e, eachModule.id)
                                  }
                                  name="can_send"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_publish ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_publish
                                  }
                                  onChange={(e) =>
                                    handleRuleCanPublish(e, eachModule.id)
                                  }
                                  name="can_publish"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_activate_inactivate ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id]
                                      .can_activate_inactivate
                                  }
                                  onChange={(e) =>
                                    handleRuleCanActiveInactive(
                                      e,
                                      eachModule.id
                                    )
                                  }
                                  name="can_activate_inactivate"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_export ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_export
                                  }
                                  onChange={(e) =>
                                    handleRuleCanExport(e, eachModule.id)
                                  }
                                  name="can_export"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {eachModule.can_import ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_import
                                  }
                                  onChange={(e) =>
                                    handleRuleImport(e, eachModule.id)
                                  }
                                  name="can_import"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>

                      <TableCell>
                        {eachModule.can_enroll ? (
                          <div className="each-module-permission-body-contant">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    grantedPermissions[eachModule.id] &&
                                    grantedPermissions[eachModule.id].can_enroll
                                  }
                                  onChange={(e) =>
                                    handleEnroll(e, eachModule.id)
                                  }
                                  name="can_enroll"
                                />
                              }
                              className="each-module-permission-body-can-add"
                            />
                          </div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </Table>
        </TableContainer>
      </div>

      <div className="add-role-action-button">
        <div className="action-all-buttons">
          <Button className="cancel-button" onClick={props.close}>
            Cancel
          </Button>
          {loader ? (
            <Loader />
          ) : (
            <Button
              variant="contained"
              onClick={(e) => addRole()}
              className="submit-button"
            >
              {formType}
            </Button>
          )}
        </div>
      </div>
      {/* <Snackbar
        open={open}
        snackbarServerty={snackbarServerty}
        snackbarMessage={snackbarMessage}
        handleClose={handleClose}
      /> */}
    </div>
  );
};

export default AddRoleFrom;
