import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@material-ui/core";
import RoleTable from "../pages/role/role_table/role_table.jsx";
import RoleFloatingButton from "../pages/role/role_floating_button/role_floating_button.jsx";
import "./role.scss";
import axios from "axios";
import urls from "../../../url.js";
import { useAlert } from '../../../hoc/alert/alert';

const Role = () => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [specificRoleData, setSpecificRoleData] = useState([]);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [formFor, setFormFor] = useState('');
  const [accessRule, setAccessRule] = useState([]);
  const alert = useAlert();
  const [auth] = useState(JSON.parse(localStorage.getItem('UserLogin')));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSpecificRoleData([])
    console.log("close called");
  };

  useEffect(() => {
     getRole();
  }, []);

  const getRole = () => {
    axios.get(urls.AddRole, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('User_Roles')
      }
    })
      .then((res) => {
        console.log(res.data.response, "search");
        setData(res.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getParticularRoleData = (id) => {
    axios.get(urls.getRole+`${id}/details/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('User_Roles')
      }
    })
      .then((response) => {
        console.log(response.data.response, "search for role");
        setSpecificRoleData(response.data.response);
        handleClickOpen();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccess = (moduleId) => {
    axios.get(urls.getPermissons, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
        module : moduleId
      },
    })
    .then(response => {
      setAccessRule(response.data.response);
    }).catch(error => {
      console.log(error.response, "auth response");
    })
  };

  useEffect(() => {
    getAccess(localStorage.getItem('User_Roles'))
  }, [])

  return (
    <div className="role-container">
      <div className="first-grid">
      {accessRule.can_view ? 
        <Grid>
          <Paper>
            <RoleTable
              data={data}
              getRole={getRole}
              getParticularRoleData={getParticularRoleData}
              accessRule= {accessRule}
            />
          </Paper>
        </Grid>
        :null}
      </div>
      <div className="action-button">
        <RoleFloatingButton
          open = {open}
          handleClose = {() => handleClose()}
          handleClickOpen = {handleClickOpen}
          data={data}
          getRole={getRole}
          accessRule= {accessRule}
          specificRoleData={specificRoleData}
        />
      </div>
    </div>
  );
};
export default Role;
