/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Button, Typography, Divider,
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Loader from '../../../hoc/loader';
import styles from './gradeMapping.style';
import urls from '../../../url';

// const alert = useAlert();

const columns = [
  { id: 'grade', label: 'Course', minWidth: 170 },
  { id: 'subject', label: 'School', minWidth: 100 },
  {
    id: 'edit',
    label: 'Edit',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'deleteMapping',
    label: 'Delete',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'State',
    label: 'State',
    minWidth: 170,
    align: 'right',
  },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// eslint-disable-next-line camelcase
function createData(obj_id, grade_id, grade, subject_id, subject, is_delete) {
  return {
    obj_id, grade_id, grade, subject_id, subject, is_delete,
  };
}

class CourseMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: JSON.parse(localStorage.getItem('UserLogin')),
      rowsPerPage: 5,
      page: 0,
      open: false,
      alert: false,
      active: false,
      inputValue: '',
      modulePermission:[],
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }


  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { auth } = this.state;
    axios.get(`${urls.getSchoolMapping}`, {
      // axios.get(`${urls.gradeMapping}?page=${1}&page_size=${5}`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School_Course_Mapping')
      },

    }).then((res) => {
      if (res.status === 200) {
      // eslint-disable-next-line react/no-unused-state
        this.setState({ mappingList: res.data });
      }
    }).catch((err) => {
      console.log(err);
    });
    axios.get(`${urls.getSchoolList}`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School_Course_Mapping')
      },

    }).then((res) => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ gradeList: res.data });
      // console.log(this.state.gradeList);
    }).catch((err) => {
      console.log(err);
    });
    axios.get(`${urls.getListCourses}`, {
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School_Course_Mapping')
      },

    }).then((res) => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ subjectList: res.data });
      // console.log(this.state.subjectList);
    }).catch((err) => {
      console.log(err);
    });
    axios.get(`${urls.getPermissons}`,{
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        module: localStorage.getItem('School_Course_Mapping')
      },
    }).then((res) => {
      this.setState({modulePermission: res.data.response});
      // console.log('hi',res.data.response)
    }).catch((err) => {
      console.log(err);
    })
  }

  handleMapping = () => {
    const { auth } = this.state;
    const { gradeId, subjectId } = this.state;
    // console.log(gradeId);
    if (gradeId && subjectId) {
      const map = {
        school_fk: gradeId,
        course_fk: subjectId,
      };
      // console.log(map);
      axios
        .post(`${urls.postSchoolMapping}`, JSON.stringify(map), {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            'Content-Type': 'application/json',
        module: localStorage.getItem('School_Course_Mapping')
          },
        })
        .then((res) => {
          if (res.status === 201) {
            this.setState({ success: true, alert: true, successMessage: 'Created Successfully' });
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.setState({ error: true, alert: true, errMessage: error.response.data.message });
          }
        });
    } else {
      this.setState({ warning: true, alert: true, warningMessage: 'Please Select School and Course' });
    }
  }

  handleModalOpen = (row) => {
    const allUser = [];
    allUser.push(row);
    // console.log(row);
    this.setState({
      selectedRow: allUser,
      gradeSelected: row.grade,
      gradeId: row.grade_id,
      subjectId: row.subject_id,
      subjectSelected: row.subject,
      active: !row.is_delete,
    });
    this.setState({ open: true });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleEdit = (row) => {
    const { auth } = this.state;
    const { gradeId, subjectId, active } = this.state;
    // console.log(row);
    if (gradeId && subjectId) {
      const map = {
        // course_fk: subjectId,
        // school_fk: gradeId,
        course_fk: gradeId,
        school_fk: subjectId,
        is_active: !active,
      };
      axios
        .put(`${urls.edit_deleteMapping}${row[0].obj_id}/school_course_mapping/`, JSON.stringify(map), {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            'Content-Type': 'application/json',
        module: localStorage.getItem('School_Course_Mapping')

          },
        })
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            this.setState({ success: true, alert: true, successMessage: 'Updated Successfully' });
            this.handleModalClose();
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.setState({ error: true, alert: true, errMessage: error.response.data.message });
          }
        });
    }
  }

  handleDelete = (row) => {
    const { auth } = this.state;
    axios
      .delete(`${urls.edit_deleteMapping}${row.obj_id}/school_course_mapping/`, {
        // data: {
        //     "delete" : true
        // },
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
            'Content-Type': 'application/json',
        module: localStorage.getItem('School_Course_Mapping')
        },
        
      })
      .then(() => {
        this.setState({ success: true, alert: true, successMessage: 'Deleted Successfully' });
        window.location.reload();
        // console.log(res);
      });
  }

  handleGradeChange = (data) => {
    if (data !== null) this.setState({ gradeId: data.id });
  }

  handleSubjectChange = (data) => {
    if (data !== null) this.setState({ subjectId: data.id });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 0 });
  };


  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ alert: false });
  };

  handleChangeState = (event) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  setInputValue = (e) => {
    this.setState({ inputValue: e });
  }

  render() {
    const {
      gradeSelected, subjectSelected, gradeList, active, subjectList, mappingList, page, rowsPerPage, open, selectedRow, alert, success, warning,
    } = this.state;
    const { classes } = this.props;
    const rows = mappingList && mappingList.map((item) => (createData(item.id, item.course_fk.id, item.course_fk.course_name, item.school_fk !== null ? item.school_fk.id : '-',    item.school_fk !== null ? item.school_fk.name : '-', item.is_active)
    ));
    const result = selectedRow && selectedRow.filter((o1) => gradeList.some((o2) => o1.type_name === o2.grade));

    return (
      <div className={classes.root}>
        <Typography variant="h4">School Course Mapping</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <Grid item xs>
            <Autocomplete
              id="Grade"
              options={gradeList}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="School" variant="outlined" />}
              onChange={(e, data) => this.handleGradeChange(data)}
            />
          </Grid>
          <Grid item xs>
            <Autocomplete
              id="Subject"
              options={subjectList}
              getOptionLabel={(option) => option.course_name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Course"   variant="outlined" />}
              onChange={(e, data) => this.handleSubjectChange(data)}

            />
          </Grid>
          <Grid item xs>
            {this.state.modulePermission && this.state.modulePermission.can_add ? 
            
            <Button
              className={classes.ShowButton}
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => this.handleMapping()}
            >
              Map School Course
            </Button>
          :null}
          </Grid>
          <Grid container spacing={3}>
            {rows
              ? (
                <Grid item xs={12}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              <Typography fontWeight={1000} variant="h5">{column.label}</Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {
                                  column.label === 'Edit'
                                    ? (
                                      <>
                                      {this.state.modulePermission.can_update ? 
                                      
                                      <IconButton id={column.id} aria-label="edit" onClick={() => this.handleModalOpen(row)} className={classes.margin}>
                                        <EditIcon />
                                      </IconButton>
                                    :null}
                                      </>
                                    )
                                    // ? <Button id={column.id} onClick={() => this.handleModalOpen(row)} color="primary" variant="contained">Edit</Button>
                                    : column.label === 'Delete'
                                      ? (
                                        <>
                                        {this.state.modulePermission.can_delete ? 
                                        
                                        <IconButton id={column.id} aria-label="delete" onClick={() => this.handleDelete(row)} className={classes.margin}>
                                          <DeleteIcon />
                                        </IconButton>
                                      :null}
                                        </>
                                      )
                                      // ? <Button id={column.id} onClick={() => this.handleDelete(row)} color="secondary" variant="container">Delete</Button>
                                      : column.label === 'State'
                                        ? (
                                          row.is_delete ? <Button variant="contained" style={{ backgroundColor: 'lightcoral' }}>InActive</Button>
                                            : <Button variant="contained" style={{ backgroundColor: 'lightGreen' }}>Active</Button>
                                        ) : value
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ paddingRight: '35%' }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event)}
                    />
                  </div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={() => this.handleModalClose()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={classes.paper}>
                        <div className={classes.root}>
                          <Typography variant="h5">Edit School and Course Mapping</Typography>
                          <Divider className={classes.divider} />
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={3}
                          >
                            <Grid item xs>
                              {/* {console.log(result && result[0].grade)} */}
                              <Autocomplete
                                id="Grade"
                                options={gradeList}
                                defaultValue={{ name: subjectSelected }}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="School" variant="outlined" />}
                                onChange={(e, data) => this.handleGradeChange(data)}

                              />
                            </Grid>
                            <Grid item xs>
                              <Autocomplete
                                id="Subject"
                                options={subjectList}
                                defaultValue={{ course_name: gradeSelected }}
                                getOptionLabel={(option) => option.course_name}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Course" variant="outlined" />}
                                onChange={(e, data) => this.handleSubjectChange(data)}
                              />
                            </Grid>
                            <Grid item xs>
                              <FormControlLabel
                                control={(
                                  <Switch
                                    checked={active}
                                    onChange={(event) => this.handleChangeState(event)}
                                    name="active"
                                    color="primary"
                                  />
                                )}
                                label="Active"
                              />
                            </Grid>
                            <Grid item xs>
                              {this.state.modulePermission.can_update ?

                              <Button
                                className={classes.ShowButton}
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={() => this.handleEdit(selectedRow)}
                              >
                                Save
                              </Button>
                              :null}
                            </Grid>
                            <Grid item xs>
                              <Button
                                className={classes.ShowButton}
                                color="primary"
                                variant="outlined"
                                size="medium"
                                onClick={() => this.handleModalClose()}
                              >
                                Return
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                </Grid>
              ) : <Loader open /> }
          </Grid>
        </Grid>
        <>
          {success ? (
            <Snackbar open={alert} autoHideDuration={1000} onClose={this.handleClose}>
              <Alert onClose={this.handleClose} severity="success">
                {this.state.successMessage}
              </Alert>
            </Snackbar>
          ) : warning ? (
            <Snackbar open={alert} autoHideDuration={1000} onClose={this.handleClose}>
              <Alert onClose={this.handleClose} severity="warning">
                {this.state.warningMessage}
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={alert} autoHideDuration={1000} onClose={this.handleClose}>
              <Alert onClose={this.handleClose} severity="error">
                {this.state.errMessage}
              </Alert>
            </Snackbar>
          ) }
        </>
      </div>
    );
  }
}
CourseMapping.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default (withStyles(styles)(CourseMapping));
